import { ImageAssetModelDimensions, NaturalImageProps } from '../../types';

export const handleOnNaturalImageLoad = (
  setImageDimensions: (dimensions: ImageAssetModelDimensions) => void,
  img: HTMLImageElement,
  isFallback: boolean,
  onLoad: NaturalImageProps['onLoad']
) => {
  if (isFallback) {
    setImageDimensions({
      height: 0,
      width: 0,
    });
  } else {
    setImageDimensions({
      height: img.naturalHeight,
      width: img.naturalWidth,
    });
  }
  if (onLoad) {
    onLoad(img, isFallback);
  }
};

export const getAspectRatio = ({ height, width }: ImageAssetModelDimensions) =>
  `${width} / ${height}`;
