import { memo } from 'react';
import clsx from 'clsx';
import { ImageSize } from '@kitted/kitted-models';

import AssetImage from '../../../AssetImage';
import LoadWhenInView from '../../../LoadWhenInView';
import {
  useAssetCatalogueApi,
  useAssetCatalogueData,
} from '../../contexts/AssetCatalogueProvider';
import { isCatalogueItemSelected } from '../../contexts/AssetCatalogueProvider/logic';
import useStyles from './styles';
import { CatalogueItemsProps } from './types';

const CatalogueItems = ({ catalogueItems }: CatalogueItemsProps) => {
  const styles = useStyles();

  const { setSelectedCatalogueItem } = useAssetCatalogueApi();
  const { selectedCatalogueItem } = useAssetCatalogueData();

  return (
    <>
      {catalogueItems.map((ci) => {
        const isSelected = isCatalogueItemSelected(ci, selectedCatalogueItem);
        return (
          <LoadWhenInView key={ci.assetId} className={styles.assetContainer}>
            <AssetImage
              className={clsx(
                styles.assetImage,
                isSelected && styles.assetSelected
              )}
              imageAsset={{ id: ci.assetId }}
              size={ImageSize.Small}
              onClick={() =>
                isSelected
                  ? setSelectedCatalogueItem(null)
                  : setSelectedCatalogueItem(ci)
              }
            />
          </LoadWhenInView>
        );
      })}
    </>
  );
};

export default memo(CatalogueItems);
