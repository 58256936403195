import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0021: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 86 96"
		height={96}
		width={86}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M12.42 55.51H1.11V1.44h72.94v7.63" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m25.45 94.55-.64-5.18c-.24-2.87-2.43-4.39-4.86-4.39H8.32c-2.43 0-4.62 1.52-4.86 4.39l-.64 5.17M48.07 94.55l-.64-5.19c-.24-2.87-2.43-4.39-4.86-4.39H30.94c-2.43 0-4.62 1.52-4.86 4.39l-.63 5.18" /><path vectorEffect="non-scaling-stroke" d="m70.68 94.56-.64-5.19c-.24-2.87-2.43-4.39-4.86-4.39H53.55c-2.43 0-4.62 1.52-4.86 4.39l-.63 5.19" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="m80.19 83.31.54-22.27c2.09-.75 4.37-2.5 4.15-4.7L83.39 39.4c-.22-2.2-2.21-4-4.42-4H68.94c-2.21 0-4.2 1.8-4.42 4l-1.49 16.94M79.92 21.76c0 3.3-2.67 5.97-5.97 5.97s-5.97-2.67-5.97-5.97 2.67-5.97 5.97-5.97 5.97 2.67 5.97 5.97" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M65.35 71.6c0 3.3-2.67 5.97-5.97 5.97s-5.97-2.67-5.97-5.97 2.67-5.97 5.97-5.97 5.97 2.67 5.97 5.97M42.73 71.6c0 3.3-2.67 5.97-5.97 5.97s-5.97-2.67-5.97-5.97 2.67-5.97 5.97-5.97 5.97 2.67 5.97 5.97M20.11 71.6c0 3.3-2.67 5.97-5.97 5.97S8.17 74.9 8.17 71.6s2.67-5.97 5.97-5.97 5.97 2.67 5.97 5.97" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M54.03 55.43c0 3.3-2.67 5.97-5.97 5.97s-5.97-2.67-5.97-5.97 2.67-5.97 5.97-5.97 5.97 2.67 5.97 5.97M31.44 55.43c0 3.3-2.67 5.97-5.97 5.97s-5.97-2.67-5.97-5.97 2.67-5.97 5.97-5.97 5.97 2.67 5.97 5.97" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0021);
