import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0038: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 100 114"
		height={114}
		width={100}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M72.3 18.78a.58.58 0 1 0 1.16 0 .58.58 0 0 0-1.16 0M65.65 18.78a.58.58 0 1 0 1.16 0 .58.58 0 0 0-1.16 0" /><circle vectorEffect="non-scaling-stroke" cx="69.55" cy="21.63" r="10.19" /><path vectorEffect="non-scaling-stroke" d="M73.91 24.17c-.87 1.48-2.52 2.47-4.35 2.47s-3.48-1-4.35-2.47" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m17.38 39.91 20.93 72.45M1.12 80.89l25.8-7.44M56.6 56.56l-20.97 6.25 13.26 46.27M28.1 36.69l4.42 15.45 18.83-5.68M43.12 88.52l55.76-16.07M89.54 21.63c0 11.04-19.99 44.7-19.99 44.7s-19.99-33.66-19.99-44.7S58.51 1.64 69.55 1.64s19.99 8.95 19.99 19.99M83.99 76.69l4.1 14.39" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0038);
