import { KeyboardEvent, memo } from 'react';
import clsx from 'clsx';
import { Typography } from '@kitted/shared-components';
import { Times } from '@kitted/website-icons';

import InputWrapper from '../InputWrapper';
import useInputText from './hooks/useInputText';
import useStyles from './styles';
import { InputTextProps } from './types';

const InputText = ({
  autoComplete,
  className,
  errors,
  iconLeft,
  iconRight,
  disabled,
  isFocused,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  meta,
  name,
  required,
  type,
  value,
  theme,
  variant,
}: InputTextProps) => {
  const styles = useStyles();
  const { sanitizedValue, inputRef, handleOnChange, onClearValue } =
    useInputText(type, onChange, value);
  const hasRightIcon = iconRight || (!!value && (meta?.canClear as boolean));

  return (
    <InputWrapper
      className={className}
      errors={errors}
      iconLeft={type === 'url' ? undefined : iconLeft}
      iconRight={!sanitizedValue ? iconRight : undefined}
      isDisabled={disabled}
      isFocused={isFocused}
      hasValue={!!sanitizedValue}
      helperText={meta?.helperText}
      theme={theme}
      variant={variant}
    >
      <>
        {type === 'url' && (
          <div className={styles.urlIcon}>
            <Typography variant={`input${variant === 'hollow' ? 'Small' : ''}`}>
              https://
            </Typography>
          </div>
        )}
        <Typography
          as="input"
          ref={inputRef}
          autoComplete={autoComplete}
          autoFocus={meta?.autoFocus}
          className={clsx(
            styles.input,
            type !== 'url' && iconLeft && styles.inputWithLeftIcon,
            hasRightIcon && styles.inputWithRightIcon,
            type === 'url' && styles.inputUrl
          )}
          disabled={disabled}
          onBlur={onBlur}
          onChange={handleOnChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown as (e: KeyboardEvent) => void}
          placeholder={meta?.placeholder as string}
          name={name}
          required={required}
          type={type === 'url' ? 'text' : type}
          value={(sanitizedValue as number | string) || ''}
          variant={`input${variant === 'hollow' ? 'Small' : ''}`}
        />
        {meta?.canClear && (
          <div
            className={clsx(
              styles.clearIcon,
              !!sanitizedValue && styles.clearIconVisible
            )}
          >
            <Times onClick={onClearValue} size="inherit" />
          </div>
        )}
      </>
    </InputWrapper>
  );
};

export default memo(InputText);
