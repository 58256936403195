import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0032: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 100 74"
		height={74}
		width={100}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M6.87 72.16c-2.91 0-5.3-2.37-5.3-5.28V7.13c0-2.91 2.39-5.29 5.3-5.29h86.26c2.91 0 5.29 2.38 5.29 5.3v59.75c0 2.91-2.38 5.28-5.29 5.28H6.87Z" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M26.51 43.37 4.64 69.55M73.49 43.37l21.87 26.18" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="m12.24 10.52 37.9 29.26 37.07-29.1" />
    </g>
  </CardIcon>
);

export default memo(Fr0032);
