import { memo } from 'react';
import { Divider, Typography } from '@kitted/shared-components';
import { Key } from '@kitted/website-icons';

import { FormProvider } from '../../../contexts/FormContext';
import Form from '../../Form';
import FormFields from '../../FormFields';
import AuthenticationModalContentWrapper from '../AuthenticationModalContentWrapper';
import useForgotPasswordModalContent from './hooks/useForgotPasswordModalContent';
import { dataSchema, layoutSchema } from './constants';
import ResetButton from './ResetButton';
import useStyles from './styles';
import { AuthForgotPasswordModalProps } from './types';

const AuthForgotPasswordModalContent = ({
  onClose,
  preventClose,
  title,
  isRequired,
}: AuthForgotPasswordModalProps) => {
  const { onSubmit, onSubmitSuccess } = useForgotPasswordModalContent(onClose);
  const styles = useStyles();

  return (
    <AuthenticationModalContentWrapper
      preventClose={preventClose}
      title={
        <>
          <span className={styles.iconContainer}>
            <Key />
          </span>
          {title}
        </>
      }
      isRequired={isRequired}
      onClose={onClose}
    >
      <Typography className={styles.forgot}>
        No worries. We&rsquo;ll email you instructions on how to reset your
        password.
      </Typography>
      <FormProvider dataSchema={dataSchema}>
        <Form
          autoComplete
          onSubmit={onSubmit}
          onSubmitSuccess={onSubmitSuccess}
        >
          <FormFields layout={layoutSchema} />
          <Divider
            className={styles.divider}
            isSubtle
            spacing="lg"
            theme="primary"
          />
          <ResetButton />
        </Form>
      </FormProvider>
    </AuthenticationModalContentWrapper>
  );
};

export default memo(AuthForgotPasswordModalContent);
