import { z } from 'zod';

import {
  AuthorCardLoadedModelSchema,
  AuthorCardUnloadedModelSchema,
} from '../Author';
import { KitCardLoadedModelSchema, KitCardUnloadedModelSchema } from '../Kit';
import {
  SubjectCardLoadedModelSchema,
  SubjectCardUnloadedModelSchema,
} from '../Subject';

export const AnyCardWithRelatedCardsWithoutToolsLoadedModelSchema = z.union([
  AuthorCardLoadedModelSchema,
  KitCardLoadedModelSchema,
  SubjectCardLoadedModelSchema,
]);

export const AnyCardWithRelatedCardsWithoutToolsUnloadedModelSchema = z.union([
  AuthorCardUnloadedModelSchema,
  KitCardUnloadedModelSchema,
  SubjectCardUnloadedModelSchema,
]);

export type AnyCardWithRelatedCardsWithoutToolsLoadedModel = z.infer<
  typeof AnyCardWithRelatedCardsWithoutToolsLoadedModelSchema
>;
export type AnyCardWithRelatedCardsWithoutToolsUnloadedModel = z.infer<
  typeof AnyCardWithRelatedCardsWithoutToolsUnloadedModelSchema
>;

export type AnyCardWithRelatedCardsWithoutToolsModel<
  IsLoaded extends boolean = false,
> = IsLoaded extends true
  ? AnyCardWithRelatedCardsWithoutToolsLoadedModel
  : AnyCardWithRelatedCardsWithoutToolsUnloadedModel;
