import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { TooltipProps } from '../../types';
import { handleMouseEnter, handleMouseLeave } from './logic';

const useTooltip = (
  defaultOpen: boolean,
  controlledOpen: TooltipProps['open']
) => {
  const timerRef = useRef<number>();
  const [open, setOpen] = useState<boolean>(defaultOpen);

  const setTimerRef = useCallback((timerId?: number) => {
    timerRef.current = timerId;
  }, []);

  useEffect(() => {
    setOpen(controlledOpen || defaultOpen);
  }, [controlledOpen, defaultOpen, setOpen]);

  const onMouseEnter = useCallback(
    () => handleMouseEnter(setOpen, timerRef.current, setTimerRef),
    [setOpen, setTimerRef]
  );

  const onMouseLeave = useCallback(
    () => handleMouseLeave(setOpen, timerRef.current, setTimerRef),
    [setOpen, setTimerRef]
  );

  const triggerProps = useMemo(
    () => ({
      onMouseEnter,
      onMouseLeave,
    }),
    [onMouseEnter, onMouseLeave]
  );

  return {
    isOpen: open,
    triggerProps,
  };
};

export default useTooltip;
