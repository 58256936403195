import { z } from 'zod';
import { DateISOSchema } from '@kitted/platform-models';

export const KittedAuditableModelSchema = z.object({
  createdByAuthorId: z.string(),
  createdByUserId: z.string(),

  createdAt: DateISOSchema,
  updatedAt: DateISOSchema,
});

export type KittedAuditableModel = z.infer<typeof KittedAuditableModelSchema>;
