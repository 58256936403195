import {
  ImageResourceCardModel,
  ResourceCardType,
} from '@kitted/card-service-models';
import {
  CardType,
  KittedModelTypeEnum,
  KittedVersionStatusTypeEnum,
} from '@kitted/kitted-models';
import { ImageResourceModel } from '@kitted/resource-service-models';

export const convertImageResourceModelToImageResourceCardModel = (
  id: ImageResourceModel['id'],
  imageResource: Omit<ImageResourceModel, 'id'>,
  oldCard: Partial<ImageResourceCardModel> = {}
): ImageResourceCardModel => ({
  ...oldCard,

  id,
  slug: imageResource.slug,

  cardType: CardType.Resource,
  resourceType: ResourceCardType.Image,
  kittedModelType: KittedModelTypeEnum.CardImageResource,

  createdAt: oldCard?.createdAt || imageResource.createdAt,
  updatedAt: imageResource.updatedAt,

  createdByAuthorId: imageResource.createdByAuthorId,
  keyImageImageAsset: imageResource.keyImageImageAsset,
  label: imageResource.label,
  title: imageResource.title,
  references: imageResource.references,
  showTitle: imageResource.showTitle,

  excludeFromDiscovery:
    imageResource.cmsSrcMessage?.excludeFromDiscovery ??
    oldCard?.excludeFromDiscovery ??
    true,
  live: imageResource.cmsSrcMessage?.live ?? oldCard?.live ?? true,
  versionStatus: imageResource.versionStatus,
  publishedAt:
    imageResource.versionStatus === KittedVersionStatusTypeEnum.Published
      ? imageResource.updatedAt
      : oldCard.publishedAt!,
});
