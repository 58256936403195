import * as ReactDOM from 'react-dom/client';
import { GlobalStyles } from '@kitted/shared-components';

import App from './app';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <>
    <GlobalStyles />
    <App />
  </>
);
