import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0049: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 100 118"
		height={118}
		width={100}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M71.49 33.54a33.35 33.35 0 0 1 11.88 25.51c0 18.38-14.97 33.32-33.37 33.32" /><path vectorEffect="non-scaling-stroke" d="M50 116.76 28.05 99.72 50 82.65M90.45 85.57C81.79 98.71 66.9 107.39 50 107.39M98.36 57.74a48.08 48.08 0 0 1-4.23 21.14M93.3 37.45c1.91 3.81 3.33 7.92 4.17 12.23M82.45 23.18c2.69 2.42 5.1 5.15 7.18 8.12" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M28.52 84.47a33.37 33.37 0 0 1-11.88-25.5c0-18.38 14.97-33.33 33.37-33.33M50.01 1.24l21.94 17.05-21.94 17.06" /><path vectorEffect="non-scaling-stroke" d="M9.56 32.44c8.66-13.15 23.55-21.82 40.45-21.82M1.64 60.27c-.01-.44-.02-.87-.02-1.3 0-7.07 1.52-13.78 4.25-19.84M6.7 80.56a47.8 47.8 0 0 1-4.17-12.24M17.55 94.83c-2.68-2.43-5.1-5.15-7.18-8.12" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0049);
