import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0025: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 124 118"
		height={118}
		width={124}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m114.23 37.71-6.4 78.53h-25.5M40.97 24.18v-9.6h19.68" /><path vectorEffect="non-scaling-stroke" d="M56.19 14.58h65.49v13.79H85.3M48.12 13.53l4.02-11.77h58.47l3.92 11.77" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M31.05 116.24H9.4L6.13 76.08M26.63 71.3H2.32v-7.05h10.06M12.38 64.25h13.68M5.97 63.71l2.06-6.02h17.49" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m81.33 56.23-4.89 60.01H35.93l-4.88-60.01M50.8 38.56h36.22V49.1H25.35V38.56h15.04M30.81 37.76l3.08-8.99h44.67l3 8.99" /></g>
    </g>
  </CardIcon>
);

export default memo(In0025);
