import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const BlockFullbleed: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 32 24"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M3.6 1.04h24.8M3.6 22.96h24.8" /><rect vectorEffect="non-scaling-stroke" width="28.52" height="15.21" x="1.74" y="4.39" stroke={color} rx=".53" ry=".53" />
    </g>
  </WebsiteIcon>
);

export default memo(BlockFullbleed);
