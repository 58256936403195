import { styling } from '@kitted/design-system';

const inherit = {
  fontSize: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  letterSpacing: 'inherit',
};

const body = {
  ...styling.typography.theme.atlasGrotesk.regular.sm,
};

const bodySmall = {
  ...styling.typography.theme.atlasGrotesk.regular['3xs'],
};

const articleByLineTitle = {
  ...styling.typography.theme.atlasGrotesk.bold.sm,
};

const heading1 = {
  ...styling.typography.theme.atlasGrotesk.bold['3xl'],
};

const heading3 = {
  ...styling.typography.theme.atlasGrotesk.bold.xl,
};

const heading4 = {
  ...styling.typography.theme.atlasGrotesk.bold.xl,
};

const heading5 = {
  ...styling.typography.theme.atlasGrotesk.bold.lg,
};

const heading6 = {
  ...styling.typography.theme.atlasGrotesk.bold.md,
};

const navLink = {
  ...styling.typography.theme.atlasGrotesk.bold['3xs'],
};

const footerLink = {
  ...styling.typography.theme.atlasGrotesk.bold.xs,
  color: styling.colors.context.footer.color,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
};

const uploadTitle = {
  ...styling.typography.theme.atlasGrotesk.medium['2xs'],
};

const uploadBody = {
  ...styling.typography.theme.atlasGrotesk.regular['3xs'],
};

const alertTitle = {
  ...styling.typography.theme.atlasGrotesk.bold.sm,
};

const alertBody = {
  ...styling.typography.theme.atlasGrotesk.regular.xs,
};

const toastTitle = {
  ...styling.typography.theme.atlasGrotesk.bold.xs,
};

const toastBody = {
  ...styling.typography.theme.atlasGrotesk.regular['3xs'],
};

const input = {
  ...styling.typography.theme.atlasGrotesk.regular.xs,
  lineHeight: '2.7rem',
};

const inputSmall = {
  ...styling.typography.theme.atlasGrotesk.regular['3xs'],
};

const inputLabel = {
  ...styling.typography.theme.atlasGrotesk.medium['3xs'],
};

const inputLabelRequiredIndicator = {
  ...inputLabel,
  fontWeight: styling.fonts.theme.atlasGrotesk.weights.regular,
};

const inputErrors = {
  ...styling.typography.theme.atlasGrotesk.regular['3xs'],
  lineHeight: '1.7rem',
};

const inputHelper = {
  ...styling.typography.theme.atlasGrotesk.regular['3xs'],
  lineHeight: '1.7rem',
};

const inputDropdown = {
  ...styling.typography.theme.atlasGrotesk.bold['3xs'],
};

const editModalFooterTitle = {
  ...styling.typography.theme.atlasGrotesk.bold.xs,
  lineHeight: '2.4rem',
};

const tooltip = {
  ...styling.typography.theme.atlasGrotesk.medium['3xs'],
};

const inlineAction = {
  ...styling.typography.theme.atlasGrotesk.medium['2xs'],
  textDecoration: 'underline',
};

const cardTitle = {
  fontFamily: styling.fonts.theme.atlasGrotesk.fontFamily,
  fontWeight: styling.fonts.theme.atlasGrotesk.weights.bold,
  fontSize: '2.3rem',
  lineHeight: '2.5rem',
  letterSpacing: styling.fonts.utils.tracking(23, -25),
  whiteSpace: 'pre-wrap',
  textTransform: 'uppercase',
};

const cardTitleFeatured = {
  ...cardTitle,
  fontSize: '2.8rem',
  lineHeight: '2.8rem',
  letterSpacing: styling.fonts.utils.tracking(28, -25),
};

const cardBody = {
  fontFamily: styling.fonts.theme.atlasGrotesk.fontFamily,
  fontWeight: styling.fonts.theme.atlasGrotesk.weights.regular,
  fontSize: '1.7rem',
  lineHeight: '2.4rem',
  letterSpacing: styling.fonts.utils.tracking(17, -10),
};

const cardBodyHover = {
  ...cardBody,
  fontSize: '1.5rem',
  lineHeight: '1.9rem',
  letterSpacing: styling.fonts.utils.tracking(15, -35),
};

const cardBodyFeatured = {
  ...cardBody,
  fontSize: '1.73rem',
  lineHeight: '2rem',
  letterSpacing: styling.fonts.utils.tracking(17.3, -10),
  paddingBottom: 2,
};

const cardSlug = {
  fontFamily: styling.fonts.theme.redHatMono.fontFamily,
  fontWeight: styling.fonts.theme.redHatMono.weights.medium,
  fontSize: '1.1rem',
  lineHeight: '1.8rem',
  letterSpacing: styling.fonts.utils.tracking(11, 50),
  whiteSpace: 'nowrap',
  textTransform: 'uppercase',
};

const cardSlugFeatured = {
  ...cardSlug,
  fontSize: '1.14rem',
  lineHeight: '1.67rem',
  letterSpacing: styling.fonts.utils.tracking(11.4, 50),
};

const cardMenuAction = {
  fontFamily: styling.fonts.theme.atlasGrotesk.fontFamily,
  fontWeight: styling.fonts.theme.atlasGrotesk.weights.bold,
  fontSize: '1.6rem',
  lineHeight: '1.76rem',
  letterSpacing: styling.fonts.utils.tracking(16, -25),
};

const pill = {
  ...styling.typography.theme.atlasGrotesk.medium['3xs'],
  whiteSpace: 'nowrap',
  textTransform: 'uppercase',
};

const boardProfileName = {
  ...styling.typography.theme.atlasGrotesk.bold.sm,
  fontStyle: 'italic',
};

const boardProfilePosition = {
  ...styling.typography.theme.atlasGrotesk.medium['3xs'],
  textTransform: 'uppercase',
};

const boardProfileLink = {
  ...styling.typography.theme.atlasGrotesk.bold['3xs'],
  textTransform: 'uppercase',
  fontStyle: 'italic',
};

const mobileNavigationLink = {
  fontFamily: styling.fonts.theme.atlasGrotesk.fontFamily,
  fontWeight: styling.fonts.theme.atlasGrotesk.weights.medium,
  fontSize: '1rem',
  lineHeight: '1.1rem',
};

const flowStepTitle = {
  ...styling.typography.theme.atlasGrotesk.bold.lg,
  lineHeight: '1.055em',
  marginBottom: styling.sizes.theme.spacing.xs,
  [styling.breakpoints.context.tabletUpString]: {
    ...styling.typography.theme.atlasGrotesk.bold['2xl'],
    lineHeight: '1.055em',
  },
};

const flowStepBody = {
  ...styling.typography.theme.atlasGrotesk.regular.xs,
  lineHeight: '2.7rem',
  [styling.breakpoints.context.tabletUpString]: {
    ...styling.typography.theme.atlasGrotesk.regular.sm,
  },
};

const flowStepElementsDropdown = {
  ...styling.typography.theme.atlasGrotesk.medium['2xs'],
};

const articleHeading2 = {
  ...styling.typography.theme.atlasGrotesk.bold['2xl'],
  // marginTop: '2.6rem',
  // marginBottom: '1.35rem',
};

const articleHeading3 = {
  ...styling.typography.theme.atlasGrotesk.bold.xl,
  // marginTop: '4.6rem', // maybe not?
  // marginBottom: '1.35rem',
};

const articleHeading4 = {
  ...styling.typography.theme.atlasGrotesk.bold.md,
  // marginTop: 0,
  // marginBottom: '1.35rem',
};

const articleHeading5 = {
  ...styling.typography.theme.atlasGrotesk.bold.sm,
  // marginTop: 0,
  // marginBottom: '1.35rem',
};

const articleBody = {
  ...styling.typography.theme.atlasGrotesk.regular.xs,
  lineHeight: '2.7rem',
  // marginTop: 0,
  // marginBottom: '1.35rem',
};

const articleQuoteBody = {
  ...articleBody,
  fontStyle: 'italic',
  fontWeight: styling.fonts.theme.atlasGrotesk.weights.medium,
};

const articleQuoteHeading = {
  fontStyle: 'italic',
  fontWeight: styling.fonts.theme.atlasGrotesk.weights.bold,
  fontSize: '3.2rem',
  lineHeight: '3.84rem',
  letterSpacing: styling.fonts.utils.tracking(32, -10),
  textTransform: 'none',
  textAlign: 'center',
  marginTop: 19,
  marginBottom: 26,
};

const articleCaption = {
  ...styling.typography.theme.atlasGrotesk.regular['2xs'],
  fontStyle: 'italic',
  textAlign: 'center',
};

const playCaption = {
  ...styling.typography.theme.atlasGrotesk.regular['2xs'],
  fontStyle: 'italic',
  textAlign: 'center',
};

const formTitle = {
  ...styling.typography.theme.atlasGrotesk.bold.sm,
};

const formSubtitle = {
  ...styling.typography.theme.atlasGrotesk.regular['2xs'],
};

const menuItem = {
  ...styling.typography.theme.atlasGrotesk.regular.xs,
  lineHeight: '2.7rem',
};

const menuItemSelected = {
  ...styling.typography.theme.atlasGrotesk.medium.xs,
};

const pageTitle = {
  ...styling.typography.theme.atlasGrotesk.bold['3xl'],
  marginBottom: styling.sizes.context.gutteringW,
};

const accountName = {
  ...styling.typography.theme.atlasGrotesk.bold.xl,
};

const accountLevel = {
  ...styling.typography.theme.atlasGrotesk.regular.xs,
  lineHeight: '2.7rem',
};

const panelTitle = {
  ...styling.typography.theme.atlasGrotesk.bold['2xl'],
};

const panelBody = {
  ...styling.typography.theme.atlasGrotesk.regular.xs,
  lineHeight: '2.7rem',
};

const mmmHeading = {
  ...styling.typography.theme.atlasGrotesk.medium['2xl'],
  [styling.container.context.drawer.desktopUpString]: {
    ...styling.typography.theme.atlasGrotesk.medium['4xl'],
  },
};

const mmmQuoteBody = {
  ...styling.typography.theme.atlasGrotesk.regular.xs,
  lineHeight: '2.7rem',
  fontStyle: 'italic',
};

const mmmQuoteHeading = {
  ...styling.typography.theme.atlasGrotesk.medium.xl,
  fontStyle: 'italic',
};

const mmmQuoteBy = {
  ...styling.typography.theme.atlasGrotesk.bold.xs,
  fontStyle: 'italic',
};

const mmmQuoteByPosition = {
  ...styling.typography.theme.atlasGrotesk.regular.xs,
  lineHeight: '2.7rem',
  fontStyle: 'italic',
};

const mmmHowTitle = {
  ...styling.typography.theme.atlasGrotesk.bold.xl,
  [styling.breakpoints.context.tabletUpString]: {
    ...styling.typography.theme.atlasGrotesk.bold['3xl'],
  },
};

const carouselLabel = {
  fontFamily: styling.fonts.theme.redHatMono.fontFamily,
  fontWeight: styling.fonts.theme.redHatMono.weights.medium,
  fontSize: '1.4rem',
  lineHeight: '2.34rem',
  letterSpacing: styling.fonts.utils.tracking(14, -25),
  whiteSpace: 'nowrap',
  textTransform: 'uppercase',
};

const nullStateTitle = {
  ...styling.typography.theme.atlasGrotesk.bold.lg,
  [styling.breakpoints.context.tabletUpString]: {
    ...styling.typography.theme.atlasGrotesk.bold['2xl'],
  },
};

const nullStateBody = {
  ...styling.typography.theme.atlasGrotesk.regular.xs,
};

const onboardingTitle = {
  ...styling.typography.theme.atlasGrotesk.bold.lg,
  [styling.breakpoints.context.tabletUpString]: {
    ...styling.typography.theme.atlasGrotesk.bold['2xl'],
  },
};

const onboardingBody = {
  ...styling.typography.theme.atlasGrotesk.regular.xs,
};

const accountSettingBannerTitle = {
  ...styling.typography.theme.atlasGrotesk.bold.sm,
};

const accountSettingBannerBody = {
  ...styling.typography.theme.atlasGrotesk.regular.xs,
};

const cardWideTitle = {
  ...styling.typography.theme.atlasGrotesk.bold['4xl'],
  fontSize: '7.27rem',
  lineHeight: '6.42rem',
  textTransform: 'uppercase',
};

const cardWideBody = {
  ...styling.typography.theme.atlasGrotesk.regular.lg,
  fontSize: '2.7rem',
  lineHeight: '3.24rem',
};

const cardWideSlug = {
  ...styling.typography.theme.atlasGrotesk.bold['2xl'],
  fontSize: '3.57rem',
  lineHeight: '2.48rem',
  textTransform: 'uppercase',
};

const cardTooltip = {
  ...styling.typography.theme.atlasGrotesk.regular['3xs'],
};

const typographyConstants = {
  inherit,
  body,
  bodySmall,
  articleHeading2,
  articleHeading3,
  articleHeading4,
  articleHeading5,
  articleBody,
  articleQuoteBody,
  articleQuoteHeading,
  articleByLineTitle,
  articleCaption,
  playCaption,
  formTitle,
  formSubtitle,
  heading1,
  heading3,
  heading4,
  heading5,
  heading6,
  navLink,
  footerLink,
  alertTitle,
  alertBody,
  toastTitle,
  toastBody,
  input,
  inputSmall,
  inputLabel,
  inputLabelRequiredIndicator,
  inputErrors,
  inputHelper,
  inputDropdown,
  editModalFooterTitle,
  tooltip,
  inlineAction,
  cardTitle,
  cardTitleFeatured,
  cardBody,
  cardBodyHover,
  cardBodyFeatured,
  cardSlug,
  cardSlugFeatured,
  cardMenuAction,
  pill,
  boardProfileName,
  boardProfilePosition,
  boardProfileLink,
  mobileNavigationLink,
  flowStepTitle,
  flowStepBody,
  flowStepElementsDropdown,
  menuItem,
  menuItemSelected,
  pageTitle,
  accountName,
  accountLevel,
  panelTitle,
  panelBody,
  mmmHeading,
  mmmQuoteBody,
  mmmQuoteHeading,
  mmmQuoteBy,
  mmmQuoteByPosition,
  mmmHowTitle,
  uploadTitle,
  uploadBody,
  carouselLabel,
  nullStateTitle,
  nullStateBody,
  onboardingTitle,
  onboardingBody,
  accountSettingBannerBody,
  accountSettingBannerTitle,
  cardWideTitle,
  cardWideBody,
  cardWideSlug,
  cardTooltip,
} as const;

export default typographyConstants;
