import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0006: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 102 98"
		height={98}
		width={102}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M32.24 66.19c6.93 6.93 7.23 14.45 2.09 23.01L9.22 64.09c8.56-5.14 16.08-4.84 23.01 2.09M61.64 36.78 97.47.95M56.57 41.85l9.44-9.43M32.24 66.19l18.42-18.42M24.42 80.31 7.68 97.05l-6.3-6.31 11.77-11.77" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M69.77 66.19c6.93-6.93 14.46-7.23 23.01-2.09L67.67 89.21c-5.14-8.56-4.84-16.08 2.09-23.01M69.76 66.19 4.53.95M83.89 74.01l16.73 16.73-6.3 6.31-11.99-12" />
    </g>
  </CardIcon>
);

export default memo(Fr0006);
