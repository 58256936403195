import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Fire: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 22 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M17.97 13.38c-.55 1.85-1.55 1.73-1.55 1.73-2.12 0-1.14-3.28-1.14-3.28C16.86 5.3 8.91 1.38 8.91 1.38s1.57 4.27-1.05 6.94c-2.28 2.33-6.34 5.46-6.34 9.69 0 1.05-.31 6.04 7.48 8.61" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M12.24 26.62c13.83-3.9 6.08-16.26 6.08-16.26" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M9.69 15.34c.6-2.33 2.09-3.49 2.09-3.49-.75 3.56 1.59 5.3 1.59 5.3 2.14 1.8 2.39 3.11 2.29 4.46-.11 1.35-.53 3.23-3.42 5M10.42 21.32c.17-3.24-2.93-5.71-2.93-5.71s.99 1.88-.7 3.86c0 0-1.59 1.52-.1 4.07" />
    </g>
  </WebsiteIcon>
);

export default memo(Fire);
