import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    assetContainer: {
      width: styling.sizes.context.tile.width,
      height: styling.sizes.context.tile.height,

      // Center the images within the asset containers:
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    assetImage: {
      width: '100%', // Ensure the image fills the container
      height: '100%', // Ensure the image fills the container
      objectFit: 'cover', // Ensure the aspect ratio is maintained
      margin: 0, // Remove the margin at the bottom of the image
      boxShadow: styling.shadows.theme.transparent,
      cursor: 'pointer',
      pointerEvents: 'all',
      transition: styling.transitions.utils.generate(['box-shadow']),
      '&:hover:not($assetSelected)': {
        boxShadow: styling.shadows.utils.boxShadowToString(
          styling.shadows.theme.secondaryXs
        ),
      },
    },
    assetSelected: {
      boxShadow: styling.shadows.utils.boxShadowToString(
        styling.shadows.theme.secondarySm
      ),
    },
  },
  { name: 'catalogueItems' }
);
