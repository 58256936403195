import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    label: {
      textAlign: 'left',
      marginBottom: 6,
      display: 'block',
    },
    'theme-primary': {
      color: styling.colors.theme.secondary,
    },
    'theme-secondary': {
      color: styling.colors.theme.primary400,
    },
    disabled: {
      opacity: 0.5,
    },
  },
  { name: 'inputLabel' }
);
