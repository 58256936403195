import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const ReorderVertical: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M14 1.27v9.55M14 26.73v-9.55M1.27 10.82h25.46M1.27 17.18h25.46M10.82 4.45 14 1.27l3.18 3.18M10.82 23.55 14 26.73l3.18-3.18" />
    </g>
  </WebsiteIcon>
);

export default memo(ReorderVertical);
