import { useMemo } from 'react';

import { useQueryParamCapture } from '../../../../contexts/QueryParamCaptureContext';
import { ExternalLinkWithCapturedQueryParamsProps } from '../../types';
import { getHrefWithQueryParams } from './logic';

const useExternalLinkWithCapturedQueryParams = (
  href: ExternalLinkWithCapturedQueryParamsProps['href']
) => {
  const { getCapturedQueryParams } = useQueryParamCapture();

  const hrefWithQueryParams = useMemo(
    () => getHrefWithQueryParams(getCapturedQueryParams, href),
    [href, getCapturedQueryParams]
  );

  return {
    hrefWithQueryParams,
  };
};

export default useExternalLinkWithCapturedQueryParams;
