import { useMemo } from 'react';

import { getCalcedValue } from '../../logic';

const useCardIcon = (
  height: string | number | undefined,
  width: string | number | undefined,
  scale: number
) => {
  const calcedHeight = useMemo(
    () => getCalcedValue(height, scale),
    [height, scale]
  );
  const calcedWidth = useMemo(
    () => getCalcedValue(width, scale),
    [width, scale]
  );

  return {
    calcedHeight,
    calcedWidth,
  };
};

export default useCardIcon;
