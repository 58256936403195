import MiniSearch from 'minisearch';
import { EMPTY_OBJ } from '@kitted/shared-utils';

import { stopWords } from './constants';
import { Fuzzy, SearchResult, TextSearchSchema, Weightings } from './types';

const proccessIndexingTerm = (term: string) =>
  stopWords.has(term) ? null : term.toLowerCase();

const processSearchTerm = (term: string) => term.toLowerCase();

export const createDb = (schema: TextSearchSchema) =>
  new MiniSearch({
    ...schema,
    processTerm: proccessIndexingTerm,
    searchOptions: {
      processTerm: processSearchTerm,
    },
  });

/* eslint-disable @typescript-eslint/no-explicit-any */
export const handleInsertBatch = (
  db: MiniSearch,
  batchItems: any[],
  reset: boolean
) => {
  if (reset) {
    db.removeAll();
  }
  db.addAll(batchItems);
};

export const handleUpsert = (
  db: MiniSearch,
  item: any,
  schema: TextSearchSchema
) => {
  if (db.has(item[schema.idField])) {
    db.replace(item);
    return;
  }
  db.add(item);
};

export const handleSearch = (
  db: MiniSearch,
  term: string,
  idSet: Set<string>,
  minSearchCharacters: number,
  weightings: Weightings = EMPTY_OBJ as Weightings,
  fuzzy: Fuzzy = 1.4
): SearchResult[] => {
  // return everything if the search term is too short
  if (term.length <= minSearchCharacters) {
    return [...idSet];
  }
  const results = db.search(term, {
    prefix: true,
    boost: weightings,
    fuzzy,
  });

  return results.map((resultItem) => resultItem.id);
};
