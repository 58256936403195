import { Dispatch, SetStateAction } from 'react';
import { clearTimeout, setTimeout } from 'worker-timers';

import { ToastApi, ToastTheme } from '../../contexts/ToastContext/types';
import { toastTimerTheme } from './constants';
import { ToastProps } from './types';

export const handleOnClose = (setIsIn: (isIn: boolean) => void) => {
  setIsIn(false);
};

export const handleMouseEnterLeave = (
  setIsHovered: Dispatch<SetStateAction<boolean>>,
  hoverState: boolean
) => {
  setIsHovered(hoverState);
};

export const handleAutoClose = ({
  isIn,
  isHovered,
  duration,
  onClose,
  setCloseTimerRef,
  closeTimerRef,
}: {
  isIn: boolean;
  isHovered: boolean;
  duration: number;
  onClose: () => void;
  setCloseTimerRef: (ref: number | undefined) => void;
  closeTimerRef?: number;
}) => {
  if (isHovered || !isIn || duration < 0) {
    // cancel close timer
    if (closeTimerRef) {
      clearTimeout(closeTimerRef);
      setCloseTimerRef(undefined);
    }
    return;
  }
  // start close timer
  const closeTimer = setTimeout(onClose, duration);
  setCloseTimerRef(closeTimer);
};

export const handleOnMount = (
  setIsHovered: Dispatch<SetStateAction<boolean>>,
  closeTimerRef: number | undefined,
  setCloseTimerRef: (ref: number | undefined) => void
) => {
  setTimeout(() => {
    setIsHovered(false);
  }, 0);

  return () => {
    if (closeTimerRef) {
      clearTimeout(closeTimerRef);
      setCloseTimerRef(undefined);
    }
  };
};

export const handleOnExited = (
  id: ToastProps['id'],
  deregisterToast: ToastApi['deregisterToast']
) => {
  deregisterToast(id);
};

export const getTimerThemeForTheme = (theme: ToastTheme) =>
  toastTimerTheme[theme];
