import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0035: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 92 68"
		height={68}
		width={92}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M6.43 66.26c-2.67 0-4.86-2.18-4.86-4.84V6.6c0-2.68 2.19-4.86 4.86-4.86h79.15c2.67 0 4.86 2.19 4.86 4.86v54.83c0 2.67-2.19 4.84-4.86 4.84H6.43Z" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="m34.79 17.04 29.32 16.97-29.32 16.96z" />
    </g>
  </CardIcon>
);

export default memo(Fr0035);
