import { useMemo } from 'react';

import {
  FormStates,
  useFormData,
} from '../../../../../../contexts/FormContext';
import { getIsDisabled, getIsLoading } from './logic';

const useFormSubmitButtonManagement = () => {
  const { formState } = useFormData();

  const isDisabled = useMemo(() => getIsDisabled(formState), [formState]);

  const isLoading = useMemo(() => getIsLoading(formState), [formState]);

  const isSubmitting = useMemo(
    () => formState === FormStates.SUBMITTING,
    [formState]
  );

  return {
    isDisabled,
    isLoading,
    isSubmitting,
    type: 'submit' as const,
  };
};

export default useFormSubmitButtonManagement;
