import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    videoWrapper: {
      '--videoEmbedAspectRatio': '16 / 9',
      position: 'relative',
      width: '100%',
      aspectRatio: 'var(--videoEmbedAspectRatio)',
      margin: [60, 0],
      overflow: 'hidden',
    },
    videoWrapperVideo: {
      borderRadius: styling.sizes.context.cardFeature.radius,
    },
    videoWrapperFullbleed: {
      '--videoEmbedAspectRatio': '9 / 7',
      margin: [30, 0],
      borderRadius: 0,
      [styling.breakpoints.context.tabletUpString]: {
        '--videoEmbedAspectRatio': '21 / 9',
        margin: [120, 0],
      },
    },
    videoInner: {
      ...styling.utils.absoluteFull(),
      display: 'flex',
    },
    videoPlayer: {
      flexGrow: 1,
      display: 'flex',
    },
    loading: {
      ...styling.utils.absoluteFull(),
    },
  },
  { name: 'videoEmbed' }
);
