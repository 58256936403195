import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const CardArrowDown: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 22 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M11 7.11v13.78M15.31 16.58 11 20.89l-4.31-4.31" /><rect vectorEffect="non-scaling-stroke" width="19.7" height="25.24" x="1.15" y="1.38" stroke={color} rx="2.57" ry="2.57" /><rect vectorEffect="non-scaling-stroke" width="19.7" height="25.24" x="1.15" y="1.38" stroke={color} rx="2.57" ry="2.57" />
    </g>
  </WebsiteIcon>
);

export default memo(CardArrowDown);
