import { Route } from '@kitted/website-constants';

import { MenuItem } from './types';

export const constructMenuItem = (
  route: Route,
  icon?: React.ElementType,
  highlightSubroutes = false
): MenuItem => ({
  route,
  icon,
  highlightSubroutes,
});
