import { useCallback, useEffect } from 'react';

const useSetTouchOnlyDevice = () => {
  const onTouchOnlyDeviceChange = useCallback((e: MediaQueryListEvent) => {
    const isTouchOnlyDevice = e.matches;
    if (isTouchOnlyDevice) {
      document.documentElement.classList.add('touch-only-device');
    } else {
      document.documentElement.classList.remove('touch-only-device');
    }
  }, []);

  const setTouchOnlyDevice = useCallback(() => {
    const mql = window?.matchMedia('(pointer: coarse)');
    mql?.addEventListener('change', onTouchOnlyDeviceChange);
    if (mql?.matches) {
      document.documentElement.classList.add('touch-only-device');
    }
    return () => {
      mql?.removeEventListener('change', onTouchOnlyDeviceChange);
    };
  }, [onTouchOnlyDeviceChange]);

  useEffect(() => {
    setTouchOnlyDevice();
  }, [setTouchOnlyDevice]);
};

export default useSetTouchOnlyDevice;
