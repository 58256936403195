import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0030: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 140 124"
		height={124}
		width={140}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M107.24 99.08a45.23 45.23 0 0 1-27.88 9.55c-25.1 0-45.45-20.35-45.45-45.44s20.35-45.45 45.45-45.45c6.8 0 13.26 1.49 19.06 4.17" /><path vectorEffect="non-scaling-stroke" d="M138.31 62.35c0 32.72-26.52 59.25-59.27 59.25S19.78 95.08 19.78 62.35 46.31 3.1 79.04 3.1c9.9 0 19.05 2.36 27.25 6.65l3.39-6.08c1.33-2.47 5.25-.96 5.34 1.36l6.46 19.03c.46 1.64-.1 3.77-1.74 4.23l-18.31 6.06" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M102.77 48.84v28.83l-24.9 10.6V59.21zM77.87 59.21 53.19 48.84v28.83l24.68 10.6M53.19 48.84 77.3 38.09l25.47 10.75" /><path vectorEffect="non-scaling-stroke" d="m66 43.56 24.69 10.43v8.34" /></g>
    </g>
  </CardIcon>
);

export default memo(In0030);
