/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';

import { PLAYER_STATE_EVENTS } from '../../constants';
import {
  generateConfigFromTracks,
  handleAutoPlay,
  handleTogglePlaying,
} from '../../logic';
import {
  MediaPlayerProps,
  MediaPlayerType,
  PlayerStateEventsValues,
} from '../../types';

const useMediaPlayerControls = ({
  playerType,
  tracks,
  additionalConfig,
  initialState = {
    playing: false,
    muted: false,
  },
  onStateChange = () => {
    // void fn
  },
  onComplete = () => {
    // void fn
  },
  controlledPlaying,
}: {
  playerType: MediaPlayerType;
  tracks: MediaPlayerProps['tracks'];
  initialState: MediaPlayerProps['initialState'];
  additionalConfig: MediaPlayerProps['additionalConfig'];
  onStateChange: MediaPlayerProps['onStateChange'];
  onComplete: MediaPlayerProps['onComplete'];
  controlledPlaying: MediaPlayerProps['playing'];
}) => {
  const playerRef = useRef<ReactPlayer>(null);
  const configRef = useRef<any>(
    generateConfigFromTracks(tracks, additionalConfig)
  );
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(initialState.muted ?? false);

  const fireStateChangeEvent = useCallback(
    (event: PlayerStateEventsValues, value: boolean) => {
      onStateChange(event, value);
    },
    [onStateChange]
  );

  useEffect(() => {
    if (typeof controlledPlaying !== 'undefined') {
      setPlaying(controlledPlaying);
    }
  }, [controlledPlaying]);

  useEffect(() => {
    fireStateChangeEvent(PLAYER_STATE_EVENTS.PLAYING, playing);
  }, [playing, fireStateChangeEvent]);

  useEffect(() => {
    fireStateChangeEvent(PLAYER_STATE_EVENTS.MUTED, muted);
  }, [muted, fireStateChangeEvent]);

  const handleComplete = useCallback(() => {
    onComplete();
    fireStateChangeEvent(PLAYER_STATE_EVENTS.COMPLETED, true);
  }, [onComplete, fireStateChangeEvent]);

  const togglePlaying = useCallback(() => {
    handleTogglePlaying(playing, setPlaying);
  }, [playing, setPlaying]);

  const onReady = useCallback(() => {
    fireStateChangeEvent(PLAYER_STATE_EVENTS.READY, true);
    handleAutoPlay({
      autoPlay: initialState.playing,
      playerRef: playerRef?.current,
      setPlaying,
      setMuted,
      playerType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPlaying, fireStateChangeEvent, playerType]);

  return {
    config: configRef.current,
    muted,
    onReady,
    handleComplete,
    playerRef,
    playing,
    togglePlaying,
    fireStateChangeEvent,
  };
};

export default useMediaPlayerControls;
