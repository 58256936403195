import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0022: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 108 104"
		height={104}
		width={108}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M104.35 38.04c0 .62.5 1.11 1.11 1.11s1.12-.5 1.12-1.11a1.116 1.116 0 0 0-2.23 0M96.12 5.92c0 .62.5 1.11 1.11 1.11s1.12-.5 1.12-1.11a1.116 1.116 0 0 0-2.23 0M63.33 2.11c0 .62.5 1.11 1.11 1.11s1.12-.5 1.12-1.11a1.116 1.116 0 0 0-2.23 0M40.87 29.67c-4.27-9.68-3.29-8.7-12.97-12.96 9.68-4.27 8.7-3.29 12.97-12.97 4.26 9.68 3.29 8.7 12.96 12.96-9.68 4.27-8.7 3.29-12.96 12.97M92.7 68.65c-3.26-7.4-2.52-6.66-9.92-9.92 7.4-3.26 6.66-2.52 9.92-9.92 3.26 7.4 2.52 6.66 9.92 9.92-7.4 3.26-6.66 2.51-9.92 9.92M79.39 41.46c-5.28-11.99-4.07-10.78-16.06-16.06 11.99-5.28 10.78-4.07 16.06-16.06 5.28 11.99 4.07 10.78 16.06 16.06-11.99 5.28-10.78 4.07-16.06 16.06" /></g><g stroke={color} ><rect vectorEffect="non-scaling-stroke" width="12.1" height="75.98" x="25.94" y="34.44" rx="1.38" ry="1.38" transform="rotate(45 31.985 72.439)" /><path vectorEffect="non-scaling-stroke" d="m44.1 51.77 8.55 8.55" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0022);
