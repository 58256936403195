import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    input: {
      background: styling.colors.theme.primary900,
    },
  },
  { name: 'searchTermInput' }
);
