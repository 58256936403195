import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const CircleQuestionMark: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M26.24 14c0 6.76-5.48 12.24-12.24 12.24S1.76 20.76 1.76 14 7.24 1.76 14 1.76 26.24 7.24 26.24 14Z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M10.98 11.29c0-1.46 1.35-2.65 3.02-2.65s3.02 1.19 3.02 2.65c0 0 .07 1.2-1.5 2.28-1.04.81-1.86 2.09-1.86 3.54M13.92 18.73c.13.13.13.33 0 .46s-.33.13-.45 0a.32.32 0 0 1 0-.46c.13-.13.33-.13.45 0Z" />
    </g>
  </WebsiteIcon>
);

export default memo(CircleQuestionMark);
