import { memo } from 'react';
import { useNavigation } from 'react-router-dom';
import { ImageSkeleton } from '@kitted/shared-components';

import DrawerSpacePortal from '../../DrawerSpace/DrawerSpacePortal';
import useStyles from './styles';

const RouteLoader = () => {
  const navigation = useNavigation();
  const styles = useStyles();

  if (navigation.state === 'idle') return null;

  return (
    <DrawerSpacePortal>
      <div className={styles.container}>
        <ImageSkeleton className={styles.imageSkeleton} theme="secondary" />
      </div>
    </DrawerSpacePortal>
  );
};

export default memo(RouteLoader);
