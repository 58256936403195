import { memo } from 'react';
import { Panel, Typography } from '@kitted/shared-components';

import FormFields from '../../../../../../../components/FormFields';
import PreviewCard from '../../../../../../../components/PreviewCard';
import SplitContent from '../../../../../../../components/SplitContent';
import { ImageResourceEditSpaceProps } from '../types';
import useImageResourceEditPageContentPageSection from './hooks/useImageResourceEditPageContentPageSection';
import { layoutSchema } from './constants';

const ImageResourceEditPageContentPageSection = ({
  imageResource,
}: ImageResourceEditSpaceProps) => {
  const { overrideCardProps } = useImageResourceEditPageContentPageSection();

  return (
    <SplitContent
      left={
        <PreviewCard
          cardId={imageResource?.id}
          overrideCardProps={overrideCardProps}
        />
      }
    >
      <Panel theme="primary">
        <Typography variant="formTitle">Image resource details</Typography>
        <FormFields layout={layoutSchema} theme="primary" />
      </Panel>
    </SplitContent>
  );
};

export default memo(ImageResourceEditPageContentPageSection);
