import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0045: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 90 104"
		height={104}
		width={90}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="m63.17 102.13-28.84-5.46a5.165 5.165 0 0 1-4.08-6.04l.15-.78c.54-2.78 3.26-4.62 6.04-4.08l13.08 2.45-6.93-10.14-14.3-20.93c-1.6-2.34-.99-5.57 1.35-7.17l.35-.24c2.34-1.6 5.57-.99 7.17 1.35l10.92 15.99 18.16-8.45s6.05-2.57 10.82 4.41l11.78 20.57" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M38.55 80.78H4.74c-1.97 0-3.57-1.71-3.57-3.68V5.44c0-1.96 1.61-3.57 3.57-3.57h36.49c1.96 0 3.57 1.61 3.57 3.57V53.9M20.02 72.9h5.93" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0045);
