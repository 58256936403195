import { useAssetCatalogueData } from '../../../contexts/AssetCatalogueProvider/hooks';
import useSearchCatalogueItems from '../../../hooks/useSearchCatalogueItems';

const useGalleryItems = () => {
  const { galleryItems, searchText } = useAssetCatalogueData();
  const filteredItems = useSearchCatalogueItems(galleryItems, searchText);

  return filteredItems;
};

export default useGalleryItems;
