import { useCallback } from 'react';
import { NOOP } from '@kitted/shared-utils';

import useAuthModal from '../../../../../../hooks/useAuthModal';
import { ForgotPasswordLinkProps } from '../../types';

const useForgotPasswordLink = (
  onClick: ForgotPasswordLinkProps['onClick'] = NOOP
) => {
  const { launchForgotPasswordModal } = useAuthModal();

  const handleOnClick = useCallback(() => {
    onClick();
    launchForgotPasswordModal();
  }, [onClick, launchForgotPasswordModal]);

  return {
    handleOnClick,
  };
};

export default useForgotPasswordLink;
