import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    verify: {
      marginBottom: 32,
    },
  },
  { name: 'authVerifyUserModalContent' }
);
