import speeds from './speeds';
import { Transition } from './types';

// easing
const linear = 'linear';
const easeInOut = 'ease-in-out';

const generate = (...args: Transition[]) =>
  args
    .map(
      ([
        property,
        speed = speeds.jog,
        easing = easeInOut,
        delay = speeds.teleport,
      ]: Transition) => `${property} ${speed} ${easing} ${delay}`
    )
    .join(',');

export default {
  easing: {
    linear,
    easeInOut,
  },
  utils: {
    generate,
  },
};
