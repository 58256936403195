import { memo } from 'react';

import useMainMenuDrawer from './hooks/useMainMenuDrawer';
import AccountMenu from './AccountMenu';
import HeaderMenu from './HeaderMenu';

const MainMenuDrawer = () => {
  useMainMenuDrawer();

  return (
    <>
      <HeaderMenu />
      <AccountMenu />
    </>
  );
};

export default memo(MainMenuDrawer);
