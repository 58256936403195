import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const EyeClosed: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 18"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <g stroke={color} data-name="Layer 1-2"><path vectorEffect="non-scaling-stroke" d="m6.3 16.74 15.4-15.4M16.46 7.26c0 1.36-1.1 2.46-2.46 2.46-.21 0-.41-.03-.6-.08l-2.59 2.59a5.93 5.93 0 0 0 9.12-5c0-1.18-.34-2.27-.93-3.19l-2.62 2.62c.05.19.08.39.08.6" /><path vectorEffect="non-scaling-stroke" d="m19.57 3.47-.58.58a5.93 5.93 0 0 1-5 9.12c-1.18 0-2.27-.34-3.19-.93L8.44 14.6c1.67.83 3.56 1.42 5.55 1.42 6.97 0 12.62-6.96 12.62-6.96s-2.87-3.54-7.05-5.57h0Z" /><path vectorEffect="non-scaling-stroke" d="M14 9.72c1.36 0 2.46-1.1 2.46-2.46 0-.21-.03-.41-.08-.6L13.4 9.64c.19.05.39.08.6.08M9.08 10.54c-.63-.94-1-2.08-1-3.3 0-1.8.8-3.41 2.06-4.49-5.08 1.7-8.76 6.11-8.76 6.11s1.93 2.43 4.92 4.46M14.71 4.91c-.22-.07-.46-.1-.7-.1a2.458 2.458 0 0 0-2.36 3.16" /><path vectorEffect="non-scaling-stroke" d="M11.65 7.97c-.07-.22-.1-.46-.1-.7a2.458 2.458 0 0 1 3.16-2.36" /><path vectorEffect="non-scaling-stroke" d="M17.1 2.52c-.99-.28-2.03-.44-3.1-.44-1.35 0-2.65.25-3.86.66a5.93 5.93 0 0 0-1.06 7.79" /></g>
    </g>
  </WebsiteIcon>
);

export default memo(EyeClosed);
