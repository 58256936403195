import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0016: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 88 90"
		height={90}
		width={88}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M44 56.62c0 10.54 7.51 19.35 17.47 21.37 2.47.5 4.33 2.52 4.33 5.04h0c0 2.82-2.29 5.11-5.11 5.11H57.5c-2.82 0-5.11-2.29-5.11-5.11V81.6M73.74 79.62v1.83a6.68 6.68 0 0 0 6.68 6.68h0a6.68 6.68 0 0 0 6.68-6.68h0c0-6.32-5.12-11.43-11.43-11.43H65.8c-7.4 0-13.4-6-13.4-13.4h0v-1.8M44 56.62c0 10.54-7.51 19.35-17.47 21.37-2.47.5-4.33 2.52-4.33 5.04h0c0 2.82 2.29 5.11 5.11 5.11h3.19c2.82 0 5.11-2.29 5.11-5.11V81.6M14.26 79.62v1.83a6.68 6.68 0 0 1-6.68 6.68h0A6.68 6.68 0 0 1 .9 81.45h0c0-6.32 5.12-11.43 11.43-11.43h9.87c7.4 0 13.4-6 13.4-13.4h0v-1.8" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M17.72 55.27V52c0-2.41-1.95-4.36-4.36-4.36h0c-3.88 0-7.03 3.15-7.03 7.03h0c0 3.88 3.15 7.03 7.03 7.03h6.99c3.88 0 7.03-3.15 7.03-7.03h0c0-2.88-1.01-5.63-2.75-7.92-4.19-5.5-6.9-12.27-6.9-18.92.01-14.34 11.63-25.97 25.98-25.97s25.97 11.63 25.97 25.97c0 6.59-2.46 13.49-6.5 19.03-1.66 2.27-2.57 5-2.57 7.82h0c0 3.88 3.15 7.03 7.03 7.03h6.99c3.88 0 7.03-3.15 7.03-7.03h0c0-3.88-3.15-7.03-7.03-7.03h0c-2.41 0-4.36 1.95-4.36 4.36v3.27" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M61.28 27.83c0-9.69-7.88-17.57-17.57-17.57" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M39.8 44.87v4.2M48.2 44.87v4.2" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0016);
