import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Anchor: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 22 26"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" fill={color} d="M20.12 18.99c-.7-.7-1.87-.7-2.57 0-.86.86-1.85 1.53-2.95 1.99-.58.24-1.18.42-1.81.56v-4.66h1.89c1 0 1.83-.82 1.83-1.83s-.82-1.83-1.83-1.83h-1.89v-1.85c2.15-.76 3.71-2.81 3.71-5.22 0-3.05-2.47-5.52-5.52-5.52S5.46 3.1 5.46 6.15c0 2.41 1.55 4.46 3.71 5.22v1.85H7.28c-1 0-1.83.82-1.83 1.83s.82 1.83 1.83 1.83h1.89v4.66c-.62-.12-1.22-.3-1.81-.56a9.1 9.1 0 0 1-2.95-1.99c-.7-.7-1.87-.7-2.57 0s-.7 1.87 0 2.57c1.18 1.18 2.57 2.13 4.12 2.77q2.415 1.02 5.04 1.02c2.625 0 3.43-.34 5.04-1.02 1.55-.64 2.93-1.59 4.12-2.77.7-.7.7-1.87 0-2.57zm-9.16-14.7c1.04 0 1.89.84 1.89 1.89s-.84 1.89-1.89 1.89-1.89-.84-1.89-1.89.84-1.89 1.89-1.89" data-name="Layer_1-2" />
    </g>
  </WebsiteIcon>
);

export default memo(Anchor);
