import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const TriangleExclaim: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 26 24"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M2.87 22.71c-1.91 0-2.68-1.33-1.73-2.96L11.25 2.51c.95-1.63 2.51-1.63 3.47 0l10.09 17.24c.95 1.63.17 2.96-1.73 2.96z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M13.29 17.32c0 .17-.14.31-.32.31s-.32-.14-.32-.31.14-.31.32-.31.32.14.32.31Z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M12.98 9.42v5.44m.31 2.46c0 .17-.14.31-.32.31s-.32-.14-.32-.31.14-.31.32-.31.32.14.32.31Z" />
    </g>
  </WebsiteIcon>
);

export default memo(TriangleExclaim);
