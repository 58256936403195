import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { RedirectToProps } from '../../types';
import { calculateRedirectPath } from './logic';

const useRedirectTo = (to: RedirectToProps['to']) => {
  const params = useParams();
  const redirectPath = useMemo(
    () => calculateRedirectPath(to, params),
    [to, params]
  );

  return {
    redirectPath,
  };
};

export default useRedirectTo;
