import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0026: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 94 118"
		height={118}
		width={94}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><g stroke={color}><path vectorEffect="non-scaling-stroke" d="M13.3 92.55h66.82M6.41 116.62c-4.06 0-5.72-2.88-3.69-6.4l40.61-70.15c2.04-3.52 5.36-3.52 7.4 0l40.55 70.15c2.03 3.52.37 6.4-3.69 6.4z" /></g><g stroke={spot}><path vectorEffect="non-scaling-stroke" d="M63.48 7.54h15.17l-7.06 7.81 7.06 7.8H56.18l6.28-6.16V1.38H47v31.39M47.54 16.95h14.82" /></g></g><path vectorEffect="non-scaling-stroke" stroke={color} d="m68.63 71.28-14.52-8.1-6.32 13.78-6.33-13.78-16.99 9.47" />
    </g>
  </CardIcon>
);

export default memo(Ac0026);
