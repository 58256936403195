import { memo } from 'react';

import { StoreSectionInitializerProps } from '../types';
import useUserStoreInitializer from './hooks/useUserStoreInitializer';

const UserStoreInitializer = ({
  onSectionLoaded,
}: StoreSectionInitializerProps) => {
  useUserStoreInitializer(onSectionLoaded);

  return null;
};

export default memo(UserStoreInitializer);
