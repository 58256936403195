import { memo } from 'react';

import useAnimatedValue from './hooks/useAnimatedValue';
import { AnimatedValueProps } from './types';

const AnimatedValue = ({
  children,
  from,
  to,
  duration,
  isPlaying = true,
}: AnimatedValueProps) => {
  const value = useAnimatedValue(from, to, duration, isPlaying);

  return <>{children(value)}</>;
};

export default memo(AnimatedValue);
