import { createContext, useMemo } from 'react';
import { NOOP } from '@kitted/shared-utils';

import { ToastContextProps } from '../types';
import useToastManagement from './hooks/useToastManagement';
import { ToastApi, ToastData } from './types';

export const ToastContext = createContext<ToastApi>({
  registerToast: NOOP,
  deregisterToast: NOOP,
  updateToast: NOOP,
  registerToastCallback: NOOP,
  deregisterToastCallback: NOOP,
  publishToastEvent: NOOP,
});

export const ToastDataContext = createContext<ToastData>({
  toasts: [],
  eventListeners: [],
});

export const ToastProvider: React.FC<ToastContextProps> = ({ children }) => {
  const {
    toasts,
    registerToast,
    deregisterToast,
    updateToast,
    registerToastCallback,
    deregisterToastCallback,
    publishToastEvent,
  } = useToastManagement();

  const apiValue = useMemo(
    () => ({
      registerToast,
      deregisterToast,
      updateToast,
      registerToastCallback,
      deregisterToastCallback,
      publishToastEvent,
    }),
    [
      registerToast,
      deregisterToast,
      updateToast,
      registerToastCallback,
      deregisterToastCallback,
      publishToastEvent,
    ]
  );

  return (
    <ToastContext.Provider value={apiValue}>
      <ToastDataContext.Provider value={toasts}>
        {children}
      </ToastDataContext.Provider>
    </ToastContext.Provider>
  );
};
