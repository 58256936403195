import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0003: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 110 46"
		height={46}
		width={110}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="m54.99 23 15.56 15.58c4.16 4.16 9.7 6.46 15.59 6.46s11.42-2.29 15.58-6.46c4.16-4.16 6.45-9.7 6.45-15.58s-2.29-11.42-6.45-15.58S92.02.96 86.14.96c-5.89 0-11.42 2.29-15.58 6.46l-11.6 11.62" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M54.99 23 39.51 7.49s-.05-.05-.07-.08c-8.59-8.59-22.58-8.59-31.17.01-8.59 8.59-8.59 22.57 0 31.16 4.16 4.16 9.7 6.46 15.58 6.46s11.42-2.29 15.58-6.46l11.22-11.24" />
    </g>
  </CardIcon>
);

export default memo(Fr0003);
