import { memo } from 'react';
import clsx from 'clsx';

import useStyles from './styles';
import { PolymorphicContentWrapperProps } from './types';

const ContentWrapper = <C extends React.ElementType = 'div'>({
  as,
  className,
  children,
  id,
  isDisabled = false,
}: PolymorphicContentWrapperProps<C>) => {
  const styles = useStyles();
  const Component = as || 'div';

  return (
    <Component
      className={clsx(
        className,
        styles.containerWrapper,
        !isDisabled && styles.container
      )}
      id={id}
    >
      {children}
    </Component>
  );
};

export default memo(ContentWrapper);
