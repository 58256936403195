import {
  AnyArticleBlockModel,
  AnyArticleBlockSaveModel,
  ArticleMetadataModel,
  ArticleModel,
  ArticleSaveModel,
} from '@kitted/article-service-models';
import { convertArticleModelToArticleCardModel } from '@kitted/card-service-shared';
import {
  GenericHttpCompleteResponse,
  KittedServiceEnum,
} from '@kitted/kitted-models';
import { Nullable } from '@kitted/platform-models';

import useRequests from '../../../contexts/RequestsContext/hooks/useRequests';
import useStoreSection from '../../../contexts/StoreContext/hooks/useStoreSection';
import { METADATA_KEY_SEPARATOR } from '../../metadata/useMetadata/constants';
import useAsyncRequest from '../../useAsyncRequest';
import useAuthedAsyncRequest from '../../useAuthedAsyncRequest';

const useArticleRequests = () => {
  const { updateStoreSectionItem } = useStoreSection('Cards');
  const { updateStoreSectionItem: updateMetadataStoreSectionItem } =
    useStoreSection('Metadata');
  const { platformRequest } = useRequests();

  const [getArticleLatest, articleLatestFetchState] = useAuthedAsyncRequest<
    ArticleModel<false>
  >((id: ArticleModel['id']) =>
    platformRequest(KittedServiceEnum.Article, {
      method: 'GET',
      url: `/v1/article/${id}/latest`,
    })
  );

  const [getArticleBlocksLatest, articleBlocksLatestFetchState] =
    useAuthedAsyncRequest<
      (AnyArticleBlockModel<false> | AnyArticleBlockModel<true>)[]
    >((id: ArticleModel['id']) =>
      platformRequest(KittedServiceEnum.Article, {
        method: 'GET',
        url: `/v1/article/${id}/latest/blocks`,
      })
    );

  const [getArticleLatestBySlug, articleLatestBySlugFetchState] =
    useAuthedAsyncRequest<ArticleModel<false>>((slug: ArticleModel['slug']) =>
      platformRequest(KittedServiceEnum.Article, {
        method: 'GET',
        url: `/v1/article/slug/${slug}/latest`,
      })
    );

  const [getArticleBySlug, articleBySlugFetchState] = useAsyncRequest<
    ArticleModel<false>
  >((slug: ArticleModel['slug']) =>
    platformRequest(KittedServiceEnum.Article, {
      method: 'GET',
      url: `/v1/article/slug/${slug}/published`,
    })
  );

  const [getArticle, articleFetchState] = useAsyncRequest<ArticleModel<false>>(
    (id: ArticleModel['id']) =>
      platformRequest(KittedServiceEnum.Article, {
        method: 'GET',
        url: `/v1/article/${id}/published`,
      })
  );

  const [getArticleBlocks, articleBlocksFetchState] = useAsyncRequest<
    (AnyArticleBlockModel<false> | AnyArticleBlockModel<true>)[]
  >((id: ArticleModel['id']) =>
    platformRequest(KittedServiceEnum.Article, {
      method: 'GET',
      url: `/v1/article/${id}/published/blocks`,
    })
  );

  const [cloneArticle, articleCloneState] = useAuthedAsyncRequest<ArticleModel>(
    (data: ArticleSaveModel) =>
      platformRequest(KittedServiceEnum.Article, {
        method: 'POST',
        data,
        url: `/v1/article/clone`,
      }),
    (response: ArticleModel | undefined) => {
      if (response) {
        updateStoreSectionItem(
          convertArticleModelToArticleCardModel(response.id, response),
          response.id
        );
      }
    }
  );

  const [createArticle, articleCreateState] =
    useAuthedAsyncRequest<ArticleModel>(
      (data: ArticleSaveModel) =>
        platformRequest(KittedServiceEnum.Article, {
          method: 'POST',
          data,
          url: `/v1/article/create`,
        }),
      (response: ArticleModel | undefined) => {
        if (response) {
          updateStoreSectionItem(
            convertArticleModelToArticleCardModel(response.id, response),
            response.id
          );
        }
      }
    );

  const [createArticleDraft, articleDraftCreateState] =
    useAuthedAsyncRequest<ArticleModel>(
      (id: ArticleModel['id'], data: ArticleSaveModel) =>
        platformRequest(KittedServiceEnum.Article, {
          method: 'POST',
          data,
          url: `/v1/article/${id}/draft`,
        })
    );

  const [createArticleAutosave, articleAutosaveCreateState] =
    useAuthedAsyncRequest<ArticleModel>((data: ArticleSaveModel) =>
      platformRequest(KittedServiceEnum.Article, {
        method: 'POST',
        data,
        url: `/v1/article/autosave`,
      })
    );

  const [createArticleBlockAutosave, articleBlockAutosaveCreateState] =
    useAuthedAsyncRequest<AnyArticleBlockModel>(
      (id: ArticleModel['id'], data: AnyArticleBlockSaveModel) =>
        platformRequest(KittedServiceEnum.Article, {
          method: 'POST',
          data,
          url: `/v1/block/article/${id}/autosave`,
        })
    );

  const [publishArticle, articlePublishState] =
    useAuthedAsyncRequest<ArticleModel>((id: ArticleModel['id']) =>
      platformRequest(KittedServiceEnum.Article, {
        method: 'PUT',
        url: `/v1/article/${id}/publish`,
      })
    );

  const [createPublishArticle, articleCreatePublishState] =
    useAuthedAsyncRequest<ArticleModel>(
      (data: ArticleSaveModel) =>
        platformRequest(KittedServiceEnum.Article, {
          method: 'POST',
          data,
          url: '/v1/article/createPublish',
        }),
      (response: ArticleModel | undefined) => {
        if (response) {
          updateStoreSectionItem(
            convertArticleModelToArticleCardModel(response.id, response),
            response.id
          );
        }
      }
    );

  const [getArticleMetadata, getArticleMetadataState] = useAsyncRequest<
    Nullable<ArticleMetadataModel>
  >((articleId: ArticleModel['id']) =>
    platformRequest(KittedServiceEnum.Article, {
      method: 'GET',
      url: `/v1/article/${articleId}/metadata`,
    })
  );

  const [setArticleMetadata, setArticleMetadataState] =
    useAuthedAsyncRequest<GenericHttpCompleteResponse>(
      (articleId: ArticleModel['id'], data: ArticleMetadataModel) =>
        platformRequest(KittedServiceEnum.Article, {
          method: 'POST',
          data,
          url: `/v1/article/${articleId}/metadata`,
        }),
      (
        response: GenericHttpCompleteResponse | undefined,
        articleId: ArticleModel['id'],
        data: ArticleMetadataModel
      ) => {
        if (response?.done) {
          updateMetadataStoreSectionItem(
            data,
            `${KittedServiceEnum.Article}${METADATA_KEY_SEPARATOR}${articleId}`
          );
        }
      }
    );

  return {
    getArticleLatest,
    articleLatestFetchState,
    getArticleBlocksLatest,
    articleBlocksLatestFetchState,
    getArticleLatestBySlug,
    articleLatestBySlugFetchState,
    getArticleBySlug,
    articleBySlugFetchState,
    getArticle,
    articleFetchState,
    getArticleBlocks,
    articleBlocksFetchState,
    cloneArticle,
    articleCloneState,
    createArticle,
    articleCreateState,
    createArticleDraft,
    articleDraftCreateState,
    createArticleAutosave,
    articleAutosaveCreateState,
    createArticleBlockAutosave,
    articleBlockAutosaveCreateState,
    publishArticle,
    articlePublishState,
    createPublishArticle,
    articleCreatePublishState,
    getArticleMetadata,
    getArticleMetadataState,
    setArticleMetadata,
    setArticleMetadataState,
  };
};

export default useArticleRequests;
