import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0050: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 66 130"
		height={130}
		width={66}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m60.36 13.53-54.72.01M60.36 115.95H5.64" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M60.8 56.05H5.39M60.98 73.42H5.47" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M23.44 89.42C11.39 82.34 1.39 75 1.39 64.98c0-25.21 63.23-32.58 63.22-63.26M64.61 128.28c0-11.89-10.01-20.35-22.06-27.81M23.43 29.85C11.38 22.44 1.39 13.92 1.39 1.73" /><path vectorEffect="non-scaling-stroke" d="M1.39 128.28c0-29.89 63.23-38.1 63.23-63.3 0-9.99-9.94-17.18-21.93-24.13" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0050);
