import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0049: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 102 116"
		height={116}
		width={102}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M43.15 45.36H23.03c-12.08-.01-21.97 9.87-21.97 21.95v.84c0 12.08 9.88 21.97 21.97 21.97h5.59v1.72c0 12.36 10.02 22.38 22.38 22.38 10.35 0 19.05-7.02 21.61-16.56" /><path vectorEffect="non-scaling-stroke" d="M63.72 83.27c4.66 4.85 8.7 6.83 15.69 6.83l1.09-.04c11.37-.8 20.43-10.35 20.43-21.91v-.84c0-11.44-8.98-21.88-20.06-21.88H70.05" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="m46.5 62.67 17.27-31.64-18.93 2.46L62.67 1.78" />
    </g>
  </CardIcon>
);

export default memo(Ac0049);
