const config = {
  keys: {
    gCaptcha: '6Ldn_TMlAAAAAGzznr6bxxthK9rvbwEOB1UFWl0z',
    rollbar: '63a7c34a5f794c5ba06e3494d8b692e8',
    amplitude: 'f3bf8b822bef47ccbbff7cbe687cab54',
    posthog: 'phc_lohV7rVKqGmmDzT3ErWQT0SKtJWYQDFONkP8trSGzxq',
  },
};

export default config;
