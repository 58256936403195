import { createUseStyles } from 'react-jss';
import { typographyConstants } from '@kitted/shared-components';

export default createUseStyles(
  {
    image: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '50%',
    },
    imageFeatured: {},
    title: {
      height: `calc(3 * ${typographyConstants.cardTitle.lineHeight})`,
      display: 'flex',
      flexGrow: 0,
      flexDirection: 'column',
      justifyContent: 'center',
      paddingInline: 'var(--cardSkeletonPaddingInline)',
    },
    titleFeatured: {
      position: 'absolute',
      top: 'calc(50% + 21px)',
      left: 0,
      right: 0,
    },
    body: {
      position: 'absolute',
      top: `calc(50% + (3 * ${typographyConstants.cardTitle.lineHeight}) + 29px)`,
      left: 0,
      right: 0,
      paddingInline: 'var(--cardSkeletonPaddingInline)',
    },
    divider: {
      width: 'calc(100% - 72px)',
      opacity: 0.2,
    },
  },
  { name: 'authorCardSkeleton' }
);
