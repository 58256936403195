// Wait for the recaptcha object to become available
// The promise will resolve as soon as needed, keep resolving with the
import { clearInterval, setInterval } from 'worker-timers';

import logger from '../../../services/logger';
import { defaultCaptchaConfig } from './constants';
import {
  CaptchaProps,
  ReCaptcha,
  ReCaptchaWidgetId,
  ReCaptchaWindow,
} from './types';

// cached object.
const waitForRecaptcha = (): Promise<ReCaptcha> =>
  new Promise((resolve) => {
    const interval = setInterval(() => {
      if (
        typeof (window as ReCaptchaWindow)?.grecaptcha?.render === 'function'
      ) {
        clearInterval(interval);
        resolve((window as ReCaptchaWindow).grecaptcha as ReCaptcha);
      }
    }, 1000);
  });

// sus on if we need this or we can use a ref for the element
const waitForElement = (elementID: string): Promise<HTMLElement | null> =>
  new Promise((resolve) => {
    const interval = setInterval(() => {
      if (typeof window !== 'undefined' && document.getElementById(elementID)) {
        clearInterval(interval);
        resolve(document.getElementById(elementID));
      }
    }, 1000);
  });

// script will check if a script with the url already exists before attempting to add it
// this prevents the script being loaded multiple times
const loadScript = (url: string) => {
  if (
    !Array.from(document.querySelectorAll('script')).some(
      (elm) => elm.src === url
    )
  ) {
    const script = document.createElement('script');
    script.setAttribute('src', url);
    script.setAttribute('async', 'async');
    script.setAttribute('defer', 'defer');
    document.getElementsByTagName('head')[0].appendChild(script);
  }
};

export const handleOnMount = async ({
  elementID,
  scriptUrl,
  setRecaptchaRef,
  sitekey,
  setWidgetId,
  onExpiredCallback,
  onVerifyCallback,
  onReady,
}: {
  elementID: string;
  scriptUrl: string;
  setRecaptchaRef: (ref: ReCaptcha) => void;
  sitekey: string;
  setWidgetId: (widgetId: ReCaptchaWidgetId) => void;
  onExpiredCallback: () => void;
  onVerifyCallback: (response: string) => void;
  onReady: CaptchaProps['onReady'];
}) => {
  loadScript(scriptUrl);
  const grecaptcha = await waitForRecaptcha();
  if (!grecaptcha) return;

  const element = await waitForElement(elementID);
  if (!element) return;

  logger.debug('[RECAPTCHA] rendering captcha on element');

  setRecaptchaRef(grecaptcha);
  const widgetId = grecaptcha.render(element, {
    ...defaultCaptchaConfig,
    sitekey,
    callback: onVerifyCallback,
    'expired-callback': onExpiredCallback,
  });
  setWidgetId(widgetId);
  onReady();
};

export const handleOnExpiredCallback = (
  recaptchaRef: ReCaptcha | undefined,
  widgetId: ReCaptchaWidgetId | undefined
) => {
  if (recaptchaRef && widgetId !== undefined) {
    recaptchaRef?.reset(widgetId);
    logger.debug('[RECAPTCHA] expired, resetting');
  }
};

export const handleOnVerifyCallback = (
  response: string,
  onVerify: (res: string) => void,
  recaptchaRef: ReCaptcha | undefined,
  widgetId: ReCaptchaWidgetId | undefined
) => {
  onVerify(response);
  logger.debug('[RECAPTCHA] response', response);
  if (recaptchaRef && widgetId !== undefined) {
    // TODO: move this to after submission success/fail?
    recaptchaRef?.reset(widgetId);
    logger.debug('[RECAPTCHA] completed resetting');
  }
};

export const handleOnExecuteCaptcha = (
  recaptchaRef: ReCaptcha | undefined,
  widgetId: ReCaptchaWidgetId | undefined
) => {
  if (recaptchaRef && widgetId !== undefined) {
    recaptchaRef?.execute(widgetId);
  } else {
    throw new Error('[RECAPTCHA] Instance not found');
  }
};
