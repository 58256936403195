import { z } from 'zod';

import {
  CMSContentBlockCardGridLoadedModelSchema,
  CMSContentBlockCardGridUnloadedModelSchema,
} from './CMSContentBlockCardGridModel';
import {
  CMSContentBlockCarouselItemLoadedModelSchema,
  CMSContentBlockCarouselItemUnloadedModelSchema,
} from './CMSContentBlockCarouselItemModel';
import {
  CMSContentBlockCarouselLoadedModelSchema,
  CMSContentBlockCarouselUnloadedModelSchema,
} from './CMSContentBlockCarouselModel';
import {
  CMSContentBlockImageLoadedModelSchema,
  CMSContentBlockImageUnloadedModelSchema,
} from './CMSContentBlockImageModel';
import { CMSContentBlockQuoteModelSchema } from './CMSContentBlockQuoteModel';
import { CMSContentBlockTextModelSchema } from './CMSContentBlockTextModel';
import {
  CMSContentBlockVideoLoadedModelSchema,
  CMSContentBlockVideoUnloadedModelSchema,
} from './CMSContentBlockVideoModel';

export const AnyCMSContentBlockUnloadedModelSchema = z.union([
  CMSContentBlockCardGridUnloadedModelSchema,
  CMSContentBlockCarouselUnloadedModelSchema,
  CMSContentBlockCarouselItemUnloadedModelSchema,
  CMSContentBlockImageUnloadedModelSchema,
  CMSContentBlockQuoteModelSchema,
  CMSContentBlockTextModelSchema,
  CMSContentBlockVideoUnloadedModelSchema,
]);

export const AnyCMSContentBlockLoadedModelSchema = z.union([
  CMSContentBlockCardGridLoadedModelSchema,
  CMSContentBlockCarouselLoadedModelSchema,
  CMSContentBlockCarouselItemLoadedModelSchema,
  CMSContentBlockImageLoadedModelSchema,
  CMSContentBlockQuoteModelSchema,
  CMSContentBlockTextModelSchema,
  CMSContentBlockVideoLoadedModelSchema,
]);

export type AnyCMSContentBlockUnloadedModel = z.infer<
  typeof AnyCMSContentBlockUnloadedModelSchema
>;

export type AnyCMSContentBlockLoadedModel = z.infer<
  typeof AnyCMSContentBlockLoadedModelSchema
>;

export type AnyCMSContentBlockModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? AnyCMSContentBlockLoadedModel
    : AnyCMSContentBlockUnloadedModel;
