import { createContext, useMemo } from 'react';

import { MediaQueryManagementContextProps } from '../types';
import useMediaQueryManagement from './hooks/useMediaQueryManagement';
import { MediaQueryManagementApi } from './types';

export const MediaQueryManagementContext =
  createContext<MediaQueryManagementApi>({
    isXs: true,
    isSm: false,
    isMd: false,
    isLg: false,
    isXl: false,
    isTablet: false,
    isDesktop: false,
  });

export const MediaQueryManagementProvider: React.FC<
  MediaQueryManagementContextProps
> = ({ children }) => {
  const mediaQueryManagement = useMediaQueryManagement();
  const value = useMemo(() => mediaQueryManagement, [mediaQueryManagement]);

  return (
    <MediaQueryManagementContext.Provider value={value}>
      {children}
    </MediaQueryManagementContext.Provider>
  );
};
