import {
  ResourceCardType,
  VideoResourceCardModel,
} from '@kitted/card-service-models';
import {
  CardType,
  KittedModelTypeEnum,
  KittedVersionStatusTypeEnum,
} from '@kitted/kitted-models';
import { VideoResourceModel } from '@kitted/resource-service-models';

export const convertVideoResourceModelToVideoResourceCardModel = (
  id: VideoResourceModel['id'],
  videoResource: Omit<VideoResourceModel, 'id'>,
  oldCard: Partial<VideoResourceCardModel> = {}
): VideoResourceCardModel => ({
  ...oldCard,

  id,
  slug: videoResource.slug,

  cardType: CardType.Resource,
  resourceType: ResourceCardType.Video,
  kittedModelType: KittedModelTypeEnum.CardVideoResource,

  createdAt: oldCard?.createdAt || videoResource.createdAt,
  updatedAt: videoResource.updatedAt,

  createdByAuthorId: videoResource.createdByAuthorId,
  keyImageImageAsset: videoResource.keyImageImageAsset,
  label: videoResource.label,
  title: videoResource.title,

  videoUrl: videoResource.videoUrl,
  description: videoResource.description,

  excludeFromDiscovery:
    videoResource.cmsSrcMessage?.excludeFromDiscovery ??
    oldCard?.excludeFromDiscovery ??
    true,
  live: videoResource.cmsSrcMessage?.live ?? oldCard?.live ?? true,
  versionStatus: videoResource.versionStatus,
  publishedAt:
    videoResource.versionStatus === KittedVersionStatusTypeEnum.Published
      ? videoResource.updatedAt
      : oldCard.publishedAt!,
});
