/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';
import { EMPTY_OBJ } from '@kitted/shared-utils';

import useFixedBottom from './hooks/useFixedBottom';
import { FixedBottomRef, PolymorphicFixedBottomProps } from './types';

const FixedBottom = React.forwardRef(
  <C extends React.ElementType = 'div'>(
    {
      as,
      className,
      children = null,
      style = EMPTY_OBJ,
      isDisabled = false,
      offset = 0,
      cssVariableName,
      ...rest
    }: PolymorphicFixedBottomProps<C>,
    ref?: FixedBottomRef<C>
  ) => {
    const {
      isSafariMobile,
      childrenStyles,
      visibilityCheckStyles,
      visibilityCheckRef,
    } = useFixedBottom(offset, style, cssVariableName);
    const Component = as || 'div';

    return (
      <>
        <Component
          {...rest}
          className={className}
          style={isDisabled ? style : childrenStyles}
          ref={ref}
        >
          {children}
        </Component>
        {isSafariMobile && (
          <div ref={visibilityCheckRef} style={visibilityCheckStyles} />
        )}
      </>
    );
  }
);

FixedBottom.displayName = 'FixedBottom';

export default memo(FixedBottom);
