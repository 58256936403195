/* eslint-disable react/jsx-props-no-spreading */

import { memo } from 'react';
import clsx from 'clsx';
import { Spinner } from '@kitted/shared-components';

import { AssetCatalogueModalSpaceProps } from '../types';
import useUploadBlobFromCatalogue from './hooks/useUploadBlobFromCatalogue';
import useStyles from './styles';
import UploadImageBody from './UploadImageBody';

const CatalogueUpload = ({
  maxFileSize,
  acceptedFileFormats,
}: AssetCatalogueModalSpaceProps) => {
  const styles = useStyles();
  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    isLoading,
    uploadDisabled,
  } = useUploadBlobFromCatalogue(maxFileSize, acceptedFileFormats);

  return (
    <div className={styles.container}>
      <div
        className={clsx(
          styles.uploadBox,
          !uploadDisabled && styles.cursorAnimations,
          isDragAccept && styles.accepted,
          isDragReject && styles.rejected
        )}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isLoading && <Spinner size={194} theme="secondary" />}
        {!isLoading && (
          <UploadImageBody
            maxFileSize={maxFileSize}
            acceptedFileFormats={acceptedFileFormats}
          />
        )}
      </div>
    </div>
  );
};

export default memo(CatalogueUpload);
