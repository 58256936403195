import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0056: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 96 94"
		height={94}
		width={96}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M72.53 37.44c7.44 14.25 5.17 32.25-6.79 44.21-14.76 14.76-38.7 14.76-53.46 0s-14.76-38.7 0-53.46C24.75 15.71 43.79 13.78 58.3 22.4M56.83 54.93H21.19M39.01 37.11v35.64" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M63.73 31.78c-.93-.93-.86-2.52.16-3.53L77.94 14.2l-14.36-3.44 31.21-9.49-9.48 31.23-3.44-14.37-14.05 14.05c-1.02 1.02-2.6 1.09-3.53.16z" />
    </g>
  </CardIcon>
);

export default memo(Sn0056);
