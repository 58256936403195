import { useMemo } from 'react';

import { getTypographyStyles } from '../../logic';
import { TypographyProps } from '../../types';

const useTypography = (
  maxLines: TypographyProps['maxLines'],
  isTight: TypographyProps['isTight'],
  variant: TypographyProps['variant'],
  style: TypographyProps['style']
) => {
  const typographyStyle = useMemo(
    () => getTypographyStyles(maxLines, isTight, variant, style),
    [maxLines, isTight, variant, style]
  );

  return {
    typographyStyle,
  };
};

export default useTypography;
