import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      flexGrow: 0,
      flexShrink: 0,
      position: 'relative',
      minHeight: styling.sizes.context.topBar.height,
      color: styling.colors.context.color,
      backgroundColor: styling.colors.theme.primary900,
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBlock: 20,
      paddingInline: styling.sizes.theme.spacing.xs,
      gap: styling.sizes.theme.spacing['2xs'],
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 1,
        background: styling.colors.theme.primary400,
      },
      [styling.breakpoints.context.tabletUpString]: {
        paddingInline: styling.sizes.theme.spacing.md,
        flexDirection: 'row',
      },
    },
  },
  { name: 'editDrawerSpaceContentHeaderBar' }
);
