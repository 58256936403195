import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Documents: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 22 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M2.6 23.41c-.48 0-.89-.39-.89-.89V2.16c0-.48.39-.89.89-.89h13.35c.48 0 .89.39.89.89" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M19.4 4.59H6.06c-.49 0-.89.4-.89.89v20.37h0c0 .17.06.33.15.46.06.1.14.18.23.24.15.11.32.18.51.18h13.35c.49 0 .89-.4.89-.89V5.48h0c0-.49-.4-.89-.89-.89Z" />
    </g>
  </WebsiteIcon>
);

export default memo(Documents);
