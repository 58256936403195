import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      position: 'relative',
      backgroundColor: styling.colors.theme.snare,
      color: styling.colors.context.inverted.color,
      borderRadius: '100rem',
      overflow: 'hidden',
      boxShadow: styling.shadows.utils.boxShadowToString({
        x: 0,
        y: 0,
        blur: 0,
        spread: 1,
        color: styling.colors.theme.secondary,
        inset: 'inset',
      }),
      transition: styling.transitions.utils.generate([
        'opacity',
        undefined,
        undefined,
        '100ms',
      ]),
    },
    'size-sm': {
      width: 32,
      height: 32,
      ...styling.typography.theme.atlasGrotesk.bold['3xs'],
    },
    'size-md': {
      width: 60,
      height: 60,
      ...styling.typography.theme.atlasGrotesk.bold.xs,
    },
    'size-lg': {
      width: 120,
      height: 120,
      ...styling.typography.theme.atlasGrotesk.bold.lg,
    },
    'size-xl': {
      width: 160,
      height: 160,
      ...styling.typography.theme.atlasGrotesk.bold['2xl'],
    },
    'size-src': {
      width: 160,
      height: 160,
      ...styling.typography.theme.atlasGrotesk.bold['2xl'],
    },
    loadingSpinner: {
      ...styling.utils.absoluteFull(),
    },
    accountImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    initials: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  { name: 'accountAvatar' }
);
