import { z } from 'zod';

import { WebsocketServerMessageType } from './enum';
import { WebsocketServerMessageSchema } from './WebsocketServerMessage';

export const WebsocketProcessMessageServerMessageSchema =
  WebsocketServerMessageSchema.merge(
    z.object({
      messageType: z.literal(WebsocketServerMessageType.ProcessMessage),
      message: z.string(),
    })
  );

// Override the any type due to the schema limitation
export type WebsocketProcessMessageServerMessage = z.infer<
  typeof WebsocketProcessMessageServerMessageSchema
>;
