import { RGB } from '../types';

// palette generator courtesy of https://github.com/Leolainen/figma-plugin-Material-palette
// which was used to generate the swatches in the figma designs
// https://css-tricks.com/converting-color-spaces-in-javascript/
export function hexToRGB(hex: string, isPct = false): RGB {
  let r = 0;
  let g = 0;
  let b = 0;

  if (hex.length === 4) {
    r = Number(`0x${hex[1]}${hex[1]}`);
    g = Number(`0x${hex[2]}${hex[2]}`);
    b = Number(`0x${hex[3]}${hex[3]}`);
  } else if (hex.length === 7) {
    r = Number(`0x${hex[1]}${hex[2]}`);
    g = Number(`0x${hex[3]}${hex[4]}`);
    b = Number(`0x${hex[5]}${hex[6]}`);
  }

  if (isPct) {
    r = +((Number(r) / 255) * 100).toFixed(1);
    g = +((Number(g) / 255) * 100).toFixed(1);
    b = +((Number(b) / 255) * 100).toFixed(1);
  }

  return {
    r,
    g,
    b,
    string: `rgb(${isPct ? `${r}%,${g}%,${b}%` : `${+r},${+g},${+b}`})`,
  };
}
