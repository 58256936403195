import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Spanner: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 18 26"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M6.29 23.94v-6.63c-.06-2.13-1.6-3.25-2.01-3.65l-.04-.04A7 7 0 0 1 2.01 8.5c0-2.9 1.76-5.38 4.27-6.44v4.27c0 1.49 1.22 2.71 2.71 2.71h.04c1.49 0 2.71-1.22 2.71-2.71V2.06a6.99 6.99 0 0 1 4.27 6.44c0 2.02-.86 3.84-2.23 5.12l-.04.04c-.41.4-1.97 1.53-2.03 3.65v6.63" />
    </g>
  </WebsiteIcon>
);

export default memo(Spanner);
