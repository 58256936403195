import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Moon: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 26 26"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M2.34 9.01c-1.17 4-.18 8.51 2.98 11.67 4.61 4.61 12.08 4.61 16.69 0 .88-.88 1.59-1.86 2.13-2.91M3.19 6.9c.54-1.05 1.26-2.03 2.13-2.91.88-.88 1.86-1.59 2.91-2.13a11.82 11.82 0 0 0 2.13 13.79c3.73 3.73 9.34 4.44 13.78 2.13" />
    </g>
  </WebsiteIcon>
);

export default memo(Moon);
