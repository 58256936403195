import { z } from 'zod';

import { WebsocketClientMessageType } from './enum';

export const WebsocketClientMessageSchema = z.object({
  messageType: z.nativeEnum(WebsocketClientMessageType),
  correlationId: z.string().optional(),
});

export type WebsocketClientMessage = z.infer<
  typeof WebsocketClientMessageSchema
>;
