import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      position: 'relative',
      flexGrow: 1,
      display: 'flex',
      overflow: 'hidden',
    },
    playPauseOverlay: {
      ...styling.utils.absoluteFull(),
      cursor: 'pointer',
      zIndex: 2,
    },
    videoPlayer: {
      flexGrow: 1,
      position: 'relative',
      '& > video': {
        objectFit: 'cover',
      },
    },
  },
  { name: 'mediaPlayer' }
);
