import { useMemo } from 'react';

import { CardSubType } from '../../../types';
import { getCardFooterComponentByType } from '../../logic';
import { CardFooterProps } from '../../types';

const useCardFooter = (
  type: CardFooterProps['type'],
  subType: CardSubType | undefined,
  isFeatured = false
) => {
  const { Component, componentProps } = useMemo(
    () => getCardFooterComponentByType(type, subType, isFeatured),
    [isFeatured, subType, type]
  );

  return {
    Component,
    componentProps,
  };
};

export default useCardFooter;
