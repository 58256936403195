import useToastData from '../../../../contexts/ToastContext/hooks/useToastData';

const useToastRoot = () => {
  const { toasts } = useToastData();

  return {
    toasts,
  };
};

export default useToastRoot;
