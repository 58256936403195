import { useEffect } from 'react';
import {
  CatalogueItemModel,
  CatalogueItemType,
} from '@kitted/catalogue-service-models';
import { KittedServiceEnum } from '@kitted/kitted-models';

import useRequests from '../../../../../../contexts/RequestsContext/hooks/useRequests';
import useMyAuthor from '../../../../../../hooks/authors/useMyAuthor';
import useAsyncRequest from '../../../../../../hooks/useAsyncRequest';
import { CatalogueSelectorViewApi } from '../../types';

const useSyncCatalogueSelectorData = (
  catalogueSelectorViewApi: CatalogueSelectorViewApi
) => {
  const { platformRequest } = useRequests();
  const { author, isLoading: myAuthorIsLoading } = useMyAuthor();

  const [getGalleryAsset, getGalleryAssetState] = useAsyncRequest<
    CatalogueItemModel[]
  >(() =>
    platformRequest(KittedServiceEnum.Catalogue, {
      method: 'GET',
      url: `/v1/catalogueItem/${CatalogueItemType.GalleryAsset}`,
    })
  );

  const [getPlatformImageResource, getPlatformImageResourceState] =
    useAsyncRequest<CatalogueItemModel[]>(() =>
      platformRequest(KittedServiceEnum.Catalogue, {
        method: 'GET',
        url: `/v1/catalogueItem/${CatalogueItemType.PlatformImageResource}`,
      })
    );

  const [getAuthorImageAsset, getAuthorImageAssetState] = useAsyncRequest<
    CatalogueItemModel[]
  >((authorId: string) => {
    if (authorId) {
      return platformRequest(KittedServiceEnum.Catalogue, {
        method: 'GET',
        url: `v1/catalogueItem/${CatalogueItemType.AuthorImageAsset}/${authorId}`,
      });
    }

    return Promise.resolve([]);
  });

  useEffect(() => {
    getGalleryAsset().then((galleryAssetItems) => {
      if (galleryAssetItems) {
        catalogueSelectorViewApi.setGalleryItems(galleryAssetItems);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPlatformImageResource().then((platformImageResourceItems) => {
      if (platformImageResourceItems) {
        catalogueSelectorViewApi.setPlatformImages(platformImageResourceItems);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (author?.id) {
      getAuthorImageAsset(author?.id).then((authorImageItems) => {
        if (authorImageItems) {
          catalogueSelectorViewApi.setMyImages(authorImageItems);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [author?.id]);

  const isLoading =
    myAuthorIsLoading ||
    getGalleryAssetState === 'loading' ||
    getPlatformImageResourceState === 'loading' ||
    getAuthorImageAssetState === 'loading';

  useEffect(() => {
    catalogueSelectorViewApi.setIsLoading(isLoading);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
};

export default useSyncCatalogueSelectorData;
