import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0007: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 114 114"
		height={114}
		width={114}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M57 1.29v111.42M112.71 57H1.29" /><circle vectorEffect="non-scaling-stroke" cx="30.06" cy="30.06" r="9.69" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="83.59" cy="30.06" r="9.69" stroke={color} /><circle vectorEffect="non-scaling-stroke" cx="30.06" cy="85.41" r="9.69" stroke={color} /><circle vectorEffect="non-scaling-stroke" cx="83.59" cy="85.41" r="9.69" stroke={color} />
    </g>
  </CardIcon>
);

export default memo(Ac0007);
