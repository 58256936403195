import { createContext } from 'react';

import {
  CatalogueSelectorViewState,
  getInitalCatalogueSelectorViewState,
} from './logic';

export const AssetCatalogueDataContext =
  createContext<CatalogueSelectorViewState>(
    getInitalCatalogueSelectorViewState()
  );
