import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      position: 'fixed',
      minWidth: styling.sizes.context.cardFeature.width,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'grid',
      zIndex: styling.zIndex.alert,
      gridTemplateColumns: '1fr',
      cursor: 'pointer',
      width: [['unset'], '!important'],
      overflow: 'auto',
      padding: [
        styling.sizes.context.gutteringW,
        styling.sizes.context.gutteringSmW,
      ],
      [styling.breakpoints.context.tabletUpString]: {
        padding: [100, styling.sizes.context.gutteringW],
      },
    },
  },
  { name: 'alert' }
);
