import { memo } from 'react';

import useFormGrid from './hooks/useFormGrid';
import useStyles from './styles';
import { FormGridProps } from './types';

const FormGrid = ({ children, item, widths }: FormGridProps) => {
  const { style } = useFormGrid(item, widths);
  const styles = useStyles();

  if (item) {
    return (
      <div className={styles.item} style={style}>
        {children}
      </div>
    );
  }

  return <div className={styles.container}>{children}</div>;
};

export default memo(FormGrid);
