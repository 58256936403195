import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    portal: {
      position: 'sticky',
      top: 0,
      left: 0,
      right: 0,
      zIndex: styling.zIndex.fixedHeaderBar,
    },
    container: {
      position: 'relative',
      height: styling.sizes.context.topBar.height,
      display: 'flex',
      alignItems: 'center',
      minWidth: styling.sizes.context.cardFeature.width,
      paddingBlock: 16,
      gap: styling.sizes.context.gutteringSmW,
      paddingInline: styling.sizes.context.gutteringSmW,
      '& $subject': {
        width: '100%',
        flexGrow: 1,
      },
      '& $search': {
        display: 'none',
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        pointerEvents: 'none',
        height: '150%',
        background: `linear-gradient(to top, ${styling.colors.theme.transparent} 10%, ${styling.colors.theme.primary1000_90} 80%, ${styling.colors.theme.primary1000} 100%)`,
        mixBlendMode: 'multiply',
      },
      [styling.breakpoints.context.tabletUpString]: {
        gap: styling.sizes.context.gutteringW,
        paddingInline: 0,
        '& $search': {
          display: 'block',
        },
        '& $searchSolo': {
          display: 'block',
          gridColumn: '1 / 3',
        },
      },
      [styling.container.utils.containerQuery(
        'width',
        styling.sizes.utils.gridColumns(
          3,
          styling.sizes.context.gutteringW,
          true
        )
      )]: {
        '& $search': {
          display: 'block',
          gridColumn: '2 / 4',
        },
        '& $searchSolo': {
          display: 'block',
          gridColumn: '1 / 4',
        },
      },
      [styling.container.utils.containerQuery(
        'width',
        styling.sizes.utils.gridColumns(
          5,
          styling.sizes.context.gutteringW,
          true
        )
      )]: {
        '& $search': {
          display: 'block',
          gridColumn: '3 / 5',
        },
        '& $searchSolo': {
          display: 'block',
          gridColumn: '2 / 5',
        },
      },
    },
    grid: {
      flexGrow: 1,
      '& > div': {
        paddingBlock: 0,
      },
    },
    subject: {
      [styling.container.utils.containerQuery(
        'width',
        styling.sizes.utils.gridColumns(
          5,
          styling.sizes.context.gutteringW,
          true
        )
      )]: {
        gridColumn: 2,
      },
    },
    search: {
      display: 'none',
      [styling.container.utils.containerQuery(
        'width',
        styling.sizes.utils.gridColumns(
          3,
          styling.sizes.context.gutteringW,
          true
        )
      )]: {
        display: 'block',
      },
    },
    searchSolo: {},
    dropdownUnderlayContainer: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: styling.zIndex.fixedHeaderBar - 1,
    },
    dropdownUnderlay: {
      ...styling.utils.absoluteFull(),
      opacity: 0.9,
      backgroundColor: styling.colors.context.background,
      mixBlendMode: 'multiply',
    },
    spacer: {
      height: styling.sizes.context.topBar.height,
    },
  },
  { name: 'catalogueTopBar' }
);
