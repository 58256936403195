import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const ArrowCardInRight: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 26 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M.68 14h17.56M12.75 8.51 18.24 14l-5.49 5.49" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M8.36 11.26V2.48c0-.61.49-1.1 1.1-1.1h14.27c.61 0 1.1.49 1.1 1.1v23.05c0 .61-.49 1.1-1.1 1.1H9.46c-.61 0-1.1-.49-1.1-1.1v-8.78" />
    </g>
  </WebsiteIcon>
);

export default memo(ArrowCardInRight);
