/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';
import { EMPTY_OBJ } from '@kitted/shared-utils';

import useIconButton from './hooks/useIconButton';
import useStyles from './styles';
import { IconButtonProps } from './types';

const IconButton = (props: IconButtonProps) => {
  const {
    className,
    icon: Icon,
    theme = 'secondary',
    variant = 'outline',
    size = 'md',
    isDisabled,
    iconProps = EMPTY_OBJ,
    tabIndex,
  } = props;
  const styles = useStyles();
  const { IconButtonComponent, iconButtonProps } = useIconButton(props);

  return (
    <IconButtonComponent
      className={clsx(
        className,
        styles.container,
        styles[`theme-${theme}`],
        styles[`variant-${variant}`],
        styles[`size-${size}`],
        isDisabled && styles.isDisabled
      )}
      {...iconButtonProps}
      tabIndex={tabIndex}
    >
      {Icon && <Icon {...iconProps} size="inherit" />}
    </IconButtonComponent>
  );
};

export default memo(IconButton);
