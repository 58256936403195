import { ResourceType } from '@kitted/resource-service-models';

import { constructAuthenticatedRoute, constructPublicRoute } from '../helpers';
import { resourceTitles } from '../routeTitles';

const resourceLinkTitleCodeBasePath = '/link/:titleSlug/:code';
const resourceVideoTitleCodeBasePath = '/video/:titleSlug/:code';
const resourceImageTitleCodeBasePath = '/image/:titleSlug/:code';

export const resourceRedirectRoutes = {
  resourceLinkCode: constructPublicRoute(
    '/link/:code',
    resourceTitles(ResourceType.Link),
    resourceLinkTitleCodeBasePath
  ),
  resourceVideoCode: constructPublicRoute(
    '/video/:code',
    resourceTitles(ResourceType.Video),
    resourceVideoTitleCodeBasePath
  ),
  resourceImageCode: constructPublicRoute(
    '/image/:code',
    resourceTitles(ResourceType.Image),
    resourceImageTitleCodeBasePath
  ),
} as const;

export const resourceRoutes = {
  ...resourceRedirectRoutes,
  resourceLinkTitleCode: constructPublicRoute(
    resourceLinkTitleCodeBasePath,
    resourceTitles(ResourceType.Link)
  ),
  resourceLinkTitleCodeEdit: constructAuthenticatedRoute(
    `${resourceLinkTitleCodeBasePath}/edit`,
    resourceTitles(ResourceType.Link, 'Edit ')
  ),
  resourceVideoTitleCode: constructPublicRoute(
    resourceVideoTitleCodeBasePath,
    resourceTitles(ResourceType.Video)
  ),
  resourceVideoTitleCodeEdit: constructAuthenticatedRoute(
    `${resourceVideoTitleCodeBasePath}/edit`,
    resourceTitles(ResourceType.Video, 'Edit ')
  ),
  resourceImageTitleCode: constructPublicRoute(
    resourceImageTitleCodeBasePath,
    resourceTitles(ResourceType.Image)
  ),
  resourceImageTitleCodeEdit: constructAuthenticatedRoute(
    `${resourceImageTitleCodeBasePath}/edit`,
    resourceTitles(ResourceType.Image, 'Edit ')
  ),
} as const;
