import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Playbook: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 26 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m6.95 10.3-5.69 5.69M6.95 15.99 1.26 10.3" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m20.31 20.36-5.69 5.69M20.31 26.05l-5.69-5.69" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M24.74 11.5c0 2.04-1.65 3.69-3.69 3.69s-3.69-1.65-3.69-3.69 1.65-3.69 3.69-3.69 3.69 1.65 3.69 3.69M11.55 2.08s6.04 14.59-7.29 23.14M11.5 1.95 9.49 5.98M11.51 1.95l3.87 2.3" />
    </g>
  </WebsiteIcon>
);

export default memo(Playbook);
