import { useEffect, useMemo, useState } from 'react';

import useClickOutside from '../../../../hooks/useClickOutside';
import { calculateStyles, handleDirectionCheck } from '../../logic';
import { CollapseMenuDirection, CollapseMenuProps } from '../../types';

const useCollapseMenu = (
  isOpen: boolean,
  onClickOutside: CollapseMenuProps['onClickOutside'],
  maxHeight: CollapseMenuProps['maxHeight'],
  lockDirection: CollapseMenuDirection | undefined
) => {
  const { ref: listRef } = useClickOutside(
    !!(onClickOutside && isOpen),
    onClickOutside
  );
  const [direction, setDirection] = useState<CollapseMenuDirection>(
    lockDirection || 'down'
  );

  const style = useMemo(() => calculateStyles(maxHeight), [maxHeight]);

  useEffect(() => {
    if (lockDirection) {
      setDirection(lockDirection);
    }
  }, [lockDirection]);

  useEffect(
    () =>
      handleDirectionCheck(
        isOpen,
        setDirection,
        lockDirection,
        listRef.current
      ),
    [isOpen, setDirection, lockDirection, listRef]
  );

  return {
    direction,
    style,
    listRef,
  };
};

export default useCollapseMenu;
