import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    contentContainer: {
      position: 'relative',
      containerName: styling.container.context.drawer.name,
      containerType: 'inline-size',
      marginLeft: styling.sizes.context.drawer.closedWidth,
      marginRight: styling.sizes.context.drawer.closedWidth,
      transition: styling.transitions.utils.generate(
        ['margin-left'],
        ['margin-right']
      ),
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      minHeight: '100%',
    },
    contentContainerMainOpen: {
      [styling.breakpoints.context.desktopUpString]: {
        marginLeft: styling.sizes.context.drawer.openWidth,
      },
    },
    contentContainerContextualOpen: {
      [styling.breakpoints.context.desktopUpString]: {
        marginRight: styling.sizes.context.drawer.openWidth,
      },
    },
    contentContainerMainDisabled: {
      marginLeft: 0,
    },
    contentContainerContextualDisabled: {
      marginRight: 0,
    },
  },
  { name: 'drawerSpaceContentWrapper' }
);
