import { memo } from 'react';
import clsx from 'clsx';
import { Stack, Typography } from '@kitted/shared-components';

import useMediaQuery from '../../../contexts/MediaQueryManagementContext/hooks/useMediaQuery';
import useStyles from './styles';
import { EditDrawerSpaceFooterProps } from './types';

const EditDrawerSpaceFooter = ({
  className,
  title,
  children,
}: EditDrawerSpaceFooterProps) => {
  const styles = useStyles();
  const isTablet = useMediaQuery('isTablet');

  return (
    <Stack
      className={clsx(styles.container, className)}
      direction={isTablet ? 'row' : 'column'}
      align="center"
      justify="between"
      spacing={isTablet ? 'md' : 'none'}
    >
      <Typography className={styles.title} variant="editModalFooterTitle">
        {title}
      </Typography>
      <Stack
        className={styles.buttons}
        direction="row"
        spacing={isTablet ? 'xs' : '2xs'}
      >
        {children}
      </Stack>
    </Stack>
  );
};

export default memo(EditDrawerSpaceFooter);
