import { useMemo } from 'react';

import useResizeObserver from '../../../DrawerSpace/context/DrawerSpaceContainerContext/hooks/useResizeObserver';
import { ScaleContainerProps } from '../../types';
import { getComputedStyle } from './logic';

const useScaleContainer = (
  aspectRatio: ScaleContainerProps['aspectRatio'],
  maxWidth: ScaleContainerProps['maxWidth']
) => {
  const { onSetRef, height, width } = useResizeObserver('both');
  const style = useMemo(
    () => getComputedStyle(aspectRatio, maxWidth, width, height),
    [aspectRatio, maxWidth, width, height]
  );

  return {
    onSetRef,
    style,
  };
};

export default useScaleContainer;
