import { MouseEvent, useCallback, useMemo, useRef } from 'react';

import useAlertApi from '../../../../contexts/AlertContext/hooks/useAlertApi';
import {
  getAlertContentComponent,
  handleOnClose,
  handleOnExited,
} from '../../logic';
import { AlertProps } from '../../types';

const useAlert = (props: AlertProps) => {
  const { closeAlert, deregisterAlert } = useAlertApi();
  const hasRequestedCloseRef = useRef<boolean>(false);

  const setHasRequestedClose = useCallback((requestClose: boolean) => {
    hasRequestedCloseRef.current = requestClose;
  }, []);

  const onClose = useCallback(
    () => handleOnClose(closeAlert, setHasRequestedClose),
    [closeAlert, setHasRequestedClose]
  );

  const onExited = useCallback(
    () =>
      handleOnExited(
        props.id,
        deregisterAlert,
        props.hasTopAlert,
        hasRequestedCloseRef.current
      ),
    [props.id, deregisterAlert, props.hasTopAlert]
  );

  const preventClose = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  const { as: AlertContentComponent, ...alertContentProps } = useMemo(
    () => getAlertContentComponent(props, onClose, preventClose),
    [props, onClose, preventClose]
  );

  return {
    onClose,
    onExited,
    AlertContentComponent,
    alertContentProps,
  };
};

export default useAlert;
