import { Dispatch, SetStateAction } from 'react';
import { EMPTY_OBJ } from '@kitted/shared-utils';

import InputDisplay from './InputDisplay';
import InputHidden from './InputHidden';
import InputImage from './InputImage';
import InputPassword from './InputPassword';
import InputRadioGroup from './InputRadioGroup';
import InputSelect from './InputSelect';
import InputText from './InputText';
import InputTextarea from './InputTextarea';
import { InputProps } from './types';

export const getComponentForType = (type: InputProps['type']) => {
  switch (type) {
    case 'number':
    case 'email':
    case 'url':
    case 'text':
      return {
        component: InputText,
        props: {
          type,
        },
      };
    case 'hidden':
      return {
        component: InputHidden,
        props: EMPTY_OBJ,
      };
    case 'password':
      return {
        component: InputPassword,
        props: EMPTY_OBJ,
      };
    case 'radio':
      return {
        component: InputRadioGroup,
        props: EMPTY_OBJ,
      };
    case 'select':
      return {
        component: InputSelect,
        props: EMPTY_OBJ,
      };
    case 'textarea':
      return {
        component: InputTextarea,
        props: EMPTY_OBJ,
      };
    case 'display':
      return {
        component: InputDisplay,
        props: EMPTY_OBJ,
      };
    case 'image':
      return {
        component: InputImage,
        props: EMPTY_OBJ,
      };
    default:
      return {
        component: 'div',
        props: EMPTY_OBJ,
      };
  }
};

export const handleOnBlurFocus = (
  setIsFocused: Dispatch<SetStateAction<boolean>>,
  value: boolean
) => setIsFocused(value);

export const handleOnTouched = (
  setIsTouched: Dispatch<SetStateAction<boolean>>
) => setIsTouched(true);

export const getTouchedErrors = (
  errors: InputProps['errors'],
  isTouched: boolean
) => {
  if (!isTouched) return undefined;
  return errors;
};
