import useIsLoggedIn from '../../../../../../../contexts/UserContext/hooks/useIsLoggedIn';
import useIsLoggingIn from '../../../../../../../contexts/UserContext/hooks/useIsLoggingIn';

const useAccountMenuItem = () => {
  const { isLoggedIn } = useIsLoggedIn();
  const { isLoggingIn } = useIsLoggingIn();

  return {
    isLoggedInOrLoggingIn: isLoggedIn || isLoggingIn,
  };
};

export default useAccountMenuItem;
