import { useContext } from 'react';

import { MediaQueryManagementContext } from '../..';
import { MediaQueryManagementApi } from '../../types';

const useMediaQuery = (key: keyof MediaQueryManagementApi) => {
  const contextValue = useContext(MediaQueryManagementContext);

  return contextValue[key];
};

export default useMediaQuery;
