import { memo } from 'react';

import { StoreSectionInitializerProps } from '../types';
import useCardsStoreInitializer from './hooks/useCardsStoreInitializer';

const CardsStoreInitializer = ({
  onSectionLoaded,
}: StoreSectionInitializerProps) => {
  useCardsStoreInitializer(onSectionLoaded);

  return null;
};

export default memo(CardsStoreInitializer);
