import { memo } from 'react';
import clsx from 'clsx';
import {
  Divider,
  ImageSkeleton,
  TextSkeleton,
} from '@kitted/shared-components';

import useStyles from './styles';
import { AuthorCardSkeletonProps } from './types';

const AuthorCardSkeleton = ({ isFeatured, theme }: AuthorCardSkeletonProps) => {
  const styles = useStyles();

  return (
    <>
      <ImageSkeleton
        className={clsx(styles.image, isFeatured && styles.imageFeatured)}
        theme={theme}
      />
      <TextSkeleton
        className={clsx(styles.title, isFeatured && styles.titleFeatured)}
        lines={isFeatured ? 2 : 3}
        theme={theme}
        variant={`cardTitle${isFeatured ? 'Featured' : ''}`}
      />
      {isFeatured && (
        <TextSkeleton
          className={styles.body}
          lines={3}
          theme={theme}
          variant="cardBodyFeatured"
        />
      )}
      {!isFeatured && (
        <Divider className={styles.divider} spacing="md" theme={theme} />
      )}
    </>
  );
};

export default memo(AuthorCardSkeleton);
