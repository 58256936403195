import { useCallback, useEffect } from 'react';

import logger from '../../../../services/logger';
import { KittedStoreSectionKey } from '../../types';
import useStoreApi from '../useStoreApi';

const useStoreItem = <StoreItem>(
  sectionKey: KittedStoreSectionKey,
  itemId: string | undefined
) => {
  const {
    getStoreItem,
    fetchStoreItem,
    mergeStoreItemItem,
    setStoreItemItem,
    deleteStoreItem,
  } = useStoreApi();

  const fetchItem = useCallback(async () => {
    if (!itemId) {
      throw new Error(`[useStoreItem] itemId is not defined: ${itemId}`);
    }
    return fetchStoreItem<StoreItem>(sectionKey, itemId);
  }, [sectionKey, itemId, fetchStoreItem]);

  const getItem = useCallback(
    () => getStoreItem<StoreItem>(sectionKey, itemId),
    [sectionKey, itemId, getStoreItem]
  );

  const setItem = useCallback(
    (item: StoreItem | undefined) => {
      if (!itemId) {
        throw new Error(`[useStoreItem] itemId is not defined: ${itemId}`);
      }
      logger.log('setting item', sectionKey, itemId, item);
      setStoreItemItem<StoreItem>(sectionKey, itemId, item);
    },
    [sectionKey, itemId, setStoreItemItem]
  );

  const updateItem = useCallback(
    (item: Partial<StoreItem>) => {
      if (!itemId) {
        throw new Error(`[useStoreItem] itemId is not defined: ${itemId}`);
      }
      logger.log('merging item', sectionKey, itemId, item);
      mergeStoreItemItem<StoreItem>(sectionKey, itemId, item);
    },
    [sectionKey, itemId, mergeStoreItemItem]
  );

  const deleteItem = useCallback(() => {
    if (!itemId) {
      throw new Error(`[useStoreItem] itemId is not defined: ${itemId}`);
    }
    logger.log('deleting item', sectionKey, itemId);
    deleteStoreItem(sectionKey, itemId);
  }, [sectionKey, itemId, deleteStoreItem]);

  const fetchOnMount = useCallback(
    (id: string | undefined) => {
      if (id) {
        fetchItem();
      }
    },
    [fetchItem]
  );

  useEffect(() => {
    fetchOnMount(itemId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  return {
    getItem,
    fetchItem,
    setItem,
    updateItem,
    deleteItem,
  };
};

export default useStoreItem;
