/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';

import Divider from '../../Divider';
import CardContentsForHoverableCard from '../CardContentsForHoverableCard';
import CardImage from '../CardImage';
import useExtractInteractionProps from '../hooks/useExtractInteractionProps';
import useStyles from './styles';
import { PolymorphicSubjectCardProps } from './types';

const SubjectCard = <C extends React.ElementType = 'div'>({
  as,
  body,
  children,
  className,
  imageSrc,
  isActive,
  isFeatured,
  isMenuOpen,
  title,
  ...rest
}: PolymorphicSubjectCardProps<C>) => {
  const interactionProps = useExtractInteractionProps(rest);
  const styles = useStyles();
  const Component = as || 'div';

  return (
    <Component
      className={clsx(
        className,
        styles.container,
        isActive && styles.containerActive,
        isFeatured && styles.containerFeatured,
        isMenuOpen && styles.containerMenuOpen
      )}
      {...interactionProps}
    >
      <CardImage imageSrc={imageSrc} isFeatured={isFeatured} />
      <CardContentsForHoverableCard
        isActive={isActive}
        isFeatured={isFeatured}
        isMenuOpen={isMenuOpen}
        title={title}
        body={body}
        type="subject"
      />
      {isFeatured && (
        <Divider className={styles.divider} spacing="md" theme="secondary" />
      )}
      {children}
    </Component>
  );
};

export default memo(SubjectCard);
