import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Sun: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M8.92 8.92 5 5.01m14.09 3.91 3.92-3.91M8.92 19.05 5 22.95m14.09-3.9 3.92 3.9M14 6.79V1.27m0 25.46v-5.52M21.21 14h5.52M1.27 14h5.52m5.22-4.8a5.197 5.197 0 1 0 3.982 9.6 5.197 5.197 0 0 0-3.982-9.6" />
    </g>
  </WebsiteIcon>
);

export default memo(Sun);
