import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0022: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 102 84"
		height={84}
		width={102}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M15.93 26.27V9.09c0-4 3.11-7.28 6.91-7.28h56.31c3.8 0 6.91 3.28 6.91 7.28v17.18M22.26 45.6h57.48M94.06 72.28c3.8 0 6.91-3.11 6.91-6.91V40.73c0-3.8-3.11-6.91-6.91-6.91h-7.52c-3.8 0-6.91 3.11-6.91 6.91v16.01H22.37V40.73c0-3.8-3.11-6.91-6.91-6.91H7.94c-3.8 0-6.91 3.11-6.91 6.91v24.64c0 3.8 3.11 6.91 6.91 6.91h86.11Z" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M3.66 82.56h15.98M98.34 82.56H82.36" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0022);
