import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0029: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 124 112"
		height={112}
		width={124}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M62.19 53.24v19.04M45.69 81.8l16.5-9.52M78.68 81.8l-16.49-9.52" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M43.13 46.74v-9.27c0-6.53 4.73-8.95 10.81-8.95l7.48 8.24 6.94-8.24c6.09 0 11.34 2.41 11.34 8.95v9.27M69.88 13.12c0 4.73-3.81 8.59-8.46 8.59s-8.46-3.87-8.46-8.59V9.94c0-4.73 3.81-8.59 8.46-8.59s8.46 3.86 8.46 8.59zM86.54 110.65v-9.27c0-6.54 4.73-8.95 10.81-8.95l7.48 8.23 6.94-8.23c6.09 0 11.34 2.41 11.34 8.95v9.27M113.29 77.03c0 4.72-3.81 8.59-8.46 8.59s-8.46-3.87-8.46-8.59v-3.18c0-4.73 3.81-8.59 8.46-8.59s8.46 3.86 8.46 8.59zM.88 110.65v-9.27c0-6.54 4.73-8.95 10.81-8.95l7.48 8.23 6.94-8.23c6.09 0 11.34 2.41 11.34 8.95v9.27M27.63 77.03c0 4.72-3.81 8.59-8.46 8.59s-8.46-3.87-8.46-8.59v-3.18c0-4.73 3.81-8.59 8.46-8.59s8.46 3.86 8.46 8.59z" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0029);
