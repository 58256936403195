import { useCallback, useEffect, useMemo, useState } from 'react';
import { throttle } from '@kitted/shared-utils';

import { getMediaMatch } from './logic';

const useMediaQueryMonitor = (query: string) => {
  const sanitizedQuery = query.replace(/^@media ?/m, '');
  const [match, setMatch] = useState<boolean>(() =>
    getMediaMatch(sanitizedQuery)
  );

  const onResizeChange = useCallback(() => {
    setMatch(getMediaMatch(sanitizedQuery));
  }, [setMatch, sanitizedQuery]);

  const throttledResizeChange = useMemo(
    () => throttle(onResizeChange, 200),
    [onResizeChange]
  );

  useEffect(() => {
    window.addEventListener('resize', throttledResizeChange);

    return () => {
      window.removeEventListener('resize', throttledResizeChange);
    };
  }, [throttledResizeChange]);

  return match;
};

export default useMediaQueryMonitor;
