import { ACCEPTED_IMAGE_FILES, MAX_FILE_SIZE } from '../../constants/inputs';
import { FieldTypes, FormDataSchema } from '../../contexts/FormContext';
import { hasMaxLength, isRequired } from '../../services/helpers/validators';
import { EditDrawerSpaceDrawerItem } from '../EditDrawerSpace/types';
import { FormLayoutSchema } from '../FormFields/types';
import ModelCreateEditDetailsSection from './ModelCreateEditDetailsSection';

export const DEFAULT_MODEL_CREATE_INITIAL_DATA = {
  title: '',
  description: '',
};

export const EDIT_ROUTE_KEYS = new Set([
  'articleAuthorTitleCodeEdit',
  'playAuthorTitleCodeEdit',
  'flowAuthorTitleCodeEdit',
  'kitTitleCodeEdit',
]);

export const dataSchema: FormDataSchema = {
  fields: {
    title: {
      type: FieldTypes.TEXT,
      validation: [isRequired, hasMaxLength(128)],
    },
    keyImage: {
      type: FieldTypes.IMAGE,
    },
    description: {
      type: FieldTypes.TEXTAREA,
      validation: [hasMaxLength(600)],
    },
  },
  form: {
    validationStrategy: 'onBlur',
  },
};

export const layoutSchema: FormLayoutSchema = {
  fields: [
    {
      key: 'keyImage',
      label: 'Key image',
      meta: {
        autoFocus: true,
        acceptedFileFormats: ACCEPTED_IMAGE_FILES,
        maxFileSize: MAX_FILE_SIZE,
      },
    },
    {
      key: 'title',
      label: 'Title', // replaced with eg. 'Kit title'
    },
    {
      key: 'description',
      label: 'Short description',
      meta: {
        rows: 5,
        canResize: false,
      },
    },
  ],
};

export const modelCreateEditSections: Record<
  string,
  EditDrawerSpaceDrawerItem
> = {
  pageDetails: {
    drawerGroup: 'top',
    title: 'Page details',
    component: ModelCreateEditDetailsSection,
  },
} as const;

export type ModelCreateEditSectionSlug = keyof typeof modelCreateEditSections;

export const modelCreateEditSectionSlugs = Object.keys(
  modelCreateEditSections
) as ModelCreateEditSectionSlug[];

export const modelCreateEditSectionDefaultSlug = modelCreateEditSectionSlugs[0];
