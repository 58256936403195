import { createContext } from 'react';
import { EMPTY_OBJ, NOOP } from '@kitted/shared-utils';

import { DrawerSpaceContainerApi, DrawerSpaceContainerData } from './types';

export const DrawerSpaceContainerDataContext =
  createContext<DrawerSpaceContainerData>({
    isXs: true,
    isSm: false,
    isMd: false,
    isLg: false,
    isXl: false,
    isTablet: false,
    isDesktop: false,
    isMainOpen: true,
    isContextualOpen: false,
    isMainDisabled: false,
    isContextualDisabled: false,
    portalRoot: null,
    contentRoot: null,
    contextualDrawerContent: null,
    contextualDrawerHeader: null,
    contextualDrawerContentProps: EMPTY_OBJ,
  });

export const DrawerSpaceContainerContext =
  createContext<DrawerSpaceContainerApi>({
    onSetRef: NOOP,
    onSetContentRoot: NOOP,
    onToggleOpen: () => NOOP,
    onClose: NOOP,
    setIsContextualDisabled: NOOP,
    mountContextualDrawer: NOOP,
    unmountContextualDrawer: NOOP,
    updateContextualDrawerContentProps: NOOP,
  });
