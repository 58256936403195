import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    divider: {
      marginLeft: styling.sizes.theme.spacing.md * -1,
      marginRight: styling.sizes.theme.spacing.md * -1,
      width: `calc(100% + ${styling.utils.px(
        styling.sizes.theme.spacing.md * 2
      )})`,
    },
  },
  { name: 'addButtonModalContent' }
);
