import { memo } from 'react';
import { Typography } from '@kitted/shared-components';

import { FormProvider } from '../../../contexts/FormContext';
import Form from '../../Form';
import FormFields from '../../FormFields';
import AuthenticationModalContentWrapper from '../AuthenticationModalContentWrapper';
import useVerifyUserModalContent from './hooks/useVerifyUserModalContent';
import { dataSchema, layoutSchema } from './constants';
import useStyles from './styles';
import { AuthVerifyModalProps } from './types';
import VerifyButton from './VerifyButton';

const AuthVerifyUserModalContent = ({
  onClose,
  preventClose,
  title,
  isRequired,
}: AuthVerifyModalProps) => {
  const { onSubmit, onSubmitSuccess } = useVerifyUserModalContent(onClose);
  const styles = useStyles();

  return (
    <AuthenticationModalContentWrapper
      preventClose={preventClose}
      title={title}
      isRequired={isRequired}
      onClose={onClose}
    >
      <Typography className={styles.verify}>
        Enter the code you received in your verification email below.
      </Typography>
      <FormProvider dataSchema={dataSchema}>
        <Form onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess}>
          <FormFields layout={layoutSchema} />
          <VerifyButton />
        </Form>
      </FormProvider>
    </AuthenticationModalContentWrapper>
  );
};

export default memo(AuthVerifyUserModalContent);
