import {
  EditDrawerSpaceApi,
  EditDrawerSpaceData,
} from '../../../contexts/EditDrawerSpaceContext';

export const getEditDrawerSpaceDrawerItemsByGroup = (
  items: EditDrawerSpaceData['items'],
  setSelectedDrawerItemSlug: EditDrawerSpaceApi['setSelectedDrawerItemSlug']
) => {
  const drawerItems = Object.entries(items).map(([slug, item]) => ({
    slug,
    onClick: () => setSelectedDrawerItemSlug(slug),
    ...item,
  }));

  return [
    drawerItems.filter((item) => item.drawerGroup === 'top'),
    drawerItems.filter((item) => item.drawerGroup === 'bottom'),
  ];
};
