import { memo } from 'react';

import { StoreSectionInitializerProps } from '../types';
import useAuthorStoreInitializer from './hooks/useAuthorStoreInitializer';

const AuthorStoreInitializer = ({
  onSectionLoaded,
}: StoreSectionInitializerProps) => {
  useAuthorStoreInitializer(onSectionLoaded);

  return null;
};

export default memo(AuthorStoreInitializer);
