import { KittedServiceEnum } from '@kitted/kitted-models';
import { AnyPageBlockModel, PageModel } from '@kitted/page-service-models';

import useRequests from '../../../contexts/RequestsContext/hooks/useRequests';
import useAsyncRequest from '../../useAsyncRequest';

const usePageRequests = () => {
  const { platformRequest } = useRequests();

  const [getPageBySlug, pageBySlugFetchState] = useAsyncRequest<
    PageModel<false>
  >((slug: PageModel['slug']) =>
    platformRequest(KittedServiceEnum.Page, {
      method: 'GET',
      url: `/v1/page/slug/${slug}/published`,
    })
  );

  const [getPageBlocks, pageBlocksFetchState] = useAsyncRequest<
    (AnyPageBlockModel<false> | AnyPageBlockModel<true>)[]
  >((id: PageModel['id']) =>
    platformRequest(KittedServiceEnum.Page, {
      method: 'GET',
      url: `/v1/page/${id}/published/blocks`,
    })
  );

  return {
    getPageBySlug,
    pageBySlugFetchState,
    getPageBlocks,
    pageBlocksFetchState,
  };
};

export default usePageRequests;
