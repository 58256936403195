import { memo } from 'react';
import { Typography } from '@kitted/shared-components';
import { NOOP } from '@kitted/shared-utils';
import { Times } from '@kitted/website-icons';

import IconButton from '../../IconButton';
import useStyles from './styles';
import { AuthenticationModalContentWrapperProps } from './types';

const AuthenticationModalContentWrapper = ({
  preventClose,
  title,
  children,
  isRequired = false,
  onClose = NOOP,
}: AuthenticationModalContentWrapperProps) => {
  const styles = useStyles();

  return (
    <div className={styles.modal} onClick={preventClose}>
      <div className={styles.topBar}>
        <Typography className={styles.title} variant="formTitle" noMargin>
          {title}
        </Typography>
        {!isRequired && (
          <IconButton
            className={styles.closeButton}
            onClick={onClose}
            icon={Times}
            size="sm"
            theme="secondary"
            variant="solid"
          />
        )}
      </div>
      <div className={styles.body}>{children}</div>
    </div>
  );
};

export default memo(AuthenticationModalContentWrapper);
