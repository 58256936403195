import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Mailbox: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 26 26"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m4.11 17.4-2.63 3.11M3.06 13.99l4.13 2.88 3.69-2.61" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M11 20.73v-9.86c0-1.96-1.6-3.56-3.56-3.56h-.31c-1.86 0-3.39 1.44-3.55 3.25M14.01 7.3H7.44" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M16.46 10.64c.86.25 1.36 1.16 1.11 2.02s-1.16 1.36-2.02 1.11a1.63 1.63 0 0 1-1.11-2.02c.25-.86 1.16-1.36 2.02-1.11M14.54 20.84v4.64M17.61 20.84v4.64M15.97 10.48V1.02h4.94v2.83H17.6" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M16.11 7.3h5.1c1.96 0 3.56 1.6 3.56 3.56v9.97H1.88c-.36 0-.65-.29-.65-.65v-7.31c0-.36.29-.65.65-.65h9.02" />
    </g>
  </WebsiteIcon>
);

export default memo(Mailbox);
