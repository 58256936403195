import { useCallback, useState } from 'react';

import { handleOnMenuOpenToggle } from '../../logic';

const useCardMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onMenuOpenToggle = useCallback(
    () => handleOnMenuOpenToggle(setIsMenuOpen),
    [setIsMenuOpen]
  );

  return {
    isMenuOpen,
    onMenuOpenToggle,
  };
};

export default useCardMenu;
