import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollRestoration = () => {
  const location = useLocation();

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      // Back off, browser, I got this...
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  useEffect(() => {
    window.top?.scrollTo(0, 0);
  }, [location]);
};

export default useScrollRestoration;
