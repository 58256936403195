import colors from './colors';
import transitions from './transitions';

const maybePx = (value: number | string): string => {
  if (typeof value === 'number') {
    return `${value}px`;
  }
  return value;
};

const px = (num: number | number[]): string => {
  if (typeof num === 'number') {
    return `${num}px`;
  }
  return num.map((val: number) => px(val)).join(' ');
};

const disabled = (color: string = colors.theme.primary700) => ({
  cursor: 'not-allowed',
  color,
});

const buttonReset = () => ({
  background: 'none',
  padding: 0,
  border: 'none',
  outline: 'none',
  color: 'inherit',
  cursor: 'pointer',
  appearance: 'none',
});

const buttonRightIconHover = () => ({
  '& > svg': {
    transition: transitions.utils.generate(['translate']),
  },
  '&:hover > svg, &:active > svg, &:focus > svg': {
    translate: '-3px 0',
  },
});

const absoluteFull = () => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const userDrag = (value = 'none') => ({
  WebkitUserDrag: value,
  KhtmlUserDrag: value,
  MozUserDrag: value,
  OUserDrag: value,
  userDrag: value,
});

const userSelect = (value = 'none') => ({
  WebkitUserSelect: value,
  KhtmlUserSelect: value,
  MozUserSelect: value,
  OUserSelect: value,
  userSelect: value,
});

export default {
  absoluteFull,
  buttonReset,
  buttonRightIconHover,
  disabled,
  px,
  maybePx,
  userDrag,
  userSelect,
};
