import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0057: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 128 72"
		height={72}
		width={128}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="M71.52 6.62C48.69 5.99 38.13 20.6 38.13 34.99s11.66 26.05 26.05 26.05c11.77 0 22.23-7.66 24.94-18.52 2.24-8.97.75-27.12-22.73-27.17-5.67-.01-19.08 4.46-19.38 15.98-.19 7.33 1.13 10.67 4.98 15.65 5.28 6.82 17.97 9.21 26.82.15 5.16-5.28 6.11-29.98-17.08-23.76-6.06 1.63-13.07 14-.84 20.24C64 45.2 73.8 45.28 73.77 34.98c-.02-6.29-11.36-9.33-12.17 0" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M1.7 35.12S29.59 70.37 64 70.37 126.3 36 126.3 36 98.4 1.63 64 1.63 1.7 35.12 1.7 35.12" />
    </g>
  </CardIcon>
);

export default memo(Sn0057);
