export const getEnvironmentDomain = (
  env: string,
  feature: string,
  domain: string
): string => {
  const envDomain = env === 'production' ? domain : `${env}.${domain}`;

  if (feature) {
    return `${feature}.${envDomain}`;
  }

  return envDomain;
};
