import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const ArrowToLineLeft: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 22"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M8.84 11h17.78M8.84 11l8.19-8.19M17.03 19.19 8.84 11M1.38 21.07V.93" />
    </g>
  </WebsiteIcon>
);

export default memo(ArrowToLineLeft);
