import { useCallback } from 'react';
import { AnyWebsocketClientMessage } from '@kitted/websocket-service-models';

import { sendAnyWebsocketClientMessage } from '../../../../services/websocket';
import { useWebsocketApi } from '../useWebsocketApi';

export const useWebsocketSendMessage = () => {
  const websocketApi = useWebsocketApi();

  const sendMessage = useCallback(
    (payload: AnyWebsocketClientMessage) => {
      if (websocketApi) {
        sendAnyWebsocketClientMessage(websocketApi, payload);
      }
    },
    [websocketApi]
  );

  return sendMessage;
};
