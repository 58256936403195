import { z } from 'zod';

import { WebsocketDownloadReadyServerMessageSchema } from './WebsocketDownloadReadyServerMessage';
import { WebsocketModelUpdateServerMessageSchema } from './WebsocketModelUpdateServerMessage';
import { WebsocketPongServerMessageSchema } from './WebsocketPongServerMessage';
import { WebsocketProcessMessageServerMessageSchema } from './WebsocketProcessMessageServerMessage';

export const AnyWebsocketServerMessageSchema = z.union([
  WebsocketDownloadReadyServerMessageSchema,
  WebsocketModelUpdateServerMessageSchema,
  WebsocketPongServerMessageSchema,
  WebsocketProcessMessageServerMessageSchema,
]);

export type AnyWebsocketServerMessage = z.infer<
  typeof AnyWebsocketServerMessageSchema
>;
