/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';
import { TriangleExclaim } from '@kitted/website-icons';

import Fade from '../Animations/Fade';
import ImageSkeleton from '../Loaders/ImageSkeleton';
import useImage from './hooks/useImage';
import useStyles from './styles';
import { ImageProps } from './types';

const Image = ({
  src,
  className,
  onLoad,
  aspectRatio,
  loaderTheme = 'secondary',
  canDrag = false,
  ...rest
}: ImageProps) => {
  const {
    imageRef,
    renderableSrc,
    isFallback,
    isLoaded,
    handleOnLoad,
    isErrored,
    handleOnError,
    containerStyle,
  } = useImage({
    src,
    onLoad,
    aspectRatio,
  });
  const styles = useStyles();

  return (
    <div
      {...rest}
      className={clsx(
        className,
        styles.container,
        canDrag && styles.canDrag,
        styles[`color-${loaderTheme}`]
      )}
      style={containerStyle}
    >
      {loaderTheme !== 'none' && (
        <Fade
          className={styles.fadeContainer}
          in={!isLoaded && !isErrored}
          durationIn={500}
          durationOut={500}
        >
          <ImageSkeleton className={styles.imageSkeleton} theme={loaderTheme} />
        </Fade>
      )}
      {isErrored && <TriangleExclaim className={styles.icon} />}
      <picture className={styles.picture}>
        <img
          ref={imageRef}
          className={clsx(
            styles.image,
            isLoaded && !isFallback && !isErrored && styles.imageLoaded
          )}
          onLoad={handleOnLoad}
          onError={handleOnError}
          src={renderableSrc}
        />
      </picture>
    </div>
  );
};

export default memo(Image);
