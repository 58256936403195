import { memo } from 'react';

import usePreloadRoutes from './hooks/usePreloadRoutes';
import { PreloadRoutesProps } from './types';

const PreloadRoutes = ({ routeKeys }: PreloadRoutesProps) => {
  usePreloadRoutes(routeKeys);

  return null;
};

export default memo(PreloadRoutes);
