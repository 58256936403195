import { useWebsocketManagement } from './hooks';
import { WebSocketContextProps } from './types';
import { WebSocketContext } from './WebsocketContext';

export const WebsocketProvider: React.FC<WebSocketContextProps> = ({
  children,
}) => {
  const websocketManagement = useWebsocketManagement();

  return (
    <WebSocketContext.Provider value={websocketManagement}>
      {children}
    </WebSocketContext.Provider>
  );
};
