import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Gem: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="m14 26.24 12.38-14.02-3.23-5.35H4.85l-3.23 5.35L14 26.24M1.87 12.22h6.77m3.15 0h14.34m-17.55 0 5.41 13.83M8.56 12.16 6.38 9.09m7.32-2.11-5.04 5.14m10.77.1-5.41 13.83m5.43-13.89 3.69-5.19m-8.83.01 5.04 5.14M14.01 3.7V1.76m6.07 1.94L21.39 2M7.92 3.7 6.61 2" />
    </g>
  </WebsiteIcon>
);

export default memo(Gem);
