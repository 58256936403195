import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      position: 'fixed',
      bottom: styling.sizes.context.gutteringSmW,
      right: 'calc(100vw - 100%)',
      zIndex: styling.zIndex.toast,
      maxWidth: 402,
      width: `calc(100% - ${styling.utils.px(
        styling.sizes.context.gutteringSmW * 2
      )})`,
      display: 'flex',
      flexDirection: 'column',
      gap: styling.sizes.context.gutteringSmW,
    },
  },
  { name: 'toastRoot' }
);
