import { useContext } from 'react';

import { RoutePreloadContext } from '../../RoutePreloadContext';

const useRoutePreloadApi = () => {
  const routePreloadService = useContext(RoutePreloadContext);

  return routePreloadService;
};

export default useRoutePreloadApi;
