import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0027: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 82 86"
		height={86}
		width={82}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m51.39 12.75 12.98 7.51M30.62 12.75l-12.99 7.51" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M46.75 6.67a5.75 5.75 0 0 1-11.5 0C35.25 3.5 37.83.92 41 .92s5.75 2.58 5.75 5.75M41 13.06v17.22" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M7.21 38.29v15.02M74.79 38.29v15.02" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M23.82 85.09V72.07c0-6.14 4.44-8.41 10.16-8.41l7.03 7.74 6.52-7.74c5.72 0 10.66 2.27 10.66 8.41v13.02M49.38 48.65c0 4.68-3.77 8.51-8.38 8.51s-8.38-3.83-8.38-8.51V45.5c0-4.68 3.77-8.51 8.38-8.51s8.38 3.83 8.38 8.51z" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M77.74 31.14c-2.75 1.59-6.27.66-7.86-2.09s-.66-6.27 2.09-7.86 6.27-.66 7.86 2.09.66 6.27-2.09 7.86M69.33 29.37l-14.67 8.52M4.26 31.14c2.75 1.59 6.27.66 7.86-2.09s.66-6.27-2.09-7.86-6.27-.66-7.86 2.09a5.74 5.74 0 0 0 2.09 7.86M12.67 29.37l14.67 8.52" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M77.75 60.46c-2.75-1.59-6.27-.66-7.86 2.09s-.66 6.27 2.09 7.86 6.27.66 7.86-2.09a5.76 5.76 0 0 0-2.09-7.86M69.33 62.23l-14.28-8.3M4.26 60.46c2.74-1.59 6.27-.66 7.86 2.09s.66 6.27-2.09 7.86-6.27.66-7.86-2.09a5.754 5.754 0 0 1 2.1-7.86ZM12.67 62.23l14.28-8.3" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0027);
