import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    item: {
      position: 'relative',
      height: 48,
      borderBottom: [1, 'solid', styling.colors.theme.primary700],
    },
    itemOpen: {
      '& $icon': {
        transform: 'translateX(0)',
      },
    },
    itemSelected: {
      overflow: 'visible',
    },
    icon: {
      flexShrink: 0,
      flexGrow: 0,
      height: '100%',
      position: 'relative',
      width: 66,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '1.8rem',
      transform: 'translateX(-9px)',
      transition: styling.transitions.utils.generate(['transform']),
    },
  },
  { name: 'mainMenuItem' }
);
