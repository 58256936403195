import { useMemo } from 'react';

import { getPlayerComponentByType } from '../../logic';
import { MediaPlayerType } from '../../types';

const useMediaPlayerComponent = (playerType: MediaPlayerType) => {
  const PlayerComponent = useMemo(
    () => getPlayerComponentByType(playerType),
    [playerType]
  );

  return {
    PlayerComponent,
  };
};

export default useMediaPlayerComponent;
