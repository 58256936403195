import { useCallback, useEffect } from 'react';
import { KittedModelTypeEnum, KittedServiceEnum } from '@kitted/kitted-models';
import { VideoResourceModel } from '@kitted/resource-service-models';
import { WebsocketModelUpdateServerMessage } from '@kitted/websocket-service-models';

import useVideoResourceRequests from '../../../../../../hooks/resources/useVideoResourceRequests';
import { useSubscribeToWebsocketModelUpdateServerMessage } from '../../../../../WebsocketContext';
import useStoreApi from '../../../../hooks/useStoreApi';
import useStoreSection from '../../../../hooks/useStoreSection';
import { StoreSectionInitializerProps } from '../../../types';

const useVideoResourceStoreInitializer = (
  onSectionLoaded: StoreSectionInitializerProps['onSectionLoaded']
) => {
  const { getVideoResource } = useVideoResourceRequests();
  const { createOrUpdateStoreSection } = useStoreApi();
  const { updateStoreSectionItem } = useStoreSection('VideoResources');

  const updateVideoResourcesStoreSectionFetcher = useCallback(() => {
    createOrUpdateStoreSection('VideoResources', getVideoResource);
    onSectionLoaded('VideoResources');
  }, [createOrUpdateStoreSection, getVideoResource, onSectionLoaded]);

  useEffect(updateVideoResourcesStoreSectionFetcher, [
    updateVideoResourcesStoreSectionFetcher,
  ]);

  const handleWebsocketItemUpdated = useCallback(
    ({ model, service }: WebsocketModelUpdateServerMessage) => {
      if (
        model &&
        model.kittedModelType === KittedModelTypeEnum.ResourceVideoResource &&
        service === KittedServiceEnum.Resource
      ) {
        const asset = model as VideoResourceModel<false>;
        updateStoreSectionItem(asset, asset.id);
      }
    },
    [updateStoreSectionItem]
  );

  useSubscribeToWebsocketModelUpdateServerMessage(handleWebsocketItemUpdated);
};

export default useVideoResourceStoreInitializer;
