import { FieldValue } from '../../../contexts/FormContext';
import { ValidationFn } from './types';

export type ValidationItemIsRequired = {
  name: 'isRequired';
  isRequired: true;
  validator: ValidationFn;
};

export const isRequired: ValidationItemIsRequired = {
  name: 'isRequired',
  isRequired: true,
  validator: (value: FieldValue) => {
    if (value === '' || value === null || value === undefined) {
      return 'This is required';
    }
    // if (field?.type === 'checkbox' && !value) {
    //   return 'This is required';
    // }
    return undefined;
  },
};
