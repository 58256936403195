import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

import { overlayBorderStyles, overlayStyles } from '../styles';

const cardBodyPaddingTop = 14;

export default createUseStyles(
  {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      '&:before': {
        ...overlayStyles(),
      },
      '&:after': {
        ...overlayBorderStyles(),
      },
    },
    containerActive: {
      '&:before': {
        opacity: 1,
      },
    },
    containerFeatured: {
      '&:before, &:after': {
        content: 'unset',
      },
      '& $divider': {
        left: 0,
        right: 0,
        width:
          'calc(100% - var(--cardPaddingInline) - var(--cardPaddingInline))',
      },
    },
    containerMenuOpen: {
      '&:before, &:after': {
        opacity: 1,
      },
    },
    content: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      zIndex: 5,
      height: '100%',
      flexGrow: 1,
      transition: styling.speeds.jog,
      paddingInline: 'var(--cardPaddingInline)',
    },
    cardBody: {
      paddingTop: cardBodyPaddingTop,
      boxSizing: 'content-box',
    },
    divider: {
      left: 0,
      right: 0,
      zIndex: 7,
      width: 'calc(100% - 72px)',
    },
  },
  { name: 'subjectCard' }
);
