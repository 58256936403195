import {
  constructAuthenticatedRoute,
  constructPublicRoute,
  removeParentPathFromRoute,
} from '../helpers';
import { flowTitles } from '../routeTitles';

const flowAuthorTitleCodeBasePath = '/flow/:authorSlug/:titleSlug/:code';

export const flowOutlets = {
  flowAuthorTitleCodePlay: constructPublicRoute(
    `${flowAuthorTitleCodeBasePath}/play`,
    [flowTitles, 'Flow']
  ),
} as const;

export type FlowOutlets = typeof flowOutlets;

export const flowRedirectRoutes = {
  flowCode: constructPublicRoute(
    '/flow/:code',
    'Flow',
    flowAuthorTitleCodeBasePath
  ),
  flowTitleCode: constructPublicRoute(
    '/flow/:titleSlug/:code',
    [flowTitles, 'Flow'],
    flowAuthorTitleCodeBasePath
  ),
} as const;

export const flowRoutes = {
  ...flowRedirectRoutes,
  flowAuthorTitleCode: constructPublicRoute<FlowOutlets>(
    flowAuthorTitleCodeBasePath,
    [flowTitles, 'Flow'],
    undefined,
    undefined,
    {
      flowAuthorTitleCodePlay: removeParentPathFromRoute(
        flowAuthorTitleCodeBasePath,
        flowOutlets.flowAuthorTitleCodePlay
      ),
    }
  ),
  flowAuthorTitleCodeEdit: constructAuthenticatedRoute(
    `${flowAuthorTitleCodeBasePath}/edit`,
    [flowTitles, 'Edit Flow']
  ),
} as const;
