import { useMemo } from 'react';

import useQueryParamCaptureManagement from './hooks/useQueryParamCaptureManagement';
import { QueryParamCaptureContext } from './QueryParamCaptureContext';
import { QueryParamCaptureContextProps } from './types';

export const QueryParamCaptureProvider = ({
  children,
}: QueryParamCaptureContextProps) => {
  const { getCapturedQueryParams } = useQueryParamCaptureManagement();
  const apiValue = useMemo(
    () => ({ getCapturedQueryParams }),
    [getCapturedQueryParams]
  );

  return (
    <QueryParamCaptureContext.Provider value={apiValue}>
      {children}
    </QueryParamCaptureContext.Provider>
  );
};
