import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    createAccount: {
      ...styling.utils.buttonReset(),
    },
    createAccountDisabled: {
      ...styling.utils.disabled(styling.colors.theme.primary100),
    },
    join: {
      width: '100%',
      textAlign: 'center',
    },
  },
  { name: 'loginButton' }
);
