import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      textAlign: 'center',
      margin: [0, 'auto'],
      maxWidth: 750,
      padding: [60, 0],
    },
    title: {
      marginBottom: 66,
    },
    button: {
      maxWidth: styling.sizes.utils.gridColumns(
        2,
        styling.sizes.context.gutteringW
      ),
      margin: [20, 'auto', 0],
    },
  },
  { name: 'errorComponent' }
);
