import { useEffect } from 'react';

import {
  WebsocketEvent,
  WebsocketSubscription,
} from '../../../../services/websocket';
import { useWebsocketApi } from '../useWebsocketApi';

export const useSubscribeToWebsocket = (
  subscriptionType: WebsocketEvent,
  callback: WebsocketSubscription
) => {
  const websocketService = useWebsocketApi();

  useEffect(() => {
    const handle = websocketService?.subscribe(subscriptionType, callback);

    return () => {
      if (handle) {
        websocketService?.unsubscribe(handle);
      }
    };
  }, [websocketService, subscriptionType, callback]);
};
