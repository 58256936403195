import { useCallback, useEffect } from 'react';

const useSetScrollbarWidth = () => {
  const setScrollbarWidth = useCallback(() => {
    const scrollDiv = document.createElement('div');
    scrollDiv.className = 'scrollbar-measure';
    document.body.appendChild(scrollDiv);

    // Get the scrollbar width
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

    // Delete the DIV
    document.body.removeChild(scrollDiv);
    document.documentElement.style.setProperty(
      '--scrollbarWidth',
      `${scrollbarWidth}px`
    );
  }, []);

  useEffect(() => {
    setScrollbarWidth();
  }, [setScrollbarWidth]);
};

export default useSetScrollbarWidth;
