import { z } from 'zod';
import {
  CardLoadedModelSchema,
  CardType,
  CardUnloadedModelSchema,
  ImageAssetModelSchema,
  makeKittedModelReferenceSchema,
} from '@kitted/kitted-models';

const DisplayCardBaseModelSchema = z.object({
  cardType: z.literal(CardType.Display),
  title: z.string(),
  description: z.string(),
  videoUrl: z.string().optional(),
  buttonUrl: z.string(),
  actionButtonTitle: z.string(),
  autoPlay: z.boolean().optional(),
});

export const DisplayCardLoadedImageAssetModelSchema =
  DisplayCardBaseModelSchema.merge(
    z.object({
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        true
      ),
    })
  );

export const DisplayCardUnloadedImageAssetModelSchema =
  DisplayCardBaseModelSchema.merge(
    z.object({
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        false
      ),
    })
  );

export const DisplayCardLoadedModelSchema = CardLoadedModelSchema.merge(
  DisplayCardLoadedImageAssetModelSchema
);
export const DisplayCardUnloadedModelSchema = CardUnloadedModelSchema.merge(
  DisplayCardUnloadedImageAssetModelSchema
);

export type DisplayCardLoadedModel = z.infer<
  typeof DisplayCardLoadedModelSchema
>;
export type DisplayCardUnloadedModel = z.infer<
  typeof DisplayCardUnloadedModelSchema
>;

export type DisplayCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? DisplayCardLoadedModel : DisplayCardUnloadedModel;
