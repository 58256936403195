import { FormSubmitButtonDataContext } from './FormSubmitButtonDataContext';
import { useFormSubmitButtonManagement } from './hooks';
import { FormSubmitButtonContextProps } from './types';

export const FormSubmitButtonProvider: React.FC<
  FormSubmitButtonContextProps
> = ({ children }) => {
  const formSubmitButtonManagement = useFormSubmitButtonManagement();

  return (
    <FormSubmitButtonDataContext.Provider value={formSubmitButtonManagement}>
      {children}
    </FormSubmitButtonDataContext.Provider>
  );
};
