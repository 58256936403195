import { ErrorCapture } from '../../../ErrorCaptureContext/types';

export const getServiceBaseUrl = (
  environmentDomain: string,
  serviceName: string,
  websocketSubdomain: string
) => {
  const baseUrl = `wss://${websocketSubdomain}.${serviceName}.${environmentDomain}`;

  return baseUrl;
};

export type WebsocketErrorEventInfo = {
  url: string;
  type: string;

  isDestroyed: boolean;
  readyState?: number;
  protocol?: string;
  extensions?: string;
  bufferedAmount?: number;
  binaryType?: BinaryType;

  message?: string;
  filename?: string;
  lineno?: number;
  colno?: number;
  error?: string;
  isTrusted?: boolean;
};

export const triggerErrorCapture = (
  errorCapture: ErrorCapture,
  websocketEventInfo: WebsocketErrorEventInfo
) => {
  // eslint-disable-next-line no-console
  console.log(
    `Websocket Error - ${websocketEventInfo.type}`,
    websocketEventInfo
  );

  errorCapture.error(
    `Websocket Error - ${websocketEventInfo.type}`,
    websocketEventInfo
  );
};
