import { constructAuthenticatedRoute, constructPublicRoute } from '../helpers';

const discoveryAllBasePath = '/discovery/all';

export const discoveryRedirectRoutes = {
  discovery: constructPublicRoute(
    '/discovery',
    'Discovery',
    discoveryAllBasePath
  ),
};

export const discoveryRoutes = {
  ...discoveryRedirectRoutes,
  discoveryAll: constructPublicRoute(discoveryAllBasePath, 'Discovery'),
  discoverySaved: constructAuthenticatedRoute('/discovery/saved', [
    'Saved',
    'Discovery',
  ]),
  discoveryMyKits: constructAuthenticatedRoute('/discovery/my-kits', [
    'My Kits',
    'Discovery',
  ]),
  discoveryMyFlows: constructAuthenticatedRoute('/discovery/my-flows', [
    'My Flows',
    'Discovery',
  ]),
  discoveryMyResources: constructAuthenticatedRoute('/discovery/my-resources', [
    'My Resources',
    'Discovery',
  ]),
  discoveryMyArticles: constructAuthenticatedRoute('/discovery/my-articles', [
    'My Articles',
    'Discovery',
  ]),
  discoveryMyPlays: constructAuthenticatedRoute('/discovery/my-plays', [
    'My Plays',
    'Discovery',
  ]),
} as const;
