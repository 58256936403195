import { constructPublicRoute, removeParentPathFromRoute } from '../helpers';

const legalsBasePath = '/legals';

export const legalsOutlets = {
  legalsTerms: constructPublicRoute(`${legalsBasePath}/terms`, 'Terms'),
  legalsPrivacy: constructPublicRoute(`${legalsBasePath}/privacy`, 'Privacy'),
  legalsCookies: constructPublicRoute(`${legalsBasePath}/cookies`, 'Cookies'),
  legalsAcceptableUse: constructPublicRoute(
    `${legalsBasePath}/acceptable-use`,
    'Acceptable Use'
  ),
} as const;

export type LegalsOutlets = typeof legalsOutlets;

export const legalsRoutes = {
  legals: constructPublicRoute<LegalsOutlets>(
    legalsBasePath,
    'Legals',
    undefined,
    undefined,
    {
      legalsTerms: removeParentPathFromRoute(
        legalsBasePath,
        legalsOutlets.legalsTerms
      ),
      legalsPrivacy: removeParentPathFromRoute(
        legalsBasePath,
        legalsOutlets.legalsPrivacy
      ),
      legalsCookies: removeParentPathFromRoute(
        legalsBasePath,
        legalsOutlets.legalsCookies
      ),
      legalsAcceptableUse: removeParentPathFromRoute(
        legalsBasePath,
        legalsOutlets.legalsAcceptableUse
      ),
    }
  ),
} as const;
