import { AuthenticateUserChallenge } from 'quidproquo';

export const getTitleFromChallenge = (challenge: AuthenticateUserChallenge) => {
  switch (challenge) {
    case 'NEW_PASSWORD_REQUIRED':
      return 'New Password Required';
    default:
      return 'Challenge';
  }
};
