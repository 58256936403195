import { memo } from 'react';
import clsx from 'clsx';
import { styling } from '@kitted/design-system';

import BY from './BY';
import CC from './CC';
import NC from './NC';
import ND from './ND';
import useStyles from './styles';
import { CCLincenseProps } from './types';

const CCLincense = ({
  className,
  fill = styling.colors.theme.primary600,
  isStacked,
}: CCLincenseProps) => {
  const styles = useStyles();
  return (
    <div
      className={clsx(
        className,
        styles.container,
        isStacked && styles.containerStacked
      )}
    >
      <CC fill={fill} />
      <BY fill={fill} />
      <NC fill={fill} />
      <ND fill={fill} />
    </div>
  );
};

export default memo(CCLincense);
