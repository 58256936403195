import { useCallback, useEffect, useRef } from 'react';
import { v4 } from 'uuid';

import useAlertApi from '../../contexts/AlertContext/hooks/useAlertApi';
import useNavigationBlocker from '../useNavigationBlocker';
import { NavigateBlockerPredicate } from '../useNavigationBlocker/types';
import UnsavedChangesAlertBody from './UnsavedChangesAlertBody';

// TODO: @haxxxton warn when multiple blockers are used on the one screen
const useUnsavedChangesNavigationBlocker = (
  shouldBlock: NavigateBlockerPredicate,
  onAlertDismissed?: () => void
) => {
  const blockingAlertId = useRef<string>(v4());
  const {
    isBlocking,
    onCancel: onCancelNavigation,
    onProceed,
  } = useNavigationBlocker(shouldBlock);
  const { registerAlert, deregisterAlert } = useAlertApi();

  const onCancel = useCallback(() => {
    onCancelNavigation();
    onAlertDismissed?.();
  }, [onAlertDismissed, onCancelNavigation]);

  const onIsBlockingChange = useCallback(
    (shouldDisplay: boolean) => {
      if (!shouldDisplay) {
        deregisterAlert(blockingAlertId.current);
      } else {
        registerAlert(blockingAlertId.current, {
          title: 'You have unsaved changes',
          bodyComponent: UnsavedChangesAlertBody,
          variant: 'default',
          meta: {
            buttons: [
              {
                title: 'Continue to exit',
                onClick: onProceed,
              },
              {
                title: 'Cancel',
                onClick: onCancel,
              },
            ],
          },
        });
      }
    },
    [deregisterAlert, registerAlert, onCancel, onProceed]
  );

  useEffect(
    () => onIsBlockingChange(isBlocking),
    [isBlocking, onIsBlockingChange]
  );
};

export default useUnsavedChangesNavigationBlocker;
