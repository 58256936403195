import { EMPTY_ARR } from '../empty';
import { compare } from './compare';
import { SortPredicate } from './types';

/*
 * predicate:
 	{
		colName: 'firstName', `string`
		ascending: true, `boolean`
 	}
 */
export const sort = <SortableItem extends Record<string, unknown>>(
  items: SortableItem[],
  predicates: SortPredicate<SortableItem>[] = EMPTY_ARR
) => {
  if (predicates.length) {
    return items.sort((a, b) =>
      // for each predicate
      predicates.reduce((direction, predicate) => {
        // only need to worry about subsequent predicates if the first is the same
        if (direction !== 0) {
          return direction;
        }
        // compare
        return compare<SortableItem>(a, b, predicate);
      }, 0)
    );
  }
  return items;
};
