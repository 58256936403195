import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0057: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 90 100"
		height={100}
		width={90}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="m16.39 90.59-9.43-8.26" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M86.95 12.64 76.13 3.17C73.54.9 69.31 1.43 66.72 4.34l-5.43 6.1 20.23 17.71 5.43-6.1c2.59-2.91 2.59-7.15 0-9.41" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M75.23 35.12 32.76 82.87 1.11 98.24l11.51-32.97 42.76-48.08M23.95 72.65 65.45 26M33.9 81.36 14.01 63.95" />
    </g>
  </CardIcon>
);

export default memo(Ac0057);
