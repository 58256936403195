import { clearTimeout, setTimeout } from 'worker-timers';

export const handleMouseEnter = (
  setOpen: (open: boolean) => void,
  timerRef: number | undefined,
  setTimerRef: (newTimerRef?: number) => void
) => {
  if (typeof timerRef === 'number') {
    clearTimeout(timerRef);
  }
  const timerId = setTimeout(() => {
    setOpen(true);
  }, 700);
  setTimerRef(timerId);
};

export const handleMouseLeave = (
  setOpen: (open: boolean) => void,
  timerRef: number | undefined,
  setTimerRef: (newTimerRef?: number) => void
) => {
  if (typeof timerRef === 'number') {
    clearTimeout(timerRef);
  }
  setTimerRef();
  setOpen(false);
};
