import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      '--bannerBackgroundColor': styling.colors.theme.primary800,
      '--bannerColor': styling.colors.context.color,
      '--bannerIconBackgroundColor': styling.colors.theme.primary400,
      '--bannerInnerBorderColor': styling.colors.theme.secondary300_30,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      boxSizing: 'border-box',
      boxShadow: styling.shadows.utils.boxShadowToString({
        x: 0,
        y: 4,
        blur: 48,
        spread: 0,
        color: styling.colors.theme.primary900_10,
        inset: null,
      }),
      overflow: 'hidden',
      borderRadius: styling.sizes.context.card.radius,
      padding: styling.sizes.theme.spacing.xs,
      gap: styling.sizes.theme.spacing.sm,
      background: 'var(--bannerBackgroundColor)',
      color: 'var(--bannerColor)',
      alignItems: 'center',
      textAlign: 'center',
      [styling.container.context.drawer.desktopUpString]: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        textAlign: 'left',
      },
      '&:before': {
        ...styling.utils.absoluteFull(),
        content: '""',
        pointerEvents: 'none',
        borderRadius: styling.sizes.context.card.radius,
        boxShadow: styling.shadows.utils.boxShadowToString({
          x: 0,
          y: 0,
          blur: 0,
          spread: 1,
          color: 'var(--bannerInnerBorderColor)',
          inset: 'inset',
        }),
      },
    },
    'theme-secondary': {
      '--bannerBackgroundColor': styling.colors.theme.pureWhite,
      '--bannerColor': styling.colors.context.inverted.color,
      '--bannerIconBackgroundColor': styling.colors.theme.primary800,
      '--bannerInnerBorderColor': styling.colors.theme.primary900_20,
    },
    'theme-primary': {},
    'theme-error': {
      '--bannerIconBackgroundColor': styling.colors.theme.action800,
    },
    'theme-info': {},
    'theme-success': {
      '--bannerIconBackgroundColor': styling.colors.theme.frame800,
    },
    iconContainer: {
      width: 48,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
    },
    icon: {
      position: 'relative',
      height: 48,
      width: 48,
      borderRadius: 48,
      color: styling.colors.context.color,
      backgroundColor: 'var(--bannerIconBackgroundColor)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonsContainer: {
      display: 'flex',
      gap: styling.sizes.theme.spacing.xs,
      flexDirection: 'column-reverse',
      width: '100%',
      [styling.container.context.drawer.desktopUpString]: {
        flexDirection: 'row-reverse',
        width: 'unset',
      },
    },
    closeButtonContainer: {
      flexShrink: 0,
      width: 18,
    },
    closeButton: {
      border: 'none',
      padding: 0,
      color: 'var(--bannerColor)',
      borderRadius: 0,
      width: '100%',
      height: '100%',
    },
    message: {
      position: 'relative',
      display: 'flex',
      gap: 4,
      flexGrow: 1,
      flexWrap: 'wrap',
      flexDirection: 'column',
      wordBreak: 'break-word',
    },
  },
  { name: 'banner' }
);
