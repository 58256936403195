import { memo } from 'react';
import { Typography } from '@kitted/shared-components';

const UnsavedChangesAlertBody = () => (
  <Typography variant="alertBody">
    Exiting will discard all of the changes you have made
  </Typography>
);

export default memo(UnsavedChangesAlertBody);
