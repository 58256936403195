import { memo } from 'react';
import clsx from 'clsx';

import useStyles from './styles';
import { CardFooterStackProps } from './types';

const CardFooterStack = ({ isMenuOpen }: CardFooterStackProps) => {
  const styles = useStyles();

  return (
    <div
      className={clsx(styles.container, isMenuOpen && styles.containerMenuOpen)}
    />
  );
};

export default memo(CardFooterStack);
