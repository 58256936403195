import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      '--collapseMenuMaxHeight': styling.utils.px(360),
      maxHeight: 0,
      minWidth: 264,
      transition: styling.speeds.jog,
      display: 'flex',
      position: 'absolute',
      width: '100%',
      zIndex: 5,
      borderRadius: 6,
    },
    containerOpen: {
      maxHeight: 'var(--collapseMenuMaxHeight)',
    },
    'direction-down': {
      top: 'calc(100% + 30px)',
    },
    'direction-up': {
      bottom: 'calc(100% + 30px)',
      '& > $list': {
        flexDirection: 'column-reverse',
      },
    },
    'docked-left': {
      left: 0,
    },
    'docked-right': {
      right: 0,
    },
    'theme-primary': {
      '&$containerOpen': {
        boxShadow: styling.shadows.context.dropdowns,
      },
      '& $listOpen': {
        background: styling.colors.theme.primary900,
        borderColor: styling.colors.theme.primary400,
      },
    },
    'theme-secondary': {
      '&$containerOpen': {
        boxShadow: styling.shadows.theme.primary900Xl,
      },
      '& $listOpen': {
        background: styling.colors.theme.pureWhite,
        borderColor: styling.colors.theme.primary900_20,
      },
    },
    list: {
      background: styling.colors.theme.transparent,
      border: [1, 'solid', styling.colors.theme.transparent],
      margin: 0,
      width: '100%',
      overflow: 'auto',
      borderRadius: 6,
      display: 'flex',
      flexDirection: 'column',
    },
    listOpen: {
      background: styling.colors.theme.primary900,
      borderColor: styling.colors.theme.primary400,
    },
  },
  { name: 'collapseMenu' }
);
