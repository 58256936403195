/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnimationItem } from 'lottie-web';

import { LottieProps } from './types';

export const calculateStyle = (
  width: LottieProps['width'],
  height: LottieProps['height']
) => ({
  width,
  height,
});

export const handleLoadAnimation = ({
  elementRef,
  lottieInstance,
  setLottieInstance,
  animationData,
  loadAnimation,
}: {
  elementRef: HTMLDivElement | null;
  lottieInstance: AnimationItem | null;
  setLottieInstance: (instance: AnimationItem) => void;
  animationData: LottieProps['animationData'];
  loadAnimation: (config: any) => AnimationItem;
}) => {
  if (elementRef && !lottieInstance) {
    setLottieInstance(
      loadAnimation({
        animationData,
        container: elementRef,
      })
    );
  }
  return () => {
    lottieInstance?.destroy();
  };
};
