import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0057: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 124 128"
		height={128}
		width={124}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m90.98 12.23 20.81 20.81M90.98 33.04l20.81-20.81" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m11.58 22.11 7.61 10.53 13.53-20.01" /><path vectorEffect="non-scaling-stroke" d="M10.53 4.62C13.98 2.29 18.14.93 22.61.93c11.94 0 21.62 9.68 21.62 21.62s-9.68 21.62-21.62 21.62S.99 34.49.99 22.54c0-3.87 1.02-7.51 2.8-10.65" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M52.77 85V31.15c0-3.48 2.76-6.34 6.14-6.34s6.14 2.86 6.14 6.34v32.3M77.31 63.93V59.3c0-3.48-2.76-6.34-6.14-6.34h-5.52" /><path vectorEffect="non-scaling-stroke" d="M89.59 66.12v-4.56c0-3.49-2.77-6.34-6.14-6.34h-7.37M92.6 127.07v-13.28c4.88-5.28 7.13-10.04 7.87-19.81l.91-27.15c.07-3.6-2.28-6.35-5.66-6.35h-5.65M46.07 75.34l-2.7-2.31c-3.22-2.88-5.3-3.45-6.43-3.81s-2.42-.38-2.42-.38c-3.14-.49-6.32 2.01-5.35 5.02l23.36 39.85s2.1 2.88 2.1 4.79v8.59" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M89.3 4.62C92.75 2.29 96.91.93 101.38.93 113.32.93 123 10.61 123 22.55s-9.68 21.62-21.62 21.62-21.62-9.68-21.62-21.62c0-3.87 1.02-7.51 2.8-10.65" />
    </g>
  </CardIcon>
);

export default memo(Fr0057);
