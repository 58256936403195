import { useCallback, useEffect } from 'react';
import { KittedModelTypeEnum, KittedServiceEnum } from '@kitted/kitted-models';
import { LinkResourceModel } from '@kitted/resource-service-models';
import { WebsocketModelUpdateServerMessage } from '@kitted/websocket-service-models';

import useLinkResourceRequests from '../../../../../../hooks/resources/useLinkResourceRequests';
import { useSubscribeToWebsocketModelUpdateServerMessage } from '../../../../../WebsocketContext';
import useStoreApi from '../../../../hooks/useStoreApi';
import useStoreSection from '../../../../hooks/useStoreSection';
import { StoreSectionInitializerProps } from '../../../types';

const useLinkResourceStoreInitializer = (
  onSectionLoaded: StoreSectionInitializerProps['onSectionLoaded']
) => {
  const { getLinkResource } = useLinkResourceRequests();
  const { createOrUpdateStoreSection } = useStoreApi();
  const { updateStoreSectionItem } = useStoreSection('LinkResources');

  const updateLinkResourcesStoreSectionFetcher = useCallback(() => {
    createOrUpdateStoreSection('LinkResources', getLinkResource);
    onSectionLoaded('LinkResources');
  }, [createOrUpdateStoreSection, getLinkResource, onSectionLoaded]);

  useEffect(updateLinkResourcesStoreSectionFetcher, [
    updateLinkResourcesStoreSectionFetcher,
  ]);

  const handleWebsocketItemUpdated = useCallback(
    ({ model, service }: WebsocketModelUpdateServerMessage) => {
      if (
        model &&
        model.kittedModelType === KittedModelTypeEnum.ResourceLinkResource &&
        service === KittedServiceEnum.Resource
      ) {
        const asset = model as LinkResourceModel<false>;
        updateStoreSectionItem(asset, asset.id);
      }
    },
    [updateStoreSectionItem]
  );

  useSubscribeToWebsocketModelUpdateServerMessage(handleWebsocketItemUpdated);
};

export default useLinkResourceStoreInitializer;
