import useModalData from '../../../../contexts/ModalContext/hooks/useModalData';

const useModalRoot = () => {
  const { modals } = useModalData();

  return {
    modals,
  };
};

export default useModalRoot;
