import { useContext } from 'react';

import { AlertDataContext } from '../..';

const useAlertData = () => {
  const { alerts, topAlert } = useContext(AlertDataContext);

  return {
    alerts,
    topAlert,
  };
};

export default useAlertData;
