import { useEffect } from 'react';

import useStoreItemSubscription from '../../../../../StoreContext/hooks/useStoreItemSubscription';
import useIsLoggingIn from '../../../useIsLoggingIn';

const useUserStoreItemSubscription = <ItemModel>(itemKey: string) => {
  const { item, state } = useStoreItemSubscription<ItemModel>('User', itemKey);
  const { isLoggingIn } = useIsLoggingIn();

  useEffect(() => {
    console.log(
      '[useUserStoreItemSubscription]',
      itemKey,
      state,
      item,
      isLoggingIn
    );
  }, [itemKey, state, item, isLoggingIn]);
};

export default useUserStoreItemSubscription;
