import { UserModel } from '@kitted/auth-service-models';

import useStoreItemSubscription from '../../../contexts/StoreContext/hooks/useStoreItemSubscription';
import { StoreSectionItemStates } from '../../../contexts/StoreContext/services/storeOfStores/types';

const useUser = () => {
  const { item: user, state } = useStoreItemSubscription<UserModel>(
    'User',
    'user'
  );

  return {
    user,
    isLoading: state !== StoreSectionItemStates.Ready,
  };
};

export default useUser;
