import { ImageSize } from '@kitted/kitted-models';
import { NaturalImage } from '@kitted/shared-components';

import AssetImage from '../../AssetImage';
import VideoEmbed from '../../VideoEmbed';
import { VideoAttrs } from '../../VideoEmbed/types';
import { LightboxModalMeta } from './types';

/*
  Source: https://stackoverflow.com/a/27728417/648350
  Matches:
  https://youtube.com/shorts/dQw4w9WgXcQ?feature=share
  //www.youtube-nocookie.com/embed/up_lNV-yoK4?rel=0
  http://www.youtube.com/user/Scobleizer#p/u/1/1p3vcRhsYGo
  http://www.youtube.com/watch?v=cKZDdG9FTKY&feature=channel
  http://www.youtube.com/watch?v=yZ-K7nCVnBI&playnext_from=TL&videos=osPknwzXEas&feature=sub
  http://www.youtube.com/ytscreeningroom?v=NRHVzbJVx8I
  http://www.youtube.com/user/SilkRoadTheatre#p/a/u/2/6dwqZw0j_jY
  http://youtu.be/6dwqZw0j_jY
  http://www.youtube.com/watch?v=6dwqZw0j_jY&feature=youtu.be
  http://youtu.be/afa-5HQHiAs
  http://www.youtube.com/user/Scobleizer#p/u/1/1p3vcRhsYGo?rel=0
  http://www.youtube.com/watch?v=cKZDdG9FTKY&feature=channel
  http://www.youtube.com/watch?v=yZ-K7nCVnBI&playnext_from=TL&videos=osPknwzXEas&feature=sub
  http://www.youtube.com/ytscreeningroom?v=NRHVzbJVx8I
  http://www.youtube.com/embed/nas1rJpm7wY?rel=0
  http://www.youtube.com/watch?v=peFZbP64dsU
  http://youtube.com/v/dQw4w9WgXcQ?feature=youtube_gdata_player
  http://youtube.com/vi/dQw4w9WgXcQ?feature=youtube_gdata_player
  http://youtube.com/?v=dQw4w9WgXcQ&feature=youtube_gdata_player
  http://www.youtube.com/watch?v=dQw4w9WgXcQ&feature=youtube_gdata_player
  http://youtube.com/?vi=dQw4w9WgXcQ&feature=youtube_gdata_player
  http://youtube.com/watch?v=dQw4w9WgXcQ&feature=youtube_gdata_player
  http://youtube.com/watch?vi=dQw4w9WgXcQ&feature=youtube_gdata_player
  http://youtu.be/dQw4w9WgXcQ?feature=youtube_gdata_player
*/
export const extractYoutubeIdFromUrl = (youtubeUrl: string) => {
  const youtubeUrlRegExp =
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]+).*/;
  const match = youtubeUrl.match(youtubeUrlRegExp);
  if (match?.[1]?.length === 11) {
    return match[1];
  }
  return '';
};

/*
  Source: https://stackoverflow.com/questions/10488943/easy-way-to-get-vimeo-id-from-a-vimeo-url#comment35026186_16841070
  Matches:
  http://vimeo.com/6701902
  http://vimeo.com/670190233
  //player.vimeo.com/video/67019023
  http://player.vimeo.com/video/6701902
  http://player.vimeo.com/video/67019022?title=0&byline=0&portrait=0
  http://player.vimeo.com/video/6719022?title=0&byline=0&portrait=0
  http://vimeo.com/channels/vimeogirls/6701902
  http://vimeo.com/channels/vimeogirls/67019023
  http://vimeo.com/channels/staffpicks/67019026
  http://vimeo.com/15414122
  http://vimeo.com/channels/vimeogirls/66882931
 */
export const extractVimeoIdFromUrl = (vimeoUrl: string) => {
  const vimeoUrlRegExp =
    /^.*(\/\/)??(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/;
  const match = vimeoUrl.match(vimeoUrlRegExp);
  return match?.[5] || '';
};

export const getVideoAttrsFromSrc = (
  videoSrc: LightboxModalMeta['videoSrc'] = ''
): VideoAttrs => {
  if (videoSrc.indexOf('youtube') >= 0) {
    return {
      player: 'youtube',
      id: extractYoutubeIdFromUrl(videoSrc),
      url: undefined as never,
      fullbleed: undefined as never,
    };
  }
  if (videoSrc.indexOf('vimeo') >= 0) {
    return {
      player: 'vimeo',
      id: extractVimeoIdFromUrl(videoSrc),
      url: undefined as never,
      fullbleed: undefined as never,
    };
  }

  return {
    player: 'video',
    url: videoSrc,
    id: undefined as never,
    fullbleed: undefined as never,
  };
};

export const getLightboxContentComponent = ({
  imgSrc,
  imageAsset,
  videoSrc,
  onImageLoad,
  onToggleImageIsZoomed,
}: {
  imgSrc: LightboxModalMeta['imgSrc'];
  imageAsset: LightboxModalMeta['imageAsset'];
  videoSrc: LightboxModalMeta['videoSrc'];
  onImageLoad: (img: HTMLImageElement, isFallback: boolean) => void;
  onToggleImageIsZoomed: () => void;
}) => {
  if (imgSrc || imageAsset) {
    return {
      component: AssetImage,
      as: NaturalImage,
      onLoad: onImageLoad,
      onClick: onToggleImageIsZoomed,
      canDrag: true,
      src: imgSrc,
      imageAsset,
      size: ImageSize.XLarge,
    };
  }
  if (videoSrc) {
    return {
      component: VideoEmbed,
      videoAttrs: getVideoAttrsFromSrc(videoSrc),
      initialState: {
        playing: true,
        muted: false,
      },
    };
  }
  return {
    component: null,
  };
};
