import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const ArrowsExpandCircleInfo: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="m26.57 1.46-6.5 6.49M26.61 9V1.38h-7.59M26.57 26.58l-6.5-6.5M19.02 26.62h7.59v-7.59M1.43 1.46l6.5 6.49M1.39 9V1.38h7.59M1.43 26.58l6.5-6.5M8.98 26.62H1.39v-7.59M12.27 11.41h1.8v6.63M12.27 18.1h3.57M14.19 9.25c0 .14-.11.26-.26.26s-.26-.11-.26-.26.12-.26.26-.26.26.11.26.26" /><circle vectorEffect="non-scaling-stroke" cx="14.02" cy="14.04" r="8.26" stroke={color} />
    </g>
  </WebsiteIcon>
);

export default memo(ArrowsExpandCircleInfo);
