import { FieldTypes, FormDataSchema } from '../../../contexts/FormContext';
import { isRequired, isValidURL } from '../../../services/helpers/validators';
import { FormLayoutSchema } from '../../FormFields/types';

export const dataSchema: FormDataSchema = {
  fields: {
    title: {
      type: FieldTypes.TEXT,
      validation: [isRequired],
    },
    url: {
      type: FieldTypes.URL,
      validation: [isValidURL(true)],
    },
  },
  form: {
    validationStrategy: 'onBlur',
  },
};

export const layoutSchema: FormLayoutSchema = {
  fields: [
    {
      key: 'title',
      label: 'Button text',
    },
    {
      key: 'url',
      label: 'Hyperlink',
      meta: {
        placeholder: 'your-website.com',
      },
    },
  ],
};
