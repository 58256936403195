import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0040: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 104 114"
		height={114}
		width={104}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="m59.01 86.5 30.28 12.12 13.74-97.46-5.85 3.94-5.12 3.44L6.9 65.84l28.8 10.63 9.57 25.99 8.59-18.02M42.96 68.44l59.29-66.26" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m53.86 84.44 3.13-5.29L102.5 2.21" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M7.08 74.62.97 80.96M15.77 78.11l-6.1 6.33M24.47 81.6l-6.11 6.33M81.07 104.55l-2.94 8.29M73.18 101.25l-2.94 8.29M65.28 97.95l-2.93 8.29" />
    </g>
  </CardIcon>
);

export default memo(Ac0040);
