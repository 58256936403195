import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0027: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 50 104"
		height={104}
		width={50}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <circle vectorEffect="non-scaling-stroke" cx="14.05" cy="46.51" r="4.93" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="25.96" cy="36.05" r="4.93" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="24.96" cy="21.88" r="4.93" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="25.96" cy="7.72" r="4.93" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="37.78" cy="27.61" r="4.93" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="38.78" cy="13.64" r="4.93" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="12.82" cy="33.09" r="4.93" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="11.82" cy="19.68" r="4.93" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="12.82" cy="6.26" r="4.93" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="28.01" cy="50.26" r="4.93" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="38.78" cy="41.58" r="4.93" stroke={spot} /><path vectorEffect="non-scaling-stroke" stroke={color} d="M27.19 73.22H16.38M48.81 1.33v37.74c0 9.9-5.81 18.44-14.1 22.28v36.62c0 2.59-2.12 4.7-4.7 4.7H19.98c-2.59 0-4.7-2.12-4.7-4.7V61.35C7 57.51 1.18 48.96 1.18 39.07V1.33" />
    </g>
  </CardIcon>
);

export default memo(Sn0027);
