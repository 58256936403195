import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0038: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 118 88"
		height={88}
		width={118}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M116.44 23.77s-9.12-.26-13.93 6.53c0 0-1.96 5.67-13.4 4.89" /><path vectorEffect="non-scaling-stroke" d="M77 24.95s6.2-6.61 8.19-7.02c0 0 13.14 4.23 17.15 11.9" /><path vectorEffect="non-scaling-stroke" d="M98.91 5.23s3.42 6.5 2.79 9.43c0 0-2.23 3.39-7.52 7.13" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M108.39 42.09s0 0 0 0c-.75 0-1.46-.24-2.05-.69a3.4 3.4 0 0 1-.64-4.74c1.11-1.44 3.3-1.74 4.74-.63a3.39 3.39 0 0 1 .64 4.74c-.65.84-1.63 1.33-2.69 1.33Z" /><g ><g stroke={spot}><path vectorEffect="non-scaling-stroke" d="M31.77 9s-6.27 8.7-16.06 8.35c0 0-6.7-2.22-14.15 9.03" /><path vectorEffect="non-scaling-stroke" d="M2.48 45.01s10.62-1.07 12.41-2.64c0 0 5.44-15.3 1.13-24.54" /><path vectorEffect="non-scaling-stroke" d="M36.58 38.63s-3.64-7.84-6.82-9.35c0 0-4.77-.33-12.04 1.93" /></g><g stroke={color}><path vectorEffect="non-scaling-stroke" d="M4.59 86.14V69.71c0-2.23 1.82-4.05 4.05-4.05h5.38c2.23 0 4.05 1.82 4.05 4.05v16.43M29.32 86.14V54.75c0-2.23 1.82-4.05 4.05-4.05h5.35c2.23 0 4.05 1.82 4.05 4.05v31.39M54.08 86.14V33.79c0-2.22 1.82-4.05 4.05-4.05h5.35c2.23 0 4.05 1.82 4.05 4.05v52.35" /></g></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M12.06 9.82c-2.19 0-3.98-1.78-3.98-3.98s1.79-3.98 3.98-3.98 3.98 1.79 3.98 3.98-1.78 3.98-3.98 3.98" />
    </g>
  </CardIcon>
);

export default memo(In0038);
