import { memo } from 'react';

import { WebSocketContextProps } from '../types';
import { useWebsocketAuthSync, useWebsocketPingPong } from './hooks';

const WebSocketAuthSync: React.FC<WebSocketContextProps> = ({ children }) => {
  // This could be moved out in the tree somewhere, if we decide to move the hook
  // to the ./src/hooks directory
  useWebsocketAuthSync();

  // Keep the active websocket alive
  useWebsocketPingPong();

  // This fragment is not useless!
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default memo(WebSocketAuthSync);
