import { memo } from 'react';
import clsx from 'clsx';

import useStyles from './styles';
import { StatusDotProps } from './types';

const StatusDot = ({
  className,
  dotClassName,
  children,
  enabled,
  status,
  align = 'start',
  side = 'left',
  on = 'rectangle',
}: StatusDotProps) => {
  const styles = useStyles();

  return (
    <div className={clsx(styles.container, className)}>
      {children}
      {enabled && (
        <div
          className={clsx(
            dotClassName,
            styles.dot,
            styles[`align-${align}`],
            styles[`status-${status}`],
            styles[`side-${side}`],
            styles[`on-${on}`]
          )}
        />
      )}
    </div>
  );
};

export default memo(StatusDot);
