import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0008: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 112 80"
		height={80}
		width={112}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M63.03 47.28 46.38 63.93c-3.11 3.11-3.11 8.2 0 11.32l.7.7c3.11 3.11 8.2 3.11 11.31 0L88.64 45.7c3.11-3.11 3.11-8.2 0-11.32L58.26 4.05c-3.11-3.11-8.2-3.11-11.31 0l-.7.7c-3.11 3.11-3.11 8.2 0 11.32l15.42 15.5H9.84c-4.4 0-8 3.6-8 8v.99c0 4.4 3.6 8 8 8h38.6" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M71.8 78.28a8 8 0 0 0 5.79-2.33l30.25-30.25c3.11-3.11 3.11-8.2 0-11.32L77.46 4.05a7.97 7.97 0 0 0-5.66-2.33" />
    </g>
  </CardIcon>
);

export default memo(In0008);
