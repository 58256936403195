import { useCallback, useState } from 'react';
import { Nullable } from '@kitted/platform-models';

import { EditDrawerSpaceContextProps } from '../../types';
import { getInitialSelectedDrawerItemSlug } from './logic';

const useEditDrawerSpaceManagement = (
  items: EditDrawerSpaceContextProps['items'],
  initialSelectedDrawerItemSlug: EditDrawerSpaceContextProps['initialSelectedDrawerItemSlug']
) => {
  const [bodyRef, setBodyRef] = useState<Nullable<HTMLDivElement>>(null);
  const [selectedDrawerItemSlug, setSelectedDrawerItemSlug] = useState<string>(
    getInitialSelectedDrawerItemSlug(items, initialSelectedDrawerItemSlug)
  );

  const onSetBodyRef = useCallback(
    (newBodyRef: Nullable<HTMLDivElement>) => {
      if (newBodyRef) {
        setBodyRef(newBodyRef);
      }
    },
    [setBodyRef]
  );

  return {
    selectedDrawerItemSlug,
    setSelectedDrawerItemSlug,
    bodyRef,
    onSetBodyRef,
  };
};

export default useEditDrawerSpaceManagement;
