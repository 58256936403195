import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const ShoppingBag: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 24 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M7.83 10.15V5.57c0-2.3 1.88-4.19 4.17-4.19s4.17 1.88 4.17 4.19v4.58M3.73 22.93h16.54M7.99 7.59h6.36" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M16.29 7.59h4.37c.5 0 .92.4.94.9l.79 16.61c.06 1.15-.82 1.52-2.01 1.52H3.61c-1.19 0-2.07-.37-2.01-1.52l.79-16.61c.02-.5.44-.9.94-.9H6" />
    </g>
  </WebsiteIcon>
);

export default memo(ShoppingBag);
