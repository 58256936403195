import { ToolTypeValues } from '@kitted/card-service-models';
import { styling } from '@kitted/design-system';

import { CardSubType } from '../types';

export const getToolCardStyle = (subType: CardSubType | undefined) =>
  ({
    '--cardSlugTypeColor':
      styling.colors.context.card.footerBackgrounds[subType as ToolTypeValues],
  }) as React.CSSProperties;
