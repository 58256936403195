import { FieldTypes, FormDataSchema } from '../../../../contexts/FormContext';
import {
  hasAtLeastXNumbers,
  hasAtLeastXSpecialCharacters,
  hasAtLeastXUpperAndXLowerLetters,
  hasMinLength,
  isRequired,
  mustMatchField,
  ValidationItemMustMatchRegex,
} from '../../../../services/helpers/validators';
import { FormLayoutSchema } from '../../../FormFields/types';

export const initialFormData = {
  password: '',
};

export const dataSchema: FormDataSchema = {
  fields: {
    email: {
      type: FieldTypes.EMAIL,
    },
    password: {
      type: FieldTypes.PASSWORD,
      validation: [
        hasMinLength(8),
        hasAtLeastXUpperAndXLowerLetters(false, 1, 1),
        hasAtLeastXNumbers(false, 1),
        hasAtLeastXSpecialCharacters(false, 1),
        isRequired,
      ],
    },
    confirmPassword: {
      type: FieldTypes.PASSWORD,
      validation: [mustMatchField('password', 'Passwords'), isRequired],
    },
  },
  form: {
    hasCaptcha: true,
    validationStrategy: 'onBlur',
  },
};

export const layoutSchema: FormLayoutSchema = {
  fields: [
    {
      key: 'email',
      label: null,
      customAttributes: {
        autoComplete: 'username',
      },
    },
    {
      key: 'password',
      label: 'Password',
      meta: {
        canShowHide: true,
        showStrengthIndicator: {
          items: [
            {
              label: 'minimum 8 characters',
              matchError:
                dataSchema.fields.password.validation?.[0].validator(''),
            },
            {
              label: 'mixture of uppercase and lowercase letters',
              matchError: (
                dataSchema.fields.password
                  .validation?.[1] as ValidationItemMustMatchRegex
              ).errorMessage,
            },
            {
              label: 'at least 1 number',
              matchError: (
                dataSchema.fields.password
                  .validation?.[2] as ValidationItemMustMatchRegex
              ).errorMessage,
            },
            {
              label: 'at least 1 special character',
              matchError: (
                dataSchema.fields.password
                  .validation?.[3] as ValidationItemMustMatchRegex
              ).errorMessage,
            },
          ],
        },
      },
      customAttributes: {
        autoComplete: 'new-password',
      },
    },
    {
      key: 'confirmPassword',
      label: 'Confirm Password',
      meta: {
        canShowHide: true,
      },
      customAttributes: {
        autoComplete: 'new-password',
      },
    },
  ],
};
