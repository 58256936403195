import { Typography } from '@kitted/shared-components';

import Button from '../Button';
import useErrorComponent from './hooks/useErrorComponent';
import useStyles from './styles';
import { ErrorComponentProps } from './types';

const ErrorComponent = ({ resetError }: ErrorComponentProps) => {
  useErrorComponent();
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Typography className={styles.title} variant="heading3">
        Oops!
      </Typography>
      <Typography variant="heading5">
        It looks like something escaped the simulation.
      </Typography>
      <Typography variant="body">
        Rest assured, our agents are on the case.{' '}
        {resetError && (
          <span>
            If you reckon this was just a glitch in the matrix, and wanna have
            another go, click below.
          </span>
        )}
      </Typography>
      {resetError && (
        <Button className={styles.button} fullWidth onClick={resetError}>
          Try again
        </Button>
      )}
    </div>
  );
};

export default ErrorComponent;
