import { z } from 'zod';

import { WebsocketClientMessageType } from './enum';
import { WebsocketClientMessageSchema } from './WebsocketClientMessage';

export const WebsocketUnauthenticateClientMessageSchema =
  WebsocketClientMessageSchema.merge(
    z.object({
      messageType: z.literal(WebsocketClientMessageType.Unauthenticate),
    })
  );

export type WebsocketUnauthenticateClientMessage = z.infer<
  typeof WebsocketUnauthenticateClientMessageSchema
>;
