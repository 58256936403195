import { z } from 'zod';

import { PublishType } from './Enum';

export const PublishSettingsSchema = z.object({
  publishType: z.nativeEnum(PublishType),
  password: z.string().optional(),
});

export type PublishSettings = z.infer<typeof PublishSettingsSchema>;
