import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Pencil: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M6 24.63 3.37 22m19.04-10.81L10.56 23.04l-8.83 3.19 3.21-8.8L16.86 5.49M8.1 19.89 19.67 8.31m-8.8 14.36-5.55-5.56M25.72 5.32 22.7 2.3c-.72-.72-1.9-.72-2.63 0l-1.51 1.51 5.64 5.65 1.51-1.51c.72-.72.72-1.9 0-2.63Z" />
    </g>
  </WebsiteIcon>
);

export default memo(Pencil);
