import { CardType } from '@kitted/kitted-models';
import { ResourceType } from '@kitted/resource-service-models';
import { extractCodeFromSlug, slugize } from '@kitted/shared-utils';
import { interpolateRoutePath, routes } from '@kitted/website-constants';

import { UserCreateableModel } from '../../types';

export const getInternalLinkAndTitleForCardType = (
  item: UserCreateableModel,
  cardType: CardType,
  resourceType?: ResourceType
): [string, string] => {
  const code = extractCodeFromSlug(item.slug);
  if (cardType === CardType.Article) {
    return [
      interpolateRoutePath(routes.articleAuthorTitleCodeEdit, {
        code,
        authorSlug: 'loading',
        titleSlug: slugize(item.title),
      }),
      'View Article page',
    ];
  }
  if (cardType === CardType.Play) {
    return [
      interpolateRoutePath(routes.playAuthorTitleCodeEdit, {
        code,
        authorSlug: 'loading',
        titleSlug: slugize(item.title),
      }),
      'View Play page',
    ];
  }
  if (cardType === CardType.Flow) {
    return [
      interpolateRoutePath(routes.flowAuthorTitleCodeEdit, {
        code,
        authorSlug: 'loading',
        titleSlug: slugize(item.title),
      }),
      'View Flow page',
    ];
  }
  if (cardType === CardType.Kit) {
    return [
      interpolateRoutePath(routes.kitTitleCodeEdit, {
        code,
        titleSlug: slugize(item.title),
      }),
      'View Kit page',
    ];
  }
  if (cardType === CardType.Resource) {
    switch (resourceType) {
      case ResourceType.Video:
        return [
          interpolateRoutePath(routes.resourceVideoTitleCode, {
            code,
            titleSlug: slugize(item.title),
          }),
          'View Video Resource page',
        ];
      case ResourceType.Link:
        return [
          interpolateRoutePath(routes.resourceLinkTitleCode, {
            code,
            titleSlug: slugize(item.title),
          }),
          'View Link Resource page',
        ];
      case ResourceType.Image:
        return [
          interpolateRoutePath(routes.resourceImageTitleCode, {
            code,
            titleSlug: slugize(item.title),
          }),
          'View Image Resource page',
        ];
      default:
        return ['', ''];
    }
  }
  return ['', ''];
};
