import { z } from 'zod';

import {
  AuthorCardLoadedModelSchema,
  AuthorCardUnloadedModelSchema,
} from '../Author';
import { KitCardLoadedModelSchema, KitCardUnloadedModelSchema } from '../Kit';
import {
  SubjectCardLoadedModelSchema,
  SubjectCardUnloadedModelSchema,
} from '../Subject';
import {
  AnyToolCardLoadedModelSchema,
  AnyToolCardUnloadedModelSchema,
} from '../Tool';

export const AnyCardWithRelatedCardsLoadedModelSchema = z.union([
  AuthorCardLoadedModelSchema,
  KitCardLoadedModelSchema,
  SubjectCardLoadedModelSchema,
  AnyToolCardLoadedModelSchema,
]);

export const AnyCardWithRelatedCardsUnloadedModelSchema = z.union([
  AuthorCardUnloadedModelSchema,
  KitCardUnloadedModelSchema,
  SubjectCardUnloadedModelSchema,
  AnyToolCardUnloadedModelSchema,
]);

export type AnyCardWithRelatedCardsLoadedModel = z.infer<
  typeof AnyCardWithRelatedCardsLoadedModelSchema
>;
export type AnyCardWithRelatedCardsUnloadedModel = z.infer<
  typeof AnyCardWithRelatedCardsUnloadedModelSchema
>;

export type AnyCardWithRelatedCardsModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? AnyCardWithRelatedCardsLoadedModel
    : AnyCardWithRelatedCardsUnloadedModel;
