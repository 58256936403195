import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const TeardropFilled: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 22 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" fill={color} fillRule="evenodd" d="m3.42 10.5 1.33-1.71 2.53-3.23 2.96-3.78c.4-.51 1.18-.51 1.58 0l6.79 8.77a9.8 9.8 0 0 1 2.2 6.21c0 5.44-4.4 9.84-9.82 9.84s-9.81-4.4-9.81-9.84c0-2.38.84-4.56 2.25-6.26Zm14.67 6.26c0-.33-.26-.59-.59-.59s-.59.26-.59.59c0 2.62-1.7 4.85-4.06 5.63-.31.1-.48.44-.38.75s.44.48.75.38c2.83-.93 4.87-3.6 4.87-6.75ZM11 22.69c-.33 0-.59.26-.59.59s.26.59.59.59.59-.26.59-.59-.26-.59-.59-.59" />
    </g>
  </WebsiteIcon>
);

export default memo(TeardropFilled);
