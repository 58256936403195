import { InputComponentProps } from '../types';

const startingHttp = /^https?:\/\//i;

export const getSanitizedValue = (
  type: InputComponentProps['type'],
  value: InputComponentProps['value']
) => {
  if (type !== 'url') return value;
  if (!value) return '';
  return `${value}`.replace(startingHttp, '');
};

export const generateHandleOnChange = (
  type: InputComponentProps['type'],
  onChange: InputComponentProps['onChange'],
  value: string
) => {
  if (type === 'number') {
    const parsedNumber = parseInt(value, 10);
    if (Number.isNaN(parsedNumber)) {
      onChange('');
    } else {
      onChange(parsedNumber);
    }
    return;
  }
  if (type === 'email') {
    onChange(value.toLowerCase());
    return;
  }
  if (type === 'url') {
    const newValue = value.replace(startingHttp, '');
    if (newValue) {
      onChange(`https://${newValue}`);
      return;
    }
    onChange(newValue);
    return;
  }
  onChange(value);
};

export const handleOnClearValue = (
  onChange: InputComponentProps['onChange'],
  inputRef: HTMLInputElement | null
) => {
  onChange('');
  if (inputRef) {
    inputRef.focus();
  }
};
