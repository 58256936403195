import { useCallback, useEffect } from 'react';
import { KitModel } from '@kitted/kit-service-models';
import { KittedServiceEnum } from '@kitted/kitted-models';
import { WebsocketModelUpdateServerMessage } from '@kitted/websocket-service-models';

import useKitRequests from '../../../../../../hooks/kits/useKitRequests';
import { useSubscribeToWebsocketModelUpdateServerMessage } from '../../../../../WebsocketContext';
import useStoreApi from '../../../../hooks/useStoreApi';
import useStoreSection from '../../../../hooks/useStoreSection';
import { StoreSectionInitializerProps } from '../../../types';

const useKitStoreInitializer = (
  onSectionLoaded: StoreSectionInitializerProps['onSectionLoaded']
) => {
  const { getKit } = useKitRequests();
  const { createOrUpdateStoreSection } = useStoreApi();
  const { updateStoreSectionItem } = useStoreSection('Kits');

  const updateKitsStoreSectionFetcher = useCallback(() => {
    createOrUpdateStoreSection('Kits', getKit);
    onSectionLoaded('Kits');
  }, [createOrUpdateStoreSection, getKit, onSectionLoaded]);

  useEffect(updateKitsStoreSectionFetcher, [updateKitsStoreSectionFetcher]);

  const handleWebsocketItemUpdated = useCallback(
    ({ model, service }: WebsocketModelUpdateServerMessage) => {
      if (model && service === KittedServiceEnum.Kit) {
        const asset = model as KitModel<false>;
        updateStoreSectionItem(asset, asset.id);
      }
    },
    [updateStoreSectionItem]
  );

  useSubscribeToWebsocketModelUpdateServerMessage(handleWebsocketItemUpdated);
};

export default useKitStoreInitializer;
