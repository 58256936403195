import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Magnify: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 30 30"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M26.84 14.29c0 6.94-5.63 12.57-12.57 12.57S1.69 21.24 1.69 14.29 7.32 1.72 14.26 1.72s12.57 5.63 12.57 12.57ZM23.48 23.45l4.83 4.83" />
    </g>
  </WebsiteIcon>
);

export default memo(Magnify);
