import { z } from 'zod';

import { WebsocketServerMessageType } from './enum';

export const WebsocketServerMessageSchema = z.object({
  messageType: z.nativeEnum(WebsocketServerMessageType),
  correlationId: z.string().optional(),
  service: z.string().optional(),
});

export type WebsocketServerMessage = z.infer<
  typeof WebsocketServerMessageSchema
>;
