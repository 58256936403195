import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0013: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 110 114"
		height={114}
		width={110}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M65.85 82.68h32.64c5.5 0 10-4.5 10-10V11.82c0-5.5-4.5-10-10-10H11.51c-5.5.01-10 4.5-10 10v60.86c0 5.5 4.5 10 10 10H25.4v29.49l26.49-29.49" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M45.95 41.23h4.56v4.56h-4.56zM51.79 34.21c-2.81 2.34-4.75 4.68-5.84 7.01M58.21 41.23h4.56v4.56h-4.56zM64.05 34.21c-2.81 2.34-4.75 4.68-5.84 7.01" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0013);
