import { memo } from 'react';

import { FormProvider } from '../../contexts/FormContext';
import PreloadRoutes from '../../contexts/RoutePreloadContext/PreloadRoutes';
import EditDrawerSpace from '../EditDrawerSpace';
import LoadingOverlay from '../LoadingOverlay';
import useResourceModelCreate from './hooks/useResourceModelCreate';
import { EDIT_ROUTE_KEYS } from './constants';
import ResourceModelCreateFormFooter from './ResourceModelCreateFormFooter';
import { ResourceModelCreateProps } from './types';

const ResourceModelCreate = ({
  initialData,
  cardType,
  resourceType,
  isCloning,
  onSaveChanges,
  onClose,
  title,
}: ResourceModelCreateProps) => {
  const {
    initialValues,
    dataSchema,
    resourceModelCreateSections,
    resourceModelCreateSectionDefaultSlug,
    spaceProps,
    showLoading,
  } = useResourceModelCreate(
    title,
    cardType,
    resourceType,
    isCloning,
    initialData,
    onSaveChanges,
    onClose
  );

  return (
    <FormProvider initialValues={initialValues} dataSchema={dataSchema}>
      <EditDrawerSpace
        items={resourceModelCreateSections}
        isDisabled
        initialSelectedDrawerItemSlug={resourceModelCreateSectionDefaultSlug}
        footer={ResourceModelCreateFormFooter}
        spaceProps={spaceProps}
      />
      <PreloadRoutes routeKeys={EDIT_ROUTE_KEYS} />
      <LoadingOverlay
        title={`${isCloning ? 'Cloning' : 'Creating'} resource`}
        subTitle={`We’re ${
          isCloning ? 'cloning' : 'creating'
        } things for you. Please don’t navigate away from this page.`}
        in={showLoading}
      />
    </FormProvider>
  );
};

export default memo(ResourceModelCreate);
