import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Teardrop: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 22 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M4.75 8.79 3.42 10.5h0a9.82 9.82 0 0 0-2.25 6.26c0 5.44 4.4 9.84 9.82 9.84s9.82-4.41 9.82-9.84c0-2.36-.83-4.52-2.2-6.21h0l-6.79-8.77a.995.995 0 0 0-1.58 0L7.28 5.56m5.75 17.39c2.59-.86 4.47-3.3 4.47-6.19M11 23.28" />
    </g>
  </WebsiteIcon>
);

export default memo(Teardrop);
