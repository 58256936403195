import { useEffect } from 'react';
import { WebsocketServerMessageType } from '@kitted/websocket-service-models';

import {
  getAnyWebsocketServerMessageHandler,
  KittedWebsocketSubscription,
  WebsocketEvent,
} from '../../../../services/websocket';
import { useWebsocketApi } from '../useWebsocketApi';

export const useSubscribeToAnyWebsocketServerMessage = (
  callback: KittedWebsocketSubscription,
  messageType?: WebsocketServerMessageType
) => {
  const websocketService = useWebsocketApi();

  useEffect(() => {
    const subscriptionHandle = websocketService?.subscribe(
      WebsocketEvent.MESSAGE,
      getAnyWebsocketServerMessageHandler(callback, messageType)
    );

    return () => {
      if (subscriptionHandle) {
        websocketService?.unsubscribe(subscriptionHandle);
      }
    };
  }, [websocketService, messageType, callback]);
};
