import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0039: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 108 98"
		height={98}
		width={108}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M15.28 65.76h30M92.72 65.76h-30" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M54 46.66v48.92" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M45.28 93.42H7.36c-4.67 0-6.58-3.31-4.24-7.35L49.78 5.45c2.34-4.04 6.16-4.04 8.5 0l46.6 80.61c2.34 4.04.43 7.35-4.24 7.35H62.72M32.85 35.54h42.31" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M63.8 55.91 54 46.14l-9.8 9.77" />
    </g>
  </CardIcon>
);

export default memo(In0039);
