import { useCallback, useMemo } from 'react';
import { upperFirst } from '@kitted/shared-utils';

import {
  FormStates,
  useFormApi,
  useFormData,
} from '../../../../../contexts/FormContext';
import {
  ModelCreateEditEditModalSpaceProps,
  ModelCreateEditFormData,
} from '../../../types';

const useModelCreateEditFormFooter = (
  id: ModelCreateEditEditModalSpaceProps['id'],
  cardType: ModelCreateEditEditModalSpaceProps['cardType'],
  isCloning: ModelCreateEditEditModalSpaceProps['isCloning'],
  onSaveChanges: ModelCreateEditEditModalSpaceProps['onSaveChanges'],
  onClose: ModelCreateEditEditModalSpaceProps['onClose']
) => {
  const { formState } = useFormData();
  const { getFormData } = useFormApi();

  const onSubmit = useCallback(async () => {
    const payload = getFormData<ModelCreateEditFormData>();
    await onSaveChanges(payload);
    onClose();
  }, [onSaveChanges, getFormData, onClose]);

  const buttonText = useMemo(() => {
    if (id) {
      return 'Save changes';
    }
    if (isCloning) {
      return `Clone ${upperFirst(cardType)}`;
    }
    return `Create ${upperFirst(cardType)}`;
  }, [id, cardType, isCloning]);

  const isDisabled = useMemo(() => {
    if (!isCloning) {
      return formState !== FormStates.DIRTY;
    }
    // can just immediately clone
    return formState !== FormStates.DIRTY && formState !== FormStates.CLEAN;
  }, [formState, isCloning]);

  return {
    buttonText,
    onSubmit,
    isDisabled,
  };
};

export default useModelCreateEditFormFooter;
