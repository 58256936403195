import { memo } from 'react';
import { Panel, Typography } from '@kitted/shared-components';

import FormFields from '../../../../../../../components/FormFields';
import PreviewCard from '../../../../../../../components/PreviewCard';
import SplitContent from '../../../../../../../components/SplitContent';
import { LinkResourceEditSpaceProps } from '../types';
import useLinkResourceEditPageContentPageSection from './hooks/useLinkResourceEditPageContentPageSection';
import { layoutSchema } from './constants';

const LinkResourceEditPageContentPageSection = ({
  linkResource,
}: LinkResourceEditSpaceProps) => {
  const { overrideCardProps } = useLinkResourceEditPageContentPageSection();

  return (
    <SplitContent
      left={
        <PreviewCard
          cardId={linkResource?.id}
          overrideCardProps={overrideCardProps}
        />
      }
    >
      <Panel theme="primary">
        <Typography variant="formTitle">Link resource details</Typography>
        <FormFields layout={layoutSchema} theme="primary" />
      </Panel>
    </SplitContent>
  );
};

export default memo(LinkResourceEditPageContentPageSection);
