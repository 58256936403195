import { Dispatch, SetStateAction } from 'react';
import { ImageAssetReference } from '@kitted/kitted-models';

import { ValidationItem } from './validationTypes';

export enum FieldTypes {
  TEXT = 'text',
  NUMBER = 'number',
  EMAIL = 'email',
  URL = 'url',
  TEXTAREA = 'textarea',
  SELECT = 'select',
  PASSWORD = 'password',
  HIDDEN = 'hidden',
  DISPLAY = 'display',
  IMAGE = 'image',
  RADIO = 'radio',
}

export type FieldValue = string | number | boolean | ImageAssetReference;

export type DataField = {
  type: FieldTypes;
  validation?: ValidationItem[];
};

export type FormDataSchema = {
  fields: {
    [key: string]: DataField;
  };
  form?: {
    hasCaptcha?: boolean;
    validationStrategy?: 'onBlur' | 'onSubmit';
  };
};

export type FormDataValue = {
  [key: string]: FieldValue;
};

export type FormLoadingItemsValue = {
  [key: string]: boolean | undefined;
};

export type FormErrorsValue = {
  [key: string]: string[] | undefined;
};

export enum FormStates {
  CLEAN = 'clean',
  DIRTY = 'dirty',
  LOADING = 'loading',
  ERROR = 'error',
  SUBMITTING = 'submitting',
}

export type ResetForm = (shouldResetData?: boolean) => void;

export type FormContextProps = {
  children: React.ReactNode;
  dataSchema: FormDataSchema;
  initialValues?: FormDataValue;
};

export type FormApi = {
  getFormData: <T extends FormDataValue>() => T;

  addFormLoadingItem: (itemKey: string) => void;
  removeFormLoadingItem: (itemKey: string) => void;

  setFormData: Dispatch<SetStateAction<FormDataValue>>;
  setFormErrors: Dispatch<SetStateAction<FormErrorsValue>>;
  setFormIsSubmitting: Dispatch<SetStateAction<boolean>>;
  resetForm: ResetForm;
};

export type FormData = {
  dataSchema: FormDataSchema;
  formData: FormDataValue;
  formState: FormStates;
  formErrors: FormErrorsValue;
  formInstanceId: string;
};
