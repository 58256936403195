import { memo } from 'react';

import MobileMenuOption from '../MobileMenuOption';
import useAccountRegisterItem from './hooks/useAccountRegisterItem';

const AccountRegisterItem = () => {
  const { title, path, target, IconComponent, isLoggingIn, isSelected } =
    useAccountRegisterItem();

  return (
    <MobileMenuOption
      isLoading={isLoggingIn}
      icon={IconComponent}
      isSelected={isSelected}
      path={path}
      target={target}
      title={title}
    />
  );
};

export default memo(AccountRegisterItem);
