import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0013: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 86 134"
		height={134}
		width={86}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><circle vectorEffect="non-scaling-stroke" cx="43.18" cy="12.14" r="9.75" /><path vectorEffect="non-scaling-stroke" d="m53.86 113.44.92-38.15c3.58-1.29 7.86-4.28 7.48-8.05l-2.92-29.03c-.38-3.76-3.79-6.84-7.57-6.84H34.59c-3.78 0-7.19 3.08-7.57 6.84l-2.93 29.03c-.38 3.76 3.9 6.75 7.48 8.05l.92 38.15M84.24 79.61 66.8 97.06M84.3 90.64V79.5H73.21M84.24 131.5 66.8 114.05M84.3 120.47v11.14H73.21M1.76 79.61 19.2 97.06M1.7 90.64V79.5h11.09M1.76 131.5l17.44-17.45M1.7 120.47v11.14h11.09" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M35.94 47.02c0-3.5 3.24-6.34 7.24-6.34s7.23 2.84 7.23 6.34c0 0 .16 2.89-3.6 5.47-2.51 1.95-4.46 5.01-4.46 8.49" /><circle vectorEffect="non-scaling-stroke" cx="42.43" cy="66.27" r=".73" /></g>
    </g>
  </CardIcon>
);

export default memo(In0013);
