import { z } from 'zod';

import { KittedModelTypeEnum } from './enum';

export const KittedModelBaseSchema = z.object({
  id: z.string().max(128),
  kittedModelType: z.nativeEnum(KittedModelTypeEnum),
});

export type KittedModelBase = z.infer<typeof KittedModelBaseSchema>;
