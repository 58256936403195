import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      '--formGridItemXsSpan': '12',
      '--formGridItemSmSpan': '12',
      '--formGridItemMdSpan': '12',
      '--formGridItemLgSpan': '12',
      '--formGridItemXlSpan': '12',
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gap: [[24, 18]],
      gridAutoRows: 'min-content',
      containerType: 'inline-size',
      width: '100%',
      [styling.breakpoints.context.tabletUpString]: {
        gap: [[24, 30]],
      },
    },
    item: {
      [styling.container.utils.containerQuery('width', 0)]: {
        gridColumn: 'span var(--formGridItemXsSpan)',
      },
      [styling.container.utils.containerQuery(
        'width',
        styling.sizes.context.columnW
      )]: {
        gridColumn: 'span var(--formGridItemSmSpan)',
      },
      [styling.container.utils.containerQuery(
        'width',
        styling.sizes.context.columnW * 1.5
      )]: {
        gridColumn: 'span var(--formGridItemMdSpan)',
      },
      [styling.container.utils.containerQuery(
        'width',
        styling.sizes.context.columnW * 2
      )]: {
        gridColumn: 'span var(--formGridItemLgSpan)',
      },
      [styling.container.utils.containerQuery(
        'width',
        styling.sizes.context.columnW * 2.5
      )]: {
        gridColumn: 'span var(--formGridItemXlSpan)',
      },
    },
  },
  { name: 'formGrid' }
);
