import { KittedBrandmark, Magnify, TrayArrowUp } from '@kitted/website-icons';

import { EditDrawerSpaceDrawerItem } from '../../EditDrawerSpace/types';
import CatalogueGallery from '../CatalogueGallery';
import CatalogueSearch from '../CatalogueSearch';
import CatalogueUpload from '../CatalogueUpload';

export const assetCatalogueSections: Record<string, EditDrawerSpaceDrawerItem> =
  {
    gallery: {
      drawerGroup: 'top',
      icon: KittedBrandmark,
      title: 'Gallery',
      component: CatalogueGallery,
    },
    search: {
      drawerGroup: 'top',
      icon: Magnify,
      title: 'Search',
      component: CatalogueSearch,
    },
    upload: {
      drawerGroup: 'top',
      icon: TrayArrowUp,
      title: 'Upload',
      component: CatalogueUpload,
    },
  } as const;

export type AssetCatalogueSectionSlug = keyof typeof assetCatalogueSections;

export const calalogueSelectorSectionSlugs = Object.keys(
  assetCatalogueSections
) as AssetCatalogueSectionSlug[];

export const calalogueSelectorSectionDefaultSlug =
  calalogueSelectorSectionSlugs[0];
