import { useMemo } from 'react';

import useModalData from '../useModalData';
import { getContextualModalTitle } from './logic';

const useContextualModalTitle = (title: string) => {
  const { modals } = useModalData();

  const contextualModalTitle = useMemo(
    () => getContextualModalTitle(modals, title),
    [title, modals]
  );

  return contextualModalTitle;
};

export default useContextualModalTitle;
