import { Dispatch, SetStateAction } from 'react';
import { PropsWithAs } from '@kitted/shared-components';

import { ModalContextApi } from '../../contexts/ModalContext/types';
import {
  AddButtonModal,
  AddButtonModalProps,
} from './AddButtonModalContent/types';
import {
  AddHyperlinkModal,
  AddHyperlinkModalProps,
} from './AddHyperlinkModalContent/types';
import {
  AuthChallengeModal,
  AuthChallengeModalContentProps,
} from './AuthChallengeModalContent/types';
import {
  AuthChangePasswordModal,
  AuthChangePasswordModalProps,
} from './AuthChangePasswordModalContent/types';
import {
  AuthRegisterModal,
  AuthRegisterModalProps,
} from './AuthCreateUserModalContent/types';
import {
  AuthForgotPasswordModal,
  AuthForgotPasswordModalProps,
} from './AuthForgotPasswordModalContent/types';
import {
  AuthLoginModal,
  AuthLoginModalProps,
} from './AuthLoginModalContent/types';
import {
  AuthVerifyModal,
  AuthVerifyModalProps,
} from './AuthVerifyUserModalContent/types';
import { DefaultModal, DefaultModalProps } from './DefaultModalContent/types';
import {
  FullscreenModal,
  FullscreenModalProps,
} from './FullscreenModalContent/types';
import {
  LightboxModal,
  LightboxModalProps,
} from './LightboxModalContent/types';
import AddButtonModalContent from './AddButtonModalContent';
import AddHyperlinkModalContent from './AddHyperlinkModalContent';
import AuthChallengeModalContent from './AuthChallengeModalContent';
import AuthChangePasswordModalContent from './AuthChangePasswordModalContent';
import AuthCreateUserModalContent from './AuthCreateUserModalContent';
import AuthForgotPasswordModalContent from './AuthForgotPasswordModalContent';
import AuthLoginModalContent from './AuthLoginModalContent';
import AuthVerifyUserModalContent from './AuthVerifyUserModalContent';
import DefaultModalContent from './DefaultModalContent';
import FullscreenModalContent from './FullscreenModalContent';
import LightboxModalContent from './LightboxModalContent';
import { ModalContentProps, ModalProps } from './types';

export const handleOnClose = (setIsIn: Dispatch<SetStateAction<boolean>>) => {
  setIsIn(false);
};

export const handleOnExited = (
  id: ModalProps['id'],
  deregisterModal: ModalContextApi['deregisterModal']
) => {
  deregisterModal(id);
};

export const getModalContentComponent = (
  props: ModalProps,
  onClose: ModalContentProps['onClose'],
  preventClose: ModalContentProps['preventClose']
) => {
  const { variant } = props;
  switch (variant) {
    case 'authLogin': {
      const modalProps = props as AuthLoginModal;

      return {
        ...modalProps,
        as: AuthLoginModalContent,
        onClose,
        preventClose,
      } as PropsWithAs<AuthLoginModalProps>;
    }
    case 'authRegister': {
      const modalProps = props as AuthRegisterModal;

      return {
        ...modalProps,
        as: AuthCreateUserModalContent,
        onClose,
        preventClose,
      } as PropsWithAs<AuthRegisterModalProps>;
    }
    case 'authVerify': {
      const modalProps = props as AuthVerifyModal;

      return {
        ...modalProps,
        as: AuthVerifyUserModalContent,
        onClose,
        preventClose,
      } as PropsWithAs<AuthVerifyModalProps>;
    }
    case 'authForgotPassword': {
      const modalProps = props as AuthForgotPasswordModal;

      return {
        ...modalProps,
        as: AuthForgotPasswordModalContent,
        onClose,
        preventClose,
      } as PropsWithAs<AuthForgotPasswordModalProps>;
    }
    case 'authChangePassword': {
      const modalProps = props as AuthChangePasswordModal;

      return {
        ...modalProps,
        as: AuthChangePasswordModalContent,
        onClose,
        preventClose,
      } as PropsWithAs<AuthChangePasswordModalProps>;
    }
    case 'authChallenge': {
      const modalProps = props as AuthChallengeModal;

      return {
        ...modalProps,
        as: AuthChallengeModalContent,
        onClose,
        preventClose,
      } as PropsWithAs<AuthChallengeModalContentProps>;
    }
    case 'lightbox': {
      const modalProps = props as LightboxModal;

      return {
        ...modalProps,
        as: LightboxModalContent,
        onClose,
        preventClose,
      } as PropsWithAs<LightboxModalProps>;
    }
    case 'fullscreen': {
      const modalProps = props as FullscreenModal;

      return {
        ...modalProps,
        as: FullscreenModalContent,
        onClose,
        preventClose,
      } as PropsWithAs<FullscreenModalProps>;
    }
    case 'addHyperlink': {
      const modalProps = props as AddHyperlinkModal;

      return {
        ...modalProps,
        as: AddHyperlinkModalContent,
        onClose,
        preventClose,
      } as PropsWithAs<AddHyperlinkModalProps>;
    }
    case 'addButton': {
      const modalProps = props as AddButtonModal;

      return {
        ...modalProps,
        as: AddButtonModalContent,
        onClose,
        preventClose,
      } as PropsWithAs<AddButtonModalProps>;
    }
    case 'default':
    default: {
      const modalProps = props as DefaultModal;

      return {
        ...modalProps,
        as: DefaultModalContent,
        onClose,
        preventClose,
      } as PropsWithAs<DefaultModalProps>;
    }
  }
};
