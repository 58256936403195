import { constructAuthenticatedRoute, constructPublicRoute } from '../helpers';
import { articleTitles } from '../routeTitles';

const articleAuthorTitleCodeBasePath = '/article/:authorSlug/:titleSlug/:code';

export const articleRedirectRoutes = {
  articleCode: constructPublicRoute(
    '/article/:code',
    'Article',
    articleAuthorTitleCodeBasePath
  ),
  articleTitleCode: constructPublicRoute(
    '/article/:titleSlug/:code',
    [articleTitles, 'Article'],
    articleAuthorTitleCodeBasePath
  ),
} as const;

export const articleRoutes = {
  ...articleRedirectRoutes,
  articleAuthorTitleCode: constructPublicRoute(articleAuthorTitleCodeBasePath, [
    articleTitles,
    'Article',
  ]),
  articleAuthorTitleCodeEdit: constructAuthenticatedRoute(
    `${articleAuthorTitleCodeBasePath}/edit`,
    [articleTitles, 'Edit Article']
  ),
} as const;
