import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const ShieldCircleCheckBreak: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 22 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M9.57 3.51C8.23 4.64 5.76 5.9 1.39 5.9v10.46S.75 21.39 11 26.22c10.25-4.83 9.61-9.86 9.61-9.86V5.89C12.68 5.89 11 1.73 11 1.73" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M16.6 12.68c.09.41.15.84.15 1.29a5.74 5.74 0 1 1-5.74-5.74c1.31 0 2.51.44 3.48 1.17" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m8.08 12.17 3.02 4.19 5.57-6.66" />
    </g>
  </WebsiteIcon>
);

export default memo(ShieldCircleCheckBreak);
