import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

import { overlayBorderStyles, overlayStyles } from '../styles';

export default createUseStyles(
  {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      '&:before': {
        ...overlayStyles(),
      },
      '&:after': {
        ...overlayBorderStyles(),
      },
    },
    containerActive: {
      '&:before': {
        opacity: 1,
      },
    },
    containerFeatured: {
      '&:before, &:after': {
        content: 'unset',
      },
    },
    containerMenuOpen: {
      '&:before, &:after': {
        opacity: 1,
      },
    },
    'containerSubType-image': {
      '&$containerActive:not($containerMenuOpen)': {
        '&:before, &:after': {
          content: 'unset',
        },
        '& $image:before, & $image:after, & $content, & $cardSlug': {
          opacity: 1,
          transition: styling.transitions.utils.generate([
            'opacity',
            styling.speeds.skip,
          ]),
        },
      },
      '& $image:before, & $image:after, & $content, & $cardSlug': {
        opacity: 0,
        transition: styling.transitions.utils.generate([
          'opacity',
          styling.speeds.walk,
        ]),
      },
    },
    'containerSubType-link': {},
    'containerSubType-video': {},
    image: {},
    content: {},
    cardSlug: {
      position: 'absolute',
      left: 0,
      right: 0,
      zIndex: 5,
      bottom: 'var(--cardSlugBottom)',
      paddingInline: 'var(--cardPaddingInline)',
    },
  },
  { name: 'resourceCard' }
);
