import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    toggleButton: {
      ...styling.utils.buttonReset(),
      flexGrow: 1,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      lineHeight: 'inherit',
      fontWeight: 'inherit',
      paddingInline: [[44, 58]],
      borderRadius: '100rem',
      '&:focus': {
        outline: 'none',
      },
      '&[disabled]': {
        cursor: 'inherit',
      },
    },
    toggleButtonWithLeftIcon: {
      paddingLeft: 99,
    },
    item: {
      transition: styling.speeds.jog,
      height: 48,
      '&:not(:last-child)': {
        borderBottom: [1, 'solid', styling.colors.theme.primary400],
      },
      '&:not($itemSelected):hover, &:not($itemSelected):focus': {
        background: styling.colors.theme.primary800,
      },
    },
    itemSelected: {
      color: styling.colors.theme.primary600,
      background: styling.colors.theme.secondary,
    },
  },
  { name: 'inputSelect' }
);
