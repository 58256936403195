import { parse } from 'telejson';
import { WebsocketServerMessageType } from '@kitted/websocket-service-models';

import { KittedWebsocketSubscription } from '../types';
import { WebsocketApi } from '../WebsocketApi';

export const getAnyWebsocketServerMessageHandler =
  (
    callback: KittedWebsocketSubscription,
    messageType?: WebsocketServerMessageType
  ) =>
  (websocketService: WebsocketApi, event?: Event) => {
    if (event) {
      const { data } = event as MessageEvent;
      try {
        const obj = parse(data);
        if (!messageType) {
          callback(obj);
        } else if (messageType === obj.messageType) {
          // Should we validate payloads here with a schema thats passed in?
          callback(obj);
        }
      } catch (e) {
        // console.error('Error parsing websocket message', e);
      }
    }
  };
