/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef } from 'react';

import { defaultCallback } from './constants';
import { handleClickOutside, handleOnMount } from './logic';

const useClickOutside = (
  enabled: boolean,
  callback: () => void = defaultCallback
) => {
  const ref = useRef<any>(null);

  const onClickOutside = useCallback(
    (e: Event) => handleClickOutside(e, ref.current, enabled, callback),
    [enabled, callback]
  );

  useEffect(() => handleOnMount(onClickOutside), [onClickOutside]);

  return {
    ref,
  };
};

export default useClickOutside;
