import { AuthChangePasswordModalMeta, ChangePasswordFormData } from './types';

export const getInitialFormData = (
  initialFormData: AuthChangePasswordModalMeta['initialFormData']
) => ({
  password: '',
  email: initialFormData?.email,
  code: initialFormData?.code,
});

export const transformChangeFormDataToChangePayload = ({
  email,
  code,
  password,
  'g-recaptcha-response': recaptchaResponse,
}: ChangePasswordFormData) => ({
  'g-recaptcha-response': recaptchaResponse,
  email,
  code,
  password,
});
