import { mustMatchRegex, ValidationItemMustMatchRegex } from './mustMatchRegex';

const validLinkedInRegex =
  /^https:\/\/([a-z]{2,3}[.])?linkedin[.]com\/(in|company)\/.*$/i;

export const isValidLinkedInURL = (
  isValueRequired: boolean
): ValidationItemMustMatchRegex =>
  mustMatchRegex(
    isValueRequired,
    validLinkedInRegex,
    'This is not a valid LinkedIn URL'
  );
