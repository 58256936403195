import {
  UserModel,
  UserVerifyEmailRequest,
  UserVerifyEmailResendResponse,
} from '@kitted/auth-service-models';
import { KittedServiceEnum } from '@kitted/kitted-models';

import useAsyncRequest from '../../../../hooks/useAsyncRequest';
import useRequests from '../../../RequestsContext/hooks/useRequests';
import useStoreItem from '../../../StoreContext/hooks/useStoreItem';
import { toastDurations } from '../../../ToastContext/constants';
import useToastApi from '../../../ToastContext/hooks/useToastApi';

const useVerifyUser = () => {
  const { updateItem: updateUser } = useStoreItem<UserModel>('User', 'user');
  const { platformRequest } = useRequests();
  const { registerToast } = useToastApi();

  // verify email function
  const [verifyEmail] = useAsyncRequest<void>(
    (verifyEmailPayload: UserVerifyEmailRequest) =>
      platformRequest(KittedServiceEnum.Auth, {
        method: 'POST',
        data: verifyEmailPayload,
        url: '/v1/verifyEmail',
      }),
    () => {
      updateUser({
        emailVerified: true,
      });
    }
  );

  // resend verify email function
  const [resendVerifyEmail] = useAsyncRequest<UserVerifyEmailResendResponse>(
    () =>
      platformRequest(KittedServiceEnum.Auth, {
        method: 'POST',
        url: '/v1/resendValidation',
      }),
    (success) => {
      if (success?.sent) {
        registerToast('resendEmail', {
          theme: 'success',
          title: 'Verification email resent',
          duration: toastDurations.success,
        });
      }
    }
  );

  return {
    verifyEmail,
    resendVerifyEmail,
  };
};

export default useVerifyUser;
