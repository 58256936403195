import { EMPTY_OBJ } from '@kitted/shared-utils';

const requiredParams = ['code'];

export const extractPathParamsAndBackfillParams = (
  preinterpolatedRoutePath: string,
  params: Record<string, string | undefined>,
  shouldErrorOnMissingRequiredParams: boolean,
  backfillValue = 'loading'
): Record<string, string> => {
  const routeParams = preinterpolatedRoutePath.match(/:[a-zA-Z0-9]+/g);

  if (!routeParams) {
    return EMPTY_OBJ;
  }

  for (const requiredParam of requiredParams) {
    if (
      routeParams.includes(`:${requiredParam}`) &&
      !params[requiredParam] &&
      shouldErrorOnMissingRequiredParams
    ) {
      throw new Error(
        `Missing required parameter: ${requiredParam} on path ${preinterpolatedRoutePath}`
      );
    }
  }

  const backfilledParams = routeParams.reduce((acc, param) => {
    const key = param.replace(':', '');
    return { ...acc, [key]: params[key] || backfillValue };
  }, {});

  return backfilledParams;
};
