import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    container: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      position: 'relative',
    },
  },
  { name: 'editDrawerSpaceContentBody' }
);
