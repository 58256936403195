import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const CodeCheck: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 26"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M6.82 18.41H1.68V1.49h24.63v16.93h-5.05m-11.18-4.27c.64-.53 1.4-.94 2.26-1.18 3.21-.89 6.55.92 7.47 4.04s-.94 6.38-4.15 7.27-6.55-.92-7.47-4.04c-.47-1.6-.21-3.22.57-4.56m1.7 2.65 2.62 2.72 3.61-5.06M5.8 7.97c0 .6-.49 1.09-1.09 1.09s-1.09-.49-1.09-1.09.49-1.09 1.09-1.09 1.09.49 1.09 1.09Zm3.72 0c0 .6-.49 1.09-1.09 1.09s-1.09-.49-1.09-1.09.49-1.09 1.09-1.09 1.09.49 1.09 1.09Zm3.71 0c0 .6-.49 1.09-1.09 1.09s-1.09-.49-1.09-1.09.49-1.09 1.09-1.09 1.09.49 1.09 1.09Zm3.72 0c0 .6-.49 1.09-1.09 1.09s-1.09-.49-1.09-1.09.49-1.09 1.09-1.09 1.09.49 1.09 1.09Zm3.71 0c0 .6-.49 1.09-1.09 1.09s-1.09-.49-1.09-1.09.49-1.09 1.09-1.09 1.09.49 1.09 1.09Zm3.71 0c0 .6-.49 1.09-1.09 1.09s-1.09-.49-1.09-1.09.49-1.09 1.09-1.09 1.09.49 1.09 1.09Z" />
    </g>
  </WebsiteIcon>
);

export default memo(CodeCheck);
