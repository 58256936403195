import { createContext, useMemo } from 'react';
import { EMPTY_ARR, NOOP } from '@kitted/shared-utils';

import useCardsManagement from './hooks/useCardsManagement';
import { CardsApi, CardsContextProps, CardsData } from './types';

export const CardsContext = createContext<CardsApi>({
  getCardIdsByText: () => EMPTY_ARR,
  upsertCardToTextSearchDb: NOOP,
});

export const CardsDataContext = createContext<CardsData>({
  isLoading: true,
  isFetchingMyCards: false,
});

export const CardsProvider = ({ children }: CardsContextProps) => {
  const {
    isLoading,
    isFetchingMyCards,
    getCardIdsByText,
    upsertCardToTextSearchDb,
  } = useCardsManagement();
  const apiValue = useMemo(
    () => ({ getCardIdsByText, upsertCardToTextSearchDb }),
    [getCardIdsByText, upsertCardToTextSearchDb]
  );
  const dataValue = useMemo(
    () => ({ isLoading, isFetchingMyCards }),
    [isLoading, isFetchingMyCards]
  );

  return (
    <CardsContext.Provider value={apiValue}>
      <CardsDataContext.Provider value={dataValue}>
        {children}
      </CardsDataContext.Provider>
    </CardsContext.Provider>
  );
};
