import { cardTypeToComponentMap } from './constants';
import {
  CardSkeletonProps,
  CardSkeletonTheme,
  CardSkeletonType,
} from './types';

export const getCardComponentByType = (
  type: CardSkeletonType
): React.ElementType | null => {
  if (cardTypeToComponentMap[type]) {
    return cardTypeToComponentMap[type];
  }
  return null;
};

export const getThemeFromInversion = (
  isInverted: CardSkeletonProps['isInverted']
): CardSkeletonTheme => {
  if (isInverted) return 'primary';
  return 'secondary';
};
