import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Nodes: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M1.38 1.38h6.31v6.31H1.38zM20.31 20.31h6.31v6.31h-6.31z" /><circle vectorEffect="non-scaling-stroke" cx="4.53" cy="23.46" r="3.16" stroke={color} /><path vectorEffect="non-scaling-stroke" stroke={color} d="M4.53 7.69v12.62M7.69 23.46h12.62" />
    </g>
  </WebsiteIcon>
);

export default memo(Nodes);
