import { createContext } from 'react';
import { EMPTY_OBJ, NOOP } from '@kitted/shared-utils';

import { EditDrawerSpaceApi, EditDrawerSpaceData } from './types';

export const EditDrawerSpaceDataContext = createContext<EditDrawerSpaceData>({
  selectedDrawerItemSlug: '',
  items: EMPTY_OBJ,
  spaceProps: EMPTY_OBJ,
  bodyRef: null,
});

export const EditDrawerSpaceContext = createContext<EditDrawerSpaceApi>({
  setSelectedDrawerItemSlug: NOOP,
  onSetBodyRef: NOOP,
});
