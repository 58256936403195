import { Dispatch, SetStateAction } from 'react';

import { thresholdsArray } from './constants';
import { BackgroundParallaxOptions } from './types';

const getMostWeCanMove = (
  entry: IntersectionObserverEntry,
  max?: BackgroundParallaxOptions['max'],
  factor?: BackgroundParallaxOptions['factor']
) => {
  if (max) return max;
  if (factor) {
    return (
      entry.boundingClientRect.height * factor - entry.boundingClientRect.height
    );
  }
  return entry.boundingClientRect.height;
};

export const calculateInView =
  (
    setPosition: Dispatch<SetStateAction<number>>,
    max: BackgroundParallaxOptions['max'],
    factor: BackgroundParallaxOptions['factor']
  ) =>
  (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // when top of entry is at bottom of viewport
        // position = 0
        // when bottom of entry is a top of viewport
        // position = mostWeCanMove
        // when center of entry is in center of viewport
        // position is half mostWeCanMove
        const mostWeCanMove = getMostWeCanMove(entry, max, factor);
        const journeyDistance =
          (entry.rootBounds as DOMRectReadOnly).height +
          entry.boundingClientRect.height;
        const fromStartOfJourney = Math.max(
          entry.boundingClientRect.top + entry.boundingClientRect.height,
          0
        );
        const percentageOfJourney = Math.min(
          fromStartOfJourney / journeyDistance,
          1
        );
        setPosition(mostWeCanMove * percentageOfJourney);
      }
    });
  };

export const getIntersectionObserver = (
  callbackFn: (entries: IntersectionObserverEntry[]) => void
) =>
  new IntersectionObserver(callbackFn, {
    threshold: thresholdsArray,
  });

export const handleOnMount = (
  enabled: boolean,
  observeElement: () => void,
  unobserveElement: () => void
) => {
  if (!enabled) return () => undefined;

  observeElement();
  return () => {
    unobserveElement();
  };
};
