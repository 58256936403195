import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0039: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 124 110"
		height={110}
		width={124}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M119.45 92.63c.41-11.28-.73-24.41-.73-24.41-3.85-23.07-24.66-22.59-24.66-22.59H23.89c-17.79 0-16.1 17.06-16.1 17.06v29.94M42.25 100.42h41.53" /><path vectorEffect="non-scaling-stroke" d="M97.66 92.61c-4.34 0-7.86 3.52-7.86 7.86 0 4.35 3.52 7.86 7.86 7.86s7.87-3.52 7.87-7.86-3.52-7.86-7.87-7.86M28.21 92.61a7.861 7.861 0 1 0 .002 15.722 7.861 7.861 0 0 0-.002-15.722M85.08 53.75v14.22M68.43 53.75v14.22M51.78 53.75v14.22M119.39 80.86c-6.44-11.77-21.67-12.57-21.67-12.57H20.03v-6.75c0-8.13 5.88-8.06 5.88-8.06h85.7M19.66 100.46H8.17c-1.98 0-3.61-1.66-3.61-3.7s1.62-3.7 3.61-3.7h5.41M114.06 93.06h5.41c1.98 0 3.6 1.66 3.6 3.7s-1.62 3.7-3.6 3.7h-13.94M35.13 53.75v14.22" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m4.6 31.69 10.93-6.07c2.22-5.04 4.18-11.35 8.56-11.25.91-.43 4.83.46 6.14 4.32.75 2.22-.51 10.77-1.05 15.37-.27 2.28-.7 7.29-5.49 6.8l-11.41-2.77-7.12 13.95" /><circle vectorEffect="non-scaling-stroke" cx="22.78" cy="5.98" r="4.31" /><path vectorEffect="non-scaling-stroke" d="m.93 50.31 6.93-15.95c.97-1.96 2.98-1.93 2.98-1.93l8.03.55 2.14-17.42" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0039);
