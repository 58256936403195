import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0028: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 100 110"
		height={110}
		width={100}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M78.5 8.32C70.05 3.91 50.32-3.3 27.64 9.57c0 0-16.86 7.3-13.3 30.64 0 0 .72 6.53-1.34 9.58L1.84 62.92s-1.49 3.9 2.97 4.28l6.81 1.4s2.85 5.55 3.13 9.82c.29 4.27-.71 9.54-.71 9.54s-2.42 6.41 1.38 9.22c.86.49 3.54 3.35 23.42-.93 0 0 1.92.91 3.14 11.3M82.88 10.95s-1.56-1.16-4.38-2.63M84.91 107.71c-.88-5.15-2.16-13.52-1.66-21.68 0 0 2.97-13.38 8.92-21.93 0 0 11.17-19.05 3.59-37.31" /><path vectorEffect="non-scaling-stroke" d="M15.6 78.7c4.01.2 7.15-.47 9.53-2.24" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="m39.19 39.31 20.55-20.67 16.2 10.09L95.76 9.62" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="m96.27 18.52.49-9.83-9.85-.5" />
    </g>
  </CardIcon>
);

export default memo(In0028);
