import { memo } from 'react';
import { createPortal } from 'react-dom';

import { useDrawerSpaceContainerData } from '../context/DrawerSpaceContainerContext';
import { DrawerSpacePortalProps } from './types';

const DrawerSpacePortal = ({ children }: DrawerSpacePortalProps) => {
  const { portalRoot } = useDrawerSpaceContainerData();

  if (!portalRoot) return null;

  return createPortal(children, portalRoot);
};

export default memo(DrawerSpacePortal);
