import { ChangeEvent, useCallback } from 'react';

import { InputComponentProps } from '../../../types';

const useInputTextarea = (onChange: InputComponentProps['onChange']) => {
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => onChange(e?.target?.value),
    [onChange]
  );

  return {
    handleOnChange,
  };
};

export default useInputTextarea;
