import { KittedModelTypeEnum, KittedServiceEnum } from '@kitted/kitted-models';
import { Envelope } from '@kitted/website-icons';

import { FieldTypes, FormDataSchema } from '../../../contexts/FormContext';
import {
  hasAtLeastXNumbers,
  hasAtLeastXSpecialCharacters,
  hasAtLeastXUpperAndXLowerLetters,
  hasMaxLength,
  hasMinLength,
  isRequired,
  isValidEmail,
  mustMatchField,
  ValidationItemMustMatchRegex,
} from '../../../services/helpers/validators';
import { FormLayoutSchema } from '../../FormFields/types';

export const dataSchema: FormDataSchema = {
  fields: {
    orderId: {
      type: FieldTypes.HIDDEN,
    },
    referralCode: {
      type: FieldTypes.HIDDEN,
    },
    firstName: {
      type: FieldTypes.TEXT,
      validation: [isRequired, hasMaxLength(128)],
    },
    lastName: {
      type: FieldTypes.TEXT,
      validation: [isRequired, hasMaxLength(128)],
    },
    email: {
      type: FieldTypes.EMAIL,
      validation: [isValidEmail(true), hasMaxLength(128)],
    },
    password: {
      type: FieldTypes.PASSWORD,
      validation: [
        hasMinLength(8),
        hasAtLeastXUpperAndXLowerLetters(false, 1, 1),
        hasAtLeastXNumbers(false, 1),
        hasAtLeastXSpecialCharacters(false, 1),
        isRequired,
      ],
    },
    confirmPassword: {
      type: FieldTypes.PASSWORD,
      validation: [mustMatchField('password', 'Passwords'), isRequired],
    },
  },
  form: {
    hasCaptcha: true,
    validationStrategy: 'onBlur',
  },
};

export const layoutSchema: FormLayoutSchema = {
  fields: [
    {
      key: 'firstName',
      label: 'First name',
      customAttributes: {
        autoComplete: 'given-name',
      },
    },
    {
      key: 'lastName',
      label: 'Last name',
      customAttributes: {
        autoComplete: 'family-name',
      },
    },
    {
      key: 'email',
      label: 'Email address',
      meta: {
        placeholder: 'your@email.com',
      },
      customAttributes: {
        autoComplete: 'username',
        iconLeft: Envelope,
      },
    },
    {
      key: 'password',
      label: 'Password',
      meta: {
        canShowHide: true,
        showStrengthIndicator: {
          items: [
            {
              label: 'minimum 8 characters',
              matchError:
                dataSchema.fields.password.validation?.[0].validator(''),
            },
            {
              label: 'mixture of uppercase and lowercase letters',
              matchError: (
                dataSchema.fields.password
                  .validation?.[1] as ValidationItemMustMatchRegex
              ).errorMessage,
            },
            {
              label: 'at least 1 number',
              matchError: (
                dataSchema.fields.password
                  .validation?.[2] as ValidationItemMustMatchRegex
              ).errorMessage,
            },
            {
              label: 'at least 1 special character',
              matchError: (
                dataSchema.fields.password
                  .validation?.[3] as ValidationItemMustMatchRegex
              ).errorMessage,
            },
          ],
        },
      },
      customAttributes: {
        autoComplete: 'new-password',
      },
    },
    {
      key: 'confirmPassword',
      label: 'Confirm Password',
      meta: {
        canShowHide: true,
      },
      customAttributes: {
        autoComplete: 'new-password',
      },
    },
  ],
};

export const submissionWebsocketMessages = [
  {
    service: KittedServiceEnum.Account,
    kittedModelType: KittedModelTypeEnum.AccountAccount,
  },
  {
    service: KittedServiceEnum.Author,
    kittedModelType: KittedModelTypeEnum.AuthorAuthor,
  },
  {
    service: KittedServiceEnum.Auth,
    kittedModelType: KittedModelTypeEnum.AuthUserAttributes,
  },
];
