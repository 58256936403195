import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Envelope: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 20"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="m8.07 11.61-5.53 6.62m17.4-6.62 5.53 6.62M2.24 1.59l11.8 9.11 11.7-9.18M3.1 1.11h21.8c.74 0 1.34.6 1.34 1.34v15.1c0 .74-.6 1.34-1.34 1.34H3.1c-.74 0-1.34-.6-1.34-1.34V2.45c0-.74.6-1.34 1.34-1.34Z" />
    </g>
  </WebsiteIcon>
);

export default memo(Envelope);
