import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const LandscapeCard: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 22 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <rect vectorEffect="non-scaling-stroke" width="19.78" height="25.34" x="1.11" y="1.33" stroke={color} rx="2.23" ry="2.23" /><circle vectorEffect="non-scaling-stroke" cx="13.54" cy="9.67" r="2" stroke={color} /><path vectorEffect="non-scaling-stroke" stroke={color} d="m14.5 18.95-7.28-7.83-6.11 6.63M20.89 18.65l-4.59-5.36-2.36 2.56" />
    </g>
  </WebsiteIcon>
);

export default memo(LandscapeCard);
