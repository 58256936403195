import { LoginState } from '../../types';
import useUserData from '../useUserData';

const useIsLoggedIn = () => {
  const { loginState } = useUserData();

  return {
    isLoggedIn: loginState === LoginState.IN,
  };
};

export default useIsLoggedIn;
