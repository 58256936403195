import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0041: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 110 106"
		height={106}
		width={110}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m79.14 104.98 29.49-33.39-7.68-12.73h-43.6l-7.69 12.73 29.49 33.39M50.26 71.59h16.12M73.88 71.59h34.16M66.22 71.59l12.89 32.94M66.17 71.43l-5.2-7.31M78.41 59.1 66.42 71.35M92.07 71.59l-12.88 32.94M92.13 71.43l8.78-12.35M79.88 59.1l12 12.25" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M17.64 58.86h29.8M17.64 40.84h48.53M17.64 71.59h22.62M17.64 84.33h33.22M25 10.99v13.73H11.26" /><path vectorEffect="non-scaling-stroke" d="M79.15 49.71V1.02H21.47L1.37 21.11v83.47h62.05" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0041);
