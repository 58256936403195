import { useMemo } from 'react';
import { CardTypeValues } from '@kitted/kitted-models';

import { getCardContentsStyle } from '../../logic';

const useCardContents = (type: CardTypeValues, isFeatured = false) => {
  const style = useMemo(
    () => getCardContentsStyle(type, isFeatured),
    [type, isFeatured]
  );

  return {
    style,
  };
};

export default useCardContents;
