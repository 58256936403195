import { GridColumn } from './types';

const columns: GridColumn[] = [1, 2, 3, 5];
const columnW = 300;
const baseSpacingValue = 6;
const spacing = {
  '3xs': baseSpacingValue, // 6
  '2xs': baseSpacingValue * 2, // 12
  xs: baseSpacingValue * 3, // 18
  sm: baseSpacingValue * 4, // 24
  md: baseSpacingValue * 6, // 36
  lg: baseSpacingValue * 7, // 42
  xl: baseSpacingValue * 12, // 72
  '2xl': baseSpacingValue * 15, // 90
};
const spacingCustomFactor = (factor: number) => {
  if ([1, 2, 3, 4, 6, 7, 12, 15].includes(factor)) {
    throw new Error(
      `This custom factor is a default spacing value, use that instead: ${factor}`
    );
  }
  return factor * baseSpacingValue;
};

const gutteringSmW = spacing.xs;
const gutteringW = spacing.md;

const topBarH = spacing['2xl'];
const bottomBarH = spacing.xl;
const mobileMenuH = spacing.xl;

const cardW = columnW;
const cardH = 400;
const cardRadius = spacing['3xs'];
const cardPaddingInline = '36px 52px';

const cardFeatureW = cardW * 1.2;
const cardFeatureH = cardH * 1.2;
const cardFeatureRadius = spacing['2xs'];
const cardFeaturePaddingInline = 43;

const cardWideW = 1200;
const cardWideH = 630;

const iconSizeSm = 18;
const iconSizeMd = 22;
const iconSizeLg = 32;
const iconSizeXl = 48;

const tileW = 100;
const tileH = 100;

const gridColumns = (
  cols: number,
  gutteringWidth: number,
  includeEdgeGutters = false
) =>
  columnW * cols +
  (cols - 1) * gutteringWidth +
  (includeEdgeGutters ? 2 * gutteringWidth : 0);

const minGridW = gridColumns(columns[0], gutteringSmW);
const maxGridW = gridColumns(columns[columns.length - 1], gutteringW); // 2,988

export default {
  theme: {
    columns,
    spacing,
  },
  context: {
    grid: {
      minWidth: minGridW,
      maxWidth: maxGridW,
    },
    topBar: {
      height: topBarH,
    },
    dynamicTopBar: {
      height: topBarH,
      mobileHeight: spacing.xl,
    },
    bottomBar: {
      height: bottomBarH,
    },
    mobileMenu: {
      height: mobileMenuH,
    },
    drawer: {
      openWidth: 250,
      closedWidth: 48,
    },
    columnW,
    gutteringSmW,
    gutteringW,
    icon: {
      sm: iconSizeSm,
      md: iconSizeMd,
      lg: iconSizeLg,
      xl: iconSizeXl,
    },
    card: {
      width: cardW,
      height: cardH,
      radius: cardRadius,
      paddingInline: cardPaddingInline,
    },
    cardFeature: {
      width: cardFeatureW,
      height: cardFeatureH,
      radius: cardFeatureRadius,
      paddingInline: cardFeaturePaddingInline,
    },
    cardWide: {
      width: cardWideW,
      height: cardWideH,
    },
    tile: {
      width: tileW,
      height: tileH,
    },
  },
  utils: {
    gridColumns,
    spacingCustomFactor,
  },
};
