import { AuthenticationTokens, AuthenticationTokensManager } from './types';

export const authenticationTokensManager = (
  initialValue: AuthenticationTokens
): AuthenticationTokensManager => {
  let tokens: AuthenticationTokens = initialValue;

  const setAuthenticationTokens = (authTokens: AuthenticationTokens) => {
    tokens = authTokens;
  };

  const getAuthenticationTokens = () => tokens;

  return {
    setAuthenticationTokens,
    getAuthenticationTokens,
  };
};
