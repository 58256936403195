import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const FileArrowUp: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 26 26"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M18.88 11.14V1.53H6.11L1.66 5.98v18.48h12.01" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M8.43 5.27v3.04H5.39M13.8 18.83h-2.18c-.27 0-.4-.21-.45-.33-.11-.27-.07-.6.09-.76l4.41-4.4c.31-.31.81-.31 1.11 0l4.38 4.4c.17.17.22.5.12.75-.09.22-.28.34-.54.34h-2.42v5.38" />
    </g>
  </WebsiteIcon>
);

export default memo(FileArrowUp);
