import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0048: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 60 120"
		height={120}
		width={60}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M32.18 100.29s-9.46 1.72-15.34-4.79c0 0 1.04 9.21 15.56 11.9M50.34 69.61c-.17 0-4.7-.04-9.8 3.54-2.69 1.89-4.61 5.2-4.77 8.22-.1 1.84.49 3.41 1.64 4.42 1.81 1.58 5.06 1.45 8.1-.34 1.23-.72 2.94-2.03 4.11-4.13 2.72-4.91 2.57-9.72 2.56-9.92l-.07-1.76-1.76-.02Z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M30.27 118.44c.29-.69 7.03-16.99-.16-29.27-5.23-8.92-1.44-20.3-.22-23.44h.1" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M47.56 12.18c-.06-.08-.11-.16-.18-.23C41.63 6.04 29.89 1.89 29.39 1.71c-.57-.2-1.2-.2-1.78 0-1.01.35-7.8 2.73-12.94 6.1 2.15.46 4.74 1.09 7.32 1.89" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M32.41 92.07s1.67-3.7 5.01-6.28" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M25.76 15.88c-10.39-3.99-20.86-5-21.38-5.05-1.14-.1-2.18.52-2.67 1.49 0 .02-.01.03-.02.05-.06.13-.12.26-.16.4-.32 1.02-.03 2.18.84 2.92.16.13 3.9 3.4 4.16 11.56.03 1.01.07 1.77.09 2.39q.11 2.44-.54 8.22l-.17 1.49c-.57 5.14.79 9.78 3.93 13.42 3.69 4.27 9.66 6.93 16.59 7.46 0 0 3.6.26 5.85.1 7.42-.26 13.92-3.02 17.85-7.57 3.14-3.64 4.5-8.27 3.93-13.42l-.17-1.49q-.64-5.77-.54-8.22c.03-.62.06-1.38.09-2.39.26-8.1 3.95-11.38 4.16-11.56a2.719 2.719 0 0 0-.96-4.71c-.36-.12-.75-.18-1.15-.14-.44.04-3.59.38-7.93 1.35l-6.26 1.67c-11.24 3.5-25.02 10.8-25.02 25.6" />
    </g>
  </CardIcon>
);

export default memo(Ac0048);
