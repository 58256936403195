import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0043: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 98 86"
		height={86}
		width={98}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M33.07 84.61v-6.84c0-5.69 4.12-7.79 9.42-7.79L49 77.15l6.05-7.17c5.3 0 9.88 2.1 9.88 7.79v6.84M56.37 56.28c0 4.12-3.32 7.48-7.37 7.48s-7.37-3.37-7.37-7.48v-2.77c0-4.11 3.32-7.48 7.37-7.48s7.37 3.36 7.37 7.48zM1.22 84.61v-6.84c0-5.69 4.12-7.79 9.42-7.79l6.51 7.17 6.04-7.17c2.05 0 4 .32 5.61 1.03M24.51 56.28c0 4.12-3.32 7.48-7.37 7.48s-7.37-3.37-7.37-7.48v-2.77c0-4.12 3.31-7.48 7.37-7.48s7.37 3.36 7.37 7.48zM96.78 84.61v-6.84c0-5.69-4.12-7.79-9.42-7.79l-6.51 7.17-6.04-7.17c-2.06 0-4 .32-5.61 1.03M73.49 56.28c0 4.12 3.32 7.48 7.37 7.48s7.36-3.37 7.36-7.48v-2.77c0-4.12-3.32-7.48-7.36-7.48s-7.37 3.36-7.37 7.48z" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m44.29 27.83 4.69 11.15 4.7-11.15h17.18l9.38 11.15V27.83h11.81c2.54 0 4.61-2.08 4.61-4.61V6c0-2.54-2.07-4.61-4.6-4.61H5.95c-2.54 0-4.61 2.08-4.61 4.62v17.21c0 2.54 2.07 4.61 4.61 4.61H8.8" /><path vectorEffect="non-scaling-stroke" d="M17.73 27.83v11.15l9.39-11.15h8.14" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0043);
