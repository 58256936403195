import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import { EMPTY_OBJ, isEqual } from '@kitted/shared-utils';

import {
  FormContextProps,
  FormDataValue,
  FormErrorsValue,
  FormLoadingItemsValue,
  FormStates,
} from '../../types';
import { handleResetForm, validateFields } from './logic';

const useFormManagement = (
  dataSchema: FormContextProps['dataSchema'],
  initialValues: FormContextProps['initialValues']
) => {
  const [formInstanceId, setFormInstanceId] = useState<string>(v4());
  const [formDataHasChanged, setFormDataHasChanged] = useState<boolean>(false);
  const [formIsSubmitting, setFormIsSubmitting] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormDataValue>(
    initialValues || (EMPTY_OBJ as FormDataValue)
  );
  const [formLoadingItems, setFormLoadingItems] =
    useState<FormLoadingItemsValue>(EMPTY_OBJ as FormLoadingItemsValue);
  const [formErrors, setFormErrors] = useState<FormErrorsValue>(
    EMPTY_OBJ as FormErrorsValue
  );

  const getFormData = useCallback(
    <T extends FormDataValue>() => formData as T,
    [formData]
  );

  const bumpFormInstanceId = useCallback(() => {
    const newInstanceId = v4();
    setFormDataHasChanged(false);
    setFormInstanceId(newInstanceId);
  }, []);

  const resetForm = useCallback(
    (shouldResetData = false) =>
      handleResetForm({
        shouldResetData,
        setFormData,
        bumpFormInstanceId,
        initialValues,
      }),
    [initialValues, bumpFormInstanceId]
  );

  const addFormLoadingItem = useCallback((itemKey: string) => {
    setFormLoadingItems((prevLoadingItems) => ({
      ...prevLoadingItems,
      [itemKey]: true,
    }));
  }, []);

  const removeFormLoadingItem = useCallback((itemKey: string) => {
    setFormLoadingItems((prevLoadingItems) => {
      const { [itemKey]: _deleted, ...newLoadingItems } = prevLoadingItems;
      return newLoadingItems;
    });
  }, []);

  useEffect(() => {
    validateFields(dataSchema, formData, setFormErrors);
  }, [dataSchema, formData]);

  useEffect(() => {
    setFormDataHasChanged(true);
  }, [formData]);

  const isFormDataDirty = useMemo(
    () => !isEqual(formData, initialValues) && formDataHasChanged,
    [formDataHasChanged, formData, initialValues]
  );

  const hasLoadingItems = useMemo(
    () => Object.values(formLoadingItems).some((isLoading) => !!isLoading),
    [formLoadingItems]
  );

  const hasFormErrors = useMemo(
    () => Object.values(formErrors).some((hasErrors) => !!hasErrors),
    [formErrors]
  );

  const formState = useMemo(() => {
    if (formIsSubmitting) {
      return FormStates.SUBMITTING;
    }
    if (hasLoadingItems) {
      return FormStates.LOADING;
    }
    if (hasFormErrors) {
      return FormStates.ERROR;
    }
    if (isFormDataDirty) {
      return FormStates.DIRTY;
    }
    return FormStates.CLEAN;
  }, [hasLoadingItems, isFormDataDirty, hasFormErrors, formIsSubmitting]);

  return {
    formData,
    formState,
    formErrors,
    formInstanceId,

    getFormData,

    addFormLoadingItem,
    removeFormLoadingItem,

    setFormData,
    setFormErrors,
    setFormIsSubmitting,
    resetForm,
  };
};

export default useFormManagement;
