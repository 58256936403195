import { BlobMetadata } from '@kitted/kitted-models';

import useRequests from '../../../../contexts/RequestsContext/hooks/useRequests';
import useAsyncRequest from '../../../useAsyncRequest';

const useBlobStorageUploadRequest = () => {
  const { platformRequest } = useRequests();

  const [uploadFile] = useAsyncRequest(
    (files: File[], onUploadProgress?: (p: number) => void) => {
      const formData = new FormData();
      files.forEach((f) => {
        formData.append('file', f);
      });

      return platformRequest<BlobMetadata[]>('blob-storage', {
        method: 'post',
        url: '/upload',
        data: formData,
        onUploadProgress: ({ loaded, total = 1 }) => {
          if (onUploadProgress) {
            onUploadProgress(loaded / total);
          }
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }
  );

  return uploadFile;
};

export default useBlobStorageUploadRequest;
