import {
  UserChangePassword,
  UserConfirmForgotPasswordRequest,
  UserConfirmForgotPasswordResponse,
  UserForgotPasswordRequest,
} from '@kitted/auth-service-models';
import { KittedServiceEnum } from '@kitted/kitted-models';

import useAsyncRequest from '../../../../hooks/useAsyncRequest';
import useRequests from '../../../RequestsContext/hooks/useRequests';

const usePasswordUser = () => {
  const { platformRequest } = useRequests();

  // change password function
  const [changePassword] = useAsyncRequest<void>(
    (changePasswordPayload: UserChangePassword) =>
      platformRequest(KittedServiceEnum.Auth, {
        method: 'POST',
        data: changePasswordPayload,
        url: '/v1/changePassword',
      })
  );

  // forgot password function
  const [forgotPassword] = useAsyncRequest<void>(
    (forgotPasswordPayload: UserForgotPasswordRequest) =>
      platformRequest(KittedServiceEnum.Auth, {
        method: 'POST',
        data: forgotPasswordPayload,
        url: '/v1/forgotPassword',
      })
  );

  // forgot password confirm function
  const [confirmForgotPassword] =
    useAsyncRequest<UserConfirmForgotPasswordResponse>(
      (confirmForgotPasswordPayload: UserConfirmForgotPasswordRequest) =>
        platformRequest(KittedServiceEnum.Auth, {
          method: 'POST',
          data: confirmForgotPasswordPayload,
          url: '/v1/forgotPassword/confirm',
        })
    );

  return {
    changePassword,
    forgotPassword,
    confirmForgotPassword,
  };
};

export default usePasswordUser;
