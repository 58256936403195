import { useCallback, useEffect, useRef } from 'react';
import type { JwtPayload } from 'jsonwebtoken';
import jwtDecode from 'jwt-decode';
import { getFullName } from '@kitted/shared-utils';

import { useAnalyticsApi } from '../../../../../contexts/AnalyticsContext';
import useAuthTokensData from '../../../../../contexts/AuthTokensContext/hooks/useAuthTokensData';
import { AuthenticationTokens } from '../../../../../services/authenticationTokensManager/types';

const useOnLoginOutTrigger = () => {
  const { identifyUser } = useAnalyticsApi();
  const { tokens } = useAuthTokensData();
  const tokensRef = useRef<AuthenticationTokens>();

  const onUserIdChange = useCallback(() => {
    if (!tokensRef.current && tokens) {
      // tokens have been added
      tokensRef.current = tokens;
      const { idToken } = tokens;
      if (idToken) {
        const {
          sub,
          email,
          given_name: givenName,
          family_name: familyName,
        } = jwtDecode<JwtPayload>(idToken);
        if (sub) {
          identifyUser(sub, {
            email,
            name: getFullName(givenName, familyName),
          });
        }
      }
    } else if (!tokens && tokensRef.current) {
      // tokens have been removed
      tokensRef.current = undefined;
      identifyUser(undefined);
    }
  }, [identifyUser, tokens]);

  useEffect(onUserIdChange, [onUserIdChange]);
};

export default useOnLoginOutTrigger;
