import { z } from 'zod';

import { ToolType } from '../Enum';
import {
  ToolBaseCardLoadedModelSchema,
  ToolBaseCardUnloadedModelSchema,
} from '../ToolBase';

export const InsightToolCardBaseModelSchema = z.object({
  toolType: z.literal(ToolType.Insight),
});

export const InsightToolCardLoadedModelSchema =
  ToolBaseCardLoadedModelSchema.merge(InsightToolCardBaseModelSchema);
export const InsightToolCardUnloadedModelSchema =
  ToolBaseCardUnloadedModelSchema.merge(InsightToolCardBaseModelSchema);

export type InsightToolCardLoadedModel = z.infer<
  typeof InsightToolCardLoadedModelSchema
>;
export type InsightToolCardUnloadedModel = z.infer<
  typeof InsightToolCardUnloadedModelSchema
>;

export type InsightToolCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? InsightToolCardLoadedModel
    : InsightToolCardUnloadedModel;
