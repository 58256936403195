import { useMemo } from 'react';

const useBackdrop = (index: number) => {
  const style = useMemo(
    () =>
      ({
        '--backdropIndex': index,
      }) as React.CSSProperties,
    [index]
  );

  return {
    style,
  };
};

export default useBackdrop;
