/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';
import type { CSSProperties } from 'react';
import { Fade, Typography } from '@kitted/shared-components';
import { Arrow, Content, Portal, Root, Trigger } from '@radix-ui/react-popover';

import DrawerSpacePortal from '../DrawerSpace/DrawerSpacePortal';
import usePopover from './hooks/usePopover';
import useStyles from './styles';
import { PopoverProps } from './types';

const Popover = ({
  className,
  arrowClassName,
  children,
  content,
  open,
  defaultOpen = false,
  onOpenChange,
  isDisabled = false,
  side = 'top',
  sideOffset = 32,
  autoFocus,
  withArrow = true,
  align = 'start',
  modal,
  portalContainer,
  onInteractOutside,
  withBackdrop,
  hoistTrigger,
  ...props
}: PopoverProps) => {
  const { triggerRef, isOpen, onOpenAutoFocus, handleInteractOutside } =
    usePopover(defaultOpen, open, autoFocus, onInteractOutside);
  const styles = useStyles();

  if (isDisabled) {
    return <>{children}</>;
  }

  return (
    <Root open={isOpen} onOpenChange={onOpenChange} modal={modal}>
      {withBackdrop && (
        <DrawerSpacePortal>
          <Fade
            className={styles.backdropContainer}
            in={isOpen}
            durationIn={500}
            durationOut={500}
          >
            <div className={styles.backdrop} />
          </Fade>
        </DrawerSpacePortal>
      )}
      <Trigger asChild>
        <span
          ref={triggerRef}
          className={clsx(className, styles.triggerWrapper)}
        >
          {children}
        </span>
      </Trigger>
      <Portal container={portalContainer}>
        {content && (
          <Content
            side={side}
            align={align}
            sideOffset={sideOffset}
            onInteractOutside={handleInteractOutside}
            {...props}
            onOpenAutoFocus={onOpenAutoFocus}
          >
            <>
              {hoistTrigger && (
                <span
                  className={styles.hoistedTrigger}
                  style={{ '--sideOffset': sideOffset } as CSSProperties}
                >
                  {children}
                </span>
              )}
              <Typography
                as={Fade}
                variant="tooltip"
                appear
                in
                durationIn={500}
                durationOut={500}
              >
                {content}
                {withArrow && (
                  <Arrow
                    width={12}
                    height={10}
                    className={clsx(styles.arrow, arrowClassName)}
                  />
                )}
              </Typography>
            </>
          </Content>
        )}
      </Portal>
    </Root>
  );
};

export default memo(Popover);
