import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0034: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 126 110"
		height={110}
		width={126}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <circle vectorEffect="non-scaling-stroke" cx="34.07" cy="22.38" r="8.66" stroke={color} /><circle vectorEffect="non-scaling-stroke" cx="10.32" cy="20.13" r="8.66" stroke={color} /><circle vectorEffect="non-scaling-stroke" cx="16.74" cy="42.33" r="8.66" stroke={color} /><circle vectorEffect="non-scaling-stroke" cx="30.53" cy="77.13" r="8.66" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="20.59" cy="98.82" r="8.66" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="43.67" cy="100.06" r="8.66" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="54.04" cy="78.66" r="8.66" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="67.18" cy="98.82" r="8.66" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="105.65" cy="9.94" r="8.66" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="91.87" cy="29.51" r="8.66" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="115.68" cy="32.14" r="8.66" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="83.2" cy="59.8" r="8.66" stroke={color} /><circle vectorEffect="non-scaling-stroke" cx="65.88" cy="45.32" r="8.66" stroke={color} />
    </g>
  </CardIcon>
);

export default memo(Ac0034);
