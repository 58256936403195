import { useMemo } from 'react';
import { ResourceCardTypeValues } from '@kitted/card-service-models';

import { CardSubType } from '../../../types';
import { shouldDisplayCardImageHalfHeight } from '../../logic';

const useResourceTypeImage = (subType?: CardSubType, isFeatured = false) => {
  const halfHeight = useMemo(
    () =>
      shouldDisplayCardImageHalfHeight(
        subType as ResourceCardTypeValues,
        isFeatured
      ),
    [subType, isFeatured]
  );
  return {
    halfHeight,
  };
};

export default useResourceTypeImage;
