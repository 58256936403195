import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const CardCarouselPlus: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 34 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M21.8 10.43h-9.82M16.89 15.34V5.52M12.03 19.11h9.77M12.03 22.44h9.77M3.45 11.91l-2.14 1.85 2.14 1.85M30.55 15.61l2.14-1.85-2.14-1.85" /><rect vectorEffect="non-scaling-stroke" width="19.7" height="25.24" x="7.15" y="1.38" stroke={color} rx="2.57" ry="2.57" /><rect vectorEffect="non-scaling-stroke" width="19.7" height="25.24" x="7.15" y="1.38" stroke={color} rx="2.57" ry="2.57" />
    </g>
  </WebsiteIcon>
);

export default memo(CardCarouselPlus);
