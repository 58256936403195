import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0024: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 108 114"
		height={114}
		width={108}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><circle vectorEffect="non-scaling-stroke" cx="54" cy="11.42" r="9.73" /><path vectorEffect="non-scaling-stroke" d="m62 112.31 1.95-32.41h13.82c-8.83-21.42-10.58-44.47-10.58-44.47-1.05-3.27-3.37-5.05-7.14-5.05H47.96c-3.78 0-6.09 1.78-7.14 5.05 0 0-1.74 23.05-10.58 44.47h13.82l1.95 32.41" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M30.67 28.24s-8.36-5.53-15.66-5.47c-6.26.06-8.52-.42-13.15-3.36 0 0-6.2 16.19 16.72 17.03" /><path vectorEffect="non-scaling-stroke" d="M6.17 34.08S3.23 46.96 22.99 47.37" /><path vectorEffect="non-scaling-stroke" d="M14.89 46.68s-.52 10.15 12.94 10.15M77.33 28.24s8.36-5.53 15.66-5.47c6.26.06 8.52-.42 13.15-3.36 0 0 6.2 16.19-16.72 17.03" /><path vectorEffect="non-scaling-stroke" d="M101.83 34.08s2.94 12.88-16.82 13.29" /><path vectorEffect="non-scaling-stroke" d="M93.11 46.68s.52 10.15-12.94 10.15" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0024);
