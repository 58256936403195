import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const RenewCircle: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 26 32"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M7.1 22.63A8.868 8.868 0 0 1 13 7.14h.16v2.45l6.92-4-6.92-4v2.45H13C6.39 4.04 1.03 9.4 1.03 16.01c0 3.12 1.19 5.96 3.15 8.09M18.89 9.38a8.83 8.83 0 0 1 2.98 6.63c0 4.9-3.97 8.87-8.87 8.87h-.16v-2.46l-6.92 4 6.92 4v-2.44H13c6.61 0 11.97-5.36 11.97-11.97 0-3.1-1.18-5.92-3.11-8.05" />
    </g>
  </WebsiteIcon>
);

export default memo(RenewCircle);
