import { styling } from '@kitted/design-system';

import { DrawerSpaceContainerData } from '../DrawerSpace/context/DrawerSpaceContainerContext';

//  import { ImageAssetModelDimensions } from './types';

// export const handleOnLoad = (
//   setImageDimensions: (dimensions: ImageAssetModelDimensions) => void,
//   img: HTMLImageElement
// ) => {
//   setImageDimensions({
//     height: img.naturalHeight,
//     width: img.naturalWidth,
//   });
// };

export const calculateOffset = ({
  offsetForMenu,
  isMainOpen,
  isMainDisabled,
  isContextualOpen,
  isContextualDisabled,
}: {
  offsetForMenu: boolean;
  isMainOpen: DrawerSpaceContainerData['isMainOpen'];
  isMainDisabled: DrawerSpaceContainerData['isMainDisabled'];
  isContextualOpen: DrawerSpaceContainerData['isContextualOpen'];
  isContextualDisabled: DrawerSpaceContainerData['isContextualDisabled'];
}): number => {
  if (!offsetForMenu) return 0;
  let offset = 0;
  if (!isMainDisabled) {
    offset += isMainOpen
      ? styling.sizes.context.drawer.openWidth
      : styling.sizes.context.drawer.closedWidth;
  }
  if (!isContextualDisabled) {
    offset += isContextualOpen
      ? styling.sizes.context.drawer.openWidth
      : styling.sizes.context.drawer.closedWidth;
  }
  return offset;
};

export const getBackgroundImageStyle = (position: number, leftValue: number) =>
  ({
    '--imageTranslateY': styling.utils.px(position),
    '--imageLeft': styling.utils.px(leftValue),
  }) as React.CSSProperties;
