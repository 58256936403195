import { useCallback } from 'react';
import { BlobGuid } from '@kitted/kitted-models';

import useEnvironmentDomain from '../useEnvironmentDomain';
import useIsLocalDevelopment from '../useIsLocalDevelopment';
import useBlobStorageUploadRequest from './hooks/useBlobStorageUploadRequest';
import { getBlobStorageContentUrl, getBlobStorageMetadataUrl } from './logic';

const useBlobStorage = () => {
  const environmentDomain = useEnvironmentDomain();
  const isLocalDevelopment = useIsLocalDevelopment();
  const uploadFile = useBlobStorageUploadRequest();

  const constructBlobStorageMetadataUrl = useCallback(
    (blobStorageId: BlobGuid) =>
      getBlobStorageMetadataUrl(
        isLocalDevelopment,
        environmentDomain,
        blobStorageId
      ),
    [isLocalDevelopment, environmentDomain]
  );

  const constructBlobStorageContentUrl = useCallback(
    (blobStorageId: BlobGuid) =>
      getBlobStorageContentUrl(environmentDomain, blobStorageId),
    [environmentDomain]
  );

  return {
    uploadFile,
    constructBlobStorageMetadataUrl,
    constructBlobStorageContentUrl,
  };
};

export default useBlobStorage;
