import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0040: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 96 112"
		height={112}
		width={96}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m70.9 40.23.64-19.83c.07-8.5.83-12.54 3.47-14.52 5.61-1.98 8.27 2.27 8.6 8.38l1.72 30.81 2.21 8.83-.31 33.28s.48 7.65 7.41 20.52" /><path vectorEffect="non-scaling-stroke" d="m71.7 110.84-8.78-17.5c-4.97-7.36-2.23-16.09-2.23-16.09 1.11-4.23 1.36-12.08 1.36-12.08l1.16-13.57c0-3.88 1.62-6.52 5.01-6.52s6.16 3.18 6.16 7.06v18.04" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M24.05 21.72h7.91v24.82M21.81 46.77h20.15" /><circle vectorEffect="non-scaling-stroke" cx="31.43" cy="13.63" r=".96" /><path vectorEffect="non-scaling-stroke" d="M54.31 11.67a29.96 29.96 0 0 1 7.24 19.59c0 16.62-13.47 30.1-30.1 30.1S1.36 47.88 1.36 31.25c0-16.62 13.48-30.1 30.1-30.1 4.9 0 9.53 1.17 13.62 3.25" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0040);
