import { memo } from 'react';
import { Typography } from '@kitted/shared-components';

import { useAssetCatalogueData } from '../../contexts/AssetCatalogueProvider';
import useStyles from './styles';

const EmptyCatalogueItems = () => {
  const { searchText } = useAssetCatalogueData();
  const styles = useStyles();

  if (searchText) {
    return (
      <div className={styles.container}>
        <Typography as="h4" variant="nullStateTitle">
          There aren’t any images that match&nbsp;“{searchText}”
        </Typography>
        <Typography variant="nullStateBody">
          Try a less specific search term or clear it to see more images.
        </Typography>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Typography as="h4" variant="nullStateTitle">
        No images have been uploaded&nbsp;yet
      </Typography>
      <Typography variant="nullStateBody">
        Click the upload button to add images to your catalogue
      </Typography>
    </div>
  );
};

export default memo(EmptyCatalogueItems);
