import { z } from 'zod';

import {
  ContentBlockCarouselItemLoadedModelSchema,
  ContentBlockCarouselItemUnloadedModelSchema,
} from './ContentBlockCarouselItemModel';
import {
  ContentBlockImageLoadedModelSchema,
  ContentBlockImageUnloadedModelSchema,
} from './ContentBlockImageModel';
import {
  ContentBlockVideoLoadedModelSchema,
  ContentBlockVideoUnloadedModelSchema,
} from './ContentBlockVideoModel';

export const AnyContentBlockCarouselItemLoadedModelSchema = z.union([
  ContentBlockCarouselItemLoadedModelSchema,
  ContentBlockImageLoadedModelSchema,
  ContentBlockVideoLoadedModelSchema,
]);

export const AnyContentBlockCarouselItemUnloadedModelSchema = z.union([
  ContentBlockCarouselItemUnloadedModelSchema,
  ContentBlockImageUnloadedModelSchema,
  ContentBlockVideoUnloadedModelSchema,
]);

export type AnyContentBlockCarouselItemLoadedModel = z.infer<
  typeof AnyContentBlockCarouselItemLoadedModelSchema
>;
export type AnyContentBlockCarouselItemUnloadedModel = z.infer<
  typeof AnyContentBlockCarouselItemUnloadedModelSchema
>;

export type AnyContentBlockCarouselItemModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? AnyContentBlockCarouselItemLoadedModel
    : AnyContentBlockCarouselItemUnloadedModel;
