/**
 * Enumeration representing the unique types for every model in the system.
 *
 * This enum is used for type identification when sending models between different layers
 * or services within the application. Specifically, it helps in the following ways:
 *
 * 1. It ensures type safety by enabling strict type checking during compile-time.
 * 2. It provides a standardized way to identify the type of a model, which simplifies deserialization logic.
 * 3. When a model is sent to the frontend or another service, the receiving end can use this enum
 *    to determine the correct type to cast the model to.
 * 4. Our CMS transforms external system models (Contentful) into internal system models. The transformed models
 *    are then broadcast to all microservices as part of a subscription mechanism. This enum helps
 *    microservices identify and properly handle or store these models.
 *
 * Example usage in a deserialization function:
 * ```typescript
 * switch (receivedModel.type) {
 *   case KittedModelTypeEnum.AccountAccount:
 *     const account = deserializeAccount(receivedModel.data);
 *     break;
 *   // ... other cases ...
 * }
 * ```
 */

export enum KittedModelTypeEnum {
  Unknown = 'Unknown',

  // Cross service Content Blocks
  XContentBlockText = 'XContentBlockText',
  XContentBlockImage = 'XContentBlockImage',
  XContentBlockVideo = 'XContentBlockVideo',
  XContentBlockCardGrid = 'XContentBlockCardGrid',
  XContentBlockCarousel = 'XContentBlockCarousel',
  XContentBlockCarouselItem = 'XContentBlockCarouselItem',
  XContentBlockQuote = 'XContentBlockQuote',

  // Account service
  AccountAccount = 'AccountAccount',
  AccountFavorite = 'AccountFavorite',

  // Article service
  ArticleArticle = 'ArticleArticle',
  ArticleArticleBlockExecutiveBoard = 'ArticleArticleBlockExecutiveBoard',

  // Asset service
  AssetAsset = 'AssetAsset',
  AssetUnknownAssetModel = 'AssetUnknownAssetModel',

  // Auth service
  AuthUserAttributes = 'AuthUserAttributes',

  // Author service
  AuthorAuthor = 'AuthorAuthor',

  // blob Storage service
  BlobStorageBlobMetadata = 'BlobStorageBlobMetadata',

  // Card service
  CardActionTool = 'CardActionTool',
  CardArticle = 'CardArticle',
  CardAuthor = 'CardAuthor',
  CardDeck = 'CardDeck',
  CardFlow = 'CardFlow',
  CardFlowStep = 'CardFlowStep',
  CardFrameTool = 'CardFrameTool',
  CardImageResource = 'CardImageResource',
  CardInsightTool = 'CardInsightTool',
  CardKit = 'CardKit',
  CardLinkResource = 'CardLinkResource',
  CardPlay = 'CardPlay',
  CardSnareTool = 'CardSnareTool',
  CardSubject = 'CardSubject',
  CardVideoResource = 'CardVideoResource',
  CardDisplay = 'CardDisplay',

  // Contentful service
  CMSArticle = 'CMSArticle',
  CMSArticleBlockExecutiveBoard = 'CMSArticleBlockExecutiveBoard',
  CMSContentBlockText = 'CMSContentBlockText',
  CMSContentBlockImage = 'CMSContentBlockImage',
  CMSContentBlockVideo = 'CMSContentBlockVideo',
  CMSContentBlockCardGrid = 'CMSContentBlockCardGrid',
  CMSContentBlockCarousel = 'CMSContentBlockCarousel',
  CMSContentBlockCarouselItem = 'CMSContentBlockCarouselItem',
  CMSContentBlockQuote = 'CMSContentBlockQuote',
  CMSFlow = 'CMSFlow',
  CMSFlowStep = 'CMSFlowStep',
  CMSKit = 'CMSKit',
  CMSImageResource = 'CMSImageResource',
  CMSLinkResource = 'CMSLinkResource',
  CMSVideoResource = 'CMSVideoResource',
  CMSDisplayCard = 'CMSDisplayCard',
  CMSPage = 'CMSPage',
  CMSPlay = 'CMSPlay',

  // Flow service
  FlowFlow = 'FlowFlow',
  FlowFlowStep = 'FlowFlowStep',

  // Resource service
  ResourceImageResource = 'ResourceImageResource',
  ResourceLinkResource = 'ResourceLinkResource',
  ResourceVideoResource = 'ResourceVideoResource',

  // Kit Service
  KitKit = 'KitKit',

  // Communication service
  CommunicationEmailButton = 'CommunicationEmailButton',
  CommunicationEmailCardGrid = 'CommunicationEmailCardGrid',
  CommunicationEmailFeaturedCard = 'CommunicationEmailFeaturedCard',
  CommunicationEmailImage = 'CommunicationEmailImage',
  CommunicationEmailPanel = 'CommunicationEmailPanel',
  CommunicationEmailText = 'CommunicationEmailText',
  CommunicationEmailTemplate = 'CommunicationEmailTemplate',

  // Membership service
  MembershipEvent = 'MembershipEvent',
  MembershipSummary = 'MembershipSummary',

  // Page service
  PagePage = 'PagePage',

  // Play service
  PlayPlay = 'PlayPlay',

  // Shop service
  ShopOrder = 'ShopOrder',

  // Invite service
  InviteInvite = 'InviteInvite',

  // Catalogue Service
  CatalogueCatalogueItem = 'CatalogueCatalogueItem',
}
