import { createContext, useMemo } from 'react';
import { EMPTY_PROMISE } from '@kitted/shared-utils';

import { RequestsContextProps } from '../types';
import useExternalRequest from './hooks/useExternalRequest';
import usePlatformRequest from './hooks/usePlatformRequest';
import { RequestApi } from './types';

export const RequestsContext = createContext<RequestApi>({
  platformRequest: EMPTY_PROMISE,
  externalRequest: EMPTY_PROMISE,
});

export const RequestsProvider: React.FC<RequestsContextProps> = ({
  children,
}) => {
  const platformRequest = usePlatformRequest();
  const externalRequest = useExternalRequest();
  const value = useMemo(
    () => ({ platformRequest, externalRequest }),
    [platformRequest, externalRequest]
  );

  return (
    <RequestsContext.Provider value={value}>
      {children}
    </RequestsContext.Provider>
  );
};
