import { useMemo } from 'react';
import { formatRelative } from 'date-fns';
import { noOrphans } from '@kitted/shared-utils';

import { CardAuthorAvatarTooltipContentProps } from '../../types';

const useCardAuthorAvatarTooltipContent = (
  publishedAt: CardAuthorAvatarTooltipContentProps['publishedAt']
) => {
  const publishedDate = useMemo(() => {
    if (!publishedAt) return '';
    const formattedDate = noOrphans(
      formatRelative(new Date(publishedAt), new Date())
    );
    if (Number.isNaN(+formattedDate[0])) {
      return formattedDate;
    }
    return `on ${formattedDate}`;
  }, [publishedAt]);

  return {
    publishedDate,
  };
};

export default useCardAuthorAvatarTooltipContent;
