import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0030: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 108 102"
		height={102}
		width={108}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M33.89 26.73c-17.21 0-32.85-5.43-32.85-5.43v41.22c0 20.85 17.21 37.9 32.85 37.9s32.85-17.06 32.85-37.9V21.3s-15.63 5.43-32.85 5.43" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M49.29 67.07c0 8.5-6.89 15.4-15.39 15.4s-15.4-6.89-15.4-15.4c0 0 6.89 3.07 15.4 3.07s15.39-3.07 15.39-3.07" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M15.15 47.17c1.08-2 3.2-3.36 5.63-3.36s4.48 1.32 5.58 3.26M41.42 47.17c1.08-2 3.19-3.36 5.63-3.36s4.48 1.32 5.58 3.26" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M71.54 80.56c.85.17 1.92.14 2.57.14 15.63 0 32.85-17.06 32.85-37.9V1.58S91.33 7.01 74.11 7.01 41.26 1.58 41.26 1.58v16.64" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M74.11 58.76c8.5 0 15.39 3.07 15.39 3.07 0-8.5-6.89-15.4-15.39-15.4" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M81.64 24.09c1.08 2 3.2 3.36 5.63 3.36s4.48-1.32 5.58-3.26" />
    </g>
  </CardIcon>
);

export default memo(Ac0030);
