import { useMemo } from 'react';

import useOnLoginLogout from './hooks/useOnLoginLogout';
import useUserManagement from './hooks/useUserManagement';
import { UserContextProps } from './types';
import { UserContext, UserDataContext } from './UserContext';

// Little internal component to run a hook in the context of the UserDataContext
const UserStateSyncInternal: React.FC<UserContextProps> = ({ children }) => {
  // This could be moved out in the tree somewhere, if we decide to move the hook
  // to the ./src/hooks directory
  useOnLoginLogout();

  // This fragment is not useless!
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export const UserProvider: React.FC<UserContextProps> = ({ children }) => {
  const {
    loginState,
    setLoginState,
    logoutUser,
    registerUser,
    loginUser,
    verifyEmail,
    resendVerifyEmail,
    forgotPassword,
    confirmForgotPassword,
    submitChallengeResponse,
  } = useUserManagement();
  const apiValue = useMemo(
    () => ({
      setLoginState,
      logoutUser,
      registerUser,
      loginUser,
      verifyEmail,
      resendVerifyEmail,
      forgotPassword,
      confirmForgotPassword,
      submitChallengeResponse,
    }),
    [
      setLoginState,
      logoutUser,
      registerUser,
      loginUser,
      verifyEmail,
      resendVerifyEmail,
      forgotPassword,
      confirmForgotPassword,
      submitChallengeResponse,
    ]
  );
  const dataValue = useMemo(() => ({ loginState }), [loginState]);

  return (
    <UserContext.Provider value={apiValue}>
      <UserDataContext.Provider value={dataValue}>
        <UserStateSyncInternal>{children}</UserStateSyncInternal>
      </UserDataContext.Provider>
    </UserContext.Provider>
  );
};
