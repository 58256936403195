import { useCallback, useMemo, useState } from 'react';
import { upperFirst } from '@kitted/shared-utils';

import {
  ModelCreateEditEditModalSpaceProps,
  ModelCreateEditFormData,
  ModelCreateEditProps,
} from '../../types';

const useModelCreateEdit = (
  title: ModelCreateEditProps['title'],
  id: ModelCreateEditProps['id'],
  cardType: ModelCreateEditProps['cardType'],
  isCloning: ModelCreateEditProps['isCloning'],
  onSaveChanges: ModelCreateEditProps['onSaveChanges'],
  onClose: ModelCreateEditProps['onClose']
) => {
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const wrappedOnSaveChanges = useCallback(
    async (formData: ModelCreateEditFormData) => {
      setShowLoading(true);
      try {
        await onSaveChanges(formData);
      } finally {
        setShowLoading(false);
      }
    },
    [onSaveChanges, setShowLoading]
  );

  const spaceProps: ModelCreateEditEditModalSpaceProps = useMemo(
    () => ({
      title,
      id,
      cardType,
      isCloning,
      onSaveChanges: wrappedOnSaveChanges,
      onClose,
    }),
    [title, id, cardType, wrappedOnSaveChanges, onClose, isCloning]
  );

  const [loadingText, action] = useMemo(() => {
    const cardTypeTitle = upperFirst(cardType);
    if (id) {
      return [`Saving ${cardTypeTitle}`, 'saving'];
    }
    if (isCloning) {
      return [`Cloning ${cardTypeTitle}`, 'cloning'];
    }
    return [`Creating ${cardTypeTitle}`, 'creating'];
  }, [cardType, id, isCloning]);

  return { spaceProps, showLoading, loadingText, action };
};

export default useModelCreateEdit;
