import { InputComponentProps, InputSelectOption } from '../types';

export const getSanitizedItem = (
  options: InputComponentProps['options'],
  value: InputComponentProps['value']
) => options?.find((option) => option.value === value);

export const handleOnChange = (
  onChange: InputComponentProps['onChange'],
  option: InputSelectOption
) => {
  onChange(option.value);
};
