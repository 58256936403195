import { FieldValue } from '../../../contexts/FormContext';
import { ValidationFn } from './types';

export type ValidationItemHasMaxLength = {
  name: 'hasMaxLength';
  maxLength: number;
  validator: ValidationFn;
};

export const hasMaxLength = (
  maxLength: ValidationItemHasMaxLength['maxLength']
): ValidationItemHasMaxLength => ({
  name: 'hasMaxLength',
  maxLength,
  validator: (value: FieldValue) => {
    if (typeof value !== 'string') {
      return `Invalid value type: ${typeof value}`;
    }
    const isValid = value?.length <= maxLength;

    if (isValid) return undefined;
    return `This cannot have more than ${maxLength} characters`;
  },
});
