/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, useState } from 'react';
import { EMPTY_OBJ } from '@kitted/shared-utils';

import { getLightboxContentComponent } from '../../logic';
import { LightboxModalMeta } from '../../types';

const useLightbox = (
  imgSrc: LightboxModalMeta['imgSrc'],
  imageAsset: LightboxModalMeta['imageAsset'],
  videoSrc: LightboxModalMeta['videoSrc'],
  _cardId: LightboxModalMeta['cardId']
) => {
  const [imageIsZoomed, setImageIsZoomed] = useState<boolean>(false);
  const [style, setStyle] = useState<React.CSSProperties>(EMPTY_OBJ);

  const onToggleImageIsZoomed = useCallback(() => {
    setImageIsZoomed((currentlyIsZoomed) => !currentlyIsZoomed);
  }, [setImageIsZoomed]);

  const onImageLoad = useCallback(
    (img: HTMLImageElement, isFallback: boolean) => {
      if (isFallback) {
        return;
      }
      setStyle({
        '--lightboxImageWidth': `${img.naturalWidth}`,
        '--lightboxImageHeight': `${img.naturalHeight}`,
        aspectRatio: `var(--lightboxImageWidth) / var(--lightboxImageHeight)`,
      } as React.CSSProperties);
    },
    [setStyle]
  );

  const { component: Component, ...componentProps }: any = useMemo(
    () =>
      getLightboxContentComponent({
        imgSrc,
        imageAsset,
        videoSrc,
        onImageLoad,
        onToggleImageIsZoomed,
      }),
    [imgSrc, imageAsset, videoSrc, onImageLoad, onToggleImageIsZoomed]
  );

  return {
    style,
    Component,
    componentProps,
    imageIsZoomed,
  };
};

export default useLightbox;
