import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0048: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 110 110"
		height={110}
		width={110}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m44.56 76.31-22 11.13 7.77-15.59M79.63 71.77l7.81 15.67-22.03-11.15M65.45 33.69l21.99-11.13-7.85 15.73M30.4 38.29l-7.84-15.73 21.93 11.1" /><path vectorEffect="non-scaling-stroke" d="m65.38 33.71 2.69 8.22L107.93 55 68.07 68.07 55 107.93 41.93 68.07 2.07 55l39.86-13.07 2.69-8.23" /><path vectorEffect="non-scaling-stroke" d="M61.95 55c0 3.84-3.11 6.96-6.95 6.96s-6.95-3.12-6.95-6.96 3.11-6.95 6.95-6.95 6.95 3.11 6.95 6.95" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M62.67 25.44 55 2.07l-7.67 23.37" />
    </g>
  </CardIcon>
);

export default memo(In0048);
