import { Dispatch, SetStateAction } from 'react';
import { uniqueId } from 'lodash';
import { replaceAtIndex } from '@kitted/shared-utils';

import { ToastApi, ToastData, ToastEventListner } from '../../types';

export const getDeregisterToast =
  (
    setToasts: Dispatch<SetStateAction<ToastData>>
  ): ToastApi['deregisterToast'] =>
  (toastId) => {
    setToasts((toastData) => ({
      ...toastData,
      toasts: toastData.toasts.filter((toast) => toast.key !== toastId),
    }));
  };

export const getRegisterToast =
  (setToasts: Dispatch<SetStateAction<ToastData>>): ToastApi['registerToast'] =>
  (toastId, toastConfig) => {
    setToasts((toastData) => {
      if (toastData.toasts.find((toast) => toast.key === toastId)) {
        return toastData;
      }

      return {
        ...toastData,
        toasts: [...toastData.toasts, { config: toastConfig, key: toastId }],
      };
    });
  };

export const getRegisterToastCallback =
  (
    setToasts: Dispatch<SetStateAction<ToastData>>
  ): ToastApi['registerToastCallback'] =>
  (toastId, event, callback) => {
    const handle = uniqueId();

    const toastEventListner: ToastEventListner = {
      event,
      callback,
      handle,
      toastId,
    };

    setToasts((toastData) => ({
      ...toastData,
      eventListeners: [...toastData.eventListeners, toastEventListner],
    }));

    return handle;
  };

export const getDeregisterToastCallback =
  (
    setToasts: Dispatch<SetStateAction<ToastData>>
  ): ToastApi['deregisterToastCallback'] =>
  (handle) => {
    setToasts((toastData) => ({
      ...toastData,
      eventListeners: toastData.eventListeners.filter(
        (el) => el.handle !== handle
      ),
    }));
  };

export const getUpdateToast =
  (setToasts: Dispatch<SetStateAction<ToastData>>): ToastApi['updateToast'] =>
  (toastId, updatedToast) => {
    setToasts((toastData) => {
      const foundToastIndex = toastData.toasts.findIndex(
        (toast) => toast.key === toastId
      );
      if (foundToastIndex < 0) return toastData;

      const oldToast = toastData.toasts[foundToastIndex];
      const newToast = {
        ...oldToast,
        config: {
          ...oldToast.config,
          ...updatedToast,
        },
      };

      return {
        ...toastData,
        toasts: replaceAtIndex(toastData.toasts, newToast, foundToastIndex),
      };
    });
  };
