import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0022: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 114 114"
		height={114}
		width={114}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M57 1.29v111.42M112.71 57H1.29" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M22.35 41.88h8.22M39.35 13.59h5.46s-4.53 15.07-18.26 15.07h-.18c-13.74 0-18.26-15.07-18.26-15.07h5.46M16.39 8.36h20.13" /><path vectorEffect="non-scaling-stroke" d="M36.52 8.36s.15 19.32-9.94 20.26M16.39 8.36s-.15 19.32 9.94 20.26M24.01 28.71s.42 4.66-3.29 8.99M28.91 28.71s-.42 4.66 3.29 8.99" /><rect vectorEffect="non-scaling-stroke" width="18.69" height="8.36" x="16.98" y="37.7" rx=".62" ry=".62" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m100.08 73.19 2.73-2.74 2.95 2.95-2.65 2.65M81.98 91.29l8.39-8.39M77.34 101.83l-2.69 2.69-2.95-2.95 2.8-2.8M93.31 85.85l-8.36 8.36" /><path vectorEffect="non-scaling-stroke" d="M83.95 100.74c.97.97.97 2.56 0 3.53l-.25.25c-.97.97-2.56.97-3.53 0l-8.48-8.48c-.97-.97-.97-2.56 0-3.53l.25-.25c.97-.97 2.56-.97 3.53 0zM101.16 89.45c.97.97 2.56.97 3.53 0l.24-.24c.97-.97.97-2.56 0-3.53L90.54 71.29c-.97-.97-2.56-.97-3.53 0l-.25.25c-.97.97-.97 2.56 0 3.53l14.39 14.39ZM93.502 70.697l.247-.247a2.5 2.5 0 0 1 3.536 0l8.48 8.476a2.5 2.5 0 0 1 0 3.535l-.247.248a2.5 2.5 0 0 1-3.536 0l-8.48-8.476a2.5 2.5 0 0 1 0-3.536M72.53 85.766l.248-.247a2.5 2.5 0 0 1 3.535 0l14.39 14.39a2.5 2.5 0 0 1 0 3.535l-.248.248a2.5 2.5 0 0 1-3.535 0l-14.39-14.39a2.5 2.5 0 0 1 0-3.536" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0022);
