import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0054: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 90 90"
		height={90}
		width={90}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M20.99 5.63c14.03-7.49 31.87-5.32 43.7 6.51 14.49 14.49 14.49 37.97 0 52.46-14.48 14.49-37.97 14.49-52.46 0-14.48-14.49-14.49-37.97 0-52.46.52-.52 1.06-1.03 1.6-1.51M68.73 61.61l18.61 18.6c1.83 1.83 1.71 4.95-.28 6.94s-5.1 2.11-6.94.28L66.81 74.12" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M50.52 47.94 30.16 27.63M50.74 32.86v15.23H35.59" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0054);
