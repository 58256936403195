import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      position: 'absolute',
      bottom: 80,
      left: 0,
      right: 0,
      textAlign: 'center',
      zIndex: 5,
      padding: [0, styling.sizes.context.gutteringSmW],
      pointerEvents: 'none',
      opacity: 0,
      transition: styling.speeds.jog,
    },
    active: {
      opacity: 1,
    },
  },
  { name: 'playerCaptions' }
);
