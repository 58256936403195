import { useMemo } from 'react';
import {
  getInteractionProps,
  InteractionProps,
} from '@kitted/shared-components';

import ExternalLinkWithCapturedQueryParams from '../../../ExternalLinkWithCapturedQueryParams';
import PreloadingInternalLink from '../../../PreloadingInternalLink';
import { IconButtonProps } from '../../types';

const useIconIconButton = ({
  onClick,
  href,
  to,
  isDisabled,
  nonInteractive,
}: IconButtonProps) => {
  const { as: IconButtonComponent, ...iconButtonProps } = useMemo(
    () =>
      getInteractionProps(
        {
          nonInteractive: isDisabled,
          href,
          to,
          onClick,
        } as InteractionProps,
        {
          defaultComponent: 'button',
          internalLinkComponent: PreloadingInternalLink,
          externalLinkComponent: ExternalLinkWithCapturedQueryParams,
        },
        {
          type: 'button',
        },
        {
          disabled: true,
          type: 'button',
        }
      ),
    [href, to, onClick, isDisabled]
  );

  return {
    IconButtonComponent: nonInteractive ? 'div' : IconButtonComponent,
    iconButtonProps,
  };
};

export default useIconIconButton;
