import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    divider: {
      marginLeft: styling.sizes.theme.spacing.md * -1,
      marginRight: styling.sizes.theme.spacing.md * -1,
      width: `calc(100% + ${styling.utils.px(
        styling.sizes.theme.spacing.md * 2
      )})`,
    },
    iconContainer: {
      background: styling.colors.context.inverted.background,
      color: styling.colors.context.inverted.color,
      height: 64,
      width: 64,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '3.8rem',
      borderRadius: '10000rem',
      margin: [0, 'auto', styling.sizes.theme.spacing.sm],
    },
    forgot: {
      marginBottom: 32,
    },
  },
  { name: 'authForgotPasswordModalContent' }
);
