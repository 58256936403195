import { z } from 'zod';
import { CMSLinkResourceUnloadedModelSchema } from '@kitted/contentful-service-models';
import {
  KittedModelTypeEnum,
  notBoth,
  RlnkSlugSchema,
} from '@kitted/kitted-models';

import { ResourceType } from '../Enum';
import {
  ResourceBaseLoadedModelSchema,
  ResourceBaseSaveModelSchema,
  ResourceBaseUnloadedModelSchema,
} from '../ResourceBaseModel';

export const LinkResourceBaseModelSchema = z.object({
  slug: RlnkSlugSchema,

  resourceType: z.literal(ResourceType.Link),
  kittedModelType: z.literal(KittedModelTypeEnum.ResourceLinkResource),

  linkUrl: z.string().url(),
  description: z.string().optional(),

  cmsSrcMessage: CMSLinkResourceUnloadedModelSchema.optional(),
});

export const LinkResourceLoadedModelSchema =
  ResourceBaseLoadedModelSchema.merge(LinkResourceBaseModelSchema);
export const LinkResourceUnloadedModelSchema =
  ResourceBaseUnloadedModelSchema.merge(LinkResourceBaseModelSchema);
export const LinkResourceSaveModelSchema =
  LinkResourceUnloadedModelSchema.merge(ResourceBaseSaveModelSchema)
    .merge(
      z.object({
        slug: LinkResourceUnloadedModelSchema.shape.slug.optional(),
        keyImageBlobId: z.string().optional().nullable(),
      })
    )
    .superRefine(notBoth('keyImageBlobId', 'keyImageImageAsset'));

export type LinkResourceLoadedModel = z.infer<
  typeof LinkResourceLoadedModelSchema
>;
export type LinkResourceUnloadedModel = z.infer<
  typeof LinkResourceUnloadedModelSchema
>;
export type LinkResourceSaveModel = z.infer<typeof LinkResourceSaveModelSchema>;

export type LinkResourceModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? LinkResourceLoadedModel : LinkResourceUnloadedModel;
