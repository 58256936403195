import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const LocationPin: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 18 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M7.47 5.85c.46-.22.98-.34 1.53-.34 1.98 0 3.58 1.6 3.58 3.58s-1.6 3.58-3.58 3.58a3.576 3.576 0 0 1-3.43-4.6m10.99 1.02C16.56 13.27 9 26.01 9 26.01S1.44 13.27 1.44 9.09 4.82 1.52 9 1.52s7.56 3.39 7.56 7.56Z" />
    </g>
  </WebsiteIcon>
);

export default memo(LocationPin);
