import { memo } from 'react';
import clsx from 'clsx';
import { Typography } from '@kitted/shared-components';
import { CircleCheck, TriangleExclaim } from '@kitted/website-icons';

import usePasswordStrengthIndicator from './hooks/usePasswordStrengthIndicator';
import useStyles from './styles';
import { PasswordStrengthIndicatorProps } from './types';

const PasswordStrengthIndicator = ({
  items,
  errors,
}: PasswordStrengthIndicatorProps) => {
  const { mappedItems } = usePasswordStrengthIndicator(items, errors);
  const styles = useStyles();

  if (!items.length) {
    return null;
  }

  return (
    <ul className={styles.list}>
      {mappedItems.map(({ label, inError }) => (
        <Typography
          key={label}
          className={styles.listItem}
          as="li"
          variant="inputHelper"
        >
          {inError ? (
            <TriangleExclaim
              className={clsx(styles.indicator, styles.indicatorError)}
              size="inherit"
            />
          ) : (
            <CircleCheck className={styles.indicator} size="inherit" />
          )}
          {label}
        </Typography>
      ))}
    </ul>
  );
};

export default memo(PasswordStrengthIndicator);
