import { SlugPrefixEnum } from '@kitted/kitted-models';
import {
  Chain,
  Hash,
  IdBadge,
  LandscapeCard,
  Newspaper,
  Nodes,
  Playbook,
  ToolboxPlus,
  VideoPlay,
} from '@kitted/website-icons';

export const slugPrefixIcons: Record<string, React.ElementType> = {
  [SlugPrefixEnum.Kit]: ToolboxPlus,
  [SlugPrefixEnum.Flow]: Nodes,
  [SlugPrefixEnum.Author]: IdBadge,
  [SlugPrefixEnum.Article]: Newspaper,
  [SlugPrefixEnum.Play]: Playbook,
  [SlugPrefixEnum.Subject]: Hash,
  [SlugPrefixEnum.Rimg]: LandscapeCard,
  [SlugPrefixEnum.Rlnk]: Chain,
  [SlugPrefixEnum.Rvid]: VideoPlay,
};
