import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0027: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 96 122"
		height={122}
		width={96}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M58.06 30.95c0 6.05-4.9 10.96-10.95 10.96S36.16 37 36.16 30.95 41.06 20 47.11 20s10.95 4.91 10.95 10.95M93.81 102.41 70.45 88.5l.9-24.64c.69-13.02-11.8-12.64-11.8-12.64h-23.1s-12.49-.38-11.8 12.64l.9 24.64L2.2 102.41" /><path vectorEffect="non-scaling-stroke" d="M25.3 120.23h-9.69c-3.78 0-6.88-3.09-6.88-6.88h0c0-3.79 1.85-5.67 6.37-8.44l20.07-11.17-.96-29.4M61.8 64.35l-.96 29.4 20.06 11.17c4.52 2.77 6.37 4.65 6.37 8.44h0c0 3.78-3.09 6.88-6.88 6.88h-41.8c-4.01 0-5.06-2.49-5.06-2.49-5.83-11.75 7.71-11.31 7.71-11.31h15.54" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="m22.35 19.67 5.24 3.03M32.04 7.54l4.26 5.54M48 9.76V1.77M59.24 13.08l4.29-5.57M66.79 22.7l5.3-3.06" />
    </g>
  </CardIcon>
);

export default memo(In0027);
