import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Info: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 12 22"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M1.9 7.42h4.14V20.4M.73 20.53h10.54M6.23 2.21c0 .34-.28.62-.62.62s-.62-.28-.62-.62.28-.62.62-.62.62.28.62.62" />
    </g>
  </WebsiteIcon>
);

export default memo(Info);
