import { memo } from 'react';

import { StoreSectionInitializerProps } from '../types';
import usePagesStoreInitializer from './hooks/usePagesStoreInitializer';

const PagesStoreInitializer = ({
  onSectionLoaded,
}: StoreSectionInitializerProps) => {
  usePagesStoreInitializer(onSectionLoaded);

  return null;
};

export default memo(PagesStoreInitializer);
