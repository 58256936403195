/* eslint-disable camelcase */
export const primaryA100 = 'rgb(149, 149, 162)'; // #9595A2
export const primaryA200 = 'rgb(179, 179, 188)'; // #B3B3BC
export const primaryA300 = 'rgb(211, 211, 216)'; // #D3D3D8
export const primary100 = 'rgb(120, 120, 136)'; // #787888
export const primary200 = 'rgb(90, 90, 109)'; // #5A5A6D
export const primary300 = 'rgb(73, 72, 92)'; // #494485C
export const primary400 = 'rgb(51, 50, 72)'; // #333248
export const primary500 = 'rgb(39, 37, 60)'; // #27253C
export const primary600 = 'rgb(32, 30, 54)'; // #201E36
export const primary600_30 = 'rgba(32, 30, 54, 0.3)';
export const primary700 = 'rgb(25, 24, 50)'; // #191832
export const primary800 = 'rgb(20, 19, 44)'; // #14132C
export const primary900 = 'rgb(4, 3, 29)'; // #04031D
export const primary900_05 = 'rgba(4, 3, 29, 0.05)';
export const primary900_10 = 'rgba(4, 3, 29, 0.1)';
export const primary900_20 = 'rgba(4, 3, 29, 0.2)';
export const primary900_30 = 'rgba(4, 3, 29, 0.3)';
export const primary900_40 = 'rgba(4, 3, 29, 0.4)';
export const primary900_50 = 'rgba(4, 3, 29, 0.5)';
export const primary900_70 = 'rgba(4, 3, 29, 0.7)';
export const primary900_90 = 'rgba(4, 3, 29, 0.9)';
export const primary1000 = 'rgb(1, 0, 14)'; // #01000E
export const primary1000_10 = 'rgba(1, 0, 14, 0.1)'; // #01000E
export const primary1000_90 = 'rgba(1, 0, 14, 0.9)'; // #01000E
