import { useCallback, useMemo } from 'react';

import useAuthModal from '../../../../../hooks/useAuthModal';
import { ModalContentProps } from '../../../types';
import { calculateStyles } from './logic';

const useCreateAndLoginButton = (
  onClose: ModalContentProps['onClose'],
  progressPercentage: number
) => {
  const { launchLoginModal } = useAuthModal();

  const onLogin = useCallback(() => {
    onClose();
    launchLoginModal();
  }, [onClose, launchLoginModal]);

  const style = useMemo(
    () => calculateStyles(progressPercentage),
    [progressPercentage]
  );

  return {
    onLogin,
    style,
  };
};

export default useCreateAndLoginButton;
