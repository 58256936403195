import { z } from 'zod';

import { WebsocketClientMessageType } from './enum';
import { WebsocketClientMessageSchema } from './WebsocketClientMessage';

export const WebsocketPingClientMessageSchema =
  WebsocketClientMessageSchema.merge(
    z.object({
      messageType: z.literal(WebsocketClientMessageType.Ping),
    })
  );

export type WebsocketPingClientMessage = z.infer<
  typeof WebsocketPingClientMessageSchema
>;
