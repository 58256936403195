import { useEffect, useRef } from 'react';

const useInputImageFocusing = (hasValue: boolean) => {
  const hasValueSyncRef = useRef<boolean>(hasValue);
  const clearButtonRef = useRef<HTMLButtonElement>(null);
  const addButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (hasValueSyncRef.current !== hasValue) {
      if (hasValue) {
        // we just set a value
        // so the add button has disappeared
        // so set focus to the clear button
        clearButtonRef.current?.focus();
      } else if (!hasValue) {
        // we just cleared the value
        // so the add button has appeared
        // so set focus to the add button
        addButtonRef.current?.focus();
      }
      hasValueSyncRef.current = hasValue;
    }
  }, [hasValue]);

  return {
    clearButtonRef,
    addButtonRef,
  };
};

export default useInputImageFocusing;
