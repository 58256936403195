import { z } from 'zod';
import {
  DisplaySlugSchema,
  ImageAssetModelSchema,
  KittedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '@kitted/kitted-models';
import { DateISOSchema } from '@kitted/platform-models';

export const CMSDisplayCardLoadedModelSchema = KittedModelBaseSchema.merge(
  z.object({
    slug: DisplaySlugSchema,
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      true
    ),
    live: z.boolean(),
    createdByAuthorId: z.string(),
    actionButtonTitle: z.string(),
    buttonUrl: z.string(),
    videoUrl: z.string().optional(),
    title: z.string(),
    description: z.string(),
    createdAt: DateISOSchema,
    updatedAt: DateISOSchema,
    autoPlay: z.boolean(),
  })
);

export const CMSDisplayCardUnloadedModelSchema =
  CMSDisplayCardLoadedModelSchema.merge(
    z.object({
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        false
      ),
    })
  );

export type CMSDisplayCardLoadedModel = z.infer<
  typeof CMSDisplayCardLoadedModelSchema
>;
export type CMSDisplayCardUnloadedModel = z.infer<
  typeof CMSDisplayCardUnloadedModelSchema
>;

export type CMSDisplayCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? CMSDisplayCardLoadedModel
    : CMSDisplayCardUnloadedModel;
