import { useCallback } from 'react';
import {
  AuthenticateUserChallenge,
  AuthenticateUserResponse,
} from 'quidproquo';
import {
  UserChallengeResponseRequest,
  UserChallengeResponseResponse,
} from '@kitted/auth-service-models';
import { KittedServiceEnum } from '@kitted/kitted-models';

import useAsyncRequest from '../../../../hooks/useAsyncRequest';
import useAuthModal from '../../../../hooks/useAuthModal';
import useAuthTokensApi from '../../../AuthTokensContext/hooks/useAuthTokensApi';
import useRequests from '../../../RequestsContext/hooks/useRequests';

const useUserChallenge = () => {
  const { launchChallengeModal } = useAuthModal();
  const { platformRequest } = useRequests();
  const { setTokens } = useAuthTokensApi();

  const handleAuthenticateUserResponse = useCallback(
    (email: string, response: AuthenticateUserResponse) => {
      const {
        challenge,
        session: responseSession,
        authenticationInfo,
      } = response;
      if (challenge === ('NONE' as AuthenticateUserChallenge.NONE)) {
        setTokens(authenticationInfo);
      } else {
        launchChallengeModal({
          email,
          challenge,
          session: responseSession,
        });
      }
    },
    [launchChallengeModal, setTokens]
  );

  // submit challenge function
  const [submitChallengeResponse] =
    useAsyncRequest<UserChallengeResponseResponse>(
      (challengeResponsePayload: UserChallengeResponseRequest) =>
        platformRequest(KittedServiceEnum.Auth, {
          method: 'POST',
          data: challengeResponsePayload,
          url: '/v1/challenge',
        }),
      (challengeResponseResponse, { email }: UserChallengeResponseRequest) => {
        handleAuthenticateUserResponse(email, challengeResponseResponse);
      }
    );

  return {
    handleAuthenticateUserResponse,
    submitChallengeResponse,
  };
};

export default useUserChallenge;
