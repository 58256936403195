import { TransitionStatus } from 'react-transition-group';
import { styling } from '@kitted/design-system';

import { TransitionDirection } from './types';

export const transitionDirectionStyles: {
  [transitionKey in TransitionDirection]: (translateX: string) => {
    [statusKey in TransitionStatus]: React.CSSProperties;
  };
} = {
  [TransitionDirection.FromTop]: (translateX) => ({
    entering: {
      transform: `translate(${translateX}, ${styling.utils.px(
        styling.sizes.context.topBar.height + styling.sizes.context.gutteringSmW
      )})`,
    },
    entered: {
      transform: `translate(${translateX}, ${styling.utils.px(
        styling.sizes.context.topBar.height + styling.sizes.context.gutteringSmW
      )})`,
    },
    exiting: { transform: `translate(${translateX}, -110%)` },
    exited: { transform: `translate(${translateX}, -110%)` },
    unmounted: { transform: `translate(${translateX}, -110%)` },
  }),
  [TransitionDirection.FromBottom]: (translateX) => ({
    entering: {
      transform: `translate(${translateX}, 0)`,
    },
    entered: {
      transform: `translate(${translateX}, 0)`,
    },
    exiting: { transform: `translate(${translateX}, 110%)` },
    exited: { transform: `translate(${translateX}, 110%)` },
    unmounted: { transform: `translate(${translateX}, 110%)` },
  }),
  [TransitionDirection.FromRight]: () => ({
    entering: {
      transform: `translate(${styling.utils.px(
        -1 * styling.sizes.context.gutteringSmW
      )}, 0)`,
    },
    entered: {
      transform: `translate(${styling.utils.px(
        -1 * styling.sizes.context.gutteringSmW
      )}, 0)`,
    },
    exiting: { transform: `translate(110%, 0)` },
    exited: { transform: `translate(110%, 0)` },
    unmounted: { transform: `translate(110%, 0)` },
  }),
};
