import { memo } from 'react';
import clsx from 'clsx';

import Typography from '../../Typography';
import useCardBody from './hooks/useCardBody';
import useStyles from './styles';
import { CardBodyProps } from './types';

const CardBody = ({
  children,
  className,
  isHover,
  isFeatured,
  useDefaultMaxLines,
  type,
}: CardBodyProps) => {
  const styles = useStyles();
  const { maxLines, variant } = useCardBody(
    type,
    isHover,
    isFeatured,
    useDefaultMaxLines
  );

  return (
    <Typography
      className={clsx(className, styles.body)}
      maxLines={maxLines}
      variant={variant}
    >
      {children}
    </Typography>
  );
};

export default memo(CardBody);
