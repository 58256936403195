import { z } from 'zod';

import {
  KittedSaveModelBaseSchema,
  KittedVersionedModelBaseSchema,
} from '../base';
import { ContentBlockTheme, ContentBlockType } from './Enum';

export const ContentBlockQuoteModelSchema =
  KittedVersionedModelBaseSchema.merge(
    z.object({
      theme: z.nativeEnum(ContentBlockTheme),
      content: z.string(),
      type: z.literal(ContentBlockType.Quote),
    })
  );

export const ContentBlockQuoteSaveModelSchema =
  ContentBlockQuoteModelSchema.merge(KittedSaveModelBaseSchema);

export type ContentBlockQuoteModel = z.infer<
  typeof ContentBlockQuoteModelSchema
>;
export type ContentBlockQuoteSaveModel = z.infer<
  typeof ContentBlockQuoteSaveModelSchema
>;
