import { useCallback, useEffect } from 'react';
import { FlowModel } from '@kitted/flow-service-models';
import { KittedServiceEnum } from '@kitted/kitted-models';
import { WebsocketModelUpdateServerMessage } from '@kitted/websocket-service-models';

import useFlowRequests from '../../../../../../hooks/flows/useFlowRequests';
import { useSubscribeToWebsocketModelUpdateServerMessage } from '../../../../../WebsocketContext';
import useStoreApi from '../../../../hooks/useStoreApi';
import useStoreSection from '../../../../hooks/useStoreSection';
import { StoreSectionInitializerProps } from '../../../types';

const useFlowStoreInitializer = (
  onSectionLoaded: StoreSectionInitializerProps['onSectionLoaded']
) => {
  const { getFlow } = useFlowRequests();
  const { createOrUpdateStoreSection } = useStoreApi();
  const { updateStoreSectionItem } = useStoreSection('Flows');

  const updateFlowsStoreSectionFetcher = useCallback(() => {
    createOrUpdateStoreSection('Flows', getFlow);
    onSectionLoaded('Flows');
  }, [createOrUpdateStoreSection, getFlow, onSectionLoaded]);

  useEffect(updateFlowsStoreSectionFetcher, [updateFlowsStoreSectionFetcher]);

  const handleWebsocketItemUpdated = useCallback(
    ({ model, service }: WebsocketModelUpdateServerMessage) => {
      if (model && service === KittedServiceEnum.Flow) {
        const asset = model as FlowModel<false>;
        updateStoreSectionItem(asset, asset.id);
      }
    },
    [updateStoreSectionItem]
  );

  useSubscribeToWebsocketModelUpdateServerMessage(handleWebsocketItemUpdated);
};

export default useFlowStoreInitializer;
