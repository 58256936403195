import { useMemo } from 'react';
import { ImageAssetModel } from '@kitted/kitted-models';

import useStoreItemSubscription from '../../../../contexts/StoreContext/hooks/useStoreItemSubscription';
import { StoreSectionItemStates } from '../../../../contexts/StoreContext/services/storeOfStores/types';
import useBlobStorage from '../../../../hooks/useBlobStorage';
import { AssetImageProps } from '../../types';
import { getExtension, getLoadedSrc } from './logic';

const useAssetImage = (
  src: AssetImageProps['src'],
  imageAsset: AssetImageProps['imageAsset'],
  size: AssetImageProps['size']
) => {
  const { constructBlobStorageContentUrl } = useBlobStorage();
  const { item: loadedAsset, state } =
    useStoreItemSubscription<ImageAssetModel>('Assets', imageAsset?.id || '');

  const loadedSrc = useMemo(
    () => getLoadedSrc(src, loadedAsset, size, constructBlobStorageContentUrl),
    [src, loadedAsset, size, constructBlobStorageContentUrl]
  );

  const extension = useMemo(
    () => getExtension(src, loadedAsset),
    [src, loadedAsset]
  );

  return {
    loadedSrc,
    extension,
    isLoading: state === StoreSectionItemStates.Loading,
  };
};

export default useAssetImage;
