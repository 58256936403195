import { replaceAtIndex } from '@kitted/shared-utils';

import { layoutSchema } from '../../../constants';

export const getLayoutSchema = (upperFirstCardType: string) => {
  const titleIndex = layoutSchema.fields.findIndex(
    (field) => field.key === 'title'
  );
  if (titleIndex === -1) {
    throw new Error('Title field not found in layout schema.');
  }
  return {
    ...layoutSchema,
    fields: replaceAtIndex(
      layoutSchema.fields,
      {
        ...layoutSchema.fields[titleIndex],
        label: `${upperFirstCardType} title`,
      },
      titleIndex
    ),
  };
};
