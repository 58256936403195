import { memo } from 'react';
import clsx from 'clsx';

import Typography from '../../Typography';
import usePlayerCaptions from './hooks/usePlayerCaptions';
import useStyles from './styles';
import { PlayerCaptionsProps } from './types';

const PlayerCaptions = ({
  active,
  playerRef,
  fireStateChangeEvent,
}: PlayerCaptionsProps) => {
  const { caption } = usePlayerCaptions(playerRef, fireStateChangeEvent);
  const styles = useStyles();

  return (
    <Typography
      as="div"
      className={clsx(styles.container, active && styles.active)}
    >
      {caption}
    </Typography>
  );
};

export default memo(PlayerCaptions);
