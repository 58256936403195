import { useMemo } from 'react';

import useAlertApi from '../../../../contexts/AlertContext/hooks/useAlertApi';
import useAlertData from '../../../../contexts/AlertContext/hooks/useAlertData';
import { getOnBackdropClick } from '../../logic';

const useAlertRoot = () => {
  const { closeAlert } = useAlertApi();
  const { topAlert, alerts } = useAlertData();

  const onBackdropClick = useMemo(
    () => getOnBackdropClick(topAlert, closeAlert),
    [closeAlert, topAlert]
  );

  return {
    alerts,
    topAlert,
    onBackdropClick,
  };
};

export default useAlertRoot;
