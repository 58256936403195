import { Heart, KittedBrandmark } from '@kitted/website-icons';

import { SubjectFilterOption } from './types';

export const defaultSubjectFilterOptions: SubjectFilterOption[] = [
  {
    icon: KittedBrandmark,
    title: 'All',
    value: 'All',
  },
  {
    icon: Heart,
    title: 'My Images',
    value: 'My',
  },
];
