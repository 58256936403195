import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      height: 32,
      width: 32,
      borderRadius: '100rem',
      position: 'relative',
      overflow: 'hidden',
      transition: styling.transitions.utils.generate(['box-shadow']),
    },
    containerSelected: {
      '& $avatar': {
        boxShadow: styling.shadows.utils.boxShadowToString({
          x: 0,
          y: 0,
          blur: 0,
          spread: 1,
          color: styling.colors.theme.primary900,
          inset: 'inset',
        }),
      },
    },
    avatar: {},
  },
  { name: 'accountMenuIcon' }
);
