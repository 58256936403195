import { useCallback, useEffect } from 'react';
import { ArticleModel } from '@kitted/article-service-models';
import { KittedServiceEnum } from '@kitted/kitted-models';
import { WebsocketModelUpdateServerMessage } from '@kitted/websocket-service-models';

import useArticleRequests from '../../../../../../hooks/articles/useArticleRequests';
import { useSubscribeToWebsocketModelUpdateServerMessage } from '../../../../../WebsocketContext';
import useStoreApi from '../../../../hooks/useStoreApi';
import useStoreSection from '../../../../hooks/useStoreSection';
import { StoreSectionInitializerProps } from '../../../types';

const useArticleStoreInitializer = (
  onSectionLoaded: StoreSectionInitializerProps['onSectionLoaded']
) => {
  const { getArticle } = useArticleRequests();
  const { createOrUpdateStoreSection } = useStoreApi();
  const { updateStoreSectionItem } = useStoreSection('Articles');

  const updateArticlesStoreSectionFetcher = useCallback(() => {
    createOrUpdateStoreSection('Articles', getArticle);
    onSectionLoaded('Articles');
  }, [createOrUpdateStoreSection, getArticle, onSectionLoaded]);

  useEffect(updateArticlesStoreSectionFetcher, [
    updateArticlesStoreSectionFetcher,
  ]);

  const handleWebsocketItemUpdated = useCallback(
    ({ model, service }: WebsocketModelUpdateServerMessage) => {
      if (model && service === KittedServiceEnum.Article) {
        const asset = model as ArticleModel<false>;
        updateStoreSectionItem(asset, asset.id);
      }
    },
    [updateStoreSectionItem]
  );

  useSubscribeToWebsocketModelUpdateServerMessage(handleWebsocketItemUpdated);
};

export default useArticleStoreInitializer;
