import { useMemo } from 'react';

import { useGlobalScriptVariables } from '../../contexts/GlobalScriptVariablesContext';
import { getIsLocalDevelopment } from './logic';

const useIsLocalDevelopment = () => {
  const { KITTED_ENVIRONMENT, KITTED_FEATURE } = useGlobalScriptVariables();

  const isLocalDevelopment = useMemo(
    () => getIsLocalDevelopment(KITTED_ENVIRONMENT, KITTED_FEATURE),
    [KITTED_ENVIRONMENT, KITTED_FEATURE]
  );

  return isLocalDevelopment;
};

export default useIsLocalDevelopment;
