import { ToolType } from '@kitted/card-service-models';

import { constructPublicRoute } from '../helpers';
import { toolTitles } from '../routeTitles';

const actionToolTitleCodeBasePath = '/action/:titleSlug/:code';
const frameToolTitleCodeBasePath = '/frame/:titleSlug/:code';
const insightToolTitleCodeBasePath = '/insight/:titleSlug/:code';
const snareToolTitleCodeBasePath = '/snare/:titleSlug/:code';

export const toolRedirectRoutes = {
  actionToolCode: constructPublicRoute(
    '/action/:code',
    toolTitles(ToolType.Action),
    actionToolTitleCodeBasePath
  ),
  frameToolCode: constructPublicRoute(
    '/frame/:code',
    toolTitles(ToolType.Frame),
    frameToolTitleCodeBasePath
  ),
  insightToolCode: constructPublicRoute(
    '/insight/:code',
    toolTitles(ToolType.Insight),
    insightToolTitleCodeBasePath
  ),
  snareToolCode: constructPublicRoute(
    '/snare/:code',
    toolTitles(ToolType.Snare),
    snareToolTitleCodeBasePath
  ),
} as const;

export const toolRoutes = {
  ...toolRedirectRoutes,
  actionToolTitleCode: constructPublicRoute(
    actionToolTitleCodeBasePath,
    toolTitles(ToolType.Action)
  ),
  frameToolTitleCode: constructPublicRoute(
    frameToolTitleCodeBasePath,
    toolTitles(ToolType.Frame)
  ),
  insightToolTitleCode: constructPublicRoute(
    insightToolTitleCodeBasePath,
    toolTitles(ToolType.Insight)
  ),
  snareToolTitleCode: constructPublicRoute(
    snareToolTitleCodeBasePath,
    toolTitles(ToolType.Snare)
  ),
} as const;
