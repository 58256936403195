import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0033: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 136 76"
		height={76}
		width={136}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M47.25 28.01c0 1.12.9 2.02 2.02 2.02s2.03-.9 2.03-2.02-.91-2.02-2.03-2.02-2.02.91-2.02 2.02M23.95 28.01c0 1.12.9 2.02 2.03 2.02s2.02-.9 2.02-2.02-.91-2.02-2.02-2.02-2.03.91-2.03 2.02" /><circle vectorEffect="non-scaling-stroke" cx="37.62" cy="38" r="35.67" /><path vectorEffect="non-scaling-stroke" d="M22.38 55.54c3.05-5.17 8.81-8.66 15.24-8.66s12.19 3.49 15.24 8.66" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M108 28.01c0 1.12.9 2.02 2.02 2.02s2.03-.9 2.03-2.02-.91-2.02-2.03-2.02-2.02.91-2.02 2.02M84.71 28.01c0 1.12.9 2.02 2.03 2.02s2.02-.9 2.02-2.02-.91-2.02-2.02-2.02-2.03.91-2.03 2.02M83.13 55.54c3.05-5.17 8.81-8.66 15.24-8.66s12.19 3.49 15.24 8.66" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M72.28 62.3c6.51 6.99 15.79 11.36 26.1 11.36 19.7 0 35.67-15.97 35.67-35.67S118.08 2.33 98.38 2.33c-10.31 0-19.59 4.38-26.1 11.36" />
    </g>
  </CardIcon>
);

export default memo(In0033);
