import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    container: {
      width: '100%',
    },
  },
  { name: 'catalogueGallery' }
);
