import { useMemo } from 'react';
import { getInteractionProps } from '@kitted/shared-components';

import ExternalLinkWithCapturedQueryParams from '../../../../../../../components/ExternalLinkWithCapturedQueryParams';
import PreloadingInternalLink from '../../../../../../../components/PreloadingInternalLink';
import useIsPathSelected from '../../../../../../../hooks/useIsPathSelected';
import { MobileMenuItemProps } from '../../types';
import { calculateInteractionProps } from './logic';

const useMobileMenuItem = ({
  isSelected,
  onClick,
  path,
  target,
  title,
  highlightSubroutes,
}: {
  isSelected: boolean;
  onClick: MobileMenuItemProps['onClick'];
  path: MobileMenuItemProps['path'];
  target: MobileMenuItemProps['target'];
  title: MobileMenuItemProps['title'];
  highlightSubroutes: MobileMenuItemProps['highlightSubroutes'];
}) => {
  const isPathSelected = useIsPathSelected(path, undefined, highlightSubroutes);
  const isItemSelected = isSelected || isPathSelected;

  const { as: ItemComponent, ...itemProps } = useMemo(() => {
    const interactionProps = calculateInteractionProps(
      isItemSelected,
      onClick,
      path,
      target
    );
    return getInteractionProps(
      interactionProps,
      {
        defaultComponent: 'div',
        internalLinkComponent: PreloadingInternalLink,
        externalLinkComponent: ExternalLinkWithCapturedQueryParams,
      },
      {
        title,
      },
      {
        title,
      }
    );
  }, [path, target, title, isItemSelected, onClick]);

  return {
    ItemComponent,
    itemProps,
    isItemSelected,
  };
};

export default useMobileMenuItem;
