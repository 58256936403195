import { z } from 'zod';
import {
  ImageAssetModelSchema,
  KittedModelBaseSchema,
  KittedSaveModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '@kitted/kitted-models';
import { DateISOSchema } from '@kitted/platform-models';

import {
  CMSContentBlockCarouselItemMediaSide,
  CMSContentBlockCarouselItemMediaType,
  CMSContentBlockTheme,
} from './Enum';

export const CMSContentBlockCarouselItemLoadedModelSchema =
  KittedModelBaseSchema.merge(
    z.object({
      theme: z.nativeEnum(CMSContentBlockTheme),
      label: z.string().optional(),
      content: z.string().optional(),
      buttonText: z.string().optional(),
      buttonLink: z.string().optional(),

      mediaType: z.nativeEnum(CMSContentBlockCarouselItemMediaType),
      mediaSide: z.nativeEnum(CMSContentBlockCarouselItemMediaSide),
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        true
      ),
      splayImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        true
      ).optional(),

      createdByAuthorId: z.string(),
      createdByUserId: z.string(),

      createdAt: DateISOSchema,
      updatedAt: DateISOSchema,
    })
  );

export const CMSContentBlockCarouselItemUnloadedModelSchema =
  CMSContentBlockCarouselItemLoadedModelSchema.merge(
    z.object({
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        false
      ),
      splayImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        false
      ).optional(),
    })
  );

export const CMSContentBlockCarouselItemSaveModelSchema =
  CMSContentBlockCarouselItemUnloadedModelSchema.merge(
    KittedSaveModelBaseSchema
  ).merge(
    z.object({
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        false
      ).optional(),
      splayImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        false
      ).optional(),
      keyImageBlobId: z.string().optional(),
      splayImageBlobId: z.string().optional(),
    })
  );

export type CMSContentBlockCarouselItemLoadedModel = z.infer<
  typeof CMSContentBlockCarouselItemLoadedModelSchema
>;
export type CMSContentBlockCarouselItemUnloadedModel = z.infer<
  typeof CMSContentBlockCarouselItemUnloadedModelSchema
>;
export type CMSContentBlockCarouselItemSaveModel = z.infer<
  typeof CMSContentBlockCarouselItemSaveModelSchema
>;

export type CMSContentBlockCarouselItemModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? CMSContentBlockCarouselItemLoadedModel
    : CMSContentBlockCarouselItemUnloadedModel;
