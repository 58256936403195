import { useCallback } from 'react';

import useUserApi from '../../../../../contexts/UserContext/hooks/useUserApi';
import { transformLoginFormDataToLoginPayload } from '../../logic';
import { AuthLoginModalProps, UserLoginFormData } from '../../types';

const useLoginModalContent = (onClose: AuthLoginModalProps['onClose']) => {
  const { loginUser } = useUserApi();

  const onSubmit = useCallback(
    async (formData: UserLoginFormData) => {
      const loginUserPayload = transformLoginFormDataToLoginPayload(formData);
      return loginUser(loginUserPayload);
    },
    [loginUser]
  );

  const onSubmitSuccess = useCallback(() => {
    onClose();
  }, [onClose]);

  return {
    onSubmit,
    onSubmitSuccess,
  };
};

export default useLoginModalContent;
