import { styling } from '@kitted/design-system';

import { ScaleContainerProps } from '../../types';

const parsedAspectRatio = (aspectRatio: ScaleContainerProps['aspectRatio']) => {
  const aspectRatioArr = aspectRatio
    .split('/')
    .map((value) => Number(value.trim()));
  return aspectRatioArr[0] / aspectRatioArr[1];
};

export const getComputedStyle = (
  aspectRatio: ScaleContainerProps['aspectRatio'],
  maxWidth: ScaleContainerProps['maxWidth'],
  containerWidth: number,
  containerHeight: number
) => {
  let desiredAspectRatio = parsedAspectRatio(aspectRatio);
  if (Number.isNaN(desiredAspectRatio)) {
    desiredAspectRatio = 1;
  }
  const containerAspectRatio = containerWidth / containerHeight;

  let scale: number;

  // Determine the constraining dimension
  if (containerAspectRatio > desiredAspectRatio) {
    // Container is wider than prop suggests, so scale based on height
    scale = (containerHeight / maxWidth) * desiredAspectRatio;
  } else {
    // Container is narrower, so scale based on width
    scale = containerWidth / maxWidth;
  }

  // Ensure scale does not exceed 1
  scale = Math.min(scale, 1);

  return {
    maxWidth: styling.utils.px(maxWidth),
    aspectRatio,
    scale: `${scale}`,
  };
};
