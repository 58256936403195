import { useCallback, useEffect } from 'react';
import { AccountModel } from '@kitted/account-service-models';
import { UserModel } from '@kitted/auth-service-models';
import { AuthorModel } from '@kitted/author-service-models';

import useAccount from '../../../../../../hooks/accounts/useAccount';
import useUser from '../../../../../../hooks/users/useUser';
import logger from '../../../../../../services/logger';
import { LoginState } from '../../../../types';
import useUserApi from '../../../useUserApi';
import useUserStoreItemSubscription from '../useUserStoreItemSubscription';

const useLoginLogoutStoreSubscriptions = () => {
  const { setLoginState } = useUserApi();
  useUserStoreItemSubscription<UserModel>('user');
  useUserStoreItemSubscription<AccountModel>('account');
  useUserStoreItemSubscription<AuthorModel>('author');
  const { user, isLoading: userIsLoading } = useUser();
  const { account, isLoading: accountIsLoading } = useAccount();

  const handleUserAndAccountReady = useCallback(() => {
    logger.log(
      '[useLoginLogoutStoreSubscriptions], user, userIsLoading, account, accountIsLoading',
      user,
      userIsLoading,
      account,
      accountIsLoading
    );
    if (user && account && !userIsLoading && !accountIsLoading) {
      setLoginState(LoginState.IN);
    }
  }, [user, userIsLoading, account, accountIsLoading, setLoginState]);

  useEffect(handleUserAndAccountReady, [handleUserAndAccountReady]);
};

export default useLoginLogoutStoreSubscriptions;
