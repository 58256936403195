import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  handleNonInteractiveChange,
  handleOnFocusChange,
  handleOnMouseEnterChange,
} from '../../components/Card/logic';
import { CardInteractionProps } from '../../components/Card/types';
import ExternalLink from '../../components/ExternalLink';
import InternalLink from '../../components/InternalLink';
import getInteractionProps from '../../services/getInteractionProps/logic';
import { InteractionProps } from '../../services/getInteractionProps/types';

const useBlockInteraction = ({
  nonInteractive,
  href,
  to,
  onClick,
  preventActive,
  target,
}: CardInteractionProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const onFocus = useCallback(
    (e: SyntheticEvent) =>
      handleOnFocusChange(e, setIsFocused, true, preventActive),
    [preventActive]
  );
  const onBlur = useCallback(
    (e: SyntheticEvent) =>
      handleOnFocusChange(e, setIsFocused, false, preventActive),
    [preventActive]
  );
  const onMouseEnter = useCallback(
    (e: SyntheticEvent) =>
      handleOnMouseEnterChange(e, setIsHovered, true, preventActive),
    [preventActive]
  );
  const onMouseLeave = useCallback(
    (e: SyntheticEvent) =>
      handleOnMouseEnterChange(e, setIsHovered, false, preventActive),
    [preventActive]
  );

  const interactionProps = useMemo(() => {
    const props = getInteractionProps(
      {
        nonInteractive,
        href,
        to,
        onClick,
      } as InteractionProps,
      {
        defaultComponent: 'div',
        internalLinkComponent: InternalLink,
        externalLinkComponent: ExternalLink,
      },
      {
        onFocus,
        onBlur,
        onMouseEnter,
        onMouseLeave,
        tabIndex: 0,
      }
    );
    return {
      ...props,
      target,
    };
  }, [
    nonInteractive,
    href,
    to,
    onClick,
    onFocus,
    onBlur,
    onMouseEnter,
    onMouseLeave,
    target,
  ]);

  useEffect(
    () =>
      handleNonInteractiveChange(setIsHovered, setIsFocused, nonInteractive),
    [nonInteractive, setIsFocused, setIsHovered]
  );

  return {
    interactionProps,
    isActive: isHovered || isFocused,
  };
};

export default useBlockInteraction;
