import { useEffect, useMemo } from 'react';

import {
  useEditDrawerSpaceApi,
  useEditDrawerSpaceData,
} from '../../../contexts/EditDrawerSpaceContext';
import { getEditDrawerSpaceDrawerItemsByGroup } from './logic';

const useEditDrawerSpaceDrawerContent = () => {
  const { setSelectedDrawerItemSlug } = useEditDrawerSpaceApi();
  const { items, selectedDrawerItemSlug } = useEditDrawerSpaceData();

  useEffect(() => {
    if (!selectedDrawerItemSlug) {
      setSelectedDrawerItemSlug(Object.keys(items)?.[0]);
    }
  }, [items, selectedDrawerItemSlug, setSelectedDrawerItemSlug]);

  const [topItems, bottomItems] = useMemo(
    () =>
      getEditDrawerSpaceDrawerItemsByGroup(items, setSelectedDrawerItemSlug),
    [items, setSelectedDrawerItemSlug]
  );

  return {
    topItems,
    bottomItems,
    selectedDrawerItemSlug,
  };
};

export default useEditDrawerSpaceDrawerContent;
