import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      height: styling.sizes.theme.spacing['3xs'],
      width: '100%',
      zIndex: styling.zIndex.drawerMainMenu - 1,
      background: styling.colors.theme.primary1000,
    },
    imageSkeleton: {
      ...styling.utils.absoluteFull(),
    },
  },
  { name: 'routeLoader' }
);
