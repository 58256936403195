import { memo } from 'react';
import clsx from 'clsx';
import { TextSkeleton } from '@kitted/shared-components';
import { NOOP } from '@kitted/shared-utils';
import { Plus } from '@kitted/website-icons';

import Popover from '../../../../../components/Popover';
import useIsLoggingIn from '../../../../../contexts/UserContext/hooks/useIsLoggingIn';
import MainMenuItem from '../../MainMenuItem';
import useCreateModelFlyout from './hooks/useCreateModelFlyout';
import useCreateModelRequests from './hooks/useCreateModelRequests';
import CreateModelPopoverContent from './CreateModelPopoverContent';
import useStyles from './styles';

const CreateModelFlyout = ({ redirectOnCreate = false }) => {
  const styles = useStyles();
  const { toggleOpen, isOpen, onClose, registerCreatedModel, onModalClose } =
    useCreateModelFlyout(redirectOnCreate);
  const { onClick } = useCreateModelRequests(
    onClose,
    registerCreatedModel,
    onModalClose
  );
  const { isLoggingIn } = useIsLoggingIn();

  if (isLoggingIn) {
    return (
      <MainMenuItem
        className={styles.button}
        title="Create"
        onClick={NOOP}
        icon={Plus}
        hasTooltip={false}
      >
        <TextSkeleton lines={1} variant="navLink" />
      </MainMenuItem>
    );
  }

  return (
    <Popover
      className={styles.popover}
      arrowClassName={styles.arrow}
      content={<CreateModelPopoverContent onClick={onClick} />}
      align="end"
      side="right"
      open={isOpen}
      onInteractOutside={onClose}
      sideOffset={10}
    >
      <MainMenuItem
        className={clsx(styles.button, isOpen && styles.openMenuItem)}
        title="Create"
        onClick={isOpen ? onClose : toggleOpen}
        icon={Plus}
        hasTooltip={false}
      >
        Create
      </MainMenuItem>
    </Popover>
  );
};

export default memo(CreateModelFlyout);
