import { z } from 'zod';

import { KittedModelBase, KittedModelBaseSchema } from './KittedModelBase';

export function makeKittedModelReferenceSchema<T extends z.ZodTypeAny>(
  schema: T,
  isLoaded: true
): T;

export function makeKittedModelReferenceSchema<T extends z.ZodTypeAny>(
  schema: T,
  isLoaded: false
): z.ZodObject<{ id: z.ZodString }>;

export function makeKittedModelReferenceSchema<T extends z.ZodTypeAny>(
  schema: T,
  isLoaded: boolean
): T | z.ZodObject<{ id: z.ZodString }> {
  if (isLoaded) {
    return schema;
  }

  return KittedModelBaseSchema.omit({
    kittedModelType: true,
  });
}

export type KittedModelReference<
  T extends Pick<KittedModelBase, 'id'> = Pick<KittedModelBase, 'id'>,
  IsLoaded extends boolean = false,
> = IsLoaded extends true ? T : Pick<T, 'id'>;
