import { memo } from 'react';
import { SlideInOut, TransitionDirection } from '@kitted/shared-components';

import { toastDurations } from '../../contexts/ToastContext/constants';
import AnimatedValue from '../AnimatedValue';
import Banner from '../Banner';
import ProgressCircle from '../ProgressCircle';
import useToast from './hooks/useToast';
import useToastIcon from './hooks/useToastIcon';
import useStyles from './styles';
import { ToastProps } from './types';

const Toast = ({
  theme,
  id,
  title,
  body,
  duration = toastDurations.error,
}: ToastProps) => {
  const { onClose, onMouseEnter, onMouseLeave, onExited, isHovered, isIn } =
    useToast(id, duration);
  const { timerTheme } = useToastIcon(theme);
  const styles = useStyles();

  return (
    <SlideInOut
      in={isIn}
      onExited={onExited}
      transitionDirection={TransitionDirection.FromRight}
    >
      <Banner
        className={styles.toast}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClose={onClose}
        title={title}
        body={body}
        theme={theme}
      >
        {duration > 0 && (
          <AnimatedValue
            from={0}
            to={100}
            duration={duration}
            isPlaying={!isHovered}
          >
            {(progress: number) => (
              <ProgressCircle
                className={styles.progress}
                progress={progress}
                size={56}
                theme={timerTheme}
                thickness={1}
              />
            )}
          </AnimatedValue>
        )}
      </Banner>
    </SlideInOut>
  );
};

export default memo(Toast);
