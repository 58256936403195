/* eslint-disable react/jsx-props-no-spreading */
import { useMemo } from 'react';
import Rollbar from 'rollbar';
import {
  ErrorBoundary as RollbarErrorBoundary,
  Provider,
  RollbarContext,
  useRollbar,
} from '@rollbar/react';

import ErrorComponent from '../../components/ErrorComponent';
import config from '../../constants/config';
import { isDevelopment } from '../../constants/environment';
import { useGlobalScriptVariables } from '../GlobalScriptVariablesContext';
import {
  ErrorBoundaryProps,
  ErrorCaptureProviderProps,
  ErrorSpaceProps,
  UseErrorCapture,
} from './types';

export const useErrorCapture = useRollbar as UseErrorCapture;

const getRollbarConfig = (environment: string): Rollbar.Configuration => ({
  enabled: !isDevelopment,
  accessToken: config.keys.rollbar,
  environment,
  // captureUncaught: true,
  // captureUnhandledRejections: true,
  payload: {
    context: 'root',
    client: {
      javascript: {
        source_map_enabled: true,
      },
    },
  },
});

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => (
  <RollbarErrorBoundary fallbackUI={ErrorComponent}>
    {children}
  </RollbarErrorBoundary>
);

export const ErrorSpace = ({ children, space, ...props }: ErrorSpaceProps) => (
  <RollbarContext {...props} context={space}>
    {children}
  </RollbarContext>
);

export const ErrorCaptureProvider = ({
  children,
}: ErrorCaptureProviderProps) => {
  const { KITTED_ENVIRONMENT } = useGlobalScriptVariables();
  const rollbarConfig = useMemo(
    () => getRollbarConfig(KITTED_ENVIRONMENT),
    [KITTED_ENVIRONMENT]
  );

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
  );
};
