import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0058: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 88 96"
		height={96}
		width={88}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><circle vectorEffect="non-scaling-stroke" cx="80.27" cy="69.58" r="6.47" /><path vectorEffect="non-scaling-stroke" d="M46.08 57.91h8.74l11.67 11.67h7.23M46.08 73.28h8.74l10.43 10.3" /><circle vectorEffect="non-scaling-stroke" cx="69.75" cy="88.2" r="6.47" /></g><g stroke={spot} ><circle vectorEffect="non-scaling-stroke" cx="80.27" cy="26.42" r="6.47" /><path vectorEffect="non-scaling-stroke" d="M46.08 38.09h8.74l11.67-11.67h7.31M46.08 22.72h8.74L65.14 12.4" /><circle vectorEffect="non-scaling-stroke" cx="69.75" cy="7.8" r="6.47" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M45.92 48h16.1" /><circle vectorEffect="non-scaling-stroke" cx="68.39" cy="48" r="6.47" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M13.33 15.53c-3.2 1.84-5.98 5.82-3.83 14.43 0 0-17.51 15.22-1.52 30.82 0 0-4.57 20.55 12.94 21.69" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M33.25 26.53c-6.83 0-12.37-5.54-12.37-12.37S26.42 1.8 33.25 1.8s12.37 5.54 12.37 12.37v68.8c0 4.14-2.17 7.61-5.88 9.83-5.76 3.46-13.24 1.6-16.7-4.16-2.85-4.73-2.09-10.62 1.46-14.49l6.92-7.52" /><path vectorEffect="non-scaling-stroke" d="M43.59 7.4c1.26 1.77 1.69 3.75.76 5.68-.18.38-.42.76-.7 1.12-2.07 2.63-5.9 3.08-8.53 1.02M25.24 38.16c-3.46-.85-5.59-4.37-4.74-7.83s4.37-5.59 7.83-4.74M45.32 42.72c-2.66-2.36-7.2-2.28-9.56.38 0 0-9.93 14.88-17.29 14.88M19.93 68.96l6.88 2.3" /><path vectorEffect="non-scaling-stroke" d="M15.55 45.18s6.47 3.04 11.04 7.99" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M36.86 80.62a4.69 4.69 0 0 1 6.34-1.9c1.62.87 2.48 2.59 2.41 4.32" />
    </g>
  </CardIcon>
);

export default memo(Fr0058);
