import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    backgroundImage: {
      position: 'absolute',
      '& img': {
        filter: 'blur(15px)',
      },
    },
    card: {
      zIndex: 2,
    },
  },
  { name: 'previewCard' }
);
