/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useCallback } from 'react';

/**
 * A custom hook that binds a logic function to an externally provided state setter function.
 * @param setState The state setter function from useState. This function is used to update the state.
 * @param logicFunction A function that takes a setState function and returns a new function. This new function
 * can be used to encapsulate complex logic that involves state updates.
 * @return A bound function that uses the provided setState function. This bound function will have the same
 * signature as the function returned by logicFunction, allowing it to be used directly in the component.
 */
export function useBindState<S, Args extends any[], R>(
  setState: Dispatch<SetStateAction<S>>,
  logicFunction: (setState: Dispatch<SetStateAction<S>>) => (...args: Args) => R
): (...args: Args) => R {
  const boundFunction = useCallback(
    (...args: Args) => {
      const functionToExecute = logicFunction(setState);
      return functionToExecute(...args);
    },
    [setState, logicFunction]
  );

  return boundFunction;
}

export default useBindState;
