import mimeTypes from 'mime-types';
import { ImageAssetModel } from '@kitted/kitted-models';

import { AssetImageProps } from '../../types';

export const getLoadedSrc = (
  src: AssetImageProps['src'],
  loadedAsset: ImageAssetModel | undefined,
  size: AssetImageProps['size'],
  constructBlobStorageContentUrl: (blobStorageId: string) => string
) => {
  if (src) return src;
  if (loadedAsset && size) {
    // if the original is smaller than the requested image
    // return original instead
    if (
      loadedAsset.sizes.src.dimensions[0] <
      loadedAsset.sizes[size].dimensions[0]
    ) {
      return constructBlobStorageContentUrl(loadedAsset.sizes.src.blobGuid);
    }
    return constructBlobStorageContentUrl(loadedAsset.sizes[size].blobGuid);
  }
  return undefined;
};

export const getExtension = (
  src: AssetImageProps['src'],
  loadedAsset: ImageAssetModel | undefined
): string => {
  if (src) {
    return src.split('.').at(-1) || 'png';
  }
  return mimeTypes.extension(loadedAsset?.contentType || '') || 'png';
};
