import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    container: {
      position: 'relative',
      width: '100%',
      aspectRatio: '350 / 80',
    },
    containerOnLight: {
      aspectRatio: '361 / 88',
    },
    kittedBrandmark: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '20.72%',
      height: 'auto',
    },
    kittedBrandmarkOnLight: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '22.72%',
      height: 'auto',
    },
    kitteditorBrand: {
      position: 'absolute',
      top: '50%',
      right: 0,
      width: '72.23%',
      height: 'auto',
      translate: '0 -58.1%',
    },
  },
  { name: 'kitteditorBrandingFull' }
);
