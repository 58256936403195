import { AlertProvider } from '../../contexts/AlertContext';
import { AnalyticsProvider } from '../../contexts/AnalyticsContext';
import { AuthTokensProvider } from '../../contexts/AuthTokensContext';
import { ErrorCaptureProvider } from '../../contexts/ErrorCaptureContext';
import { GlobalScriptVariablesProvider } from '../../contexts/GlobalScriptVariablesContext';
import { MediaQueryManagementProvider } from '../../contexts/MediaQueryManagementContext';
import { ModalProvider } from '../../contexts/ModalContext';
import { QueryParamCaptureProvider } from '../../contexts/QueryParamCaptureContext';
import { RequestsProvider } from '../../contexts/RequestsContext';
import { StoreProvider } from '../../contexts/StoreContext';
import { TimerProvider } from '../../contexts/TimerContext';
import { ToastProvider } from '../../contexts/ToastContext';
import { TooltipProvider } from '../../contexts/TooltipContext';
import { WebsocketProvider } from '../../contexts/WebsocketContext';

export const providers = [
  // top most provider
  QueryParamCaptureProvider,
  GlobalScriptVariablesProvider,
  ErrorCaptureProvider,
  MediaQueryManagementProvider,
  TimerProvider,
  ToastProvider,
  AlertProvider,
  ModalProvider,
  TooltipProvider,
  AuthTokensProvider,
  AnalyticsProvider,
  RequestsProvider,
  WebsocketProvider,
  StoreProvider,
  // inner most provider
];
