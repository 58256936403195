import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0048: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 108 62"
		height={62}
		width={108}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M91.35 23.25c0 .68-.54 1.23-1.23 1.23s-1.23-.55-1.23-1.23a1.23 1.23 0 1 1 2.46 0" /><circle vectorEffect="non-scaling-stroke" cx="73.33" cy="23.25" r="1.23" /><circle vectorEffect="non-scaling-stroke" cx="81.73" cy="31.65" r="1.23" /><path vectorEffect="non-scaling-stroke" d="M81.73 16.09c-.68 0-1.23-.55-1.23-1.23s.55-1.22 1.23-1.22 1.23.55 1.23 1.22-.55 1.23-1.23 1.23" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M14.83 24.26h19.53M24.6 14.49v19.53" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M95.98 4.55C94.9 2.35 90.77.91 84.4.91c-3.67 0-6.82.44-6.85.45-7.14.4-9.5 7.15-9.52 7.22l-.49 1.51H40.49L40 8.58c-.02-.07-2.38-6.82-9.52-7.22-.03 0-3.18-.45-6.86-.45-6.38 0-10.51 1.44-11.59 3.64C11.42 5.72.32 23.4 1.36 54.59c.02.27.64 6.5 6.28 6.5 1.37 0 2.95-.34 4.71-1 2.75-.95 15.17-11.32 15.83-11.95.67-.64 9.31-7.9 14.25-7.9h23.14c4.93 0 13.58 7.26 14.24 7.9.67.63 13.08 11 15.83 11.95 1.76.66 3.35 1 4.71 1 5.64 0 6.26-6.23 6.28-6.5C107.68 23.4 96.58 5.72 95.97 4.54Z" />
    </g>
  </CardIcon>
);

export default memo(Fr0048);
