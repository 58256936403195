import { useContext } from 'react';

import { CardsOrderingDataContext } from '../../CardsOrderingDataContext';

const useCardsOrderingData = () => {
  const cardsOrderingService = useContext(CardsOrderingDataContext);

  return cardsOrderingService;
};

export default useCardsOrderingData;
