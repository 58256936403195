import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Chain: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 22 24"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M13.03 13.37c-1.16.85-2.79.77-3.82-.26l-.73-.73a2.957 2.957 0 0 1 0-4.19l5.78-5.78a2.957 2.957 0 0 1 4.19 0l.7.7a2.957 2.957 0 0 1 0 4.19l-3.89 3.89" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M8.97 10.63c1.16-.85 2.79-.77 3.82.26l.73.73a2.957 2.957 0 0 1 0 4.19l-5.78 5.78a2.957 2.957 0 0 1-4.19 0l-.7-.7a2.957 2.957 0 0 1 0-4.19l3.89-3.89" />
    </g>
  </WebsiteIcon>
);

export default memo(Chain);
