export enum ContentBlockType {
  Text = 'text',
  Image = 'image',
  Carousel = 'carousel',
  CarouselItem = 'carousel-item',
  Video = 'video',
  CardGrid = 'card-grid',
  Quote = 'quote',
}

export type ContentBlockTypeValues = `${ContentBlockType}`;
