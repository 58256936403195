import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0005: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 110 80"
		height={80}
		width={110}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M87.44 1.01v57.53M65.81 1.01v57.53M44.19 1.01v57.53M22.56 1.01v57.53M55 58.54v20.45M.93 58.54h108.14M22.56 29.36h21.63M65.81 29.36h21.63" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M.93 1.01h108.14v77.98H.93z" />
    </g>
  </CardIcon>
);

export default memo(Ac0005);
