import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0042: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 110 62"
		height={62}
		width={110}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><g stroke={color}><path vectorEffect="non-scaling-stroke" d="M47.45 12.54 19.08 60.29M62.54 12.54l28.38 47.75" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="m59.31 57.99-8.62-8.62M50.69 57.99l8.62-8.62M55 38.42v-6.86M55 24.38v-2.93M55 15.03v-2" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M1.02 2.57s15-5.74 23.29 12.39M108.98 2.57s-15-5.74-23.29 12.39" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="m2.36 60.29 37.19-47.75S30.2.89 21.34 7.98M88.66 7.98c-8.87-7.09-18.22 4.56-18.22 4.56h0l37.19 47.75" />
    </g>
  </CardIcon>
);

export default memo(Sn0042);
