import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    container: {
      margin: [-6, 0, 16],
    },
  },
  { name: 'linkScrapeButton' }
);
