import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    modal: {
      cursor: 'default',
      width: '100%',
      maxWidth: 494,
      margin: 'auto',
      borderRadius: styling.sizes.context.card.radius,
      border: [1, 'solid', styling.colors.theme.primary400],
    },
    topBar: {
      backgroundColor: styling.colors.theme.primary900,
      color: styling.colors.context.color,
      padding: styling.sizes.theme.spacing.md,
      borderTopLeftRadius: styling.sizes.context.card.radius,
      borderTopRightRadius: styling.sizes.context.card.radius,
      position: 'relative',
      textAlign: 'center',
    },
    title: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    closeButton: {
      position: 'absolute',
      right: 0,
      bottom: 'calc(100% + 22px)',
    },
    body: {
      backgroundColor: styling.colors.theme.pureWhite,
      color: styling.colors.context.inverted.color,
      borderBottomLeftRadius: styling.sizes.context.card.radius,
      borderBottomRightRadius: styling.sizes.context.card.radius,
      padding: styling.sizes.theme.spacing.md,
    },
  },
  { name: 'authenticationModalContent' }
);
