import { FieldValue } from '../../../contexts/FormContext';
import { ValidationFn } from './types';

export type ValidationItemHasMinLength = {
  name: 'hasMinLength';
  minLength: number;
  validator: ValidationFn;
};

export const hasMinLength = (
  minLength: ValidationItemHasMinLength['minLength']
): ValidationItemHasMinLength => ({
  name: 'hasMinLength',
  minLength,
  validator: (value: FieldValue = '') => {
    if (typeof value !== 'string') {
      return `Invalid value type: ${typeof value}`;
    }
    const isValid = value?.length >= minLength;

    if (isValid) return undefined;
    return `This cannot have fewer than ${minLength} characters`;
  },
});
