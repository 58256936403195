import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { routesFlat } from '@kitted/website-constants';

import { getIsMatchingRouteAuthenticated } from '../../logic';

const useIsAuthenticatedRoute = () => {
  const { pathname } = useLocation();
  const params = useParams();
  const isAuthenticatedRoute = useMemo(
    () => getIsMatchingRouteAuthenticated(routesFlat, pathname, params),
    [pathname, params]
  );

  return isAuthenticatedRoute;
};

export default useIsAuthenticatedRoute;
