import { useMemo } from 'react';

import { getMappedItems } from '../../logic';
import { PasswordStrengthIndicatorProps } from '../../types';

const usePasswordStrengthIndicator = (
  items: PasswordStrengthIndicatorProps['items'],
  errors: PasswordStrengthIndicatorProps['errors']
) => {
  const mappedItems = useMemo(
    () => getMappedItems(items, errors),
    [items, errors]
  );

  return {
    mappedItems,
  };
};

export default usePasswordStrengthIndicator;
