import { useCallback, useMemo } from 'react';

import { AddHyperlinkModalProps, HyperlinkFormData } from '../../types';

const useAddHyperlinkModalContent = (
  initialUrl: AddHyperlinkModalProps['url'],
  onSave: AddHyperlinkModalProps['onSave'],
  onClose: AddHyperlinkModalProps['onClose']
) => {
  const initialValues = useMemo(
    () => ({
      url: initialUrl,
    }),
    [initialUrl]
  );

  const onSubmit = useCallback(
    async (formData: HyperlinkFormData) => {
      const { url } = formData;
      return onSave(url);
    },
    [onSave]
  );

  const onSubmitSuccess = useCallback(() => {
    onClose();
  }, [onClose]);

  return {
    initialValues,
    onSubmit,
    onSubmitSuccess,
  };
};

export default useAddHyperlinkModalContent;
