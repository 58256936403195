import { z } from 'zod';

import {
  ImageResourceLoadedModelSchema,
  ImageResourceUnloadedModelSchema,
} from './Image';
import {
  LinkResourceLoadedModelSchema,
  LinkResourceUnloadedModelSchema,
} from './Link';
import {
  VideoResourceLoadedModelSchema,
  VideoResourceUnloadedModelSchema,
} from './Video';

export const AnyResourceLoadedModelSchema = z.union([
  ImageResourceLoadedModelSchema,
  LinkResourceLoadedModelSchema,
  VideoResourceLoadedModelSchema,
]);

export const AnyResourceUnloadedModelSchema = z.union([
  ImageResourceUnloadedModelSchema,
  LinkResourceUnloadedModelSchema,
  VideoResourceUnloadedModelSchema,
]);

export type AnyResourceLoadedModel = z.infer<
  typeof AnyResourceLoadedModelSchema
>;
export type AnyResourceUnloadedModel = z.infer<
  typeof AnyResourceUnloadedModelSchema
>;

export type AnyResourceModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? AnyResourceLoadedModel : AnyResourceUnloadedModel;
