import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0046: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 110 88"
		height={88}
		width={110}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="m76.54 86.22-19.6-19.64c-1.89-1.91-1.87-5.01.04-6.89l.53-.53c1.9-1.89 5.01-1.87 6.89.04l8.9 8.89V32.52c0-2.68 2.19-4.87 4.87-4.87h.41c2.68 0 4.87 2.19 4.87 4.87v18.3l18.68 3.09s6.09 1.22 6.09 9.2l-1.78 22.33" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M51.33 70.61H6.65c-2.68 0-4.87-2.19-4.87-4.87V6.65c0-2.68 2.19-4.87 4.87-4.87h84.42c2.68 0 4.87 2.19 4.87 4.87V45.8M11.63 32.6v7.19" />
    </g>
  </CardIcon>
);

export default memo(Fr0046);
