import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0012: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 58 104"
		height={104}
		width={58}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><path vectorEffect="non-scaling-stroke" stroke={color} d="M52.66 25.2H32.11V3.82h11.61" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="m39.46 11.88 5.14 5.71 12.39-16" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M52.66 63.81H32.11V42.43h11.61" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="m39.46 50.49 5.14 5.71 12.39-16" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M52.66 102.41H32.11V81.04h11.61" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="m39.46 89.1 5.14 5.71L56.99 78.8" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M6.46 81.03v21.39M1.01 81.03h10.91M1.01 102.42h10.91M1.02 3.82v21.39M1.01 19.37 15.94 4.06M16.74 25.21 7.1 13.22M1.01 56.49h6.4c4.53 0 8.36-2.37 8.36-6.96v-.06c0-4.2-3.16-6.75-7.97-6.75l-6.79.05v21.39" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0012);
