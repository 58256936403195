import { memo } from 'react';

import useContextualModalTitle from '../../../contexts/ModalContext/hooks/useContextualModalTitle';
import Button from '../../Button';
import EditDrawerSpaceFooter from '../../EditDrawerSpace/EditDrawerSpaceFooter';
import { ResourceModelCreateModalSpaceProps } from '../types';
import useResourceModelCreateFormFooter from './hooks/useResourceModelCreateFormFooter';

const ResourceModelCreateFormFooter = ({
  onSaveChanges,
  onClose,
  isCloning,
  title,
}: ResourceModelCreateModalSpaceProps) => {
  const contextualTitle = useContextualModalTitle(title);
  const { onSubmit, isDisabled } = useResourceModelCreateFormFooter(
    isCloning,
    onSaveChanges,
    onClose
  );

  return (
    <EditDrawerSpaceFooter title={contextualTitle}>
      <Button onClick={onClose} type="button" theme="primary" fullWidth>
        Cancel
      </Button>
      <Button
        onClick={onSubmit}
        isDisabled={isDisabled}
        variant="solid"
        theme="primary"
        type="button"
        fullWidth
      >
        {isCloning ? 'Clone' : 'Create'} Resource
      </Button>
    </EditDrawerSpaceFooter>
  );
};

export default memo(ResourceModelCreateFormFooter);
