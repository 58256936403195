import { Dispatch, SetStateAction, SyntheticEvent } from 'react';

import { cardTypeToComponentMap } from './constants';
import { CardComponentInteractionProps, CardProps } from './types';

export const handleOnFocusChange = (
  e: SyntheticEvent,
  setFocus: (isFocused: boolean) => void,
  value: boolean,
  preventActive = false
) => {
  if (preventActive) return;
  setFocus(value);
};

export const handleOnMouseEnterChange = (
  e: SyntheticEvent,
  setMouseEnter: (isHovered: boolean) => void,
  value: boolean,
  preventActive = false
) => {
  if (preventActive) return;
  setMouseEnter(value);
};

export const handleNonInteractiveChange = (
  setIsHovered: (isHovered: boolean) => void,
  setIsFocused: (isFocused: boolean) => void,
  nonInteractive = false
) => {
  if (nonInteractive) {
    setIsHovered(false);
    setIsFocused(false);
  }
};

export const extractInteractionProps = ({
  onFocus,
  onBlur,
  onMouseEnter,
  onMouseLeave,
  tabIndex,
  onClick,
  href,
  to,
  target,
}: {
  [key: string]: unknown;
}): CardComponentInteractionProps => ({
  onFocus: onFocus as CardComponentInteractionProps['onFocus'],
  onBlur: onBlur as CardComponentInteractionProps['onBlur'],
  onMouseEnter: onMouseEnter as CardComponentInteractionProps['onMouseEnter'],
  onMouseLeave: onMouseLeave as CardComponentInteractionProps['onMouseLeave'],
  tabIndex: tabIndex as CardComponentInteractionProps['tabIndex'],
  onClick: onClick as CardComponentInteractionProps['onClick'],
  href: (href as CardComponentInteractionProps['href'])?.toLowerCase(),
  to: to as CardComponentInteractionProps['to'],
  target: target as CardComponentInteractionProps['target'],
});

export const getCardComponentByType = (
  type: CardProps['type']
): React.ElementType | null => {
  if (cardTypeToComponentMap[type]) {
    return cardTypeToComponentMap[type];
  }
  return null;
};

export const handleOnMenuOpenToggle = (
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>
) => {
  setIsMenuOpen((currentIsOpen) => !currentIsOpen);
};
