import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0037: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 64 100"
		height={100}
		width={64}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M62.23 56.4v.94c0 15.03-12.29 27.32-27.32 27.32h-5.82c-15.03 0-27.32-12.29-27.32-27.32v-.94" /><rect vectorEffect="non-scaling-stroke" width="36.5" height="71.68" x="13.75" y="1.2" stroke={spot} rx="16.81" ry="16.81" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M39.12 19.83h10.09M39.12 30.54h10.09M39.12 41.47h10.09M14.79 19.83H26.3M14.79 30.54H26.3M14.79 41.47H26.3" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M18.4 99.18h27.2M32 84.69v14.33" />
    </g>
  </CardIcon>
);

export default memo(Fr0037);
