import {
  AnyCardModel,
  AnyResourceCardModel,
  AnyToolCardModel,
  ArticleCardModel,
  AuthorCardModel,
  DeckCardModel,
  DisplayCardModel,
  FlowCardModel,
  ImageResourceCardModel,
  KitCardModel,
  LinkResourceCardModel,
  PlayCardModel,
  SubjectCardModel,
  VideoResourceCardModel,
} from '@kitted/card-service-models';
import { CardModelId, CardType } from '@kitted/kitted-models';
import { CardProps } from '@kitted/shared-components';
import {
  EMPTY_ARR,
  extractCodeFromSlug,
  getFullName,
  NOOP,
  slugize,
} from '@kitted/shared-utils';
import { interpolateRoutePath, routes } from '@kitted/website-constants';

import { LightboxOnClickProps } from './types';

const getResourceSubTypeProps = (
  resourceCard: AnyResourceCardModel,
  getLightboxOnClick: (lightboxProps: LightboxOnClickProps) => () => void
) => {
  switch (resourceCard.resourceType) {
    case 'link': {
      const { linkUrl, description } = resourceCard as LinkResourceCardModel;
      return {
        body: description,
        href: linkUrl,
        cardId: resourceCard.id,
      };
    }
    case 'video': {
      const { title, videoUrl, description } =
        resourceCard as VideoResourceCardModel;
      return {
        body: description,
        // href: videoUrl,
        onClick: getLightboxOnClick({
          title,
          meta: {
            videoSrc: videoUrl,
            cardId: resourceCard.id,
          },
        }),
      };
    }
    case 'image': {
      const {
        showTitle,
        title,
        keyImageImageAsset: imageAsset,
      } = resourceCard as ImageResourceCardModel;
      return {
        // href: image.srcUrl
        showTitle,
        onClick: getLightboxOnClick({
          title,
          meta: {
            imageAsset,
            cardId: resourceCard.id,
          },
        }),
      };
    }
    default:
      throw new Error(
        `Unknown resource card type: ${resourceCard.resourceType}`
      );
  }
};

const getResourceProps = (
  resourceCard: AnyResourceCardModel,
  getLightboxOnClick: (lightboxProps: LightboxOnClickProps) => () => void
): CardProps => {
  const {
    cardType,
    resourceType,
    title,
    keyImageImageAsset: imageAsset,
    label,
    slug,
    versionStatus,
    publishedAt,
    createdByAuthorId,
  } = resourceCard;
  return {
    type: cardType,
    subType: resourceType,
    title,
    imageAsset,
    label,
    slug,
    versionStatus,
    publishedAt,
    authorId: createdByAuthorId,
    ...getResourceSubTypeProps(resourceCard, getLightboxOnClick),
  };
};

const getDisplayProps = ({
  cardType,
  description,
  title,
  keyImageImageAsset: imageAsset,
  slug,
  buttonUrl,
  videoUrl,
  actionButtonTitle,
  autoPlay,
  versionStatus,
  publishedAt,
  createdByAuthorId,
}: DisplayCardModel): CardProps => {
  const baseDisplayCardProps = {
    type: cardType,
    title,
    body: description,
    slug,
    imageAsset,
    videoUrl,
    actionButtonTitle,
    autoPlay,
    versionStatus,
    publishedAt,
    authorId: createdByAuthorId,
  };
  if (buttonUrl) {
    return {
      ...baseDisplayCardProps,
      href: buttonUrl,
    };
  }
  return {
    ...baseDisplayCardProps,
    nonInteractive: true,
  };
};

const getToolProps = (
  {
    cardType,
    toolType,
    keyImageImageAsset: imageAsset,
    title,
    description,
    slug,
    versionStatus,
    publishedAt,
    createdByAuthorId,
  }: AnyToolCardModel,
  pathGenerator: typeof interpolateRoutePath
): CardProps => ({
  type: cardType,
  subType: toolType,
  title,
  body: description,
  imageAsset,
  slug,
  versionStatus,
  publishedAt,
  authorId: createdByAuthorId,
  to: pathGenerator(routes[`${toolType}ToolTitleCode`], {
    toolType,
    titleSlug: slugize(title),
    code: extractCodeFromSlug(slug),
  }),
});

const getKitProps = (
  {
    cardType,
    description,
    title,
    keyImageImageAsset: imageAsset,
    slug,
    versionStatus,
    publishedAt,
    createdByAuthorId,
  }: KitCardModel,
  pathGenerator: typeof interpolateRoutePath
): CardProps => ({
  type: cardType,
  title,
  body: description,
  slug,
  imageAsset,
  versionStatus,
  publishedAt,
  authorId: createdByAuthorId,
  to: pathGenerator(routes.kitTitleCode, {
    code: extractCodeFromSlug(slug),
    titleSlug: slugize(title),
  }),
});

const getFlowProps = (
  {
    cardType,
    description,
    title,
    keyImageImageAsset: imageAsset,
    slug,
    versionStatus,
    publishedAt,
    createdByAuthorId,
  }: FlowCardModel,
  pathGenerator: typeof interpolateRoutePath
): CardProps => ({
  type: cardType,
  title,
  body: description,
  slug,
  imageAsset,
  versionStatus,
  publishedAt,
  authorId: createdByAuthorId,
  to: pathGenerator(routes.flowAuthorTitleCode, {
    code: extractCodeFromSlug(slug),
    titleSlug: slugize(title),
    authorSlug: 'loading',
  }),
});

const getSubjectProps = (
  {
    cardType,
    description,
    title,
    keyImageImageAsset: imageAsset,
    slug,
    versionStatus,
    publishedAt,
    createdByAuthorId,
  }: SubjectCardModel,
  pathGenerator: typeof interpolateRoutePath
): CardProps => ({
  type: cardType,
  title,
  body: description,
  slug,
  imageAsset,
  versionStatus,
  publishedAt,
  authorId: createdByAuthorId,
  to: pathGenerator(routes.subjectTitleCode, {
    code: extractCodeFromSlug(slug),
    titleSlug: slugize(title),
  }),
});

const getArticleProps = (
  {
    cardType,
    description,
    title,
    keyImageImageAsset: imageAsset,
    slug,
    versionStatus,
    publishedAt,
    createdByAuthorId,
  }: ArticleCardModel,
  pathGenerator: typeof interpolateRoutePath
): CardProps => ({
  type: cardType,
  title,
  body: description,
  slug,
  imageAsset,
  versionStatus,
  publishedAt,
  authorId: createdByAuthorId,
  to: pathGenerator(routes.articleAuthorTitleCode, {
    code: extractCodeFromSlug(slug),
    titleSlug: slugize(title),
    authorSlug: 'loading',
  }),
});

const getPlayProps = (
  {
    cardType,
    description,
    title,
    keyImageImageAsset: imageAsset,
    slug,
    versionStatus,
    publishedAt,
    createdByAuthorId,
  }: PlayCardModel,
  pathGenerator: typeof interpolateRoutePath
): CardProps => ({
  type: cardType,
  title,
  body: description,
  slug,
  imageAsset,
  versionStatus,
  publishedAt,
  authorId: createdByAuthorId,
  to: pathGenerator(routes.playAuthorTitleCode, {
    code: extractCodeFromSlug(slug),
    titleSlug: slugize(title),
    authorSlug: 'loading',
  }),
});

const getAuthorProps = (
  {
    cardType,
    firstName,
    lastName,
    keyImageImageAsset: imageAsset,
    description,
    slug,
    versionStatus,
    publishedAt,
    createdByAuthorId,
  }: AuthorCardModel,
  pathGenerator: typeof interpolateRoutePath
): CardProps => ({
  type: cardType,
  slug,
  title: getFullName(firstName, lastName),
  body: description,
  imageAsset,
  versionStatus,
  publishedAt,
  authorId: createdByAuthorId,
  to: pathGenerator(routes.authorTitleCode, {
    code: extractCodeFromSlug(slug),
    titleSlug: slugize(getFullName(firstName, lastName)),
  }),
});

const getDeckProps = ({
  cardType,
  title,
  keyImageImageAsset: imageAsset,
  slug,
}: DeckCardModel): CardProps => ({
  type: cardType,
  nonInteractive: true,
  title,
  slug,
  imageAsset,
});

export const getCardProps = (
  card: AnyCardModel,
  pathGenerator: typeof interpolateRoutePath = NOOP,
  getLightboxOnClick: (
    lightboxProps: LightboxOnClickProps
  ) => () => void = () => NOOP
): CardProps => {
  const { cardType } = card;
  switch (cardType) {
    case CardType.Resource:
      return getResourceProps(card as AnyResourceCardModel, getLightboxOnClick);
    case CardType.Tool:
      return getToolProps(card as AnyToolCardModel, pathGenerator);
    case CardType.Kit:
      return getKitProps(card as KitCardModel, pathGenerator);
    case CardType.Flow:
      return getFlowProps(card as FlowCardModel, pathGenerator);
    case CardType.Subject:
      return getSubjectProps(card as SubjectCardModel, pathGenerator);
    case CardType.Article:
      return getArticleProps(card as ArticleCardModel, pathGenerator);
    case CardType.Play:
      return getPlayProps(card as PlayCardModel, pathGenerator);
    case CardType.Author:
      return getAuthorProps(card as AuthorCardModel, pathGenerator);
    case CardType.Deck:
      return getDeckProps(card as DeckCardModel);
    case CardType.Display:
      return getDisplayProps(card as DisplayCardModel);
    default:
      throw new Error(`Card Type not found: ${cardType}`);
  }
};

export const getAvailableCardIds = (
  cardIds: CardModelId[] | undefined,
  cardIdsForView: CardModelId[]
) => {
  if (!cardIds) return EMPTY_ARR;
  return cardIds.filter((cardId) => cardIdsForView.includes(cardId));
};
