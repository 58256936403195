import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const HandPresent: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 32 30"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M9.61 11.35v4.66h12.96v-4.66m-4.98-2.06h5.97V6.22H8.6v3.07h5.97m.01-2.98v9.67m3.01-9.67v9.67m4.71 4.51 5.09-2.51c.98-.51 2.14-.3 2.62.55.47.85.06 1.97-.93 2.48l-10.57 5.45-.56.13-9.86.26s-1.27.34-2.79 2m-3.5-4.98 4.47-3.96c2.19-1.42 4.78-.64 4.78-.64 1.25.32 3.58.39 3.58.39h3.87c1.15 0 2.1.79 2.1 1.76s-.94 1.76-2.1 1.76h-5.35m1.06-21.53c.65.66 1.79 4.19 1.79 4.19s-3.62-1.26-4.27-1.92-.63-1.7.06-2.33c.68-.63 1.77-.6 2.42.05Zm3.75 0c-.65.66-1.79 4.19-1.79 4.19s3.62-1.26 4.27-1.92.63-1.7-.06-2.33-1.77-.6-2.42.05Z" />
    </g>
  </WebsiteIcon>
);

export default memo(HandPresent);
