import { FieldValue } from '../../../contexts/FormContext';
import { ValidationFn } from './types';

export type ValidationItemMustMatchField = {
  name: 'mustMatchField';
  compareValueKey: string;
  pluralEntityName: string;
  validator: ValidationFn;
};

export const mustMatchField = (
  compareValueKey: ValidationItemMustMatchField['compareValueKey'],
  pluralEntityName: ValidationItemMustMatchField['pluralEntityName']
): ValidationItemMustMatchField => ({
  name: 'mustMatchField',
  compareValueKey,
  pluralEntityName,
  validator: (
    value: FieldValue,
    _fieldType,
    getCompareValue?: (key: string) => FieldValue
  ) => {
    const compareValue = getCompareValue?.(compareValueKey);
    if (value === compareValue) return undefined;

    return `${pluralEntityName} must match`;
  },
});
