import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    background: {
      ...styling.utils.absoluteFull(),
    },
    popoverContent: {
      marginTop: 'auto',
      position: 'relative',
      zIndex: 2,
      marginBottom: styling.sizes.theme.spacing.md,
      marginLeft: 'auto',
      marginRight: '20%',
    },
  },
  { name: 'createMenuContent' }
);
