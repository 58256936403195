import { z } from 'zod';
import {
  CardLoadedModelSchema,
  CardType,
  CardUnloadedModelSchema,
} from '@kitted/kitted-models';
import { DateISOSchema } from '@kitted/platform-models';

import { PublishSettingsSchema } from '../../PublishSettings';

const FlowCardLoadedBaseLoadedModelSchema = z.object({
  cardType: z.literal(CardType.Flow),
  title: z.string(),
  description: z.string(),

  publishedAt: DateISOSchema.optional(),
  publishSettings: PublishSettingsSchema,
});

export const FlowCardLoadedModelSchema = CardLoadedModelSchema.merge(
  FlowCardLoadedBaseLoadedModelSchema
);
export const FlowCardUnloadedModelSchema = CardUnloadedModelSchema.merge(
  FlowCardLoadedBaseLoadedModelSchema
);

export type FlowCardLoadedModel = z.infer<typeof FlowCardLoadedModelSchema>;
export type FlowCardUnloadedModel = z.infer<typeof FlowCardUnloadedModelSchema>;

export type FlowCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? FlowCardLoadedModel : FlowCardUnloadedModel;
