import { UserRefreshTokenRequest } from '@kitted/auth-service-models';

import { getAuthConfig } from '../../services/requests/authorizedRequests/logic';
import { addServiceBaseUrlToConfig } from '../RequestsContext/hooks/usePlatformRequest/logic';
import { tokenExiryOffsetMs } from './constants';
import { AuthTokensApi } from './types';

const getRefreshTokenPayloadFromTokens = (
  getRefreshToken: AuthTokensApi['getRefreshToken']
): UserRefreshTokenRequest => ({
  refreshToken: getRefreshToken()!,
});

export const getRefreshTokenConfig = (
  isLocalDevelopment: boolean,
  environmentDomain: string,
  getAccessToken: AuthTokensApi['getAccessToken'],
  getRefreshToken: AuthTokensApi['getRefreshToken']
) =>
  getAuthConfig(
    addServiceBaseUrlToConfig(isLocalDevelopment, environmentDomain, 'auth', {
      method: 'POST',
      data: getRefreshTokenPayloadFromTokens(getRefreshToken),
      url: '/v1/refreshToken',
    }),
    getAccessToken()
  );

export const isTokenWithinExpiry = (
  tokenExpiresAt: Date | string,
  now: number
) => {
  const expiresAtUnix = new Date(tokenExpiresAt).getTime();
  return expiresAtUnix - now < tokenExiryOffsetMs;
};
