import { memo } from 'react';
import clsx from 'clsx';
import { Typography } from '@kitted/shared-components';

import useCollapseMenu from './hooks/useCollapseMenu';
import useStyles from './styles';
import { CollapseMenuProps } from './types';

const CollapseMenu = ({
  className,
  children,
  onClickOutside,
  isOpen,
  maxHeight,
  docked = 'left',
  lockDirection,
  theme = 'primary',
}: CollapseMenuProps) => {
  const { direction, style, listRef } = useCollapseMenu(
    isOpen,
    onClickOutside,
    maxHeight,
    lockDirection
  );
  const styles = useStyles();

  return (
    <Typography
      ref={listRef}
      as="div"
      className={clsx(
        className,
        styles.container,
        styles[`docked-${docked}`],
        styles[`direction-${direction}`],
        styles[`theme-${theme}`],
        isOpen && styles.containerOpen
      )}
      style={style}
      variant="inputDropdown"
    >
      <ul className={clsx(styles.list, isOpen && styles.listOpen)}>
        {isOpen && children}
      </ul>
    </Typography>
  );
};

export default memo(CollapseMenu);
