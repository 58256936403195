import { memo } from 'react';
import clsx from 'clsx';
import { ImageSkeleton, TextSkeleton } from '@kitted/shared-components';

import useStyles from './styles';
import { ToolCardSkeletonProps } from './types';

const ToolCardSkeleton = ({ isFeatured, theme }: ToolCardSkeletonProps) => {
  const styles = useStyles();

  return (
    <>
      <ImageSkeleton
        className={clsx(styles.image, isFeatured && styles.imageFeatured)}
        theme={theme}
      />
      <TextSkeleton
        className={clsx(styles.title, isFeatured && styles.titleFeatured)}
        lines={2}
        theme={theme}
        variant={`cardTitle${isFeatured ? 'Featured' : ''}`}
      />
      <TextSkeleton
        className={clsx(styles.body, isFeatured && styles.bodyFeatured)}
        lines={4}
        theme={theme}
        variant={`cardBody${isFeatured ? 'Featured' : ''}`}
      />
    </>
  );
};

export default memo(ToolCardSkeleton);
