import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    toast: {
      boxShadow: styling.shadows.utils.boxShadowToString(
        styling.shadows.context.cardFeature
      ),
      [styling.breakpoints.context.tabletUpString]: {
        flexWrap: 'nowrap',
        flexDirection: 'row',
        justifyContent: 'center',
        textAlign: 'left',
      },
    },
    progress: {
      position: 'absolute',
      top: styling.sizes.theme.spacing.xs - 5,
      left: '50%',
      transform: 'translate(-50%, 0)',
      [styling.breakpoints.context.tabletUpString]: {
        top: '50%',
        left: styling.sizes.theme.spacing.xs,
        transform: 'translate(-5px, -50%)',
      },
    },
  },
  { name: 'toast' }
);
