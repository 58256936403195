import { memo } from 'react';
import { Panel, Typography } from '@kitted/shared-components';

import FormFields from '../../../components/FormFields';
import PreviewCard from '../../PreviewCard';
import SplitContent from '../../SplitContent';
import { ModelCreateEditEditModalSpaceProps } from '../types';
import useModelCreateEditForm from './hooks/useModelCreateEditForm';

const ModelCreateEditDetailsSection = ({
  id,
  cardType,
}: ModelCreateEditEditModalSpaceProps) => {
  const { overrideCardProps, upperFirstCardType, layoutSchema } =
    useModelCreateEditForm(cardType);

  return (
    <SplitContent
      left={<PreviewCard cardId={id} overrideCardProps={overrideCardProps} />}
    >
      <Panel theme="primary">
        <Typography variant="formTitle">
          {upperFirstCardType} details
        </Typography>
        <FormFields layout={layoutSchema} theme="primary" />
      </Panel>
    </SplitContent>
  );
};

export default memo(ModelCreateEditDetailsSection);
