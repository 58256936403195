import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { InputComponentProps } from '../../../types';
import { handleOnToggleShow } from '../../logic';

const useInputPassword = (
  onChange: InputComponentProps['onChange'],
  onBlur: InputComponentProps['onBlur'],
  showStrengthIndicator?: boolean
) => {
  const [isShowing, setIsShowing] = useState<boolean>(false);

  const handleOnChange = useCallback(
    (e: ChangeEvent) => onChange((e.target as HTMLInputElement).value),
    [onChange]
  );

  const onToggleShow = useCallback(
    () => handleOnToggleShow(setIsShowing),
    [setIsShowing]
  );

  useEffect(() => {
    // make the strength indicator work regardless of validation strategy
    if (showStrengthIndicator) {
      onBlur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    handleOnChange,
    onToggleShow,
    isShowing,
    shouldHideErrors: !!showStrengthIndicator,
  };
};

export default useInputPassword;
