/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import { ImageSize } from '@kitted/kitted-models';

import BackgroundImage from '../BackgroundImage';
import CardWithImageAsset from '../CardWithImageAsset';
import CardSkeleton from '../Loaders/CardSkeleton';
import FeaturedCardScaleContainer from '../ScaleContainer/FeaturedCardScaleContainer';
import usePreviewCard from './hooks/usePreviewCard';
import useStyles from './styles';
import { PreviewCardProps } from './types';

const PreviewCard = ({ cardId, overrideCardProps }: PreviewCardProps) => {
  const styles = useStyles();
  const { cardProps, isLoading } = usePreviewCard(cardId, overrideCardProps);

  return (
    <>
      <BackgroundImage
        className={styles.backgroundImage}
        variant="page"
        src={
          overrideCardProps?.imageSrc ?? overrideCardProps?.imageAsset
            ? overrideCardProps?.imageSrc
            : cardProps.imageSrc
        }
        imageAsset={
          overrideCardProps?.imageAsset ?? overrideCardProps?.imageSrc
            ? overrideCardProps?.imageAsset
            : cardProps.imageAsset
        }
        size={ImageSize.XLarge}
      />
      <FeaturedCardScaleContainer className={styles.card}>
        {isLoading && cardId && <CardSkeleton isFeatured />}
        {(!isLoading || !cardId) && <CardWithImageAsset {...cardProps} />}
      </FeaturedCardScaleContainer>
    </>
  );
};

export default memo(PreviewCard);
