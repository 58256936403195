import { memo } from 'react';

import MainMenuItem from '../../../routes/_root/MainMenuDrawer/MainMenuItem';
import useEditDrawerSpaceDrawerContent from './hooks/useEditDrawerSpaceDrawerContent';
import useStyles from './styles';

const EditDrawerSpaceDrawerContent = () => {
  const { topItems, bottomItems, selectedDrawerItemSlug } =
    useEditDrawerSpaceDrawerContent();
  const styles = useStyles();

  return (
    <>
      <nav className={styles.topItems}>
        <ul>
          {topItems.map(({ itemComponent: ItemComponent, ...item }) => {
            const Component = ItemComponent || MainMenuItem;
            return (
              <Component
                key={item.slug}
                onClick={item.onClick}
                title={item.title}
                icon={item.icon}
                isSelected={item.slug === selectedDrawerItemSlug}
              >
                {item.title}
              </Component>
            );
          })}
        </ul>
      </nav>
      <nav className={styles.bottomItems}>
        <ul>
          {bottomItems.map(({ itemComponent: ItemComponent, ...item }) => {
            const Component = ItemComponent || MainMenuItem;
            return (
              <Component
                key={item.slug}
                onClick={item.onClick}
                title={item.title}
                icon={item.icon}
                isSelected={item.slug === selectedDrawerItemSlug}
              >
                {item.title}
              </Component>
            );
          })}
        </ul>
      </nav>
    </>
  );
};

export default memo(EditDrawerSpaceDrawerContent);
