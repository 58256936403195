import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const CaretRight: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 14 26"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" fill={color} d="m12.73 13.24.07-.07s.07-.11.07-.18-.03-.13-.07-.18c0 .01-9.17-9.15-11.29-11.26a.223.223 0 0 0-.38.16v22.58c0 .2.24.3.38.16l11.21-11.21Z" />
    </g>
  </WebsiteIcon>
);

export default memo(CaretRight);
