import { useCallback, useMemo, useState } from 'react';

import { FormDataValue } from '../../../../contexts/FormContext';
import {
  ResourceModelCreateModalSpaceProps,
  ResourceModelCreateProps,
} from '../../types';
import { getDataSchema, getInitialData, getSections } from './logic';

const useResourceModelCreate = (
  title: ResourceModelCreateProps['title'],
  cardType: ResourceModelCreateProps['cardType'],
  resourceType: ResourceModelCreateProps['resourceType'],
  isCloning: ResourceModelCreateProps['isCloning'],
  initialData: ResourceModelCreateProps['initialData'],
  onSaveChanges: ResourceModelCreateProps['onSaveChanges'],
  onClose: ResourceModelCreateProps['onClose']
) => {
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const wrappedOnSaveChanges = useCallback(
    async (formData: FormDataValue) => {
      setShowLoading(true);
      try {
        await onSaveChanges(formData);
      } finally {
        setShowLoading(false);
      }
    },
    [onSaveChanges, setShowLoading]
  );

  const spaceProps: ResourceModelCreateModalSpaceProps = useMemo(
    () => ({
      title,
      cardType,
      resourceType,
      isCloning,
      onSaveChanges: wrappedOnSaveChanges,
      onClose,
    }),
    [title, cardType, resourceType, isCloning, wrappedOnSaveChanges, onClose]
  );

  const initialValues = useMemo(
    () => getInitialData(resourceType, initialData),
    [resourceType, initialData]
  );

  const dataSchema = useMemo(() => getDataSchema(resourceType), [resourceType]);

  const [resourceModelCreateSections, resourceModelCreateSectionDefaultSlug] =
    useMemo(() => getSections(resourceType), [resourceType]);

  return {
    initialValues,
    dataSchema,
    resourceModelCreateSections,
    resourceModelCreateSectionDefaultSlug,
    spaceProps,
    showLoading,
  };
};

export default useResourceModelCreate;
