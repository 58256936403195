import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    forgotPassword: {
      ...styling.utils.buttonReset(),
      display: 'block',
      marginTop: -1 * styling.sizes.theme.spacing.xs,
      marginBottom: styling.sizes.theme.spacing.xs,
      position: 'relative',
      zIndex: 2,
    },
  },
  { name: 'forgotPasswordLink' }
);
