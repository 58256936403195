import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0045: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 114 114"
		height={114}
		width={114}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M57 1.29v111.42M112.71 57H1.29" /></g><circle vectorEffect="non-scaling-stroke" cx="27.53" cy="26.61" r="15.05" stroke={spot} /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M15.68 96.52c2.76 3.51 7.04 5.77 11.85 5.77 8.31 0 15.05-6.74 15.05-15.05h-30.1c0-8.31 6.74-15.05 15.05-15.05 4.27 0 8.13 1.78 10.87 4.64M99.21 18.19c-2.7-4-7.28-6.64-12.48-6.64-8.31 0-15.05 6.74-15.05 15.05s6.74 15.05 15.05 15.05 15.05-6.74 15.05-15.05H90.72" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M86.73 72.18v15.05M93.11 100.85c5.12-2.4 8.67-7.59 8.67-13.62 0-8.31-6.74-15.05-15.05-15.05s-15.05 6.74-15.05 15.05c0 6.03 3.55 11.22 8.67 13.62" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0045);
