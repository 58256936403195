import { memo } from 'react';
import clsx from 'clsx';
import { Typography } from '@kitted/shared-components';

import InputWrapper from '../InputWrapper';
import { InputComponentProps } from '../types';
import useInputTextarea from './hooks/useInputTextarea';
import useStyles from './styles';
import { InputTextareaMetaProp } from './types';

const InputTextarea = ({
  autoComplete,
  className,
  required,
  disabled,
  errors,
  isFocused,
  onBlur,
  onChange,
  onFocus,
  name,
  value,
  meta,
  theme,
  variant,
}: InputComponentProps & InputTextareaMetaProp) => {
  const styles = useStyles();
  const { handleOnChange } = useInputTextarea(onChange);

  return (
    <InputWrapper
      className={className}
      errors={errors}
      isDisabled={disabled}
      isFocused={isFocused}
      hasValue={!!value}
      helperText={meta?.helperText}
      theme={theme}
      variant={variant}
    >
      <Typography
        as="textarea"
        autoFocus={meta?.autoFocus}
        autoComplete={autoComplete}
        className={clsx(styles.input, meta?.canResize && styles.inputResize)}
        disabled={disabled}
        name={name}
        onBlur={onBlur}
        onChange={handleOnChange}
        onFocus={onFocus}
        placeholder={meta?.placeholder}
        required={required}
        rows={meta?.rows || 4}
        value={(value as string | null) || ''}
        variant={`input${variant === 'hollow' ? 'Small' : ''}`}
      />
    </InputWrapper>
  );
};

export default memo(InputTextarea);
