import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0012: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 82 102"
		height={102}
		width={82}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m53.08 34.85 6.71 48.43M22.21 83.28l6.7-48.44" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M1.76 96.44s8.27 8.32 19.61 0c0 0 8.33 8.32 19.67 0 0 0 8.27 8.32 19.6 0 0 0 8.27 8.32 19.6 0M53.06 34.11v-20.3c0-6.58-5.49-11.95-12.06-11.95S28.94 7.24 28.94 13.81v6.54M29.16 11.68h23.68" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M16.77 16.95H3.63M16.46 9.1 3.94 3.98M3.94 29.92l12.52-5.12M65.23 16.95h13.14M65.54 24.8l12.52 5.12M78.06 3.98 65.54 9.1" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M25.07 34.4h31.86M25.07 26.18h31.86M28.94 34.33v-8.1M14.33 93.32l2.03-9.85h49.28l2.03 9.85M54.58 47.02H33.56M55.8 59.73H31.88M57.4 72.45H30.2M41 25.66v-7.92" />
    </g>
  </CardIcon>
);

export default memo(Ac0012);
