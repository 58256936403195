import { memo } from 'react';

import { StoreSectionInitializerProps } from '../types';
import useFavoritesStoreInitializer from './hooks/useFavoritesStoreInitializer';

const FavoritesStoreInitializer = ({
  onSectionLoaded,
}: StoreSectionInitializerProps) => {
  useFavoritesStoreInitializer(onSectionLoaded);

  return null;
};

export default memo(FavoritesStoreInitializer);
