import { useCallback, useMemo } from 'react';

import useModalApi from '../../../../../contexts/ModalContext/hooks/useModalApi';
import AssetCatalogue from '../../../../AssetCatalogue';
import { AssetCatalogueSave } from '../../../../AssetCatalogue/types';
import { ButtonProps } from '../../../../Button/types';
import { InputComponentProps } from '../../../types';
import { InputImageMetaProp } from '../../types';

const useInputImage = ({
  meta,
  onChange,
  theme,
}: {
  meta: InputImageMetaProp['meta'];
  onChange: InputComponentProps['onChange'];
  theme: InputComponentProps['theme'];
}) => {
  const { registerModal } = useModalApi();

  const onClear = useCallback(() => {
    onChange();
  }, [onChange]);

  const buttonTheme: ButtonProps['theme'] = useMemo(() => {
    if (theme === 'primary') {
      return 'secondary';
    }
    return 'primary';
  }, [theme]);

  const onSaveChanges = useCallback(
    (saveInfo: AssetCatalogueSave) => {
      if (saveInfo.isUploadedBlob) {
        onChange(saveInfo.blobId);
      } else {
        onChange(saveInfo.assetId);
      }
    },
    [onChange]
  );

  const onAddImage = useCallback(() => {
    registerModal('inputImage', {
      title: 'Add key image',
      bodyComponent: AssetCatalogue,
      bodyComponentProps: {
        isMultiSelect: false,
        maxFileSize: meta?.maxFileSize,
        acceptedFileFormats: meta?.acceptedFileFormats,
        onSaveChanges,
        initialCatalogueSectionSlug: meta?.initialCatalogueSectionSlug,
      },
      variant: 'fullscreen',
    });
  }, [
    onSaveChanges,
    registerModal,
    meta?.maxFileSize,
    meta?.acceptedFileFormats,
    meta?.initialCatalogueSectionSlug,
  ]);

  return {
    onAddImage,
    onClear,

    buttonTheme,
  };
};

export default useInputImage;
