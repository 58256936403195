import { memo } from 'react';
import { styling } from '@kitted/design-system';

import { TTFHBrandProps } from './types';

const TTFHBrand = ({
  className,
  height = 46,
  width = 144,
  fill = styling.colors.theme.primary600,
}: TTFHBrandProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14.4 4.6"
    className={className}
    height={height}
    width={width}
  >
    <g fill={fill}>
      <path d="m2.2,3.7c.6-.02.78-.02,1.08-.51h.07l-.13,1.28H.04v-.04l1.05-1.12c.59-.63,1.1-1.25,1.1-2.06,0-.49-.19-.84-.61-.84-.36,0-.56.31-.56.74,0,.31.1.56.28.69-.07.07-.26.1-.41.1-.37,0-.68-.27-.68-.71C.2.61.76.18,1.58.18s1.59.34,1.59,1.28c0,.85-.84,1.47-2.28,2.27l1.31-.03Z" />
      <path d="m5.74,3.19c0-.62-.23-1.03-.83-1.03-.36,0-.64.15-.86.33h-.02l.27-2.24c.44.05.97.09,1.32.09.46,0,.75-.03.99-.27h.02c0,.68-.22,1.15-.96,1.15-.41,0-.94-.11-1.2-.22l-.13,1.08c.19-.15.56-.28,1-.28.77,0,1.43.42,1.43,1.3,0,1-.91,1.43-1.78,1.43-.78,0-1.48-.34-1.48-1,0-.46.35-.72.73-.72.18,0,.32.05.4.1-.14.12-.27.34-.27.67,0,.46.21.7.62.7.51,0,.74-.45.74-1.09Z" />
      <path d="m8.75,4.53c-1.08,0-1.8-.84-1.8-2.18S7.67.18,8.75.18s1.81.84,1.81,2.18-.73,2.18-1.81,2.18Zm0-4.1c-.55,0-.76.75-.76,1.92s.21,1.92.76,1.92.77-.75.77-1.92-.21-1.92-.77-1.92Z" />
      <path d="m12.55,4.53c-1.08,0-1.8-.84-1.8-2.18S11.47.18,12.55.18s1.81.84,1.81,2.18-.73,2.18-1.81,2.18Zm0-4.1c-.55,0-.76.75-.76,1.92s.21,1.92.76,1.92.77-.75.77-1.92-.21-1.92-.77-1.92Z" />
    </g>
    <path
      fill={fill}
      d="m14.25.26c.05,0,.09.04.09.09s-.04.09-.09.09-.09-.04-.09-.09.04-.09.09-.09Zm.07.09s-.03-.07-.07-.07-.07.03-.07.07.03.07.07.07.07-.03.07-.07Zm-.08.04h-.02v-.09h.04s.03,0,.03.03c0,.02-.01.02-.02.03l.02.04h-.02l-.02-.04h-.01v.04Zm.01-.05s.02,0,.02-.01c0,0,0-.01-.01-.01h-.02v.03h.01Z"
    />
  </svg>
);

export default memo(TTFHBrand);
