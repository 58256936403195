import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    tooltipProfileAvatar: {
      scale: 3 / 4,
    },
    statusItem: {
      padding: [14, 20, 14, 0],
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
    },
    statusItemIcon: {
      width: 62,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  { name: 'cardAuthorAvatar' }
);
