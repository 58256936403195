import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

import { overlayBorderStyles, overlayStyles } from '../styles';

export default createUseStyles(
  {
    container: {
      '&:before': {
        ...overlayStyles(),
      },
      '&:after': {
        ...overlayBorderStyles(),
      },
    },
    image: {
      '$container &': {
        backgroundColor: styling.colors.theme.snare,
      },
    },
    containerFeatured: {
      '&:before, &:after': {
        content: 'unset',
      },
    },
    containerMenuOpen: {
      '&:before, &:after': {
        opacity: 1,
      },
    },
  },
  { name: 'authorCard' }
);
