import { memo } from 'react';
import clsx from 'clsx';
import { Fade } from '@kitted/shared-components';

import DrawerSpaceContentWrapper from '../DrawerSpace/DrawerSpaceContentWrapper';
import DrawerSpacePortal from '../DrawerSpace/DrawerSpacePortal';
import FixedBottom from '../FixedBottom';
import useMobileOverlay from './hooks/useMobileOverlay';
import useStyles from './styles';
import { MobileOverlayProps } from './types';

const MobileOverlay = ({
  isOpen,
  children,
  childrenClassName,
  isInDynamicTopBar = false,
}: MobileOverlayProps) => {
  const { isClosing, onExited } = useMobileOverlay(isOpen);
  const styles = useStyles();

  return (
    <DrawerSpacePortal>
      <FixedBottom
        className={clsx(
          styles.menuContainer,
          isInDynamicTopBar && styles.menuContainerInDynamicTopBar,
          (isOpen || isClosing) && styles.menuContainerOpen
        )}
      >
        <Fade
          className={styles.menuContainerInner}
          durationIn={500}
          durationOut={500}
          in={isOpen}
          onExited={onExited}
        >
          <DrawerSpaceContentWrapper
            className={clsx(childrenClassName, styles.children)}
          >
            {children}
          </DrawerSpaceContentWrapper>
        </Fade>
      </FixedBottom>
    </DrawerSpacePortal>
  );
};

export default memo(MobileOverlay);
