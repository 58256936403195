import { createBrowserRouter, RouteObject } from 'react-router-dom';
import { Entries } from 'type-fest';
import {
  InternalRoute,
  routes,
  routesFlat,
  RoutesNonRedirectsKeys,
} from '@kitted/website-constants';

import RedirectTo from '../../components/RedirectTo';
import { ErrorBoundary } from '../../contexts/ErrorCaptureContext';
import Root from '../../routes/_root';
import routeSceneMap from './routesWithScenes';
import { RouteComponentProps } from './types';

const getRouteElementOrLazy = (
  routeKey: RouteComponentProps['routeKey'],
  route: InternalRoute
):
  | { element: React.ReactNode }
  | { lazy: () => Promise<{ element: React.ReactNode }> } => {
  if (route.redirectTo) {
    return {
      element: <RedirectTo to={route.redirectTo} />,
    };
  }
  const nonRedirectRouteKey = routeKey as RoutesNonRedirectsKeys;

  if (!(nonRedirectRouteKey in routeSceneMap)) return { element: null };

  return {
    lazy: async () => {
      const { default: Component } = await routeSceneMap[nonRedirectRouteKey]();

      return {
        element: <Component routeKey={nonRedirectRouteKey} />,
      };
    },
  };
};

const processRoutes = (
  routesToProcess: Partial<typeof routesFlat>
): RouteObject[] =>
  (Object.entries(routesToProcess) as Entries<typeof routesFlat>).map(
    ([routeKey, route]) =>
      ({
        ...getRouteElementOrLazy(routeKey, route),
        path: route.path,
        children:
          'children' in route ? processRoutes(route.children) : undefined,
        ErrorBoundary,
      }) as RouteObject
  );

const router = createBrowserRouter([
  {
    element: <Root />,
    ErrorBoundary,
    children: [
      ...processRoutes(routes),
      {
        path: '*',
        element: <RedirectTo to={routesFlat.fourOhFour.path} />,
      },
    ],
  } as RouteObject,
]);

export default router;
