import { memo } from 'react';

import useAuthTokensRefreshTimer from './hooks/useAuthTokensRefreshTimer';

const AuthTokensRefreshTimer = () => {
  useAuthTokensRefreshTimer();

  return null;
};

export default memo(AuthTokensRefreshTimer);
