import { z } from 'zod';
import { KittedModelBaseSchema } from '@kitted/kitted-models';
import { DateISOSchema } from '@kitted/platform-models';

import { CatalogueItemType } from './enum';

export const CatalogueItemModelSchema = KittedModelBaseSchema.merge(
  z.object({
    pk: z.string(),
    sk: z.string(),

    itemType: z.nativeEnum(CatalogueItemType),
    createdByAuthorId: z.string(),
    createdByUserId: z.string(),

    assetId: z.string(),

    name: z.string(),

    description: z.string(),
    tags: z.array(z.string()),

    updatedAt: DateISOSchema,
  })
);

export type CatalogueItemModel = z.infer<typeof CatalogueItemModelSchema>;
