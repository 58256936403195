import { useCallback, useRef } from 'react';

import { FormDataValue } from '../../../../../contexts/FormContext';
import { toastDurations } from '../../../../../contexts/ToastContext/constants';
import useToastApi from '../../../../../contexts/ToastContext/hooks/useToastApi';
import useUserApi from '../../../../../contexts/UserContext/hooks/useUserApi';
import {
  getInitialFormData,
  transformChangeFormDataToChangePayload,
} from '../../logic';
import {
  AuthChangePasswordModalProps,
  ChangePasswordFormData,
} from '../../types';

const useChangePasswordModalContent = (
  onClose: AuthChangePasswordModalProps['onClose'],
  meta: AuthChangePasswordModalProps['meta']
) => {
  const initialFormData = useRef<FormDataValue>(
    getInitialFormData(meta.initialFormData)
  );
  const { confirmForgotPassword } = useUserApi();
  const { registerToast } = useToastApi();

  const onSubmit = useCallback(
    async (formData: ChangePasswordFormData) => {
      const changePasswordPayload =
        transformChangeFormDataToChangePayload(formData);
      return confirmForgotPassword(changePasswordPayload);
    },
    [confirmForgotPassword]
  );

  const onSubmitSuccess = useCallback(() => {
    registerToast('changePasswordSuccess', {
      title: 'Your password has been changed',
      theme: 'success',
      duration: toastDurations.success,
    });
    onClose();
  }, [registerToast, onClose]);

  return {
    onSubmit,
    onSubmitSuccess,
    initialFormData: initialFormData.current,
  };
};

export default useChangePasswordModalContent;
