import { memo } from 'react';

import useContextualModalTitle from '../../../contexts/ModalContext/hooks/useContextualModalTitle';
import Button from '../../Button';
import EditDrawerSpaceFooter from '../../EditDrawerSpace/EditDrawerSpaceFooter';
import { ModelCreateEditEditModalSpaceProps } from '../types';
import useModelCreateEditFormFooter from './hooks/useModelCreateEditFormFooter';

const ModelCreateEditFormFooter = ({
  id,
  cardType,
  isCloning,
  onSaveChanges,
  onClose,
  title,
}: ModelCreateEditEditModalSpaceProps) => {
  const contextualTitle = useContextualModalTitle(title);
  const { buttonText, onSubmit, isDisabled } = useModelCreateEditFormFooter(
    id,
    cardType,
    isCloning,
    onSaveChanges,
    onClose
  );

  return (
    <EditDrawerSpaceFooter title={contextualTitle}>
      <Button onClick={onClose} type="button" theme="primary" fullWidth>
        Cancel
      </Button>
      <Button
        onClick={onSubmit}
        isDisabled={isDisabled}
        variant="solid"
        theme="primary"
        type="button"
        fullWidth
      >
        {buttonText}
      </Button>
    </EditDrawerSpaceFooter>
  );
};

export default memo(ModelCreateEditFormFooter);
