import { EMPTY_ARR, EMPTY_OBJ } from '@kitted/shared-utils';

import deepFreeze from '../helpers/deepFreeze';
import {
  ItemSubscriptionCallback,
  KeysSubscriptionCallback,
  KeyValueStore,
  Unsubscribe,
} from './types';

const createKeyValueStore = <T = unknown>(
  initialState = {}
): KeyValueStore<T> => {
  let cache: Record<string, T> = deepFreeze(initialState);
  let itemWhereSubscriptions: {
    finderFn: (value: T) => boolean;
    matchedKey: string | undefined;
    callback: ItemSubscriptionCallback<T | undefined>;
  }[] = EMPTY_ARR;
  let itemSubscriptions: Record<
    string,
    ItemSubscriptionCallback<T | undefined>[]
  > = EMPTY_OBJ as Record<string, ItemSubscriptionCallback<T | undefined>[]>;
  let keysSubscriptions: {
    callback: KeysSubscriptionCallback;
  }[] = EMPTY_ARR;

  const notifyItemSubscribers = (key: string, value: T | undefined) => {
    const itemSubscribers = itemSubscriptions[key] || EMPTY_ARR;
    itemSubscribers.forEach((itemSubscriber) => {
      itemSubscriber(value);
    });
    itemWhereSubscriptions.forEach(({ matchedKey, callback }) => {
      if (matchedKey === key) {
        callback(value);
      }
    });
  };

  const notifyKeysSubscribers = () => {
    const keys = Object.keys(cache);
    keysSubscriptions.forEach(({ callback }) => {
      callback(keys);
    });
  };

  const syncItemWhereSubscribers = (key: string, value: T | undefined) => {
    itemWhereSubscriptions = itemWhereSubscriptions.map(
      (itemWhereSubscriber) => {
        const { finderFn, matchedKey } = itemWhereSubscriber;
        // if we've previously matched the key, early return
        if (matchedKey || !value) return itemWhereSubscriber;
        const doesMatch = finderFn(value);

        if (doesMatch) {
          return {
            ...itemWhereSubscriber,
            matchedKey: key,
          };
        }

        return itemWhereSubscriber;
      }
    );
  };

  const getKeyWhere = (finderFn: (value: T) => boolean): string | undefined =>
    Object.keys(cache).find((key) => finderFn(cache[key]));

  const getKeysWhere = (finderFn: (value: T) => boolean): string[] =>
    Object.keys(cache).filter((key) => finderFn(cache[key]));

  const getValueForKey = (key: string): T | undefined => cache[key];

  const setValueForKey = (key: string, value: T | undefined) => {
    const willAdd = !getValueForKey(key);
    if (willAdd) {
      // on create of an item
      syncItemWhereSubscribers(key, value);
    }
    cache = deepFreeze({
      ...cache,
      [key]: value,
    });
    notifyItemSubscribers(key, value);
    if (willAdd) {
      notifyKeysSubscribers();
    }
  };

  const deleteValueForKey = (key: string) => {
    const { [key]: deleted, ...newCache } = cache;
    syncItemWhereSubscribers(key, deleted);
    cache = deepFreeze(newCache);
    notifyItemSubscribers(key, undefined);
    notifyKeysSubscribers();
  };

  const subscribeToItem = (
    key: string,
    callback: ItemSubscriptionCallback<T | undefined>
  ): Unsubscribe => {
    const closedCallback = ((item: T | undefined) => {
      callback(item);
    }) as ItemSubscriptionCallback<T | undefined>;
    itemSubscriptions = {
      ...itemSubscriptions,
      [key]: [...(itemSubscriptions[key] || []), closedCallback],
    };
    closedCallback(getValueForKey(key));
    return () => {
      itemSubscriptions = {
        ...itemSubscriptions,
        [key]: itemSubscriptions[key].filter(
          (itemSubscription) => itemSubscription !== closedCallback
        ),
      };
    };
  };

  const subscribeToItemWhere = (
    finderFn: (value: T) => boolean,
    callback: ItemSubscriptionCallback<T | undefined>
  ): Unsubscribe => {
    const closedCallback = ((item: T | undefined) => {
      callback(item);
    }) as ItemSubscriptionCallback<T | undefined>;
    const matchedKey = getKeyWhere(finderFn);
    itemWhereSubscriptions = [
      ...itemWhereSubscriptions,
      {
        finderFn,
        matchedKey,
        callback: closedCallback,
      },
    ];
    closedCallback(matchedKey ? getValueForKey(matchedKey) : undefined);
    return () => {
      itemWhereSubscriptions = itemWhereSubscriptions.filter(
        (itemWhereSubscription) =>
          itemWhereSubscription.callback !== closedCallback
      );
    };
  };

  const subscribeToKeys = (callback: (keys: string[]) => void): Unsubscribe => {
    const closedCallback = (keys: string[]) => {
      callback(keys);
    };
    keysSubscriptions = [
      ...keysSubscriptions,
      {
        callback: closedCallback,
      },
    ];
    closedCallback(Object.keys(cache));
    return () => {
      keysSubscriptions = keysSubscriptions.filter(
        (keysSubscription) => keysSubscription.callback !== closedCallback
      );
    };
  };

  const resetStore = () => {
    const keys = Object.keys(cache);
    cache = deepFreeze(initialState);
    keys.forEach((key) => {
      const itemSubscribers = itemSubscriptions[key] || EMPTY_ARR;
      itemSubscribers.forEach((itemSubscriber) => {
        itemSubscriber(undefined);
      });
      itemWhereSubscriptions.forEach(({ matchedKey, callback }) => {
        if (matchedKey === key) {
          callback(undefined);
        }
      });
    });
  };

  return {
    getKeyWhere,
    getKeysWhere,
    getValueForKey,
    setValueForKey,
    deleteValueForKey,
    subscribeToItem,
    subscribeToItemWhere,
    subscribeToKeys,
    resetStore,
  };
};

export default createKeyValueStore;
