import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    imageSkeleton: {
      height: '100%',
      backgroundSize: [
        [
          styling.animations.context.loaderBackgroundSecondary.useage
            .backgroundSize,
        ],
        '!important',
      ],
    },
    container: {
      position: 'relative',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      pointerEvents: 'none',
      fontSize: '2.4rem',
    },
    canDrag: {
      pointerEvents: 'all',
    },
    'color-none': {
      color: styling.colors.context.color,
    },
    'color-primary': {
      color: styling.colors.context.inverted.color,
    },
    'color-secondary': {
      color: styling.colors.context.color,
    },
    fadeContainer: {
      ...styling.utils.absoluteFull(),
      zIndex: 1,
    },
    icon: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    picture: {
      ...styling.utils.absoluteFull(),
      zIndex: 1,
      display: 'block',
    },
    image: {
      display: 'block',
      opacity: 0,
      height: '100%',
      width: '100%',
      transition: [['opacity', '500ms']],
      objectFit: 'cover',
    },
    imageLoaded: {
      opacity: 1,
    },
  },
  { name: 'image' }
);
