/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import { EMPTY_ARR } from '@kitted/shared-utils';

import Input from '../../Input';
import useField from './hooks/useField';
import useFieldPropFetchers from './hooks/useFieldPropFetchers';
import { FieldProps } from './types';

const Field = ({
  onFieldChange,
  type,
  name,
  customAttributes,
  validation = EMPTY_ARR,
  ...rest
}: FieldProps) => {
  const { inputProps } = useField({
    validation,
    customAttributes,
  });
  const { value, errors, isLoading, onChange, onSetIsLoading } =
    useFieldPropFetchers(name, onFieldChange);

  return (
    <Input
      {...rest}
      {...inputProps}
      name={name}
      isLoading={!!isLoading}
      errors={errors}
      onChange={onChange}
      onSetIsLoading={onSetIsLoading}
      value={value}
      type={type as any}
    />
  );
};

export default memo(Field);
