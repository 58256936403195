import { FieldValue } from '../../../contexts/FormContext';
import { isRequired } from './isRequired';
import { ValidationFn } from './types';

export type ValidationItemMustMatchRegex = {
  name: 'mustMatchRegex';
  expression: RegExp;
  isRequired: boolean;
  errorMessage: string;
  validator: ValidationFn;
};

export const mustMatchRegex = (
  isValueRequired: boolean,
  expression: ValidationItemMustMatchRegex['expression'],
  errorMessage: ValidationItemMustMatchRegex['errorMessage']
): ValidationItemMustMatchRegex => ({
  name: 'mustMatchRegex',
  isRequired: isValueRequired,
  expression,
  errorMessage,
  validator: (value: FieldValue) => {
    if (isValueRequired) {
      const requiredErrors = isRequired.validator(value);
      if (requiredErrors) {
        return requiredErrors;
      }
    } else if (value === '' || value === null || value === undefined) {
      return undefined;
    }
    const isValid = expression.test(`${value}`);
    if (isValid) return undefined;

    return errorMessage;
  },
});
