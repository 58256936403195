/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';

import CardContents from '../CardContents';
import CardContentsForHoverableCard from '../CardContentsForHoverableCard';
import CardImage from '../CardImage';
import useExtractInteractionProps from '../hooks/useExtractInteractionProps';
import useStyles from './styles';
import { PolymorphicKitCardProps } from './types';

const KitCard = <C extends React.ElementType = 'div'>({
  as,
  body,
  children,
  className,
  isActive,
  isFeatured,
  isMenuOpen,
  imageSrc,
  title,
  slug,
  subType,
  ...rest
}: PolymorphicKitCardProps<C>) => {
  const interactionProps = useExtractInteractionProps(rest);
  const styles = useStyles();
  const Component = as || 'div';

  return (
    <Component
      className={clsx(
        className,
        styles.container,
        isActive && styles.containerActive,
        isFeatured && styles.containerFeatured,
        isMenuOpen && styles.containerMenuOpen,
        isFeatured && 'inverted'
      )}
      {...interactionProps}
    >
      <CardImage
        halfHeight={isFeatured}
        isFeatured={isFeatured}
        imageSrc={imageSrc}
        loaderTheme="primary"
      />
      {isFeatured ? (
        <CardContents title={title} body={body} isFeatured type="kit" />
      ) : (
        <CardContentsForHoverableCard
          isActive={isActive}
          isFeatured={false}
          isMenuOpen={isMenuOpen}
          title={title}
          body={body}
          type="kit"
        />
      )}
      {children}
    </Component>
  );
};

export default memo(KitCard);
