import { memo } from 'react';
import clsx from 'clsx';

import useStyles from './styles';
import { ImageSkeletonProps } from './types';

const ImageSkeleton = ({
  className,
  theme = 'secondary',
}: ImageSkeletonProps) => {
  const styles = useStyles();

  return (
    <div
      className={clsx(
        className,
        styles.image,
        theme === 'primary' && styles.imageDark
      )}
    />
  );
};

export default memo(ImageSkeleton);
