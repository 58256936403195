import { memo } from 'react';
import clsx from 'clsx';

import useStyles from './styles';
import { SplitContentProps } from './types';

const SplitContent = ({ className, children, left }: SplitContentProps) => {
  const styles = useStyles();

  return (
    <div className={clsx(className, styles.container)}>
      <div className={styles.left}>{left}</div>
      <div className={styles.content}>
        <div className={styles.contentInner}>{children}</div>
      </div>
    </div>
  );
};

export default memo(SplitContent);
