import { memo } from 'react';
import { Image, Spinner } from '@kitted/shared-components';
import { routes } from '@kitted/website-constants';

import Button from '../../../../../components/Button';
import useAccountSignup from './hooks/useAccountSignup';
import useStyles from './styles';
import { AccountSignupProps } from './types';

const AccountSignup = ({ onToggleOpen }: AccountSignupProps) => {
  const { isLoggingIn, isLoggedIn, isOnMMM } = useAccountSignup();
  const styles = useStyles();

  return (
    <div className={styles.container}>
      {isLoggingIn && <Spinner />}
      {!isLoggingIn && !isLoggedIn && (
        <>
          <Image
            className={styles.image}
            src="/assets/images/mmmondays/video-frame.webp"
          />
          {isOnMMM ? (
            <Button
              className={styles.button}
              onClick={onToggleOpen}
              fullWidth
              size="xs"
              variant="solid"
              theme="secondary"
            >
              Sign up for Mental Model Mondays
            </Button>
          ) : (
            <Button
              className={styles.button}
              to={routes.mentalModelMondays.path}
              fullWidth
              size="xs"
              variant="solid"
              theme="secondary"
            >
              Sign up for Mental Model Mondays
            </Button>
          )}
        </>
      )}
      {!isLoggingIn && isLoggedIn && (
        <Image className={styles.image} src="/assets/images/card-rain.webp" />
      )}
    </div>
  );
};

export default memo(AccountSignup);
