import { memo } from 'react';
import { Nekuodah } from '@kitted/shared-components';

import { providers } from './constants';
import { AppProvidersProps } from './types';

const AppProviders = ({ children }: AppProvidersProps) => (
  <Nekuodah components={providers}>{children}</Nekuodah>
);

export default memo(AppProviders);
