import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const KittedBrandmark: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M21.58 6.74V14H14V3.28" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m7.09 5.748 4.017-4.016a.956.956 0 0 1 1.344 0l6.908 6.908L14 14 7.091 7.092a.956.956 0 0 1 0-1.344" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M6.74 6.42H14V14H3.28" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m5.748 20.91-4.016-4.017a.956.956 0 0 1 0-1.343L8.64 8.64l5.36 5.36-6.909 6.908a.956.956 0 0 1-1.343 0" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M6.42 21.26V14H14v10.72" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m20.91 22.252-4.017 4.016a.956.956 0 0 1-1.343 0L8.64 19.36l5.36-5.36 6.908 6.908a.956.956 0 0 1 0 1.344" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M21.26 21.58H14V14h10.72" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m22.252 7.09 4.016 4.017a.956.956 0 0 1 0 1.344l-6.908 6.908L14 14l6.908-6.908a.956.956 0 0 1 1.344 0" />
    </g>
  </WebsiteIcon>
);

export default memo(KittedBrandmark);
