import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Whistle: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 26 22"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M20.45 7.12c.32-.56.96-.99 1.65-.99 1.03 0 1.86.83 1.86 1.86 0 .68-.37 1.28-.91 1.6" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M14.32 17.58c1.46 2.93 5.01 3.15 7.15 1.91 2.06-1.19 3.33-2.73 3.34-5.43.01-2.59-1.58-5.32-4.46-5.32 0 0-1.47-.02-3.5 1.12C14.83 11 5.43 16.28 5.43 16.28v3.26l4.56-2.42s3.25-1.71 4.34.46Z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m22.89 9.6-3.97-3.66c-.81-.75-1.92-1.09-3.06-1.09 0 0-1.53-.02-3.57 1.12l-11.1 6.27v3.16l4.24 4.15M1.19 12.26l4.24 4.03M9.35 7.92l3.83 3.7M11.95 6.28l4.09 3.83M9.35 4.94 8.31 3.11M7.49 6l-1.8-1.09M11.21 3.89l-.01-2.1" />
    </g>
  </WebsiteIcon>
);

export default memo(Whistle);
