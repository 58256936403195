import { memo } from 'react';
import { ImageSkeleton } from '@kitted/shared-components';

import useStyles from './styles';

const LoadingCatalogueItems = () => {
  const styles = useStyles();

  return (
    <>
      {Array.from({ length: 5 }, (_, index) => (
        <div key={index} className={styles.assetContainer}>
          <ImageSkeleton className={styles.assetImage} theme="secondary" />
        </div>
      ))}
    </>
  );
};

export default memo(LoadingCatalogueItems);
