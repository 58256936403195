import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0054: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 74 122"
		height={122}
		width={74}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M24.48 70.86s16.08 2.27 21.1-10.02c0 0 5.38 9.94 13.24 9.94M24.85 100.82v9.33c0 .36.67 3.99-22.98 10.01M49.15 100.82v9.33c0 .36-.67 3.99 22.98 10.01" /><path vectorEffect="non-scaling-stroke" d="M44.33 102.29c3.2-.77 4.84-2.2 4.84-2.2 5.43-3.85 8.79-15.94 9.81-24.41 1.49-12.48-.23-27.4-21.98-27.89-21.74.49-23.47 15.42-21.97 27.89 1.01 8.47 4.38 20.56 9.8 24.41 0 0 1.64 1.44 4.84 2.2" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m11.51 16.58 5.72 13.34v8.95h42.99v-8.95l9.02-24.46-17.5 11.32-13-14.94h-.03L25.72 16.78 8.22 5.46M59.87 30.37H25.3" /></g>
    </g>
  </CardIcon>
);

export default memo(In0054);
