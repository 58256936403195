import { EMPTY_OBJ } from '@kitted/shared-utils';

import createKeyValueStore from '../../../../services/keyValueStore';
import createStoreItemApi from './storeItemApi';
import {
  Store,
  StoreOfStoresApi,
  StoreSection,
  StoreSectionFetcherGetItemById,
  StoreSectionItem,
  StoreSectionKey,
} from './types';

const createStoreOfStores = (): StoreOfStoresApi => {
  let store = EMPTY_OBJ as Store;

  const getSection = <StoreItem>(key: StoreSectionKey) => {
    if (store[key]) {
      return store[key].kvs as StoreSection<StoreItem | undefined>['kvs'];
    }

    throw new Error(`[StoreOfStores] No store exists for section: ${key}`);
  };

  const getSectionItemFetcher = <StoreItem>(key: StoreSectionKey) => {
    if (store[key]) {
      return store[key].itemFetcher as StoreSection<
        StoreItem | undefined
      >['itemFetcher'];
    }
    return undefined;
  };

  const createOrUpdateStoreSection = <StoreItem>(
    key: StoreSectionKey,
    storeItemFetcher?: StoreSectionFetcherGetItemById<StoreItem>
  ) => {
    const kvs =
      store[key]?.kvs || createKeyValueStore<StoreSectionItem<StoreItem>>();
    store = {
      ...store,
      [key]: {
        kvs,
        itemFetcher: storeItemFetcher,
      },
    };
  };

  const setSection = <StoreItem>(
    key: StoreSectionKey,
    value: Record<string, StoreSectionItem<StoreItem | undefined>> | undefined
  ) => {
    const section = getSection<StoreItem>(key);

    if (!value) {
      section.resetStore();
      return;
    }
    Object.entries(value).forEach(([itemKey, itemValue]) => {
      section.setValueForKey(itemKey, itemValue);
    });
  };

  const {
    getDefaultStoreItem,
    fetchStoreItem,
    getStoreItem,
    getStoreItemKeyWhere,
    getStoreItemKeysWhere,
    setStoreItemItem,
    mergeStoreItemItem,
    setStoreItemState,
    deleteStoreItem,
    subscribeToItemChanges,
    subscribeToKeysChanges,
  } = createStoreItemApi({
    getSection,
    getSectionItemFetcher,
  });

  return {
    createOrUpdateStoreSection,
    setSection,
    getDefaultStoreItem,
    fetchStoreItem,
    getStoreItem,
    getStoreItemKeyWhere,
    getStoreItemKeysWhere,
    setStoreItemItem,
    mergeStoreItemItem,
    setStoreItemState,
    deleteStoreItem,
    subscribeToItemChanges,
    subscribeToKeysChanges,
  };
};

export default createStoreOfStores;
