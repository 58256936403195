/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';

import useIsLoggedIn from '../../contexts/UserContext/hooks/useIsLoggedIn';
import useIsLoggingIn from '../../contexts/UserContext/hooks/useIsLoggingIn';
import useAuthModal from '../useAuthModal';

const useLoginIsRequiredWrapper = (
  functionToFireIfAuthenticated: (...args: any[]) => any
): [(...args: any[]) => any, boolean] => {
  const { isLoggedIn } = useIsLoggedIn();
  const { isLoggingIn } = useIsLoggingIn();
  const { launchLoginModal } = useAuthModal();

  const executor = useMemo(() => {
    if (isLoggedIn) {
      return (...args: any[]) => functionToFireIfAuthenticated(...args);
    }
    return () => launchLoginModal();
  }, [isLoggedIn, launchLoginModal, functionToFireIfAuthenticated]);

  return [executor, isLoggingIn];
};

export default useLoginIsRequiredWrapper;
