import { AnyCardModel } from '@kitted/card-service-models';
import { SortPredicate } from '@kitted/shared-utils';

export type ReducedUserCardForSorting = Pick<
  AnyCardModel,
  'updatedAt' | 'slug' | 'id'
>;

export const userCardsSortOrder: SortPredicate<
  AnyCardModel | ReducedUserCardForSorting
>[] = [
  {
    colName: 'updatedAt',
    ascending: false,
  },
  {
    colName: 'slug',
    ascending: true,
  },
];
