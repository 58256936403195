/* eslint-disable @typescript-eslint/no-explicit-any */
import { NOOP } from '@kitted/shared-utils';

import { FormDataValue, ResetForm } from '../../../../contexts/FormContext';
import { FormProps } from '../../types';

export const generateHandleOnSubmit = async ({
  getFormData,
  onSubmit,
  resetForm,
  onSubmitSuccess = NOOP,
  onSubmitFail = NOOP,
}: {
  getFormData: () => FormDataValue;
  onSubmit: FormProps['onSubmit'];
  onSubmitSuccess: FormProps['onSubmitSuccess'];
  onSubmitFail: FormProps['onSubmitFail'];
  resetForm: ResetForm;
}) => {
  try {
    await onSubmit(getFormData());
    onSubmitSuccess(resetForm, getFormData());
  } catch (e: any) {
    onSubmitFail(e, resetForm);
    throw e;
  }
};
