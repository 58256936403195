import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0004: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 86 110"
		height={110}
		width={86}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M53.68 20.18V9.14M32.32 20.18V9.14M21.64 37.78l.01-28.64M21.65 89.4l-.01-29.14M21.65 50.95v-4.39M64.36 37.7v38.36M64.36 84.63v4.77M64.35 9.14l.01 20.14M43 9.14v5.98" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M49.44 30.43c0 3.56-2.89 6.44-6.44 6.44s-6.44-2.88-6.44-6.44 2.88-6.44 6.44-6.44 6.44 2.89 6.44 6.44M50.06 98.67l.61-25.2c2.36-.85 5.19-2.83 4.94-5.31l-1.93-19.17c-.25-2.49-2.5-4.52-5-4.52H37.33c-2.5 0-4.75 2.03-5 4.52L30.4 68.16c-.25 2.48 2.58 4.46 4.94 5.31l.61 25.2" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M12.64.68v98.15M73.36 99.36V.68" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M5.23 108.94v-6.62c0-1.63 1.33-2.96 2.96-2.96H77.8c1.63 0 2.96 1.33 2.96 2.96v6.62M.72 108.99h84.56" />
    </g>
  </CardIcon>
);

export default memo(Fr0004);
