import { z } from 'zod';
import {
  CardLoadedModelSchema,
  CardType,
  CardUnloadedModelSchema,
} from '@kitted/kitted-models';
import { DateISOSchema } from '@kitted/platform-models';

import { PublishSettingsSchema } from '../../PublishSettings';

const PlayCardBaseModelSchema = z.object({
  cardType: z.literal(CardType.Play),
  title: z.string(),
  description: z.string(),

  publishedAt: DateISOSchema.optional(),
  publishSettings: PublishSettingsSchema,
});

export const PlayCardLoadedModelSchema = CardLoadedModelSchema.merge(
  PlayCardBaseModelSchema
);
export const PlayCardUnloadedModelSchema = CardUnloadedModelSchema.merge(
  PlayCardBaseModelSchema
);

export type PlayCardLoadedModel = z.infer<typeof PlayCardLoadedModelSchema>;
export type PlayCardUnloadedModel = z.infer<typeof PlayCardUnloadedModelSchema>;

export type PlayCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? PlayCardLoadedModel : PlayCardUnloadedModel;
