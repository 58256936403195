import { useState } from 'react';

import useAuthTokensData from '../../../AuthTokensContext/hooks/useAuthTokensData';
import { LoginState } from '../../types';
import usePasswordUser from '../usePasswordUser';
import useSignInOutUpUser from '../useSignInOutUpUser';
import useUserChallenge from '../useUserChallenge';
import useVerifyUser from '../useVerifyUser';

const useUserManagement = () => {
  const { hasTokens } = useAuthTokensData();
  const [loginState, setLoginState] = useState<LoginState>(
    hasTokens ? LoginState.LOADING : LoginState.OUT
  );

  const { handleAuthenticateUserResponse, submitChallengeResponse } =
    useUserChallenge();
  const { registerUser, loginUser, logoutUser } = useSignInOutUpUser(
    handleAuthenticateUserResponse
  );
  const { verifyEmail, resendVerifyEmail } = useVerifyUser();
  const { forgotPassword, confirmForgotPassword } = usePasswordUser();

  return {
    loginState,
    setLoginState,
    logoutUser,
    registerUser,
    loginUser,
    verifyEmail,
    resendVerifyEmail,
    forgotPassword,
    confirmForgotPassword,
    submitChallengeResponse,
  };
};

export default useUserManagement;
