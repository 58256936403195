/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import { Typography } from '@kitted/shared-components';
import { EMPTY_OBJ } from '@kitted/shared-utils';
import { TriangleExclaim } from '@kitted/website-icons';

import AlertButtons from '../AlertButtons';
import useStyles from './styles';
import { DefaultAlertProps } from './types';

const DefaultAlertContent = ({
  preventClose,
  onClose,
  meta,
  title,
  bodyComponent: BodyComponent,
  bodyComponentProps = EMPTY_OBJ,
}: DefaultAlertProps) => {
  const styles = useStyles();

  return (
    <div className={styles.alert} onClick={preventClose}>
      <div className={styles.topBar}>
        <div className={styles.iconContainer}>
          <div className={styles.icon}>
            <TriangleExclaim size="lg" preserveAspectRatio="xMidYMin meet" />
          </div>
        </div>
        <Typography className={styles.title} variant="alertTitle" noMargin>
          {title}
        </Typography>
      </div>
      <div className={styles.body}>
        {BodyComponent && (
          <>
            <BodyComponent {...bodyComponentProps} />
            <div className={styles.divider} />
          </>
        )}
        <AlertButtons buttons={meta?.buttons} onClose={onClose} />
      </div>
    </div>
  );
};

export default memo(DefaultAlertContent);
