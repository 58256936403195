import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      backgroundColor: styling.colors.context.background,
      paddingBlock: styling.sizes.theme.spacing.md,
      paddingInline: styling.sizes.theme.spacing.sm,
      borderBottomLeftRadius: styling.sizes.theme.spacing['2xs'],
      borderBottomRightRadius: styling.sizes.theme.spacing['2xs'],
      margin: [
        0,
        -1 * styling.sizes.theme.spacing.sm,
        -1 * styling.sizes.theme.spacing.md,
      ],
      textAlign: 'left',
      [styling.container.context.drawer.desktopUpString]: {
        padding: styling.sizes.theme.spacing.md,
        margin: [
          0,
          -1 * styling.sizes.theme.spacing.md,
          -1 * styling.sizes.theme.spacing.md,
        ],
      },
    },
  },
  { name: 'panelFooter' }
);
