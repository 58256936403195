import { Dispatch, SetStateAction } from 'react';
import { EMPTY_OBJ } from '@kitted/shared-utils';

import {
  FormContextProps,
  FormDataSchema,
  FormDataValue,
  FormErrorsValue,
} from '../../types';

export const handleResetForm = ({
  shouldResetData,
  setFormData,
  bumpFormInstanceId,
  initialValues = EMPTY_OBJ as FormDataValue,
}: {
  shouldResetData: boolean;
  setFormData: Dispatch<SetStateAction<FormDataValue>>;
  bumpFormInstanceId: () => void;
  initialValues: FormContextProps['initialValues'];
}) => {
  if (shouldResetData) {
    setFormData(initialValues);
  }
  bumpFormInstanceId();
};

const validationCompareValue =
  (formData: FormDataValue) => (fieldKey: string) =>
    formData?.[fieldKey];

export const validateFields = (
  dataSchema: FormDataSchema,
  formData: FormDataValue,
  setErrors: Dispatch<SetStateAction<FormErrorsValue>>
) => {
  const fields = Object.entries(dataSchema.fields).map(([key, field]) => ({
    ...field,
    key,
  }));
  const errors = fields.reduce((acc, field) => {
    const validationResults = field.validation
      ?.map(({ validator }) =>
        validator(
          formData?.[field.key],
          field.type,
          validationCompareValue(formData)
        )
      )
      .filter((validationResult) => !!validationResult);
    if (validationResults?.length) {
      return {
        ...acc,
        [field.key]: validationResults,
      } as FormErrorsValue;
    }
    return acc;
  }, EMPTY_OBJ as FormErrorsValue);

  setErrors(errors);
};
