import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const ToolboxPlus: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 22"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M10.07 4.74V2.99c0-.66.54-1.2 1.2-1.2h5.47c.66 0 1.2.54 1.2 1.2v1.75M26.62 13.75V19c0 .66-.54 1.2-1.2 1.2H2.58c-.66 0-1.2-.54-1.2-1.2v-5.25M7.95 12.08H1.38V7.6c0-.66.54-1.2 1.2-1.2h22.84c.66 0 1.2.54 1.2 1.2v4.48h-6.57" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M17.32 12.08h-6.64M14 15.4V8.76" /></g>
    </g>
  </WebsiteIcon>
);

export default memo(ToolboxPlus);
