import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0036: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 118 96"
		height={96}
		width={118}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m13.81 76.95-6.19-6.18M24.12 76.31 13.11 65.29M24.77 65.99l-6.19-6.18M35.08 65.35 24.06 54.33M35.73 55.04l-6.19-6.18M52.29 60.78 23.27 89.8M18.9 94.17 1.42 76.69l33.66-33.66" /></g><g ><path vectorEffect="non-scaling-stroke" stroke={spot} d="M85.26 9.86c10.98 10.81 11.12 28.48.3 39.47s-28.48 11.11-39.46.29c-10.99-10.82-11.12-28.5-.32-39.47C56.6-.84 74.27-.97 85.26 9.85Z" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="m93.86 26.05 18.37.43c2.42.02 4.37 1.69 4.36 3.7-.02 2.01-2.01 3.64-4.42 3.62l-13.21-.12" /><g stroke={color}><path vectorEffect="non-scaling-stroke" d="m66.55 10.58 19.02 18.36M59.66 17.7l14.78 13.95M53.14 24.43l19.89 19.59M46.78 31l12.88 13.02" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="m44.06 33.8 22.49-23.22" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0036);
