import { WebsocketServerMessageType } from '@kitted/websocket-service-models';

import {
  KittedWebsocketModelUpdateSubscription,
  KittedWebsocketSubscription,
} from '../../../../services/websocket';
import { useSubscribeToAnyWebsocketServerMessage } from '../useSubscribeToAnyWebsocketServerMessage';

export const useSubscribeToWebsocketModelUpdateServerMessage = (
  callback: KittedWebsocketModelUpdateSubscription
) => {
  useSubscribeToAnyWebsocketServerMessage(
    callback as KittedWebsocketSubscription,
    WebsocketServerMessageType.ModelUpdate
  );
};
