import AuthorCardSkeleton from './AuthorCardSkeleton';
import SubjectCardSkeleton from './SubjectCardSkeleton';
import ToolCardSkeleton from './ToolCardSkeleton';
import { CardSkeletonType } from './types';

export const cardTypeToComponentMap: {
  [key in CardSkeletonType]: React.ElementType;
} = {
  tool: ToolCardSkeleton as React.ElementType,
  author: AuthorCardSkeleton as React.ElementType,
  subject: SubjectCardSkeleton as React.ElementType,
};
