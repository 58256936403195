import { isTokenWithinExpiry } from '../../contexts/AuthTokensContext/logic';
import { AuthTokensApi } from '../../contexts/AuthTokensContext/types';
import { RequestFn } from '../../hooks/useAsyncRequest/types';

export const executeTokenRefresh =
  (getTokens: AuthTokensApi['getTokens'], requestTokenRefresh: RequestFn) =>
  (now: number) => {
    const currentTokens = getTokens();
    if (currentTokens?.expiresAt) {
      if (isTokenWithinExpiry(currentTokens.expiresAt, now)) {
        requestTokenRefresh(currentTokens);
      }
    }
  };
