import { constructPublicRoute } from '../helpers';
import { betaPageTitles } from '../routeTitles';

export const betaInviteRoutes = {
  betaInvite: constructPublicRoute('/beta-invite', 'Beta Invite'),
  betaInviteWithCode: constructPublicRoute('/beta-invite/:inviteCode', [
    betaPageTitles,
    'Beta Invite',
  ]),
} as const;
