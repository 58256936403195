import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    title: {
      paddingInline: 'var(--cardSkeletonPaddingInline)',
      flexGrow: 0,
    },
    divider: {
      width: 'calc(100% - 72px)',
      opacity: 0.2,
    },
    dividerFeatured: {
      width:
        'calc(100% - var(--cardSkeletonPaddingInline) - var(--cardSkeletonPaddingInline))',
      left: 0,
      right: 0,
    },
  },
  { name: 'subjectCardSkeleton' }
);
