import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0060: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 102 114"
		height={114}
		width={102}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M75.55 36.78c-6 1.51-17.73 1.1-32.67-7.96C27.95 19.75 18.25 8.43 21.22 3.54 23.92-.9 36.1 1.47 49.48 8.71" /><path vectorEffect="non-scaling-stroke" d="M71.8 31.31c3.1-3.1 8.14-3.11 11.25 0a7.95 7.95 0 0 1 0 11.25 7.95 7.95 0 0 1-10.4.74" /><path vectorEffect="non-scaling-stroke" d="M41.13 28.69 1.66 68.15l44.55 44.56 54.13-54.14-.45-26.91-17.18-17.18-26.92-.46-8.6 8.61" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="m52.8 42.4 4.12 12.7h13.36l-10.81 7.85 4.13 12.71-10.8-7.85-10.81 7.85 4.13-12.71-10.81-7.85h13.36z" />
    </g>
  </CardIcon>
);

export default memo(Ac0060);
