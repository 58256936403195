import { CardType, CardTypeValues } from '@kitted/kitted-models';

import { TypographyMaxLines } from '../../Typography/types';

export const cardTypeToMaxLinesMap: {
  [key in CardTypeValues]: {
    default: TypographyMaxLines;
    featured: TypographyMaxLines;
  };
} = {
  [CardType.Article]: { default: 4, featured: 3 },
  [CardType.Author]: { default: 2, featured: 3 },
  [CardType.Deck]: { default: 4, featured: 3 },
  [CardType.Display]: { default: 4, featured: 3 },
  [CardType.Flow]: { default: 4, featured: 3 },
  [CardType.Kit]: { default: 4, featured: 3 },
  [CardType.Play]: { default: 4, featured: 3 },
  [CardType.Resource]: { default: 4, featured: 3 },
  [CardType.Subject]: { default: 4, featured: 3 },
  [CardType.Tool]: { default: 3, featured: 3 },
};

export const cardTypeToPrefixMap: {
  [key in CardTypeValues]: string | null;
} = {
  [CardType.Article]: null,
  [CardType.Author]: null,
  [CardType.Deck]: null,
  [CardType.Display]: null,
  [CardType.Flow]: null,
  [CardType.Kit]: null,
  [CardType.Play]: null,
  [CardType.Resource]: null,
  [CardType.Subject]: '#',
  [CardType.Tool]: null,
};
