import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    '@keyframes stepIn': {
      ...styling.animations.context.stepIn.keyframes,
    },
    container: {
      overflow: 'hidden',
      display: 'inline-block',
      '& > span': {
        animationName: '$stepIn',
        ...styling.animations.context.stepIn.useage,
      },
    },
  },
  { name: 'ellipsisDots' }
);
