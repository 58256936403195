/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';

import ExternalLinkWithCrossDomainDistinctId from '../ExternalLinkWithCrossDomainDistinctId';
import useExternalLinkWithCapturedQueryParams from './hooks/useExternalLinkWithCapturedQueryParams';
import { ExternalLinkWithCapturedQueryParamsProps } from './types';

const ExternalLinkWithCapturedQueryParams = ({
  children,
  href,
  ...props
}: ExternalLinkWithCapturedQueryParamsProps) => {
  const { hrefWithQueryParams } = useExternalLinkWithCapturedQueryParams(href);

  return (
    <ExternalLinkWithCrossDomainDistinctId
      {...props}
      href={hrefWithQueryParams}
    >
      {children}
    </ExternalLinkWithCrossDomainDistinctId>
  );
};

export default memo(ExternalLinkWithCapturedQueryParams);
