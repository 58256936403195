import { AnalyticsApi } from '../../../../contexts/AnalyticsContext';
import { CROSS_DOMAIN_DISTINCT_ID_KEY } from '../../constants';
import { ExternalLinkWithCrossDomainDistinctIdProps } from '../../types';

export const getHrefWithCrossDomainDistinctId = (
  getDistinctId: AnalyticsApi['getDistinctId'],
  href: ExternalLinkWithCrossDomainDistinctIdProps['href']
) => {
  const distinctId = getDistinctId();
  let url;
  try {
    url = new URL(href);
  } catch (e) {
    return href;
  }
  if (!distinctId) {
    return href;
  }
  if (url.searchParams.has(CROSS_DOMAIN_DISTINCT_ID_KEY)) {
    return href;
  }
  url.searchParams.append(CROSS_DOMAIN_DISTINCT_ID_KEY, distinctId);
  return url.toString();
};
