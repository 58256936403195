import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0031: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 134 100"
		height={100}
		width={134}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="M131.83 71.57c-4.13 4.19-9.7 6.68-15.85 5.45-6.1-1.23-10.94-5.65-15.51-9.82s-9.59-8.46-15.76-9.32c-8-1.11-15.27 4.34-17.71 11.63-2.44-7.29-9.71-12.74-17.71-11.63-6.17.86-11.19 5.15-15.76 9.32s-9.41 8.59-15.51 9.82c-6.15 1.24-11.73-1.25-15.85-5.45-.38-.38-1.02-.02-.89.5C3.26 80.23 8.4 87.76 15.5 92.39c8.15 5.32 18.44 6.73 28.16 5.67 5.1-.56 10.21-1.79 14.58-4.43 4.27-2.57 7.27-6.45 8.76-10.85 1.49 4.4 4.49 8.27 8.76 10.85 4.37 2.64 9.49 3.87 14.58 4.43 9.72 1.07 20.01-.34 28.16-5.67 7.1-4.64 12.24-12.17 14.22-20.32.13-.52-.51-.88-.89-.5" /><g stroke={color} ><circle vectorEffect="non-scaling-stroke" cx="34.83" cy="22.94" r="21.34" /><circle vectorEffect="non-scaling-stroke" cx="99.17" cy="22.94" r="21.34" /><path vectorEffect="non-scaling-stroke" d="M53.17 12.03C57.2 9.57 61.94 8.15 67 8.15s9.8 1.42 13.83 3.88" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0031);
