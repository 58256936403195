import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0019: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 92 100"
		height={100}
		width={92}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m65.72 71.2 15.96-8.16c3.08-1.66 6.7-.99 8.19 1.78 1.49 2.78.18 6.4-2.9 8.06L53.86 90.63l-1.75.44-30.88.84s-3.98 1.11-8.75 6.53" /><path vectorEffect="non-scaling-stroke" d="m1.51 82.2 14.01-12.89c6.85-4.62 14.96-2.07 14.96-2.07 3.93 1.03 11.23 1.27 11.23 1.27h12.12c3.61 0 6.56 2.58 6.56 5.73s-2.95 5.73-6.56 5.73H37.07" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M59.43 21.8a8.71 8.71 0 0 1 3.44 7.36 8.74 8.74 0 0 1-3.54 6.63s-8.7 8.16-28.39 6.25l.31-.27C52.05 23.36 54.91 6.69 54.91 6.69c1.53-5.73 8.5-5.1 8.5-5.1 5.95 0 7.53 4.65 7.73 7.79l.02.63 8.33 3.55-8.02 4.57.41 5.43c4.71 37.4-41.61 27.67-41.61 27.67l-8.88 6.24-9.32-1.44 5.94-4.12a129 129 0 0 0 8.01-5.76M51.55 51.94l4.58 11.57M44.04 52.51l4.35 10.89" /><path vectorEffect="non-scaling-stroke" d="M63.34 11.05c0 .45.36.81.81.81s.81-.36.81-.81-.36-.81-.81-.81-.81.36-.81.81" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0019);
