import { useCallback, useEffect } from 'react';
import { ArticleMetadataModel } from '@kitted/article-service-models';
import { FlowMetadataModel } from '@kitted/flow-service-models';
import { KitMetadataModel } from '@kitted/kit-service-models';
import { KittedServiceEnum } from '@kitted/kitted-models';
import { Nullable } from '@kitted/platform-models';
import { ResourceMetadataModel } from '@kitted/resource-service-models';

import useArticleRequests from '../../../../../../hooks/articles/useArticleRequests';
import useFlowRequests from '../../../../../../hooks/flows/useFlowRequests';
import useKitRequests from '../../../../../../hooks/kits/useKitRequests';
import { METADATA_KEY_SEPARATOR } from '../../../../../../hooks/metadata/useMetadata/constants';
import useStoreApi from '../../../../hooks/useStoreApi';
import { StoreSectionInitializerProps } from '../../../types';

const useMetadataStoreInitializer = (
  onSectionLoaded: StoreSectionInitializerProps['onSectionLoaded']
) => {
  const { getArticleMetadata } = useArticleRequests();
  const { getFlowMetadata } = useFlowRequests();
  const { getKitMetadata } = useKitRequests();
  const { createOrUpdateStoreSection } = useStoreApi();

  const getMetadata = useCallback(
    (itemKey: string) => {
      const [model, itemId] = itemKey.split(METADATA_KEY_SEPARATOR);
      switch (model as KittedServiceEnum) {
        case KittedServiceEnum.Article:
          return getArticleMetadata(itemId) as Promise<
            Nullable<ArticleMetadataModel>
          >;
        case KittedServiceEnum.Flow:
          return getFlowMetadata(itemId) as Promise<
            Nullable<FlowMetadataModel>
          >;
        case KittedServiceEnum.Kit:
          return getKitMetadata(itemId) as Promise<Nullable<KitMetadataModel>>;
        case KittedServiceEnum.Resource:
          return Promise.resolve({ isCloneable: true }) as Promise<
            Nullable<ResourceMetadataModel>
          >;
        default:
          return Promise.resolve(null);
      }
    },
    [getArticleMetadata, getFlowMetadata, getKitMetadata]
  );

  const updateKitsStoreSectionFetcher = useCallback(() => {
    createOrUpdateStoreSection('Metadata', getMetadata);
    onSectionLoaded('Metadata');
  }, [createOrUpdateStoreSection, getMetadata, onSectionLoaded]);

  useEffect(updateKitsStoreSectionFetcher, [updateKitsStoreSectionFetcher]);
};

export default useMetadataStoreInitializer;
