export type AnalyticsContextProps = {
  children: React.ReactNode;
};

export enum AnalyticsEvents {
  SignUp = 'Sign Up',
}

export type AnalyticsApi = {
  trackEvent: (
    event: AnalyticsEvents,
    eventProperties?: Record<string, unknown>
  ) => void;
  identifyUser: (
    userId: string | undefined,
    userInfo?: Record<string, string>
  ) => void;
  getDistinctId: () => string;
};
