/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';
import { Plus } from '@kitted/website-icons';

import Divider from '../../Divider';
import CardContentsForHoverableCard from '../CardContentsForHoverableCard';
import CardSlug from '../CardSlug';
import useExtractInteractionProps from '../hooks/useExtractInteractionProps';
import useStyles from './styles';
import { PolymorphicAddCardProps } from './types';

const AddCard = <C extends React.ElementType = 'div'>({
  as,
  body,
  children,
  className,
  image,
  isActive,
  isFeatured,
  isMenuOpen,
  label,
  title,
  ...rest
}: PolymorphicAddCardProps<C>) => {
  const interactionProps = useExtractInteractionProps(rest);
  const styles = useStyles();
  const Component = as || 'div';

  return (
    <Component
      className={clsx(
        className,
        styles.container,
        isActive && styles.containerActive,
        isFeatured && styles.containerFeatured,
        isMenuOpen && styles.containerMenuOpen
      )}
      {...interactionProps}
    >
      <div className={styles.plus}>
        <Plus />
      </div>
      <CardContentsForHoverableCard
        isActive={isActive}
        isFeatured={isFeatured}
        isMenuOpen={isMenuOpen}
        title={title}
        body={body}
        type="resource"
      />
      {isFeatured && (
        <Divider className={styles.divider} spacing="md" theme="secondary" />
      )}
      <CardSlug className={styles.cardSlug} isFeatured={isFeatured} noPrefix>
        {label}
      </CardSlug>
      {children}
    </Component>
  );
};

export default memo(AddCard);
