import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Folder: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 22"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M23.88 4.28H11.14L9.16 1.54H3.01c-.69 0-1.25.57-1.25 1.26v16.33c0 .73.56 1.33 1.25 1.33s1.26-.6 1.26-1.33V6.86h21.97v13.6H3.05" />
    </g>
  </WebsiteIcon>
);

export default memo(Folder);
