import breakpoints from './breakpoints';
import utils from './utils';

const drawerContainerName = 'drawer-space';

const containerQuery = (
  direction: 'width' | 'height',
  min: number | string,
  max?: number | string,
  containerName?: string
) =>
  `@container${
    containerName ? ` ${containerName}` : ''
  } ((min-${direction}: ${utils.maybePx(min)})${
    max ? ` and (max-${direction}: ${utils.maybePx(max)}))` : ')'
  }`;

export default {
  context: {
    drawer: {
      name: drawerContainerName,
      mobileOnlyString: containerQuery(
        'width',
        0,
        breakpoints.theme.sm,
        drawerContainerName
      ),
      tabletUpString: containerQuery(
        'width',
        breakpoints.theme.sm,
        undefined,
        drawerContainerName
      ),
      desktopUpString: containerQuery(
        'width',
        breakpoints.theme.md,
        undefined,
        drawerContainerName
      ),
      largeUpString: containerQuery(
        'width',
        breakpoints.theme.lg,
        undefined,
        drawerContainerName
      ),
      extraLargeUpString: containerQuery(
        'width',
        breakpoints.theme.xl,
        undefined,
        drawerContainerName
      ),
    },
  },
  utils: {
    containerQuery,
  },
};
