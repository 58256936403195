import { ResourceCardTypeValues } from '@kitted/card-service-models';

import { CardProps } from '../types';

export const getDisplayedTitle = (
  subType: ResourceCardTypeValues,
  title: CardProps['title'],
  showTitle: CardProps['showTitle']
) => {
  if (subType !== 'image') return title;
  return showTitle ? title : undefined;
};

export const shouldDisplayCardImageHalfHeight = (
  subType: ResourceCardTypeValues,
  _isFeatured = false
) => {
  if (subType === 'image') return false;
  return true;
};
