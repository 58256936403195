import { useCallback } from 'react';
import { FavoriteModel } from '@kitted/account-service-models';
import { KittedServiceEnum } from '@kitted/kitted-models';
import { EMPTY_ARR, NOOP } from '@kitted/shared-utils';

import useRequests from '../../../contexts/RequestsContext/hooks/useRequests';
import logger from '../../../services/logger';
import useAuthedAsyncRequest from '../../useAuthedAsyncRequest';

const useFavoritesRequests = () => {
  const { platformRequest } = useRequests();
  const falseFavoritesRequest = useCallback(async () => {
    logger.log('[favorites requests] attempted request to all favorites');
    return EMPTY_ARR;
  }, []);
  const [requestFavorites, requestFavoritesState] = useAuthedAsyncRequest<
    FavoriteModel[],
    FavoriteModel[]
  >(
    () =>
      platformRequest(KittedServiceEnum.Account, {
        method: 'GET',
        url: '/v1/favorites',
      }),
    NOOP,
    falseFavoritesRequest
  );

  return {
    requestFavorites,
    requestFavoritesState,
  };
};

export default useFavoritesRequests;
