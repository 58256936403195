import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0034: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 102 106"
		height={106}
		width={102}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="m1.8 78.35 16.11-17.29a4.163 4.163 0 0 1 5.87-.18l.46.44c1.66 1.56 1.75 4.2.18 5.87l-7.29 7.85 9.88-.36 20.4-.74c2.28-.08 4.21 1.72 4.3 4v.35c.09 2.28-1.71 4.22-3.98 4.3l-15.58.57-2.05 16s-.85 5.22-7.65 5.47l-19.07-.83M100.2 27.65 84.09 44.94a4.163 4.163 0 0 1-5.87.18l-.46-.44a4.163 4.163 0 0 1-.18-5.87l7.29-7.85-9.88.36-20.4.74c-2.28.08-4.21-1.72-4.3-4v-.35c-.09-2.28 1.71-4.22 3.98-4.3l15.58-.57 2.05-16s.85-5.22 7.65-5.47l19.07.83" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m33 62.87 12.68-13.56L58.5 62.83" /><path vectorEffect="non-scaling-stroke" d="M32.89 38.35h37.68v29.42H32.89z" /><path vectorEffect="non-scaling-stroke" d="m57.56 57.65 4.33-4.61 8.67 9.26M60.6 46.25c0 1.72-1.4 3.12-3.12 3.12s-3.12-1.4-3.12-3.12 1.4-3.12 3.12-3.12 3.12 1.4 3.12 3.12" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0034);
