import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0004: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 112 124"
		height={124}
		width={112}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M80.69 53.94 67.97 41.22a5.136 5.136 0 0 0-7.27 0l-6.57 6.57c-6.81-2.56-14.5-3.36-22.58-1.41-15.77 3.79-27.73 17.4-29.32 33.54-2.44 24.66 18.38 45.17 43.13 42.19 16.6-1.99 30.24-14.93 33.17-31.4 1.54-8.69.07-16.89-3.41-23.93l5.58-5.58a5.136 5.136 0 0 0 0-7.27Z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M54.13 47.79s1.76 18.43 20.98 19M73.99 47.86s.21-15.42 10.28-17.99" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M84.27 42.72 99.69 53l-2.57-15.42 12.85-2.57-10.28-7.71 7.71-12.85-15.42 2.57L84.27 1.6l-5.14 15.42H61.14l7.71 12.85" />
    </g>
  </CardIcon>
);

export default memo(In0004);
