import { stringify } from 'telejson';
import { AnyWebsocketClientMessage } from '@kitted/websocket-service-models';

import { WebsocketApi } from '../WebsocketApi';

export const sendAnyWebsocketClientMessage = (
  websocketService: WebsocketApi,
  payload: AnyWebsocketClientMessage
) => {
  websocketService?.send(stringify(payload));
};
