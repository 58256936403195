import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0050: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 104 100"
		height={100}
		width={104}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="M8.26 29.3S-.91 18.55 2.65 5.28c0 0 2.06 6.45 14.07 11.65M95.74 29.3s9.17-10.75 5.62-24.02c0 0-2.06 6.45-14.07 11.65" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M26.26 59.97c4 10.29 13.98 17.6 25.68 17.6 5.53 0 10.68-1.63 14.99-4.44" /><circle vectorEffect="non-scaling-stroke" cx="37.99" cy="40.86" r="4.08" stroke={color} /><path vectorEffect="non-scaling-stroke" stroke={color} d="m27.04 34.09 16.39 5.38" /><circle vectorEffect="non-scaling-stroke" cx="65.9" cy="40.86" r="4.08" stroke={color} /><path vectorEffect="non-scaling-stroke" stroke={color} d="m76.85 34.09-16.39 5.38M83.43 13.38C74.97 6.1 63.98 1.67 51.94 1.67S28.92 6.1 20.46 13.38M6.4 34.09C4.65 39.08 3.61 44.41 3.61 50c0 26.69 21.64 48.33 48.33 48.33S100.27 76.69 100.27 50c0-5.59-1.03-10.92-2.79-15.91" />
    </g>
  </CardIcon>
);

export default memo(Fr0050);
