/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { EMPTY_PROMISE } from '@kitted/shared-utils';

import useAuthTokensData from '../../contexts/AuthTokensContext/hooks/useAuthTokensData';
import useAsyncRequest from '../useAsyncRequest';
import {
  AsyncRequestState,
  RequestFn,
  SuccessCallback,
} from '../useAsyncRequest/types';
import { createDeferredPromise } from './logic';
import { AuthedOrNonAuthedRequestFn } from './types';

const useAuthedAsyncRequest = <ResponseType, NonAuthedResponseType = undefined>(
  requestFunction: RequestFn<ResponseType>,
  successCallback?: SuccessCallback<ResponseType | undefined>,
  nonAuthedPromise: RequestFn<NonAuthedResponseType> = EMPTY_PROMISE
): [
  AuthedOrNonAuthedRequestFn<ResponseType, NonAuthedResponseType>,
  AsyncRequestState,
] => {
  const { hasTokens, tokenRefreshState } = useAuthTokensData();
  const [request, requestState] = useAsyncRequest<ResponseType>(
    requestFunction,
    successCallback
  );
  const tokenRefreshStateRef = useRef(tokenRefreshState);

  const deferred = useRef(
    createDeferredPromise<ResponseType, NonAuthedResponseType>()
  );

  const emptyPromiseWhenUnauthedRequest: AuthedOrNonAuthedRequestFn<
    ResponseType,
    NonAuthedResponseType
  > = useMemo(() => {
    if (hasTokens) {
      return request;
    }
    return nonAuthedPromise;
  }, [hasTokens, request, nonAuthedPromise]);

  useEffect(() => {
    tokenRefreshStateRef.current = tokenRefreshState;

    if (tokenRefreshState === AsyncRequestState.Loading) {
      deferred.current = createDeferredPromise();
    }

    if (tokenRefreshState === AsyncRequestState.Success && deferred.current) {
      deferred.current.resolve(emptyPromiseWhenUnauthedRequest);
    }

    if (tokenRefreshState === AsyncRequestState.Error && deferred.current) {
      deferred.current.resolve(nonAuthedPromise);
    }
  }, [tokenRefreshState, emptyPromiseWhenUnauthedRequest, nonAuthedPromise]);

  const execute = useCallback(
    (...args: any[]) => {
      const currentTokenRefreshState = tokenRefreshStateRef.current;
      if (currentTokenRefreshState === AsyncRequestState.Loading) {
        return deferred.current.promise.then(async (fn: any) => fn(...args));
      }
      return emptyPromiseWhenUnauthedRequest(...args);
    },
    [emptyPromiseWhenUnauthedRequest]
  );

  return [execute, requestState];
};

export default useAuthedAsyncRequest;
