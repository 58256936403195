import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0049: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 108 108"
		height={108}
		width={108}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m19.17 72.59-8.25 8.25M27.3 80.72 8.11 99.9M35.42 88.84l-8.64 8.64" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M79.25 43.64c-4.11 4.11-10.77 4.11-14.88 0s-4.11-10.77 0-14.88 10.77-4.11 14.88 0 4.1 10.77 0 14.88M79.09 6.05l22.86 22.86" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M106.07 1.91c-5.94-1.48-43.14-7.42-80.83 63.1L43 82.77c70.74-37.8 64.53-75.09 63.07-80.86" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M34.4 33c-8.34 1-21.48 5.35-33.26 22.86 0 0 6.94-3.18 19.09-.93M75 73.61c-1 8.34-5.35 21.49-22.86 33.26 0 0 3.18-6.94.93-19.09" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0049);
