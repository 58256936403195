import { PropsWithAs } from '@kitted/shared-components';

import { AlertContextApi } from '../../contexts/AlertContext/types';
import { DefaultAlert, DefaultAlertProps } from './DefaultAlertContent/types';
import DefaultAlertContent from './DefaultAlertContent';
import { AlertContentProps, AlertProps } from './types';

export const handleOnClose = (
  onClose: AlertContextApi['closeAlert'],
  setHasRequestedClose: (requestClose: boolean) => void
) => {
  setHasRequestedClose(true);
  onClose();
};

export const handleOnExited = (
  id: AlertProps['id'],
  deregisterAlert: AlertContextApi['deregisterAlert'],
  hasTopAlert: AlertProps['hasTopAlert'],
  requestedClose: boolean
) => {
  if (hasTopAlert && !requestedClose) return;
  deregisterAlert(id);
};

export const getAlertContentComponent = (
  props: AlertProps,
  onClose: AlertContentProps['onClose'],
  preventClose: AlertContentProps['preventClose']
) => {
  const { variant } = props;
  switch (variant) {
    case 'default':
    default: {
      const alertProps = props as DefaultAlert;

      return {
        as: DefaultAlertContent,
        onClose,
        preventClose,
        ...alertProps,
      } as PropsWithAs<DefaultAlertProps>;
    }
  }
};
