import { memo } from 'react';
import clsx from 'clsx';
import { MediaPlayer, Spinner } from '@kitted/shared-components';

import LoadWhenInView from '../LoadWhenInView';
import useVideoEmbed from './hooks/useVideoEmbed';
import useStyles from './styles';
import { VideoEmbedProps } from './types';

const VideoEmbed = ({
  className,
  videoAttrs,
  initialState,
  loadingTheme = 'primary',
}: VideoEmbedProps) => {
  const { player, id, url, fullbleed } = videoAttrs;
  const styles = useStyles();
  const { playerConfig, initialPlayerState, playerSource, playerType } =
    useVideoEmbed(player, id, url, fullbleed, initialState);

  return (
    <div
      className={clsx(
        styles.videoWrapper,
        player === 'video' && styles.videoWrapperVideo,
        player === 'video' && fullbleed && styles.videoWrapperFullbleed,
        className
      )}
    >
      <div className={styles.videoInner}>
        <div className={styles.loading}>
          <Spinner theme={loadingTheme} />
        </div>
        <LoadWhenInView className={styles.videoPlayer}>
          {playerSource && (
            <MediaPlayer
              additionalConfig={playerConfig}
              initialState={initialPlayerState}
              isClickable={player === 'video'}
              sources={playerSource}
              loop={fullbleed}
              playerType={playerType}
            />
          )}
        </LoadWhenInView>
      </div>
    </div>
  );
};

export default memo(VideoEmbed);
