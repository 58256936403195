import { useEffect } from 'react';
import { InternalLinkProps } from '@kitted/shared-components';

import { useRoutePreloadApi } from '../../../../contexts/RoutePreloadContext';

const usePreloadingInternalLink = (to: InternalLinkProps['to']) => {
  const { preloadPath } = useRoutePreloadApi();

  useEffect(() => {
    if (to) {
      preloadPath(to.toString());
    }
  }, [preloadPath, to]);
};

export default usePreloadingInternalLink;
