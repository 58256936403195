import { useCallback, useEffect } from 'react';
import { KittedServiceEnum } from '@kitted/kitted-models';
import { PlayModel } from '@kitted/play-service-models';
import { WebsocketModelUpdateServerMessage } from '@kitted/websocket-service-models';

import usePlayRequests from '../../../../../../hooks/plays/usePlayRequests';
import { useSubscribeToWebsocketModelUpdateServerMessage } from '../../../../../WebsocketContext';
import useStoreApi from '../../../../hooks/useStoreApi';
import useStoreSection from '../../../../hooks/useStoreSection';
import { StoreSectionInitializerProps } from '../../../types';

const usePlayStoreInitializer = (
  onSectionLoaded: StoreSectionInitializerProps['onSectionLoaded']
) => {
  const { getPlay } = usePlayRequests();
  const { createOrUpdateStoreSection } = useStoreApi();
  const { updateStoreSectionItem } = useStoreSection('Plays');

  const updatePlaysStoreSectionFetcher = useCallback(() => {
    createOrUpdateStoreSection('Plays', getPlay);
    onSectionLoaded('Plays');
  }, [createOrUpdateStoreSection, getPlay, onSectionLoaded]);

  useEffect(updatePlaysStoreSectionFetcher, [updatePlaysStoreSectionFetcher]);

  const handleWebsocketItemUpdated = useCallback(
    ({ model, service }: WebsocketModelUpdateServerMessage) => {
      if (model && service === KittedServiceEnum.Play) {
        const asset = model as PlayModel<false>;
        updateStoreSectionItem(asset, asset.id);
      }
    },
    [updateStoreSectionItem]
  );

  useSubscribeToWebsocketModelUpdateServerMessage(handleWebsocketItemUpdated);
};

export default usePlayStoreInitializer;
