import { useMemo } from 'react';

import { useRoutePreloadManagement } from './hooks';
import PreloadRoutes from './PreloadRoutes';
import { RoutePreloadContext } from './RoutePreloadContext';
import { RoutePreloadContextProps } from './types';

export const RoutePreloadProvider = ({
  children,
}: RoutePreloadContextProps) => {
  const { preloadRouteKeys, preloadPath } = useRoutePreloadManagement();
  const apiValue = useMemo(() => ({ preloadPath }), [preloadPath]);

  return (
    <RoutePreloadContext.Provider value={apiValue}>
      {children}
      <PreloadRoutes routeKeys={preloadRouteKeys} />
    </RoutePreloadContext.Provider>
  );
};
