import { useMemo } from 'react';

import { getCardIconByCode } from '../../logic';

const useCardIcon = (slug: string) => {
  const Component = useMemo(() => getCardIconByCode(slug), [slug]);

  return {
    Component,
  };
};

export default useCardIcon;
