import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    wrapper: {
      padding: styling.sizes.theme.spacing.lg,
    },
    imageGrid: {
      display: 'grid', // Enable CSS Grid
      gridTemplateColumns: `repeat(auto-fill, ${styling.utils.px(
        styling.sizes.context.tile.width
      )})`, // Create as many columns as possible with at least 100px width
      gap: styling.sizes.context.gutteringSmW,
      justifyContent: 'center',
    },
  },
  { name: 'catalogueItemGrid' }
);
