import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0045: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 140 112"
		height={112}
		width={140}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M122.88 46.9s-8.15-13.49-26.99-3.82" /><path vectorEffect="non-scaling-stroke" d="M103.2 40.13s-5.42-11.17-19.78-5.96M92.41 56.76s-3.47 3.54-6.2 9.49" /><path vectorEffect="non-scaling-stroke" d="M90.78 59.63c20.12 12.99 47.38-2.54 47.38-2.54 2.04-29.54-12.07-50.28-12.07-50.28s-3.12-4.98-16.11-4.82c0 0-6.45 3.33-5.26 10.96l1.82 2.48 9.63-1.12s1.51-4.21 2.31-5.38" /><path vectorEffect="non-scaling-stroke" d="M117.39 11.36s4.71 3.77 5.47 8.42l.07.3c.44 2.44.95 6.1.46 7.73-.59 1.98-3.02 5.33-3.97 9.95" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M44.11 43.08C25.27 33.4 17.12 46.9 17.12 46.9" /><path vectorEffect="non-scaling-stroke" d="M56.59 34.17c-14.36-5.21-19.78 5.96-19.78 5.96M53.79 66.25c-2.72-5.95-6.2-9.49-6.2-9.49" /><path vectorEffect="non-scaling-stroke" d="M21.51 8.92c.8 1.17 2.31 5.38 2.31 5.38l9.63 1.12 1.82-2.48c1.19-7.63-5.26-10.96-5.26-10.96C17.02 1.82 13.9 6.8 13.9 6.8c.01 0-14.1 20.75-12.06 50.28 0 0 27.26 15.53 47.38 2.54" /><path vectorEffect="non-scaling-stroke" d="M20.58 37.75c-.94-4.61-3.37-7.97-3.97-9.95-.49-1.62.03-5.29.46-7.73l.07-.3c.76-4.64 5.47-8.42 5.47-8.42" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M69.59 87.92V34.05M69.62 95.8c-10.83 0-20.34 5.68-25.7 14.22l51.35-.09C89.9 101.44 80.42 95.8 69.63 95.8Z" /></g><circle vectorEffect="non-scaling-stroke" cx="70" cy="16.27" r="9.98" stroke={spot} />
    </g>
  </CardIcon>
);

export default memo(In0045);
