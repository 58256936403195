import { constructPublicRoute, interpolateRoutePath } from './helpers';
import { routes } from './internalRoutes';

export const discoveryMagicAbout = constructPublicRoute(
  interpolateRoutePath(routes.articleAuthorTitleCode, {
    authorSlug: 'kitted',
    titleSlug: 'the-story-of-kitted',
    code: '0051',
  }),
  'About',
  undefined,
  routes.articleAuthorTitleCode.path
);

export const discoverMagicActions = constructPublicRoute(
  interpolateRoutePath(routes.subjectTitleCode, {
    titleSlug: 'actions',
    code: '0001',
  }),
  'Actions',
  undefined,
  routes.subjectTitleCode.path
);

export const discoverMagicInsights = constructPublicRoute(
  interpolateRoutePath(routes.subjectTitleCode, {
    titleSlug: 'insights',
    code: '0002',
  }),
  'Insights',
  undefined,
  routes.subjectTitleCode.path
);

export const discoverMagicFrames = constructPublicRoute(
  interpolateRoutePath(routes.subjectTitleCode, {
    titleSlug: 'frames',
    code: '0003',
  }),
  'Frames',
  undefined,
  routes.subjectTitleCode.path
);

export const discoverMagicSnares = constructPublicRoute(
  interpolateRoutePath(routes.subjectTitleCode, {
    titleSlug: 'snares',
    code: '0004',
  }),
  'Snares',
  undefined,
  routes.subjectTitleCode.path
);

export const discoverMagicSubjects = constructPublicRoute(
  interpolateRoutePath(routes.subjectTitleCode, {
    titleSlug: 'subjects',
    code: '0000',
  }),
  'Subjects',
  undefined,
  routes.subjectTitleCode.path
);

export const discoverMagicTools = constructPublicRoute(
  interpolateRoutePath(routes.subjectTitleCode, {
    titleSlug: 'tools',
    code: '0043',
  }),
  'Tools',
  undefined,
  routes.subjectTitleCode.path
);

export const discoverMagicKits = constructPublicRoute(
  interpolateRoutePath(routes.subjectTitleCode, {
    titleSlug: 'kits',
    code: '0044',
  }),
  'Kits',
  undefined,
  routes.subjectTitleCode.path
);

export const discoverMagicFlows = constructPublicRoute(
  interpolateRoutePath(routes.subjectTitleCode, {
    titleSlug: 'flows',
    code: '0045',
  }),
  'Flows',
  undefined,
  routes.subjectTitleCode.path
);

export const discoverMagicDecks = constructPublicRoute(
  interpolateRoutePath(routes.subjectTitleCode, {
    titleSlug: 'decks',
    code: '0046',
  }),
  'Decks',
  undefined,
  routes.subjectTitleCode.path
);

export const discoveryMagicWelcomePaidArticle = constructPublicRoute(
  interpolateRoutePath(routes.articleAuthorTitleCode, {
    authorSlug: 'kitted',
    titleSlug: 'welcome-to-kitted',
    code: '0056',
  }),
  'Welcome to Kitted',
  undefined,
  routes.articleAuthorTitleCode.path
);

export const discoveryMagicInstructionsArticle = constructPublicRoute(
  interpolateRoutePath(routes.articleAuthorTitleCode, {
    authorSlug: 'kitted',
    titleSlug: 'welcome-to-kitted',
    code: '0055',
  }),
  'Instructions',
  undefined,
  routes.articleAuthorTitleCode.path
);
