import { memo } from 'react';

import { StoreSectionInitializerProps } from '../types';
import useArticleStoreInitializer from './hooks/useArticleStoreInitializer';

const ArticleStoreInitializer = ({
  onSectionLoaded,
}: StoreSectionInitializerProps) => {
  useArticleStoreInitializer(onSectionLoaded);

  return null;
};

export default memo(ArticleStoreInitializer);
