import { memo } from 'react';

import useStyles from './styles';

const EllipsisDots = () => {
  const styles = useStyles();

  return (
    <span className={styles.container}>
      <span>&hellip;</span>
    </span>
  );
};

export default memo(EllipsisDots);
