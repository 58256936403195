import { memo } from 'react';

import useLottie from './hooks/useLottie';
import { LottieProps } from './types';

const Lottie = ({ className, animationData, width, height }: LottieProps) => {
  const { elementRef, style } = useLottie(animationData, width, height);

  return <div className={className} style={style} ref={elementRef} />;
};

export default memo(Lottie);
