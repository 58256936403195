import { memo } from 'react';

import useCaptcha from './hooks/useCaptcha';
// import { defaultCaptchaConfig } from './constants';
import useStyles from './styles';
import { CaptchaProps } from './types';

const Captcha = ({
  onReady,
  onVerify,
  registerExecuteCaptcha,
}: CaptchaProps) => {
  useStyles();
  const { elementID, containerRef } = useCaptcha(
    registerExecuteCaptcha,
    onVerify,
    onReady
  );

  return (
    <div
      ref={containerRef}
      id={elementID}
      // data-onloadcallbackname={defaultCaptchaConfig.onloadCallbackName}
      // data-verifycallbackname={defaultCaptchaConfig.verifyCallbackName}
    />
  );
};

export default memo(Captcha);
