import { memo } from 'react';

import useCardIcon from './hooks/useCardIcon';
import { CardIconProps } from './types';

const CardIcon = ({ className, slug, spot, scale = 0.51 }: CardIconProps) => {
  const { Component } = useCardIcon(slug);

  if (!Component) return null;

  return (
    <Component
      className={className}
      scale={scale}
      spot={spot || 'var(--cardSlugTypeColor)'}
    />
  );
};

export default memo(CardIcon);
