import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const MagnifyHash: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 30 30"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M26.85 14.29c0 6.95-5.64 12.59-12.59 12.59S1.68 21.25 1.68 14.29 7.31 1.71 14.26 1.71 26.85 7.34 26.85 14.3ZM23.49 23.46l4.83 4.83M10.07 12.36h9.48M8.98 16.34h9.48M10.68 19.03l3.16-9.47M14.65 19.03l3.16-9.47" />
    </g>
  </WebsiteIcon>
);

export default memo(MagnifyHash);
