import { createContext } from 'react';

import { FormSubmitButtonData } from './types';

export const FormSubmitButtonDataContext = createContext<FormSubmitButtonData>({
  isDisabled: false,
  isLoading: false,
  isSubmitting: false,
  type: 'submit',
});
