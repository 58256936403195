/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
const loggerLevelWrapper =
  (logFn: (...args: any) => void) =>
  (...msgs: any[]) => {
    if (process.env.NODE_ENV === 'development') {
      logFn(...msgs);
    }
  };

const log = (...msgs: any[]) => {
  console.log(...msgs);
};

const debug = (...msgs: any[]) => {
  console.debug(...msgs);
};

const info = (...msgs: any[]) => {
  console.info(...msgs);
};

const trace = (...msgs: any[]) => {
  console.trace(...msgs);
};

const warn = (...msgs: any[]) => {
  console.warn(...msgs);
};

const error = (...msgs: any[]) => {
  console.error(...msgs);
};

const logger = {
  log: loggerLevelWrapper(log),
  debug: loggerLevelWrapper(debug),
  info: loggerLevelWrapper(info),
  trace,
  warn,
  error,
};

export default logger;
