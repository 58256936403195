/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  AuthenticateUserChallenge,
  AuthenticateUserResponse,
} from 'quidproquo';
import { UserRefreshTokenResponse } from '@kitted/auth-service-models';

import useAsyncRequest from '../../../../hooks/useAsyncRequest';
import { AsyncRequestState } from '../../../../hooks/useAsyncRequest/types';
import useAuthModal from '../../../../hooks/useAuthModal';
import useEnvironmentDomain from '../../../../hooks/useEnvironmentDomain';
import useIsLocalDevelopment from '../../../../hooks/useIsLocalDevelopment';
import { authenticationTokensManager } from '../../../../services/authenticationTokensManager';
import {
  AuthenticationTokens,
  AuthenticationTokensManager,
} from '../../../../services/authenticationTokensManager/types';
import logger from '../../../../services/logger';
import persistentKeyValueStore from '../../../../services/persistentKeyValueStore';
import { PersistentKeyValueStore } from '../../../../services/persistentKeyValueStore/types';
import { networkRequest } from '../../../../services/requests';
import { Response } from '../../../../services/requests/types';
import { getRefreshTokenConfig, isTokenWithinExpiry } from '../../logic';

const useAuthTokensManagement = () => {
  const environmentDomain = useEnvironmentDomain();
  const isLocalDevelopment = useIsLocalDevelopment();
  const { launchChallengeModal, launchLoginModal } = useAuthModal();
  /*
    - https://github.com/facebook/react/issues/3473
    - https://stormpath.com/blog/where-to-store-your-jwts-cookies-vs-html5-web-storage
  */
  const persistentStore = useRef<PersistentKeyValueStore>(
    persistentKeyValueStore('kitted')
  );
  const authInfoRef = useRef<AuthenticationTokensManager>(
    authenticationTokensManager(
      persistentStore.current.getValueForKey('tokens') as AuthenticationTokens
    )
  );
  const [tokensSync, setTokensSync] = useState<AuthenticationTokens>(
    authInfoRef.current?.getAuthenticationTokens()
  );
  const [hasCheckedTokens, setHasCheckedTokens] = useState<boolean>(false);

  const getAccessToken = useCallback(
    () => authInfoRef.current?.getAuthenticationTokens()?.accessToken,
    []
  );

  const getRefreshToken = useCallback(
    () => authInfoRef.current?.getAuthenticationTokens()?.refreshToken,
    []
  );

  const getTokens = useCallback(
    () => authInfoRef.current?.getAuthenticationTokens(),
    []
  );

  const setTokens = useCallback((authInfo: AuthenticationTokens) => {
    authInfoRef.current.setAuthenticationTokens(authInfo);
    if (authInfo) {
      persistentStore.current.setValueForKey('tokens', authInfo);
    } else {
      persistentStore.current.deleteValueForKey('tokens');
    }
    setTokensSync(authInfo);
  }, []);

  const handleRefreshTokenResponse = useCallback(
    (response: AuthenticateUserResponse) => {
      const {
        challenge,
        session: responseSession,
        authenticationInfo,
      } = response;
      if (challenge === ('NONE' as AuthenticateUserChallenge.NONE)) {
        // currently /refreshToken doesnt return a new refreshToken
        // so maintain old one
        setTokens({
          ...authenticationInfo,
          refreshToken: getRefreshToken(),
        });
      } else {
        // this is so rare, but in theory it could happen that we get a challenge
        launchChallengeModal({
          challenge,
          session: responseSession,
        });
      }
    },
    [launchChallengeModal, setTokens, getRefreshToken]
  );

  // refresh token function
  const [requestTokenRefresh, tokenRefreshState] = useAsyncRequest<
    Response<UserRefreshTokenResponse>
  >(
    async () => {
      try {
        return await networkRequest<UserRefreshTokenResponse>(
          getRefreshTokenConfig(
            isLocalDevelopment,
            environmentDomain,
            getAccessToken,
            getRefreshToken
          )
        );
      } catch (e: any) {
        logger.log('token refresh failed, logging out');
        setTokens(undefined);
        launchLoginModal();
        throw e;
      }
    },
    ({ data: refreshTokenResponse }) => {
      handleRefreshTokenResponse(refreshTokenResponse);
    }
  );

  const checkAndRefreshTokens = useCallback(async () => {
    const currentTokens = getTokens();
    const now = Date.now();
    if (currentTokens?.expiresAt) {
      logger.log('auth tokens present');
      if (isTokenWithinExpiry(currentTokens.expiresAt, now)) {
        logger.log('auth tokens present but expired, fetching new ones');
        await requestTokenRefresh(currentTokens);
      }
    }
    setHasCheckedTokens(true);
  }, [getTokens, requestTokenRefresh, setHasCheckedTokens]);

  useEffect(() => {
    if (tokenRefreshState === AsyncRequestState.Loading) {
      logger.log('already refreshing token');
      return;
    }
    checkAndRefreshTokens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAndRefreshTokens]);

  const tokenState = useMemo(() => {
    if (!hasCheckedTokens) {
      return AsyncRequestState.Loading;
    }
    // if we have checked, but didnt have to fetch the tokens
    // mark them as successfully fetched
    if (tokenRefreshState === AsyncRequestState.Default) {
      return AsyncRequestState.Success;
    }
    return tokenRefreshState;
  }, [hasCheckedTokens, tokenRefreshState]);

  return {
    hasCheckedTokens,
    tokens: tokensSync,
    tokenRefreshState: tokenState,
    requestTokenRefresh,
    getAccessToken,
    getRefreshToken,
    getTokens,
    setTokens,
  };
};

export default useAuthTokensManagement;
