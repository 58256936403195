import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0059: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 124 130"
		height={130}
		width={124}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><g stroke={color}><circle vectorEffect="non-scaling-stroke" cx="62.64" cy="41.24" r="10.73" /><path vectorEffect="non-scaling-stroke" d="M49.97 112.27c-7.84 0-11.9-6.41-11.9-14.25V77.15c0-7.84 6.41-14.26 14.25-14.26h20.64c7.84 0 14.26 6.41 14.26 14.26v20.87c0 7.84-4.07 14.25-11.91 14.25M75.43 81.47v47.1M49.85 81.47v47.1" /></g><g stroke={spot}><circle vectorEffect="non-scaling-stroke" cx="26.05" cy="41.24" r="10.73" /><path vectorEffect="non-scaling-stroke" d="M13.38 112.27c-7.84 0-11.9-6.41-11.9-14.25V77.15c0-7.84 6.41-14.26 14.25-14.26h15.32M13.26 81.47v47.1" /></g><g stroke={color}><circle vectorEffect="non-scaling-stroke" cx="97.95" cy="41.24" r="10.73" /><path vectorEffect="non-scaling-stroke" d="M92.94 62.9h15.32c7.84 0 14.25 6.41 14.25 14.26v20.87c0 7.84-4.06 14.25-11.9 14.25M110.74 128.57v-47.1" /></g><g stroke={color}><circle vectorEffect="non-scaling-stroke" cx="80.94" cy="12.54" r="10.73" /><circle vectorEffect="non-scaling-stroke" cx="44.35" cy="12.54" r="10.73" /></g></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m30.27 45.21-8.44-7.89M22.11 45.49 30 37.05" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0059);
