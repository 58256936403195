import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Globe: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M14.01 26.61V1.5M26.57 14.01H3.41M13.03 1.38s-11.86 10.88 0 25.19M14.99 1.38s11.86 10.88 0 25.19" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M23.45 21.98c-3.42-2.06-9.54-4.33-17.03-.94M23.55 6c-3.45 2.1-9.72 4.48-17.41.87" /><circle vectorEffect="non-scaling-stroke" cx="14" cy="14" r="12.62" stroke={color} transform="rotate(-80.7 14.003 14.004)" />
    </g>
  </WebsiteIcon>
);

export default memo(Globe);
