import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0001: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 88 110"
		height={110}
		width={88}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><path vectorEffect="non-scaling-stroke" stroke={spot} d="M23.58 107.85c-.15-14.4 12.55-27.36 19.93-27.36s20.31 12.96 20.46 27.36" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M1.77 1.41h84.01" /><g stroke={color}><path vectorEffect="non-scaling-stroke" d="M10.54 11.24v21.3c0 12.16 22.04 15.76 22.04 20.39v4.03c0 4.63-22.04 8.23-22.04 20.39v30.86M77.01 2.99v29.56c0 12.16-22.04 15.76-22.04 20.39v4.03c0 4.63 22.04 8.23 22.04 20.39v30.86" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M43.77 62.84v3.76" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M1.29 108.44h85.42" />
    </g>
  </CardIcon>
);

export default memo(Sn0001);
