import { ToolType } from '@kitted/card-service-models';
import { ResourceType } from '@kitted/resource-service-models';
import { upperFirst } from '@kitted/shared-utils';

export const subjectTitles = ({
  titleSlug,
}: Record<string, string | undefined>) => titleSlug;

export const resourceTitles =
  (resourceType: ResourceType, prefix = '') =>
  ({ titleSlug }: Record<string, string | undefined>) => [
    titleSlug,
    `${prefix}${upperFirst(resourceType)}`,
  ];

export const toolTitles =
  (toolType: ToolType) =>
  ({ titleSlug }: Record<string, string | undefined>) => [
    titleSlug,
    upperFirst(toolType),
  ];

export const kitTitles = ({ titleSlug }: Record<string, string | undefined>) =>
  titleSlug;

export const authorTitles = ({
  titleSlug,
}: Record<string, string | undefined>) => titleSlug;

export const articleTitles = ({
  titleSlug,
}: Record<string, string | undefined>) => titleSlug;

export const playTitles = ({ titleSlug }: Record<string, string | undefined>) =>
  titleSlug;

export const flowTitles = ({ titleSlug }: Record<string, string | undefined>) =>
  titleSlug;

export const betaPageTitles = ({ code }: Record<string, string | undefined>) =>
  code;
