import { useMemo } from 'react';

import {
  AssetCatalogueModalSpaceProps,
  AssetCatalogueProps,
} from '../../types';

const useAssetCatalogue = ({
  onSaveChanges,
  onClose,
  title,
  maxFileSize,
  acceptedFileFormats,
  isMultiSelect,
}: {
  onSaveChanges: AssetCatalogueProps['onSaveChanges'];
  onClose: AssetCatalogueProps['onClose'];
  title: AssetCatalogueProps['title'];
  maxFileSize: AssetCatalogueProps['maxFileSize'];
  acceptedFileFormats: AssetCatalogueProps['acceptedFileFormats'];
  isMultiSelect: AssetCatalogueProps['isMultiSelect'];
}) => {
  const spaceProps: AssetCatalogueModalSpaceProps = useMemo(
    () => ({
      onSaveChanges,
      onClose,
      title,
      maxFileSize,
      acceptedFileFormats,
      isMultiSelect,
    }),
    [
      onSaveChanges,
      onClose,
      title,
      maxFileSize,
      acceptedFileFormats,
      isMultiSelect,
    ]
  );

  return {
    spaceProps,
  };
};

export default useAssetCatalogue;
