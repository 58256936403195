import { memo } from 'react';

import { InputComponentProps } from '../types';

const InputHidden = ({
  autoComplete,
  name,
  required,
  value,
}: InputComponentProps) => (
  <input
    autoComplete={autoComplete}
    disabled
    name={name}
    required={required}
    type="hidden"
    value={(value as number | string) || ''}
  />
);

export default memo(InputHidden);
