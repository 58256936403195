import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0058: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 100 102"
		height={102}
		width={100}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m71.44 71.07 17.34-8.86c3.35-1.8 7.28-1.08 8.91 1.94 1.62 3.02.2 6.96-3.15 8.76L58.55 92.2l-1.9.48-33.56.91s-4.32 1.21-9.51 7.09" /><path vectorEffect="non-scaling-stroke" d="m1.64 83.03 15.22-14.01c7.44-5.02 16.26-2.25 16.26-2.25 4.27 1.12 12.2 1.38 12.2 1.38h13.17c3.92 0 7.13 2.8 7.13 6.23s-3.21 6.23-7.13 6.23H40.27" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="m64.09 1.33 30.2 8.55-8.55 30.2-30.2-8.55 6.44-22.75" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M75.94 21c-.16.57-.75.9-1.32.74s-.9-.75-.74-1.32.76-.9 1.32-.74c.57.16.9.75.74 1.32" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M84.46 16.23c-.16.57-.75.9-1.32.74s-.9-.75-.74-1.32.76-.9 1.32-.74c.57.16.9.75.74 1.32M71.19 12.47c-.16.57-.75.9-1.32.74s-.9-.75-.74-1.32.76-.9 1.32-.74c.57.16.9.75.74 1.32M80.7 29.52c-.16.57-.75.9-1.32.74s-.9-.75-.74-1.32.76-.9 1.32-.74c.57.16.89.75.74 1.32M67.43 25.76c-.16.57-.75.9-1.32.74s-.9-.75-.74-1.32.76-.9 1.32-.74c.57.16.9.75.74 1.32" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="m27.34 59.34-4.82-5.19 23-21.37 21.36 23-6.56 6.09" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M45.84 54.22c.4.43.38 1.11-.06 1.51-.43.4-1.11.38-1.51-.05s-.38-1.11.06-1.51c.43-.4 1.11-.38 1.51.06ZM35.73 53.87c.4.43.38 1.11-.05 1.51s-1.11.38-1.51-.05-.38-1.11.06-1.51c.43-.4 1.11-.38 1.51.06ZM55.24 54.6c.4.43.38 1.11-.05 1.51s-1.11.38-1.52-.05c-.4-.43-.37-1.11.06-1.51s1.11-.38 1.51.06Z" /></g>
    </g>
  </CardIcon>
);

export default memo(In0058);
