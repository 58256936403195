import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Newspaper: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 22"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M22.65 10.87h-4.6M22.65 7.8h-4.6M22.65 4.73h-4.6M2 4.34v13.74c0 1.28 1.09 2.34 2.37 2.34s2.37-1.06 2.37-2.34V1.57H26v16.72c0 1.16-.95 2.13-2.11 2.13H4.61M4.35 18.09V4.34M22.65 14.08H9.84M22.65 17.15H9.84" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M9.84 4.85h5.54v6.1H9.84V7.38" />
    </g>
  </WebsiteIcon>
);

export default memo(Newspaper);
