import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0005: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 114 94"
		height={94}
		width={114}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M84.6 21.73c16.5 4.26 27.6 12.22 27.6 21.33 0 13.6-24.72 24.63-55.2 24.63S1.8 56.67 1.8 43.06c0-9.14 11.16-17.12 27.73-21.37M103.6 67.98C93.8 74.84 76.59 79.4 57 79.4s-36.8-4.55-46.6-11.42" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M112.2 43.06v24.53c0 13.6-24.71 24.63-55.2 24.63S1.8 81.2 1.8 67.6V40.93" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M61.11 19.7v27.5M52.89 47.2V25.48" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M50.73 10.45h12.54v9.05H50.73zM49.61 56.19v-8.57h14.78v8.57" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M36.66 14.89h14.09M36.51 11.27v7.24M77.33 14.89H63.2M77.49 18.51v-7.24" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M57 1.55v8.5M60.63 1.4h-7.26" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M20.6 48.5c0-8.29 13.93-15.13 31.92-16.11M93.4 48.5c0-8.29-13.93-15.13-31.92-16.11" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0005);
