import { memo } from 'react';
import { styling } from '@kitted/design-system';

import { CCProps } from './types';

const CC = ({
  className,
  height = 26,
  width = 26,
  fill = styling.colors.theme.primary600,
}: CCProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 26"
    className={className}
    height={height}
    width={width}
  >
    <g fill={fill}>
      <path d="m12.98,1.12c-3.25,0-6.12,1.2-8.33,3.45-2.28,2.31-3.51,5.31-3.51,8.43s1.2,6.09,3.48,8.36c2.28,2.28,5.24,3.52,8.36,3.52s6.15-1.24,8.49-3.55c2.21-2.18,3.38-5.08,3.38-8.33s-1.17-6.15-3.42-8.4c-2.28-2.28-5.21-3.48-8.46-3.48Zm.03,2.15c2.67,0,5.04,1.01,6.9,2.86,1.82,1.82,2.8,4.23,2.8,6.87s-.94,5.01-2.77,6.8c-1.92,1.89-4.39,2.9-6.93,2.9s-4.98-1.01-6.83-2.86c-1.85-1.85-2.9-4.3-2.9-6.83s1.04-5.01,2.9-6.9c1.82-1.85,4.16-2.83,6.83-2.83Z" />
      <path d="m12.86,11.02c-.67-1.22-1.81-1.71-3.14-1.71-1.93,0-3.47,1.37-3.47,3.68s1.45,3.69,3.54,3.69c1.34,0,2.49-.74,3.12-1.86l-1.47-.75c-.33.79-.83,1.02-1.46,1.02-1.09,0-1.59-.91-1.59-2.11s.42-2.11,1.59-2.11c.32,0,.95.17,1.32.96l1.58-.83Z" />
      <path d="m19.71,11.02c-.67-1.22-1.81-1.71-3.14-1.71-1.93,0-3.47,1.37-3.47,3.68s1.45,3.69,3.54,3.69c1.34,0,2.49-.74,3.12-1.86l-1.47-.75c-.33.79-.83,1.02-1.46,1.02-1.09,0-1.59-.91-1.59-2.11s.42-2.11,1.59-2.11c.31,0,.95.17,1.32.96l1.58-.83Z" />
    </g>
  </svg>
);

export default memo(CC);
