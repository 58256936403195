import { useCallback, useEffect, useRef, useState } from 'react';
import { NOOP } from '@kitted/shared-utils';

import {
  StoreItemSubscriptionCallback,
  StoreSectionItem,
  StoreSectionItemStates,
} from '../../services/storeOfStores/types';
import { KittedStoreSectionKey } from '../../types';
import useStoreApi from '../useStoreApi';
import useStoreItem from '../useStoreItem';

const useStoreItemSubscription = <StoreItem>(
  sectionKey: KittedStoreSectionKey,
  itemId: string | undefined
) => {
  const itemIdRef = useRef<string | undefined>(itemId);
  const { getDefaultStoreItem, subscribeToItemChanges } = useStoreApi();
  const { getItem, fetchItem } = useStoreItem<StoreItem>(sectionKey, itemId);
  const defaultStoreItem = useRef<StoreSectionItem<undefined>>(
    getDefaultStoreItem()
  );
  const [item, setItem] = useState<StoreSectionItem<StoreItem | undefined>>(
    () => getItem()
  );

  useEffect(() => {
    if (itemId !== itemIdRef.current) {
      setItem(getItem());
      itemIdRef.current = itemId;
    }
  }, [getItem, sectionKey, itemId, setItem]);

  const onItemChangeCallback = useCallback<
    StoreItemSubscriptionCallback<StoreSectionItem<StoreItem | undefined>>
  >(
    (sectionItem, _id) => {
      setItem(sectionItem);
    },
    [setItem]
  );

  useEffect(() => {
    const unsubscriber =
      typeof itemId !== 'undefined'
        ? subscribeToItemChanges(sectionKey, itemId, onItemChangeCallback)
        : NOOP;
    return unsubscriber;
  }, [subscribeToItemChanges, onItemChangeCallback, sectionKey, itemId]);

  const fetchItemIfDefaultState = useCallback(() => {
    if (itemId && item.state === StoreSectionItemStates.Default) {
      fetchItem();
    }
  }, [item.state, fetchItem, itemId]);

  useEffect(() => {
    fetchItemIfDefaultState();
  }, [fetchItemIfDefaultState]);

  return itemId ? item : defaultStoreItem.current;
};

export default useStoreItemSubscription;
