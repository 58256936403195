import { memo } from 'react';

import CatalogueItemGrid from '../CatalogueItemGrid';
import CatalogueTopBar from '../CatalogueTopBar';
import useGalleryItems from './hooks/useGalleryItems';
import useStyles from './styles';

const SelectedCatalogueItemsView = () => {
  const styles = useStyles();
  const galleryItems = useGalleryItems();

  return (
    <div className={styles.container}>
      <CatalogueTopBar hideFilterDropdown />
      <CatalogueItemGrid catalogueItems={galleryItems} />
    </div>
  );
};

export default memo(SelectedCatalogueItemsView);
