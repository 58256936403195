import { PageTitle, PublicRoute, PublicRouteWithChildren } from '../types';

export function constructPublicRoute(
  path: string,
  title: PageTitle,
  redirectTo?: string,
  preinterpolatedRoutePath?: string
): PublicRoute;

export function constructPublicRoute<C>(
  path: string,
  title: PageTitle,
  redirectTo: string | undefined,
  preinterpolatedRoutePath: string | undefined,
  children: C
): PublicRouteWithChildren<C>;

export function constructPublicRoute<C>(
  path: string,
  title: PageTitle,
  redirectTo?: string,
  preinterpolatedRoutePath?: string,
  children?: C
): PublicRoute | PublicRouteWithChildren<C> {
  if (children) {
    return {
      path,
      title,
      preinterpolatedRoutePath,
      redirectTo,
      isAuthenticated: false,
      children,
    };
  }
  return {
    path,
    title,
    preinterpolatedRoutePath,
    redirectTo,
    isAuthenticated: false,
  };
}
