import { useMemo } from 'react';

import { CardsOrderingContext } from './CardsOrderingContext';
import { CardsOrderingDataContext } from './CardsOrderingDataContext';
import { useCardsOrderingManagement } from './hooks';
import { CardsOrderingContextProps } from './types';

export const CardsOrderingProvider = ({
  children,
}: CardsOrderingContextProps) => {
  const {
    orderedCardIds,
    setInitialPlatformCardOrdering,
    setInitialUserCardOrdering,
  } = useCardsOrderingManagement();
  const apiValue = useMemo(
    () => ({ setInitialPlatformCardOrdering, setInitialUserCardOrdering }),
    [setInitialPlatformCardOrdering, setInitialUserCardOrdering]
  );
  const dataValue = useMemo(() => ({ orderedCardIds }), [orderedCardIds]);

  return (
    <CardsOrderingContext.Provider value={apiValue}>
      <CardsOrderingDataContext.Provider value={dataValue}>
        {children}
      </CardsOrderingDataContext.Provider>
    </CardsOrderingContext.Provider>
  );
};
