import { useCallback, useEffect } from 'react';
import { AnyCardModel } from '@kitted/card-service-models';
import { CardModelId, KittedServiceEnum } from '@kitted/kitted-models';
import { WebsocketModelUpdateServerMessage } from '@kitted/websocket-service-models';

import useCardsRequests from '../../../../../../hooks/useCardsRequests';
import logger from '../../../../../../services/logger';
import { UNKNOWN_CARD_ID } from '../../../../../CardsContext/hooks/useCard/constants';
import useCardsApi from '../../../../../CardsContext/hooks/useCardsApi';
import { useSubscribeToWebsocketModelUpdateServerMessage } from '../../../../../WebsocketContext';
import useStoreApi from '../../../../hooks/useStoreApi';
import useStoreSection from '../../../../hooks/useStoreSection';
import { StoreSectionInitializerProps } from '../../../types';

const useCardsStoreInitializer = (
  onSectionLoaded: StoreSectionInitializerProps['onSectionLoaded']
) => {
  const { requestCard } = useCardsRequests();
  const { upsertCardToTextSearchDb } = useCardsApi();
  const { createOrUpdateStoreSection } = useStoreApi();
  const { updateStoreSectionItem } = useStoreSection('Cards');

  const getCard = useCallback(
    async (cardId: CardModelId) => {
      if (cardId === UNKNOWN_CARD_ID) return undefined;

      const card = await requestCard(cardId);
      if (card) {
        upsertCardToTextSearchDb(card);
      }
      return card;
    },
    [requestCard, upsertCardToTextSearchDb]
  );

  const updateCardsStoreSectionFetcher = useCallback(() => {
    createOrUpdateStoreSection('Cards', getCard);
    onSectionLoaded('Cards');
  }, [createOrUpdateStoreSection, getCard, onSectionLoaded]);

  useEffect(updateCardsStoreSectionFetcher, [updateCardsStoreSectionFetcher]);

  const handleWebsocketItemUpdated = useCallback(
    ({ model, service }: WebsocketModelUpdateServerMessage) => {
      if (model && service === KittedServiceEnum.Card) {
        const card = model as AnyCardModel;
        logger.log('[cardServiceWebsocket] card model update', card);
        upsertCardToTextSearchDb(card);
        updateStoreSectionItem(card, card.id);
      }
    },
    [updateStoreSectionItem, upsertCardToTextSearchDb]
  );

  useSubscribeToWebsocketModelUpdateServerMessage(handleWebsocketItemUpdated);
};

export default useCardsStoreInitializer;
