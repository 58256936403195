import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0002: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 116 104"
		height={104}
		width={116}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M58 21.87c-15.91.26-27.72 7.5-27.93 28.45-.02 10.55 3.29 23.84 8.57 34.25 5.27 10.51 12.54 17.7 19.36 17.56 6.82.14 14.09-7.05 19.36-17.56 5.29-10.41 8.59-23.7 8.58-34.25-.23-20.95-12.04-28.19-27.93-28.45ZM58 93.05v9.08" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M64.91 79.9c3.15 0 4.44 2.23 2.87 4.95 0 0-5.12 5.16-6.91 6.81-1.89 1.74-3.26 1.99-5.73 0-1.89-1.52-6.91-6.81-6.91-6.81-1.57-2.72-.28-4.95 2.87-4.95h13.82ZM13.79 49.41c-.33 1.04-.5 2.14-.5 3.28 0 4.18 2.35 7.81 5.81 9.71-.98 1.64-1.55 3.55-1.55 5.59 0 4.35 2.55 8.1 6.26 9.92-.32 1.04-.5 2.14-.5 3.28 0 6.13 5.05 11.1 11.3 11.1.31 0 .62-.02.93-.04 1.43 4.51 5.72 7.79 10.79 7.79.96 0 3.1-.39 3.98-.61M99.2 32.07a11.9 11.9 0 0 0 1.29-5.42c0-6.71-5.54-12.16-12.38-12.16-1.29 0-2.54.2-3.71.56-1.05-5.67-6.1-9.96-12.16-9.96-1.54 0-3.01.29-4.37.8a12.42 12.42 0 0 0-9.16-4.02c-3.85 0-7.29 1.73-9.56 4.45-1.63-.78-3.45-1.22-5.38-1.22-6.07 0-11.11 4.29-12.16 9.96a12.6 12.6 0 0 0-3.71-.56c-6.82 0-12.37 5.45-12.37 12.16 0 1.95.47 3.79 1.3 5.42M65.93 99.27c.89.22 2.78.78 3.74.78 5.07 0 9.36-3.28 10.79-7.79.31.02.62.04.93.04 6.24 0 11.3-4.97 11.3-11.1 0-1.14-.17-2.25-.5-3.28 3.71-1.82 6.26-5.57 6.26-9.92 0-2.04-.57-3.95-1.55-5.59 3.46-1.89 5.81-5.53 5.81-9.7 0-1.15-.18-2.26-.51-3.3" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M30.24 46.65c-1.66.86-3.42 1.6-5.27 2.12-11.96 3.36-23.09-3.31-23.09-3.31s8.28-12.16 20.26-15.54c5.28-1.49 10.4-1.02 14.47-.03M85.76 46.65c1.66.86 3.42 1.59 5.26 2.11 11.97 3.36 23.1-3.31 23.1-3.31s-8.28-12.16-20.26-15.54c-5.29-1.49-10.41-1.02-14.48-.02" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M42.86 43.85v4.59M73.14 43.85v4.59" />
    </g>
  </CardIcon>
);

export default memo(In0002);
