import { useCallback, useMemo, useRef, useState } from 'react';
import { EMPTY_ARR } from '@kitted/shared-utils';

import { storeSections } from '../../../constants';
import { KittedStoreSectionKey } from '../../../types';

const useStoreInitializer = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const loadedSections = useRef<KittedStoreSectionKey[]>(EMPTY_ARR);

  const initializers = useMemo(
    () =>
      Object.values(storeSections).map(
        (storeSection) => storeSection.component
      ),
    []
  );

  const onSectionLoaded = useCallback((sectionKey: KittedStoreSectionKey) => {
    loadedSections.current = [...loadedSections.current, sectionKey];
    if (loadedSections.current.length === Object.keys(storeSections).length) {
      setIsLoading(false);
    }
  }, []);

  return {
    isLoading,
    initializers,
    onSectionLoaded,
  };
};

export default useStoreInitializer;
