import { z } from 'zod';

import {
  ContentBlockCardGridLoadedModelSchema,
  ContentBlockCardGridUnloadedModelSchema,
} from './ContentBlockCardGridModel';
import {
  ContentBlockCarouselItemLoadedModelSchema,
  ContentBlockCarouselItemUnloadedModelSchema,
} from './ContentBlockCarouselItemModel';
import {
  ContentBlockCarouselLoadedModelSchema,
  ContentBlockCarouselUnloadedModelSchema,
} from './ContentBlockCarouselModel';
import {
  ContentBlockImageLoadedModelSchema,
  ContentBlockImageUnloadedModelSchema,
} from './ContentBlockImageModel';
import { ContentBlockQuoteModelSchema } from './ContentBlockQuoteModel';
import { ContentBlockTextModelSchema } from './ContentBlockTextModel';
import {
  ContentBlockVideoLoadedModelSchema,
  ContentBlockVideoUnloadedModelSchema,
} from './ContentBlockVideoModel';

export const AnyContentBlockLoadedModelSchema = z.union([
  ContentBlockImageLoadedModelSchema,
  ContentBlockCardGridLoadedModelSchema,
  ContentBlockVideoLoadedModelSchema,
  ContentBlockQuoteModelSchema,
  ContentBlockTextModelSchema,
  ContentBlockCarouselLoadedModelSchema,
  ContentBlockCarouselItemLoadedModelSchema,
]);

export const AnyContentBlockUnloadedModelSchema = z.union([
  ContentBlockImageUnloadedModelSchema,
  ContentBlockCardGridUnloadedModelSchema,
  ContentBlockVideoUnloadedModelSchema,
  ContentBlockQuoteModelSchema,
  ContentBlockTextModelSchema,
  ContentBlockCarouselUnloadedModelSchema,
  ContentBlockCarouselItemUnloadedModelSchema,
]);

export type AnyContentBlockLoadedModel = z.infer<
  typeof AnyContentBlockLoadedModelSchema
>;
export type AnyContentBlockUnloadedModel = z.infer<
  typeof AnyContentBlockUnloadedModelSchema
>;

export type AnyContentBlockModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? AnyContentBlockLoadedModel
    : AnyContentBlockUnloadedModel;
