import { constructAuthenticatedRoute, constructPublicRoute } from '../helpers';
import { playTitles } from '../routeTitles';

const playAuthorTitleCodeBasePath = '/play/:authorSlug/:titleSlug/:code';

export const playRedirectRoutes = {
  playCode: constructPublicRoute(
    '/play/:code',
    'Play',
    playAuthorTitleCodeBasePath
  ),
  playTitleCode: constructPublicRoute(
    '/play/:titleSlug/:code',
    [playTitles, 'Play'],
    playAuthorTitleCodeBasePath
  ),
} as const;

export const playRoutes = {
  ...playRedirectRoutes,
  playAuthorTitleCode: constructPublicRoute(playAuthorTitleCodeBasePath, [
    playTitles,
    'Play',
  ]),
  playAuthorTitleCodeEdit: constructAuthenticatedRoute(
    `${playAuthorTitleCodeBasePath}/edit`,
    [playTitles, 'Edit Play']
  ),
} as const;
