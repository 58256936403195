/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import FocusTrap from 'focus-trap-react';
import { Fade } from '@kitted/shared-components';

import useAlert from './hooks/useAlert';
import useStyles from './styles';
import { AlertProps } from './types';

const Alert = (props: AlertProps) => {
  const { isIn = false } = props;
  const { onClose, onExited, AlertContentComponent, alertContentProps } =
    useAlert(props);
  const styles = useStyles();

  return (
    <FocusTrap active={isIn}>
      <Fade
        className={styles.container}
        durationIn={500}
        durationOut={500}
        in={isIn}
        onClick={onClose}
        onExited={onExited}
      >
        <AlertContentComponent {...alertContentProps} />
      </Fade>
    </FocusTrap>
  );
};

export default memo(Alert);
