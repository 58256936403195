import { z } from 'zod';

import { ContentBlockCardGridSaveModelSchema } from './ContentBlockCardGridModel';
import { ContentBlockCarouselItemSaveModelSchema } from './ContentBlockCarouselItemModel';
import { ContentBlockCarouselSaveModelSchema } from './ContentBlockCarouselModel';
import { ContentBlockImageSaveModelSchema } from './ContentBlockImageModel';
import { ContentBlockQuoteSaveModelSchema } from './ContentBlockQuoteModel';
import { ContentBlockTextSaveModelSchema } from './ContentBlockTextModel';
import { ContentBlockVideoSaveModelSchema } from './ContentBlockVideoModel';

export const AnyContentBlockSaveModelSchema = z.union([
  ContentBlockCardGridSaveModelSchema,
  ContentBlockImageSaveModelSchema,
  ContentBlockQuoteSaveModelSchema,
  ContentBlockTextSaveModelSchema,
  ContentBlockVideoSaveModelSchema,
  ContentBlockCarouselItemSaveModelSchema,
  ContentBlockCarouselSaveModelSchema,
]);

export type AnyContentBlockSaveModel = z.infer<
  typeof AnyContentBlockSaveModelSchema
>;
