import { Dispatch, SetStateAction } from 'react';
import { WebsocketModelUpdateServerMessage } from '@kitted/websocket-service-models';

import { submissionWebsocketMessages } from './constants';
import { UserCreateFormData } from './types';

export const transformCreateFormDataToRegisterPayload = ({
  firstName,
  lastName,
  email,
  password,
  'g-recaptcha-response': recaptchaResponse,
  orderId,
  referralCode,
}: UserCreateFormData) => ({
  'g-recaptcha-response': recaptchaResponse,
  firstName,
  lastName,
  email,
  password,
  orderId,
  referralCode,
});

export const handleWebsocketItemUpdated =
  (setSubmissionProgress: Dispatch<SetStateAction<number>>) =>
  ({ model, service }: WebsocketModelUpdateServerMessage) => {
    if (model) {
      const foundMessage = submissionWebsocketMessages.find(
        ({ service: serviceName }) => service === serviceName
      );
      if (foundMessage) {
        if (foundMessage.kittedModelType === model.kittedModelType) {
          setSubmissionProgress((progress) => progress + 1);
        }
      }
    }
  };
