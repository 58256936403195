import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0002: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 92 100"
		height={100}
		width={92}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M1.21 26.28s16.98 12.13 8.56 23.2c0 0-19.29 21.88 12.31 26.12M90.79 26.28s-16.98 12.13-8.56 23.2c0 0 19.29 21.88-12.32 26.12" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M44.92 45.16c0 .59.48 1.07 1.07 1.07s1.08-.48 1.08-1.07-.49-1.07-1.08-1.07-1.07.48-1.07 1.07" /><circle vectorEffect="non-scaling-stroke" cx="46" cy="45.16" r="10.45" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M29.22 67.63h33.57" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M36 61.87v5.42M56 61.87v5.42" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M59.68 8.18a5.86 5.86 0 0 0 6.95 4.54 5.855 5.855 0 0 0 4.54-6.95c-.66-3.17-3.78-5.2-6.95-4.54a5.867 5.867 0 0 0-4.54 6.95M57.79 24.38l5.16-12.06M32.32 8.18a5.86 5.86 0 0 1-6.95 4.54 5.855 5.855 0 0 1-4.54-6.95c.66-3.17 3.78-5.2 6.95-4.54s5.21 3.78 4.54 6.95M34.21 24.38l-5.16-12.06" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M46 22.23v-6.49" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m65.34 30.87 4.21-4.94M26.66 30.87l-4.21-4.94" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M33.66 85.23s.35 7.24-7.13 12.58M58.34 85.23s-.35 7.24 7.12 12.58" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M46 85.71v13.56M83.2 94.26c-14.21 0-14.21-12.58-14.21-19.89V44.36c0-12.18-9.96-22.14-22.14-22.14h-1.7c-12.18 0-22.14 9.96-22.14 22.14v30.01c0 7.3 0 19.89-14.21 19.89" />
    </g>
  </CardIcon>
);

export default memo(Fr0002);
