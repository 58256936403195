import { memo } from 'react';

import useStyles from './styles';
import { EditDrawerSpaceContentHeaderBarProps } from './types';

const EditDrawerSpaceContentHeaderBar = ({
  children,
}: EditDrawerSpaceContentHeaderBarProps) => {
  const styles = useStyles();

  return <div className={styles.container}>{children}</div>;
};

export default memo(EditDrawerSpaceContentHeaderBar);
