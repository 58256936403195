import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0006: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 92 96"
		height={96}
		width={92}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <rect vectorEffect="non-scaling-stroke" width="30.74" height="19.43" x="30.63" y="40.2" stroke={color} rx="2.99" ry="2.99" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M41.94 49.91h8.12" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="m36.66 15.67.37-10.4a2.983 2.983 0 0 0-2.99-3.09H5.1c-1.69 0-3.05 1.4-2.99 3.1l.38 10.31" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m35.71 39.87.61-16.23H2.9l1.88 51.62h29.59L34.89 60M29.38 75.65v15.18c0 1.65-1.34 2.99-2.99 2.99H12.06c-1.65 0-2.99-1.34-2.99-2.99V75.65" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="m55.34 15.67-.37-10.4a2.983 2.983 0 0 1 2.99-3.09h28.95c1.69 0 3.05 1.4 2.99 3.1l-.38 10.31" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m56.29 39.87-.61-16.23H89.1l-1.88 51.62H57.63L57.12 60M62.62 75.65v15.18c0 1.65 1.34 2.99 2.99 2.99h14.33c1.65 0 2.99-1.34 2.99-2.99V75.65" />
    </g>
  </CardIcon>
);

export default memo(In0006);
