import { constructPublicRoute } from '../helpers';

const mentalModelMondaysBasePath = '/mental-model-mondays';

export const mmmRedirectRoutes = {
  mmm: constructPublicRoute(
    '/mmm',
    'Mental Model Mondays',
    mentalModelMondaysBasePath
  ),
} as const;

export const mmmRoutes = {
  ...mmmRedirectRoutes,
  mentalModelMondays: constructPublicRoute(
    mentalModelMondaysBasePath,
    'Mental Model Mondays'
  ),
} as const;
