import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0042: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 98 102"
		height={102}
		width={98}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><path vectorEffect="non-scaling-stroke" stroke={color} d="M38.37 58.53v-5.06c0-3.58 2.65-5.68 6.23-5.68L49 52.3l4.09-4.51c3.58 0 6.54 2.1 6.54 5.68v5.06M33.58 73.03l-10.52-5.88v-4.81h51.88v4.81l-10.51 5.88" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M64.01 68.31v7.66M33.99 75.97v-7.66" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M41.04 77.66v-8.9h15.92v8.9M53.89 38.47c0 2.73-2.2 4.96-4.89 4.96s-4.89-2.23-4.89-4.96v-1.84c0-2.73 2.2-4.96 4.89-4.96s4.89 2.23 4.89 4.96z" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M46.47 95.65c0 2.73-2.2 4.96-4.89 4.96s-4.89-2.23-4.89-4.96v-1.84c0-2.73 2.2-4.96 4.89-4.96s4.89 2.23 4.89 4.96zM31.62 95.65c0 2.73-2.2 4.96-4.89 4.96s-4.89-2.23-4.89-4.96v-1.84c0-2.73 2.2-4.96 4.89-4.96s4.89 2.23 4.89 4.96zM61.31 95.65c0 2.73-2.2 4.96-4.89 4.96s-4.89-2.23-4.89-4.96v-1.84c0-2.73 2.2-4.96 4.89-4.96s4.89 2.23 4.89 4.96zM76.15 95.65c0 2.73-2.2 4.96-4.89 4.96s-4.89-2.23-4.89-4.96v-1.84c0-2.73 2.2-4.96 4.89-4.96s4.89 2.23 4.89 4.96z" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M44.11 85.65c0-2.73 2.2-4.96 4.89-4.96s4.89 2.23 4.89 4.96M29.27 85.65c0-2.73 2.2-4.96 4.89-4.96s4.89 2.23 4.89 4.96M58.95 85.65c0-2.73 2.2-4.96 4.89-4.96s4.89 2.23 4.89 4.96" /></g></g><g ><path vectorEffect="non-scaling-stroke" stroke={color} d="M17.45 64.74H1.08V1.39h95.84v63.35H80.21" /><g stroke={color}><path vectorEffect="non-scaling-stroke" d="M8.64 64.74V98.9M89.35 64.74V98.9" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M10.32 16.72h76.97" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0042);
