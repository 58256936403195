import { memo } from 'react';
import clsx from 'clsx';
import { Typography } from '@kitted/shared-components';

import useStyles from './styles';
import { InputWrapperProps } from './types';

const InputWrapper = ({
  children,
  className,
  errors,
  iconLeft: IconLeft,
  iconRight: IconRight,
  isDisabled,
  isFocused,
  hasValue,
  helperText,
  theme = 'secondary',
  variant = 'solid',
}: InputWrapperProps) => {
  const styles = useStyles();
  const hasErrors = errors && errors?.length > 0;

  return (
    <Typography
      as="div"
      className={clsx(
        className,
        styles.container,
        styles[`variant-${variant}`],
        styles[`theme-${theme}`],
        isFocused && styles.focused,
        isDisabled && styles.disabled,
        hasValue && styles.hasValue,
        hasErrors && styles.errored
      )}
      variant={`input${variant === 'hollow' ? 'Small' : ''}`}
    >
      {IconLeft && (
        <div className={styles.iconLeft}>
          <IconLeft size="inherit" />
        </div>
      )}
      <div className={styles.input}>{children}</div>
      {IconRight && (
        <div className={styles.iconRight}>
          <IconRight size="inherit" />
        </div>
      )}
      {hasErrors && (
        <Typography className={styles.errors} variant="inputErrors">
          {errors[0] || ''}
        </Typography>
      )}
      {!hasErrors && (
        <Typography className={styles.helperText} variant="inputErrors">
          {helperText || ''}
        </Typography>
      )}
    </Typography>
  );
};

export default memo(InputWrapper);
