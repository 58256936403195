import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0008: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 94 110"
		height={110}
		width={94}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M91.43 90.72 47.96 1.76c-.37-.75-1.43-.75-1.8 0L2.44 91.37c-1.81 4 .13 8.64 4.13 10.32 12.9 5.42 43.42 13.09 80.48-.52 5.06-2.35 6.19-6.32 4.39-10.45Z" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M47.45 61.7h-8.13v27.08h8.13c7.74 0 10.32-6.96 10.32-13.54 0-8.38-3.22-13.54-10.32-13.54" /><path vectorEffect="non-scaling-stroke" d="M46.81 96.65H35.2c-3.48 0-5.67-1.42-5.67-5.67V59.51c0-4.39 2.32-5.67 5.67-5.67h11.87c10.7 0 20.38 4.64 20.38 21.41 0 13.54-7.61 21.41-20.64 21.41Z" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0008);
