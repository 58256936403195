import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    popover: {
      width: '100%',
    },
    arrow: {
      fill: styling.colors.theme.primary900,
    },
    button: {
      overflow: 'hidden',
    },
    openMenuItem: {
      backgroundColor: styling.colors.theme.primary800,
    },
  },
  { name: 'createModelFlyout' }
);
