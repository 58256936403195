import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      '&:before': {
        content: '""',
        position: 'absolute',
        border: [0, 'solid', styling.colors.theme.transparent],
        borderWidth: [10, 7],
        borderTopColor: styling.colors.theme.secondary300_20,
        height: 0,
        width: 0,
        top: '100%',
        right: 'calc((100% / 6) - 5px)',
        marginTop: 1,
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        border: [0, 'solid', styling.colors.theme.transparent],
        borderWidth: [10, 7],
        borderTopColor: styling.colors.theme.primary900,
        height: 0,
        width: 0,
        top: '100%',
        right: 'calc((100% / 6) - 5px)',
        marginTop: -1,
      },
      [styling.breakpoints.context.tabletUpString]: {
        '&:before': {
          borderWidth: [7, 10],
          borderTopColor: styling.colors.theme.transparent,
          borderRightColor: styling.colors.theme.secondary300_20,
          bottom: 0,
          top: 'unset',
          right: '100%',
          marginBottom: 17,
          marginRight: 1,
        },
        '&:after': {
          content: 'unset',
        },
      },
    },
    list: {
      backgroundColor: styling.colors.theme.primary900,
      color: styling.colors.context.color,
      borderRadius: styling.sizes.context.cardFeature.radius,
      width: '60vw',
      overflow: 'hidden',
      boxShadow: styling.shadows.utils.boxShadowToString({
        x: 0,
        y: 0,
        blur: 0,
        spread: 1,
        inset: null,
        color: styling.colors.theme.secondary300_20,
      }),
      '& li:last-child': {
        borderBottom: 'none',
      },
      [styling.breakpoints.context.tabletUpString]: {
        width: styling.sizes.context.drawer.openWidth,
      },
    },
  },
  { name: 'createModelPopoverContent' }
);
