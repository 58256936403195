import { useMemo } from 'react';
import { styling } from '@kitted/design-system';

import useMediaQuery from '../../../../../contexts/MediaQueryManagementContext/hooks/useMediaQuery';
import {
  useDrawerSpaceContainerApi,
  useDrawerSpaceContainerData,
} from '../../../context/DrawerSpaceContainerContext';

const useDrawerSpaceContentWrapper = () => {
  const { onSetContentRoot } = useDrawerSpaceContainerApi();
  const { isMainOpen, isMainDisabled, isContextualOpen, isContextualDisabled } =
    useDrawerSpaceContainerData();
  const isDesktop = useMediaQuery('isDesktop');

  const style = useMemo(() => {
    let drawerLeftWidth = styling.sizes.context.drawer.closedWidth;
    let drawerRightWidth = styling.sizes.context.drawer.closedWidth;
    if (isMainOpen && isDesktop) {
      drawerLeftWidth = styling.sizes.context.drawer.openWidth;
    }
    if (isMainDisabled) {
      drawerLeftWidth = 0;
    }
    if (isContextualOpen && isDesktop) {
      drawerRightWidth = styling.sizes.context.drawer.openWidth;
    }
    if (isContextualDisabled) {
      drawerRightWidth = 0;
    }
    return {
      '--drawerSpaceDrawerMainWidth': styling.utils.px(drawerLeftWidth),
      '--drawerSpaceDrawerContextualWidth': styling.utils.px(drawerRightWidth),
    } as React.CSSProperties;
  }, [
    isMainOpen,
    isMainDisabled,
    isContextualOpen,
    isContextualDisabled,
    isDesktop,
  ]);

  return {
    isMainOpen,
    isMainDisabled,
    isContextualOpen,
    isContextualDisabled,
    style,
    onSetContentRoot,
  };
};

export default useDrawerSpaceContentWrapper;
