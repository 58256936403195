import { useContext } from 'react';

import { FormSubmitButtonDataContext } from '../../FormSubmitButtonDataContext';

const useFormSubmitButtonData = () => {
  const formSubmitButtonDataService = useContext(FormSubmitButtonDataContext);
  return formSubmitButtonDataService;
};

export default useFormSubmitButtonData;
