import { memo } from 'react';
import clsx from 'clsx';
import { Typography } from '@kitted/shared-components';

import useStyles from './styles';
import { InputLabelProps } from './types';

const InputLabel = ({
  className,
  forInput,
  disabled,
  label,
  type,
  theme = 'secondary',
  isRequired = false,
}: InputLabelProps) => {
  const styles = useStyles();

  if (!label || type === 'image') return null;

  return (
    <Typography
      className={clsx(
        styles.label,
        styles[`theme-${theme}`],
        disabled && styles.disabled,
        className
      )}
      htmlFor={forInput}
      variant="inputLabel"
      as="label"
    >
      {label}
      {isRequired && (
        <Typography variant="inputLabelRequiredIndicator" as="span">
          *
        </Typography>
      )}
    </Typography>
  );
};

export default memo(InputLabel);
