import { memo } from 'react';
import clsx from 'clsx';

import useCardFooterStripe from './hooks/useCardFooterStripe';
import useStyles from './styles';
import { CardFooterStripeProps } from './types';

const CardFooterStripe = ({
  background,
  isMenuOpen,
}: CardFooterStripeProps) => {
  const styles = useStyles();
  const { style } = useCardFooterStripe(background);

  return (
    <div
      className={clsx(styles.stripe, isMenuOpen && styles.stripeMenuOpen)}
      style={style}
    />
  );
};

export default memo(CardFooterStripe);
