import { memo } from 'react';
import clsx from 'clsx';

import useDrawerSpaceContentWrapper from './hooks/useDrawerSpaceContentWrapper';
import useStyles from './styles';
import { DrawerSpaceContentWrapperProps } from './types';

const DrawerSpaceContentWrapper = ({
  className,
  children,
  isContentRoot,
}: DrawerSpaceContentWrapperProps) => {
  const styles = useStyles();
  const {
    isMainOpen,
    isMainDisabled,
    isContextualOpen,
    isContextualDisabled,
    style,
    onSetContentRoot,
  } = useDrawerSpaceContentWrapper();

  return (
    <div
      ref={isContentRoot ? onSetContentRoot : undefined}
      className={clsx(
        className,
        styles.contentContainer,
        isMainOpen && styles.contentContainerMainOpen,
        isContextualOpen && styles.contentContainerContextualOpen,
        isMainDisabled && styles.contentContainerMainDisabled,
        isContextualDisabled && styles.contentContainerContextualDisabled
      )}
      style={style}
    >
      {children}
    </div>
  );
};

export default memo(DrawerSpaceContentWrapper);
