import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const ChevronsDown: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 22 22"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M1.82 10.9 11 20.08l9.18-9.18" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M1.82 1.72 11 10.9l9.18-9.18" />
    </g>
  </WebsiteIcon>
);

export default memo(ChevronsDown);
