/* eslint-disable react/no-array-index-key */
import { memo } from 'react';

import useStoreInitializers from './hooks/useStoreInitializers';
import { StoreInitializersProps } from './types';

const StoreInitializers = ({ children }: StoreInitializersProps) => {
  const { isLoading, initializers, onSectionLoaded } = useStoreInitializers();

  return (
    <>
      {initializers.map((Initializer, index) => (
        <Initializer key={index} onSectionLoaded={onSectionLoaded} />
      ))}
      {!isLoading && children}
    </>
  );
};

export default memo(StoreInitializers);
