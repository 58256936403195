import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Trash: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 22 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M6.81 5.22V3.01c0-.89.73-1.62 1.62-1.62h5.13c.89 0 1.62.73 1.62 1.62v2.21M19.68 7.79l-.65 16.98c-.04 1.02-.91 1.85-1.93 1.85H5.28c-1.02 0-1.9-.83-1.96-1.85l-1-16.98M.56 5.31h20.88M11 23.88V7.74M14.7 23.88l.44-16.15M6.86 7.75l.45 16.13" />
    </g>
  </WebsiteIcon>
);

export default memo(Trash);
