import { isArray, upperFirst } from 'lodash';
import { EMPTY_OBJ } from '@kitted/shared-utils';
import { PageTitle, routesFlat } from '@kitted/website-constants';

import { SEOHeadProps } from '../../types';

export const getTitleFromRouteTitle = (
  title: PageTitle,
  params: Record<string, string | undefined>,
  resolveArray = true
): string => {
  if (isArray(title)) {
    if (!resolveArray) {
      return getTitleFromRouteTitle(title[0], params, resolveArray);
    }
    return title
      .map((titlePart) =>
        getTitleFromRouteTitle(titlePart, params, resolveArray)
      )
      .flat()
      .filter((titlePart) => !!titlePart)
      .join(' - ');
  }
  if (typeof title === 'string') {
    return upperFirst(title);
  }
  if (typeof title === 'function') {
    return getTitleFromRouteTitle(title(params), params, resolveArray);
  }
  return '';
};

export const getPageTitleFromRouteKey = (
  routeKey: SEOHeadProps['routeKey'],
  params: Record<string, string | undefined>,
  paramOverrides: SEOHeadProps['paramOverrides'],
  isLoading = false
) => {
  if (!(routeKey in routesFlat)) return null;
  const route = routesFlat[routeKey];
  if (isLoading) {
    return getTitleFromRouteTitle(route.title, EMPTY_OBJ);
  }
  return getTitleFromRouteTitle(route.title, { ...params, ...paramOverrides });
};
