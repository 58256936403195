import { createUseStyles } from 'react-jss';

import typographyConstants from '../../Typography/constants';

export default createUseStyles(
  {
    prefix: {
      display: 'block',
      height: 0,
      transform: `translateY(calc((${typographyConstants.cardTitle.lineHeight} + 10px) * -1))`,
    },
    prefixFeatured: {
      transform: `translateY(calc((${typographyConstants.cardTitleFeatured.lineHeight} + 15px) * -1))`,
    },
  },
  { name: 'cardTitle' }
);
