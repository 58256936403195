import { useLayoutEffect } from 'react';
import { useIsServer } from '@kitted/shared-components';

import { removeDefaulSEOHeadValues } from './logic';

const useRemoveDefaultSEOHeadValues = () => {
  const isServer = useIsServer();

  useLayoutEffect(() => {
    if (isServer) return;

    removeDefaulSEOHeadValues();
  }, [isServer]);
};

export default useRemoveDefaultSEOHeadValues;
