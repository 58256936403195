import { memo } from 'react';
import clsx from 'clsx';

import AccountAvatar from '../../../../../../components/AccountAvatar';
import { AccountAvatarSize } from '../../../../../../components/AccountAvatar/Enum';
import useAccountMenuItemIcon from './hooks/useAccountMenuItemIcon';
import useStyles from './styles';

const AccountMenuItemIcon = () => {
  const {
    initials,
    accountImageAsset,
    isLoggingIn,
    isLoading,
    linkIsSelected,
  } = useAccountMenuItemIcon();
  const styles = useStyles();

  return (
    <div
      className={clsx(
        styles.container,
        linkIsSelected && styles.containerSelected
      )}
    >
      <AccountAvatar
        className={styles.avatar}
        imageAsset={accountImageAsset}
        initials={initials}
        isLoading={isLoading || isLoggingIn}
        size={AccountAvatarSize.Small}
      />
    </div>
  );
};

export default memo(AccountMenuItemIcon);
