import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const CircleCheckBreak: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="m8.36 11.68 5.3 7.22L26.44 4.36" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M25.86 11.64c.15.76.23 1.55.23 2.36 0 6.77-5.49 12.27-12.26 12.27S1.56 20.77 1.56 14 7.05 1.73 13.82 1.73c2.35 0 4.54.66 6.41 1.81" />
    </g>
  </WebsiteIcon>
);

export default memo(CircleCheckBreak);
