import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      ...styling.utils.absoluteFull(),
      backgroundSize: 'cover',
      backgroundColor: styling.colors.theme.primary900_30,
      zIndex: 0,
      borderRadius: styling.sizes.context.card.radius,
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 256,
        zIndex: 2,
        background: styling.colors.context.card.bottomGradient,
        mixBlendMode: 'multiply',
      },
    },
    containerIsFeatured: {
      borderRadius: styling.sizes.context.cardFeature.radius,
    },
    halfHeight: {
      bottom: '50%',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      '&:after': {
        content: 'unset',
      },
    },
  },
  { name: 'cardImage' }
);
