/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';

import useEditDrawerSpaceContentBody from './hooks/useEditDrawerSpaceContentBody';
import useStyles from './styles';

const EditDrawerSpaceContentBody = () => {
  const styles = useStyles();
  const { BodyComponent, spaceProps, onSetBodyRef } =
    useEditDrawerSpaceContentBody();

  return (
    <div ref={onSetBodyRef} className={styles.container}>
      {BodyComponent && <BodyComponent {...spaceProps} />}
    </div>
  );
};

export default memo(EditDrawerSpaceContentBody);
