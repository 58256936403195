import { useMemo } from 'react';
import { CardType } from '@kitted/kitted-models';
import { extractTypeFromSlug, noOrphans } from '@kitted/shared-utils';

import { slugPrefixIcons } from '../../constants';
import { CardWideProps } from '../../types';

const useCardWide = ({
  authorName,
  type,
  subType,
  body,
  title,
  slug,
}: {
  authorName: CardWideProps['authorName'];
  type: CardWideProps['type'];
  subType: CardWideProps['subType'];
  body: CardWideProps['body'];
  title: CardWideProps['title'];
  slug: CardWideProps['slug'];
}) => {
  const cardTypeBody = useMemo(() => {
    const name = authorName || 'Kitted Thinking Tools';
    if (type === CardType.Resource) {
      switch (subType) {
        case 'image':
          return `This image was uploaded by ${name}`;
        case 'link':
          return `This link card was created by ${name}`;
        case 'video':
          return `This video card was created by ${name}`;
        default:
          return '';
      }
    }
    return body || '';
  }, [body, type, subType, authorName]);

  const noOrphansBody = useMemo(
    // replace last space with non breaking space
    () => noOrphans(cardTypeBody),
    [cardTypeBody]
  );

  const cardTypeTitle = useMemo(() => {
    if (type === CardType.Resource) {
      return `${subType} Resource`;
    }
    return title;
  }, [title, type, subType]);

  const SlugTypeIcon = useMemo(() => {
    const slugPrefix = extractTypeFromSlug(slug);
    return slugPrefixIcons[slugPrefix];
  }, [slug]);

  return {
    noOrphansBody,
    cardTypeTitle,
    SlugTypeIcon,
  };
};

export default useCardWide;
