import { EMPTY_OBJ, humanFileSize } from '@kitted/shared-utils';

import { AcceptedFileFormats } from '../../../../../Input/InputImage/types';

export const getHelperText = (maxSize: number, accept: AcceptedFileFormats) => {
  const helperText = [];
  let extensions = Object.values(accept || EMPTY_OBJ).flat();
  if (extensions.length) {
    // replace '.' and uppercase
    extensions = extensions.map((ext) => ext.replace('.', '').toUpperCase());
    if (extensions.length > 1) {
      // comma join all but last
      // join last element with "or"
      helperText.push(
        `${extensions.slice(0, -1).join(', ')} or ${extensions.at(-1)}.`
      );
    }
  }
  if (maxSize) {
    helperText.push(`${humanFileSize(maxSize, false, 0)} maximum.`);
  }

  return helperText.join(' ');
};
