import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Home: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 24"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="m14 4.97-8.77 7.85v8.26c0 .97.79 1.76 1.76 1.76h4.74v-5.7h4.53v5.7H21c.97 0 1.76-.79 1.76-1.76v-8.26l-8.77-7.85Z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M26.62 12.16 14 1.16 8.06 6.32v-3.3H5.32v5.69l-3.94 3.45" />
    </g>
  </WebsiteIcon>
);

export default memo(Home);
