import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { handleOnToggleOpen } from '../../logic';

const useFixedMobileMenu = () => {
  const hasBeenOpenRef = useRef(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const { pathname } = useLocation();

  const setHasBeenOpen = useCallback(() => {
    hasBeenOpenRef.current = true;
  }, []);

  const onToggleOpen = useCallback(
    () => handleOnToggleOpen(setIsOpen, setHasBeenOpen),
    [setIsOpen, setHasBeenOpen]
  );

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    onClose();
  }, [onClose, pathname]);

  useEffect(() => {
    if (isOpen && hasBeenOpenRef.current) {
      setIsClosing(true);
    }
  }, [isOpen, setIsClosing]);

  useEffect(() => {
    if (isOpen) {
      window.document.scrollingElement?.classList.add('lock-scroll-mobileMenu');
    } else {
      window.document.scrollingElement?.classList.remove(
        'lock-scroll-mobileMenu'
      );
    }
    return () => {
      window.document.scrollingElement?.classList.remove(
        'lock-scroll-mobileMenu'
      );
    };
  }, [isOpen]);

  const onExited = useCallback(() => {
    setIsClosing(false);
  }, [setIsClosing]);

  return {
    onExited,
    isOpen,
    isClosing,
    onClose,
    onToggleOpen,
  };
};

export default useFixedMobileMenu;
