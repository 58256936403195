/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import { EMPTY_ARR } from '@kitted/shared-utils';

import Button from '../../Button';
import useModalButtons from './hooks/useModalButtons';
import useStyles from './styles';
import { ModalButtonsProps } from './types';

const ModalButtons = ({ buttons = EMPTY_ARR, onClose }: ModalButtonsProps) => {
  const styles = useStyles();
  const { getInteractionProps } = useModalButtons(onClose);

  if (buttons.length === 0) return null;

  return (
    <div className={styles.container}>
      {buttons.map(({ title, ...rest }) => (
        <Button
          key={title}
          fullWidth
          theme="primary"
          variant="solid"
          {...getInteractionProps(rest)}
        >
          {title}
        </Button>
      ))}
    </div>
  );
};

export default memo(ModalButtons);
