import { z } from 'zod';

import { ToolType } from '../Enum';
import {
  ToolBaseCardLoadedModelSchema,
  ToolBaseCardUnloadedModelSchema,
} from '../ToolBase';

export const FrameToolCardBaseModelSchema = z.object({
  toolType: z.literal(ToolType.Frame),
});

export const FrameToolCardLoadedModelSchema =
  ToolBaseCardLoadedModelSchema.merge(FrameToolCardBaseModelSchema);
export const FrameToolCardUnloadedModelSchema =
  ToolBaseCardUnloadedModelSchema.merge(FrameToolCardBaseModelSchema);

export type FrameToolCardLoadedModel = z.infer<
  typeof FrameToolCardLoadedModelSchema
>;
export type FrameToolCardUnloadedModel = z.infer<
  typeof FrameToolCardUnloadedModelSchema
>;

export type FrameToolCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? FrameToolCardLoadedModel : FrameToolCardUnloadedModel;
