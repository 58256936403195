import { useCallback, useEffect, useState } from 'react';
import { TRUE_OP } from '@kitted/shared-utils';

import { StoreKeysSubscriptionCallback } from '../../services/storeOfStores/types';
import { KittedStoreSectionKey } from '../../types';
import useStoreApi from '../useStoreApi';

const useStoreKeysSubscription = <Key extends string>(
  sectionKey: KittedStoreSectionKey
) => {
  const { getStoreItemKeysWhere, subscribeToKeysChanges } = useStoreApi();
  const [keys, setKeys] = useState<string[]>(
    getStoreItemKeysWhere(sectionKey, TRUE_OP)
  );

  const onKeysChangeCallback = useCallback<StoreKeysSubscriptionCallback>(
    (newKeys: string[]) => {
      setKeys(newKeys);
    },
    [setKeys]
  );

  useEffect(() => {
    const unsubscriber = subscribeToKeysChanges(
      sectionKey,
      onKeysChangeCallback
    );
    return unsubscriber;
  }, [subscribeToKeysChanges, sectionKey, onKeysChangeCallback]);

  return {
    keys: keys as Key[],
  };
};

export default useStoreKeysSubscription;
