import { z } from 'zod';
import {
  FlowSlugSchema,
  ImageAssetModelSchema,
  KittedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '@kitted/kitted-models';
import { DateISOSchema } from '@kitted/platform-models';

import {
  AnyCMSFlowBlockLoadedModelSchema,
  AnyCMSFlowBlockUnloadedModelSchema,
} from './AnyCMSFlowBlockModel';
import {
  CMSFlowStepLoadedModelSchema,
  CMSFlowStepUnloadedModelSchema,
} from './CMSFlowStep';

export const CMSFlowLoadedModelSchema = KittedModelBaseSchema.merge(
  z.object({
    slug: FlowSlugSchema,
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      true
    ).optional(),
    title: z.string(),
    description: z.string(),

    excludeFromDiscovery: z.boolean(),
    live: z.boolean(),

    createdByAuthorId: z.string(),
    createdByUserId: z.string(),

    showThumbnails: z.boolean(),

    createdAt: DateISOSchema,
    updatedAt: DateISOSchema,

    flowBlocks: makeKittedModelReferenceSchema(
      AnyCMSFlowBlockLoadedModelSchema,
      true
    ).array(),

    cmsFlowSteps: makeKittedModelReferenceSchema(
      CMSFlowStepLoadedModelSchema,
      true
    ).array(),
  })
);

export const CMSFlowUnloadedModelSchema = CMSFlowLoadedModelSchema.merge(
  z.object({
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      false
    ).optional(),

    flowBlocks: makeKittedModelReferenceSchema(
      AnyCMSFlowBlockUnloadedModelSchema,
      false
    ).array(),

    cmsFlowSteps: makeKittedModelReferenceSchema(
      CMSFlowStepUnloadedModelSchema,
      false
    ).array(),
  })
);

export type CMSFlowLoadedModel = z.infer<typeof CMSFlowLoadedModelSchema>;
export type CMSFlowUnloadedModel = z.infer<typeof CMSFlowUnloadedModelSchema>;

export type CMSFlowModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? CMSFlowLoadedModel : CMSFlowUnloadedModel;
