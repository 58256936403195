import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    backdrop: {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      backgroundColor: styling.colors.context.background,
      opacity: 0.9,
      mixBlendMode: 'multiply',
    },
    'variant-modal': {
      zIndex: `calc(${styling.zIndex.backdrops.modal} + var(--backdropIndex, 0))`,
    },
    'variant-alert': {
      zIndex: `calc(${styling.zIndex.backdrops.alert} + var(--backdropIndex, 0))`,
    },
    backdropOnClick: {
      cursor: 'pointer',
    },
  },
  { name: 'backdrop' }
);
