import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0047: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 120 98"
		height={98}
		width={120}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M36.22 96.9h47.56M51.74 81.74v14.75M68.26 81.74v14.75M1.54 11.31c0-5.41 4.43-9.84 9.84-9.84h97.24c5.41 0 9.83 4.43 9.83 9.83v60.05c0 5.41-4.43 9.83-9.83 9.83H11.38c-5.41 0-9.83-4.43-9.83-9.83V11.31Z" /></g><circle vectorEffect="non-scaling-stroke" cx="15.55" cy="13.8" r="3.99" stroke={color} /><circle vectorEffect="non-scaling-stroke" cx="29.18" cy="13.8" r="3.99" stroke={color} /><circle vectorEffect="non-scaling-stroke" cx="42.81" cy="13.8" r="3.99" stroke={spot} /><path vectorEffect="non-scaling-stroke" stroke={color} d="M1.54 25.11h116.92" />
    </g>
  </CardIcon>
);

export default memo(Fr0047);
