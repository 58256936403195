import { memo } from 'react';

import { StoreSectionInitializerProps } from '../types';
import useMetadataStoreInitializer from './hooks/useMetadataStoreInitializer';

const MetadataStoreInitializer = ({
  onSectionLoaded,
}: StoreSectionInitializerProps) => {
  useMetadataStoreInitializer(onSectionLoaded);

  return null;
};

export default memo(MetadataStoreInitializer);
