import { useMemo } from 'react';

import { getCardSlugPrefix } from '../../logic';

const useCardSlug = (isFeatured = false) => {
  const prefix = useMemo(() => getCardSlugPrefix(isFeatured), [isFeatured]);

  return {
    prefix,
  };
};

export default useCardSlug;
