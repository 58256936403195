import { useMemo } from 'react';

import { NekuodahProps } from '../../types';
import { getLevels } from './logic';

const useNekuodah = (
  components: NekuodahProps['components'],
  children: NekuodahProps['children']
) => {
  const levels = useMemo(
    () => getLevels(components, children),
    [components, children]
  );

  return levels;
};

export default useNekuodah;
