import { createContext, useMemo } from 'react';
import { NOOP } from '@kitted/shared-utils';

import useTimerManagement from './hooks/useTimerManagement';
import { TimerApi, TimerContextProps } from './types';

export const TimerContext = createContext<TimerApi>({
  addTimerCallback: () => NOOP,
});

export const TimerProvider: React.FC<TimerContextProps> = ({ children }) => {
  const timerManagement = useTimerManagement();
  const value = useMemo(() => timerManagement, [timerManagement]);

  return (
    <TimerContext.Provider value={value}>{children}</TimerContext.Provider>
  );
};
