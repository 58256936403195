import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0024: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 74 114"
		height={114}
		width={74}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M55.39 18.17h12.26c2.25 0 4.09 1.84 4.09 4.09v85.83c0 2.25-1.84 4.09-4.09 4.09H6.35c-2.25 0-4.09-1.84-4.09-4.09V22.26c0-2.25 1.84-4.09 4.09-4.09h20.44" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M45.17 38.61H28.82c-3.39 0-6.13-2.74-6.13-6.13h0c0-3.39 2.74-6.13 6.13-6.13h16.35c3.39 0 6.13 2.74 6.13 6.13h0c0 3.39-2.74 6.13-6.13 6.13" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M43.13 26.35 55.39 1.82H43.13L30.87 26.35M26.78 18.17 18.61 1.82h12.26l3.06 6.13" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m16.56 63.13 6.13 16.35 6.14-16.35M37 63.13v16.35M45.17 79.48V63.13h8.17c2.26 0 4.09 1.83 4.09 4.09h0c0 2.26-1.83 4.09-4.09 4.09h-8.17" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M43.13 50.87h8.18M59.48 50.87c2.26 0 4.09 1.83 4.09 4.09v32.7c0 2.26-1.83 4.09-4.09 4.09H14.52c-2.26 0-4.09-1.83-4.09-4.09v-32.7c0-2.26 1.83-4.09 4.09-4.09h20.44" />
    </g>
  </CardIcon>
);

export default memo(In0024);
