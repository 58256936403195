import colors from './colors';
import speeds from './speeds';

const backgroundSlide = {
  '0%': {
    backgroundPosition: `
      0 0
    `,
  },
  '100%': {
    backgroundPosition: `
      4000px 0
    `,
  },
};

const ripple = {
  '0%': {
    opacity: 0.38,
    scale: 0,
    filter: 'blue(0)',
  },
  '85%': {
    opacity: 0.18,
  },
  '94%': {
    opacity: 0.08,
  },
  '100%': {
    opacity: 0,
    scale: 14,
    filter: 'blue(20px)',
  },
};

const stepIn = {
  '0%': {
    translate: '-105% 0',
  },
  '24.99999%': {
    translate: '-105% 0',
  },
  '25%': {
    translate: '-70% 0',
  },
  '49.99999%': {
    translate: '-70% 0',
  },
  '50%': {
    translate: '-35% 0',
  },
  '74.99999%': {
    translate: '-35% 0',
  },
  '75%': {
    translate: '0% 0',
  },
  '100%': {
    translate: '0% 0',
  },
};

export default {
  context: {
    stepIn: {
      keyframes: {
        ...stepIn,
      },
      useage: {
        display: 'inline-block',
        translate: '-105% 0',
        animationIterationCount: 'infinite',
        animationDuration: speeds.walk,
        animationTimingFunction: 'linear',
      },
    },
    ripple: {
      keyframes: {
        ...ripple,
      },
      useage: {
        opacity: 0.38,
        scale: 0,
        filter: 'blue(0)',
        animationIterationCount: 1,
        animationDuration: speeds.snail,
        animationTimingFunction: 'cubic-bezier(0.095, 0.880, 0.810, 1.000)',
      },
    },
    loaderBackgroundPrimary: {
      keyframes: {
        ...backgroundSlide,
      },
      useage: {
        background: colors.context.loader.primaryGradient,
        backgroundSize: '4000px 100px',
        animationIterationCount: 'infinite',
        animationDuration: speeds.walk,
        animationTimingFunction: 'linear',
      },
    },
    loaderBackgroundSecondary: {
      keyframes: {
        ...backgroundSlide,
      },
      useage: {
        background: colors.context.loader.secondaryGradient,
        backgroundSize: '4000px 100px',
        animationIterationCount: 'infinite',
        animationDuration: speeds.walk,
        animationTimingFunction: 'linear',
      },
    },
  },
};
