import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const FileCircleTimes: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 26 26"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M18.8 11.14V1.53H6.03L1.57 5.98v18.48h12.01" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M8.34 5.27v3.04H5.3M20.92 16.78l-4.18 4.18m4.18 0-4.18-4.18m7.69 2.09c0 3.09-2.5 5.6-5.6 5.6s-5.6-2.51-5.6-5.6 2.51-5.6 5.6-5.6 5.6 2.51 5.6 5.6Z" />
    </g>
  </WebsiteIcon>
);

export default memo(FileCircleTimes);
