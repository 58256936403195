import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      ...styling.utils.absoluteFull(),
      left: 'var(--imageLeft, 0)',
      zIndex: 1,
      overflow: 'hidden',
      transition: styling.transitions.utils.generate(['left']),
    },
    containerNoImageOverlay: {
      '& $image:before': {
        content: 'unset',
      },
    },
    'variant-page': {
      position: 'fixed',
      zIndex: 'unset',
    },
    'variant-pageBlur': {
      '& $image': {
        filter: 'blur(15px)',
      },
    },
    'variant-header': {
      zIndex: 2,
      '--imageTranslateY': '0px',
      '& $image': {
        transform: 'scale(2) translate3d(0, var(--imageTranslateY), 0)',
        transformOrigin: 'center bottom',
        filter: 'blur(15px)',
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 265,
        background: styling.colors.context.images.topGradient,
      },
    },
    image: {
      ...styling.utils.absoluteFull(),
      '&:before': {
        content: '""',
        ...styling.utils.absoluteFull(),
        zIndex: 2,
        opacity: 0.92,
        backgroundColor: styling.colors.context.background,
      },
    },
  },
  { name: 'backgroundImage' }
);
