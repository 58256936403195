import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0055: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 92 108"
		height={108}
		width={92}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M69.71 65.5V24.02M1.58 12.91v62.52c0 6.14 4.97 11.11 11.11 11.11h37.04" /><path vectorEffect="non-scaling-stroke" d="M69.71 1.8H12.69C6.55 1.8 1.58 6.77 1.58 12.91s4.97 11.11 11.11 11.11h57.03" /><path vectorEffect="non-scaling-stroke" d="M69.71 1.8c-6.13 0-11.11 4.97-11.11 11.11s4.97 11.11 11.11 11.11M13.43 12.91h45.18" /></g><g ><path vectorEffect="non-scaling-stroke" stroke={color} d="m35.48 59.72 44.89 44.89a5.38 5.38 0 0 0 3.83 1.58c1.39 0 2.77-.53 3.83-1.58l.8-.8a5.426 5.426 0 0 0 0-7.66L54.35 61.67 43.94 51.26a5.426 5.426 0 0 0-7.66 0l-.8.8a5.426 5.426 0 0 0 0 7.66" /><g stroke={spot}><path vectorEffect="non-scaling-stroke" d="m29.78 47.54-6.38-6.38M25.31 57.39h-9.02M29.11 67.52l-6.38 6.38M39.63 43.07v-9.02M49.76 46.87l6.38-6.38" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="m45.49 69.73 8.46-8.46" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0055);
