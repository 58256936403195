/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';

import CardWideComponent from '../../../components/CardWide';
import { useCardWideComponentProps } from './hooks/useCardWideComponentProps';
import { RenderCardWideComponentProps } from './types';

const RenderCardWideComponent = (props: RenderCardWideComponentProps) => {
  const cardWideProps = useCardWideComponentProps(props);

  return <CardWideComponent {...cardWideProps} />;
};

export default memo(RenderCardWideComponent);
