import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0009: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 104 118"
		height={118}
		width={104}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M20.76 83.32 30.8 66.3h42.31l10.06 17.05m0 0 19.41 33.73H1.42L16.2 91.54M52 .92c3.42 0 6.19 2.77 6.19 6.19S55.42 13.3 52 13.3s-6.19-2.77-6.19-6.19S48.58.92 52 .92m12.12 34.39-1.86-11.8c-.24-2.39-2.41-4.35-4.81-4.35H46.53c-2.4 0-4.57 1.96-4.81 4.35l-1.19 11.81M41.61 67.61l-28.09 48.9M52.52 66.3l-27.11 50.21M63.23 67.61l-25.93 48.9M73.89 67.63l-24.7 48.88M80.7 79.16l-19.62 37.35M87.64 91.11l-14.67 25.4M93.79 101.35l-8.61 15.11" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="m35.43 58 16.6-28.38 16.45 28.16" />
    </g>
  </CardIcon>
);

export default memo(Sn0009);
