import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const CloudCircleCheck: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 24 22"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M18 16.42c2.59 0 4.71-2.12 4.71-4.71v-.18c0-2.59-2.12-4.71-4.71-4.71h-1.2v-.37c0-2.65-2.15-4.8-4.8-4.8-2.22 0-4.09 1.51-4.64 3.55M9.28 8.28c-1-1.04-1.86-1.47-3.37-1.47h-.24c-2.44.18-4.38 2.23-4.38 4.71v.18c0 2.45 1.93 4.69 4.3 4.69M9.58 15.91l1.5 2.08 2.76-3.3" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M9.92 12.42c.57-.29 1.21-.45 1.88-.45 2.31 0 4.19 1.87 4.19 4.19s-1.87 4.19-4.19 4.19-4.19-1.87-4.19-4.19c0-.96.32-1.85.87-2.55" />
    </g>
  </WebsiteIcon>
);

export default memo(CloudCircleCheck);
