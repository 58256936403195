import { uniqueId } from 'lodash';
import { NOOP } from '@kitted/shared-utils';
import {
  AnyWebsocketDownloadClientMessage,
  WebsocketServerMessageType,
} from '@kitted/websocket-service-models';

import { WebsocketApi, WebsocketEvent } from '../WebsocketApi';
import { getAnyWebsocketServerMessageHandler } from './getAnyWebsocketServerMessageHandler';
import { sendAnyWebsocketClientMessage } from './sendAnyWebsocketClientMessage';

export type ResolveDownloadBlobIdViaWebsocketProcessMessage = (
  message: string
) => void;

// This basically used websockets to resolve the blobid that can be downloaded
export const resolveDownloadBlobIdViaWebsocket = async (
  websocketApi: WebsocketApi,
  clientMessage: AnyWebsocketDownloadClientMessage,
  onProcessMessage: ResolveDownloadBlobIdViaWebsocketProcessMessage = NOOP
): Promise<string> => {
  // Make a corrolation for this request if we need it
  const correlationId = clientMessage.correlationId || uniqueId();

  // Request to the server the download we want, and return its blob id when successful
  const blobId = await new Promise<string>((resolve, reject) => {
    // Subscribe to the websocket event that will give us the blob id
    const subscriptionHandle = websocketApi.subscribe(
      WebsocketEvent.MESSAGE,
      getAnyWebsocketServerMessageHandler((message) => {
        if (message.correlationId === correlationId) {
          switch (message.messageType) {
            case WebsocketServerMessageType.DownloadReady:
              websocketApi.unsubscribe(subscriptionHandle);

              if (message.blobId && !message.isError) {
                resolve(message.blobId);
              } else {
                reject();
              }
              break;

            case WebsocketServerMessageType.ProcessMessage:
              onProcessMessage(message.message);
              break;

            default:
              break;
          }
        }
      })
    );

    // Send the request to the server
    sendAnyWebsocketClientMessage(websocketApi, clientMessage);
  });

  return blobId;
};
