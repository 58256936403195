import { Dispatch, SetStateAction } from 'react';

export const handleOnToggleOpen = (
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  setHasBeenOpen: () => void
) => {
  setIsOpen((currentOpen: boolean) => {
    if (!currentOpen) {
      setHasBeenOpen();
    }
    return !currentOpen;
  });
};
