import { useMemo } from 'react';

import rawAnimationData from '../../animationData';
import { applyThemeToAnimationData } from '../../logic';
import { SpinnerTheme } from '../../types';

const useSpinner = (theme: SpinnerTheme) => {
  const animationData = useMemo(
    () => applyThemeToAnimationData(rawAnimationData, theme),
    [theme]
  );

  return {
    animationData,
  };
};

export default useSpinner;
