import { useCallback, useEffect, useMemo, useRef } from 'react';
import lottie, { AnimationItem } from 'lottie-web';

import { calculateStyle, handleLoadAnimation } from '../../logic';
import { LottieProps } from '../../types';

const useLottie = (
  animationData: LottieProps['animationData'],
  width: LottieProps['width'],
  height: LottieProps['height']
) => {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const lottieInstance = useRef<AnimationItem | null>(null);

  const setLottieInstance = useCallback((ref: AnimationItem) => {
    lottieInstance.current = ref;
  }, []);

  useEffect(
    () =>
      handleLoadAnimation({
        elementRef: elementRef.current,
        lottieInstance: lottieInstance.current,
        setLottieInstance,
        animationData,
        loadAnimation: lottie.loadAnimation,
      }),
    [animationData, setLottieInstance]
  );

  const style = useMemo(() => calculateStyle(width, height), [width, height]);

  return {
    elementRef,
    style,
  };
};

export default useLottie;
