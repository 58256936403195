import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      position: 'relative',
      zIndex: styling.zIndex.fixedMobileNav,
      flexGrow: 0,
      flexShrink: 0,
      height: styling.sizes.context.mobileMenu.height,
      background: styling.colors.context.background,
      borderTop: [1, 'solid', styling.colors.theme.primary400],
      minWidth: styling.sizes.context.cardFeature.width,
      '&:before': {
        content: '""',
        position: 'absolute',
        bottom: '100%',
        left: 0,
        right: 0,
        pointerEvents: 'none',
        height: 100,
        background: styling.colors.context.images.bottomGradient,
        mixBlendMode: 'multiply',
        opacity: 0.5,
        transform: 'translateY(-1px)',
        transition: styling.transitions.utils.generate(['opacity']),
      },
    },
  },
  { name: 'editDrawerSpaceMobileMenu' }
);
