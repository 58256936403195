import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0036: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 108 100"
		height={100}
		width={108}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="M60.23 42.68 44.46 71.57l17.28-2.25-16.27 28.95" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m28.93 79.97-3.41 6.06M80 83l-3.41 6.05M76.86 67.45l-3.4 6.05M94.91 66.27l-3.4 6.05M20.41 64.43l-3.4 6.05" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M42.68 55.34H15.14c-7.81 0-14.13-6.59-14.13-14.85 0-3.41 1.36-7.12 3.47-9.73M82.52 31.04l1.17-1.15c4.43-4.3 11.16-5.2 16.56-1.76 6.58 4.19 8.72 13.23 4.76 20.2-2.54 4.48-6.98 6.88-11.57 7H63.9" /><path vectorEffect="non-scaling-stroke" d="M22.11 43.82c-1.45-6.33 1.69-12.71 7.17-15.19M73.32 27.97c3.81-.29 7.92 1.67 10.65 5.5 2.01 2.83 2.88 6.14 2.68 9.21M72.39 42.45c-2.59 1.48-3.57 6.62-2.17 9.36 1 1.96 2.96 3.62 4.94 3.54M33.18 11.71s17.4-21.18 35.23-1.84c0 0 23.49-5.52 24.79 15.65M49.2 40.62s6.73-10.99-.32-18.69" /><path vectorEffect="non-scaling-stroke" d="M51.78 25.95s8.47-6.04 3.8-14.51M21.53 38.19c-5.68-1.77-9.83-7.33-9.83-13.91 0-8.01 6.13-14.5 13.7-14.5 2.72 0 5.27.84 7.4 2.3M33.23 55.08s6.48-8.14 6.48-13.36" /><path vectorEffect="non-scaling-stroke" d="M33.18 11.71s-2.55 3.26-3.31 4.76" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0036);
