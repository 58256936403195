export default (colorArray: number[]) => ({
  v: '5.9.6',
  fr: 60,
  ip: 0,
  op: 30,
  w: 1756,
  h: 1756,
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      fr: 60,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 3,
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 0,
              ix: 11,
            },
            r: {
              a: 0,
              k: 0,
              ix: 10,
            },
            p: {
              a: 0,
              k: [250, 250, 0],
              ix: 2,
              l: 2,
            },
            a: {
              a: 0,
              k: [0, 0, 0],
              ix: 1,
              l: 2,
            },
            s: {
              a: 0,
              k: [100, 100, 100],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 0,
          op: 60,
          st: -60,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11,
            },
            r: {
              a: 0,
              k: 270,
              ix: 10,
            },
            p: {
              a: 0,
              k: [0, 0, 0],
              ix: 2,
              l: 2,
            },
            a: {
              a: 0,
              k: [76, 76, 0],
              ix: 1,
              l: 2,
            },
            s: {
              a: 0,
              k: [303, 303, 100],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          // 9 o'clock
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, -0.678],
                        [0, 0],
                        [0, 0],
                        [-0.479, 0.479],
                        [0, 0],
                        [0.297, 0.297],
                      ],
                      o: [
                        [-0.479, -0.479],
                        [0, 0],
                        [0, 0],
                        [0, 0.677],
                        [0, 0],
                        [0.297, -0.297],
                        [0, 0],
                      ],
                      v: [
                        [-10.197, -21.933],
                        [-11.495, -21.395],
                        [-11.495, -13.044],
                        [-11.495, 21.395],
                        [-10.197, 21.932],
                        [11.198, 0.537],
                        [11.198, -0.538],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: colorArray,
                    ix: 4,
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5,
                  },
                  r: 1,
                  bm: 0,
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [90.916, 45.011],
                    ix: 2,
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3,
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6,
                  },
                  o: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: -10,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 0,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 50,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 60,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 110,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 120,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 170,
                        s: [10],
                      },
                      {
                        t: 180,
                        s: [100],
                      },
                    ],
                    ix: 7,
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4,
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5,
                  },
                },
              ],
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0.677],
                        [0, 0],
                        [0.42, 0],
                        [0, 0],
                        [-0.478, -0.479],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, -0.42],
                        [0, 0],
                        [-0.677, 0],
                        [0, 0],
                        [0.479, 0.478],
                      ],
                      v: [
                        [16.017, 15.001],
                        [16.017, -15.257],
                        [15.257, -16.017],
                        [-15.001, -16.017],
                        [-15.538, -14.719],
                        [14.719, 15.538],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: colorArray,
                    ix: 4,
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5,
                  },
                  r: 1,
                  bm: 0,
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [56.562, 51.405],
                    ix: 2,
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3,
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6,
                  },
                  o: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: -15,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: -5,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 45,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 55,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 105,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 115,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 165,
                        s: [10],
                      },
                      {
                        t: 175,
                        s: [100],
                      },
                    ],
                    ix: 7,
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4,
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5,
                  },
                },
              ],
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.677, 0],
                        [0, 0],
                        [0, 0.42],
                        [0, 0],
                        [0.221, 0.22],
                        [0, 0],
                        [0.297, -0.296],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0.42, 0],
                        [0, 0],
                        [0, -0.312],
                        [0, 0],
                        [-0.297, -0.296],
                        [0, 0],
                        [-0.478, 0.479],
                      ],
                      v: [
                        [-15.001, 11.495],
                        [15.257, 11.495],
                        [16.016, 10.735],
                        [16.016, -1.626],
                        [15.672, -2.458],
                        [6.932, -11.199],
                        [5.857, -11.199],
                        [-15.538, 10.197],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: colorArray,
                    ix: 4,
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5,
                  },
                  r: 1,
                  bm: 0,
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: 0.6,
                          y: 1,
                        },
                        o: {
                          x: 0.26,
                          y: 0,
                        },
                        t: -15,
                        s: [40, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: {
                          x: 0.944,
                          y: 0,
                        },
                        o: {
                          x: 0.66,
                          y: 0,
                        },
                        t: -5,
                        s: [56.562, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: {
                          x: 0.6,
                          y: 1,
                        },
                        o: {
                          x: 0.26,
                          y: 0,
                        },
                        t: 45,
                        s: [40, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: {
                          x: 0.801,
                          y: 0,
                        },
                        o: {
                          x: 0.26,
                          y: 0,
                        },
                        t: 55,
                        s: [56.562, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: {
                          x: 0.6,
                          y: 1,
                        },
                        o: {
                          x: 0.26,
                          y: 0,
                        },
                        t: 105,
                        s: [40, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: {
                          x: 0.944,
                          y: 0,
                        },
                        o: {
                          x: 0.66,
                          y: 0,
                        },
                        t: 115,
                        s: [56.562, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: {
                          x: 0.6,
                          y: 1,
                        },
                        o: {
                          x: 0.26,
                          y: 0,
                        },
                        t: 165,
                        s: [40, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        t: 175,
                        s: [56.562, 17.052],
                      },
                    ],
                    ix: 2,
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3,
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6,
                  },
                  o: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: -15,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: -5,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 45,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 55,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 105,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 115,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 165,
                        s: [10],
                      },
                      {
                        t: 175,
                        s: [100],
                      },
                    ],
                    ix: 7,
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4,
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5,
                  },
                },
              ],
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              hd: false,
            },
          ],
          ip: 0,
          op: 60,
          st: -75,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11,
            },
            r: {
              a: 0,
              k: 180,
              ix: 10,
            },
            p: {
              a: 0,
              k: [0, 0, 0],
              ix: 2,
              l: 2,
            },
            a: {
              a: 0,
              k: [76, 76, 0],
              ix: 1,
              l: 2,
            },
            s: {
              a: 0,
              k: [303, 303, 100],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          // 6 o'clock
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, -0.678],
                        [0, 0],
                        [0, 0],
                        [-0.479, 0.479],
                        [0, 0],
                        [0.297, 0.297],
                      ],
                      o: [
                        [-0.479, -0.479],
                        [0, 0],
                        [0, 0],
                        [0, 0.677],
                        [0, 0],
                        [0.297, -0.297],
                        [0, 0],
                      ],
                      v: [
                        [-10.197, -21.933],
                        [-11.495, -21.395],
                        [-11.495, -13.044],
                        [-11.495, 21.395],
                        [-10.197, 21.932],
                        [11.198, 0.537],
                        [11.198, -0.538],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: colorArray,
                    ix: 4,
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5,
                  },
                  r: 1,
                  bm: 0,
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [90.916, 45.011],
                    ix: 2,
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3,
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6,
                  },
                  o: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: -25,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: -15,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 35,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 45,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 95,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 105,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 155,
                        s: [10],
                      },
                      {
                        t: 165,
                        s: [100],
                      },
                    ],
                    ix: 7,
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4,
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5,
                  },
                },
              ],
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0.677],
                        [0, 0],
                        [0.42, 0],
                        [0, 0],
                        [-0.478, -0.479],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, -0.42],
                        [0, 0],
                        [-0.677, 0],
                        [0, 0],
                        [0.479, 0.478],
                      ],
                      v: [
                        [16.017, 15.001],
                        [16.017, -15.257],
                        [15.257, -16.017],
                        [-15.001, -16.017],
                        [-15.538, -14.719],
                        [14.719, 15.538],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: colorArray,
                    ix: 4,
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5,
                  },
                  r: 1,
                  bm: 0,
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [56.562, 51.405],
                    ix: 2,
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3,
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6,
                  },
                  o: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: -30,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: -20,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 30,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 40,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 90,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 100,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 150,
                        s: [10],
                      },
                      {
                        t: 160,
                        s: [100],
                      },
                    ],
                    ix: 7,
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4,
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5,
                  },
                },
              ],
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.677, 0],
                        [0, 0],
                        [0, 0.42],
                        [0, 0],
                        [0.221, 0.22],
                        [0, 0],
                        [0.297, -0.296],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0.42, 0],
                        [0, 0],
                        [0, -0.312],
                        [0, 0],
                        [-0.297, -0.296],
                        [0, 0],
                        [-0.478, 0.479],
                      ],
                      v: [
                        [-15.001, 11.495],
                        [15.257, 11.495],
                        [16.016, 10.735],
                        [16.016, -1.626],
                        [15.672, -2.458],
                        [6.932, -11.199],
                        [5.857, -11.199],
                        [-15.538, 10.197],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: colorArray,
                    ix: 4,
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5,
                  },
                  r: 1,
                  bm: 0,
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: 0.6,
                          y: 1,
                        },
                        o: {
                          x: 0.26,
                          y: 0,
                        },
                        t: -30,
                        s: [40, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: {
                          x: 0.944,
                          y: 0,
                        },
                        o: {
                          x: 0.66,
                          y: 0,
                        },
                        t: -20,
                        s: [56.562, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: {
                          x: 0.6,
                          y: 1,
                        },
                        o: {
                          x: 0.26,
                          y: 0,
                        },
                        t: 30,
                        s: [40, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: {
                          x: 0.801,
                          y: 0,
                        },
                        o: {
                          x: 0.26,
                          y: 0,
                        },
                        t: 40,
                        s: [56.562, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: {
                          x: 0.6,
                          y: 1,
                        },
                        o: {
                          x: 0.26,
                          y: 0,
                        },
                        t: 90,
                        s: [40, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: {
                          x: 0.944,
                          y: 0,
                        },
                        o: {
                          x: 0.66,
                          y: 0,
                        },
                        t: 100,
                        s: [56.562, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: {
                          x: 0.6,
                          y: 1,
                        },
                        o: {
                          x: 0.26,
                          y: 0,
                        },
                        t: 150,
                        s: [40, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        t: 160,
                        s: [56.562, 17.052],
                      },
                    ],
                    ix: 2,
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3,
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6,
                  },
                  o: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: -30,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: -20,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 30,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 40,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 90,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 100,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 150,
                        s: [10],
                      },
                      {
                        t: 160,
                        s: [100],
                      },
                    ],
                    ix: 7,
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4,
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5,
                  },
                },
              ],
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              hd: false,
            },
          ],
          ip: 0,
          op: 60,
          st: -90,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11,
            },
            r: {
              a: 0,
              k: 90,
              ix: 10,
            },
            p: {
              a: 0,
              k: [0, 0, 0],
              ix: 2,
              l: 2,
            },
            a: {
              a: 0,
              k: [76, 76, 0],
              ix: 1,
              l: 2,
            },
            s: {
              a: 0,
              k: [303, 303, 100],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          // 3 o'clock
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, -0.678],
                        [0, 0],
                        [0, 0],
                        [-0.479, 0.479],
                        [0, 0],
                        [0.297, 0.297],
                      ],
                      o: [
                        [-0.479, -0.479],
                        [0, 0],
                        [0, 0],
                        [0, 0.677],
                        [0, 0],
                        [0.297, -0.297],
                        [0, 0],
                      ],
                      v: [
                        [-10.197, -21.933],
                        [-11.495, -21.395],
                        [-11.495, -13.044],
                        [-11.495, 21.395],
                        [-10.197, 21.932],
                        [11.198, 0.537],
                        [11.198, -0.538],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: colorArray,
                    ix: 4,
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5,
                  },
                  r: 1,
                  bm: 0,
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [90.916, 45.011],
                    ix: 2,
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3,
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6,
                  },
                  o: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: -40,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: -30,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 20,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 30,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 80,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 90,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 140,
                        s: [10],
                      },
                      {
                        t: 150,
                        s: [100],
                      },
                    ],
                    ix: 7,
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4,
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5,
                  },
                },
              ],
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0.677],
                        [0, 0],
                        [0.42, 0],
                        [0, 0],
                        [-0.478, -0.479],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, -0.42],
                        [0, 0],
                        [-0.677, 0],
                        [0, 0],
                        [0.479, 0.478],
                      ],
                      v: [
                        [16.017, 15.001],
                        [16.017, -15.257],
                        [15.257, -16.017],
                        [-15.001, -16.017],
                        [-15.538, -14.719],
                        [14.719, 15.538],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: colorArray,
                    ix: 4,
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5,
                  },
                  r: 1,
                  bm: 0,
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [56.562, 51.405],
                    ix: 2,
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3,
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6,
                  },
                  o: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: -45,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: -35,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 15,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 25,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 75,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 85,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 135,
                        s: [10],
                      },
                      {
                        t: 145,
                        s: [100],
                      },
                    ],
                    ix: 7,
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4,
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5,
                  },
                },
              ],
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.677, 0],
                        [0, 0],
                        [0, 0.42],
                        [0, 0],
                        [0.221, 0.22],
                        [0, 0],
                        [0.297, -0.296],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0.42, 0],
                        [0, 0],
                        [0, -0.312],
                        [0, 0],
                        [-0.297, -0.296],
                        [0, 0],
                        [-0.478, 0.479],
                      ],
                      v: [
                        [-15.001, 11.495],
                        [15.257, 11.495],
                        [16.016, 10.735],
                        [16.016, -1.626],
                        [15.672, -2.458],
                        [6.932, -11.199],
                        [5.857, -11.199],
                        [-15.538, 10.197],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: colorArray,
                    ix: 4,
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5,
                  },
                  r: 1,
                  bm: 0,
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: 0.6,
                          y: 1,
                        },
                        o: {
                          x: 0.26,
                          y: 0,
                        },
                        t: -45,
                        s: [40, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: {
                          x: 0.944,
                          y: 0,
                        },
                        o: {
                          x: 0.66,
                          y: 0,
                        },
                        t: -35,
                        s: [56.562, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: {
                          x: 0.6,
                          y: 1,
                        },
                        o: {
                          x: 0.26,
                          y: 0,
                        },
                        t: 15,
                        s: [40, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: {
                          x: 0.944,
                          y: 0,
                        },
                        o: {
                          x: 0.26,
                          y: 0,
                        },
                        t: 25,
                        s: [56.562, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: {
                          x: 0.6,
                          y: 1,
                        },
                        o: {
                          x: 0.26,
                          y: 0,
                        },
                        t: 75,
                        s: [40, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        t: 85,
                        s: [56.562, 17.052],
                      },
                    ],
                    ix: 2,
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3,
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6,
                  },
                  o: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: -45,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: -35,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 15,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 25,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 75,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 85,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 135,
                        s: [10],
                      },
                      {
                        t: 145,
                        s: [100],
                      },
                    ],
                    ix: 7,
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4,
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5,
                  },
                },
              ],
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              hd: false,
            },
          ],
          ip: 0,
          op: 60,
          st: -105,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 0,
              k: 100,
              ix: 11,
            },
            r: {
              a: 0,
              k: 0,
              ix: 10,
            },
            p: {
              a: 0,
              k: [0, 0, 0],
              ix: 2,
              l: 2,
            },
            a: {
              a: 0,
              k: [76, 76, 0],
              ix: 1,
              l: 2,
            },
            s: {
              a: 0,
              k: [303, 303, 100],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          // 12 o'clock
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, -0.678],
                        [0, 0],
                        [0, 0],
                        [-0.479, 0.479],
                        [0, 0],
                        [0.297, 0.297],
                      ],
                      o: [
                        [-0.479, -0.479],
                        [0, 0],
                        [0, 0],
                        [0, 0.677],
                        [0, 0],
                        [0.297, -0.297],
                        [0, 0],
                      ],
                      v: [
                        [-10.197, -21.933],
                        [-11.495, -21.395],
                        [-11.495, -13.044],
                        [-11.495, 21.395],
                        [-10.197, 21.932],
                        [11.198, 0.537],
                        [11.198, -0.538],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: colorArray,
                    ix: 4,
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5,
                  },
                  r: 1,
                  bm: 0,
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [90.916, 45.011],
                    ix: 2,
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3,
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6,
                  },
                  o: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: -55,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: -45,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 5,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 15,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 65,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.833],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 75,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 125,
                        s: [10],
                      },
                      {
                        t: 135,
                        s: [100],
                      },
                    ],
                    ix: 7,
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4,
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5,
                  },
                },
              ],
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0.677],
                        [0, 0],
                        [0.42, 0],
                        [0, 0],
                        [-0.478, -0.479],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, -0.42],
                        [0, 0],
                        [-0.677, 0],
                        [0, 0],
                        [0.479, 0.478],
                      ],
                      v: [
                        [16.017, 15.001],
                        [16.017, -15.257],
                        [15.257, -16.017],
                        [-15.001, -16.017],
                        [-15.538, -14.719],
                        [14.719, 15.538],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: colorArray,
                    ix: 4,
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5,
                  },
                  r: 1,
                  bm: 0,
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {
                    a: 0,
                    k: [56.562, 51.405],
                    ix: 2,
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3,
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6,
                  },
                  o: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: -60,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: -50,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 0,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 10,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 60,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 70,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 120,
                        s: [10],
                      },
                      {
                        t: 130,
                        s: [100],
                      },
                    ],
                    ix: 7,
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4,
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5,
                  },
                },
              ],
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.677, 0],
                        [0, 0],
                        [0, 0.42],
                        [0, 0],
                        [0.221, 0.22],
                        [0, 0],
                        [0.297, -0.296],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0.42, 0],
                        [0, 0],
                        [0, -0.312],
                        [0, 0],
                        [-0.297, -0.296],
                        [0, 0],
                        [-0.478, 0.479],
                      ],
                      v: [
                        [-15.001, 11.495],
                        [15.257, 11.495],
                        [16.016, 10.735],
                        [16.016, -1.626],
                        [15.672, -2.458],
                        [6.932, -11.199],
                        [5.857, -11.199],
                        [-15.538, 10.197],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: colorArray,
                    ix: 4,
                  },
                  o: {
                    a: 0,
                    k: 100,
                    ix: 5,
                  },
                  r: 1,
                  bm: 0,
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: 0.6,
                          y: 1,
                        },
                        o: {
                          x: 0.26,
                          y: 0,
                        },
                        t: -60,
                        s: [40, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: {
                          x: 0.944,
                          y: 0,
                        },
                        o: {
                          x: 0.66,
                          y: 0,
                        },
                        t: -50,
                        s: [56.562, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: {
                          x: 0.6,
                          y: 1,
                        },
                        o: {
                          x: 0.26,
                          y: 0,
                        },
                        t: 0,
                        s: [40, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: {
                          x: 0.944,
                          y: 0,
                        },
                        o: {
                          x: 0.26,
                          y: 0,
                        },
                        t: 10,
                        s: [56.562, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        i: {
                          x: 0.6,
                          y: 1,
                        },
                        o: {
                          x: 0.26,
                          y: 0,
                        },
                        t: 60,
                        s: [40, 17.052],
                        to: [0, 0],
                        ti: [0, 0],
                      },
                      {
                        t: 70,
                        s: [56.562, 17.052],
                      },
                    ],
                    ix: 2,
                  },
                  a: {
                    a: 0,
                    k: [0, 0],
                    ix: 1,
                  },
                  s: {
                    a: 0,
                    k: [100, 100],
                    ix: 3,
                  },
                  r: {
                    a: 0,
                    k: 0,
                    ix: 6,
                  },
                  o: {
                    a: 1,
                    k: [
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: -60,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: -50,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 0,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 10,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 60,
                        s: [10],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.167],
                          y: [0.641],
                        },
                        t: 70,
                        s: [100],
                      },
                      {
                        i: {
                          x: [0.48],
                          y: [1],
                        },
                        o: {
                          x: [0.26],
                          y: [1],
                        },
                        t: 120,
                        s: [10],
                      },
                      {
                        t: 130,
                        s: [100],
                      },
                    ],
                    ix: 7,
                  },
                  sk: {
                    a: 0,
                    k: 0,
                    ix: 4,
                  },
                  sa: {
                    a: 0,
                    k: 0,
                    ix: 5,
                  },
                },
              ],
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              hd: false,
            },
          ],
          ip: 0,
          op: 61,
          st: -120,
          ct: 1,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      refId: 'comp_0',
      sr: 0.5,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [878, 878, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [250, 250, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 500,
      h: 500,
      ip: 0,
      op: 30,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
});
