import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0032: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 110 126"
		height={126}
		width={110}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M42.46 61.7H56.5M64.68 50.28 56.49 61.7l8.19 11.42" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m73.22 39.58 7.91-11.6M76.31 14.79l4.82 13.19 14.04-.32" /></g><circle vectorEffect="non-scaling-stroke" cx="68.67" cy="45.08" r="6.65" stroke={color} /><circle vectorEffect="non-scaling-stroke" cx="73.22" cy="8.14" r="6.65" stroke={color} /><circle vectorEffect="non-scaling-stroke" cx="101.83" cy="28.04" r="6.65" stroke={color} /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m73.22 86.42 7.91 11.6M76.31 111.21l4.82-13.19 14.04.32" /></g><circle vectorEffect="non-scaling-stroke" cx="73.22" cy="117.86" r="6.65" stroke={color} /><circle vectorEffect="non-scaling-stroke" cx="101.83" cy="97.96" r="6.65" stroke={color} /><circle vectorEffect="non-scaling-stroke" cx="68.67" cy="79.77" r="6.65" stroke={color} /><circle vectorEffect="non-scaling-stroke" cx="19.54" cy="61.7" r="18.02" stroke={spot} />
    </g>
  </CardIcon>
);

export default memo(Ac0032);
