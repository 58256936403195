import { memo } from 'react';

import { useAssetCatalogueData } from '../contexts/AssetCatalogueProvider';
import CatalogueItems from './CatalogueItems';
import EmptyCatalogueItems from './EmptyCatalogueItems';
import LoadingCatalogueItems from './LoadingCatalogueItems';
import useStyles from './styles';
import { CatalogueItemGridProps } from './types';

const CatalogueItemGrid = ({ catalogueItems }: CatalogueItemGridProps) => {
  const styles = useStyles();
  const { isLoading } = useAssetCatalogueData();

  return (
    <div className={styles.wrapper}>
      <div className={styles.imageGrid}>
        {isLoading && <LoadingCatalogueItems />}
        {!isLoading && <CatalogueItems catalogueItems={catalogueItems} />}
      </div>
      {!isLoading && catalogueItems.length === 0 && <EmptyCatalogueItems />}
    </div>
  );
};

export default memo(CatalogueItemGrid);
