import { AnyCardModel, AuthorCardModel } from '@kitted/card-service-models';
import { KittedServiceEnum, Slug } from '@kitted/kitted-models';

import useRequests from '../../contexts/RequestsContext/hooks/useRequests';
import useAsyncRequest from '../useAsyncRequest';

const useCardsRequests = () => {
  const { platformRequest } = useRequests();
  const [requestAllCards, requestAllCardsState] = useAsyncRequest<
    AnyCardModel[]
  >(() =>
    platformRequest(KittedServiceEnum.Card, {
      method: 'GET',
      url: '/v1/allCards',
    })
  );

  const [requestMyCards, requestMyCardsState] = useAsyncRequest<AnyCardModel[]>(
    () =>
      platformRequest(KittedServiceEnum.Card, {
        method: 'GET',
        url: '/v1/allCards/me',
      })
  );

  const [requestCard, requestCardState] = useAsyncRequest<AnyCardModel>(
    (cardId: string) =>
      platformRequest(KittedServiceEnum.Card, {
        method: 'GET',
        url: `/v1/card/${cardId}`,
      })
  );

  const [requestAuthorCard, requestAuthorCardState] =
    useAsyncRequest<AuthorCardModel>((authorId: string) =>
      platformRequest(KittedServiceEnum.Card, {
        method: 'GET',
        url: `/v1/card/author/${authorId}`,
      })
    );

  const [requestIdsForSlugs, requestIdsForSlugsState] = useAsyncRequest<
    string[]
  >((slugs: Slug[]) =>
    platformRequest(KittedServiceEnum.Card, {
      method: 'GET',
      url: '/v1/idsForSlugs',
      params: {
        slugs: slugs.join(','),
      },
    })
  );

  return {
    requestAllCards,
    requestAllCardsState,
    requestMyCards,
    requestMyCardsState,
    requestCard,
    requestCardState,
    requestAuthorCard,
    requestAuthorCardState,
    requestIdsForSlugs,
    requestIdsForSlugsState,
  };
};

export default useCardsRequests;
