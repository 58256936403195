import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    alert: {
      cursor: 'default',
      width: '100%',
      maxWidth: 494,
      margin: 'auto',
      background: styling.colors.theme.pureWhite,
      borderRadius: styling.sizes.context.card.radius,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      border: [1, 'solid', styling.colors.theme.primary400],
    },
    topBar: {
      backgroundColor: styling.colors.theme.primary900,
      color: styling.colors.context.color,
      padding: styling.sizes.theme.spacing.md,
      borderTopLeftRadius: styling.sizes.context.card.radius,
      borderTopRightRadius: styling.sizes.context.card.radius,
      position: 'relative',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    iconContainer: {
      width: 48,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      marginBottom: styling.sizes.theme.spacing.sm,
    },
    icon: {
      position: 'relative',
      height: 48,
      width: 48,
      borderRadius: 48,
      color: styling.colors.context.color,
      backgroundColor: styling.colors.theme.action800,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    body: {
      backgroundColor: styling.colors.theme.pureWhite,
      color: styling.colors.context.inverted.color,
      borderBottomLeftRadius: styling.sizes.context.card.radius,
      borderBottomRightRadius: styling.sizes.context.card.radius,
      padding: styling.sizes.theme.spacing.md,
    },
    divider: {
      height: 1,
      backgroundColor: styling.colors.theme.primary900_20,
      marginInline: -1 * styling.sizes.theme.spacing.md,
      marginBlock: styling.sizes.theme.spacing.md,
    },
  },
  { name: 'defaultAlertContent' }
);
