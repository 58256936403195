import { useEffect } from 'react';
import { CardProps } from '@kitted/shared-components';

import { useRoutePreloadApi } from '../../../../contexts/RoutePreloadContext';

const usePreloadingCard = (to: CardProps['to']) => {
  const { preloadPath } = useRoutePreloadApi();

  useEffect(() => {
    if (to) {
      preloadPath(to.toString());
    }
  }, [preloadPath, to]);
};

export default usePreloadingCard;
