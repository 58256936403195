import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      height: styling.sizes.context.mobileMenu.height,
      left: 0,
      right: 0,
      zIndex: styling.zIndex.fixedMobileNav,
      background: styling.colors.context.background,
      borderTop: [1, 'solid', styling.colors.theme.primary400],
      minWidth: styling.sizes.context.cardFeature.width,
    },
    containerOpen: {
      '&:before': {
        opacity: 0,
      },
    },
    menuContainer: {
      top: 0,
      left: 0,
      right: 0,
      zIndex: styling.zIndex.fixedMobileNav - 1,
      minWidth: styling.sizes.context.cardFeature.width,
    },
    menuInner: {
      ...styling.utils.absoluteFull(),
      paddingBottom: styling.sizes.context.mobileMenu.height,
      background: styling.colors.context.background,
      display: 'flex',
      flexDirection: 'column',
    },
    popover: {
      ...styling.utils.absoluteFull(),
      paddingBottom: styling.sizes.context.mobileMenu.height,
      background: styling.colors.theme.transparent,
      display: 'flex',
      flexDirection: 'column',
    },
    fixedMobileMenuSpacer: {
      height: styling.sizes.context.mobileMenu.height,
    },
  },
  { name: 'fixedMobileMenu' }
);
