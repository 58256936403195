import { createContext } from 'react';
import { NOOP } from '@kitted/shared-utils';

import { CatalogueSelectorViewApi } from './types';

export const AssetCatalogueApiContext = createContext<CatalogueSelectorViewApi>(
  {
    getInitalState: NOOP,
    setFilter: NOOP,
    setSearchText: NOOP,
    setGalleryItems: NOOP,
    setPlatformImages: NOOP,
    setMyImages: NOOP,
    setSelectedCatalogueItem: NOOP,
    setIsLoading: NOOP,
    setUploadedBlobGuid: NOOP,
  }
);
