import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    drawerContainer: {
      left: 0,
      top: 0,
      zIndex: styling.zIndex.drawerMainMenu,
      width: styling.sizes.context.drawer.closedWidth + 1,
      transition: styling.transitions.utils.generate(['width']),
      borderRight: [1, 'solid', styling.colors.theme.primary700],
      background: styling.colors.theme.primary900,
      display: 'flex',
      flexDirection: 'column',
    },
    drawerRight: {
      left: 'unset',
      right: 0,
      borderRight: 'none',
      borderLeft: [1, 'solid', styling.colors.theme.primary700],
    },
    drawerContainerOpen: {
      width: styling.sizes.context.drawer.openWidth,
    },
    drawerUnderlay: {
      left: 0,
      top: 0,
      zIndex: styling.zIndex.drawerMainMenu,
      right: 0,
      backgroundColor: styling.colors.theme.transparent,
      opacity: 0.9,
      mixBlendMode: 'multiply',
      pointerEvents: 'none',
      transition: styling.transitions.utils.generate(['background-color']),
    },
    drawerUnderlayOpen: {
      backgroundColor: styling.colors.context.background,
      pointerEvents: 'all',
      cursor: 'pointer',
      [styling.breakpoints.context.desktopUpString]: {
        pointerEvents: 'none',
        backgroundColor: styling.colors.theme.transparent,
        cursor: 'default',
      },
    },
    drawerUnderlayInner: {
      ...styling.utils.absoluteFull(),
    },
    footerSpacer: {
      width: '100%',
      height: 'calc(var(--drawerSpaceFooterHeight) * 1px)',
      flexShrink: 0,
      flexGrow: 0,
    },
    footerContainer: {
      left: 0,
      right: 0,
      minHeight: 'calc(var(--drawerSpaceFooterMinHeight) * 1px)',
    },
  },
  { name: 'drawerSpace' }
);
