import {
  AnyCardModel,
  AnyCardWithRelatedCardsModel,
} from '@kitted/card-service-models';
import { CardType } from '@kitted/kitted-models';

const cardTypesWithRelatedCards = [
  CardType.Kit,
  CardType.Author,
  CardType.Subject,
  CardType.Tool,
];

export function isCardWithRelatedCards(
  card: AnyCardModel
): card is AnyCardWithRelatedCardsModel {
  return cardTypesWithRelatedCards.includes(card.cardType);
}
