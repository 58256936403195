import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0051: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 106 114"
		height={114}
		width={106}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M1.66 99.95V12.78c0-6.34 5.22-11.52 11.56-11.52h69.13c6.34 0 11.52 4.45 11.4 9.88" /><path vectorEffect="non-scaling-stroke" d="m93.75 49.19.12 50.76c0 7.03-5.22 12.79-11.56 12.79H13.18c-6.34 0-11.52-5.75-11.52-12.79M12.86 35.63s38.08-18.9 52.71 14.98c0 0 32.53-3.91 38.77-39.49 0 0-89.99-3.66-102.68 43.37" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M65.57 83.89c-9.81 5.77-22.54 6.25-33.19.34" /><circle vectorEffect="non-scaling-stroke" cx="65.57" cy="62.94" r="3.81" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="30.56" cy="62.94" r="3.81" stroke={spot} />
    </g>
  </CardIcon>
);

export default memo(Ac0051);
