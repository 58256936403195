import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    body: {
      whiteSpace: 'pre-wrap',
    },
  },
  { name: 'cardBody' }
);
