import { SyntheticEvent, useCallback, useMemo, useRef, useState } from 'react';

import {
  getComponentForType,
  getTouchedErrors,
  handleOnBlurFocus,
  handleOnTouched,
} from '../../logic';
import { InputProps } from '../../types';

const useInput = (type: InputProps['type'], errors: InputProps['errors']) => {
  const component = useRef(getComponentForType(type));
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const touchedErrors = useMemo(
    () => getTouchedErrors(errors, isTouched),
    [errors, isTouched]
  );

  const handleOnFocus = useCallback(
    () => handleOnBlurFocus(setIsFocused, true),
    [setIsFocused]
  );
  const handleOnBlur = useCallback(
    (e?: SyntheticEvent) => {
      if (
        type === 'select' &&
        e?.currentTarget.parentElement?.contains(e.target as Node)
      ) {
        return;
      }

      handleOnBlurFocus(setIsFocused, false);
      window.setTimeout(() => {
        handleOnTouched(setIsTouched);
      }, 0);
    },
    [setIsFocused, type]
  );

  return {
    InputComponent: component?.current.component,
    inputProps: component?.current.props,
    isFocused,
    isTouched,
    handleOnFocus,
    handleOnBlur,
    touchedErrors,
  };
};

export default useInput;
