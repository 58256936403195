import { AuthorModel } from '@kitted/author-service-models';

import useStoreItemSubscription from '../../../contexts/StoreContext/hooks/useStoreItemSubscription';
import { StoreSectionItemStates } from '../../../contexts/StoreContext/services/storeOfStores/types';

const useMyAuthor = () => {
  const { item: author, state } = useStoreItemSubscription<AuthorModel>(
    'User',
    'author'
  );

  return {
    author,
    isLoading: state !== StoreSectionItemStates.Ready,
  };
};

export default useMyAuthor;
