import { ModalData } from '../../types';

export const getContextualModalTitle = (
  modals: ModalData['modals'],
  title: string
) => {
  const modalIndex = modals.findIndex((modal) => modal.config.title === title);

  return modals
    .slice(0, modalIndex)
    .map((modal) => modal.config.title)
    .concat(title)
    .join(' : ');
};
