import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0029: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 106 90"
		height={90}
		width={106}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m33.8 45.76-18.06 7.99L1.21 85.79l26.08-11.55L53 88.77V73.29M34 56.73l-6.71 17.51M72.3 45.59l17.96 8.16 14.53 32.04-26.08-11.55L53 88.77M72 56.73l6.71 17.51M72.98 21.22c0 11.04-20 44.72-20 44.72s-20-33.68-20-44.72 8.96-19.99 20-19.99 20 8.95 20 20Z" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="m46.03 15.96 13.94 13.93M59.97 15.96 46.03 29.89" />
    </g>
  </CardIcon>
);

export default memo(Sn0029);
