import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0031: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 106 106"
		height={106}
		width={106}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M29.91 65.01a25.14 25.14 0 0 0 15.94 6.04c.15 0 .3.01.45.01 13.93 0 25.22-11.29 25.22-25.22H45.85V19.33" /><path vectorEffect="non-scaling-stroke" d="M40.92 21.19c-11.35 2.46-19.85 12.56-19.85 24.64 0 5.97 2.07 11.45 5.54 15.77L46.2 45.24M46.64 20.84c-.15 0-.3 0-.44.01v24.87h25.33c0-13.74-11.14-24.88-24.88-24.88ZM25.58 31.32H45.7M21.92 40.6h23.61M21.49 49.87h19.03" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M76.72 14.6c17.19 17.19 17.19 45.06 0 62.25s-45.05 17.19-62.25 0c-17.19-17.19-17.19-45.05 0-62.25 17.19-17.19 45.06-17.19 62.25 0" /><path vectorEffect="non-scaling-stroke" d="m80.81 72.12 22.08 22.08c2.17 2.17 2.02 5.87-.33 8.23-2.35 2.35-6.06 2.5-8.23.33l-15.8-15.79" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0031);
