import { memo } from 'react';

import { StoreSectionInitializerProps } from '../types';
import useVideoResourceStoreInitializer from './hooks/useVideoResourceStoreInitializer';

const VideoResourceStoreInitializer = ({
  onSectionLoaded,
}: StoreSectionInitializerProps) => {
  useVideoResourceStoreInitializer(onSectionLoaded);

  return null;
};

export default memo(VideoResourceStoreInitializer);
