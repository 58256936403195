import useAssetCatalogueManagement from './hooks/useAssetCatalogueManagement';
import { AssetCatalogueApiContext } from './AssetCatalogueApiContext';
import { AssetCatalogueDataContext } from './AssetCatalogueDataContext';
import { CardGridEditContextProps } from './types';

export const AssetCatalogueProvider = ({
  children,
}: CardGridEditContextProps) => {
  const [state, api] = useAssetCatalogueManagement();

  return (
    <AssetCatalogueApiContext.Provider value={api}>
      <AssetCatalogueDataContext.Provider value={state}>
        {children}
      </AssetCatalogueDataContext.Provider>
    </AssetCatalogueApiContext.Provider>
  );
};
