import { useCallback, useEffect, useState } from 'react';
import { EMPTY_ARR } from '@kitted/shared-utils';

import { Alert, AlertData } from '../../types';
import {
  getCloseAlert,
  getDeregisterAlert,
  getRegisterAlert,
  handleAlertsChange,
} from './logic';

const useAlertManagement = () => {
  const [topAlert, setTopAlert] = useState<AlertData['topAlert']>();
  const [alerts, setAlerts] = useState<AlertData['alerts']>(
    EMPTY_ARR as AlertData['alerts']
  );

  const closeAlert = useCallback(
    () => getCloseAlert(setTopAlert),
    [setTopAlert]
  );

  const registerAlert = useCallback(
    (alertId: string, alertConfig: Alert) =>
      getRegisterAlert(setAlerts, alertId, alertConfig),
    [setAlerts]
  );

  const deregisterAlert = useCallback(
    (alertId: string) => getDeregisterAlert(setAlerts, alertId),
    [setAlerts]
  );

  useEffect(
    () => handleAlertsChange(alerts, setTopAlert),
    [alerts, setTopAlert]
  );

  return {
    registerAlert,
    closeAlert,
    deregisterAlert,
    topAlert,
    alerts,
  };
};

export default useAlertManagement;
