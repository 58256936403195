import { EditDrawerSpaceContextProps } from '../../types';

export const getInitialSelectedDrawerItemSlug = (
  items: EditDrawerSpaceContextProps['items'],
  initialSelectedDrawerItemSlug: EditDrawerSpaceContextProps['initialSelectedDrawerItemSlug']
) => {
  if (!Object.keys(items).length) {
    throw new Error('At least one DrawerItem is required');
  }
  return initialSelectedDrawerItemSlug || Object.keys(items)[0];
};
