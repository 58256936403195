import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0042: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 122 138"
		height={138}
		width={122}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m76.58 19.41 19.48 11.28M45.43 19.41 25.94 30.69M76.58 118.59l19.48-11.27M45.43 118.59l-19.49-11.27" /><circle vectorEffect="non-scaling-stroke" cx="61" cy="10.29" r="8.63" /><path vectorEffect="non-scaling-stroke" d="M61 19.88v13.18" /><circle vectorEffect="non-scaling-stroke" cx="61" cy="127.71" r="8.63" /><path vectorEffect="non-scaling-stroke" d="M61 118.12v-13.18" /><circle vectorEffect="non-scaling-stroke" cx="111.79" cy="39.55" r="8.63" /><path vectorEffect="non-scaling-stroke" d="m103.49 44.36-11.4 6.61" /><circle vectorEffect="non-scaling-stroke" cx="10.21" cy="39.55" r="8.63" /><path vectorEffect="non-scaling-stroke" d="m18.51 44.36 11.4 6.61" /><circle vectorEffect="non-scaling-stroke" cx="10.21" cy="98.45" r="8.63" /><path vectorEffect="non-scaling-stroke" d="m18.51 93.64 11.4-6.61" /><circle vectorEffect="non-scaling-stroke" cx="111.79" cy="98.45" r="8.63" /><path vectorEffect="non-scaling-stroke" d="m103.49 93.64-11.4-6.61M10.31 57.73v22.54M111.69 57.73v22.54" /></g><g stroke={spot} ><ellipse cx="76.34" cy="65.32" rx="8.86" ry="20.32" /><path vectorEffect="non-scaling-stroke" d="M55.84 76.72c-.96-3.24-1.51-7.15-1.51-11.36 0-2.57.21-5.02.59-7.28M72.34 75.14c3.6-1.3 6.22-5.21 6.22-9.82s-2.62-8.52-6.22-9.82" /><path vectorEffect="non-scaling-stroke" d="M74.94 45.24c-12.82 9.6-20.11 8.88-26.42 8.88S36.8 59.16 36.8 65.32s5.42 11.2 11.72 11.2 13.6-.72 26.42 8.88M46.85 80.45l5.01 11.13c.35.78 1.35 1.42 2.21 1.42h4.63c.86 0 1.39-.68 1.17-1.51l-2.75-10.68" /></g>
    </g>
  </CardIcon>
);

export default memo(In0042);
