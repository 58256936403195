import { memo } from 'react';
import clsx from 'clsx';
import { TextSkeleton, Typography } from '@kitted/shared-components';

import { useDrawerSpaceContainerData } from '../../../../components/DrawerSpace/context/DrawerSpaceContainerContext';
import useIsLoggingIn from '../../../../contexts/UserContext/hooks/useIsLoggingIn';
import MenuItemLink from './MenuItemLink';
import useStyles from './styles';
import { MainMenuItemProps } from './types';

const MainMenuItem = ({
  className,
  children,
  path,
  onClick,
  target,
  title,
  preinterpolatedRoutePath,
  highlightSubroutes,
  icon: Icon,
  isSelected,
  isAuthenticated = false,
  isDisabled = false,
  hasTooltip = true,
}: MainMenuItemProps) => {
  const { isMainOpen } = useDrawerSpaceContainerData();
  const { isLoggingIn } = useIsLoggingIn();
  const styles = useStyles();

  return (
    <Typography
      as="li"
      className={clsx(
        className,
        styles.item,
        isMainOpen && styles.itemOpen,
        isSelected && styles.itemSelected
      )}
      variant="navLink"
    >
      <MenuItemLink
        onClick={onClick}
        path={path}
        target={target}
        preinterpolatedRoutePath={preinterpolatedRoutePath}
        highlightSubroutes={highlightSubroutes}
        title={isMainOpen ? '' : title}
        isDisabled={isDisabled}
        isSelected={isSelected}
        hasTooltip={hasTooltip}
      >
        <span className={styles.icon}>{Icon && <Icon size="inherit" />}</span>
        {isLoggingIn && isAuthenticated ? (
          <TextSkeleton lines={1} variant="navLink" />
        ) : (
          children
        )}
      </MenuItemLink>
    </Typography>
  );
};

export default memo(MainMenuItem);
