import { FlowCardModel, PublishType } from '@kitted/card-service-models';
import { FlowModel } from '@kitted/flow-service-models';
import {
  CardType,
  KittedModelTypeEnum,
  KittedVersionStatusTypeEnum,
} from '@kitted/kitted-models';

export const convertFlowModelToFlowCardModel = (
  id: FlowModel['id'],
  flow: Omit<FlowModel, 'id'>,
  oldCard: Partial<FlowCardModel> = {}
): FlowCardModel => ({
  ...oldCard,

  id,
  slug: flow.slug,

  kittedModelType: KittedModelTypeEnum.CardFlow,

  cardType: CardType.Flow,
  createdAt: oldCard?.createdAt || flow.createdAt,
  createdByAuthorId: flow.createdByAuthorId,
  description: flow.description,

  title: flow.title,
  updatedAt: flow.updatedAt,

  keyImageImageAsset: flow.keyImageImageAsset,

  excludeFromDiscovery:
    flow.cmsSrcMessage?.excludeFromDiscovery ??
    oldCard?.excludeFromDiscovery ??
    true,
  live: flow.cmsSrcMessage?.live ?? oldCard?.live ?? true,

  publishSettings: {
    publishType: PublishType.Private,
  },
  versionStatus: flow.versionStatus,
  publishedAt:
    flow.versionStatus === KittedVersionStatusTypeEnum.Published
      ? flow.updatedAt
      : oldCard.publishedAt!,
});
