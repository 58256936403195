export const getCalcedValue = (
  value: string | number | undefined,
  scale: number
) => {
  if (typeof value === 'number') {
    return value * scale;
  }

  return value;
};
