import { memo } from 'react';
import { TextSkeleton, Typography } from '@kitted/shared-components';

import useAccountMenuItemBody from './hooks/useAccountMenuItemBody';
import useStyles from './styles';
import { AccountMenuItemBodyProps } from './types';

const AccountMenuItemBody = ({ path }: AccountMenuItemBodyProps) => {
  const {
    account,
    name,
    isLoading,
    isLoggingIn,
    linkIsSelected,
    membershipLevelName,
  } = useAccountMenuItemBody(path);
  const styles = useStyles();

  return (
    <div className={styles.container}>
      {isLoggingIn || isLoading ? (
        <TextSkeleton
          lines={1}
          variant="navLink"
          theme={linkIsSelected ? 'primary' : 'secondary'}
        />
      ) : (
        <Typography maxLines={1} as="span" variant="navLink">
          {name}
        </Typography>
      )}
      {isLoggingIn || !account ? (
        <TextSkeleton
          lines={1}
          variant="bodySmall"
          theme={linkIsSelected ? 'primary' : 'secondary'}
        />
      ) : (
        <Typography as="span" variant="bodySmall">
          {membershipLevelName} Account
        </Typography>
      )}
    </div>
  );
};

export default memo(AccountMenuItemBody);
