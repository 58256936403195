import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0014: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 112 70"
		height={70}
		width={112}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M10.35 43.91h18.52s-.74-8.11-5.72-8.11h-4.91M86.6 43.91H68.09s.74-8.11 5.72-8.11h4.95M24.17 21.82h48.45s4.81.21 6.72-2.57M12.53 59.83h72.13" /><path vectorEffect="non-scaling-stroke" d="M28.02 64.51v2.68c0 .87-.71 1.58-1.58 1.58H13.89c-.87 0-1.58-.71-1.58-1.58v-7.63c-1.43-.56-2.5-1.97-2.5-3.6V37.19s.2-1.85.86-4.12l5.75-12.53 3.39-10.56c.94-5.25 7.95-8.06 11.09-8.06h35.14c3.14 0 10.14 2.82 11.09 8.06l3.4 10.56 5.75 12.53c.67 2.28.87 4.12.87 4.12v18.77c0 1.63-1.05 3.03-2.48 3.6v7.63c0 .87-.71 1.58-1.58 1.58H70.54c-.87 0-1.58-.71-1.58-1.58v-2.68" /><path vectorEffect="non-scaling-stroke" d="M10.61 17.71H6.19c-4.35 0-4.84 6.51-4.84 6.51l12.33 1.09M83.27 25.31l12.33-1.09s-.49-6.51-4.84-6.51h-4.42M34.41 59.81V51.7h28.13v8.11M10.66 51.58h17.95M68.35 51.58h17.94" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M82.94 2.79c2.39-1.01 5.01-1.57 7.76-1.57 11.02 0 19.95 8.93 19.95 19.95 0 9.38-6.47 17.24-15.19 19.38" />
    </g>
  </CardIcon>
);

export default memo(Sn0014);
