import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    input: {
      color: 'inherit',
      width: '100%',
      background: 'none',
      border: 'none',
      padding: [14, 24],
      appearance: 'none',
      MozAppearance: 'textfield',
      '&:focus': {
        outline: 'none',
      },
      '&:-webkit-autofill, &:-webkit-autofill:focus, &[data-com-onepassword-filled]':
        {
          transition: 'background-color 600000s 0s, color 600000s 0s',
        },
    },
    inputWithLeftIcon: {
      paddingLeft: 66,
    },
    inputWithRightIcon: {
      paddingRight: 66,
    },
    showIcon: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      right: 21,
      top: 0,
      bottom: 0,
      maxWidth: 34,
      fontSize: '2.4rem',
      cursor: 'pointer',
      opacity: 0,
      pointerEvents: 'none',
      transition: styling.transitions.utils.generate(['opacity']),
    },
    showIconVisible: {
      opacity: 1,
      pointerEvents: 'all',
    },
    shouldHideErrors: {
      marginBottom: 0,
    },
  },
  { name: 'inputPassword' }
);
