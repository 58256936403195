import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const ListNumbers: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 24 18"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M8.77.75h14.08M8.77 8.89h14.08M8.77 17.25h14.08" /><path vectorEffect="non-scaling-stroke" fill={color} d="M1.31 6.26v-.82h1.1V2.89l-1.1.45v-.85l1.46-.6h.6v3.54h1.07v.82H1.31ZM1.29 15.59v-.68l1.84-1.82c.07-.07.13-.13.17-.19.04-.07.07-.13.09-.2s.03-.13.03-.2c0-.14-.06-.26-.19-.35-.12-.1-.27-.14-.45-.14-.2 0-.38.04-.55.12s-.35.22-.55.41l-.54-.65c.25-.24.51-.41.79-.54.28-.12.57-.18.88-.18s.58.05.81.16c.23.1.41.25.54.44s.19.41.19.66a1.34 1.34 0 0 1-.24.77c-.08.12-.19.24-.32.37l-1.26 1.21h1.89v.81z" />
    </g>
  </WebsiteIcon>
);

export default memo(ListNumbers);
