/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  chunk as lodashChunk,
  shuffle as lodashShuffle,
  uniq as lodashUniq,
  uniqBy as lodashUniqBy,
} from 'lodash';

import { isArray } from './validation';

export const chunk = lodashChunk;
export const shuffle = lodashShuffle;
export const uniq = lodashUniq;
export const uniqBy = lodashUniqBy;

export const replaceAtIndex = (arr: any[], el: any, index: number) => [
  ...arr.slice(0, index),
  el,
  ...arr.slice(index + 1),
];

export function* askReplaceAtIndex(arr: any[], el: any, index: number) {
  return replaceAtIndex(arr, el, index);
}

export const insertAtIndex = (arr: any[], el: any, index: number) => [
  ...arr.slice(0, index),
  el,
  ...arr.slice(index),
];

export function* askInsertAtIndex(arr: any[], el: any, index: number) {
  return insertAtIndex(arr, el, index);
}

export const swap = (array: any[], moveIndex: number, toIndex: number) => {
  /* #swap - Moves an array item from one position in an array to another.

     Note: This is a pure function so a new array will be returned, instead
     of altering the array argument.

    Arguments:
    1. array     (String) : Array in which to move an item.         (required)
    2. moveIndex (Object) : The index of the item to move.          (required)
    3. toIndex   (Object) : The index to move item at moveIndex to. (required)
  */
  const item = array[moveIndex];
  const { length } = array;
  const diff = moveIndex - toIndex;

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, moveIndex),
      ...array.slice(moveIndex + 1, length),
    ];
  }
  if (diff < 0) {
    // move right
    const targetIndex = toIndex + 1;
    return [
      ...array.slice(0, moveIndex),
      ...array.slice(moveIndex + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length),
    ];
  }
  return array;
};

export const removeFalseyFromArray = <T = any>(array: any[]): T[] =>
  array.filter((item) => !!item);

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export type NestedLeafKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}.${NestedLeafKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

type TypeAtPath<
  T,
  Path extends string,
> = Path extends `${infer First}.${infer Rest}`
  ? First extends keyof T
    ? TypeAtPath<T[First], Rest>
    : undefined
  : Path extends keyof T
    ? T[Path]
    : undefined;

export const traverse = <TraversableItem extends Record<string, unknown>>(
  obj: TraversableItem,
  stepString: NestedLeafKeyOf<TraversableItem>
):
  | TypeAtPath<TraversableItem, NestedLeafKeyOf<TraversableItem>>
  | undefined => {
  const arr = stepString.split('.');
  if (!isArray(arr)) {
    return undefined;
  }
  if (arr.length === 0) {
    return obj as TypeAtPath<TraversableItem, NestedLeafKeyOf<TraversableItem>>;
  }

  const traverseFn = (
    item: Record<string, unknown>,
    stepPath: string[]
  ): unknown => {
    const currentKey = stepPath.at(0);
    if (currentKey && currentKey in item) {
      const valueOfItemAtStepPath = item[currentKey];
      if (stepPath.length > 1) {
        if (typeof valueOfItemAtStepPath === 'object') {
          return traverseFn(
            valueOfItemAtStepPath as Record<string, unknown>,
            stepPath.slice(1)
          );
        }
        return undefined;
      }
      return valueOfItemAtStepPath;
    }
    return undefined;
  };

  return traverseFn(obj, arr) as TypeAtPath<
    TraversableItem,
    NestedLeafKeyOf<TraversableItem>
  >;
};

export const evenlyDistributeItemsIntoArray = <Item>(
  baseArr: Item[],
  interleaveArr: Item[]
): Item[] => {
  const lengthOfBase = baseArr.length;
  // add 1 to the lengthOfInterleave so that we dont always insert as the last item
  const lengthOfInterleave = interleaveArr.length + 1;
  const returnedItems: Item[] = [];
  let interleaveIndex = 0;

  if (lengthOfInterleave === 1) {
    return baseArr;
  }
  if (lengthOfBase === 0) {
    return interleaveArr;
  }

  // Calculate the interval at which to insert elements from interleaveArr
  const insertEveryXItems = Math.floor(lengthOfBase / (lengthOfInterleave - 1));

  for (let i = 0; i < lengthOfBase; i += 1) {
    returnedItems.push(baseArr[i]);
    // Check if it's time to insert an element from interleaveArr
    if (
      (i + 1) % insertEveryXItems === 0 &&
      interleaveIndex < interleaveArr.length
    ) {
      returnedItems.push(interleaveArr[interleaveIndex]);
      interleaveIndex += 1;
    }
  }

  // Check if there are remaining elements in interleaveArr to add at the end
  while (interleaveIndex < interleaveArr.length) {
    returnedItems.push(interleaveArr[interleaveIndex]);
    interleaveIndex += 1;
  }

  return returnedItems;
};
