import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0051: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 106 116"
		height={116}
		width={106}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M53.32 114.67c19.95 0 27.76-7.87 27.76-7.87-12.94-15.47-16.85-54.56-16.85-54.56-1.79-7.08-11.18-7.08-11.18-7.08h-.09s-9.39 0-11.18 7.08c0 0-3.91 39.08-16.85 54.56 0 0 7.81 7.87 27.76 7.87zM62.44 28.38c0 5.21-4.23 9.44-9.44 9.44s-9.44-4.23-9.44-9.44 4.23-9.44 9.44-9.44 9.44 4.23 9.44 9.44" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M64.25 6.47c0 2.84-5.04 5.14-11.25 5.14s-11.25-2.3-11.25-5.14S46.79 1.33 53 1.33s11.25 2.3 11.25 5.14" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M39.47 43.84c-2.37-1.44-5.78-2.14-7.75-1.8 0 0-6.86 0-9.1 9.37-2.87 12.01-9 27.71-21.4 36.11 0 0 12.71 15.36 28.88 10.38" /><path vectorEffect="non-scaling-stroke" d="M19.17 83.2s-5.46 7.53-9.66 10.15M25 89.62s-2.78 4.98-6.97 8.39M66.53 43.84c2.37-1.44 5.78-2.14 7.75-1.8 0 0 6.86 0 9.1 9.37 2.88 12.01 9 27.71 21.4 36.11 0 0-12.71 15.36-28.88 10.38" /><path vectorEffect="non-scaling-stroke" d="M86.83 83.2s5.46 7.53 9.66 10.15M80.99 89.62s2.78 4.98 6.97 8.39" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0051);
