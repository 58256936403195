/* eslint-disable camelcase */
export const secondary050 = 'rgb(251, 240, 233)'; // #FBF0E9
export const secondary100 = 'rgb(255, 246, 231)'; // #FFF6E7
export const secondary200 = 'rgb(255, 240, 225)'; // #FFF0E1
export const secondary300 = 'rgb(247, 226, 211)'; // #F7E2D3
export const secondary300_06 = 'rgba(247, 226, 211, 0.06)'; // #F7E2D3
export const secondary300_10 = 'rgba(247, 226, 211, 0.1)'; // #F7E2D3
export const secondary300_20 = 'rgba(247, 226, 211, 0.2)'; // #F7E2D3
export const secondary300_30 = 'rgba(247, 226, 211, 0.3)'; // #F7E2D3
export const secondary300_40 = 'rgba(247, 226, 211, 0.4)'; // #F7E2D3
export const secondary400 = 'rgb(209, 190, 175)'; // #D1BEAF
export const secondary500 = 'rgb(179, 160, 146)'; // #B3A092
export const secondary600 = 'rgb(136, 119, 105)'; // #887769
export const secondary700 = 'rgb(116, 99, 86)'; // #746356
export const secondary800 = 'rgb(84, 68, 56)'; // #544438
export const secondary900 = 'rgb(49, 35, 24)'; // #312318
export const secondary900_30 = 'rgba(49, 35, 24, 0.3)'; // #312318
