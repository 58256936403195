import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      width: styling.sizes.context.cardWide.width,
      height: styling.sizes.context.cardWide.height,
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: '2fr 1fr',
      gridTemplateRows: '3fr 1fr',
      gap: 2,
      backgroundColor: styling.colors.context.inverted.background,
      color: styling.colors.context.inverted.color,
      textDecoration: 'none',
      '--cardWideThemeColor': styling.colors.theme.primary800,
      '--cardWideThemeTextOnColor': styling.colors.context.color,
      '--cardWideSpotColor': styling.colors.context.color,
    },
    'cardType-deck': {},
    'cardType-display': {},
    'cardType-tool': {},
    'cardType-resource': {},
    'cardType-flow': {},
    'cardType-author': {
      '--cardWideThemeColor': styling.colors.context.inverted.background,
      '--cardWideThemeTextOnColor': styling.colors.context.inverted.color,
    },
    'cardType-kit': {
      '--cardWideThemeColor': styling.colors.context.inverted.background,
      '--cardWideThemeTextOnColor': styling.colors.context.inverted.color,
    },
    'cardType-article': {
      '--cardWideThemeColor': styling.colors.context.inverted.background,
      '--cardWideThemeTextOnColor': styling.colors.context.inverted.color,
    },
    'cardType-play': {
      '--cardWideThemeColor': styling.colors.context.inverted.background,
      '--cardWideThemeTextOnColor': styling.colors.context.inverted.color,
    },
    'cardType-subject': {
      '--cardWideThemeColor': styling.colors.context.inverted.background,
      '--cardWideThemeTextOnColor': styling.colors.context.inverted.color,
    },
    'cardSubType-video': {},
    'cardSubType-link': {},
    'cardSubType-image': {},
    'cardSubType-action': {
      '--cardWideThemeColor':
        styling.colors.context.card.footerBackgrounds.action,
      '--cardWideSpotColor':
        styling.colors.context.card.footerBackgrounds.action,
      '--cardWideThemeTextOnColor': styling.colors.context.inverted.color,
    },
    'cardSubType-insight': {
      '--cardWideThemeColor':
        styling.colors.context.card.footerBackgrounds.insight,
      '--cardWideSpotColor':
        styling.colors.context.card.footerBackgrounds.insight,
      '--cardWideThemeTextOnColor': styling.colors.context.inverted.color,
    },
    'cardSubType-frame': {
      '--cardWideThemeColor':
        styling.colors.context.card.footerBackgrounds.frame,
      '--cardWideSpotColor':
        styling.colors.context.card.footerBackgrounds.frame,
      '--cardWideThemeTextOnColor': styling.colors.context.inverted.color,
    },
    'cardSubType-snare': {
      '--cardWideThemeColor':
        styling.colors.context.card.footerBackgrounds.snare,
      '--cardWideSpotColor':
        styling.colors.context.card.footerBackgrounds.snare,
      '--cardWideThemeTextOnColor': styling.colors.context.inverted.color,
    },
    body: {
      backgroundColor: styling.colors.theme.primary800,
      color: styling.colors.context.color,
      padding: [20, 103],
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 47,
    },
    icon: {
      fontSize: '4.6rem',
    },
    title: {
      color: 'var(--cardWideSpotColor)',
    },
    image: {
      position: 'relative',
      backgroundColor: styling.colors.theme.primary800,
    },
    slug: {
      backgroundColor: 'var(--cardWideThemeColor)',
      color: 'var(--cardWideThemeTextOnColor)',
      padding: [20, 103],
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      backgroundColor: styling.colors.theme.primary800,
      padding: [20, 78],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    flowStripe: {
      position: 'relative',
      left: 0,
      right: 0,
      bottom: 0,
      height: 6,
      backgroundColor: styling.colors.context.card.footerBackgrounds.flow,
    },
  },
  { name: 'cardWide' }
);
