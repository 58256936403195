import { useCallback } from 'react';
import { FavoriteModel } from '@kitted/account-service-models';
import { KittedServiceEnum } from '@kitted/kitted-models';
import { NOOP } from '@kitted/shared-utils';

import useRequests from '../../../contexts/RequestsContext/hooks/useRequests';
import useAuthedAsyncRequest from '../../useAuthedAsyncRequest';
import { generateFalseFavoriteForId } from '../logic';

const useFavoriteRequests = () => {
  const { platformRequest } = useRequests();
  const falseFavoriteRequest = useCallback(
    async (id: string) => generateFalseFavoriteForId(id),
    []
  );
  const [requestFavoriteById] = useAuthedAsyncRequest<
    FavoriteModel,
    FavoriteModel
  >(
    (id: string) =>
      platformRequest(KittedServiceEnum.Account, {
        method: 'GET',
        url: `/v1/favorite/get/${id}`,
      }),
    NOOP,
    falseFavoriteRequest
  );
  const [requestAddFavoriteById] = useAuthedAsyncRequest((id: string) =>
    platformRequest(KittedServiceEnum.Account, {
      method: 'POST',
      url: `/v1/favorite/add/${id}`,
    })
  );
  const [requestRemoveFavoriteById] = useAuthedAsyncRequest((id: string) =>
    platformRequest(KittedServiceEnum.Account, {
      method: 'DELETE',
      url: `/v1/favorite/remove/${id}`,
    })
  );

  return {
    requestFavoriteById,
    requestAddFavoriteById,
    requestRemoveFavoriteById,
  };
};

export default useFavoriteRequests;
