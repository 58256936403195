import { useEffect, useMemo } from 'react';

import { getChallengeComponent } from '../../logic';
import { AuthChallengeModalContentProps } from '../../types';

const useAuthChallengeModalContent = (
  meta: AuthChallengeModalContentProps['meta'],
  onClose: AuthChallengeModalContentProps['onClose']
) => {
  const { as: ChallengeComponent, ...challengeComponentProps } = useMemo(
    () => getChallengeComponent(meta),
    [meta]
  );

  useEffect(() => {
    if (!ChallengeComponent) {
      onClose();
    }
  }, [onClose, ChallengeComponent]);

  return {
    ChallengeComponent,
    challengeComponentProps,
  };
};

export default useAuthChallengeModalContent;
