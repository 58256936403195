import { z } from 'zod';
import {
  ImageAssetModelSchema,
  KittedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '@kitted/kitted-models';
import { DateISOSchema } from '@kitted/platform-models';

export const CMSResourceBaseLoadedModelSchema = KittedModelBaseSchema.merge(
  z.object({
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      true
    ).optional(),
    excludeFromDiscovery: z.boolean(),
    live: z.boolean(),
    createdAt: DateISOSchema,
    updatedAt: DateISOSchema,

    createdByAuthorId: z.string(),
    createdByUserId: z.string(),

    title: z.string(),
    label: z.string(),
  })
);

export const CMSResourceBaseUnloadedModelSchema =
  CMSResourceBaseLoadedModelSchema.merge(
    z.object({
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        false
      ).optional(),
    })
  );

export type CMSResourceBaseLoadedModel = z.infer<
  typeof CMSResourceBaseLoadedModelSchema
>;
export type CMSResourceBaseUnloadedModel = z.infer<
  typeof CMSResourceBaseUnloadedModelSchema
>;

export type CMSResourceBaseModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? CMSResourceBaseLoadedModel
    : CMSResourceBaseUnloadedModel;
