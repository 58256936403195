import { z } from 'zod';

import { KittedVersionedModelBaseSchema } from './KittedVersionedModelBase';

export const KittedSaveModelBaseSchema = KittedVersionedModelBaseSchema.merge(
  z.object({
    id: KittedVersionedModelBaseSchema.shape.id.optional(),
    kittedModelType:
      KittedVersionedModelBaseSchema.shape.kittedModelType.optional(),

    version: KittedVersionedModelBaseSchema.shape.version.optional(),
    versionStatus:
      KittedVersionedModelBaseSchema.shape.versionStatus.optional(),

    createdByAuthorId:
      KittedVersionedModelBaseSchema.shape.createdByAuthorId.optional(),
    createdByUserId:
      KittedVersionedModelBaseSchema.shape.createdByUserId.optional(),

    createdAt: KittedVersionedModelBaseSchema.shape.createdAt.optional(),
    updatedAt: KittedVersionedModelBaseSchema.shape.updatedAt.optional(),
  })
);

export type KittedSaveModelBase = z.infer<typeof KittedSaveModelBaseSchema>;

export type KittedSaveModel<T> = Omit<T, keyof KittedSaveModelBase> &
  KittedSaveModelBase;
