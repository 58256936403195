import { createContext, useMemo } from 'react';
import { EMPTY_ARR, NOOP } from '@kitted/shared-utils';

import useAlertManagement from './hooks/useAlertManagement';
import { AlertContextApi, AlertContextProps, AlertData } from './types';

export const AlertContext = createContext<AlertContextApi>({
  closeAlert: NOOP,
  registerAlert: NOOP,
  deregisterAlert: NOOP,
});

export const AlertDataContext = createContext<AlertData>({
  topAlert: undefined,
  alerts: EMPTY_ARR,
});

export const AlertProvider: React.FC<AlertContextProps> = ({ children }) => {
  const { topAlert, alerts, registerAlert, closeAlert, deregisterAlert } =
    useAlertManagement();
  const apiValue = useMemo(
    () => ({ registerAlert, closeAlert, deregisterAlert }),
    [registerAlert, closeAlert, deregisterAlert]
  );
  const dataValue = useMemo(() => ({ alerts, topAlert }), [alerts, topAlert]);

  return (
    <AlertContext.Provider value={apiValue}>
      <AlertDataContext.Provider value={dataValue}>
        {children}
      </AlertDataContext.Provider>
    </AlertContext.Provider>
  );
};
