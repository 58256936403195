import { ImageAssetModel, KittedServiceEnum } from '@kitted/kitted-models';

import useRequests from '../../contexts/RequestsContext/hooks/useRequests';
import useAsyncRequest from '../useAsyncRequest';

const useAssetsApi = () => {
  const { platformRequest } = useRequests();
  const [getAsset] = useAsyncRequest<ImageAssetModel>((id: string) =>
    platformRequest(KittedServiceEnum.Asset, {
      method: 'GET',
      url: `/v1/asset/${id}`,
    })
  );

  return {
    getAsset,
  };
};

export default useAssetsApi;
