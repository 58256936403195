import {
  AnyAuthorBlockModel,
  AuthorDraftSavePublishModel,
  AuthorModel,
} from '@kitted/author-service-models';
import { convertAuthorModelToAuthorCardModel } from '@kitted/card-service-shared';
import { KittedServiceEnum } from '@kitted/kitted-models';

import useRequests from '../../../contexts/RequestsContext/hooks/useRequests';
import useStoreSection from '../../../contexts/StoreContext/hooks/useStoreSection';
import useAsyncRequest from '../../useAsyncRequest';
import useAuthedAsyncRequest from '../../useAuthedAsyncRequest';

const useAuthorRequests = () => {
  const { updateStoreSectionItem } = useStoreSection('Cards');
  const { platformRequest } = useRequests();

  const [getAuthorBySlug, authorBySlugFetchState] = useAsyncRequest<
    AuthorModel<false>
  >((slug: AuthorModel['slug']) =>
    platformRequest(KittedServiceEnum.Author, {
      method: 'GET',
      url: `/v1/author/slug/${slug}/published`,
    })
  );

  const [getAuthor, authorFetchState] = useAsyncRequest<AuthorModel<false>>(
    (id: AuthorModel['id']) =>
      platformRequest(KittedServiceEnum.Author, {
        method: 'GET',
        url: `/v1/author/${id}/published`,
      })
  );

  const [getAuthorBlocks, authorBlocksFetchState] = useAsyncRequest<
    (AnyAuthorBlockModel<false> | AnyAuthorBlockModel<true>)[]
  >((id: AuthorModel['id']) =>
    platformRequest(KittedServiceEnum.Author, {
      method: 'GET',
      url: `/v1/author/${id}/published/blocks`,
    })
  );

  const [draftSavePublishAuthor, authorDraftSavePublishState] =
    useAuthedAsyncRequest<AuthorModel>(
      (data: AuthorDraftSavePublishModel) =>
        platformRequest(KittedServiceEnum.Author, {
          method: 'POST',
          data,
          url: '/v1/author/draftSavePublish',
        }),
      (response: AuthorModel | undefined) => {
        if (response) {
          updateStoreSectionItem(
            convertAuthorModelToAuthorCardModel(response.id, response),
            response.id
          );
        }
      }
    );

  return {
    getAuthorBySlug,
    authorBySlugFetchState,
    getAuthor,
    authorFetchState,
    getAuthorBlocks,
    authorBlocksFetchState,
    draftSavePublishAuthor,
    authorDraftSavePublishState,
  };
};

export default useAuthorRequests;
