import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      width: '100%',
      marginTop: 'auto',
      borderTop: [1, 'solid', styling.colors.theme.primary700],
    },
  },
  { name: 'accountMenu' }
);
