import { AuthenticateUserChallenge } from 'quidproquo';

import {
  AuthResetPasswordChallengeProps,
  ResetPasswordChallengeFormData,
} from './types';

export const getInitialFormData = (
  email: AuthResetPasswordChallengeProps['email']
) => ({
  password: '',
  email,
});

export const transformResetChallengeFormDataToResetChallengePayload = (
  { password }: ResetPasswordChallengeFormData,
  session: AuthResetPasswordChallengeProps['session'],
  email: AuthResetPasswordChallengeProps['email']
) => ({
  email,
  session,
  newPassword: password,
  challenge:
    'NEW_PASSWORD_REQUIRED' as AuthenticateUserChallenge.NEW_PASSWORD_REQUIRED,
});
