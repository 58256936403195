import { RGBA } from '../types';
import { hexToRGB } from './toRgb';

export function hexToRGBA(hex: string, alpha: number, isPct = false): RGBA {
  const { r, g, b } = hexToRGB(hex);
  const a = alpha;

  return {
    r,
    g,
    b,
    a,
    string: `rgba(${
      isPct ? `${r}%,${g}%,${b}%,${a}` : `${+r},${+g},${+b},${a}`
    })`,
  };
}
