import { z } from 'zod';

import { WebsocketAuthenticateClientMessageSchema } from './WebsocketAuthenticateClientMessage';
import { WebsocketDownloadCardZipClientMessageSchema } from './WebsocketDownloadCardZipClientMessage';
import { WebsocketPingClientMessageSchema } from './WebsocketPingClientMessage';
import { WebsocketServiceRequestClientMessageSchema } from './WebsocketServiceRequestClientMessage';
import { WebsocketUnauthenticateClientMessageSchema } from './WebsocketUnauthenticateClientMessage'; // z.union([ WebsocketDownloadCardZipClientMessageSchema ]);

export const AnyWebsocketDownloadClientMessageSchema =
  WebsocketDownloadCardZipClientMessageSchema;

export const AnyWebsocketClientMessageSchema = z.union([
  WebsocketAuthenticateClientMessageSchema,
  WebsocketUnauthenticateClientMessageSchema,
  WebsocketPingClientMessageSchema,
  WebsocketServiceRequestClientMessageSchema,
  AnyWebsocketDownloadClientMessageSchema,
]);

export type AnyWebsocketClientMessage = z.infer<
  typeof AnyWebsocketClientMessageSchema
>;

export type AnyWebsocketDownloadClientMessage = z.infer<
  typeof AnyWebsocketDownloadClientMessageSchema
>;
