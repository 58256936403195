import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0006: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 108 104"
		height={104}
		width={108}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><path vectorEffect="non-scaling-stroke" stroke={color} d="M39.23 16.45c0-8.16 6.61-14.77 14.77-14.77s14.77 6.61 14.77 14.77C68.77 29 54 27.68 54 43.72" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M55.72 55.75a1.72 1.72 0 1 1-3.44 0 1.72 1.72 0 0 1 3.44 0" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M12.06 57.65C4.3 55.13.06 46.8 2.58 39.04s10.85-12 18.61-9.48c11.93 3.88 6.12 17.52 21.37 22.47M42.85 96.23c-4.79 6.6-14.03 8.06-20.63 3.27s-8.06-14.03-3.27-20.63c7.38-10.15 18.55-.41 27.97-13.38M89.05 78.87c4.79 6.6 3.33 15.83-3.27 20.63-6.6 4.79-15.83 3.33-20.63-3.27-7.38-10.15 5.34-17.77-4.08-30.74M86.81 29.56c7.76-2.52 16.09 1.72 18.61 9.48s-1.72 16.09-9.48 18.61c-11.93 3.88-15.25-10.57-30.5-5.62" />
    </g>
  </CardIcon>
);

export default memo(Ac0006);
