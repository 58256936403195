import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0039: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 102 116"
		height={116}
		width={102}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M3.25 9.68h95.51v63.14H3.25z" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M44.41 72.83v41.75M57.59 72.83v41.75" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M36.31 114.48h29.44" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M1.12 82.69H43.3M58.7 82.69h42.18" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M51.03 1.78v7.34M58.93 1.42H43.12" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M21.16 1.78v7.34M29.07 1.42H13.25M80.84 1.78v7.34M88.75 1.42H72.93" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M12.39 24.97h76.78" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M12.39 82.39V72.8M89.61 82.39V72.8" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0039);
