/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';

import useFormFields from './hooks/useFormFields';
import FormField from './FormField';
import FormGrid from './FormGrid';
import { FormFieldsProps } from './types';

const FormFields = ({ layout, theme, variant }: FormFieldsProps) => {
  const { fields, onFieldChange, getWidths, formInstanceId } =
    useFormFields(layout);

  return (
    <FormGrid>
      {fields.map(({ key, ...rest }) => (
        <FormGrid key={`${formInstanceId}-${key}`} item widths={getWidths(key)}>
          <FormField
            theme={theme}
            variant={variant}
            {...rest}
            onFieldChange={onFieldChange}
            name={key}
          />
        </FormGrid>
      ))}
    </FormGrid>
  );
};

export default memo(FormFields);
