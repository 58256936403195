import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    assetContainer: {
      width: styling.sizes.context.tile.width,
      height: styling.sizes.context.tile.height,

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      transition: 'opacity 0.3s ease, border 0.3s ease',

      opacity: 1.0,
      '&:hover': {
        opacity: 0.8,
      },
    },
    assetImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      margin: 0,
      border: [2, 'solid', styling.colors.theme.transparent],
      pointerEvents: 'all',
    },
  },
  { name: 'loadingCatalogueItems' }
);
