import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0046: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 102 102"
		height={102}
		width={102}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M7.33 38.98c4.15-15.11 15.93-27.06 30.94-31.45M45.42 95.94C24.88 93.42 8.6 77.15 6.06 56.62M95.94 56.62C93.4 77.16 77.11 93.43 56.56 95.94M56.74 6.08C77.17 8.66 93.35 24.86 95.92 45.3M45.68 74.84c-9.24-2.05-16.5-9.34-18.53-18.58M74.84 56.31c-2.05 9.24-9.33 16.51-18.57 18.54M56.28 27.15c9.2 2.03 16.45 9.24 18.53 18.42M27.17 45.6c2.07-9.18 9.31-16.4 18.5-18.44" /><g stroke={spot} ><rect vectorEffect="non-scaling-stroke" width="10.29" height="29.23" x="45.85" y="1.05" rx="3.29" ry="3.29" /><rect vectorEffect="non-scaling-stroke" width="10.29" height="29.23" x="45.85" y="71.72" rx="3.29" ry="3.29" /></g><g stroke={spot} ><rect vectorEffect="non-scaling-stroke" width="29.23" height="10.29" x="71.72" y="45.85" rx="3.29" ry="3.29" /><rect vectorEffect="non-scaling-stroke" width="29.23" height="10.29" x="1.05" y="45.85" rx="3.29" ry="3.29" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0046);
