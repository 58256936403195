import { AuthenticatedRoute, PublicRoute } from '../types';

export function removeParentPathFromRoute(
  parentPath: string,
  childRoute: AuthenticatedRoute
): AuthenticatedRoute;

export function removeParentPathFromRoute(
  parentPath: string,
  childRoute: PublicRoute
): PublicRoute;

export function removeParentPathFromRoute(
  parentPath: string,
  childRoute: AuthenticatedRoute | PublicRoute
): AuthenticatedRoute | PublicRoute {
  return { ...childRoute, path: childRoute.path.replace(`${parentPath}/`, '') };
}
