import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0010: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 90 106"
		height={106}
		width={90}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="M50.12 15.04a5.12 5.12 0 1 1-10.24 0c0-2.84 2.29-5.12 5.12-5.12s5.12 2.29 5.12 5.12" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M45.02 1.75c7.34 0 13.29 5.95 13.29 13.29 0 5.92-3.87 10.94-9.22 12.66v10.98h9.19c1.05-1.23 2.59-2.02 4.33-2.02 3.16 0 5.73 2.56 5.73 5.73s-2.56 5.73-5.73 5.73c-1.74 0-3.28-.79-4.33-2.02h-9.19v35.49c13.42 0 26.25-14.62 26.25-14.62l-6.43-4.44 19.29-6.52-.75 20.55-6.15-4.63s-8.66 19.58-32.22 21.58v5.2l-4.06 5.55h-.04l-4.06-5.55v-5.2C17.36 91.5 8.7 71.93 8.7 71.93l-6.15 4.63-.75-20.55 19.29 6.52-6.43 4.44s12.83 14.62 26.25 14.62V46.1h-9.19c-1.05 1.23-2.59 2.02-4.33 2.02-3.16 0-5.73-2.56-5.73-5.73s2.56-5.73 5.73-5.73c1.74 0 3.28.8 4.33 2.02h9.19V27.7c-5.35-1.72-9.22-6.73-9.22-12.66 0-7.34 5.95-13.29 13.29-13.29z" />
    </g>
  </CardIcon>
);

export default memo(Sn0010);
