import { useCallback } from 'react';

import {
  StoreSectionItem,
  StoreSectionItemStates,
} from '../../services/storeOfStores/types';
import { KittedStoreSectionKey } from '../../types';
import useStoreApi from '../useStoreApi';

const useStoreSection = <StoreItem>(sectionKey: KittedStoreSectionKey) => {
  const {
    setSection,
    getStoreItemKeyWhere,
    getStoreItemKeysWhere,
    setStoreItemItem,
  } = useStoreApi();

  const setStoreSectionItems = useCallback(
    (items: StoreItem[], itemKeyResolver: (item: StoreItem) => string) => {
      const itemsObject: Record<
        string,
        StoreSectionItem<StoreItem | undefined>
      > = items.reduce(
        (acc, item) => ({
          ...acc,
          [itemKeyResolver(item)]: {
            item,
            state: StoreSectionItemStates.Ready,
          },
        }),
        {}
      );
      setSection<StoreItem>(sectionKey, itemsObject);
    },
    [sectionKey, setSection]
  );

  const updateStoreSectionItem = useCallback(
    (item: Partial<StoreItem>, itemKey: string) => {
      setStoreItemItem(sectionKey, itemKey, item);
    },
    [sectionKey, setStoreItemItem]
  );

  const resetStoreSection = useCallback(() => {
    setSection(sectionKey, undefined);
  }, [sectionKey, setSection]);

  const getStoreSectionItemKeyWhere = useCallback(
    (finderFn: (item: StoreSectionItem<StoreItem | undefined>) => boolean) =>
      getStoreItemKeyWhere(sectionKey, finderFn),
    [sectionKey, getStoreItemKeyWhere]
  );

  const getStoreSectionItemKeysWhere = useCallback(
    (finderFn: (item: StoreSectionItem<StoreItem | undefined>) => boolean) =>
      getStoreItemKeysWhere(sectionKey, finderFn),
    [sectionKey, getStoreItemKeysWhere]
  );

  return {
    setStoreSectionItems,
    updateStoreSectionItem,
    resetStoreSection,
    getStoreSectionItemKeyWhere,
    getStoreSectionItemKeysWhere,
  };
};

export default useStoreSection;
