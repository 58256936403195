export enum SlugPrefixEnum {
  Action = 'ACTION',
  Article = 'ARTICLE',
  Author = 'AUTHOR',
  Deck = 'DECK',
  Display = 'DISPLAY',
  Flow = 'FLOW',
  Frame = 'FRAME',
  Insight = 'INSIGHT',
  Kit = 'KIT',
  Play = 'PLAY',
  Resource = 'RESOURCE',
  Rimg = 'RIMG',
  Rlnk = 'RLNK',
  Rvid = 'RVID',
  Snare = 'SNARE',
  Subject = 'SUBJECT',

  DevOnly = 'DEV',
}
