import { useMemo } from 'react';

import useIsLoggingIn from '../../../../../../../../contexts/UserContext/hooks/useIsLoggingIn';
import useAccount from '../../../../../../../../hooks/accounts/useAccount';
import useMyAuthor from '../../../../../../../../hooks/authors/useMyAuthor';
import { getMembershipLevelName } from '../../../../../../../../hooks/memberships/logic';
import useAuthorMeta from '../../../../../../../../hooks/useAuthorMeta';
import useIsPathSelected from '../../../../../../../../hooks/useIsPathSelected';
import { AccountMenuItemBodyProps } from '../../types';

const useAccountMenuItemBody = (path: AccountMenuItemBodyProps['path']) => {
  const { account } = useAccount();
  const { author, isLoading } = useMyAuthor();
  const { name } = useAuthorMeta(author);
  const { isLoggingIn } = useIsLoggingIn();
  const linkIsSelected = useIsPathSelected(path);
  const membershipLevelName = useMemo(
    () => getMembershipLevelName(account?.membershipLevel),
    [account?.membershipLevel]
  );

  return {
    account,
    name,
    isLoading,
    isLoggingIn,
    linkIsSelected,
    membershipLevelName,
  };
};

export default useAccountMenuItemBody;
