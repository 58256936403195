import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0023: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 112 88"
		height={88}
		width={112}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M1.53 60.3h36.31v25.85H1.53zM37.84 60.3h36.31v25.85H37.84z" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M37.84 1.86h36.31v25.85H37.84z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M74.16 60.3h36.31v25.85H74.16zM19.69 34.45H56V60.3H19.69zM56 34.45h36.31V60.3H56z" />
    </g>
  </CardIcon>
);

export default memo(Ac0023);
