import { constructPublicRoute } from '../helpers';
import { kitTitles } from '../routeTitles';

const kitTitleCodeBasePath = '/kit/:titleSlug/:code';

export const kitRedirectRoutes = {
  kitCode: constructPublicRoute('/kit/:code', 'Kit', kitTitleCodeBasePath),
} as const;

export const kitRoutes = {
  ...kitRedirectRoutes,
  kitTitleCode: constructPublicRoute(kitTitleCodeBasePath, [kitTitles, 'Kit']),
  kitTitleCodeEdit: constructPublicRoute(`${kitTitleCodeBasePath}/edit`, [
    kitTitles,
    'Edit Kit',
  ]),
} as const;
