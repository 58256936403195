/* eslint-disable camelcase */
import { generateLinearPalette } from '../../services/linearPaletteGenerator';

const dangerBaseColor = '#D53E64'; // rgb(213, 62, 100)
const palette = generateLinearPalette(dangerBaseColor);

export const danger050 = palette['050']; // #f5d1da
export const danger100 = palette['100']; // #f2c2ce
export const danger200 = palette['200']; // #edaebd
export const danger300 = palette['300']; // #e792a8
export const danger400 = palette['400']; // #e06e8a
export const danger500 = palette['500']; // #D53E64
export const danger500_10 = palette['500_10']; // #D53E64
export const danger500_40 = palette['500_40']; // #D53E64
export const danger600 = palette['600']; // #c02a4f
export const danger700 = palette['700']; // #a32343
export const danger700_30 = palette['700_30']; // #a32343
export const danger700_40 = palette['700_40']; // #a32343
export const danger800 = palette['800']; // #8b1e39
export const danger900 = palette['900']; // #761a31
