/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import { Transition } from 'react-transition-group';

import useSlideInOut from './hooks/useSlideInOut';
import { SlideInOutProps, TransitionDirection } from './types';

const defaultStyle = (duration: number) => ({
  transition: `transform ${duration}ms ease-in-out`,
  transform: 'translate(0, -100%)',
});

const SlideInOut = ({
  className,
  durationIn = 300,
  durationOut = 200,
  in: inProp,
  children,
  onClick,
  translateX,
  transitionDirection = TransitionDirection.FromTop,
  ...rest
}: SlideInOutProps) => {
  const { transitionStyles, ref } = useSlideInOut(
    transitionDirection,
    translateX
  );

  return (
    <Transition
      appear
      in={inProp}
      nodeRef={ref}
      timeout={{
        enter: durationIn,
        exit: durationOut,
      }}
      unmountOnExit
      {...rest}
    >
      {(state) => (
        <div
          ref={ref}
          className={className}
          onClick={onClick}
          style={{
            ...defaultStyle(inProp ? durationIn : durationOut),
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};

export default memo(SlideInOut);
