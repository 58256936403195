import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Fullscreen: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 30 24"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <rect vectorEffect="non-scaling-stroke" width="27.18" height="20.88" x="1.41" y="1.56" stroke={color} rx=".98" ry=".98" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M26.03 13.89v5.99h-5.98m5.92-.06-6.24-6.24M3.97 13.89v5.99h5.98m-5.92-.06 6.24-6.24M3.97 10.11V4.12h5.98m-5.92.06 6.24 6.24M26.03 10.11V4.12h-5.98m5.92.06-6.24 6.24" />
    </g>
  </WebsiteIcon>
);

export default memo(Fullscreen);
