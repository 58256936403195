import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const IdBadge: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 26 22"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M7.85 8.13c-1.59 0-2.87 1.29-2.87 2.87s1.29 2.87 2.87 2.87 2.87-1.29 2.87-2.87-1.28-2.87-2.87-2.87zM4.31 20.53v-2.91c0-1.1.9-2 2-2h3.2c1.1 0 2 .9 2 2v2.91" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M16.88 5.05h6.87c.66 0 1.19.54 1.19 1.19v13.17c0 .66-.54 1.19-1.19 1.19H2.25c-.66 0-1.19-.54-1.19-1.19V6.24c0-.66.54-1.19 1.19-1.19h7.3" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M11.62 1.4h3.23v5.45h-3.23zM14.42 10.09h7.09M14.42 13.06h7.09M14.42 16.03h7.09" />
    </g>
  </WebsiteIcon>
);

export default memo(IdBadge);
