/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnyCardModel } from '@kitted/card-service-models';

import { Weightings } from '../../../../hooks/useTextSearch/types';

export const handlePopulateSearchDb = (
  insertBatch: (data: any[], shouldClearFirst: boolean) => void,
  cardsBatch: AnyCardModel[]
) => {
  insertBatch(cardsBatch, true);
};

export const handleSearchCardsByText = (
  searchString: string,
  search: (term: string, weightings: Weightings) => any[]
) =>
  search(searchString, {
    title: 2,
    slug: 0.5,
  });

export const cardItemKeyResolver = (card: AnyCardModel): string => card.id;
