import { z } from 'zod';

import {
  CMSContentBlockCardGridLoadedModelSchema,
  CMSContentBlockCardGridUnloadedModelSchema,
  CMSContentBlockTextModelSchema,
  CMSContentBlockVideoLoadedModelSchema,
  CMSContentBlockVideoUnloadedModelSchema,
} from '../content-blocks';

export const AnyCMSKitBlockLoadedModelSchema = z.union([
  CMSContentBlockCardGridLoadedModelSchema,
  CMSContentBlockVideoLoadedModelSchema,
  CMSContentBlockTextModelSchema,
]);

export const AnyCMSKitBlockUnloadedModelSchema = z.union([
  CMSContentBlockCardGridUnloadedModelSchema,
  CMSContentBlockVideoUnloadedModelSchema,
  CMSContentBlockTextModelSchema,
]);

export type AnyCMSKitBlockLoadedModel = z.infer<
  typeof AnyCMSKitBlockLoadedModelSchema
>;
export type AnyCMSKitBlockUnloadedModel = z.infer<
  typeof AnyCMSKitBlockUnloadedModelSchema
>;

export type AnyCMSKitBlockModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? AnyCMSKitBlockLoadedModel
    : AnyCMSKitBlockUnloadedModel;
