import { memo } from 'react';

import { StoreSectionInitializerProps } from '../types';
import useLinkResourceStoreInitializer from './hooks/useLinkResourceStoreInitializer';

const LinkResourceStoreInitializer = ({
  onSectionLoaded,
}: StoreSectionInitializerProps) => {
  useLinkResourceStoreInitializer(onSectionLoaded);

  return null;
};

export default memo(LinkResourceStoreInitializer);
