import { memo } from 'react';
import { Panel, Typography } from '@kitted/shared-components';

import FormFields from '../../../../../../../components/FormFields';
import PreviewCard from '../../../../../../../components/PreviewCard';
import SplitContent from '../../../../../../../components/SplitContent';
import { VideoResourceEditSpaceProps } from '../types';
import useVideoResourceEditPageContentPageSection from './hooks/useVideoResourceEditPageContentPageSection';
import { layoutSchema } from './constants';

const VideoResourceEditPageContentPageSection = ({
  videoResource,
}: VideoResourceEditSpaceProps) => {
  const { overrideCardProps } = useVideoResourceEditPageContentPageSection();

  return (
    <SplitContent
      left={
        <PreviewCard
          cardId={videoResource?.id}
          overrideCardProps={overrideCardProps}
        />
      }
    >
      <Panel theme="primary">
        <Typography variant="formTitle">Video resource details</Typography>
        <FormFields layout={layoutSchema} theme="primary" />
      </Panel>
    </SplitContent>
  );
};

export default memo(VideoResourceEditPageContentPageSection);
