import { useMemo, useState } from 'react';

import {
  CatalogueSelectorViewState,
  getInitalCatalogueSelectorViewState,
  getSetFilter,
  getSetGalleryItems,
  getSetIsLoading,
  getSetMyImages,
  getSetPlatformImages,
  getSetSearchText,
  getSetSelectedCatalogueItem,
  getSetUploadedBlobGuid,
} from '../../logic';
import { CatalogueSelectorViewApi } from '../../types';
import useSyncAssetCatalogueData from '../useSyncAssetCatalogueData';

const useCatalogueSelectorManagement = (): [
  CatalogueSelectorViewState,
  CatalogueSelectorViewApi,
] => {
  const [catalogueSelectorViewState, setCatalogueSelectorViewState] = useState(
    getInitalCatalogueSelectorViewState()
  );

  const catalogueSelectorViewApi = useMemo(
    () => ({
      getInitalState: () => getInitalCatalogueSelectorViewState(),
      setFilter: getSetFilter(setCatalogueSelectorViewState),
      setSearchText: getSetSearchText(setCatalogueSelectorViewState),
      setGalleryItems: getSetGalleryItems(setCatalogueSelectorViewState),
      setPlatformImages: getSetPlatformImages(setCatalogueSelectorViewState),
      setMyImages: getSetMyImages(setCatalogueSelectorViewState),
      setSelectedCatalogueItem: getSetSelectedCatalogueItem(
        setCatalogueSelectorViewState
      ),
      setIsLoading: getSetIsLoading(setCatalogueSelectorViewState),
      setUploadedBlobGuid: getSetUploadedBlobGuid(
        setCatalogueSelectorViewState
      ),
    }),
    []
  );

  // Sync the data from the server
  useSyncAssetCatalogueData(catalogueSelectorViewApi);

  return useMemo(
    () => [catalogueSelectorViewState, catalogueSelectorViewApi],
    [catalogueSelectorViewState, catalogueSelectorViewApi]
  );
};

export default useCatalogueSelectorManagement;
