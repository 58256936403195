import { useMemo } from 'react';
import { upperFirst } from '@kitted/shared-utils';

import { ModelCreateEditEditModalSpaceProps } from '../../../types';
import { getLayoutSchema } from './logic';

const useModelCreateEditForm = (
  cardType: ModelCreateEditEditModalSpaceProps['cardType']
) => {
  const upperFirstCardType = useMemo(() => upperFirst(cardType), [cardType]);

  const layoutSchema = useMemo(
    () => getLayoutSchema(upperFirstCardType),
    [upperFirstCardType]
  );

  const overrideCardProps = useMemo(
    () => ({
      type: cardType,
    }),
    [cardType]
  );

  return {
    upperFirstCardType,
    layoutSchema,
    overrideCardProps,
  };
};
export default useModelCreateEditForm;
