import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      position: 'relative',
      display: 'inline-flex',
      minWidth: 185,
      alignItems: 'center',
      gap: 25,
      borderRadius: '100rem',
      textDecoration: 'none',
      cursor: 'pointer',
      outline: 'none',
      overflow: 'hidden',
      transition: styling.transitions.utils.generate(
        ['color', styling.speeds.jog],
        ['border-color', styling.speeds.jog],
        ['background', styling.speeds.jog]
      ),
    },
    fullWidth: {
      width: '100%',
    },
    icon: {
      transition: styling.transitions.utils.generate([
        'opacity',
        styling.speeds.jog,
      ]),
      flexShrink: 0,
      flexGrow: 0,
    },
    content: {
      whiteSpace: 'nowrap',
      transition: styling.transitions.utils.generate([
        'opacity',
        styling.speeds.jog,
      ]),
      flexGrow: 1,
    },
    isDisabled: {},
    isLoading: {
      cursor: 'progress',
      '& $icon, & $content': {
        opacity: 0,
      },
    },
    'theme-primary': {
      '--buttonSolidBackground': styling.colors.theme.primary600,
      '--buttonSolidColor': styling.colors.theme.secondary,
      '--buttonSolidBackgroundHover': styling.colors.theme.primary1000,
      '--buttonSolidBorderHover': styling.colors.theme.secondary,
      '--buttonOutlineBorder': styling.colors.theme.primary600,
      '--buttonOutlineColor': styling.colors.theme.primary600,
      '--buttonOutlineColorHover': styling.colors.theme.primary1000,
      '--buttonOutlineBorderHover': styling.colors.theme.primary1000,
      '--buttonOutlineBackgroundHover': styling.colors.theme.secondary050,
      '--buttonGhostColor': styling.colors.theme.primary600,
      '--buttonGhostColorHover': styling.colors.theme.secondary,
      '--buttonGhostBackgroundHover': styling.colors.theme.primary600,
      '--buttonInlineColor': styling.colors.theme.primary400,
      '--buttonInlineColorHover': styling.colors.theme.primary800,
    },
    'theme-secondary': {
      '--buttonSolidBackground': styling.colors.theme.secondary,
      '--buttonSolidColor': styling.colors.theme.primary1000,
      '--buttonSolidBorder': styling.colors.theme.primary1000,
      '--buttonSolidBackgroundHover': styling.colors.theme.secondary050,
      '--buttonSolidBorderHover': styling.colors.theme.primary600,
      '--buttonOutlineBorder': styling.colors.theme.secondary300_40,
      '--buttonOutlineColor': styling.colors.theme.secondary,
      '--buttonOutlineColorHover': styling.colors.theme.secondary,
      '--buttonOutlineBorderHover': styling.colors.theme.secondary050,
      '--buttonGhostColor': styling.colors.theme.secondary,
      '--buttonGhostColorHover': styling.colors.theme.primary1000,
      '--buttonGhostBackgroundHover': styling.colors.theme.secondary,
      '--buttonInlineColor': styling.colors.theme.secondary,
      '--buttonInlineColorHover': styling.colors.theme.secondary500,
    },
    'theme-action': {
      '--buttonSolidBackground': styling.colors.theme.action600,
      '--buttonSolidColor': styling.colors.context.inverted.color,
      '--buttonSolidBackgroundHover': styling.colors.theme.action200,
      '--buttonOutlineBorder': styling.colors.theme.action500_40,
      '--buttonOutlineColor': styling.colors.theme.action600,
      '--buttonOutlineColorHover': styling.colors.theme.action600,
      '--buttonOutlineBorderHover': styling.colors.theme.action200,
      '--buttonGhostColor': styling.colors.theme.action,
      '--buttonGhostColorHover': styling.colors.theme.action900,
      '--buttonGhostBackgroundHover': styling.colors.theme.action100,
      '--buttonInlineColor': styling.colors.theme.action,
      '--buttonInlineColorHover': styling.colors.theme.action900,
    },
    'theme-frame': {
      '--buttonSolidBackground': styling.colors.theme.frame,
      '--buttonSolidColor': styling.colors.context.inverted.color,
      '--buttonSolidBackgroundHover': styling.colors.theme.frame300,
      '--buttonOutlineBorder': styling.colors.theme.frame500_40,
      '--buttonOutlineColor': styling.colors.theme.frame,
      '--buttonOutlineColorHover': styling.colors.theme.frame,
      '--buttonOutlineBorderHover': styling.colors.theme.frame200,
      '--buttonGhostColor': styling.colors.theme.frame,
      '--buttonGhostColorHover': styling.colors.theme.frame900,
      '--buttonGhostBackgroundHover': styling.colors.theme.frame100,
      '--buttonInlineColor': styling.colors.theme.frame,
      '--buttonInlineColorHover': styling.colors.theme.frame900,
    },
    'theme-snare': {
      '--buttonSolidBackground': styling.colors.theme.snare,
      '--buttonSolidColor': styling.colors.context.inverted.color,
      '--buttonSolidBackgroundHover': styling.colors.theme.snare200,
      '--buttonOutlineBorder': styling.colors.theme.snare500_40,
      '--buttonOutlineColor': styling.colors.theme.snare,
      '--buttonOutlineColorHover': styling.colors.theme.snare300,
      '--buttonOutlineBorderHover': styling.colors.theme.snare200,
      '--buttonGhostColor': styling.colors.theme.snare,
      '--buttonGhostColorHover': styling.colors.theme.snare900,
      '--buttonGhostBackgroundHover': styling.colors.theme.snare100,
      '--buttonInlineColor': styling.colors.theme.snare,
      '--buttonInlineColorHover': styling.colors.theme.snare900,
    },
    'theme-insight': {
      '--buttonSolidBackground': styling.colors.theme.insight,
      '--buttonSolidColor': styling.colors.context.inverted.color,
      '--buttonSolidBackgroundHover': styling.colors.theme.insight200,
      '--buttonOutlineBorder': styling.colors.theme.insight500_40,
      '--buttonOutlineColor': styling.colors.theme.insight,
      '--buttonOutlineColorHover': styling.colors.theme.insight300,
      '--buttonOutlineBorderHover': styling.colors.theme.insight200,
      '--buttonGhostColor': styling.colors.theme.insight,
      '--buttonGhostColorHover': styling.colors.theme.insight900,
      '--buttonGhostBackgroundHover': styling.colors.theme.insight100,
      '--buttonInlineColor': styling.colors.theme.insight,
      '--buttonInlineColorHover': styling.colors.theme.insight900,
    },
    'theme-danger': {
      '--buttonSolidBackground': styling.colors.theme.danger,
      '--buttonSolidColor': styling.colors.context.inverted.color,
      '--buttonSolidBackgroundHover': styling.colors.theme.danger200,
      '--buttonOutlineBorder': styling.colors.theme.danger700_40,
      '--buttonOutlineColor': styling.colors.theme.danger,
      '--buttonOutlineColorHover': styling.colors.theme.danger,
      '--buttonOutlineBorderHover': styling.colors.theme.danger200,
      '--buttonGhostColor': styling.colors.theme.danger,
      '--buttonGhostColorHover': styling.colors.theme.danger900,
      '--buttonGhostBackgroundHover': styling.colors.theme.danger100,
      '--buttonInlineColor': styling.colors.theme.danger,
      '--buttonInlineColorHover': styling.colors.theme.danger900,
    },
    'size-inherit': {
      fontWeight: styling.fonts.theme.atlasGrotesk.weights.bold,
      height: 'auto',
      fontSize: 'inherit',
      lineHeight: 'inherit',
    },
    'size-xs': {
      height: 46,
      gap: 18,
      paddingInline: 36,
      ...styling.typography.theme.atlasGrotesk.bold['2xs'],
      '& $icon': {
        fontSize: '2rem',
        width: 'unset',
        minWidth: '1.3em',
      },
    },
    'size-sm': {
      height: 52,
      paddingInline: 32,
      ...styling.typography.theme.atlasGrotesk.bold.xs,
      '& $icon': {
        fontSize: '2.4rem',
        width: 'unset',
        minWidth: '1.3em',
      },
    },
    'size-md': {
      height: 66,
      paddingInline: 40,
      ...styling.typography.theme.atlasGrotesk.bold.sm,
      '& $icon': {
        fontSize: '3rem',
        width: 'unset',
        minWidth: '1.3em',
      },
    },
    'size-lg': {
      height: 80,
      paddingInline: 48,
      ...styling.typography.theme.atlasGrotesk.bold.xl,
      '& $icon': {
        fontSize: '3.6rem',
        width: 'unset',
        minWidth: '1.3em',
      },
    },
    'variant-solid': {
      border: [
        1,
        'solid',
        'var(--buttonSolidBorder, var(--buttonSolidBackground))',
      ],
      background: 'var(--buttonSolidBackground)',
      color: 'var(--buttonSolidColor)',
      '&$isDisabled': {
        opacity: 0.4,
        ...styling.utils.disabled('var(--buttonSolidColor)'),
      },
      '&:not($isDisabled):not($isLoading)': {
        '&:hover,&:active,&:focus': {
          borderColor:
            'var(--buttonSolidBorderHover, var(--buttonSolidBackgroundHover))',
          background: 'var(--buttonSolidBackgroundHover)',
        },
      },
    },
    'variant-outline': {
      backgroundColor: styling.colors.theme.transparent,
      border: [1, 'solid', 'var(--buttonOutlineBorder)'],
      color: 'var(--buttonOutlineColor)',
      '&$isDisabled': {
        opacity: 0.4,
        ...styling.utils.disabled('var(--buttonOutlineBorder)'),
      },
      '&:not($isDisabled),&:not($isLoading)': {
        '&:hover,&:active,&:focus': {
          color: 'var(--buttonOutlineColorHover)',
          borderColor: 'var(--buttonOutlineBorderHover)',
          backgroundColor: `var(--buttonOutlineBackgroundHover, ${styling.colors.theme.transparent})`,
        },
      },
    },
    'variant-ghost': {
      borderRadius: 0,
      background: styling.colors.theme.transparent,
      color: 'var(--buttonGhostColor)',
      border: [1, 'solid', styling.colors.theme.transparent],
      '&$isDisabled': {
        opacity: 0.4,
        ...styling.utils.disabled('var(--buttonGhostBackgroundHover)'),
      },
      '&:not($isDisabled),&:not($isLoading)': {
        '&:hover,&:active,&:focus': {
          color: 'var(--buttonGhostColorHover)',
          background: 'var(--buttonGhostBackgroundHover)',
          borderColor: 'var(--buttonGhostBackgroundHover)',
        },
      },
    },
    'variant-inline': {
      background: styling.colors.theme.transparent,
      color: 'var(--buttonInlineColor)',
      border: 'none',
      padding: 0,
      borderRadius: 0,
      textDecoration: 'underline',
      '&$isDisabled': {
        opacity: 0.4,
        ...styling.utils.disabled('var(--buttonInlineColor)'),
      },
      '&:not($isDisabled),&:not($isLoading)': {
        '&:hover,&:active,&:focus': {
          color: 'var(--buttonInlineColorHover)',
        },
      },
    },
    'alignment-pullIconRight': {
      textAlign: 'left',
      justifyContent: 'flex-start',
      '& $icon:last-of-type': {
        marginLeft: 'auto',
      },
    },
    'alignment-pullLeft': {
      textAlign: 'left',
    },
    'alignment-center': {
      justifyContent: 'center',
      textAlign: 'center',
    },
    loadingSpinner: {
      ...styling.utils.absoluteFull(),
    },
  },
  { name: 'button' }
);
