import { z } from 'zod';

import {
  CMSContentBlockCarouselLoadedModelSchema,
  CMSContentBlockCarouselUnloadedModelSchema,
  CMSContentBlockImageLoadedModelSchema,
  CMSContentBlockImageUnloadedModelSchema,
  CMSContentBlockVideoLoadedModelSchema,
  CMSContentBlockVideoUnloadedModelSchema,
} from '../content-blocks';

export const AnyCMSPageBlockLoadedModelSchema = z.union([
  CMSContentBlockCarouselLoadedModelSchema,
  CMSContentBlockImageLoadedModelSchema,
  CMSContentBlockVideoLoadedModelSchema,
]);

export const AnyCMSPageBlockUnloadedModelSchema = z.union([
  CMSContentBlockCarouselUnloadedModelSchema,
  CMSContentBlockImageUnloadedModelSchema,
  CMSContentBlockVideoUnloadedModelSchema,
]);

export type AnyCMSPageBlockLoadedModel = z.infer<
  typeof AnyCMSPageBlockLoadedModelSchema
>;
export type AnyCMSPageBlockUnloadedModel = z.infer<
  typeof AnyCMSPageBlockUnloadedModelSchema
>;

export type AnyCMSPageBlockModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? AnyCMSPageBlockLoadedModel
    : AnyCMSPageBlockUnloadedModel;
