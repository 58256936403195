/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';

import Toast from '../Toast';
import useToastRoot from './hooks/useToastRoot';
import useStyles from './styles';

const ToastRoot = () => {
  const styles = useStyles();
  const { toasts } = useToastRoot();

  return (
    <div className={styles.container}>
      {toasts.map((toast) => {
        const toastKey = toast.key;
        const toastProps = toast.config || {};
        return <Toast key={toastKey} id={toastKey} {...toastProps} />;
      })}
    </div>
  );
};

export default memo(ToastRoot);
