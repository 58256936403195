/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import { Card, CardProps } from '@kitted/shared-components';

import usePreloadingCard from './hooks/usePreloadingCard';

const PreloadingCard = (props: CardProps) => {
  const { to } = props;
  usePreloadingCard(to);

  return <Card {...props} />;
};

export default memo(PreloadingCard);
