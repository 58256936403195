import { useMemo } from 'react';
import { useLocation, useParams, useResolvedPath } from 'react-router-dom';
import { InternalRoute, interpolateRoutePath } from '@kitted/website-constants';

const useIsPathSelected = (
  path = 'non-existent',
  preinterpolatedRoutePath = '',
  highlightSubroutes = false
) => {
  const params = useParams();
  const { pathname } = useResolvedPath(path);
  const { pathname: locationPathname } = useLocation();

  const resolvedPath = useMemo(() => {
    if (preinterpolatedRoutePath) {
      return interpolateRoutePath(
        { path: preinterpolatedRoutePath } as InternalRoute,
        params,
        false
      );
    }
    return '';
  }, [preinterpolatedRoutePath, params]);

  const isSelected = useMemo(() => {
    if (pathname === locationPathname) return true;
    if (pathname === resolvedPath) return true;
    if (highlightSubroutes) {
      if (locationPathname.startsWith(pathname)) return true;
      if (resolvedPath.startsWith(pathname)) return true;
    }
    return false;
  }, [pathname, locationPathname, resolvedPath, highlightSubroutes]);

  return isSelected;
};

export default useIsPathSelected;
