import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    footerContainer: {
      position: 'relative',
      zIndex: 3,
      background: styling.colors.context.footer.background,
      color: styling.colors.context.footer.color,
      padding: [120, 0, 87],
    },
    brandShop: {
      display: 'flex',
      gap: 48,
      flexDirection: 'column',
      [styling.breakpoints.context.tabletUpString]: {
        flexDirection: 'row',
        gap: 0,
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      },
    },
    brand: {
      position: 'relative',
      paddingTop: 13,
    },
    brandmark: {
      transform: 'translateY(-10px)',
      marginRight: 14,
    },
    shopButton: {
      minWidth: styling.sizes.context.columnW,
      ...styling.utils.buttonRightIconHover(),
    },
    ttfhMenus: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        width: '100%',
      },
      [styling.container.context.drawer.desktopUpString]: {
        flexDirection: 'row',
      },
    },
    ttfhBrandEntity: {
      display: 'flex',
      flexDirection: 'row',
      gap: 16,
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      marginBottom: 28,
      '& > *': {
        flexBasis: 'calc(50% - 8px)',
      },
      [styling.container.context.drawer.desktopUpString]: {
        gap: 0,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginBottom: 0,
        '& > *': {
          flexBasis: 'unset',
        },
      },
    },
    ttfhFrom: {
      marginBottom: 14,
    },
    ttfh: {
      fontWeight: styling.fonts.theme.atlasGrotesk.weights.medium,
      marginBottom: 4,
      [styling.container.context.drawer.desktopUpString]: {
        marginBottom: 28,
      },
    },
    ttfhUK: {
      marginBottom: 14,
    },
    menus: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 16,
      '& ul': {
        flexBasis: 'calc(50% - 8px)',
      },
      [styling.container.context.drawer.desktopUpString]: {
        justifyContent: 'flex-end',
        flexWrap: 'nowrap',
        '& ul': {
          flexBasis: 'unset',
          width: 150,
        },
      },
    },
    license: {
      position: 'relative',
    },
    licenseIcons: {
      marginTop: 17,
      '& > svg:first-child': {
        marginRight: 4,
      },
    },
    licenseBody: {
      marginBottom: 16,
      marginTop: 10,
    },
  },
  { name: 'footer' }
);
