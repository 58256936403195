/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import {
  AnyResourceCardModel,
  AnyToolCardModel,
  ArticleCardModel,
  AuthorCardModel,
  FlowCardModel,
  KitCardModel,
  PlayCardModel,
  SubjectCardModel,
} from '@kitted/card-service-models';
import { BlobGuid, CardType } from '@kitted/kitted-models';
import { getFullName } from '@kitted/shared-utils';

import { CardWideProps } from '../../../../../components/CardWide/types';
import { RenderCardWideComponentProps } from '../../types';

const getBlobStorageContentUrl = (baseUrl: string, blobId?: BlobGuid) => {
  if (!blobId) return undefined;

  if (blobId.startsWith('T::')) {
    return `https://temporary.blob-storage.${baseUrl}/${blobId}`;
  }

  return `https://persistent.blob-storage.${baseUrl}/${blobId}`;
};

const getCardComponentProps: Record<
  string,
  (baseUrl: string, card: any, authorName: string | undefined) => CardWideProps
> = {
  tool: (baseUrl: string, tool: AnyToolCardModel<true>) => ({
    slug: tool.slug,
    body: tool.description,
    imageSrc: getBlobStorageContentUrl(
      baseUrl,
      tool.keyImageImageAsset?.sizes.xl.blobGuid
    ),
    title: tool.title,
    subType: tool.toolType,
    type: CardType.Tool,
  }),
  subject: (baseUrl: string, subject: SubjectCardModel<true>) => ({
    slug: subject.slug,
    body: subject.description,
    imageSrc: getBlobStorageContentUrl(
      baseUrl,
      subject.keyImageImageAsset?.sizes.xl.blobGuid
    ),

    title: subject.title,
    type: CardType.Subject,
  }),
  kit: (baseUrl: string, kit: KitCardModel<true>) => ({
    slug: kit.slug,
    body: kit.description,
    imageSrc: getBlobStorageContentUrl(
      baseUrl,
      kit.keyImageImageAsset?.sizes.xl.blobGuid
    ),

    title: kit.title,
    type: CardType.Kit,
  }),
  article: (baseUrl: string, article: ArticleCardModel<true>) => ({
    slug: article.slug,
    body: article.description,
    imageSrc: getBlobStorageContentUrl(
      baseUrl,
      article.keyImageImageAsset?.sizes.xl.blobGuid
    ),

    title: article.title,
    type: CardType.Article,
  }),
  play: (baseUrl: string, play: PlayCardModel<true>) => ({
    slug: play.slug,
    body: play.description,
    imageSrc: getBlobStorageContentUrl(
      baseUrl,
      play.keyImageImageAsset?.sizes.xl.blobGuid
    ),

    title: play.title,
    type: CardType.Play,
  }),
  flow: (baseUrl: string, flow: FlowCardModel<true>) => ({
    slug: flow.slug,
    body: flow.description,
    imageSrc: getBlobStorageContentUrl(
      baseUrl,
      flow.keyImageImageAsset?.sizes.xl.blobGuid
    ),

    title: flow.title,
    type: CardType.Flow,
  }),
  resource: (
    baseUrl: string,
    resource: AnyResourceCardModel<true>,
    authorName?: string
  ) => {
    const { resourceType, title, keyImageImageAsset, slug } = resource;
    return {
      slug,
      imageSrc: getBlobStorageContentUrl(
        baseUrl,
        keyImageImageAsset?.sizes.xl.blobGuid
      ),

      title,
      type: CardType.Resource,
      subType: resourceType,
      authorName,
    };
  },
  author: (baseUrl: string, author: AuthorCardModel<true>) => ({
    type: CardType.Author,
    slug: author.slug,
    title: getFullName(author.firstName, author.lastName),
    body: author.description,
    imageSrc: getBlobStorageContentUrl(
      baseUrl,
      author.keyImageImageAsset?.sizes.xl.blobGuid
    ),
  }),
};

const getCardComponentDefaultProps = (): CardWideProps => ({
  slug: '',
  body: '',
  title: '',
  type: CardType.Tool,
});

export const useCardWideComponentProps = ({
  card,
  authorName,
  baseUrl,
}: RenderCardWideComponentProps) => {
  const cardComponentProps = useMemo(() => {
    const cardProps = getCardComponentProps[card.cardType as string]
      ? getCardComponentProps[card.cardType](baseUrl, card, authorName)
      : getCardComponentDefaultProps();
    return cardProps;
  }, [card, baseUrl, authorName]);

  return cardComponentProps;
};
