import { Fragment } from 'react';

import { LevelProps } from '../../Level/types';
import { NekuodahProps } from '../../types';

export const getLevels = (
  components: NekuodahProps['components'],
  children: NekuodahProps['children']
): LevelProps => {
  const reversedLevels = components.slice().reverse();

  return reversedLevels.reduce(
    (acc: LevelProps, provider: React.ElementType) => ({
      component: provider,
      childComponent: acc,
    }),
    {
      component: Fragment,
      children,
    } as LevelProps
  );
};
