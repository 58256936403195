import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    wrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      padding: 20,
    },
    disabledWrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      margin: 20,
    },
    profileAvatar: {
      zIndex: 10,
      boxShadow: styling.shadows.utils.boxShadowToString({
        x: 0,
        y: 0,
        blur: 0,
        spread: 1,
        inset: null,
        color: styling.colors.theme.secondary,
      }),
    },
    dot: {
      boxShadow: styling.shadows.utils.boxShadowToString({
        x: 0,
        y: 0,
        blur: 0,
        spread: 1,
        inset: null,
        color: styling.colors.theme.secondary,
      }),
    },
    tooltip: {
      padding: 0,
      width: styling.sizes.context.card.width,
    },
  },
  { name: 'cardAuthorAvatar' }
);
