/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';

import CardAuthorAvatar from '../CardAuthorAvatar';
import PreloadingCard from '../PreloadingCard';
import useCardWithImageAsset from './hooks/useCardWithImageAsset';
import { CardWithImageAssetProps } from './types';

const CardWithImageAsset = ({
  imageAsset,
  isFeatured,
  imageSrc,
  ...rest
}: CardWithImageAssetProps) => {
  const { loadedSrc } = useCardWithImageAsset(imageSrc, imageAsset, isFeatured);

  return (
    <PreloadingCard
      {...rest}
      isFeatured={isFeatured}
      imageSrc={loadedSrc}
      authorAvatar={CardAuthorAvatar}
    />
  );
};

export default memo(CardWithImageAsset);
