import { useCallback, useEffect } from 'react';
import { AuthorModel } from '@kitted/author-service-models';
import { KittedServiceEnum } from '@kitted/kitted-models';
import { WebsocketModelUpdateServerMessage } from '@kitted/websocket-service-models';

import useAuthorRequests from '../../../../../../hooks/authors/useAuthorRequests';
import { useSubscribeToWebsocketModelUpdateServerMessage } from '../../../../../WebsocketContext';
import useStoreApi from '../../../../hooks/useStoreApi';
import useStoreSection from '../../../../hooks/useStoreSection';
import { StoreSectionInitializerProps } from '../../../types';

const useAuthorStoreInitializer = (
  onSectionLoaded: StoreSectionInitializerProps['onSectionLoaded']
) => {
  const { getAuthor } = useAuthorRequests();
  const { createOrUpdateStoreSection } = useStoreApi();
  const { updateStoreSectionItem } = useStoreSection('Authors');

  const updateAuthorsStoreSectionFetcher = useCallback(() => {
    createOrUpdateStoreSection('Authors', getAuthor);
    onSectionLoaded('Authors');
  }, [createOrUpdateStoreSection, getAuthor, onSectionLoaded]);

  useEffect(updateAuthorsStoreSectionFetcher, [
    updateAuthorsStoreSectionFetcher,
  ]);

  const handleWebsocketItemUpdated = useCallback(
    ({ model, service }: WebsocketModelUpdateServerMessage) => {
      if (model && service === KittedServiceEnum.Author) {
        const asset = model as AuthorModel<false>;
        updateStoreSectionItem(asset, asset.id);
      }
    },
    [updateStoreSectionItem]
  );

  useSubscribeToWebsocketModelUpdateServerMessage(handleWebsocketItemUpdated);
};

export default useAuthorStoreInitializer;
