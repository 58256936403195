import { useMemo } from 'react';

import { useGlobalScriptVariables } from '../../contexts/GlobalScriptVariablesContext';
import { getEnvironmentDomain } from './logic';

// TODO: @haxxxton ~ This function should be in a kitted logic file somewhere
// We should be able to use it outside the context of react
const useEnvironmentDomain = () => {
  const { KITTED_ENVIRONMENT, KITTED_FEATURE, KITTED_DOMAIN } =
    useGlobalScriptVariables();

  const environmentDomain = useMemo(
    () =>
      getEnvironmentDomain(KITTED_ENVIRONMENT, KITTED_FEATURE, KITTED_DOMAIN),
    [KITTED_ENVIRONMENT, KITTED_FEATURE, KITTED_DOMAIN]
  );

  return environmentDomain;
};

export default useEnvironmentDomain;
