import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0053: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 96 106"
		height={106}
		width={96}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><path vectorEffect="non-scaling-stroke" stroke={spot} d="M1.83 1.79c10.96 0 19.84 6.1 19.84 13.63S12.78 29.05 1.83 29.05M1.83 1.79v44.79" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M1.83 59.79c10.96 0 19.84 6.1 19.84 13.63S12.79 87.05 1.83 87.05M1.83 59.79v44.79" /></g><g ><path vectorEffect="non-scaling-stroke" stroke={spot} d="M74.33 1.79c10.96 0 19.84 6.1 19.84 13.63s-8.88 13.63-19.84 13.63M74.33 1.79v44.79" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M74.33 59.79c10.96 0 19.84 6.1 19.84 13.63s-8.88 13.63-19.84 13.63M74.33 59.79v44.79" /></g><g ><path vectorEffect="non-scaling-stroke" stroke={color} d="M38.95 1.79v44.79M38.95 1.79c10.96 0 19.84 6.1 19.84 13.63s-8.88 13.63-19.84 13.63" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M38.95 59.79c10.96 0 19.84 6.1 19.84 13.63s-8.88 13.63-19.84 13.63M38.95 59.79v44.79" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0053);
