import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      position: 'relative',
      background: styling.colors.context.inverted.background,
      color: styling.colors.context.inverted.color,
      paddingBottom: styling.sizes.theme.spacing.xs,
      paddingInline: styling.sizes.theme.spacing.xs,
      [styling.breakpoints.context.tabletUpString]: {
        paddingBlock: styling.sizes.theme.spacing['3xs'],
        paddingInline: [
          [styling.sizes.theme.spacing.lg, styling.sizes.theme.spacing.xs],
        ],
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 1,
        backgroundColor: styling.colors.theme.primary400,
      },
    },
    title: {
      paddingBlock: styling.sizes.theme.spacing.xs,
    },
    buttons: {
      '& > *': {
        width: '100%',
      },
      [styling.breakpoints.context.mobileOnlyString]: {
        '& button': {
          minWidth: 0,
          paddingInline: styling.sizes.theme.spacing.xs,
        },
      },
      [styling.breakpoints.context.tabletUpString]: {
        width: 'auto',
        '& > *': {
          width: '100%',
        },
      },
    },
  },
  { name: 'editDrawerSpace' }
);
