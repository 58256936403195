import { Nullable } from '@kitted/platform-models';

export enum DrawerSpaceDrawerSide {
  Main = 'main',
  Contextual = 'contextual',
}

export type DrawerSpaceContainerContextProps = {
  children: React.ReactNode;
  isMainDisabled: boolean;
};

export type DrawerSpaceContainerData = {
  isXs: boolean;
  isSm: boolean;
  isMd: boolean;
  isLg: boolean;
  isXl: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isMainOpen: boolean;
  isContextualOpen: boolean;
  isMainDisabled: boolean;
  portalRoot: HTMLDivElement | null;
  contentRoot: HTMLDivElement | null;
  isContextualDisabled: boolean;
  contextualDrawerContent: Nullable<React.ElementType>;
  contextualDrawerHeader: Nullable<React.ElementType>;
  contextualDrawerContentProps: Record<string, unknown>;
};

export type DrawerSpaceContainerApi = {
  onSetRef: (newElement: HTMLDivElement | null) => void;
  onSetContentRoot: (newElement: HTMLDivElement | null) => void;
  onToggleOpen: (drawerSide: DrawerSpaceDrawerSide) => () => void;
  onClose: () => void;
  setIsContextualDisabled: (isDisabled: boolean) => void;
  mountContextualDrawer: (
    newContent: React.ElementType,
    newContentProps?: Record<string, unknown>,
    newHeader?: React.ElementType
  ) => void;
  unmountContextualDrawer: () => void;
  updateContextualDrawerContentProps: (
    newContentProps: Record<string, unknown>
  ) => void;
};
