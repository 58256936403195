/* eslint-disable @typescript-eslint/no-explicit-any */
export enum AsyncRequestState {
  Default = 'idle',
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
}

export type RequestFn<ResponseType = any> = (
  ...args: any[]
) => Promise<ResponseType>;

export type SuccessCallback<ResponseType> = (
  result: ResponseType,
  ...args: any[]
) => void;

export type AsyncRequestReturnValues<ResponseType> = [
  RequestFn<ResponseType>,
  AsyncRequestState,
  Error | undefined,
];
