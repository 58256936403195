import { useCallback, useEffect } from 'react';
import { FavoriteModel } from '@kitted/account-service-models';
import { KittedModelTypeEnum, KittedServiceEnum } from '@kitted/kitted-models';

import useFavoriteRequests from '../../../../../../hooks/favorites/useFavoriteRequests';
import { KittedWebsocketModelUpdateSubscription } from '../../../../../../services/websocket';
import { useSubscribeToWebsocketModelUpdateServerMessage } from '../../../../../WebsocketContext';
import useStoreApi from '../../../../hooks/useStoreApi';
import useStoreSection from '../../../../hooks/useStoreSection';
import { StoreSectionInitializerProps } from '../../../types';

const useFavoritesStoreInitializer = (
  onSectionLoaded: StoreSectionInitializerProps['onSectionLoaded']
) => {
  const { requestFavoriteById } = useFavoriteRequests();
  const { createOrUpdateStoreSection } = useStoreApi();
  const { updateStoreSectionItem } = useStoreSection('Favorites');

  const updateFavoritesStoreSectionFetcher = useCallback(() => {
    createOrUpdateStoreSection('Favorites', requestFavoriteById);
    onSectionLoaded('Favorites');
  }, [createOrUpdateStoreSection, requestFavoriteById, onSectionLoaded]);

  useEffect(updateFavoritesStoreSectionFetcher, [
    updateFavoritesStoreSectionFetcher,
  ]);

  const handleWebsocketItemUpdated: KittedWebsocketModelUpdateSubscription =
    useCallback(
      ({ model, service }) => {
        if (model && service === KittedServiceEnum.Account) {
          if (model.kittedModelType === KittedModelTypeEnum.AccountFavorite) {
            const favorite = model as unknown as FavoriteModel;
            updateStoreSectionItem(favorite, favorite.id);
          }
        }
      },
      [updateStoreSectionItem]
    );

  useSubscribeToWebsocketModelUpdateServerMessage(handleWebsocketItemUpdated);
};

export default useFavoritesStoreInitializer;
