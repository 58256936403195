import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      ...styling.utils.absoluteFull(),
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      overflow: 'hidden',
      paddingLeft: styling.sizes.theme.spacing.xs,
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        width: 5,
        background: styling.colors.theme.primary900,
      },
    },
    branding: {
      display: 'inline-block',
      minWidth: 179,
      transformOrigin: 'left center',
      transform: 'translate(-6px, 2px) scale(0.65)',
      transition: styling.transitions.utils.generate(['transform']),
    },
    brandingOpen: {
      transform: 'translate(0) scale(1)',
    },
  },
  { name: 'defaultEditDrawerHeader' }
);
