import { z } from 'zod';
import {
  ArticleSlugSchema,
  ImageAssetModelSchema,
  KittedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '@kitted/kitted-models';
import { DateISOSchema } from '@kitted/platform-models';

import {
  AnyCMSArticleBlockLoadedModelSchema,
  AnyCMSArticleBlockUnloadedModelSchema,
} from './AnyCMSArticleBlockModel';

export const CMSArticleLoadedModelSchema = KittedModelBaseSchema.merge(
  z.object({
    slug: ArticleSlugSchema,
    title: z.string(),
    description: z.string(),
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      true
    ).optional(),
    articleBlocks: makeKittedModelReferenceSchema(
      AnyCMSArticleBlockLoadedModelSchema,
      true
    ).array(),
    excludeFromDiscovery: z.boolean(),
    live: z.boolean(),
    createdByAuthorId: z.string(),
    createdByUserId: z.string(),

    createdAt: DateISOSchema,
    updatedAt: DateISOSchema,
  })
);

export const CMSArticleUnloadedModelSchema = CMSArticleLoadedModelSchema.merge(
  z.object({
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      false
    ).optional(),

    articleBlocks: makeKittedModelReferenceSchema(
      AnyCMSArticleBlockUnloadedModelSchema,
      false
    ).array(),
  })
);

export type CMSArticleLoadedModel = z.infer<typeof CMSArticleLoadedModelSchema>;
export type CMSArticleUnloadedModel = z.infer<
  typeof CMSArticleUnloadedModelSchema
>;

export type CMSArticleModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? CMSArticleLoadedModel : CMSArticleUnloadedModel;
