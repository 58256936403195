import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    modal: {
      cursor: 'default',
      width: '100%',
      position: 'relative',
      maxWidth: styling.sizes.utils.gridColumns(
        3,
        styling.sizes.context.gutteringW
      ),
      borderRadius: styling.sizes.context.cardFeature.radius,
      margin: 'auto',
      background: styling.colors.context.inverted.background,
      color: styling.colors.context.inverted.color,
      boxSizing: 'border-box',
      border: [1, 'solid', styling.colors.context.inverted.background],
    },
    closeButton: {
      background: styling.colors.theme.primary800,
      position: 'absolute',
      top: -48 - 34,
      right: 0,
    },
    content: {
      position: 'relative',
      zIndex: 2,
      padding: [52, 40, 47],
      textAlign: 'center',
      maxWidth: styling.sizes.utils.gridColumns(
        2,
        styling.sizes.context.gutteringW
      ),
      margin: [0, 'auto'],
    },
    contentWithImage: {},
    title: {
      marginBottom: 20,
    },
    image: {
      aspectRatio: 2 / 1,
      borderTopLeftRadius: styling.sizes.context.cardFeature.radius,
      borderTopRightRadius: styling.sizes.context.cardFeature.radius,
      overflow: 'hidden',
    },
  },
  { name: 'defaultModalContent' }
);
