import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { clearTimeout, setTimeout } from 'worker-timers';
import { NOOP } from '@kitted/shared-utils';

import {
  FormDataValue,
  FormStates,
  useFormApi,
  useFormData,
} from '../../../../contexts/FormContext';
import logger from '../../../../services/logger';
import { FormProps } from '../../types';
import { generateHandleOnSubmit } from './logic';

const useFormSubmit = (
  onSubmit: FormProps['onSubmit'],
  onSubmitSuccess: FormProps['onSubmitSuccess'],
  onSubmitFail: FormProps['onSubmitFail']
) => {
  const {
    getFormData,
    setFormIsSubmitting,
    resetForm,
    addFormLoadingItem,
    removeFormLoadingItem,
  } = useFormApi();
  const { formState, dataSchema } = useFormData();

  const hasCaptcha = !!dataSchema.form?.hasCaptcha;
  const [executeCaptchaRef, setExecuteCaptchaRef] = useState<{
    execute: typeof NOOP;
  }>({
    execute: NOOP,
  });
  const [captchaLoaded, setCaptchaLoaded] = useState<boolean>(!hasCaptcha);
  const timeoutRef = useRef<number | null>(null);
  const resolveRef = useRef<() => void>();
  const rejectRef = useRef<(e: unknown) => void>();
  const formDataRef = useRef<FormDataValue>(getFormData());

  const registerExecuteCaptcha = useCallback(
    (execute: () => void) => {
      setExecuteCaptchaRef({ execute });
    },
    [setExecuteCaptchaRef]
  );

  const onRecaptchaReady = useCallback(() => {
    logger.log('recaptcha loaded');
    setCaptchaLoaded(true);
  }, [setCaptchaLoaded]);

  const onCaptchaVerified = useCallback(
    async (captchaResponse: string) => {
      clearTimeout(timeoutRef.current!);
      try {
        await onSubmit({
          ...formDataRef.current,
          'g-recaptcha-response': captchaResponse,
        });
        resolveRef.current!();
      } catch (e) {
        rejectRef.current!(e);
      }
    },
    [onSubmit]
  );

  const wrappedOnSubmit = useCallback(
    async (submittedFormData: FormDataValue) => {
      if (!hasCaptcha) {
        return onSubmit(submittedFormData);
      }

      if (!executeCaptchaRef) {
        throw new Error('[FORM] captcha execute not registered');
      }

      const abortController = new AbortController();
      const timeout = setTimeout(() => {
        console.log('you took too long, aborting!');
        abortController.abort(); // Abort the request if it takes longer than 60 seconds
      }, 60 * 1000); // Maximum wait time of 60 seconds

      return new Promise<void>((resolve, reject) => {
        abortController.signal.addEventListener('abort', () => {
          reject(new Error('Captcha verification timed out.'));
        });

        timeoutRef.current = timeout;
        resolveRef.current = resolve;
        rejectRef.current = reject;
        formDataRef.current = submittedFormData;

        executeCaptchaRef.execute(); // Call the reCAPTCHA execute method
      });
    },
    [onSubmit, hasCaptcha, executeCaptchaRef]
  );

  const handleOnSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (formState === FormStates.DIRTY) {
        setFormIsSubmitting(true);
        try {
          await generateHandleOnSubmit({
            getFormData,
            onSubmit: wrappedOnSubmit,
            onSubmitSuccess,
            onSubmitFail,
            resetForm,
          });
        } finally {
          setFormIsSubmitting(false);
        }
      }
    },
    [
      formState,
      getFormData,
      wrappedOnSubmit,
      onSubmitSuccess,
      onSubmitFail,
      resetForm,
      setFormIsSubmitting,
    ]
  );

  useEffect(() => {
    if (!captchaLoaded) {
      addFormLoadingItem('__captcha');
    }
    return () => {
      removeFormLoadingItem('__captcha');
    };
  }, [captchaLoaded, addFormLoadingItem, removeFormLoadingItem]);

  return {
    hasCaptcha,

    registerExecuteCaptcha,
    onCaptchaVerified,
    onRecaptchaReady,

    handleOnSubmit,
  };
};

export default useFormSubmit;
