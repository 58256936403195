import { Dispatch, SetStateAction } from 'react';

import { Modal, ModalData } from '../../types';

export const getDeregisterModal = (
  setModals: Dispatch<SetStateAction<ModalData['modals']>>,
  modalId: string
) => {
  setModals((modals) => {
    const currentModal = modals.find((modal) => modal.key === modalId);
    currentModal?.config?.onClose?.();
    return modals.filter((modal) => modal.key !== modalId);
  });
};

export const getRegisterModal = (
  setModals: Dispatch<SetStateAction<ModalData['modals']>>,
  modalId: string,
  modalConfig: Modal
) => {
  setModals((modals) => {
    if (modals.find((modal) => modal.key === modalId)) {
      return modals;
    }
    return [...modals, { config: modalConfig, key: modalId }];
  });
};

export const handleModalsChange = (modals: ModalData['modals']) => {
  if (modals.length) {
    window.document.scrollingElement?.classList.add('lock-scroll-modal');
    return;
  }
  window.document.scrollingElement?.classList.remove('lock-scroll-modal');
};
