import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

import typographyConstants from '../../Typography/constants';

export const centerOffset = 21;

export default createUseStyles(
  {
    container: {
      '--cardContentsTitleHeight': `calc(var(--cardTitleMaxLines) * ${typographyConstants.cardTitle.lineHeight})`,
      transition: styling.transitions.utils.generate(['color']),
    },
    containerFeatured: {
      '--cardContentsTitleHeight': `calc(var(--cardTitleMaxLines) * ${typographyConstants.cardTitleFeatured.lineHeight})`,
      '& $cardBody': {
        paddingInline: styling.utils.px([
          styling.sizes.context.cardFeature.paddingInline,
          52,
        ]),
      },
    },
    containerHover: {
      '& $cardBody': {
        paddingInline: 'var(--cardPaddingInline)',
      },
    },
    'variant-belowMiddle': {
      '& $cardTitleContainer': {
        top: `calc(50% + ${centerOffset}px)`,
      },
      '& $cardBody': {
        top: `calc(50% + var(--cardContentsTitleHeight) + ${
          centerOffset + 8
        }px)`,
      },
    },
    'variant-straddleMiddle': {
      '& $cardTitleContainer': {
        bottom: `calc(50% - ${centerOffset}px)`,
        paddingBlock: `calc(${typographyConstants.cardTitle.lineHeight} / 2)`,
      },
      '& $cardBody': {
        top: `calc(50% + ${centerOffset}px)`,
      },
    },
    cardTitleContainer: {
      height: 'var(--cardContentsTitleHeight)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'absolute',
      left: 0,
      right: 0,
      paddingInline: 'var(--cardPaddingInline)',
      boxSizing: 'content-box',
    },
    cardBody: {
      position: 'absolute',
      left: 0,
      right: 0,
      paddingInline: styling.sizes.context.card.paddingInline,
    },
  },
  { name: 'cardContents' }
);
