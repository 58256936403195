import { useCallback, useEffect, useRef, useState } from 'react';
import { AnyCardModel } from '@kitted/card-service-models';
import { EMPTY_ARR, uniqBy } from '@kitted/shared-utils';

import { AsyncRequestState } from '../../../../hooks/useAsyncRequest/types';
import useCardsRequests from '../../../../hooks/useCardsRequests';
import useTextSearch from '../../../../hooks/useTextSearch';
import { useCardsOrderingApi } from '../../../CardsOrderingContext';
import useStoreSection from '../../../StoreContext/hooks/useStoreSection';
import useIsLoggedIn from '../../../UserContext/hooks/useIsLoggedIn';
import useIsLoggingIn from '../../../UserContext/hooks/useIsLoggingIn';
import { cardSearchSchema } from './constants';
import {
  cardItemKeyResolver,
  handlePopulateSearchDb,
  handleSearchCardsByText,
} from './logic';

const useCardsManagement = () => {
  const { setInitialPlatformCardOrdering, setInitialUserCardOrdering } =
    useCardsOrderingApi();
  const [allCards, setAllCards] = useState<AnyCardModel[] | undefined>();
  const [myCards, setMyCards] = useState<AnyCardModel[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const hasLoadedOnceRef = useRef<boolean>(false);

  const { setStoreSectionItems: setCards } =
    useStoreSection<AnyCardModel>('Cards');
  const { requestAllCards, requestMyCards, requestMyCardsState } =
    useCardsRequests();
  const { isLoggedIn } = useIsLoggedIn();
  const { isLoggingIn } = useIsLoggingIn();

  const {
    search,
    insertBatch,
    upsertItem: upsertCardToTextSearchDb,
  } = useTextSearch(cardSearchSchema);

  const getCardIdsByText = useCallback(
    (searchTerm: string) => handleSearchCardsByText(searchTerm, search),
    [search]
  );

  const fetchAllCards = useCallback(async () => {
    const allCardsData = await requestAllCards();

    if (allCardsData) {
      setAllCards(allCardsData);
    }
  }, [requestAllCards, setAllCards]);

  useEffect(() => {
    fetchAllCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMyCards = useCallback(async () => {
    const myCardsData = await requestMyCards();

    if (myCardsData) {
      setMyCards(myCardsData);
    }
  }, [requestMyCards, setMyCards]);

  useEffect(() => {
    if (isLoggingIn) return;
    if (isLoggedIn) {
      fetchMyCards();
    } else {
      setMyCards(EMPTY_ARR);
    }
  }, [isLoggedIn, isLoggingIn, fetchMyCards]);

  useEffect(() => {
    if (!allCards || !myCards) return;
    const allCardAggregated: AnyCardModel[] = uniqBy(
      [...allCards, ...myCards],
      'id'
    );

    setCards(allCardAggregated, cardItemKeyResolver);
    if (!hasLoadedOnceRef.current) {
      setInitialPlatformCardOrdering(allCards);
    }
    setInitialUserCardOrdering(myCards);

    handlePopulateSearchDb(insertBatch, allCardAggregated);

    setIsLoading(false);
    hasLoadedOnceRef.current = true;
  }, [
    allCards,
    myCards,
    setCards,
    insertBatch,
    setIsLoading,
    setInitialPlatformCardOrdering,
    setInitialUserCardOrdering,
  ]);

  return {
    getCardIdsByText,
    upsertCardToTextSearchDb,
    isLoading,
    isFetchingMyCards: requestMyCardsState === AsyncRequestState.Loading,
  };
};

export default useCardsManagement;
