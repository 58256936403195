import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0006: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 96 108"
		height={108}
		width={96}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M45.14 14.59c7.63-.66 16.81.55 26.64 6.12 0 0 14.56 6.3 11.48 26.46 0 0-.62 5.64 1.16 8.27l9.64 11.34s1.28 3.37-2.57 3.69l-5.88 1.21s-2.46 4.79-2.7 8.48c-.25 3.69.61 8.24.61 8.24s2.09 5.53-1.19 7.96c-.64.16-3.05 2.89-20.22-.8 0 0-1.97 1.8-3.02 10.78M22.02 106.48c.76-4.45 2.17-12.7 1.74-19.74 0 0-2.57-11.55-7.7-18.93 0 0-5.86-9.99-5.34-21.86M82.17 80.41l-7.37.12" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M29.07 28.65 1.74 6.41l16.8-3.46 6.94-1.43 22.15 34.71-11.53 2.35L53.61 67 5.9 30.51l10.7-2.24" />
    </g>
  </CardIcon>
);

export default memo(Sn0006);
