import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0045: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 116 110"
		height={110}
		width={116}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M50.67 40.01H38.64l19.45-29.48 19.43 29.48H59.14" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M58.1 10.57V1.48h11.01v5.06h9.96" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M42.36 40.24v26.89M73.22 40.24v26.89" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M19.08 53.39V43.8M8.96 102.15V43.8M29.18 94.86V43.8M9.14 53.5h19.87M97.09 53.39V43.8M107.2 108.54V43.8M86.98 94.86V43.8M107.03 53.5H87.15" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M1.42 108.9h113.16M30.14 73.76h7.98v7.59h7.99v-7.59h7.98v7.59h7.98v-7.59h7.99v7.59h7.98v-7.59h7.99" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M65.9 45.86 50.27 61.5M65.9 61.5 50.27 45.86" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0045);
