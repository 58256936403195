import { z } from 'zod';

import { ImageAssetModelSchema } from './ImageAssetModel';

// export const AnyAssetModelSchema = z.union([
//   ImageAssetModelSchema,
//   VideoAssetModelSchema,
// ]);

// Can't union with only one
export const AnyAssetModelSchema = ImageAssetModelSchema;

export type AnyAssetModel = z.infer<typeof AnyAssetModelSchema>;
