/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EMPTY_OBJ, NOOP, removeEmptyFromRecord } from '@kitted/shared-utils';

import {
  AnalyticsEvents,
  useAnalyticsApi,
} from '../../../../../contexts/AnalyticsContext';
import { FieldValue, ResetForm } from '../../../../../contexts/FormContext';
import { toastDurations } from '../../../../../contexts/ToastContext/constants';
import useToastApi from '../../../../../contexts/ToastContext/hooks/useToastApi';
import useUserApi from '../../../../../contexts/UserContext/hooks/useUserApi';
import { useSubscribeToWebsocketModelUpdateServerMessage } from '../../../../../contexts/WebsocketContext';
import { submissionWebsocketMessages } from '../../constants';
import {
  handleWebsocketItemUpdated,
  transformCreateFormDataToRegisterPayload,
} from '../../logic';
import { AuthRegisterModalProps, UserCreateFormData } from '../../types';

const useCreateUserModalContent = (
  onClose: AuthRegisterModalProps['onClose'],
  meta: AuthRegisterModalProps['meta'] = EMPTY_OBJ
) => {
  const { orderId, inviteCode, onComplete = NOOP } = meta;
  const [submissionProgress, setSubmissionProgress] = useState<number>(0);
  const resolveRef = useRef<() => void>();
  const rejectRef = useRef<(e: unknown) => void>();
  const { registerUser } = useUserApi();
  const { registerToast } = useToastApi();
  const { trackEvent } = useAnalyticsApi();

  useEffect(() => {
    if (submissionProgress >= submissionWebsocketMessages.length) {
      resolveRef.current!();
      setSubmissionProgress(0);
    }
  }, [submissionProgress]);

  useSubscribeToWebsocketModelUpdateServerMessage(
    handleWebsocketItemUpdated(setSubmissionProgress)
  );

  const onSubmit = useCallback(
    async (formData: UserCreateFormData) =>
      new Promise<void>((resolve, reject) => {
        resolveRef.current = resolve;
        rejectRef.current = reject;
        const registerUserPayload =
          transformCreateFormDataToRegisterPayload(formData);

        async function performRegister() {
          try {
            await registerUser(registerUserPayload);
          } catch (e) {
            rejectRef.current!(e);
            setSubmissionProgress(0);
          }
        }

        performRegister();
      }),
    [registerUser]
  );

  const onSubmitSuccess = useCallback(
    (resetForm: ResetForm) => {
      trackEvent(AnalyticsEvents.SignUp);
      registerToast('createUserSuccess', {
        title: 'Account created',
        body: 'Please check your email to verify.',
        theme: 'success',
        duration: toastDurations.success,
      });
      onClose();
      resetForm();
      onComplete();
    },
    [onClose, trackEvent, registerToast, onComplete]
  );

  const initialFormData = useMemo(() => {
    const formData = removeEmptyFromRecord<FieldValue>({
      password: '',
      orderId,
      referralCode: inviteCode,
    });
    return formData;
  }, [orderId, inviteCode]);

  return {
    onSubmit,
    onSubmitSuccess,
    progressPercentage: submissionProgress / submissionWebsocketMessages.length,
    initialFormData,
  };
};

export default useCreateUserModalContent;
