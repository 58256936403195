import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    input: {
      width: '100%',
      boxSizing: 'border-box',
      background: 'none',
      border: 'none',
      padding: [14, 24],
      color: 'inherit',
      appearance: 'none',
      resize: 'none',
      textShadow: 'inherit',
      '&:focus': {
        outline: 'none',
      },
    },
    inputResize: {
      resize: 'vertical',
      maxHeight: 500,
    },
  },
  { name: 'inputTextarea' }
);
