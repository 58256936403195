import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    container: {
      display: 'flex',
      gap: 2,
    },
    containerStacked: {
      flexDirection: 'column',
      gap: 4,
      '& > svg:first-child': {
        marginBottom: 12,
      },
    },
  },
  { name: 'CCLicence' }
);
