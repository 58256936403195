import { useMemo } from 'react';

import { getCardFooterStripeStyle } from '../../logic';

const useCardFooterStripe = (background: string) => {
  const style = useMemo(
    () => getCardFooterStripeStyle(background),
    [background]
  );

  return {
    style,
  };
};

export default useCardFooterStripe;
