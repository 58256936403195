import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    button: {
      margin: [20, 'auto', 10],
    },
  },
  { name: 'loginButton' }
);
