import { UserLoginFormData } from './types';

export const transformLoginFormDataToLoginPayload = ({
  email,
  password,
  'g-recaptcha-response': recaptchaResponse,
}: UserLoginFormData) => ({
  'g-recaptcha-response': recaptchaResponse,
  email,
  password,
});
