import { useMemo } from 'react';

import { ProgressCircleProps } from '../../types';

const useProgressCircle = (
  size: ProgressCircleProps['size'],
  thickness: ProgressCircleProps['thickness'],
  progress: ProgressCircleProps['progress']
) => {
  const radius = useMemo(() => size / 2, [size]);
  const center = useMemo(() => radius + thickness, [radius, thickness]);

  const { dashArrayStart, dashArrayEnd } = useMemo(() => {
    const circum = Math.floor(Math.PI * radius * 2);
    const start = Math.floor(circum * (progress / 100));
    return {
      dashArrayStart: start,
      dashArrayEnd: circum,
    };
  }, [radius, progress]);

  return {
    center,
    radius,
    dashArrayStart,
    dashArrayEnd,
  };
};

export default useProgressCircle;
