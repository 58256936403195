import { useContext } from 'react';

import { ModalDataContext } from '../..';

const useModalData = () => {
  const { modals } = useContext(ModalDataContext);

  return {
    modals,
  };
};

export default useModalData;
