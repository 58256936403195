import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      position: 'relative',
    },
    dot: {
      borderRadius: '50%',
      height: 5,
      width: 5,
      zIndex: 10,
      position: 'absolute',
      left: 'calc(50% + var(--statusDotOnXOffset, 0%))',
      top: 'calc(50% + var(--statusDotOnYOffset, 0%))',
      translate: '-50% -50%',
    },
    'align-start': {},
    'align-center': {},
    'align-end': {},
    'side-left': {
      '--statusDotOnXOffset': '-50%',
      '&$align-start': {
        '--statusDotOnYOffset': '-50%',
      },
      '&$align-center': {
        '--statusDotOnYOffset': '0%',
      },
      '&$align-end': {
        '--statusDotOnYOffset': '50%',
      },
    },
    'side-right': {
      '--statusDotOnXOffset': '50%',
      '&$align-start': {
        '--statusDotOnYOffset': '-50%',
      },
      '&$align-center': {
        '--statusDotOnYOffset': '0%',
      },
      '&$align-end': {
        '--statusDotOnYOffset': '50%',
      },
    },
    'status-warn': {
      backgroundColor: styling.colors.theme.action,
    },
    'status-error': {
      backgroundColor: styling.colors.theme.error,
    },
    'status-success': {
      backgroundColor: styling.colors.theme.success,
    },
    'status-info': {
      backgroundColor: styling.colors.theme.info,
    },
    'on-circle': {
      '&$side-left': {
        '&$align-start': {
          '--statusDotOnXOffset': 'calc(-50% * sin(45deg))',
          '--statusDotOnYOffset': 'calc(-50% * cos(45deg))',
        },
        '&$align-center': {
          '--statusDotOnXOffset': '-50%',
          '--statusDotOnYOffset': '0%',
        },
        '&$align-end': {
          '--statusDotOnXOffset': 'calc(-50% * sin(45deg))',
          '--statusDotOnYOffset': 'calc(50% * cos(45deg))',
        },
      },
      '&$side-right': {
        '&$align-start': {
          '--statusDotOnXOffset': 'calc(50% * sin(45deg))',
          '--statusDotOnYOffset': 'calc(-50% * cos(45deg))',
        },
        '&$align-center': {
          '--statusDotOnXOffset': '50%',
          '--statusDotOnYOffset': '0%',
        },
        '&$align-end': {
          '--statusDotOnXOffset': 'calc(50% * sin(45deg))',
          '--statusDotOnYOffset': 'calc(50% * cos(45deg))',
        },
      },
    },
    'on-rectangle': {},
  },
  { name: 'statusDot' }
);
