import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0033: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 108 110"
		height={110}
		width={108}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M50.24 108.25V11.09M98.85 59.5H9.22M46.45 10.61s-45.92 42.11 0 97.51M54.04 10.61s45.92 42.11 0 97.51" /><path vectorEffect="non-scaling-stroke" d="M86.79 90.32c-13.26-7.97-36.94-16.74-65.89-3.64M87.18 28.49c-13.34 8.13-37.64 17.33-67.38 3.38" /><circle vectorEffect="non-scaling-stroke" cx="50.21" cy="59.47" r="49.05" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M79.02 4.99c9.58 2.34 22.44 12.27 23.38 24.55M90.71 1.48c6.78 1.64 14.03 9.13 16.13 15.78" />
    </g>
  </CardIcon>
);

export default memo(Fr0033);
