import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0004: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 114 114"
		height={114}
		width={114}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M57 1.29v111.42M112.71 57H1.29" /><circle vectorEffect="non-scaling-stroke" cx="83.51" cy="34.7" r=".5" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="71.8" cy="86.28" r=".5" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="16.62" cy="101.95" r=".5" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="91.88" cy="21.16" r=".5" stroke={color} /><circle vectorEffect="non-scaling-stroke" cx="40.93" cy="70" r=".5" stroke={color} /><circle vectorEffect="non-scaling-stroke" cx="86.41" cy="103.41" r=".5" stroke={color} /><circle vectorEffect="non-scaling-stroke" cx="25.5" cy="39.8" r=".5" stroke={color} />
    </g>
  </CardIcon>
);

export default memo(Ac0004);
