import { CatalogueItemModel } from '@kitted/catalogue-service-models';
import { Nullable } from '@kitted/platform-models';

import { AssetCatalogueSave } from '../../types';
import { CatalogueSelectorViewApi } from './types';

export type CatalogueSelectorFilters = 'All' | 'My';

export enum CatalogueItemType {
  PlatformImageResource = 'PlatformImageResource',
  AuthorImageAsset = 'AuthorImageAsset',
  GalleryAsset = 'GalleryAsset',
}

export type CatalogueSelectorViewState = {
  filter: CatalogueSelectorFilters;
  searchText: string;

  galleryItems: CatalogueItemModel[];
  platformImages: CatalogueItemModel[];
  myImages: CatalogueItemModel[];

  selectedCatalogueItem: Nullable<CatalogueItemModel>;
  uploadedBlobGuid: Nullable<string>;

  isLoading: boolean;
};

// Function to set the current state of the catalogue selector view
export type SetCatalogueSelectorViewState = (
  update:
    | CatalogueSelectorViewState
    | ((prevState: CatalogueSelectorViewState) => CatalogueSelectorViewState)
) => void;

export const getInitalCatalogueSelectorViewState =
  (): CatalogueSelectorViewState => ({
    filter: 'All',
    searchText: '',

    galleryItems: [],
    platformImages: [],
    myImages: [],

    selectedCatalogueItem: null,
    uploadedBlobGuid: null,

    isLoading: false,
  });

export const getSetFilter =
  (
    stateSetter: SetCatalogueSelectorViewState
  ): CatalogueSelectorViewApi['setFilter'] =>
  (filter: CatalogueSelectorFilters) => {
    stateSetter((prevState) => ({ ...prevState, filter }));
  };

export const getSetSearchText =
  (
    stateSetter: SetCatalogueSelectorViewState
  ): CatalogueSelectorViewApi['setSearchText'] =>
  (searchText: string) => {
    stateSetter((prevState) => ({ ...prevState, searchText }));
  };

export const getSetGalleryItems =
  (
    stateSetter: SetCatalogueSelectorViewState
  ): CatalogueSelectorViewApi['setGalleryItems'] =>
  (galleryItems: CatalogueItemModel[]) => {
    stateSetter((prevState) => ({ ...prevState, galleryItems }));
  };

export const getSetPlatformImages =
  (
    stateSetter: SetCatalogueSelectorViewState
  ): CatalogueSelectorViewApi['setPlatformImages'] =>
  (platformImages: CatalogueItemModel[]) => {
    stateSetter((prevState) => ({ ...prevState, platformImages }));
  };

export const getSetMyImages =
  (
    stateSetter: SetCatalogueSelectorViewState
  ): CatalogueSelectorViewApi['setMyImages'] =>
  (myImages: CatalogueItemModel[]) => {
    stateSetter((prevState) => ({ ...prevState, myImages }));
  };

export const getSetSelectedCatalogueItem =
  (
    stateSetter: SetCatalogueSelectorViewState
  ): CatalogueSelectorViewApi['setSelectedCatalogueItem'] =>
  (selectedCatalogueItem: Nullable<CatalogueItemModel>) => {
    stateSetter((prevState) => ({
      ...prevState,
      selectedCatalogueItem,
      uploadedBlobGuid: null,
    }));
  };

export const getSetIsLoading =
  (
    stateSetter: SetCatalogueSelectorViewState
  ): CatalogueSelectorViewApi['setIsLoading'] =>
  (isLoading: boolean) => {
    stateSetter((prevState) => ({ ...prevState, isLoading }));
  };

export const getSetUploadedBlobGuid =
  (
    stateSetter: SetCatalogueSelectorViewState
  ): CatalogueSelectorViewApi['setUploadedBlobGuid'] =>
  (uploadedBlobGuid: Nullable<string>) => {
    stateSetter((prevState) => ({
      ...prevState,
      uploadedBlobGuid,
      selectedCatalogueItem: null,
    }));
  };

export const getFilteredItems = (
  filter: CatalogueSelectorFilters,
  platformImages: CatalogueItemModel[],
  myImages: CatalogueItemModel[]
) => {
  if (filter === 'All') {
    return [...myImages, ...platformImages];
  }

  return myImages;
};

export function isCatalogueItemSelected(
  item: CatalogueItemModel,
  selectedCatalogueItem: Nullable<CatalogueItemModel>
): boolean {
  return selectedCatalogueItem === item;
}

export function canSubmit(state: CatalogueSelectorViewState): boolean {
  return !!state.uploadedBlobGuid || !!state.selectedCatalogueItem?.id;
}

export function getCatalogueResult(
  state: CatalogueSelectorViewState
): AssetCatalogueSave {
  if (state.uploadedBlobGuid) {
    return {
      blobId: state.uploadedBlobGuid,
      isUploadedBlob: true,
    };
  }

  return {
    isUploadedBlob: false,
    assetId: state.selectedCatalogueItem?.assetId || '',
  };
}
