import { useCallback, useEffect, useRef } from 'react';

import useAuthTokensApi from '../../../../contexts/AuthTokensContext/hooks/useAuthTokensApi';
import useAuthTokensData from '../../../../contexts/AuthTokensContext/hooks/useAuthTokensData';
import { timerFrequencies } from '../../../../contexts/TimerContext/constants';
import useTimer from '../../../../contexts/TimerContext/hooks/useTimer';
import { TimerRemoveCallback } from '../../../../contexts/TimerContext/types';
import { AsyncRequestState } from '../../../../hooks/useAsyncRequest/types';
import { executeTokenRefresh } from '../../logic';

const useAuthTokensRefreshTimer = () => {
  const { getTokens, requestTokenRefresh } = useAuthTokensApi();
  const { tokens, tokenRefreshState } = useAuthTokensData();
  const { addTimerCallback } = useTimer();
  const refreshTimerRef = useRef<TimerRemoveCallback>();

  const unbindFromTimer = useCallback(() => {
    refreshTimerRef.current?.();
  }, []);

  const bindToTimer = useCallback(() => {
    const currentTokens = getTokens();
    // when we have a tokens
    if (currentTokens) {
      // bind to the timer the refreshToken function
      refreshTimerRef.current = addTimerCallback(
        timerFrequencies.oneMinute,
        executeTokenRefresh(getTokens, requestTokenRefresh)
      );
    }
    return unbindFromTimer;
  }, [requestTokenRefresh, addTimerCallback, getTokens, unbindFromTimer]);

  useEffect(() => bindToTimer(), [bindToTimer, tokens]);

  useEffect(() => {
    if (tokenRefreshState === AsyncRequestState.Loading) {
      unbindFromTimer();
    }
  }, [unbindFromTimer, tokenRefreshState]);
};

export default useAuthTokensRefreshTimer;
