import { useMemo } from 'react';

import { useAssetCatalogueData } from '../../../contexts/AssetCatalogueProvider/hooks';
import useSearchCatalogueItems from '../../../hooks/useSearchCatalogueItems';
import { getSearchItemsByFilter } from './logic';

const useSearchItems = () => {
  const { myImages, platformImages, searchText, filter } =
    useAssetCatalogueData();

  const allSearchItems = useMemo(
    () => getSearchItemsByFilter(filter, myImages, platformImages),
    [myImages, platformImages, filter]
  );

  const filteredItems = useSearchCatalogueItems(allSearchItems, searchText);

  return filteredItems;
};

export default useSearchItems;
