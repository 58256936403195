import { memo } from 'react';
import { RouterProvider } from 'react-router-dom';

import AppProviders from './components/AppProviders';
import router from './constants/routes/router';
import { WebSocketAuthSync } from './contexts/WebsocketContext';

const App = () => (
  <AppProviders>
    <WebSocketAuthSync>
      <RouterProvider router={router} />
    </WebSocketAuthSync>
  </AppProviders>
);

export default memo(App);
