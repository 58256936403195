import {
  discoverMagicDecks,
  discoverMagicFlows,
  discoverMagicKits,
  discoverMagicSubjects,
  discoverMagicTools,
  discoveryMagicAbout,
  discoveryMagicInstructionsArticle,
  externalRoutes,
  routes,
} from '@kitted/website-constants';
import {
  // BrowserGear,
  CardInfo,
  Heart,
  Home,
  KittedBrandmark,
  MagnifyHash,
  Scales,
  ShoppingBag,
  Spanner,
  SpeechBubble,
} from '@kitted/website-icons';

import { constructMenuItem } from './helpers';
import { MenuItem } from './types';

const mainMenu: MenuItem[] = [
  constructMenuItem(routes.home, Home),
  constructMenuItem(discoveryMagicInstructionsArticle, Spanner),
  constructMenuItem(routes.discoveryAll, KittedBrandmark),
  constructMenuItem(discoverMagicSubjects, MagnifyHash),
  constructMenuItem(discoveryMagicAbout, CardInfo),
  constructMenuItem(externalRoutes.shop, ShoppingBag),
];

const mobileMainMenu: MenuItem[] = [
  constructMenuItem(routes.home, Home),
  constructMenuItem(externalRoutes.shop, ShoppingBag),
  constructMenuItem(routes.discoveryAll, KittedBrandmark),
  // constructMenuItem(routes.dashboard, BrowserGear),
];

const mobileSecondaryMenu: MenuItem[] = [
  constructMenuItem(routes.legals, Scales, true),
  constructMenuItem(discoveryMagicAbout, CardInfo),
  constructMenuItem(discoveryMagicInstructionsArticle, Spanner),
  constructMenuItem(routes.contact, SpeechBubble),
];

const accountMenu: MenuItem[] = [
  // constructMenuItem(routes.dashboard, BrowserGear),
  constructMenuItem(routes.discoverySaved, Heart),
];

const footerMenuOne: MenuItem[] = [
  constructMenuItem(routes.home),
  constructMenuItem(discoveryMagicAbout),
  constructMenuItem(externalRoutes.shop),
  constructMenuItem(routes.contact),
  constructMenuItem(routes.legals),
  constructMenuItem(routes.mentalModelMondays),
];

const footerMenuTwo: MenuItem[] = [
  constructMenuItem(routes.discoveryAll),
  constructMenuItem(discoverMagicTools),
  constructMenuItem(discoverMagicKits),
  constructMenuItem(discoverMagicFlows),
  constructMenuItem(discoverMagicSubjects),
  constructMenuItem(discoverMagicDecks),
];

const footerMenuSocials: MenuItem[] = [
  constructMenuItem(externalRoutes.linkedIn),
  constructMenuItem(externalRoutes.facebook),
  // constructMenuItem(externalRoutes.instagram),
  constructMenuItem(externalRoutes.twitter),
];

export default {
  mainMenu,
  mobileMainMenu,
  mobileSecondaryMenu,
  accountMenu,
  footerMenuOne,
  footerMenuTwo,
  footerMenuSocials,
};
