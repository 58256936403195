import { FavoriteModel } from '@kitted/account-service-models';
import { CardModelId } from '@kitted/kitted-models';

import { generateFalseFavoriteForId } from '../../../hooks/favorites/logic';

export const mergeNonFavoritesWithFavorites = (
  allCardIds: CardModelId[],
  favourites: FavoriteModel[]
) => {
  const currentKnownIds = favourites.map((favourite) => favourite.id);
  const unknownFavourites: FavoriteModel[] = allCardIds
    .filter((id) => !currentKnownIds.includes(id))
    .map((id) => generateFalseFavoriteForId(id));

  return favourites.concat(unknownFavourites);
};

export const favoriteItemKeyResolver = (favorite: FavoriteModel): string =>
  favorite.id;
