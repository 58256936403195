import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const VideoPlay: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 22"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="m11.24 7.5 6.05 3.5-6.05 3.5z" /><rect vectorEffect="non-scaling-stroke" width="25.24" height="18.54" x="1.38" y="1.73" stroke={color} rx="1.4" ry="1.4" />
    </g>
  </WebsiteIcon>
);

export default memo(VideoPlay);
