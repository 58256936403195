import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Landscape: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 22"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="m1.95 17.31 8.16-8.72 8.25 8.7" /><rect vectorEffect="non-scaling-stroke" width="24.24" height="18.93" x="1.88" y="1.54" stroke={color} rx=".71" ry=".71" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m17.75 13.95 2.79-2.96 5.58 5.95M19.71 6.62a2.01 2.01 0 1 1-4.02 0 2.01 2.01 0 0 1 4.02 0" />
    </g>
  </WebsiteIcon>
);

export default memo(Landscape);
