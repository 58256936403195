import { memo } from 'react';
import { styling } from '@kitted/design-system';

import { NDProps } from './types';

const ND = ({
  className,
  height = 26,
  width = 26,
  fill = styling.colors.theme.primary600,
}: NDProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 26"
    className={className}
    height={height}
    width={width}
  >
    <g fill={fill}>
      <path d="m12.98,1.07c-3.31,0-6.1,1.15-8.39,3.46-2.35,2.39-3.53,5.21-3.53,8.47s1.17,6.07,3.53,8.41c2.35,2.35,5.15,3.52,8.39,3.52s6.13-1.18,8.54-3.55c2.27-2.25,3.41-5.04,3.41-8.38s-1.16-6.17-3.47-8.47c-2.31-2.31-5.14-3.46-8.48-3.46Zm.03,2.15c2.71,0,5.01.96,6.9,2.87,1.91,1.89,2.87,4.2,2.87,6.92s-.93,5.02-2.81,6.83c-1.97,1.95-4.29,2.92-6.96,2.92s-4.97-.96-6.9-2.89c-1.93-1.93-2.9-4.22-2.9-6.86s.98-4.95,2.93-6.92c1.87-1.91,4.16-2.87,6.87-2.87Z" />
      <path d="m17.51,10.17h-8.67v2.05h8.67v-2.05Zm0,3.84h-8.67v2.05h8.67v-2.05Z" />
    </g>
  </svg>
);

export default memo(ND);
