import { MouseEvent, useCallback, useMemo, useState } from 'react';

import useModalApi from '../../../../contexts/ModalContext/hooks/useModalApi';
import {
  getModalContentComponent,
  handleOnClose,
  handleOnExited,
} from '../../logic';
import { ModalProps } from '../../types';

const useModal = (props: ModalProps) => {
  const [isIn, setIsIn] = useState<boolean>(true);
  const { deregisterModal } = useModalApi();

  const style = useMemo(
    () =>
      ({
        '--modalIndex': props.index,
      }) as React.CSSProperties,
    [props.index]
  );

  const onClose = useCallback(() => handleOnClose(setIsIn), [setIsIn]);

  const onExited = useCallback(
    () => handleOnExited(props.id, deregisterModal),
    [props.id, deregisterModal]
  );

  const preventClose = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  const { as: ModalContentComponent, ...modalContentProps } = useMemo(
    () => getModalContentComponent(props, onClose, preventClose),
    [props, onClose, preventClose]
  );

  return {
    onClose,
    onExited,
    ModalContentComponent,
    modalContentProps,
    isIn,
    style,
  };
};

export default useModal;
