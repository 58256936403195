import { memo } from 'react';

import MobileMenuItem from '../../../routes/_root/FixedMobileMenu/MobileMenuItems/MobileMenuItem';
import useMobileMenuItemsStyles from '../../../routes/_root/FixedMobileMenu/MobileMenuItems/styles';
import useEditDrawerSpaceMobileMenu from './hooks/useEditDrawerSpaceMobileMenu';
import useStyles from './styles';

const EditDrawerSpaceMobileMenu = () => {
  const styles = useStyles();
  const mobileMenuItemsStyles = useMobileMenuItemsStyles();
  const { items, selectedDrawerItemSlug } = useEditDrawerSpaceMobileMenu();

  if (items?.length <= 1) return null;

  return (
    <nav className={styles.container}>
      <ul className={mobileMenuItemsStyles.list}>
        {items.map(
          ({ itemComponent: ItemComponent, icon, onClick, title, slug }) => {
            const Component = ItemComponent || MobileMenuItem;
            return (
              <li key={slug} className={mobileMenuItemsStyles.item}>
                <Component
                  icon={icon}
                  onClick={onClick}
                  title={title}
                  isSelected={slug === selectedDrawerItemSlug}
                />
              </li>
            );
          }
        )}
      </ul>
    </nav>
  );
};

export default memo(EditDrawerSpaceMobileMenu);
