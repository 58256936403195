import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0028: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 120 110"
		height={110}
		width={120}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><g stroke={color}><path vectorEffect="non-scaling-stroke" d="M113.52 109.08h5.42M1.06 109.08h105.28" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M46.74 39.28h22.84M46.74 50.92h22.84M46.74 62.55h22.84M46.74 85.82h22.84M46.74 97.46h22.84M46.74 74.18h22.84" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M58.16.78v9.1M38.54 108.84V28.03h39.24v80.81M45.55 27.75v-9.27h25.21v9.27M51.01 10.18h14.3v8.09M8.84 101.94V60.85h21.75M28.59 85.16v7.63M19.45 85.16v7.63M28.59 69.95v7.62M19.45 69.95v7.62M28.59 100.37v8.39M19.45 100.37v8.39M98.16 108.84V76.21H86.2M88.19 85.16v7.63M88.19 100.37v8.39" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M8.05 18.68h7.87c0-2.96 2.4-5.36 5.35-5.36s5.35 2.4 5.35 5.36h4.76M107.52 49.24h-4.24c0-2.42-1.96-4.37-4.37-4.37s-4.38 1.96-4.38 4.37h-7.95M105.53 56.51h8.74M93.32 58.29h3.65" />
    </g>
  </CardIcon>
);

export default memo(Fr0028);
