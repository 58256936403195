import { FormLayoutSchema } from '../../../../../../../components/FormFields/types';
import {
  ACCEPTED_IMAGE_FILES,
  MAX_FILE_SIZE,
} from '../../../../../../../constants/inputs';
import VideoScrapeButton from './VideoScrapeButton';

export const layoutSchema: FormLayoutSchema = {
  fields: [
    {
      key: 'videoUrl',
      label: 'Link to resource',
      meta: {
        autoFocus: true,
        helperText: 'YouTube or Vimeo links only',
      },
    },
    {
      key: 'videoScrape',
      label: null,
      meta: {
        component: VideoScrapeButton,
      },
    },
    {
      key: 'keyImage',
      label: 'Key image',
      meta: {
        acceptedFileFormats: ACCEPTED_IMAGE_FILES,
        maxFileSize: MAX_FILE_SIZE,
      },
    },
    {
      key: 'title',
      label: 'Title',
    },
    {
      key: 'description',
      label: 'Short description',
    },
  ],
};
