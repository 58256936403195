import { Dispatch, SetStateAction } from 'react';

import { InputComponentProps, InputSelectOption } from '../types';

export const getSanitizedItem = (
  options: InputComponentProps['options'],
  value: InputComponentProps['value']
) => options?.find((option) => option.value === value);

export const handleOnButtonClick = (
  setIsOpen: Dispatch<SetStateAction<boolean>>
) => {
  setIsOpen(true);
};

export const handleOnChange = (
  onChange: InputComponentProps['onChange'],
  option: InputSelectOption,
  onClose: () => void
) => {
  onChange(option?.value);
  onClose();
};

export const handleOnClose = (setIsOpen: Dispatch<SetStateAction<boolean>>) => {
  setIsOpen(false);
};
