import { memo } from 'react';
import clsx from 'clsx';

import Lottie from '../../Lottie';
import useSpinner from './hooks/useSpinner';
import useStyles from './styles';
import { SpinnerProps } from './types';

const Spinner = ({
  className,
  size = 200,
  theme = 'secondary',
}: SpinnerProps) => {
  const styles = useStyles();
  const { animationData } = useSpinner(theme);

  return (
    <Lottie
      className={clsx(className, styles.container)}
      animationData={animationData}
      height={size}
      width={size}
    />
  );
};

export default memo(Spinner);
