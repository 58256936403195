import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0024: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 118 86"
		height={86}
		width={118}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="m32.93 26.98-7.77-5.29" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M14.18 14.38 12.57 1.29M1.47 17.9l12.71-3.52" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M81.3 69.06 66.37 83.92 34.81 52.4c-7.56-7.56-7.69-19.82-.27-27.23 7.41-7.41 19.67-7.29 27.23.27l4.6 4.6 4.6-4.6c7.56-7.56 19.81-7.68 27.23-.27 7.42 7.42 7.29 19.67-.27 27.23l-6.27 6.3" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="m100.98 73.94-29.74-21M107.34 64.97l9.19 19.74-21.68-1.88z" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M25.16 21.69 23.55 8.6M12.45 25.2l12.71-3.51" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M77.82 48.89c-2.14.17-4.29 1.16-5.94 2.92-2.14 2.29-2.92 5.33-2.31 8.01" />
    </g>
  </CardIcon>
);

export default memo(Fr0024);
