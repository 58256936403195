import { CatalogueItemModel } from '@kitted/catalogue-service-models';

export const getSearchItemsByFilter = (
  filter: string,
  myImages: CatalogueItemModel[],
  platformImages: CatalogueItemModel[]
) => {
  if (filter === 'All') {
    return [...myImages, ...platformImages];
  }

  return myImages;
};
