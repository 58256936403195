/* eslint-disable @typescript-eslint/no-explicit-any */

export const handleClickOutside = (
  e: Event,
  ref: any,
  enabled: boolean,
  callback: () => void
) => {
  if (enabled) {
    if (ref && !ref.contains(e.target)) {
      e.preventDefault();
      e.stopPropagation();
      callback();
    }
  }
};

export const handleOnMount = (onClickOutside: (e: Event) => void) => {
  window.document.addEventListener('click', onClickOutside, true);
  return () => {
    window.document.removeEventListener('click', onClickOutside, true);
  };
};
