import { mustMatchRegex, ValidationItemMustMatchRegex } from './mustMatchRegex';

const getAtLeastXNumbersRegex = (numberCount: number) =>
  new RegExp(`(?=.*\\d{${numberCount}})`);

export const hasAtLeastXNumbers = (
  isValueRequired: boolean,
  numberCount: number
): ValidationItemMustMatchRegex =>
  mustMatchRegex(
    isValueRequired,
    getAtLeastXNumbersRegex(numberCount),
    `This requires at least ${numberCount} number(s)`
  );
