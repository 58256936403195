/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import { ExternalLink } from '@kitted/shared-components';

import useExternalLinkWithCrossDomainDistinctId from './hooks/useExternalLinkWithCrossDomainDistinctId';
import { ExternalLinkWithCrossDomainDistinctIdProps } from './types';

const ExternalLinkWithCrossDomainDistinctId = ({
  children,
  href,
  ...props
}: ExternalLinkWithCrossDomainDistinctIdProps) => {
  const { hrefWithCrossDomainDistinctId } =
    useExternalLinkWithCrossDomainDistinctId(href);
  return (
    <ExternalLink {...props} href={hrefWithCrossDomainDistinctId}>
      {children}
    </ExternalLink>
  );
};

export default memo(ExternalLinkWithCrossDomainDistinctId);
