import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const SpeechBubble: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M19.05 20.05h5.37a2.2 2.2 0 0 0 2.2-2.2V4.08a2.2 2.2 0 0 0-2.2-2.2H3.58a2.2 2.2 0 0 0-2.2 2.2v13.77c0 1.22.99 2.2 2.2 2.2h11.33l-3.76 6.07M6.28 8.21h15.44M6.28 13.71h11.01" />
    </g>
  </WebsiteIcon>
);

export default memo(SpeechBubble);
