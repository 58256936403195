import { FormLayoutSchema } from '../../../../../../../components/FormFields/types';
import {
  ACCEPTED_IMAGE_FILES,
  MAX_FILE_SIZE,
} from '../../../../../../../constants/inputs';

export const layoutSchema: FormLayoutSchema = {
  fields: [
    {
      key: 'keyImage',
      label: 'Key image',
      meta: {
        autoFocus: true,
        acceptedFileFormats: ACCEPTED_IMAGE_FILES,
        maxFileSize: MAX_FILE_SIZE,
      },
    },
    {
      key: 'title',
      label: 'Title',
    },
  ],
};
