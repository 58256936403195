import { memo } from 'react';
import clsx from 'clsx';
import { styling } from '@kitted/design-system';
import { CardType } from '@kitted/kitted-models';

import CardIcon from '../Card/CardIcon';
import Image from '../Image';
import KittedBrandingFull from '../KittedLogos/KittedBrandingFull';
import Typography from '../Typography';
import useCardWide from './hooks/useCardWide';
import useStyles from './styles';
import { CardWideProps } from './types';

const CardWide = ({
  className,
  slug,
  subType,
  type,
  imageSrc,
  body,
  title,
  authorName,
}: CardWideProps) => {
  const { noOrphansBody, cardTypeTitle, SlugTypeIcon } = useCardWide({
    authorName,
    type,
    subType,
    body,
    title,
    slug,
  });
  const styles = useStyles();

  return (
    <div
      className={clsx(
        className,
        styles.container,
        styles[`cardType-${type}`],
        subType && styles[`cardSubType-${subType}`]
      )}
    >
      <div className={styles.body}>
        {SlugTypeIcon && (
          <SlugTypeIcon className={styles.icon} size="inherit" />
        )}
        {!SlugTypeIcon && (
          <CardIcon slug={slug} spot="var(--cardWideSpotColor)" />
        )}
        <Typography as="h3" className={styles.title} variant="cardWideTitle">
          {cardTypeTitle}
        </Typography>
        <Typography as="span" maxLines={2} variant="cardWideBody">
          {noOrphansBody}
        </Typography>
      </div>
      <Image className={styles.image} src={imageSrc} loaderTheme="secondary" />
      <div className={styles.slug}>
        <Typography as="span" variant="cardWideSlug">
          kitted.app/{slug}
        </Typography>
      </div>
      <div className={styles.logo}>
        <KittedBrandingFull
          width={242}
          fill={styling.colors.theme.secondary}
          useBrandColors
        />
      </div>
      {type === CardType.Flow && <div className={styles.flowStripe} />}
    </div>
  );
};

export default memo(CardWide);
