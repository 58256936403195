import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: styling.colors.theme.primary1000,
      minHeight: '100vh',
      [styling.breakpoints.context.tabletUpString]: {
        flexDirection: 'row',
        minHeight: `calc(100vh - ${styling.utils.px(
          styling.sizes.context.bottomBar.height
        )})`,
      },
    },
    left: {
      height: 393,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      flexGrow: 1,
      backgroundColor: styling.colors.theme.primary900,
      flexShrink: 0,
      padding: styling.sizes.theme.spacing.lg,
      [styling.breakpoints.context.tabletUpString]: {
        height: 'auto',
        minWidth: 0,
        flexShrink: 1,
        padding: styling.sizes.theme.spacing.lg,
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBlock: styling.sizes.theme.spacing.md,
      paddingInline: styling.sizes.theme.spacing.xs,
      [styling.breakpoints.context.tabletUpString]: {
        overflow: 'auto',
        minWidth: 524,
        width: '50%',
        paddingBlock: styling.sizes.theme.spacing.lg,
        paddingInline: styling.sizes.theme.spacing.lg,
      },
      [styling.breakpoints.context.desktopUpString]: {
        paddingBlock: styling.sizes.theme.spacing.xl,
        paddingInline: styling.sizes.theme.spacing.xl,
      },
      [styling.container.context.drawer.largeUpString]: {
        paddingBlock: styling.sizes.utils.spacingCustomFactor(20),
        paddingInline: styling.sizes.utils.spacingCustomFactor(20),
      },
    },
    contentInner: {
      margin: 'auto',
      width: '100%',
      maxWidth: styling.sizes.utils.gridColumns(
        3,
        styling.sizes.context.gutteringW
      ),
    },
  },
  { name: 'splitContent' }
);
