import { memo } from 'react';
import clsx from 'clsx';

import useProgressCircle from './hooks/useProgressCircle';
import useStyles from './styles';
import { ProgressCircleProps } from './types';

const ProgressCircle = ({
  className,
  progress,
  size,
  thickness,
  theme,
}: ProgressCircleProps) => {
  const { center, radius, dashArrayStart, dashArrayEnd } = useProgressCircle(
    size,
    thickness,
    progress
  );
  const styles = useStyles();

  return (
    <svg
      className={clsx(className, styles.svg, styles[`theme-${theme}`])}
      height={center * 2}
      width={center * 2}
    >
      <circle
        className={styles.backingLine}
        cx={center}
        cy={center}
        fill="none"
        r={radius}
        strokeWidth={thickness}
      />
      <circle
        className={styles.line}
        cx={center}
        cy={center}
        fill="none"
        r={radius}
        strokeLinecap={progress ? 'round' : undefined}
        strokeDasharray={`${dashArrayStart}, ${dashArrayEnd}`}
        strokeWidth={thickness}
      />
    </svg>
  );
};

export default memo(ProgressCircle);
