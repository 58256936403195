import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    loginWrapper: {
      textAlign: 'center',
      width: '100%',
    },
    login: {
      ...styling.utils.buttonReset(),
    },
    loginDisabled: {
      ...styling.utils.disabled(styling.colors.theme.primary100),
    },
  },
  { name: 'createAndLoginButton' }
);
