import { memo } from 'react';
import { ImageSkeleton } from '@kitted/shared-components';

import EllipsisDots from '../../../../Loaders/EllipsisDots';
import useStyles from './styles';

const LoadingProgress = () => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <ImageSkeleton className={styles.loadingPulse} />
      <div className={styles.text}>
        Creating account
        <EllipsisDots />
      </div>
    </div>
  );
};

export default memo(LoadingProgress);
