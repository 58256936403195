import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0028: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 92 108"
		height={108}
		width={92}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M58.54 106.6 30.29 31.54V17.48c0-8.84 7.23-16.07 16.07-16.07h0c8.84 0 16.09 7.23 16.09 16.07v14.06l28.74 75.06" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M62.44 31.54H14.3" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m34.2 106.6 28.24-75.06m-32.15 0L.81 106.6M55.34 50.42H23.07M47.17 72.13h-32.8M39.39 93.02H6.18M69.54 50.42h-5.89M77.96 72.13H56.02M85.82 93.02H64.76" />
    </g>
  </CardIcon>
);

export default memo(Ac0028);
