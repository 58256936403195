import { z } from 'zod';
import {
  ImageAssetModelSchema,
  KitSlugSchema,
  KittedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '@kitted/kitted-models';
import { DateISOSchema } from '@kitted/platform-models';

import {
  AnyCMSKitBlockLoadedModelSchema,
  AnyCMSKitBlockUnloadedModelSchema,
} from './AnyCMSKitBlockModel';

export const CMSKitLoadedModelSchema = KittedModelBaseSchema.merge(
  z.object({
    slug: KitSlugSchema,
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      true
    ).optional(),
    title: z.string(),
    description: z.string(),
    kitBlocks: makeKittedModelReferenceSchema(
      AnyCMSKitBlockLoadedModelSchema,
      true
    ).array(),

    excludeFromDiscovery: z.boolean(),
    live: z.boolean(),

    createdByAuthorId: z.string(),
    createdByUserId: z.string(),

    createdAt: DateISOSchema,
    updatedAt: DateISOSchema,
  })
);

export const CMSKitUnloadedModelSchema = CMSKitLoadedModelSchema.merge(
  z.object({
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      false
    ).optional(),
    kitBlocks: makeKittedModelReferenceSchema(
      AnyCMSKitBlockUnloadedModelSchema,
      false
    ).array(),
  })
);

export type CMSKitLoadedModel = z.infer<typeof CMSKitLoadedModelSchema>;
export type CMSKitUnloadedModel = z.infer<typeof CMSKitUnloadedModelSchema>;

export type CMSKitModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? CMSKitLoadedModel : CMSKitUnloadedModel;
