import { useCallback, useMemo, useState } from 'react';
import { NOOP } from '@kitted/shared-utils';

import { InputComponentProps, InputSelectOption } from '../../../types';
import {
  getSanitizedItem,
  handleOnButtonClick,
  handleOnChange,
  handleOnClose,
} from '../../logic';

const useInputSelect = (
  options: InputComponentProps['options'],
  value: InputComponentProps['value'],
  onChange: InputComponentProps['onChange'] = NOOP
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const selectedItem = useMemo(
    () => getSanitizedItem(options, value),
    [options, value]
  );

  const onButtonClick = useCallback(
    () => handleOnButtonClick(setIsOpen),
    [setIsOpen]
  );

  const onClose = useCallback(() => handleOnClose(setIsOpen), [setIsOpen]);

  const onSelectItem = useCallback(
    (option: InputSelectOption) => () =>
      handleOnChange(onChange, option, onClose),
    [onChange, onClose]
  );

  return {
    isOpen,
    onButtonClick,
    onSelectItem,
    selectedItem,
    onClose,
  };
};

export default useInputSelect;
