import { extractTypeFromSlug } from '@kitted/shared-utils';

import { cardSlugToIconMap } from './constants';

export const getCardIconByCode = (slug: string) => {
  if (cardSlugToIconMap[slug]) {
    return cardSlugToIconMap[slug];
  }
  const type = extractTypeFromSlug(slug);
  if (cardSlugToIconMap[type]) {
    return cardSlugToIconMap[type];
  }
  return null;
};
