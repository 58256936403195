/* eslint-disable react/jsx-props-no-spreading */
import { Fragment, memo } from 'react';
import clsx from 'clsx';

import FixedBottom from '../../FixedBottom';
import {
  DrawerSpaceDrawerSide,
  useDrawerSpaceContainerApi,
  useDrawerSpaceContainerData,
} from '../context/DrawerSpaceContainerContext';
import DrawerSpaceContentWrapper from '../DrawerSpaceContentWrapper';
import useDrawerSpaceContent from './hooks/useDrawerSpaceContent';
import DrawerSpaceSizerElement from './DrawerSpaceSizerElement';
import DrawerSpaceToggle from './DrawerSpaceToggle';
import useStyles from './styles';
import { DrawerSpaceContentProps } from './types';

const DrawerSpaceContent = ({
  isMainDisabled,
  mainDrawerContent: MainDrawerContent,
  mainDrawerHeader,
  children,
  footer: Footer,
  footerHeight,
}: DrawerSpaceContentProps) => {
  const {
    isMainOpen,
    isContextualOpen,
    isContextualDisabled,
    contextualDrawerContent: ContextualDrawerContent,
    contextualDrawerHeader,
  } = useDrawerSpaceContainerData();
  const { onClose } = useDrawerSpaceContainerApi();
  const { style, footerHeightValue, spaceProps, onSetRef } =
    useDrawerSpaceContent(footerHeight, !!Footer);
  const styles = useStyles();

  return (
    <>
      {!(isMainDisabled || isContextualDisabled) && (
        <FixedBottom
          className={clsx(
            styles.drawerUnderlay,
            (isMainOpen || isContextualOpen) && styles.drawerUnderlayOpen
          )}
          offset={footerHeightValue}
          style={style}
          cssVariableName="drawerSpaceFooterHeight"
        >
          <div className={styles.drawerUnderlayInner} onClick={onClose} />
        </FixedBottom>
      )}
      {!isMainDisabled && (
        <FixedBottom
          className={clsx(
            styles.drawerContainer,
            isMainOpen && styles.drawerContainerOpen
          )}
          offset={footerHeightValue}
          style={style}
          cssVariableName="drawerSpaceFooterHeight"
        >
          <DrawerSpaceToggle
            side={DrawerSpaceDrawerSide.Main}
            drawerHeader={mainDrawerHeader}
          />
          <MainDrawerContent />
        </FixedBottom>
      )}
      {!isContextualDisabled && (
        <FixedBottom
          className={clsx(
            styles.drawerRight,
            styles.drawerContainer,
            isContextualOpen && styles.drawerContainerOpen
          )}
          offset={footerHeightValue}
          style={style}
          cssVariableName="drawerSpaceFooterHeight"
        >
          <DrawerSpaceToggle
            side={DrawerSpaceDrawerSide.Contextual}
            drawerHeader={contextualDrawerHeader ?? Fragment}
          />
          {ContextualDrawerContent && <ContextualDrawerContent />}
        </FixedBottom>
      )}
      <DrawerSpaceContentWrapper isContentRoot>
        <DrawerSpaceSizerElement />
        {children}
        {Footer && <div className={styles.footerSpacer} style={style} />}
      </DrawerSpaceContentWrapper>
      {Footer && (
        <FixedBottom
          ref={onSetRef}
          className={styles.footerContainer}
          style={style}
        >
          <Footer {...spaceProps} />
        </FixedBottom>
      )}
    </>
  );
};

export default memo(DrawerSpaceContent);
