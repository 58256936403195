import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { interpolateRoutePath, routes } from '@kitted/website-constants';
import { Gear, Gem } from '@kitted/website-icons';

import useIsLoggedIn from '../../../../../../../contexts/UserContext/hooks/useIsLoggedIn';
import useIsLoggingIn from '../../../../../../../contexts/UserContext/hooks/useIsLoggingIn';
import useIsPathSelected from '../../../../../../../hooks/useIsPathSelected';

const useAccountRegisterItem = () => {
  const { isLoggedIn } = useIsLoggedIn();
  const { isLoggingIn } = useIsLoggingIn();
  const params = useParams();
  const isBetaInviteInviteSelected = useIsPathSelected(
    interpolateRoutePath(routes.betaInviteWithCode, params)
  );

  const { title, path, target, IconComponent, isSelected } = useMemo(() => {
    if (isLoggedIn) {
      const route = routes.account;
      return {
        title: route.title,
        path: route.path,
        target: route.target,
        IconComponent: Gear,
      };
    }
    const route = routes.betaInvite;
    return {
      title: 'Join the Beta',
      path: route.path,
      target: route.target,
      IconComponent: Gem,
      isSelected: isBetaInviteInviteSelected || undefined,
    };
  }, [isLoggedIn, isBetaInviteInviteSelected]);

  return { title, path, target, IconComponent, isLoggingIn, isSelected };
};

export default useAccountRegisterItem;
