import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0030: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 116 116"
		height={116}
		width={116}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M48.99 64.92h66.35M.66 64.92h37.31M58.06 64.81l28.61-49.56M29.33 114.58l24.14-41.82M53.05 56.34 29.33 15.25M62.53 72.76l-4.65-8.05M86.67 114.58 67.98 82.2M34.19 23.56s14.73 4.59 37.71 1.59M41.66 36.02s9.63 1.94 23.66.73M56.05 48.33c3.14.07 7.12-.09 11.68-.8M82.2 23.6s3.4 15.11 17.57 33.56M75.14 36.29s3.14 9.32 11.2 20.87M68.51 47.92s2.35 7.61 9.7 16.71M30.62 33.22c-3.3 7.71-9.48 19.24-20.48 31.5M37.29 44.78c-2.65 5.19-6.83 12.23-12.93 19.57M47.67 47.92s-2.36 7.61-9.7 16.71M44.26 104.42c8.33-1 21.39-1.4 37.47 1.99M50.9 92.86c5.82-.3 14.01-.2 23.4 1.41M48.41 82.3s7.77-1.77 19.32.04M85.5 96.81c3.28-7.71 9.47-19.31 20.54-31.64M78.84 85.21c2.65-5.2 6.84-12.3 12.99-19.68M68.51 81.95s2.35-7.61 9.7-16.71M33.98 106.28s-3.4-15.08-17.53-33.51M41.04 93.58s-3.13-9.29-11.17-20.83M47.67 81.95s-2.36-7.61-9.7-16.71" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="m57.88 15.25 13.5-13.83h-27z" />
    </g>
  </CardIcon>
);

export default memo(Sn0030);
