import { z } from 'zod';

import {
  CMSContentBlockCardGridLoadedModelSchema,
  CMSContentBlockCardGridUnloadedModelSchema,
  CMSContentBlockCarouselItemLoadedModelSchema,
  CMSContentBlockCarouselItemUnloadedModelSchema,
  CMSContentBlockCarouselLoadedModelSchema,
  CMSContentBlockCarouselUnloadedModelSchema,
  CMSContentBlockImageLoadedModelSchema,
  CMSContentBlockImageUnloadedModelSchema,
  CMSContentBlockQuoteModelSchema,
  CMSContentBlockTextModelSchema,
  CMSContentBlockVideoLoadedModelSchema,
  CMSContentBlockVideoUnloadedModelSchema,
} from '../content-blocks';

export const AnyCMSPlayBlockLoadedModelSchema = z.union([
  CMSContentBlockImageLoadedModelSchema,
  CMSContentBlockCardGridLoadedModelSchema,
  CMSContentBlockCarouselLoadedModelSchema,
  CMSContentBlockCarouselItemLoadedModelSchema,
  CMSContentBlockVideoLoadedModelSchema,
  CMSContentBlockQuoteModelSchema,
  CMSContentBlockTextModelSchema,
]);

export const AnyCMSPlayBlockUnloadedModelSchema = z.union([
  CMSContentBlockImageUnloadedModelSchema,
  CMSContentBlockCardGridUnloadedModelSchema,
  CMSContentBlockCarouselUnloadedModelSchema,
  CMSContentBlockCarouselItemUnloadedModelSchema,
  CMSContentBlockVideoUnloadedModelSchema,
  CMSContentBlockQuoteModelSchema,
  CMSContentBlockTextModelSchema,
]);

export type AnyCMSPlayBlockLoadedModel = z.infer<
  typeof AnyCMSPlayBlockLoadedModelSchema
>;
export type AnyCMSPlayBlockUnloadedModel = z.infer<
  typeof AnyCMSPlayBlockUnloadedModelSchema
>;

export type AnyCMSPlayBlockModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? AnyCMSPlayBlockLoadedModel
    : AnyCMSPlayBlockUnloadedModel;
