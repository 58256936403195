import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0040: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 98 108"
		height={108}
		width={98}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><circle vectorEffect="non-scaling-stroke" cx="49.03" cy="65.93" r="5.03" /><path vectorEffect="non-scaling-stroke" d="M42.44 99.26c-3.68 0-4.94-3.01-4.94-6.69v-9.79c0-3.68 3.01-6.69 6.69-6.69h9.68c3.68 0 6.69 3.01 6.69 6.69v9.79c0 3.68-1.27 6.69-4.94 6.69M55.67 84.8v22.1M42.38 84.8v22.1" /><circle vectorEffect="non-scaling-stroke" cx="31.86" cy="65.93" r="5.03" /><path vectorEffect="non-scaling-stroke" d="M25.28 99.26c-3.68 0-4.94-3.01-4.94-6.69v-9.79c0-3.68 3.01-6.69 6.69-6.69h7.19M25.22 84.8v22.1" /><circle vectorEffect="non-scaling-stroke" cx="66.19" cy="65.93" r="5.04" /><path vectorEffect="non-scaling-stroke" d="M72.78 99.26c3.68 0 4.94-3.01 4.94-6.69v-9.79c0-3.68-3.01-6.69-6.69-6.69h-7.19M72.84 84.8v22.1" /></g><g ><path vectorEffect="non-scaling-stroke" stroke={color} d="M17.45 72.75H1.08V9.39h95.84v63.36H80.21" /><g stroke={color}><path vectorEffect="non-scaling-stroke" d="M8.64 72.75v34.15M89.35 72.75v34.15" /></g><g stroke={color}><path vectorEffect="non-scaling-stroke" d="M49.03 1.46v7.37M56.96 1.1H41.09" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M10.32 24.73h76.97" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0040);
