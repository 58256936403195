import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0017: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 92 132"
		height={132}
		width={92}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><g stroke={color}><path vectorEffect="non-scaling-stroke" d="M25.79 66c0 .73-.58 1.31-1.31 1.31s-1.31-.58-1.31-1.31.59-1.31 1.31-1.31 1.31.58 1.31 1.31M68.82 66c0 .73-.58 1.31-1.31 1.31S66.2 66.73 66.2 66s.59-1.31 1.31-1.31 1.31.58 1.31 1.31" /><path vectorEffect="non-scaling-stroke" d="M4.42 42.41h83.15v47.18H4.42z" /><path vectorEffect="non-scaling-stroke" d="M57.86 80.33h13.2c0-3.79 3.08-6.87 6.87-6.87V58.54c-3.79 0-6.87-3.07-6.87-6.87h-13.2M55.23 66c0 7.12-4.14 12.9-9.24 12.9s-9.23-5.78-9.23-12.9 4.14-12.9 9.23-12.9 9.24 5.77 9.24 12.9" /></g><g stroke={spot}><path vectorEffect="non-scaling-stroke" d="M46.03 1.85v24.77M53.9 9.64l-7.91-7.91-7.87 7.88M90.03 15.24 72.51 32.75M90.09 26.31V15.13H78.95M2.02 15.21l17.52 17.52M13.1 15.15H1.91v11.14M45.97 130.15v-24.77M38.1 122.36l7.91 7.91 7.87-7.88M1.97 116.76l17.52-17.51M1.91 105.69v11.18h11.14M89.98 116.79 72.46 99.27M78.9 116.85h11.19v-11.14" /></g></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M34.4 51.67H20.94c0 3.79-3.08 6.87-6.87 6.87v14.92c3.79 0 6.87 3.08 6.87 6.87H34.4" />
    </g>
  </CardIcon>
);

export default memo(In0017);
