import {
  constructAuthenticatedRoute,
  removeParentPathFromRoute,
} from '../helpers';

const accountBasePath = '/account';

export const accountOutlets = {
  accountChangePassword: constructAuthenticatedRoute(
    `${accountBasePath}/change-password`,
    'Change Password'
  ),
  accountInvitations: constructAuthenticatedRoute(
    `${accountBasePath}/invitations`,
    'Invitations'
  ),
  accountMMM: constructAuthenticatedRoute(
    `${accountBasePath}/mental-model-mondays`,
    'Mental Model Mondays'
  ),
  accountEmails: constructAuthenticatedRoute(
    `${accountBasePath}/account-emails`,
    'Account Emails'
  ),
} as const;

export type AccountOutlets = typeof accountOutlets;

export const accountRoutes = {
  account: constructAuthenticatedRoute<AccountOutlets>(
    accountBasePath,
    'Account',
    undefined,
    undefined,
    {
      accountChangePassword: removeParentPathFromRoute(
        accountBasePath,
        accountOutlets.accountChangePassword
      ),
      accountInvitations: removeParentPathFromRoute(
        accountBasePath,
        accountOutlets.accountInvitations
      ),
      accountMMM: removeParentPathFromRoute(
        accountBasePath,
        accountOutlets.accountMMM
      ),
      accountEmails: removeParentPathFromRoute(
        accountBasePath,
        accountOutlets.accountEmails
      ),
    }
  ),
} as const;
