import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const ClipboardPlus: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M14.55 26.62H2.81c-.48 0-.88-.39-.88-.88V5.55c0-.48.39-.88.88-.88h2.41M17.29 4.67h2.41c.48 0 .88.39.88.88v7.35" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M13.94 3.57c-.25-1.25-1.36-2.19-2.69-2.19s-2.43.94-2.69 2.19H6.31c-.61 0-1.1.49-1.1 1.1v1.65c0 .3.25.55.55.55h10.97c.3 0 .55-.25.55-.55V4.67c0-.61-.49-1.1-1.1-1.1h-2.25ZM7.42 16.19h5.48" /><circle vectorEffect="non-scaling-stroke" cx="4.67" cy="16.19" r=".82" stroke={color} /><path vectorEffect="non-scaling-stroke" stroke={color} d="M7.42 21.13h4.39" /><circle vectorEffect="non-scaling-stroke" cx="4.67" cy="21.13" r=".82" stroke={color} /><path vectorEffect="non-scaling-stroke" stroke={color} d="M7.42 11.26h8.77" /><circle vectorEffect="non-scaling-stroke" cx="4.67" cy="11.26" r=".82" stroke={color} /><path vectorEffect="non-scaling-stroke" stroke={color} d="M18.39 21.13h4.39M20.58 18.94v4.39" /><circle vectorEffect="non-scaling-stroke" cx="20.58" cy="21.13" r="5.49" stroke={color} />
    </g>
  </WebsiteIcon>
);

export default memo(ClipboardPlus);
