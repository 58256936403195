import { memo } from 'react';
import clsx from 'clsx';

import useMediaPlayerComponent from './hooks/useMediaPlayerComponent';
import useMediaPlayerControls from './hooks/useMediaPlayerControls';
import PlayerCaptions from './PlayerCaptions';
import useStyles from './styles';
import { MediaPlayerProps } from './types';

const MediaPlayer = ({
  className,
  additionalConfig,
  initialState,
  onComplete,
  onStateChange,
  sources,
  tracks,
  playing: controlledPlaying,
  showCC = false,
  controls = false,
  loop = false,
  isClickable = false,
  playerType = 'video',
}: MediaPlayerProps) => {
  const styles = useStyles();
  const {
    config,
    muted,
    onReady,
    handleComplete,
    playerRef,
    playing,
    togglePlaying,
    fireStateChangeEvent,
  } = useMediaPlayerControls({
    playerType,
    tracks,
    initialState,
    additionalConfig,
    onStateChange,
    onComplete,
    controlledPlaying,
  });
  const { PlayerComponent } = useMediaPlayerComponent(playerType);

  if (!PlayerComponent) return null;

  return (
    <div className={clsx(className, styles.container)}>
      {(controls || isClickable) && (
        <div className={styles.playPauseOverlay} onClick={togglePlaying} />
      )}
      <PlayerComponent
        ref={playerRef}
        className={styles.videoPlayer}
        config={config}
        height=""
        loop={loop}
        muted={muted}
        onEnded={handleComplete}
        onReady={onReady}
        playing={playing}
        playsinline
        url={sources}
        width=""
      />
      <PlayerCaptions
        key={`${muted}`}
        active={showCC}
        fireStateChangeEvent={fireStateChangeEvent}
        playerRef={playerRef?.current}
      />
    </div>
  );
};

export default memo(MediaPlayer);
