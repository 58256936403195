import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    '@global': {
      '[data-radix-popper-content-wrapper]': {
        zIndex: [[styling.zIndex.tooltip], '!important'],
      },
    },
    triggerWrapper: {
      display: 'inline-block',
    },
    container: {
      borderRadius: styling.sizes.context.card.radius,
      paddingInline: styling.sizes.theme.spacing['2xs'],
      paddingBlock: styling.sizes.theme.spacing['3xs'],
      userSelect: 'none',
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      '& > span:not([role="tooltip"]):last-of-type:before': {
        content: '""',
        position: 'absolute',
        border: [0, 'solid', styling.colors.theme.transparent],
        borderWidth: [11, 7],
        marginLeft: -7,
        height: 0,
        width: 0,
        top: 0,
        left: '50%',
        zIndex: 1,
      },
    },
    'theme-primary': {
      background: styling.colors.context.background,
      color: styling.colors.context.color,
      boxShadow: styling.shadows.utils.boxShadowToString({
        x: 0,
        y: 0,
        blur: 0,
        spread: 1,
        color: styling.colors.context.color,
        inset: null,
      }),
      '& $arrow': {
        fill: styling.colors.context.background,
      },
      '& > span:not([role="tooltip"]):last-of-type:before': {
        borderTopColor: styling.colors.context.color,
      },
    },
    'theme-secondary': {
      background: styling.colors.context.inverted.background,
      color: styling.colors.context.inverted.color,
      boxShadow: styling.shadows.utils.boxShadowToString({
        x: 0,
        y: 0,
        blur: 0,
        spread: 1,
        color: styling.colors.context.inverted.color,
        inset: null,
      }),
      '& $arrow': {
        fill: styling.colors.context.inverted.background,
      },
      '& > span:not([role="tooltip"]):last-of-type:before': {
        borderTopColor: styling.colors.context.inverted.color,
      },
    },
    'theme-white': {
      background: styling.colors.theme.pureWhite,
      color: styling.colors.context.inverted.color,
      boxShadow: styling.shadows.utils.boxShadowToString({
        x: 0,
        y: 0,
        blur: 0,
        spread: 1,
        color: styling.colors.theme.primary900_20,
        inset: null,
      }),
      '& $arrow': {
        fill: styling.colors.theme.pureWhite,
      },
      '& > span:not([role="tooltip"]):last-of-type:before': {
        borderTopColor: styling.colors.theme.primary900_20,
      },
    },
    arrow: {
      position: 'relative',
      zIndex: 2,
    },
  },
  { name: 'tooltip' }
);
