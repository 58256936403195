import { constructPublicRoute } from '../helpers';
import { authorTitles } from '../routeTitles';

const authorTitleCodeBasePath = '/author/:titleSlug/:code';

export const authorRedirectRoutes = {
  authorCode: constructPublicRoute(
    '/author/:code',
    'Author',
    authorTitleCodeBasePath
  ),
} as const;

export const authorRoutes = {
  ...authorRedirectRoutes,
  authorTitleCode: constructPublicRoute(authorTitleCodeBasePath, [
    authorTitles,
    'Author',
  ]),
  authorTitleCodeEdit: constructPublicRoute(`${authorTitleCodeBasePath}/edit`, [
    authorTitles,
    'Edit Author',
  ]),
} as const;
