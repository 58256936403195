import { useMemo } from 'react';
import {
  getInteractionProps,
  InteractionProps,
} from '@kitted/shared-components';
import { EMPTY_OBJ } from '@kitted/shared-utils';

import ExternalLinkWithCapturedQueryParams from '../../../../../../../components/ExternalLinkWithCapturedQueryParams';
import PreloadingInternalLink from '../../../../../../../components/PreloadingInternalLink';
import { getTitleFromRouteTitle } from '../../../../../../../components/SEOHead/hooks/usePageTitle/logic';
import useIsPathSelected from '../../../../../../../hooks/useIsPathSelected';
import { MenuItemLinkProps } from '../../types';
import { getHrefToFromPath } from './logic';

const useMenuItemLink = (
  path: MenuItemLinkProps['path'],
  onClick: MenuItemLinkProps['onClick'],
  target: MenuItemLinkProps['target'],
  preinterpolatedRoutePath: MenuItemLinkProps['preinterpolatedRoutePath'],
  highlightSubroutes: MenuItemLinkProps['highlightSubroutes'],
  title: MenuItemLinkProps['title'],
  isSelected: MenuItemLinkProps['isSelected'],
  isDisabled: MenuItemLinkProps['isDisabled']
) => {
  const linkIsSelected = useIsPathSelected(
    path,
    preinterpolatedRoutePath,
    highlightSubroutes
  );
  const { as: Component, ...componentProps } = useMemo(() => {
    const { href, to } = getHrefToFromPath(path);
    const props = getInteractionProps(
      {
        href,
        to,
        onClick,
        nonInteractive: isDisabled,
      } as InteractionProps,
      {
        defaultComponent: 'button',
        internalLinkComponent: PreloadingInternalLink,
        externalLinkComponent: ExternalLinkWithCapturedQueryParams,
      },
      {
        type: 'button',
      },
      {
        type: 'button',
        disabled: true,
      }
    );
    return {
      ...props,
      target,
    };
  }, [path, onClick, target, isDisabled]);

  const titleValue = useMemo(
    () => getTitleFromRouteTitle(title, EMPTY_OBJ, false),
    [title]
  );

  return {
    Component,
    componentProps,
    linkIsSelected: linkIsSelected || isSelected,
    titleValue,
  };
};

export default useMenuItemLink;
