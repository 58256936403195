import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

import { generateContainerQueries, generateWholeRowsQueries } from './logic';

export default createUseStyles(
  {
    container: {
      '--cardGridTemplateColumns': styling.utils.px(
        styling.sizes.context.card.width
      ),
      '--cardFixerRowWidth': 'span 1',
      containerType: 'inline-size',
      ...generateContainerQueries(
        '& $grid',
        styling.utils.px(styling.sizes.context.card.width),
        styling.sizes.theme.columns
      ),
    },
    containerHiddenOverflow: {
      overflow: 'hidden',
    },
    onlyWholeRows: {
      ...generateWholeRowsQueries('& $grid', styling.sizes.theme.columns),
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'var(--cardGridTemplateColumns)',
      justifyContent: 'center',
      gap: styling.sizes.context.gutteringSmW,
      paddingBlock: styling.sizes.context.gutteringSmW,
      paddingInline: styling.sizes.context.gutteringSmW,
      [styling.container.context.drawer.tabletUpString]: {
        gap: styling.sizes.context.gutteringW,
        paddingBlock: styling.sizes.context.gutteringW,
        paddingInline: styling.sizes.context.gutteringW,
      },
    },
    chromeFixer: {
      transform: `translateY(-${
        styling.sizes.context.card.height + styling.sizes.context.gutteringSmW
      }px)`,
      [styling.container.context.drawer.tabletUpString]: {
        transform: `translateY(-${
          styling.sizes.context.card.height + styling.sizes.context.gutteringW
        }px)`,
      },
      marginBottom: -1 * styling.sizes.context.card.height,
    },
    fixerRow: {
      gridColumn: 'var(--cardFixerRowWidth)',
      height: styling.sizes.context.card.height,
    },
  },
  { name: 'cardGrid' }
);
