import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0008: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 62 108"
		height={108}
		width={62}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M41.5 73.35H24.35q-.735 0-1.26-.51c-.34-.34-.52-.75-.52-1.24v-3.37c0-2.54-.67-4.69-2-6.45s-3.42-3.86-6.28-6.3c-2.76-2.34-5-4.49-6.73-6.45-1.72-1.95-3.2-4.47-4.44-7.55-1.23-3.08-1.85-6.77-1.85-11.07 0-8.6 2.61-15.51 7.84-20.74 5.23-5.24 12.23-7.85 21-7.85 6.01 0 11.34 1.25 15.97 3.74s8.23 6.01 10.79 10.55 3.84 9.75 3.84 15.61v1.47c0 .49-.17.91-.52 1.25q-.51.51-1.26.51l-17.3.73c-1.18 0-1.77-.54-1.77-1.61v-2.34c0-3.32-.84-5.98-2.51-7.99-1.67-2-3.94-3-6.8-3-2.46 0-4.46.95-5.99 2.86-1.53 1.9-2.29 4.42-2.29 7.55 0 2.93.74 5.4 2.22 7.4 1.48 2.01 3.8 4.38 6.95 7.11 3.84 3.32 6.78 6.48 8.8 9.46s3.03 6.91 3.03 11.8v4.69c0 .49-.17.91-.52 1.24q-.51.51-1.26.51Z" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M32.92 106.18q-5.175 0-8.43-3.3c-2.17-2.2-3.25-4.96-3.25-8.28s1.09-6.06 3.25-8.21c2.17-2.15 4.98-3.22 8.43-3.22s6.11 1.05 8.28 3.15 3.25 4.86 3.25 8.28-1.08 6.21-3.25 8.35c-2.17 2.15-4.93 3.23-8.28 3.23" />
    </g>
  </CardIcon>
);

export default memo(Fr0008);
