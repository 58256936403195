import { useCallback, useEffect, useState } from 'react';

import { CueChangeEvent } from '../../types';
import { PlayerCaptionsProps } from '../types';
import { handleOnCueChange, handleOnPlayerMount } from './logic';

const usePlayerCaptions = (
  playerRef: PlayerCaptionsProps['playerRef'],
  fireStateChangeEvent: PlayerCaptionsProps['fireStateChangeEvent']
) => {
  const [trackIndex, _setTrackIndex] = useState(0);
  const [caption, setCaption] = useState('');

  const onCueChange = useCallback(
    (e: CueChangeEvent) =>
      handleOnCueChange(e, setCaption, fireStateChangeEvent),
    [setCaption, fireStateChangeEvent]
  );

  useEffect(
    () => handleOnPlayerMount(playerRef, onCueChange, trackIndex),
    [playerRef, onCueChange, trackIndex]
  );

  return {
    caption,
  };
};

export default usePlayerCaptions;
