import { z } from 'zod';
import { KittedServiceEnum } from '@kitted/kitted-models';

import { WebsocketClientMessageType } from './enum';
import { WebsocketClientMessageSchema } from './WebsocketClientMessage';

export const WebsocketServiceRequestClientMessageSchema =
  WebsocketClientMessageSchema.merge(
    z.object({
      messageType: z.literal(WebsocketClientMessageType.ServerRequest),
      service: z.nativeEnum(KittedServiceEnum),
      function: z.string().regex(/^ws[A-Z].*/, {
        message:
          "String must start with 'ws' followed by a capital letter starting the function name",
      }),
      payload: z.any(),
    })
  );

export type WebsocketServiceRequestClientMessage = z.infer<
  typeof WebsocketServiceRequestClientMessageSchema
>;
