import { useCallback, useEffect } from 'react';
import { AnyAssetModel, KittedServiceEnum } from '@kitted/kitted-models';
import { WebsocketModelUpdateServerMessage } from '@kitted/websocket-service-models';

import useAssetsApi from '../../../../../../hooks/useAssets';
import { useSubscribeToWebsocketModelUpdateServerMessage } from '../../../../../WebsocketContext';
import useStoreApi from '../../../../hooks/useStoreApi';
import useStoreSection from '../../../../hooks/useStoreSection';
import { StoreSectionInitializerProps } from '../../../types';

const useAssetsStoreInitializer = (
  onSectionLoaded: StoreSectionInitializerProps['onSectionLoaded']
) => {
  const { getAsset } = useAssetsApi();
  const { createOrUpdateStoreSection } = useStoreApi();
  const { updateStoreSectionItem } = useStoreSection('Assets');

  const updateAssetsStoreSectionFetcher = useCallback(() => {
    createOrUpdateStoreSection('Assets', getAsset);
    onSectionLoaded('Assets');
  }, [createOrUpdateStoreSection, getAsset, onSectionLoaded]);

  useEffect(updateAssetsStoreSectionFetcher, [updateAssetsStoreSectionFetcher]);

  const handleWebsocketItemUpdated = useCallback(
    ({ model, service }: WebsocketModelUpdateServerMessage) => {
      if (model && service === KittedServiceEnum.Asset) {
        const asset = model as AnyAssetModel;
        updateStoreSectionItem(asset, asset.id);
      }
    },
    [updateStoreSectionItem]
  );

  useSubscribeToWebsocketModelUpdateServerMessage(handleWebsocketItemUpdated);
};

export default useAssetsStoreInitializer;
