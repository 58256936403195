import { useEffect, useMemo } from 'react';

import { AnimatedValueProps } from '../../types';
import useAnimationTimer from '../useAnimationTimer';

const useAnimatedValue = (
  from: AnimatedValueProps['from'],
  to: AnimatedValueProps['to'],
  duration: AnimatedValueProps['duration'],
  isPlaying: boolean
) => {
  const { elapsed, onReset } = useAnimationTimer(duration);

  useEffect(() => {
    if (isPlaying) {
      onReset();
    }
  }, [isPlaying, onReset]);

  const value = useMemo(() => {
    if (!isPlaying) return from;
    const difference = to - from;
    const amountElapsed = Math.min(1, elapsed) * difference;

    return from + amountElapsed;
  }, [from, to, elapsed, isPlaying]);

  return value;
};

export default useAnimatedValue;
