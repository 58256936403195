import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0005: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 104 102"
		height={102}
		width={104}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="m51.99 100.33 49.89-56.48L88.87 22.3H15.12l-13 21.55L52 100.33M3.12 43.85H30.4M43.08 43.85h57.79M30.13 43.85l21.8 55.72M30.04 43.57 21.25 31.2M50.76 22.72l-20.3 20.72M73.86 43.85l-21.8 55.72M73.95 43.57l14.86-20.9M53.24 22.72l20.29 20.72" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M52.02 9.51V1.67M76.5 9.51l5.3-6.84M27.5 9.51l-5.31-6.84" />
    </g>
  </CardIcon>
);

export default memo(In0005);
