/* eslint-disable no-param-reassign */
import { useCallback, useRef } from 'react';

const useFade = (ref: React.ComponentPropsWithRef<'div'>['ref']) => {
  const nodeRef = useRef<HTMLDivElement | null>(null);

  const setNodeRef = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        nodeRef.current = node;
        if (typeof ref === 'function') {
          ref(node);
        } else if (ref) {
          (ref as React.MutableRefObject<HTMLDivElement>).current = node;
        }
      }
    },
    [ref]
  );

  return {
    nodeRef,
    setNodeRef,
  };
};

export default useFade;
