import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';
import { typographyConstants } from '@kitted/shared-components';

export default createUseStyles(
  {
    image: {
      position: 'absolute',
      top: 75,
      left: 36,
      right: 0,
      transform: 'translate(0, -50%)',
      height: 60,
      width: 60,
    },
    imageFeatured: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '50%',
      width: '100%',
      transform: 'none',
    },
    title: {
      position: 'absolute',
      left: 0,
      right: 0,
      boxSizing: 'content-box',
      height: `calc(3 * ${typographyConstants.cardTitle.lineHeight})`,
      paddingInline: 'var(--cardSkeletonPaddingInline)',
      bottom: `calc(50% - 21px)`,
      paddingBlock: `calc(${typographyConstants.cardTitle.lineHeight} / 2)`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    titleFeatured: {
      paddingBlock: 'unset',
      position: 'absolute',
      top: 'calc(50% + 21px)',
      bottom: 'unset',
      left: 0,
      right: 0,
    },
    body: {
      position: 'absolute',
      left: 0,
      right: 0,
      paddingInline: styling.sizes.context.card.paddingInline,
      top: `calc(50% + 21px)`,
    },
    bodyFeatured: {
      position: 'absolute',
      top: `calc(50% + (3 * ${typographyConstants.cardTitle.lineHeight}) + 29px)`,
      left: 0,
      right: 0,
      paddingInline: 'var(--cardSkeletonPaddingInline)',
    },
  },
  { name: 'authorCardSkeleton' }
);
