import { memo } from 'react';
import { Typography } from '@kitted/shared-components';

import { FormProvider } from '../../../../contexts/FormContext';
import Form from '../../../Form';
import FormFields from '../../../FormFields';
import useAuthResetPasswordChallenge from './hooks/useAuthResetPasswordChallenge';
import ChangeButton from './ChangeButton';
import { dataSchema, layoutSchema } from './constants';
import useStyles from './styles';
import { AuthResetPasswordChallengeProps } from './types';

const AuthResetPasswordChallenge = ({
  onClose,
  session,
  email,
}: AuthResetPasswordChallengeProps) => {
  const { onSubmit, onSubmitSuccess, initialFormData } =
    useAuthResetPasswordChallenge(onClose, session, email);
  const styles = useStyles();

  return (
    <>
      <Typography className={styles.reset}>
        Your account password has been reset, and needs to be changed. Use the
        form below to set your new password.
      </Typography>
      <FormProvider initialValues={initialFormData} dataSchema={dataSchema}>
        <Form
          autoComplete
          onSubmit={onSubmit}
          onSubmitSuccess={onSubmitSuccess}
        >
          <FormFields layout={layoutSchema} />
          <ChangeButton />
        </Form>
      </FormProvider>
    </>
  );
};

export default memo(AuthResetPasswordChallenge);
