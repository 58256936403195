import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0012: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 106 94"
		height={94}
		width={106}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M4.49 92.12 99.11 1.88" /><g ><path vectorEffect="non-scaling-stroke" stroke={spot} d="M81.89 28.61c4.28 2.4 12.32 4.8 22.14-2.41" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M1.97 26.2s10.75 10.82 25.48 0c0 0 10.82 10.82 25.56 0 0 0 2.71 2.7 7.25 4.06" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M38.44 67.74c4.06.32 9.04-.7 14.56-4.75 0 0 10.83 10.82 25.56 0 0 0 10.75 10.82 25.48 0" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M1.97 62.99s8.91 8.97 21.63 2.4" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M18.06 83.79c4.3 2.4 12.38 4.8 22.2-2.41 0 0 10.82 10.82 25.55 0 0 0 10.75 10.82 25.48 0M57.37 48.78c2.61-.68 5.45-1.99 8.44-4.19 0 0 10.75 10.82 25.48 0" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M14.7 44.6s10.82 10.82 25.56 0c0 0 1.2 1.2 3.35 2.41M14.7 7.81s10.82 10.82 25.56 0c0 0 10.82 10.82 25.55 0 0 0 5.72 5.75 14.53 4.67" /></g>
    </g>
  </CardIcon>
);

export default memo(In0012);
