import { ImageSize } from '@kitted/kitted-models';

import useAssetImage from '../../../AssetImage/hooks/useAssetImage';
import { CardWithImageAssetProps } from '../../types';

const useCardWithImageAsset = (
  imageSrc: CardWithImageAssetProps['imageSrc'],
  imageAsset: CardWithImageAssetProps['imageAsset'],
  isFeatured: CardWithImageAssetProps['isFeatured']
) => {
  const { loadedSrc } = useAssetImage(
    imageSrc,
    imageAsset,
    isFeatured ? ImageSize.XLarge : ImageSize.Medium
  );

  return {
    loadedSrc,
  };
};

export default useCardWithImageAsset;
