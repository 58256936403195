/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction } from 'react';
import { styling } from '@kitted/design-system';

import { CollapseMenuDirection, CollapseMenuProps } from './types';

export const calculateStyles = (maxHeight: CollapseMenuProps['maxHeight']) => {
  if (maxHeight) {
    return {
      '--collapseMenuMaxHeight': styling.utils.maybePx(maxHeight),
    } as React.CSSProperties;
  }
  return undefined;
};

export const handleDirectionCheck = (
  isOpen: boolean,
  setDirection: Dispatch<SetStateAction<CollapseMenuDirection>>,
  lockDirection: CollapseMenuProps['lockDirection'],
  listRef: any
) => {
  if (lockDirection) return;

  if (isOpen && listRef) {
    const { innerHeight } = window;
    const listTop = listRef.getBoundingClientRect().top;

    if (listTop > innerHeight / 2) {
      setDirection('up');
      return;
    }
  }
  setDirection('down');
};
