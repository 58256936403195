import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0038: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 102 80"
		height={80}
		width={102}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="M86.74 38.86H67.2M86.74 26.19H67.2M86.74 13.5H67.2M86.74 51.54H32.9M86.74 64.22H32.9" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M1.45 12.5v56.75c0 5.28 4.49 9.68 9.77 9.68s9.8-4.39 9.8-9.68V1.07h79.54v69.04c0 4.8-3.93 8.81-8.73 8.81h-79.6M11.17 69.3V12.5" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M33.31 13.42h22.87v25.19H33.31V23.89" />
    </g>
  </CardIcon>
);

export default memo(Fr0038);
