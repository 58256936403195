import { memo } from 'react';
import clsx from 'clsx';

import useBackdrop from './hooks/useBackdrop';
import useStyles from './styles';
import { BackdropProps } from './types';

const Backdrop = ({ variant, onClick, index = 0 }: BackdropProps) => {
  const styles = useStyles();
  const { style } = useBackdrop(index);

  return (
    <div
      className={clsx(
        styles.backdrop,
        styles[`variant-${variant}`],
        !!onClick && styles.backdropOnClick
      )}
      onMouseDown={onClick}
      style={style}
    />
  );
};

export default memo(Backdrop);
