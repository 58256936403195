import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const LaptopPadlock: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 30 20"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M24.33 5.31V3.29c0-.97-.78-1.75-1.75-1.75H5.25c-.97 0-1.75.78-1.75 1.75v11.25M18.18 18.47H3.28c-1.07 0-1.72-.92-2.08-2.08h16.97M27.11 10.62h1.68v7.85h-8.93v-7.85h1.68V9.26c0-1.2.97-2.17 2.17-2.17h1.23c1.2 0 2.17.97 2.17 2.17zM23.16 10.62h2.34" /><circle vectorEffect="non-scaling-stroke" cx="24.33" cy="13.72" r=".44" stroke={color} /><path vectorEffect="non-scaling-stroke" stroke={color} d="M24.33 16.06v-2.34" />
    </g>
  </WebsiteIcon>
);

export default memo(LaptopPadlock);
