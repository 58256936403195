import { memo } from 'react';
import { Typography } from '@kitted/shared-components';

import useForgotPasswordLink from './hooks/useForgotPasswordLink';
import useStyles from './styles';
import { ForgotPasswordLinkProps } from './types';

const ForgotPasswordLink = ({ onClick }: ForgotPasswordLinkProps) => {
  const styles = useStyles();
  const { handleOnClick } = useForgotPasswordLink(onClick);

  return (
    <Typography
      className={styles.forgotPassword}
      as="button"
      onClick={handleOnClick}
      variant="inlineAction"
      type="button"
    >
      Forgot password?
    </Typography>
  );
};

export default memo(ForgotPasswordLink);
