import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const CircleCheck: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="m8.65 14 3.96 3.96 7.52-7.52M26.25 14c0 6.76-5.48 12.24-12.24 12.24S1.76 20.76 1.76 14 7.24 1.76 14 1.76 26.24 7.24 26.24 14Z" />
    </g>
  </WebsiteIcon>
);

export default memo(CircleCheck);
