import {
  AuthenticatedRoute,
  AuthenticatedRouteWithChildren,
  PageTitle,
} from '../types';

export function constructAuthenticatedRoute(
  path: string,
  title: PageTitle,
  redirectTo?: string,
  preinterpolatedRoutePath?: string
): AuthenticatedRoute;

export function constructAuthenticatedRoute<C>(
  path: string,
  title: PageTitle,
  redirectTo: string | undefined,
  preinterpolatedRoutePath: string | undefined,
  children: C
): AuthenticatedRouteWithChildren<C>;

export function constructAuthenticatedRoute<C>(
  path: string,
  title: PageTitle,
  redirectTo?: string,
  preinterpolatedRoutePath?: string,
  children?: C
): AuthenticatedRoute | AuthenticatedRouteWithChildren<C> {
  if (children) {
    return {
      path,
      title,
      preinterpolatedRoutePath,
      redirectTo,
      isAuthenticated: true,
      children,
    };
  }
  return {
    path,
    title,
    preinterpolatedRoutePath,
    redirectTo,
    isAuthenticated: true,
  };
}
