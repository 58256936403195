import colors from './colors';
import { Shadow } from './types';
import utils from './utils';

const transparent: Shadow = {
  x: 0,
  y: 0,
  blur: 0,
  spread: 0,
  color: colors.theme.transparent,
  inset: null,
};

const primary100: Shadow = {
  x: 0,
  y: 0,
  blur: 0,
  spread: 1,
  color: colors.theme.primary100,
  inset: null,
};

const primary900: Shadow = {
  x: 0,
  y: 0,
  blur: 10,
  spread: 0,
  color: colors.theme.primary900,
  inset: null,
};

const primary900Lg: Shadow = {
  x: 0,
  y: 0,
  blur: 20,
  spread: 0,
  color: colors.theme.primary900_50,
  inset: null,
};

const primary900Xl: Shadow = {
  x: 0,
  y: 3,
  blur: 48,
  spread: 0,
  color: colors.theme.primary900_10,
  inset: null,
};

const pureBlackLg: Shadow = {
  x: 10,
  y: 10,
  blur: 25,
  spread: 0,
  color: 'rgba(0,0,0,0.75)',
  inset: null,
};

const secondaryXs: Shadow = {
  x: 0,
  y: 0,
  blur: 0,
  spread: 1,
  color: colors.theme.secondary,
  inset: null,
};

const secondarySm: Shadow = {
  x: 0,
  y: 0,
  blur: 0,
  spread: 2,
  color: colors.theme.secondary,
  inset: null,
};

const svgFilter = (shadow: Shadow) => {
  if (typeof shadow.blur === 'string')
    return `drop-shadow(${shadow.x}px ${shadow.y}px ${shadow.blur} ${shadow.color})`;
  return `drop-shadow(${shadow.x}px ${shadow.y}px ${shadow.blur * 0.5}px ${
    shadow.color
  })`;
};

const boxShadowToString = ({ x, y, blur, spread, color, inset }: Shadow) =>
  `${inset ? 'inset ' : ''}${x}px ${y}px ${utils.maybePx(blur)} ${utils.maybePx(
    spread
  )} ${color}`;

export default {
  theme: {
    transparent,
    primary100,
    primary900,
    primary900Lg,
    primary900Xl,
    pureBlackLg,
    secondaryXs,
    secondarySm,
  },
  context: {
    card: primary900,
    cardFeature: primary900Lg,
    cardFocus: secondarySm,
    dropdowns: primary900Lg,
  },
  utils: {
    svgFilter,
    boxShadowToString,
  },
};
