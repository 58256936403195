import { useEffect, useMemo } from 'react';
import { CatalogueItemModel } from '@kitted/catalogue-service-models';

import useTextSearch from '../../../../hooks/useTextSearch';
import { catalogueItemTextSearchSchema } from './constants';
import { filterCatalogueItemsBySearchTerm } from './logic';

const useSearchCatalogueItems = (
  items: CatalogueItemModel[],
  searchText: string
) => {
  const { insertBatch, search } = useTextSearch(catalogueItemTextSearchSchema);

  useEffect(() => {
    insertBatch(items, true);
  }, [items, insertBatch]);

  const filteredItems = useMemo(
    () => filterCatalogueItemsBySearchTerm(items, searchText, search),
    [searchText, search, items]
  );

  return filteredItems;
};

export default useSearchCatalogueItems;
