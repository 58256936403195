import { useCallback, useRef } from 'react';

import {
  createDb,
  handleInsertBatch,
  handleSearch,
  handleUpsert,
} from './logic';
import { Fuzzy, TextSearchSchema, Weightings } from './types';

const useTextSearch = (schema: TextSearchSchema, minSearchCharacters = 2) => {
  const schemaRef = useRef<TextSearchSchema>(schema);
  const dbRef = useRef(createDb(schemaRef.current));
  const idSetRef = useRef(new Set<string>([]));

  const insertBatchIdIntoSet = useCallback((newIds: string[]) => {
    newIds.forEach((id) => idSetRef.current.add(id));
  }, []);

  const insertIdIntoSet = useCallback((newId: string) => {
    idSetRef.current.add(newId);
  }, []);

  const onInsertBatch = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (batchItems: any[], reset = false) => {
      handleInsertBatch(dbRef.current, batchItems, reset);
      insertBatchIdIntoSet(
        batchItems.map((item) => item[schemaRef.current.idField])
      );
    },
    [insertBatchIdIntoSet]
  );

  const onSearch = useCallback(
    (term: string, weightings?: Weightings, fuzzy?: Fuzzy) =>
      handleSearch(
        dbRef.current,
        term,
        idSetRef.current,
        minSearchCharacters,
        weightings,
        fuzzy
      ),
    [minSearchCharacters]
  );

  const onUpsert = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (item: any) => {
      handleUpsert(dbRef.current, item, schemaRef.current);
      insertIdIntoSet(item[schemaRef.current.idField]);
    },
    [insertIdIntoSet]
  );

  return {
    insertBatch: onInsertBatch,
    upsertItem: onUpsert,
    search: onSearch,
  };
};

export default useTextSearch;
