import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    container: {
      position: 'relative',
      width: '100%',
      aspectRatio: '279 / 88',
    },
    containerOnLight: {
      aspectRatio: '272 / 90',
    },
    kittedBrandmark: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '28.62%',
      height: 'auto',
    },
    kittedBrandmarkOnLight: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '31.02%',
      height: 'auto',
    },
    kittedBrand: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: '61.83%',
      height: 'auto',
    },
  },
  { name: 'kittedBrandingFull' }
);
