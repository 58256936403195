import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EMPTY_OBJ } from '@kitted/shared-utils';

const useClearSkipBlockerOnLocationChange = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.skipBlocker) {
      const { skipBlocker: _deleted, ...state } = location.state || EMPTY_OBJ;
      navigate(location.pathname, { replace: true, state });
    }
  }, [location, navigate]);
};

export default useClearSkipBlockerOnLocationChange;
