import { useMemo } from 'react';

import { extractInteractionProps } from '../../logic';

const useExtractInteractionProps = (props: { [key: string]: unknown }) => {
  const {
    onFocus,
    onBlur,
    onMouseEnter,
    onMouseLeave,
    tabIndex,
    onClick,
    href,
    to,
    target,
  } = props;

  const interactionProps = useMemo(
    () => extractInteractionProps(props),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      onFocus,
      onBlur,
      onMouseEnter,
      onMouseLeave,
      tabIndex,
      onClick,
      href,
      to,
      target,
    ]
  );

  return interactionProps;
};

export default useExtractInteractionProps;
