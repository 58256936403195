import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      textAlign: 'center',
    },
    link: {
      fontWeight: styling.fonts.theme.atlasGrotesk.weights.medium,
    },
  },
  { name: 'terms' }
);
