import { useCallback, useMemo } from 'react';

import {
  ACCEPTED_IMAGE_FILES,
  MAX_FILE_SIZE,
} from '../../../../../../constants/inputs';
import useBlobStorage from '../../../../../../hooks/useBlobStorage';
import {
  useAssetCatalogueApi,
  useAssetCatalogueData,
} from '../../../../contexts/AssetCatalogueProvider';
import { UploadImageBodyProps } from '../../types';
import { getHelperText } from './logic';

export const useUploadedImage = (
  maxFileSize: UploadImageBodyProps['maxFileSize'] = MAX_FILE_SIZE,
  acceptedFileFormats: UploadImageBodyProps['acceptedFileFormats'] = ACCEPTED_IMAGE_FILES
) => {
  const { setUploadedBlobGuid } = useAssetCatalogueApi();
  const { uploadedBlobGuid } = useAssetCatalogueData();
  const { constructBlobStorageContentUrl } = useBlobStorage();

  const blobUrl = useMemo(
    () =>
      uploadedBlobGuid
        ? constructBlobStorageContentUrl(uploadedBlobGuid)
        : null,
    [constructBlobStorageContentUrl, uploadedBlobGuid]
  );

  const clearUploadedImage = useCallback(() => {
    setUploadedBlobGuid(null);
  }, [setUploadedBlobGuid]);

  const helperText = useMemo(
    () => getHelperText(maxFileSize, acceptedFileFormats),
    [maxFileSize, acceptedFileFormats]
  );

  return { blobUrl, clearUploadedImage, helperText };
};
