import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles({
  svg: {},
  'theme-secondary': {
    '--progressCircleStrokeColor': styling.colors.theme.secondary,
    '--progressCircleBackingStrokeColor': styling.colors.theme.secondary300_20,
  },
  'theme-primary': {
    '--progressCircleStrokeColor': styling.colors.theme.primary900,
    '--progressCircleBackingStrokeColor': styling.colors.theme.primary900_20,
  },
  backingLine: {
    stroke: 'var(--progressCircleBackingStrokeColor)',
  },
  line: {
    stroke: 'var(--progressCircleStrokeColor)',
    transform: 'rotate(-90deg)',
    transformOrigin: 'center center',
  },
});
