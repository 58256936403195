import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0019: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 116 100"
		height={100}
		width={116}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m40.53 41.68 15.79-10.44 4.93 17.22 7.07-14.96L84.1 44.55" /><path vectorEffect="non-scaling-stroke" d="M24.4 72.33 61.61.96l37.13 71.37" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M114.93 88.02H12.28V.96M.69 23.82h11.49M.69 49.89h11.49M.69 75.96h11.49M24.4 99.41V87.92M61.57 99.41V87.92M98.75 99.41V87.92" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0019);
