import { z } from 'zod';
import { ArticleModel } from '@kitted/article-service-models';
import { AuthorModel } from '@kitted/author-service-models';
import { AnyCardUnloadedModel } from '@kitted/card-service-models';
import { FlowModel } from '@kitted/flow-service-models';
import { KitModel } from '@kitted/kit-service-models';
import { AnyAssetModel } from '@kitted/kitted-models';
import { PlayModel } from '@kitted/play-service-models';
import { AnyResourceModel } from '@kitted/resource-service-models';

//  import {
//   ActionToolCardUnloadedModelSchema,
//   ArticleCardUnloadedModelSchema,
//   AuthorCardUnloadedModelSchema,
//   DeckCardUnloadedModelSchema,
//   FlowCardUnloadedModelSchema,
//   FlowStepUnloadedCardModelSchema,
//   FrameToolCardUnloadedModelSchema,
//   ImageAssetModelSchema,
//   ImageResourceCardUnloadedModelSchema,
//   InsightToolCardUnloadedModelSchema,
//   KitCardUnloadedModelSchema,
//   LinkResourceCardUnloadedModelSchema,
//   SnareToolCardUnloadedModelSchema,
//   SubjectCardUnloadedModelSchema,
//   VideoResourceCardUnloadedModelSchema,
// } from '../../cards-service';
import { WebsocketServerMessageType } from './enum';
import { WebsocketServerMessageSchema } from './WebsocketServerMessage';

export const WebsocketModelUpdateServerMessageSchema =
  WebsocketServerMessageSchema.merge(
    z.object({
      messageType: z.literal(WebsocketServerMessageType.ModelUpdate),
      model: z.any(),
      // model: z.union([
      //   ArticleCardUnloadedModelSchema,
      //   ImageAssetModelSchema,
      //   AuthorCardUnloadedModelSchema,
      //   DeckCardUnloadedModelSchema,
      //   FlowCardUnloadedModelSchema,
      //   FlowStepUnloadedCardModelSchema,
      //   KitCardUnloadedModelSchema,
      //   SubjectCardUnloadedModelSchema,
      //   ActionToolCardUnloadedModelSchema,
      //   FrameToolCardUnloadedModelSchema,
      //   InsightToolCardUnloadedModelSchema,
      //   SnareToolCardUnloadedModelSchema,
      //   ImageResourceCardUnloadedModelSchema,
      //   LinkResourceCardUnloadedModelSchema,
      //   VideoResourceCardUnloadedModelSchema,
      //  ]),
    })
  );

// Override the any type due to the schema limitation
export type WebsocketModelUpdateServerMessage = Omit<
  z.infer<typeof WebsocketModelUpdateServerMessageSchema>,
  'model'
> & {
  model:
    | AnyCardUnloadedModel
    | AnyAssetModel
    | ArticleModel
    | AuthorModel
    | KitModel
    | FlowModel
    | PlayModel
    | AnyResourceModel;
};
