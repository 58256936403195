/* eslint-disable camelcase */
import { generateLinearPalette } from '../../services/linearPaletteGenerator';

const snareBaseColor = '#85D3EA'; // rgb(133, 211, 234)
const palette = generateLinearPalette(snareBaseColor);

export const snare050 = palette['050']; // #e2f4fa
export const snare100 = palette['100']; // #d8f1f8
export const snare200 = palette['200']; // #ccecf6
export const snare300 = palette['300']; // #bae6f3
export const snare400 = palette['400']; // #a4deef
export const snare500 = palette['500']; // #85D3EA
export const snare500_10 = palette['500_10']; // #85D3EA
export const snare500_30 = palette['500_30']; // #85D3EA
export const snare500_40 = palette['500_40']; // #85D3EA
export const snare600 = palette['600']; // #56c1e2
export const snare700 = palette['700']; // #2eb3db
export const snare800 = palette['800']; // #219bc0
export const snare900 = palette['900']; // #1c84a4
