import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0011: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 138 110"
		height={110}
		width={138}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M135.51 93.63H15.98V1.74M2.49 18.88h13.38M2.49 49.24h13.38M2.49 79.59h13.38M30.1 108.26V94.89M58.95 108.26V94.89M87.81 108.26V94.89M116.66 108.26V94.89" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M29.93 78.66c9.45 0 15.55-.29 20.56-4.35 4.97-4.02 8.86-11.64 13.87-27.17l.6-1.85c6.71-20.81 9.78-30.3 14.29-30.3s7.58 9.5 14.29 30.3l.6 1.85c5.01 15.53 8.9 23.14 13.87 27.17 5.02 4.06 11.12 4.35 20.56 4.35" />
    </g>
  </CardIcon>
);

export default memo(In0011);
