import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0007: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 90 90"
		height={90}
		width={90}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M88.46 45.1h-34.9c-.06 0-.1-.05-.1-.1s.05-.1.1-.1h34.9c.06 0 .1.05.1.1s-.05.1-.1.1" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M76 57.57s-.05-.01-.07-.03a.11.11 0 0 1 0-.15L88.32 45 75.93 32.61s-.04-.11 0-.15.11-.04.15 0l12.46 12.46s.04.11 0 .15L76.08 57.53s-.05.03-.07.03ZM36.44 45.1H1.54c-.06 0-.1-.05-.1-.1s.05-.1.1-.1h34.9c.06 0 .1.05.1.1s-.05.1-.1.1" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M14 57.57s-.05-.01-.07-.03L1.47 45.07s-.04-.11 0-.15l12.46-12.46s.11-.04.15 0 .04.11 0 .15L1.69 45l12.39 12.39s.04.11 0 .15c-.02.02-.05.03-.07.03Z" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M45 36.54a.1.1 0 0 1-.1-.1V1.54c0-.06.05-.1.1-.1s.1.05.1.1v34.9a.1.1 0 0 1-.1.1" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M57.46 14.11s-.05-.01-.07-.03L45 1.69 32.61 14.08s-.11.04-.15 0a.11.11 0 0 1 0-.15L44.93 1.47s.11-.04.15 0l12.46 12.46s.04.11 0 .15c-.02.02-.05.03-.07.03Z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M45 88.56a.1.1 0 0 1-.1-.1v-34.9c0-.06.05-.1.1-.1s.1.05.1.1v34.9a.1.1 0 0 1-.1.1" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M45 88.56s-.05-.01-.07-.03L32.47 76.07s-.04-.11 0-.15.11-.04.15 0l12.39 12.39L57.4 75.92s.11-.04.15 0 .04.11 0 .15L45.09 88.53s-.05.03-.07.03Z" />
    </g>
  </CardIcon>
);

export default memo(Fr0007);
