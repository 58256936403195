import { z } from 'zod';
import { KittedModelBaseSchema } from '@kitted/kitted-models';
import { DateISOSchema } from '@kitted/platform-models';

import { CMSContentBlockTextAlignment, CMSContentBlockTheme } from './Enum';

export const CMSContentBlockTextModelSchema = KittedModelBaseSchema.merge(
  z.object({
    theme: z.nativeEnum(CMSContentBlockTheme),
    content: z.string(),

    alignment: z.nativeEnum(CMSContentBlockTextAlignment),

    createdByAuthorId: z.string(),
    createdByUserId: z.string(),

    createdAt: DateISOSchema,
    updatedAt: DateISOSchema,
  })
);

export type CMSContentBlockTextModel = z.infer<
  typeof CMSContentBlockTextModelSchema
>;
