import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0018: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 94 116"
		height={116}
		width={94}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M65.64 17c15.64 7.1 26.52 22.86 26.52 41.17 0 24.94-20.22 45.17-45.16 45.17-1.29 0-2.56-.05-3.82-.16M28.95 99.56C13.03 92.58 1.84 76.67 1.84 58.17c0-24.96 20.22-45.19 45.16-45.19 1.56 0 3.1.08 4.62.23M40.95 103.04l12.16 11.23M53.11 91.76 40.95 103M53.11 12.96 40.95 1.73M40.95 24.23 53.11 13" /></g><g ><path vectorEffect="non-scaling-stroke" stroke={color} d="M55.37 39.53H30.44v36.94h36.94V60.92" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="m43.15 53.46 8.89 9.87 21.41-27.66" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0018);
