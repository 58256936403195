import { parse, stringify } from 'telejson';

import { PersistentKeyValueStore } from './types';

const storageIsAvailable = !!window.localStorage;
const storage = window.localStorage;

const persistentKeyValueStore = (
  namespace: string
): PersistentKeyValueStore => {
  const constructNamespacedKey = (key: string) => `${namespace}||${key}`;

  const getValueForKey = (key: string, defaultValue?: unknown) => {
    if (!storageIsAvailable) {
      return defaultValue;
    }
    const storedValue = storage.getItem(constructNamespacedKey(key));
    if (storedValue !== null) {
      return parse(storedValue);
    }
    return defaultValue;
  };

  const setValueForKey = (key: string, value: unknown) => {
    if (!storageIsAvailable) {
      return;
    }
    storage.setItem(constructNamespacedKey(key), stringify(value));
  };

  const deleteValueForKey = (key: string) => {
    if (!storageIsAvailable) {
      return;
    }
    storage.removeItem(constructNamespacedKey(key));
  };

  const deleteAll = () => {
    if (!storageIsAvailable) {
      return;
    }
    const keys = Object.keys(storage);
    keys.forEach((key) => {
      if (key.indexOf(namespace) === 0) {
        storage.removeItem(key);
      }
    });
  };

  return {
    getValueForKey,
    setValueForKey,
    deleteValueForKey,
    deleteAll,
  };
};

export default persistentKeyValueStore;
