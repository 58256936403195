import { useCallback, useMemo, useState } from 'react';
import { AnyCardModel } from '@kitted/card-service-models';
import { KittedServiceEnum } from '@kitted/kitted-models';
import { EMPTY_ARR } from '@kitted/shared-utils';
import { WebsocketModelUpdateServerMessage } from '@kitted/websocket-service-models';

import { useGlobalScriptVariables } from '../../../GlobalScriptVariablesContext';
import { useSubscribeToWebsocketModelUpdateServerMessage } from '../../../WebsocketContext';
import { ReducedUserCardForSorting } from './constants';
import {
  getPlatformCardIdsSortOrder,
  getUserCardIdsSortOrder,
  updateOrderIdsForCardAddedUpdated,
} from './logic';

const useCardsOrderingManagement = () => {
  const { KITTED_PLATFORM_AUTHOR_ID } = useGlobalScriptVariables();
  const [orderedPlatformCardIds, setOrderedPlatformCardIds] =
    useState<AnyCardModel['id'][]>(EMPTY_ARR);
  const [orderedUserCardIds, setOrderedUserCardIds] =
    useState<ReducedUserCardForSorting[]>(EMPTY_ARR);

  // when the user adds/updates a card, reorder the user cards
  // add newly added platform cards in randomly to the platform ordered ids
  const handleWebsocketItemUpdated = useCallback(
    ({ model, service }: WebsocketModelUpdateServerMessage) => {
      if (model && service === KittedServiceEnum.Card) {
        const card = model as AnyCardModel;
        updateOrderIdsForCardAddedUpdated(
          card,
          setOrderedUserCardIds,
          setOrderedPlatformCardIds,
          KITTED_PLATFORM_AUTHOR_ID
        );
      }
    },
    [
      setOrderedUserCardIds,
      setOrderedPlatformCardIds,
      KITTED_PLATFORM_AUTHOR_ID,
    ]
  );

  useSubscribeToWebsocketModelUpdateServerMessage(handleWebsocketItemUpdated);

  const setInitialPlatformCardOrdering = useCallback(
    (platformCards: AnyCardModel[]) => {
      setOrderedPlatformCardIds(getPlatformCardIdsSortOrder(platformCards));
    },
    [setOrderedPlatformCardIds]
  );

  const setInitialUserCardOrdering = useCallback(
    (userCards: AnyCardModel[]) => {
      setOrderedUserCardIds(getUserCardIdsSortOrder(userCards));
    },
    [setOrderedUserCardIds]
  );

  const orderedCardIds = useMemo(
    () =>
      orderedUserCardIds
        .map((userCard) => userCard.id)
        .concat(orderedPlatformCardIds),
    [orderedPlatformCardIds, orderedUserCardIds]
  );

  return {
    orderedCardIds,
    setInitialPlatformCardOrdering,
    setInitialUserCardOrdering,
  };
};

export default useCardsOrderingManagement;
