import { QueryParamCaptureApi } from '../../../../contexts/QueryParamCaptureContext';
import { ExternalLinkWithCapturedQueryParamsProps } from '../../types';

export const getHrefWithQueryParams = (
  getCapturedQueryParams: QueryParamCaptureApi['getCapturedQueryParams'],
  href: ExternalLinkWithCapturedQueryParamsProps['href']
) => {
  let url;
  try {
    url = new URL(href);
    // only modify shop urls
    if (url.host !== 'kitted.shop') return href;
  } catch (e) {
    return href;
  }

  const capturedParams = getCapturedQueryParams();
  const capturedParamsKeys = new Set(capturedParams.keys());
  capturedParamsKeys.forEach((key) => {
    if (key.includes('[]')) {
      const capturedValues = capturedParams.getAll(key);
      const urlValues = url.searchParams.getAll(key);
      capturedValues.forEach((value) => {
        if (!urlValues.includes(value)) {
          url.searchParams.append(key, value);
        }
      });
    } else if (!url.searchParams.has(key)) {
      url.searchParams.append(key, capturedParams.get(key) as string);
    }
  });

  return url.toString();
};
