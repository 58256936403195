import { constructExternalRoute } from './helpers';

export const externalRoutes = {
  shop: constructExternalRoute('https://kitted.shop/', 'Shop', '_blank'),
  linkedIn: constructExternalRoute(
    'https://linkedin.com/company/kitted-thinking-tools/',
    'LinkedIn',
    '_blank'
  ),
  facebook: constructExternalRoute(
    'https://www.facebook.com/kittedthinkingtools',
    'Facebook',
    '_blank'
  ),
  // instagram: constructExternalRoute(
  //   'https://instagram.com/',
  //   'Instagram',
  //   '_blank'
  // ),
  twitter: constructExternalRoute(
    'https://twitter.com/kittedthinking',
    'Twitter/X',
    '_blank'
  ),
};
