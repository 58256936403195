import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const HamburgerMarshmallows: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 26"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M23.18 4.07h3.44M1.38 4.07h13.37M19.99 21.93h6.63M1.38 21.93h10.28M4.94 13H1.38M26.62 13H13.35" /><rect vectorEffect="non-scaling-stroke" width="5.85" height="5.85" x="5.14" y="10.08" stroke={color} rx=".84" ry=".84" /><rect vectorEffect="non-scaling-stroke" width="5.85" height="5.85" x="17.23" y="1.15" stroke={color} rx=".84" ry=".84" /><rect vectorEffect="non-scaling-stroke" width="5.85" height="5.85" x="13.96" y="19.01" stroke={color} rx=".84" ry=".84" />
    </g>
  </WebsiteIcon>
);

export default memo(HamburgerMarshmallows);
