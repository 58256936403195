import { useCallback, useRef } from 'react';

import { FormDataValue } from '../../../../../../contexts/FormContext';
import useUserApi from '../../../../../../contexts/UserContext/hooks/useUserApi';
import {
  getInitialFormData,
  transformResetChallengeFormDataToResetChallengePayload,
} from '../../logic';
import {
  AuthResetPasswordChallengeProps,
  ResetPasswordChallengeFormData,
} from '../../types';

const useAuthResetPasswordChallenge = (
  onClose: AuthResetPasswordChallengeProps['onClose'],
  session: AuthResetPasswordChallengeProps['session'],
  email: AuthResetPasswordChallengeProps['email']
) => {
  const initialFormData = useRef<FormDataValue>(getInitialFormData(email));
  const { submitChallengeResponse } = useUserApi();

  const onSubmit = useCallback(
    async (formData: ResetPasswordChallengeFormData) => {
      const resetPasswordChallengePayload =
        transformResetChallengeFormDataToResetChallengePayload(
          formData,
          session,
          email
        );
      return submitChallengeResponse(resetPasswordChallengePayload);
    },
    [submitChallengeResponse, session, email]
  );

  const onSubmitSuccess = useCallback(() => {
    onClose();
  }, [onClose]);

  return {
    onSubmit,
    onSubmitSuccess,
    initialFormData: initialFormData.current,
  };
};

export default useAuthResetPasswordChallenge;
