import { useCallback, useRef } from 'react';

import { cancelableRequestFactory } from '../../../../services/requests/cancelableRequests';
import {
  RequestConfig,
  RequestPerformer,
} from '../../../../services/requests/types';

const useExternalRequest = () => {
  const performCachedRequest = useRef<RequestPerformer>(
    cancelableRequestFactory()
  );

  const performRequest = useCallback(
    async <ResponseType>(config: RequestConfig) => {
      const response = await performCachedRequest.current<ResponseType>(config);
      return response;
    },
    []
  );

  return performRequest;
};

export default useExternalRequest;
