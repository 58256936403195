import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      textDecoration: 'none',
      gap: 6,
      position: 'relative',
      color: styling.colors.context.color,
      background: styling.colors.theme.transparent,
      transition: styling.transitions.utils.generate(
        [
          'background-color',
          styling.speeds.jog,
          styling.transitions.easing.linear,
        ],
        ['color', styling.speeds.jog, styling.transitions.easing.linear]
      ),
    },
    containerSelected: {
      background: styling.colors.context.inverted.background,
      color: styling.colors.context.inverted.color,
      '& $caret:before': {
        borderBottomColor: styling.colors.theme.primary400,
        transform: 'translateY(0px)',
      },
      '& $caret:after': {
        borderBottomColor: styling.colors.context.inverted.background,
        transform: 'translateY(0px)',
      },
    },
    caret: {
      position: 'absolute',
      bottom: '100%',
      height: 13,
      pointerEvents: 'none',
      overflow: 'hidden',
      left: 0,
      right: 0,
      '&:before,&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        height: 0,
        width: 0,
        pointerEvents: 'none',
        border: [0, 'solid', styling.colors.theme.transparent],
        transition: styling.transitions.utils.generate(
          [
            'border-color',
            styling.speeds.jog,
            styling.transitions.easing.linear,
          ],
          ['transform']
        ),
      },
      '&:before': {
        borderBottomColor: styling.colors.theme.transparent,
        borderWidth: [11, 13],
        marginLeft: -13,
        transform: 'translateY(11px)',
      },
      '&:after': {
        borderBottomColor: styling.colors.theme.transparent,
        borderWidth: [10, 12],
        marginLeft: -12,
        transform: 'translateY(10px)',
      },
    },
    icon: {
      height: styling.sizes.context.icon.md,
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  { name: 'mobileMenuItem' }
);
