import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0058: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 70 102"
		height={102}
		width={70}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M25.85 53.6v-9c0-4.95 3.58-6.78 8.2-6.78l5.67 6.24 5.26-6.24c4.61 0 8.6 1.83 8.6 6.78v9M46.48 25.7c0 3.78-3.04 6.87-6.77 6.87s-6.76-3.09-6.76-6.87v-2.54c0-3.78 3.04-6.87 6.76-6.87s6.77 3.09 6.77 6.87z" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M38.43 77.3v22.83l-7.03-4.67-7.03 4.67V83.2M11.27 9.42v51.73M11.18 77.4h57.06M38.91 86.01h29.33" /><path vectorEffect="non-scaling-stroke" d="M1.76 76.43V10.37c0-4.67 3.83-8.5 8.5-8.5h57.98v66.91H10.26c-4.67 0-8.5 3.82-8.5 8.5v.24c0 4.67 3.82 8.5 8.5 8.5h6.14" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0058);
