import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0011: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 156 102"
		height={102}
		width={156}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="M78 19.2v74.71" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M1.02 100.69c14.75 0 24.27-.46 32.1-6.8 7.75-6.28 13.82-18.16 21.65-42.4l.93-2.89C66.17 16.13 70.95 1.31 78 1.31s11.83 14.82 22.3 47.3l.93 2.89c7.82 24.24 13.89 36.13 21.65 42.4 7.83 6.34 17.35 6.8 32.1 6.8" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M67.03 84.41 78 93.91l10.97-9.5" />
    </g>
  </CardIcon>
);

export default memo(Sn0011);
