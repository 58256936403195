import { useContext } from 'react';

import { CardsOrderingContext } from '../../CardsOrderingContext';

const useCardsOrderingApi = () => {
  const cardsOrderingService = useContext(CardsOrderingContext);

  return cardsOrderingService;
};

export default useCardsOrderingApi;
