/* eslint-disable jsx-a11y/no-autofocus */

import { memo } from 'react';
import clsx from 'clsx';
import { CaretDown, CaretUp } from '@kitted/website-icons';

import CollapseMenu from '../../CollapseMenu';
import InputWrapper from '../InputWrapper';
import { InputComponentProps } from '../types';
import useInputSelect from './hooks/useInputSelect';
import InputSelectOption from './InputSelectOption';
import useStyles from './styles';
import { InputSelectMetaProp } from './types';

const InputSelect = ({
  className,
  disabled,
  errors,
  isFocused,
  iconLeft,
  onBlur,
  onChange,
  onFocus,
  options,
  meta,
  value,
  theme,
  variant,
}: InputComponentProps & InputSelectMetaProp) => {
  const styles = useStyles();
  const { isOpen, onButtonClick, selectedItem, onSelectItem, onClose } =
    useInputSelect(options, value, onChange);

  const direction = meta?.openDirection;

  return (
    <InputWrapper
      className={className}
      errors={errors}
      iconLeft={iconLeft}
      iconRight={direction === 'up' ? CaretUp : CaretDown}
      isDisabled={disabled}
      isFocused={isFocused}
      hasValue={!!selectedItem}
      helperText={meta?.helperText}
      theme={theme}
      variant={variant}
    >
      <button
        autoFocus={meta?.autoFocus}
        className={clsx(
          styles.toggleButton,
          iconLeft && styles.toggleButtonWithLeftIcon
        )}
        disabled={disabled}
        onBlur={onBlur}
        onClick={onButtonClick}
        onFocus={disabled ? undefined : onFocus}
        tabIndex={disabled ? -1 : 0}
        type="button"
      >
        {selectedItem?.title ? `#${selectedItem.title}` : ''}
      </button>
      <CollapseMenu
        isOpen={isOpen}
        onClickOutside={onClose}
        lockDirection={direction}
        theme={theme}
      >
        {options?.map((option) => (
          <li
            key={option.value}
            className={clsx(
              styles.item,
              selectedItem?.value === option.value && styles.itemSelected
            )}
          >
            <InputSelectOption
              onClick={onSelectItem(option)}
              option={option}
              disabled={disabled}
              isSelected={selectedItem?.value === option.value}
            />
          </li>
        ))}
      </CollapseMenu>
    </InputWrapper>
  );
};

export default memo(InputSelect);
