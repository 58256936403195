import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0017: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 134 68"
		height={68}
		width={134}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="M132.83 61.9s-9.24 10.1-21.9 0c0 0-9.31 10.1-21.97 0 0 0-9.3 10.1-21.97 0 0 0-9.3 10.1-21.95 0 0 0-9.3 10.1-21.97 0 0 0-9.24 10.1-21.89 0" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M100.01 61.9c0-1.56 28.61-30.97 28.62-32.53.04-6.84-2.93-20.61-5.63-27.77-11.74 11.71-23.49 23.42-35.23 35.12-5.46 5.44-10.92 10.88-16.37 16.32M102.95 9.69l-8.37-4.66-6.26 6.24-6.89-4.17-6.85 6.83-7.21-3.9-17.78 17.71L52 35.97 39.33 48.59l-9.01-1.63-10.23 10.19M10.21 57.15l-4.94-4.96 5.53-5.52 14.32 5.08M69.78 19.55 58.2 31.09M96.55 14.6c-12.39 13.19-25.72 25.31-38.1 37.98" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0017);
