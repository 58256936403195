import { memo } from 'react';
import clsx from 'clsx';

import Typography from '../../Typography';
import useCardTitle from './hooks/useCardTitle';
import useStyles from './styles';
import { CardTitleProps } from './types';

const CardTitle = ({
  className,
  children,
  isFeatured,
  type,
  useDefaultMaxLines = false,
}: CardTitleProps) => {
  const styles = useStyles();
  const { prefix, maxLines } = useCardTitle(
    type,
    isFeatured,
    useDefaultMaxLines
  );
  return (
    <p className={className}>
      {prefix && (
        <Typography
          as="span"
          className={clsx(styles.prefix, isFeatured && styles.prefixFeatured)}
          variant={`cardTitle${isFeatured ? 'Featured' : ''}`}
        >
          {prefix}
        </Typography>
      )}
      <Typography
        as="span"
        maxLines={maxLines}
        variant={`cardTitle${isFeatured ? 'Featured' : ''}`}
      >
        {children}
      </Typography>
    </p>
  );
};

export default memo(CardTitle);
