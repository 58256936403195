import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0008: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 102 108"
		height={108}
		width={102}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M17.41 53.25h33.8M34.31 25.7h31.8M17.41 68.06h29.71M17.41 82.88h27.72M24.83 11.87V25.7H10.98" /><path vectorEffect="non-scaling-stroke" d="M79.39 36.02V1.82H21.26L1.01 22.07v84.11h54.64" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m63.29 60.18-3.43-1.63M66.15 54.77 63.1 52.5M61.66 66.01l-3.87-1.16M64.87 53.32c2.93-4.09 7.08-4.26 7.08-4.26s1.82-4.96 8.32-5.92v6.1l5.77.99 1.32 2.34s10.12 7.5 12.32 10.44c0 0 1.84 6.98-3.49 6.06 0 0-16.25-5.42-16.02-.46 0 0 14.19 8.8 13.64 22.57H69.08s.27-6.91-4.16-9.71c-5.65-3.58-6.34-7.97-5.42-15.59M95.96 97.77c2.94 1.65 5.03 8.4 5.03 8.4H61.54s2.09-6.75 5.03-8.4h29.4Z" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0008);
