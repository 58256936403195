import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

import { overlayBorderStyles } from '../styles';

export default createUseStyles(
  {
    container: {
      '&:after': {
        ...overlayBorderStyles(),
      },
    },
    containerMenuOpen: {
      '&:after': {
        opacity: 1,
      },
    },
    cardIconContainer: {
      position: 'absolute',
      top: 75,
      left: 0,
      right: 0,
      transform: 'translate(0, -50%)',
      paddingInline: styling.sizes.context.card.paddingInline,
    },
  },
  { name: 'toolCard' }
);
