import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '@kitted/website-constants';

// import useAuthModal from '../../../../../../hooks/useAuthModal';
import { ModalContentProps } from '../../../../types';

const useLoginCreateButton = (onClose: ModalContentProps['onClose']) => {
  // const { launchRegisterModal } = useAuthModal();
  const navigate = useNavigate();

  // const onCreateAccount = useCallback(() => {
  //   onClose();
  //   launchRegisterModal();
  // }, [onClose, launchRegisterModal]);

  const onJoinBeta = useCallback(() => {
    navigate(routes.betaInvite.path);
    onClose();
  }, [navigate, onClose]);

  return {
    // onCreateAccount,
    onJoinBeta,
  };
};

export default useLoginCreateButton;
