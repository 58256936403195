import { useCallback, useEffect, useRef } from 'react';
import { v4 } from 'uuid';
import { EMPTY_ARR } from '@kitted/shared-utils';

import config from '../../../../../constants/config';
import {
  handleOnExecuteCaptcha,
  handleOnExpiredCallback,
  handleOnMount,
  handleOnVerifyCallback,
} from '../../logic';
import { CaptchaProps, ReCaptcha, ReCaptchaWidgetId } from '../../types';

const useCaptcha = (
  registerExecuteCaptcha: CaptchaProps['registerExecuteCaptcha'],
  onVerify: CaptchaProps['onVerify'],
  onReady: CaptchaProps['onReady']
) => {
  const recaptchaKey = config.keys.gCaptcha;
  const elementID = useRef(`cap-g-${v4()}`);
  const containerRef = useRef<HTMLDivElement>(null);
  const recaptchaRef = useRef<ReCaptcha>();
  const widgetIdRef = useRef<ReCaptchaWidgetId>();

  const setRecaptchaRef = useCallback((ref: ReCaptcha) => {
    recaptchaRef.current = ref;
  }, []);

  const setWidgetId = useCallback((widgetId: ReCaptchaWidgetId) => {
    widgetIdRef.current = widgetId;
  }, []);

  const onExpiredCallback = useCallback(
    () => handleOnExpiredCallback(recaptchaRef?.current, widgetIdRef?.current),
    []
  );

  const onCaptchaReady = useCallback(() => {
    const childIframes =
      containerRef.current?.getElementsByTagName('iframe') || EMPTY_ARR;
    for (let i = 0; i < childIframes.length; i += 1) {
      const childIframe = childIframes[i];
      childIframe?.setAttribute('tabindex', '-1');
    }
    onReady?.();
  }, [onReady]);

  const onVerifyCallback = useCallback(
    (response: string) =>
      handleOnVerifyCallback(
        response,
        onVerify,
        recaptchaRef?.current,
        widgetIdRef?.current
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onExecuteCaptcha = useCallback(
    () => handleOnExecuteCaptcha(recaptchaRef?.current, widgetIdRef?.current),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [recaptchaRef?.current, widgetIdRef?.current]
  );

  useEffect(() => {
    registerExecuteCaptcha(onExecuteCaptcha);
  }, [registerExecuteCaptcha, onExecuteCaptcha]);

  useEffect(() => {
    const onMount = async () => {
      await handleOnMount({
        scriptUrl: 'https://www.google.com/recaptcha/api.js',
        elementID: elementID.current,
        setRecaptchaRef,
        sitekey: recaptchaKey,
        setWidgetId,
        onExpiredCallback,
        onVerifyCallback,
        onReady: onCaptchaReady,
      });
    };
    if (recaptchaKey) {
      onMount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recaptchaKey]);

  return {
    elementID: elementID.current,
    containerRef,
  };
};

export default useCaptcha;
