import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0017: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 110 80"
		height={80}
		width={110}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M55 78.99V17.41M73.02 78.99V17.41M91.05 78.99V17.41M109.07 16.61H.93M109.07 47.8H.93M109.07 32.2H.93M109.07 63.39H.93" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M109.07 78.99H.93V1.01h108.14z" />
    </g>
  </CardIcon>
);

export default memo(Ac0017);
