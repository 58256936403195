import { constructPublicRoute } from '../helpers';

export const authModalRedirectRoutes = {
  authLogin: constructPublicRoute('/account/login', 'Login'),
  // TODO: @haxxxton, put this back when our of beta
  // authRegister: constructPublicRoute('/account/create', 'Create Account'),
  authForgotPassword: constructPublicRoute(
    '/account/forgotPassword',
    'Forgot Password'
  ),
  authResetPassword: constructPublicRoute(
    '/account/resetPassword',
    'Reset Password'
  ),
} as const;
