import { useContext } from 'react';

import { ModalContext } from '../..';

const useModalApi = () => {
  const { registerModal, deregisterModal } = useContext(ModalContext);

  return {
    registerModal,
    deregisterModal,
  };
};

export default useModalApi;
