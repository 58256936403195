import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0047: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 112 114"
		height={114}
		width={112}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="m22.88 76.3 10.64-18.04h44.85l10.67 18.07m4.57 8.68 16.01 27.07H2.38l15.67-27.07h75.57ZM30.09 85.58l-14.88 25.9M49.5 58.61 38.87 76.24M65.53 58.61 54.91 76.2M79.61 62.08l-8.82 14.1M42.69 85.58l-14.88 25.9M55.3 85.58l-14.88 25.9M67.9 85.58l-14.88 25.9M80.51 85.58l-14.88 25.9M92.67 86.47l-14.44 25.01M100.3 96.31l-9.13 15.12" /><g stroke={spot} ><circle vectorEffect="non-scaling-stroke" cx="56" cy="6.33" r="4.4" /><path vectorEffect="non-scaling-stroke" d="m60.83 51.99.42-17.24c1.62-.58 3.55-1.94 3.38-3.64l-1.32-13.12c-.17-1.7-1.71-3.09-3.42-3.09h-7.77c-1.71 0-3.25 1.39-3.42 3.09l-1.32 13.12c-.17 1.7 1.76 3.05 3.38 3.64l.42 17.24" /></g>
    </g>
  </CardIcon>
);

export default memo(In0047);
