import { useMemo } from 'react';

import { useFormData } from '../../../../../contexts/FormContext';
import { getInputProps } from '../../logic';
import { FieldProps } from '../../types';

const useField = ({
  validation,
  customAttributes,
}: {
  validation: FieldProps['validation'];
  customAttributes: FieldProps['customAttributes'];
}) => {
  const { formState } = useFormData();

  const inputProps = useMemo(
    () => getInputProps(formState, validation, customAttributes),
    [formState, validation, customAttributes]
  );

  return {
    inputProps,
  };
};

export default useField;
