import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0052: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 144 50"
		height={50}
		width={144}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><path vectorEffect="non-scaling-stroke" stroke={color} d="m1.49 28.42 26.17-14.93-4.77-8.37c-.57-1.01-.05-1.93.3-2.39.76-.97 2.09-1.55 3.03-1.29L52.2 8.55c1.81.5 2.88 2.38 2.38 4.18l-7.14 25.91c-.27 1-1.4 1.9-2.57 2.05-1.02.13-1.89-.33-2.43-1.29l-4.96-8.71-18.4 10.5M95.63 27.12l20.1 8.59-3.94 9.22c-.43 1.01-.28 1.98.44 2.71.82.84 2.21 1.2 3.18.81l24.96-9.96a3.4 3.4 0 0 0 1.89-4.42l-10.02-25c-.36-.91-1.62-1.62-2.86-1.64-.58 0-1.63.14-2.09 1.21l-3.79 8.87L95.8 5.67" /><circle vectorEffect="non-scaling-stroke" cx="74.53" cy="11.89" r="9.58" stroke={spot} /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="m11.45 34.37 36.31-19.92M93.42 14.8l40.21 17.45" />
    </g>
  </CardIcon>
);

export default memo(In0052);
