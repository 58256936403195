import { z } from 'zod';

import { WebsocketClientMessageType } from './enum';
import { WebsocketClientMessageSchema } from './WebsocketClientMessage';

export const WebsocketAuthenticateClientMessageSchema =
  WebsocketClientMessageSchema.merge(
    z.object({
      messageType: z.literal(WebsocketClientMessageType.Authenticate),
      accessToken: z.string(),
    })
  );

export type WebsocketAuthenticateClientMessage = z.infer<
  typeof WebsocketAuthenticateClientMessageSchema
>;
