import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0026: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 100 118"
		height={118}
		width={100}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M41.68 12.21C31.43 12.21 20.7 20 15.7 29c-3 7-2 15-2 23-1 8-6 13-11 18-1 1-2 4 0 5 3 0 7 0 9 2 7 8 0 19 3 28 8 4 16 0 24-1 2 4 2 8 2.98 11.55M84.62 115.71C79.71 99 86.71 82 93.71 66c5-10 7-21 1.26-32.38" /><path vectorEffect="non-scaling-stroke" d="M15.31 86.7c4.01.2 7.15-.47 9.53-2.24" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m61.15 72.5 5.67-9.35" /><ellipse cx="66.82" cy="32.06" rx="23.69" ry="29.77" /><path vectorEffect="non-scaling-stroke" d="m67.77 63.15 5.67 9.35M61.15 72.5h12.29M67.3 72.5c-2.91 10.03-.6 18.29 3.31 24.78" /></g>
    </g>
  </CardIcon>
);

export default memo(In0026);
