/* eslint-disable react/no-array-index-key */
import { memo } from 'react';
import clsx from 'clsx';

import Typography from '../../Typography';
import useTextSkeleton from './hooks/useTextSkeleton';
import useStyles from './styles';
import { TextSkeletonProps } from './types';

const TextSkeleton = ({
  lines,
  className,
  variant,
  isCentered,
  theme = 'secondary',
}: TextSkeletonProps) => {
  const styles = useStyles();
  const { onSetRef, style } = useTextSkeleton();

  return (
    <Typography
      ref={onSetRef}
      as="div"
      className={clsx(
        className,
        styles.container,
        theme === 'primary' && styles.containerDark
      )}
      variant={variant}
      style={style}
    >
      {[...Array(lines)].map((_, index) => (
        <div
          key={index}
          className={clsx(
            styles.line,
            styles[`line${(index + 1) as TextSkeletonProps['lines']}`],
            isCentered && styles.lineCentered
          )}
        >
          &nbsp;
        </div>
      ))}
    </Typography>
  );
};

export default memo(TextSkeleton);
