import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Bell: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 20 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M15.14 10.92S15.3 7.07 12.02 6c-.41-.13-1.13-.28-2.04-.3-.89.02-1.6.17-2.01.3-3.28 1.07-3.12 4.92-3.12 4.92 0 5.58-3.83 7.57-3.83 7.57v3.47h17.95v-3.47s-3.83-1.98-3.83-7.57ZM10 1.76c1.08 0 1.97.88 1.97 1.97S11.09 5.7 10 5.7s-1.97-.88-1.97-1.97.88-1.97 1.97-1.97ZM12.89 23.79c-.23 1.39-1.43 2.45-2.89 2.45s-2.66-1.06-2.88-2.45" />
    </g>
  </WebsiteIcon>
);

export default memo(Bell);
