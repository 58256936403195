import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0002: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 108 102"
		height={102}
		width={108}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M101.38 7.58c.11-.6-.42 8.38-13.43 6.82l-1.92-.2c-8.93-1.5-18.69-1.55-31.97 1.46l-.11.03c-13.28 3.01-22.07 7.25-29.48 12.46l-1.65 1.01c-11.07 7.01-15.41-.86-15.06-.37M54 75.62V15.65" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M106.29 46.42c0 6.15-6.93 11.13-15.49 11.13s-15.49-4.98-15.49-11.13S90.8 14.81 90.8 14.81s15.49 25.47 15.49 31.61M76.78 43.84h28.04" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M32.69 63.68c0 6.15-6.93 11.13-15.49 11.13S1.71 69.83 1.71 63.68 17.2 32.07 17.2 32.07s15.49 25.47 15.49 31.61M3.18 61.1h28.04" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M55.49 3.26c0 .83-.66 1.49-1.49 1.49s-1.49-.67-1.49-1.49.67-1.49 1.49-1.49 1.49.67 1.49 1.49M54.03 84.4c-12.06 0-22.64 6.32-28.61 15.83l57.16-.1C76.6 90.67 66.05 84.4 54.03 84.4" />
    </g>
  </CardIcon>
);

export default memo(Sn0002);
