import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    modal: {
      cursor: 'default',
      minWidth: styling.sizes.context.columnW,
      position: 'relative',
      maxWidth: '100%',
      margin: 'auto',
      background: styling.colors.context.background,
      color: styling.colors.context.color,
      boxSizing: 'border-box',
      border: [1, 'solid', styling.colors.context.inverted.background],
    },
    closeButton: {
      ...styling.utils.buttonReset(),
      position: 'absolute',
      top: 32,
      right: 32,
    },
    image: {
      // * 2 because of the 0.5
      maxHeight: `calc(var(--lightboxImageHeight, ${
        styling.sizes.context.card.height * 2
      }) * 0.5px)`,
      maxWidth: `calc(var(--lightboxImageWidth, ${
        styling.sizes.context.card.width * 2
      }) * 0.5px)`,
      minHeight: styling.sizes.context.card.height / 2,
      width: '100%',
      cursor: 'zoom-in',
      transition: styling.transitions.utils.generate(
        ['maxHeight'],
        ['maxWidth']
      ),
      '& > *': {
        ...styling.utils.absoluteFull(),
      },
    },
    zoomedImage: {
      cursor: 'zoom-out',
      maxHeight: `calc(var(--lightboxImageHeight, ${
        styling.sizes.context.card.height * 2
      }) * 1px)`,
      maxWidth: `calc(var(--lightboxImageWidth, ${
        styling.sizes.context.card.width * 2
      }) * 1px)`,
    },
    video: {
      width: `min(100%, calc(100vh - ${styling.utils.px(
        styling.sizes.context.gutteringW * 2 + 2
      )}) * (16 / 9))`,
      [styling.breakpoints.context.tabletUpString]: {
        width: `min(100%, calc(100vh - ${styling.utils.px(
          styling.sizes.context.gutteringW + 100 + 2
        )}) * (16 / 9))`,
      },
      '& > *': {
        margin: 0,
      },
    },
  },
  { name: 'lightboxModalContent' }
);
