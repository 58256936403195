import { createUseStyles } from 'react-jss';

import { overlayBorderStyles, overlayStyles } from '../styles';

export default createUseStyles(
  {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      '&:before': {
        ...overlayStyles(),
      },
      '&:after': {
        ...overlayBorderStyles(),
      },
    },
    containerActive: {
      '&:before': {
        opacity: 1,
      },
    },
    containerFeatured: {
      '&:before, &:after': {
        content: 'unset',
      },
    },
    containerMenuOpen: {
      '&:before, &:after': {
        opacity: 1,
      },
    },
  },
  { name: 'playCard' }
);
