import { z } from 'zod';

import { makeKittedModelReferenceSchema } from '../base';
import { ImageSize } from './Enum/ImageSize';
import { AssetBaseSchema } from './AssetBase';

export const ImageAssetModelDimensionsSchema = z.tuple([
  z.number(),
  z.number(),
]);
export type ImageAssetModelDimensions = z.infer<
  typeof ImageAssetModelDimensionsSchema
>;

export const ImageAssetModelDetailsSchema = z.object({
  dimensions: ImageAssetModelDimensionsSchema,
  blobGuid: z.string(),
});
export type ImageAssetModelDetails = z.infer<
  typeof ImageAssetModelDetailsSchema
>;

export const ImageAssetModelSizeInfoSchema = z.object(
  Object.values(ImageSize).reduce(
    (acc, size) => ({
      ...acc,
      [size]: ImageAssetModelDetailsSchema,
    }),
    {} as { [key in ImageSize]: typeof ImageAssetModelDetailsSchema }
  )
);
export type ImageAssetModelSizeInfo = z.infer<
  typeof ImageAssetModelSizeInfoSchema
>;

export const ImageAssetModelSchema = AssetBaseSchema.merge(
  z.object({
    sizes: ImageAssetModelSizeInfoSchema,
  })
);
export const ImageAssetModelReferenceSchema = makeKittedModelReferenceSchema(
  ImageAssetModelSchema,
  false
);

export type ImageAssetModel = z.infer<typeof ImageAssetModelSchema>;
export type ImageAssetReference = z.infer<
  typeof ImageAssetModelReferenceSchema
>;
