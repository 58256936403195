import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // Full viewport height
      backgroundColor: styling.colors.theme.primary1000,
    },
    iconContainer: {
      borderRadius: '50%',
      background: styling.colors.theme.secondary300,
      color: styling.colors.theme.primary900,
      width: 40,
      height: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    uploadBox: {
      display: 'flex',
      flexDirection: 'column', // Stack children vertically
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',

      maxWidth: '80vw',
      width: 816,
      height: 194,
      backgroundColor: styling.colors.theme.primary900,
      borderRadius: 8, // Rounded corners
      border: [1, 'dashed', styling.colors.theme.primary400],
      transition: styling.transitions.utils.generate(['background-color']),
      paddingInline: styling.sizes.theme.spacing.xs,
    },
    cursorAnimations: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: styling.colors.theme.primary700,
      },
    },
    accepted: {
      backgroundColor: styling.colors.theme.insight500_10,
      boxShadow: styling.shadows.utils.boxShadowToString({
        inset: 'inset',
        x: 0,
        y: 0,
        blur: 0,
        spread: 2,
        color: styling.colors.theme.insight500,
      }),
    },
    rejected: {
      backgroundColor: styling.colors.theme.action500_10,
      boxShadow: styling.shadows.utils.boxShadowToString({
        inset: 'inset',
        x: 0,
        y: 0,
        blur: 0,
        spread: 3,
        color: styling.colors.theme.error,
      }),
    },
  },
  { name: 'catalogueUpload' }
);
