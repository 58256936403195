import { memo } from 'react';

import useStyles from './styles';
import { PanelFooterProps } from './types';

const PanelFooter = ({ children }: PanelFooterProps) => {
  const styles = useStyles();

  return <div className={styles.container}>{children}</div>;
};

export default memo(PanelFooter);
