import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0012: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 104 106"
		height={106}
		width={104}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="M39.1 82.46H4.83" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m61.83 44.78 38.4 33.81c3.63 3.2 3.8 8.61.38 12.03l-1.46 1.46c-3.42 3.42-8.83 3.25-12.03-.38L53.3 53.31M22.678 38l23.158-23.158 16.85 16.85L39.528 54.85zM46.01 14.98 47 8.09l6.43-6.44 22.38 22.38-6.43 6.44-6.9.99" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M.93 104.35V93.1h42.06v11.25" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m22.79 38.19-6.89.99-6.43 6.44L31.85 68l6.44-6.44.98-6.89" />
    </g>
  </CardIcon>
);

export default memo(Sn0012);
