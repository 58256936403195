import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0033: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 104 94"
		height={94}
		width={104}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M39.61 76.23h54.66c4.54 0 8.25 3.71 8.25 8.26v.23c0 4.54-3.72 8.25-8.25 8.25H18.89c-4.54 0-8.25-3.72-8.25-8.25v-.23c0-4.54 3.72-8.26 8.25-8.26h7.86M26.11 76.23c-4.54 0-8.25-3.71-8.25-8.26v-.23c0-4.54 3.72-8.26 8.25-8.26h60.94c4.54 0 8.25 3.72 8.25 8.26v.23c0 .93-.16 1.82-.44 2.65" /><path vectorEffect="non-scaling-stroke" d="M57.14 59.49c-6.14 0-21.05.12-24.83-22.13 0 0-4.01-27.1 15.13-34.67 0 0 16.85-5.84 24.35 8.62 0 0 2.04 7.18-12.86 7.07l-14.83-.74" /><path vectorEffect="non-scaling-stroke" d="M48.92 18.78c-3.82 5.62-2.89 10.85-2.89 10.85 1.81 13.8 16.36 13.13 24.35 13.13h8.36c4.54 0 8.25 3.72 8.25 8.25v.23c0 .62-.07 1.23-.2 1.81M72.23 12.84h8.3M84.6 9.07l-4.35 3.77M80.25 12.84l4.35 3.77M10.66 83.66C-6.23 71.27 5.33 55.81 5.33 55.81s.22 9.53 6.6 12.91" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M57.06 9.01a.939.939 0 1 1-1.88 0 .939.939 0 1 1 1.88 0" />
    </g>
  </CardIcon>
);

export default memo(Sn0033);
