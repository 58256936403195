import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0041: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 134 116"
		height={116}
		width={134}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="m35.06 81.25 16.43-14.46M60.94 57.91l17.42-14.29M88.3 34.13l15.05-13.61" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M132.04 101.18H15.19V1.83M1.96 27.92h13.11M1.96 57.66h13.11M1.96 87.41h13.11M29.02 114.17v-13.11M57.3 114.17v-13.11M85.59 114.17v-13.11M113.86 114.17v-13.11" /></g><circle vectorEffect="non-scaling-stroke" cx="82.88" cy="38.58" r="6.69" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="107.96" cy="15.16" r="6.57" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="30.36" cy="85.16" r="6.12" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="56.18" cy="62.11" r="6.63" stroke={spot} />
    </g>
  </CardIcon>
);

export default memo(In0041);
