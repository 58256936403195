import { useMemo } from 'react';
import {
  getInteractionProps,
  InteractionProps,
} from '@kitted/shared-components';

import ExternalLinkWithCapturedQueryParams from '../../../../../ExternalLinkWithCapturedQueryParams';
import PreloadingInternalLink from '../../../../../PreloadingInternalLink';
import { InputSelectOptionProps } from '../../types';

const useInputSelectOption = (
  option: InputSelectOptionProps['option'],
  isSelected: InputSelectOptionProps['isSelected'],
  onClick: InputSelectOptionProps['onClick'],
  disabled: InputSelectOptionProps['disabled'] = false
) => {
  const { to, href } = option as {
    to?: string;
    href?: string;
  };
  const { as: Component, ...interactionProps } = useMemo(
    () =>
      getInteractionProps(
        {
          nonInteractive: isSelected || disabled,
          to,
          href,
          onClick,
        } as InteractionProps,
        {
          defaultComponent: 'div',
          internalLinkComponent: PreloadingInternalLink,
          externalLinkComponent: ExternalLinkWithCapturedQueryParams,
        }
      ),
    [to, href, onClick, disabled, isSelected]
  );

  return {
    Component,
    interactionProps,
  };
};

export default useInputSelectOption;
