import { useCallback, useRef, useState } from 'react';

function useResizeObserver(attributeKey: 'width'): {
  element: HTMLDivElement | null;
  onSetRef: (newElement: HTMLDivElement | null) => void;
  width: number;
};

function useResizeObserver(attributeKey: 'height'): {
  element: HTMLDivElement | null;
  onSetRef: (newElement: HTMLDivElement | null) => void;
  height: number;
};

function useResizeObserver(attributeKey: 'both'): {
  element: HTMLDivElement | null;
  onSetRef: (newElement: HTMLDivElement | null) => void;
  width: number;
  height: number;
};

function useResizeObserver(attributeKey: 'width' | 'height' | 'both') {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const onElementResize = useCallback(
    (contentRect: DOMRectReadOnly) => {
      setWidth(contentRect.width);
      setHeight(contentRect.height);
    },
    [setWidth, setHeight]
  );
  const onResizeObserve = useCallback(
    (entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        onElementResize(entry.contentRect);
      }
    },
    [onElementResize]
  );
  const observerRef = useRef<ResizeObserver>(
    new ResizeObserver(onResizeObserve)
  );
  const elementRef = useRef<HTMLDivElement | null>(null);

  const onSetRef = useCallback((newElement: HTMLDivElement | null) => {
    if (elementRef.current === newElement) return;
    if (elementRef.current) {
      observerRef.current.unobserve(elementRef.current);
    }
    if (newElement) {
      observerRef.current.observe(newElement);
    }
    elementRef.current = newElement;
  }, []);

  if (attributeKey === 'both') {
    return {
      element: elementRef.current,
      onSetRef,
      width,
      height,
    };
  }
  if (attributeKey === 'width') {
    return {
      element: elementRef.current,
      onSetRef,
      width,
    };
  }
  return {
    element: elementRef.current,
    onSetRef,
    height,
  };
}

export default useResizeObserver;
