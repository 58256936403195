import { useMemo } from 'react';

import { calculateStyles } from '../../logic';
import { FormGridProps } from '../../types';

const useFormGrid = (
  item: FormGridProps['item'],
  widths: FormGridProps['widths']
) => {
  const style = useMemo(() => calculateStyles(item, widths), [item, widths]);

  return {
    style,
  };
};

export default useFormGrid;
