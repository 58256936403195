import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useDrawerSpaceContainerApi } from '../../../../../components/DrawerSpace/context/DrawerSpaceContainerContext';
import useMediaQuery from '../../../../../contexts/MediaQueryManagementContext/hooks/useMediaQuery';

const useMainMenuDrawer = () => {
  const { onClose } = useDrawerSpaceContainerApi();
  const isWindowDesktop = useMediaQuery('isDesktop');
  const location = useLocation();

  const onLocationChangeCloseBelowDesktopDrawer = useCallback(() => {
    if (!isWindowDesktop) {
      onClose();
    }
  }, [onClose, isWindowDesktop]);

  useEffect(onLocationChangeCloseBelowDesktopDrawer, [
    onLocationChangeCloseBelowDesktopDrawer,
    location.pathname,
  ]);
};

export default useMainMenuDrawer;
