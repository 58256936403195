import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      position: 'relative',
      minHeight: 57,
      display: 'flex',
      flexDirection: 'column',
    },
    'variant-naked': {
      minHeight: 48,
      background: styling.colors.theme.transparent,
      border: 'none',
      fontWeight: styling.fonts.theme.atlasGrotesk.weights.medium,
      marginBottom: 20,
    },
    'variant-hollow': {
      minHeight: 48,
      borderRadius: 48,
      background: styling.colors.theme.transparent,
      border: 'none',
      fontWeight: styling.fonts.theme.atlasGrotesk.weights.medium,
      transition: styling.transitions.utils.generate(['background'], ['color']),
    },
    'variant-solid': {
      borderRadius: styling.sizes.context.cardFeature.radius,
      marginBottom: 20,
      border: 'none',
      transition: styling.transitions.utils.generate(
        ['background'],
        ['color'],
        ['box-shadow']
      ),
    },
    'variant-solidFullRound': {
      minHeight: 72,
      borderRadius: '100rem',
      marginBottom: 20,
      border: 'none',
      transition: styling.transitions.utils.generate(
        ['background'],
        ['color'],
        ['box-shadow']
      ),
    },
    'theme-primary': {
      '&$variant-hollow': {
        color: styling.colors.theme.primary500,
        boxShadow: styling.shadows.utils.boxShadowToString({
          inset: 'inset',
          x: 0,
          y: 0,
          blur: 0,
          spread: 1,
          color: styling.colors.theme.primary500,
        }),
        '&:hover:not($disabled)': {
          background: styling.colors.theme.secondary100,
        },
        '&:hover:not($disabled), &$focused': {
          boxShadow: styling.shadows.utils.boxShadowToString({
            inset: 'inset',
            x: 0,
            y: 0,
            blur: 0,
            spread: 1,
            color: styling.colors.theme.primary500,
          }),
          color: styling.colors.theme.primary900,
          '&:not($hasValue) $iconLeft, &:not($hasValue) $iconRight': {
            color: styling.colors.theme.primary200,
          },
        },
        '&$hasValue': {
          boxShadow: styling.shadows.utils.boxShadowToString({
            inset: 'inset',
            x: 0,
            y: 0,
            blur: 0,
            spread: 1,
            color: styling.colors.theme.primary500,
          }),
          color: styling.colors.theme.primary900,
        },
      },
      '&$variant-solid, &$variant-solidFullRound': {
        color: styling.colors.theme.secondary,
        background: styling.colors.theme.primary800,
        boxShadow: styling.shadows.utils.boxShadowToString({
          inset: 'inset',
          x: 0,
          y: 0,
          blur: 0,
          spread: 1,
          color: styling.colors.theme.secondary300_20,
        }),
        '&:hover:not($disabled):not($focused):not($errored)': {
          boxShadow: styling.shadows.utils.boxShadowToString({
            inset: 'inset',
            x: 0,
            y: 0,
            blur: 0,
            spread: 1,
            color: styling.colors.theme.secondary,
          }),
        },
        '&$focused:not($errored)': {
          boxShadow: styling.shadows.utils.boxShadowToString({
            inset: 'inset',
            x: 0,
            y: 0,
            blur: 0,
            spread: 2,
            color: styling.colors.theme.secondary,
          }),
        },
        '&$errored': {
          boxShadow: styling.shadows.utils.boxShadowToString({
            inset: 'inset',
            x: 0,
            y: 0,
            blur: 0,
            spread: 3,
            color: styling.colors.theme.error,
          }),
        },
        '&$disabled': {
          ...styling.utils.disabled(styling.colors.theme.secondary),
          backgroundColor: styling.colors.theme.primary300,
          boxShadow: 'none',
          opacity: 0.5,
        },
      },
    },
    'theme-secondary': {
      '&$variant-hollow': {
        color: styling.colors.theme.primary200,
        boxShadow: styling.shadows.utils.boxShadowToString({
          inset: 'inset',
          x: 0,
          y: 0,
          blur: 0,
          spread: 1,
          color: styling.colors.context.filterInputBorder,
        }),
        '&:hover:not($disabled)': {
          background: styling.colors.context.filterInputBackground,
        },
        '&:hover:not($disabled), &$focused': {
          boxShadow: styling.shadows.utils.boxShadowToString({
            inset: 'inset',
            x: 0,
            y: 0,
            blur: 0,
            spread: 1,
            color: styling.colors.context.filterInputBorder,
          }),
          color: styling.colors.context.color,
          '&:not($hasValue) $iconLeft, &:not($hasValue) $iconRight': {
            color: styling.colors.theme.primary200,
          },
        },
        '&$hasValue': {
          boxShadow: styling.shadows.utils.boxShadowToString({
            inset: 'inset',
            x: 0,
            y: 0,
            blur: 0,
            spread: 1,
            color: styling.colors.context.filterInputBorder,
          }),
          color: styling.colors.context.color,
        },
      },
      '&$variant-solid, &$variant-solidFullRound': {
        color: styling.colors.theme.primary900,
        background: styling.colors.theme.pureWhite,
        boxShadow: styling.shadows.utils.boxShadowToString({
          inset: 'inset',
          x: 0,
          y: 0,
          blur: 0,
          spread: 1,
          color: styling.colors.theme.primary900_20,
        }),
        '&:hover:not($disabled):not($focused):not($errored)': {
          boxShadow: styling.shadows.utils.boxShadowToString({
            inset: 'inset',
            x: 0,
            y: 0,
            blur: 0,
            spread: 1,
            color: styling.colors.theme.primary900,
          }),
        },
        '&$focused:not($errored)': {
          boxShadow: styling.shadows.utils.boxShadowToString({
            inset: 'inset',
            x: 0,
            y: 0,
            blur: 0,
            spread: 2,
            color: styling.colors.theme.primary900,
          }),
        },
        '&$errored': {
          boxShadow: styling.shadows.utils.boxShadowToString({
            inset: 'inset',
            x: 0,
            y: 0,
            blur: 0,
            spread: 3,
            color: styling.colors.theme.error,
          }),
        },
        '&$disabled': {
          ...styling.utils.disabled(styling.colors.theme.primary900),
          backgroundColor: styling.colors.theme.primaryA300,
          boxShadow: 'none',
          opacity: 0.5,
        },
      },
    },
    focused: {},
    errored: {},
    hasValue: {},
    disabled: {
      '& $errors, & $helperText': {
        opacity: 0.5,
      },
    },
    input: {
      position: 'relative',
      flexGrow: 1,
      width: '100%',
      display: 'flex',
    },
    iconLeft: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      pointerEvents: 'none',
      fontSize: '2.4rem',
      left: 21,
      top: 0,
      bottom: 0,
      maxWidth: 24,
      transition: styling.transitions.utils.generate(['color']),
    },
    iconRight: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      pointerEvents: 'none',
      fontSize: '2.4rem',
      right: 21,
      top: 0,
      bottom: 0,
      maxWidth: 24,
      transition: styling.transitions.utils.generate(['color']),
    },
    errors: {
      color: styling.colors.theme.error,
      position: 'absolute',
      bottom: -23,
      left: 0,
      right: 0,
      textAlign: 'left',
    },
    helperText: {
      color: styling.colors.theme.primary200,
      position: 'absolute',
      bottom: -23,
      left: 0,
      right: 0,
      textAlign: 'right',
    },
  },
  { name: 'inputWrapper' }
);
