import { EMPTY_ARR } from '@kitted/shared-utils';

import {
  IntervalCallback,
  IntervalCallbackStoreId,
  IntervalId,
  Intervals,
  IntervalStore,
  IntervalStoreUpdater,
} from './types';

export const createInitialIntervalStore = (
  intervals: Intervals
): IntervalStore =>
  intervals.reduce(
    (acc, interval) => ({ ...acc, [interval]: EMPTY_ARR }),
    {} as IntervalStore
  );

export const handleRemoveIntervalCallback =
  (
    setIntervalStore: (storeUpdater: IntervalStoreUpdater) => void,
    intervalId: IntervalId,
    intervalCallbackId: IntervalCallbackStoreId
  ) =>
  () => {
    setIntervalStore(
      (intervalStore: IntervalStore) =>
        ({
          ...intervalStore,
          [intervalId]: intervalStore[intervalId].filter(
            (interval) => interval.id !== intervalCallbackId
          ),
        }) as IntervalStore
    );
  };

export const handleAddInternalCallback = ({
  setIntervalStore,
  intervalId,
  intervalCallbackId,
  intervalCallback,
  removeIntervalCallback,
}: {
  setIntervalStore: (storeUpdater: IntervalStoreUpdater) => void;
  intervalId: IntervalId;
  intervalCallbackId: IntervalCallbackStoreId;
  intervalCallback: IntervalCallback;
  removeIntervalCallback: (
    intervalId: IntervalId,
    intervalCallbackId: IntervalCallbackStoreId
  ) => () => void;
}) => {
  setIntervalStore((intervalStore: IntervalStore) => {
    if (!intervalStore[intervalId]) {
      throw new Error(
        `[Intervals] No interval store exists for key: ${intervalId}`
      );
    }
    return {
      ...intervalStore,
      [intervalId]: [
        ...intervalStore[intervalId],
        {
          id: intervalCallbackId,
          callback: intervalCallback,
        },
      ],
    };
  });
  return removeIntervalCallback(intervalId, intervalCallbackId);
};
