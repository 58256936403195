import { useCallback, useMemo, useState } from 'react';

import { ImageAssetModelDimensions, NaturalImageProps } from '../../types';
import { getAspectRatio, handleOnNaturalImageLoad } from './logic';

const useNaturalImage = (onLoad: NaturalImageProps['onLoad']) => {
  const [imgDimensions, setImageDimensions] =
    useState<ImageAssetModelDimensions>({
      height: 0,
      width: 0,
    });

  const onNaturalImageLoad = useCallback(
    (img: HTMLImageElement, isFallback: boolean) =>
      handleOnNaturalImageLoad(setImageDimensions, img, isFallback, onLoad),
    [onLoad]
  );

  const aspectRatio = useMemo(
    () => getAspectRatio(imgDimensions),
    [imgDimensions]
  );

  return {
    onNaturalImageLoad,
    aspectRatio,
  };
};

export default useNaturalImage;
