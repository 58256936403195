import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0044: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 124 102"
		height={102}
		width={124}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="m85.86 66.24-1.2 27.98 2.72 6.49H65.64s-.88-23.54-5.84-36.38" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M32.6 74.25c3.5 2.63 14.78 7.01 30.06 2.92" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M40.47 66.26c-3.02 4-6.42 8.79-18.49 12.61l-2.49 15.82 1.17 6.03H1.02l1.22-15.09c.27-3.29.4-6.58.4-9.88 0-6.17-1.55-25.46-1.55-28.63 0-10.19 5.08-17.56 11.75-21.93 16.5-10.8 36.57-10.53 49.01-8.92" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M93.81 87.45c0-5.86 4.75-10.6 10.6-10.6M104.42 98.06c-5.86 0-10.6-4.75-10.6-10.6" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M117.67 84.23c0 5.15-3.83 13.83-13.26 13.83M117.67 66.04v18.19M117.67 39.73v16.24M87.19 50.34s-19.11-4.46-19.11-27.32c0-20.56 9.21-21.73 12.94-21.73h0c3.25 0 8.11 1.64 11.3 4.09 2.47 1.9 5.38 3.18 8.39 3.99 12.54 3.37 16.96 18.14 16.97 30.36M97.79 55.64c1.73 1.2 12.56 9.93 25.19 10.6" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M52.61 31.08c1.5.63 2.85 1.55 3.98 2.69a12.7 12.7 0 0 1 2.69 3.98M36.19 37.75c.63-1.5 1.55-2.85 2.69-3.98a12.7 12.7 0 0 1 3.98-2.69M42.86 54.17c-1.5-.63-2.85-1.55-3.98-2.69a12.7 12.7 0 0 1-2.69-3.98M59.28 47.5c-.63 1.5-1.55 2.85-2.69 3.98a12.7 12.7 0 0 1-3.98 2.69M47.63 28.39v9.36M47.63 47.5v9.36M61.87 42.62h-9.36M42.75 42.62h-9.36M47.74 42.62" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0044);
