import { cancelableRequestFactory } from '../cancelableRequests';
import { RequestConfig, RequestPerformer } from '../types';
import { getAuthConfig } from './logic';

export const authorizedRequestFactory = (
  getBearerToken: () => string | undefined
): RequestPerformer => {
  const cancelableRequest = cancelableRequestFactory();

  const authorizedRequest = async <ResponseType>(config: RequestConfig) => {
    const token = getBearerToken();
    const authConfig = getAuthConfig(config, token);

    const response = await cancelableRequest<ResponseType>(authConfig);

    return response;
  };

  return authorizedRequest;
};
