/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';

import useInputSelectOption from './hooks/useInputSelectOption';
import useStyles from './styles';
import { InputSelectOptionProps } from './types';

const InputSelectOption = ({
  onClick,
  option,
  disabled,
  isSelected,
}: InputSelectOptionProps) => {
  const styles = useStyles();
  const { Component, interactionProps } = useInputSelectOption(
    option,
    isSelected,
    onClick,
    disabled
  );
  const Icon = option?.icon as React.ElementType;

  return (
    <Component
      className={clsx(
        styles.container,
        Icon && styles.containerWithIcon,
        isSelected && styles.containerIsSelected
      )}
      {...interactionProps}
    >
      {Icon && (
        <span className={styles.icon}>
          <Icon size="md" />
        </span>
      )}
      <span>{option.title}</span>
    </Component>
  );
};

export default memo(InputSelectOption);
