import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0060: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 126 94"
		height={94}
		width={126}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M67.11 24.8c-1.67 0-3.03-1.36-3.03-3.03s1.36-3.03 3.03-3.03 3.03 1.36 3.03 3.03-1.36 3.03-3.03 3.03" /><circle vectorEffect="non-scaling-stroke" cx="107.28" cy="21.78" r="3.03" stroke={color} /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M88.86 62.13 107 52.86c3.51-1.88 7.62-1.13 9.32 2.03 1.69 3.16.21 7.28-3.3 9.16L75.38 84.23l-1.99.5-35.1.95s-4.52 1.27-9.95 7.42" /><path vectorEffect="non-scaling-stroke" d="m15.85 74.63 15.92-14.66c7.78-5.25 17.01-2.35 17.01-2.35 4.47 1.18 12.77 1.44 12.77 1.44h13.78c4.1 0 7.46 2.93 7.46 6.51s-3.36 6.51-7.46 6.51H56.27M50.62.91h73.77v41.86H50.62z" /><path vectorEffect="non-scaling-stroke" d="M98.03 34.55h11.71c0-3.37 2.73-6.09 6.09-6.09V15.22c-3.37 0-6.09-2.73-6.09-6.09H98.03M77.21 9.13H65.26c0 3.37-2.73 6.09-6.09 6.09v13.24c3.37 0 6.09 2.73 6.09 6.09h11.95" /><ellipse cx="87.5" cy="21.84" rx="8.19" ry="11.44" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M45.35 9.13H25M45.35 17.6H9.48M45.35 26.08H29.83M1.24 34.55h44.11" />
    </g>
  </CardIcon>
);

export default memo(Fr0060);
