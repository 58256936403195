import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Toolbox: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 22"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M10.07 4.74V2.99c0-.66.54-1.2 1.2-1.2h5.47c.66 0 1.2.54 1.2 1.2v1.75M26.62 13.75V19c0 .66-.54 1.2-1.2 1.2H2.58c-.66 0-1.2-.54-1.2-1.2v-5.25M12.63 12.08H1.38V7.6c0-.66.54-1.2 1.2-1.2h22.84c.66 0 1.2.54 1.2 1.2v4.48H15.37" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M12.63 10.23h2.75v3.71h-2.75z" />
    </g>
  </WebsiteIcon>
);

export default memo(Toolbox);
