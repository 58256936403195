import { useCallback } from 'react';
import { AuthenticateUserChallenge } from 'quidproquo';
import { removeEmptyFromRecord } from '@kitted/shared-utils';

import { AuthChallengeModalMeta } from '../../components/Modal/AuthChallengeModalContent/types';
import { AuthRegisterModalMeta } from '../../components/Modal/AuthCreateUserModalContent/types';
import { FieldValue } from '../../contexts/FormContext';
import useModalApi from '../../contexts/ModalContext/hooks/useModalApi';
import { getTitleFromChallenge } from './logic';

const useAuthModal = () => {
  const { registerModal } = useModalApi();

  const launchLoginModal = useCallback(
    (title = 'Welcome back') => {
      registerModal('loginUser', {
        title,
        variant: 'authLogin',
      });
    },
    [registerModal]
  );

  const launchRegisterModal = useCallback(
    (meta?: AuthRegisterModalMeta, title = 'Create free account') => {
      registerModal('createUser', {
        title,
        variant: 'authRegister',
        meta,
      });
    },
    [registerModal]
  );

  const launchVerifyEmailModal = useCallback(() => {
    registerModal('verifyUser', {
      title: 'Verify Email',
      variant: 'authVerify',
    });
  }, [registerModal]);

  const launchForgotPasswordModal = useCallback(() => {
    registerModal('forgotPassword', {
      title: 'Forgot your password',
      variant: 'authForgotPassword',
    });
  }, [registerModal]);

  const launchChangePasswordModal = useCallback(
    (email?: string, code?: string) => {
      const initialFormData = removeEmptyFromRecord<FieldValue>({
        email,
        code,
      });
      registerModal('changePassword', {
        title: 'Change Password',
        variant: 'authChangePassword',
        meta: {
          initialFormData,
        },
      });
    },
    [registerModal]
  );

  const launchChallengeModal = useCallback(
    (meta: AuthChallengeModalMeta) => {
      const title = getTitleFromChallenge(
        meta.challenge as AuthenticateUserChallenge
      );
      registerModal('authChallenge', {
        title,
        variant: 'authChallenge',
        meta,
      });
    },
    [registerModal]
  );

  return {
    launchLoginModal,
    launchRegisterModal,
    launchVerifyEmailModal,
    launchForgotPasswordModal,
    launchChangePasswordModal,
    launchChallengeModal,
  };
};

export default useAuthModal;
