import { Envelope } from '@kitted/website-icons';

import { FieldTypes, FormDataSchema } from '../../../contexts/FormContext';
import {
  hasMaxLength,
  isRequired,
  isValidEmail,
} from '../../../services/helpers/validators';
import { FormLayoutSchema } from '../../FormFields/types';

export const dataSchema: FormDataSchema = {
  fields: {
    email: {
      type: FieldTypes.EMAIL,
      validation: [isValidEmail(true), hasMaxLength(128)],
    },
    password: {
      type: FieldTypes.PASSWORD,
      validation: [isRequired],
    },
  },
  form: {
    hasCaptcha: true,
    validationStrategy: 'onBlur',
  },
};

export const layoutSchema: FormLayoutSchema = {
  fields: [
    {
      key: 'email',
      label: 'Email address',
      meta: {
        placeholder: 'your@email.com',
      },
      customAttributes: {
        autoComplete: 'username',
        iconLeft: Envelope,
      },
    },
    {
      key: 'password',
      label: 'Password',
      meta: {
        canShowHide: true,
      },
      customAttributes: {
        autoComplete: 'current-password',
      },
    },
  ],
};
