import { createContext, useMemo } from 'react';
import { EMPTY_ARR, NOOP } from '@kitted/shared-utils';

import { ModalContextProps } from '../types';
import useModalManagement from './hooks/useModalManagement';
import { ModalContextApi, ModalData } from './types';

export const ModalContext = createContext<ModalContextApi>({
  registerModal: NOOP,
  deregisterModal: NOOP,
});

export const ModalDataContext = createContext<ModalData>({
  modals: EMPTY_ARR,
});

export const ModalProvider: React.FC<ModalContextProps> = ({ children }) => {
  const { modals, registerModal, deregisterModal } = useModalManagement();
  const apiValue = useMemo(
    () => ({ registerModal, deregisterModal }),
    [registerModal, deregisterModal]
  );
  const dataValue = useMemo(() => ({ modals }), [modals]);

  return (
    <ModalContext.Provider value={apiValue}>
      <ModalDataContext.Provider value={dataValue}>
        {children}
      </ModalDataContext.Provider>
    </ModalContext.Provider>
  );
};
