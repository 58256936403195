import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const QuotesDoubleLeft: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 20"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" fill="#161d33" d="M13.88 2.82c-.78.34-1.67.81-2.67 1.4s-1.79 1.16-2.39 1.7c-.68.62-1.3 1.31-1.85 2.08q-.825 1.155-1.29 2.64h1.43c1.48 0 2.59.28 3.32.85s1.1 1.35 1.1 2.36c0 1.3-.55 2.48-1.64 3.55S7.5 19 5.99 19q-2.385 0-3.45-1.29c-.71-.86-1.06-1.98-1.06-3.35 0-2.68 1.13-5.26 3.4-7.72C7.14 4.18 9.91 2.3 13.18 1l.72 1.82Zm12.64 0c-.78.34-1.67.81-2.67 1.4s-1.79 1.16-2.39 1.7c-.68.62-1.3 1.31-1.85 2.08q-.825 1.155-1.29 2.64h1.43c1.48 0 2.59.28 3.32.85s1.1 1.35 1.1 2.36c0 1.3-.55 2.48-1.64 3.55s-2.39 1.6-3.9 1.6q-2.385 0-3.45-1.29c-.71-.86-1.06-1.98-1.06-3.35 0-2.68 1.13-5.26 3.4-7.72 2.26-2.46 5.03-4.34 8.3-5.64l.72 1.82Z" />
    </g>
  </WebsiteIcon>
);

export default memo(QuotesDoubleLeft);
