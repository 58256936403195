import { memo } from 'react';
import clsx from 'clsx';
import { routes } from '@kitted/website-constants';
import { CirclePlus } from '@kitted/website-icons';

import MainMenuItem from '../../MainMenuItem';
import useAccountMenuItem from './hooks/useAccountMenuItem';
import AccountMenuItemBody from './AccountMenuItemBody';
import AccountMenuItemIcon from './AccountMenuItemIcon';
import useStyles from './styles';

const AccountMenuItem = () => {
  const styles = useStyles();
  const { isLoggedInOrLoggingIn } = useAccountMenuItem();

  if (isLoggedInOrLoggingIn) {
    return (
      <MainMenuItem
        className={clsx(
          styles.container,
          isLoggedInOrLoggingIn && styles.loggedOrLogging
        )}
        path={routes.account.path}
        title={routes.account.title}
        icon={AccountMenuItemIcon}
      >
        <AccountMenuItemBody path={routes.account.path} />
      </MainMenuItem>
    );
  }

  return (
    <MainMenuItem
      path={routes.betaInvite.path}
      target={routes.betaInvite.target}
      title="Join the Beta"
      icon={CirclePlus}
      preinterpolatedRoutePath={routes.betaInvite.preinterpolatedRoutePath}
      highlightSubroutes
    >
      Join the Beta
    </MainMenuItem>
  );
};

export default memo(AccountMenuItem);
