import { useCallback, useMemo, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import {
  ACCEPTED_IMAGE_FILES,
  MAX_FILE_SIZE,
} from '../../../../../constants/inputs';
import useBlobStorage from '../../../../../hooks/useBlobStorage';
import {
  useAssetCatalogueApi,
  useAssetCatalogueData,
} from '../../../contexts/AssetCatalogueProvider';
import { AssetCatalogueModalSpaceProps } from '../../../types';

const useUploadBlobFromCatalogue = (
  maxFileSize: AssetCatalogueModalSpaceProps['maxFileSize'] = MAX_FILE_SIZE,
  acceptedFileFormats: AssetCatalogueModalSpaceProps['acceptedFileFormats'] = ACCEPTED_IMAGE_FILES
) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setUploadedBlobGuid } = useAssetCatalogueApi();
  const { uploadedBlobGuid } = useAssetCatalogueData();
  const { uploadFile } = useBlobStorage();

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (acceptedFiles.length === 0 || rejectedFiles.length > 0) {
        return;
      }

      try {
        setIsLoading(true);
        const response = await uploadFile(acceptedFiles);
        if (response) {
          const { id } = response[0];

          setUploadedBlobGuid(id);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [uploadFile, setUploadedBlobGuid]
  );

  const uploadDisabled = isLoading || !!uploadedBlobGuid;

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      maxSize: maxFileSize,
      accept: acceptedFileFormats,
      disabled: uploadDisabled,
      multiple: false,
    });

  const api = useMemo(
    () => ({
      getRootProps,
      getInputProps,
      isDragAccept,
      isDragReject,
      isLoading,
      uploadDisabled,
    }),
    [
      getRootProps,
      getInputProps,
      isDragAccept,
      isDragReject,
      isLoading,
      uploadDisabled,
    ]
  );

  return api;
};

export default useUploadBlobFromCatalogue;
