import { useMemo } from 'react';

import { getCardComponentByType } from '../../logic';
import { CardProps } from '../../types';

const useCardType = (type: CardProps['type']) => {
  const CardComponent = useMemo(() => getCardComponentByType(type), [type]);

  return {
    CardComponent,
  };
};

export default useCardType;
