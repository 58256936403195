import { useCallback } from 'react';
import { CardType } from '@kitted/kitted-models';
import { ResourceType } from '@kitted/resource-service-models';

import ModelCreateEdit from '../../../../../../../components/ModelCreateEdit';
import { ModelCreateEditFormData } from '../../../../../../../components/ModelCreateEdit/types';
import ResourceModelCreate from '../../../../../../../components/ResourceModelCreate';
import useModalApi from '../../../../../../../contexts/ModalContext/hooks/useModalApi';
import useArticleRequests from '../../../../../../../hooks/articles/useArticleRequests';
import useFlowRequests from '../../../../../../../hooks/flows/useFlowRequests';
import useKitRequests from '../../../../../../../hooks/kits/useKitRequests';
import usePlayRequests from '../../../../../../../hooks/plays/usePlayRequests';
import useImageResourceRequests from '../../../../../../../hooks/resources/useImageResourceRequests';
import useLinkResourceRequests from '../../../../../../../hooks/resources/useLinkResourceRequests';
import useVideoResourceRequests from '../../../../../../../hooks/resources/useVideoResourceRequests';
import useLoginIsRequiredWrapper from '../../../../../../../hooks/useLoginIsRequiredWrapper';
import { UserCreateableModel } from '../../types';
import {
  getCreatorRequestForCardType,
  getModalTitleForCardType,
  getPayloadFromFormData,
} from './logic';

const useCreateModelRequests = (
  onClose: () => void,
  registerCreatedModel: (model: UserCreateableModel) => void,
  onModalClose: (cardType: CardType, resourceType?: ResourceType) => () => void,
  additionalPayloadData?: Record<string, unknown>
) => {
  const { registerModal } = useModalApi();
  const { createPublishArticle } = useArticleRequests();
  const { createPublishPlay } = usePlayRequests();
  const { createPublishKit } = useKitRequests();
  const { createPublishFlow } = useFlowRequests();
  const { createPublishImageResource } = useImageResourceRequests();
  const { createPublishLinkResource } = useLinkResourceRequests();
  const { createPublishVideoResource } = useVideoResourceRequests();

  const getCreatorRequest = useCallback(
    (cardType: CardType, resourceType?: ResourceType) =>
      getCreatorRequestForCardType({
        cardType,
        resourceType,
        createPublishArticle,
        createPublishPlay,
        createPublishFlow,
        createPublishKit,
        createPublishImageResource,
        createPublishLinkResource,
        createPublishVideoResource,
      }),
    [
      createPublishArticle,
      createPublishPlay,
      createPublishFlow,
      createPublishKit,
      createPublishImageResource,
      createPublishLinkResource,
      createPublishVideoResource,
    ]
  );

  const onSaveChanges = useCallback(
    (cardType: CardType, resourceType?: ResourceType) =>
      async (formData: ModelCreateEditFormData) => {
        const payload = getPayloadFromFormData(formData, additionalPayloadData);
        const creatorFn = getCreatorRequest(cardType, resourceType);
        // await creation
        const response = await creatorFn(payload);
        if (response) {
          registerCreatedModel(response);
        }
        return response;
      },
    [registerCreatedModel, getCreatorRequest, additionalPayloadData]
  );

  const onOpenCreateModal = useCallback(
    (cardType: CardType, resourceType?: ResourceType) => {
      registerModal('create-item', {
        title: getModalTitleForCardType(cardType, resourceType),
        bodyComponent: resourceType ? ResourceModelCreate : ModelCreateEdit,
        bodyComponentProps: {
          cardType,
          resourceType,
          onSaveChanges: onSaveChanges(cardType, resourceType),
        },
        onClose: onModalClose(cardType, resourceType),
        variant: 'fullscreen',
      });
    },
    [registerModal, onSaveChanges, onModalClose]
  );

  const [onWrappedClick] = useLoginIsRequiredWrapper(onOpenCreateModal);

  const onClick = useCallback(
    (cardType: CardType, resourceType?: ResourceType) => () => {
      onWrappedClick(cardType, resourceType);
      onClose();
    },
    [onWrappedClick, onClose]
  );

  return {
    onClick,
  };
};

export default useCreateModelRequests;
