import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0033: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 98 98"
		height={98}
		width={98}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M89.79 46.35V1.81L60.88 12.24v34.52M30.52 1.81 1.61 12.24v74.55l28.91-10.42M54.24 84.92l-23.72-8.55V1.81l28.91 10.43" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M94.51 69.52c0 10.24-8.3 18.54-18.55 18.54s-18.55-8.3-18.55-18.54 8.3-18.55 18.55-18.55 18.55 8.3 18.55 18.55" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M67.14 69.51c0-3.75 2.34-6.96 5.64-8.23M86.98 84.44l9.41 11.75" />
    </g>
  </CardIcon>
);

export default memo(Ac0033);
