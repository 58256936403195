import { setTimeout } from 'worker-timers';

import { emptyImageSrc, minWaitTime } from '../../constants';
import { ImageProps } from '../../types';

export const getFallbackImageSrc = () => emptyImageSrc;

export const getInitialImageSrc = (
  isServer: boolean,
  serverSrc: string | undefined,
  webSrc: string
) => {
  if (isServer) {
    return serverSrc || webSrc;
  }
  return webSrc;
};

export const onLoaded = ({
  src,
  startTime,
  image,
  onLoad,
  setIsLoaded,
  setFadeInTimerRef,
}: {
  src: string;
  startTime: number;
  image: HTMLImageElement;
  onLoad: ImageProps['onLoad'];
  setIsLoaded: (isLoaded: boolean) => void;
  setFadeInTimerRef: (ref: number) => void;
}) => {
  if (onLoad) {
    onLoad(image, src === getFallbackImageSrc());
  }
  const waitTime = minWaitTime - (Date.now() - startTime);
  const timerId = setTimeout(() => {
    setIsLoaded(true);
  }, waitTime); // negative numbers fire immediately
  setFadeInTimerRef(timerId);
};

export const getContainerStyle = (aspectRatio?: string) => ({
  aspectRatio,
});
