import { useMemo } from 'react';
import type { CSSProperties } from 'react';
import { styling } from '@kitted/design-system';

import { useEditDrawerSpaceData } from '../../../../EditDrawerSpace/contexts/EditDrawerSpaceContext';
import useResizeObserver from '../../../context/DrawerSpaceContainerContext/hooks/useResizeObserver';
import { DrawerSpaceContentProps } from '../../types';

const useDrawerSpaceContent = (
  footerHeight: DrawerSpaceContentProps['footerHeight'],
  hasFooter: boolean
) => {
  const { onSetRef, height } = useResizeObserver('height');
  const { spaceProps } = useEditDrawerSpaceData();
  const footerHeightValue = useMemo(
    () =>
      hasFooter ? footerHeight || styling.sizes.context.bottomBar.height : 0,
    [hasFooter, footerHeight]
  );

  const style = useMemo(
    () =>
      ({
        '--drawerSpaceFooterHeight': height,
        '--drawerSpaceFooterMinHeight': footerHeightValue,
      }) as CSSProperties,
    [footerHeightValue, height]
  );

  return {
    style,
    footerHeightValue,
    spaceProps,
    onSetRef,
  };
};

export default useDrawerSpaceContent;
