import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    arrow: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '100%',
      zIndex: 0,
      '&:after,&:before': {
        zIndex: 2,
        content: '""',
        position: 'absolute',
        top: '50%',
        left: 0,
        height: 0,
        width: 0,
        pointerEvents: 'none',
        border: [0, 'solid', styling.colors.theme.transparent],
        transition: styling.transitions.utils.generate(
          ['border-width'],
          ['border-color']
        ),
      },
      '&:before': {
        borderWidth: [26, 0],
        marginTop: -26,
      },
      '&:after': {
        borderWidth: [24, 0],
        marginTop: -24,
      },
    },
    arrowSelected: {
      '&:before': {
        borderLeftColor: styling.colors.theme.primary700,
        borderWidth: [26, 15],
      },
      '&:after': {
        borderLeftColor: styling.colors.theme.secondary,
        borderWidth: [24, 14],
      },
    },
    container: {
      overflow: 'hidden',
      position: 'relative',
      height: '100%',
      width: '100%',
    },
    item: {
      ...styling.utils.buttonReset(),
      fontWeight: 'inherit',
      display: 'flex',
      position: 'relative',
      textDecoration: 'none',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: '100%',
      color: styling.colors.theme.secondary,
      backgroundColor: styling.colors.theme.transparent,
      transition: styling.transitions.utils.generate(
        ['background-color'],
        ['color']
      ),
      '&:hover:not($itemSelected),&:focus:not($itemSelected),&:active:not($itemSelected)':
        {
          backgroundColor: styling.colors.theme.primary800,
        },
    },
    itemSelected: {
      color: styling.colors.theme.primary600,
      backgroundColor: styling.colors.theme.secondary,
    },
    itemDisabled: {
      ...styling.utils.disabled(styling.colors.theme.primary200),
    },
  },
  { name: 'menuItemLink' }
);
