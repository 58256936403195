import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      position: 'relative',
      display: 'flex',
      padding: 0,
      borderRadius: '100rem',
      textDecoration: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      transition: styling.speeds.jog,
      outline: 'none',
      overflow: 'hidden',
    },
    isDisabled: {},
    'theme-primary': {
      '--buttonSolidBorder': styling.colors.theme.primary800,
      '--buttonSolidBackground': styling.colors.theme.primary400,
      '--buttonSolidColor': styling.colors.theme.secondary,
      '--buttonOutlineBorder': styling.colors.theme.primary400,
      '--buttonOutlineBorderHover': styling.colors.theme.primary600,
      '--buttonOutlineColor': styling.colors.theme.primary600,
    },
    'theme-secondary': {
      '--buttonSolidBorder': styling.colors.theme.secondary,
      '--buttonSolidBackground': styling.colors.theme.secondary,
      '--buttonSolidColor': styling.colors.theme.primary600,
      '--buttonOutlineBorder': styling.colors.theme.primary400,
      '--buttonOutlineBorderHover': styling.colors.theme.secondary,
      '--buttonOutlineColor': styling.colors.theme.secondary,
    },
    'variant-outline': {
      background: 'none',
      border: [1, 'solid', 'var(--buttonOutlineBorder)'],
      color: 'var(--buttonOutlineColor)',
      '&:hover:not($isDisabled)': {
        borderColor: 'var(--buttonOutlineBorderHover)',
      },
      '&$isDisabled': {
        ...styling.utils.disabled('var(--buttonOutlineBorder)'),
      },
    },
    'variant-solid': {
      border: [1, 'solid', 'var(--buttonSolidBorder)'],
      background: 'var(--buttonSolidBackground)',
      color: 'var(--buttonSolidColor)',
      '&$isDisabled': {
        ...styling.utils.disabled('var(--buttonSolidBorder)'),
      },
    },
    'size-sm': {
      height: 36,
      width: 36,
      fontSize: '1.6rem',
    },
    'size-md': {
      height: 48,
      width: 48,
      fontSize: '1.8rem',
    },
  },
  { name: 'button' }
);
