import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0026: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 104 104"
		height={104}
		width={104}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M52 1.13v26.49M52 76.38v26.49" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M102.87 52H76.38M27.62 52H1.13" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M75.67 52c0 13.07-10.6 23.67-23.67 23.67S28.33 65.07 28.33 52 38.93 28.33 52 28.33 75.67 38.93 75.67 52M41.81 91.01C24.48 86.5 11.69 70.74 11.69 51.99M12.99 41.8c3.67-14.06 14.74-25.14 28.81-28.81" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M62.29 52c0 5.68-4.61 10.29-10.29 10.29S41.71 57.69 41.71 52 46.32 41.71 52 41.71 62.29 46.32 62.29 52" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M92.31 52c0 22.27-18.05 40.32-40.31 40.32M52 11.68c18.76 0 34.52 12.82 39.02 30.17" />
    </g>
  </CardIcon>
);

export default memo(Fr0026);
