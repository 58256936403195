import { z } from 'zod';
import {
  ImageAssetModelSchema,
  KittedModelBaseSchema,
  makeKittedModelReferenceSchema,
  PlaySlugSchema,
} from '@kitted/kitted-models';
import { DateISOSchema } from '@kitted/platform-models';

import {
  AnyCMSPlayBlockLoadedModelSchema,
  AnyCMSPlayBlockUnloadedModelSchema,
} from './AnyCMSPlayBlockModel';

export const CMSPlayLoadedModelSchema = KittedModelBaseSchema.merge(
  z.object({
    slug: PlaySlugSchema,
    title: z.string(),
    description: z.string(),
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      true
    ).optional(),
    playBlocks: makeKittedModelReferenceSchema(
      AnyCMSPlayBlockLoadedModelSchema,
      true
    ).array(),
    excludeFromDiscovery: z.boolean(),
    live: z.boolean(),
    createdByAuthorId: z.string(),
    createdByUserId: z.string(),

    createdAt: DateISOSchema,
    updatedAt: DateISOSchema,
  })
);

export const CMSPlayUnloadedModelSchema = CMSPlayLoadedModelSchema.merge(
  z.object({
    keyImageImageAsset: makeKittedModelReferenceSchema(
      ImageAssetModelSchema,
      false
    ).optional(),

    playBlocks: makeKittedModelReferenceSchema(
      AnyCMSPlayBlockUnloadedModelSchema,
      false
    ).array(),
  })
);

export type CMSPlayLoadedModel = z.infer<typeof CMSPlayLoadedModelSchema>;
export type CMSPlayUnloadedModel = z.infer<typeof CMSPlayUnloadedModelSchema>;

export type CMSPlayModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? CMSPlayLoadedModel : CMSPlayUnloadedModel;
