import { matchRoutes } from 'react-router-dom';
import { uniqBy } from '@kitted/shared-utils';
import { authModalRedirectRoutes, routes } from '@kitted/website-constants';

const ignorePaths = [routes.fourOhFour.path, routes.rootCard.path];

const routesToRoutesArray = uniqBy(
  Object.entries(routes)
    .map(([key, route]) => ({
      path: route.redirectTo || route.path,
      id: key,
      isAuthenticated: route.isAuthenticated,
    }))
    .reverse(),
  'path'
).filter((route) => !ignorePaths.includes(route.path));

const getRouteMatchesForUrl = (url: string) =>
  matchRoutes(routesToRoutesArray, url);

export const getNonRedirectRouteKeyForPath = (
  pathname: string
): string | undefined => {
  const matches = getRouteMatchesForUrl(pathname);

  if (!matches || matches.length === 0) {
    return undefined;
  }

  const { route } = matches[0];

  if (Object.keys(authModalRedirectRoutes).includes(route.id)) {
    return 'home';
  }

  return route.id;
};
