import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0036: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 126 110"
		height={110}
		width={126}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><g stroke={spot}><path vectorEffect="non-scaling-stroke" d="M102.4 60.59c0 .78-.63 1.41-1.41 1.41s-1.42-.63-1.42-1.41.64-1.42 1.42-1.42 1.41.63 1.41 1.42M85.09 60.59c0 .78-.63 1.41-1.41 1.41s-1.42-.63-1.42-1.41.64-1.42 1.42-1.42 1.41.63 1.41 1.42" /></g><g stroke={spot}><path vectorEffect="non-scaling-stroke" d="M92.33 70.66c-.78 0-1.41-.63-1.41-1.41s.63-1.42 1.41-1.42 1.42.64 1.42 1.42-.63 1.41-1.42 1.41M92.33 53.35c-.78 0-1.41-.63-1.41-1.41s.63-1.42 1.41-1.42 1.42.64 1.42 1.42-.63 1.41-1.42 1.41" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M19.94 61.78h21.04M30.46 51.25V72.3" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M62.25 1.68s16.89 10.67 6.33 21.23c-5.12 5.12-7.46 6.19-7.46 14.71" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M88.58 33c19.7 0 23.33 12.51 24.73 16.83 2.71 8.36 9.36 33.88 9.36 33.88 3.65 13.72-2.99 21.42-8.93 23.4-14.29 4.74-18.7-5.55-20.71-9.54-10.26-20.39-13.22-18.19-23.15-18.19H56.12c-9.92 0-12.88-2.2-23.15 18.19-2.01 3.99-6.42 14.28-20.71 9.54-5.94-1.97-12.58-9.68-8.93-23.4 0 0 6.65-25.52 9.36-33.88C14.09 45.51 17.72 33 37.42 33c0 0 17.32-.44 17.32 11.94h17.31S75.79 33 88.57 33Z" />
    </g>
  </CardIcon>
);

export default memo(In0036);
