import { useEffect, useRef } from 'react';
import { EMPTY_OBJ } from '@kitted/shared-utils';
import { RoutesNonRedirectsKeys } from '@kitted/website-constants';

import routeSceneMap from '../../../../../constants/routes/routesWithScenes';
import logger from '../../../../../services/logger';
import { PreloadRoutesProps } from '../../types';

const usePreloadRoutes = (routeKeys: PreloadRoutesProps['routeKeys']) => {
  const routeKeysRef = useRef<Record<string, boolean>>(EMPTY_OBJ);

  useEffect(() => {
    routeKeys.forEach((routeKey) => {
      if (!routeKeysRef.current[routeKey]) {
        routeKeysRef.current = {
          ...routeKeysRef.current,
          [routeKey]: true,
        };
        if (routeKey in routeSceneMap) {
          const nonRedirectingRouteKey = routeKey as RoutesNonRedirectsKeys;
          routeSceneMap[nonRedirectingRouteKey]();
        } else {
          logger.log('[preload routes] route not found', routeKey);
        }
      }
    });
  }, [routeKeys]);
};

export default usePreloadRoutes;
