import * as actionPalette from './action';
import * as dangerPalette from './danger';
import * as framePalette from './frame';
import * as insightPalette from './insight';
import * as primaryPalette from './primary';
import * as secondaryPalette from './secondary';
import * as snarePalette from './snare';

const transparent = 'rgba(0,0,0,0)';
const pureWhite = '#fff';
const pureBlack = '#020101';

// tools
const action = actionPalette.action500;
const insight = insightPalette.insight500;
const frame = framePalette.frame500;
const snare = snarePalette.snare500;
const danger = dangerPalette.danger700;
const primary = primaryPalette.primary500;
const secondary = secondaryPalette.secondary300;

const error = actionPalette.action600;
const success = frame;
const info = snare;

const flowGradient = `linear-gradient(to right, ${action} 0%, ${insight} 33.3%, ${snare} 66.6%, ${frame} 100%)`;
const cardTopGradient = `linear-gradient(to top, ${transparent} 0%, ${primaryPalette.primary700} 100%)`;
const cardBottomGradient = `linear-gradient(to bottom, ${transparent} 0%, ${primaryPalette.primary700} 100%)`;
const imageTopGradient = `linear-gradient(to top, ${transparent} 0%, ${primaryPalette.primary900} 100%)`;
const imageBottomGradient = `linear-gradient(to bottom, ${transparent} 0%, ${primaryPalette.primary900} 100%)`;
const loaderPrimaryGradient = `linear-gradient(90deg, ${primaryPalette.primary900_05} 35%, ${primaryPalette.primary900_20} 50%, ${primaryPalette.primary900_05} 65%)`;
const loaderSecondaryGradient = `linear-gradient(90deg, ${secondaryPalette.secondary300_06} 35%, ${secondaryPalette.secondary300_30} 50%, ${secondaryPalette.secondary300_06} 65%)`;

const colors = {
  theme: {
    error,
    success,
    info,
    action,
    insight,
    frame,
    snare,
    transparent,
    pureWhite,
    pureBlack,
    danger,
    primary,
    secondary,
    ...actionPalette,
    ...dangerPalette,
    ...framePalette,
    ...insightPalette,
    ...primaryPalette,
    ...secondaryPalette,
    ...snarePalette,
  },
  context: {
    background: primaryPalette.primary900,
    color: secondary,
    inverted: {
      background: secondary,
      color: primaryPalette.primary900,
    },
    footer: {
      background: secondary,
      color: primaryPalette.primary900,
    },
    pills: {
      action,
      insight,
      frame,
      snare,
    },
    tools: {
      action,
      insight,
      frame,
      snare,
    },
    card: {
      background: primaryPalette.primary600,
      color: secondary,
      hoverBackground: primaryPalette.primary700,
      invertedBackground: secondaryPalette.secondary100,
      invertedColor: primaryPalette.primary600,
      border: primaryPalette.primary400,
      menuButtonBackground: primaryPalette.primary800,
      topGradient: cardTopGradient,
      bottomGradient: cardBottomGradient,
      footerBackgrounds: {
        article: secondary,
        flow: flowGradient,
        action,
        insight,
        frame,
        snare,
        play: dangerPalette.danger500,
      },
    },
    loader: {
      primaryGradient: loaderPrimaryGradient,
      secondaryGradient: loaderSecondaryGradient,
    },
    images: {
      topGradient: imageTopGradient,
      bottomGradient: imageBottomGradient,
    },
    iconDefaultColor: secondary,
    filterInputBorder: primaryPalette.primary400,
    filterInputBackground: primaryPalette.primary800,
    inputBackground: secondaryPalette.secondary200,
    inputPlaceholderText: primaryPalette.primary200,
  },
};

export default colors;
