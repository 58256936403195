import { z } from 'zod';

import { ToolType } from '../Enum';
import {
  ToolBaseCardLoadedModelSchema,
  ToolBaseCardUnloadedModelSchema,
} from '../ToolBase';

export const SnareToolCardBaseModelSchema = z.object({
  toolType: z.literal(ToolType.Snare),
});

export const SnareToolCardLoadedModelSchema =
  ToolBaseCardLoadedModelSchema.merge(SnareToolCardBaseModelSchema);
export const SnareToolCardUnloadedModelSchema =
  ToolBaseCardUnloadedModelSchema.merge(SnareToolCardBaseModelSchema);

export type SnareToolCardLoadedModel = z.infer<
  typeof SnareToolCardLoadedModelSchema
>;
export type SnareToolCardUnloadedModel = z.infer<
  typeof SnareToolCardUnloadedModelSchema
>;

export type SnareToolCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? SnareToolCardLoadedModel : SnareToolCardUnloadedModel;
