/* eslint-disable camelcase */
import { generateLinearPalette } from '../../services/linearPaletteGenerator';

const insightBaseColor = '#B58EEF'; // rgb(181, 142, 239)
const palette = generateLinearPalette(insightBaseColor);

export const insight050 = palette['050']; // #ede4fb
export const insight100 = palette['100']; // #e8dbfa
export const insight200 = palette['200']; // #e0cff8
export const insight300 = palette['300']; // #d5bff6
export const insight300_50 = palette['300_50']; // #d5bff6
export const insight300_80 = palette['300_80']; // #d5bff6
export const insight400 = palette['400']; // #c7aaf3
export const insight500 = palette['500']; // #B58EEF
export const insight500_10 = palette['500_10']; // #B58EEF
export const insight500_30 = palette['500_30']; // #B58EEF
export const insight500_40 = palette['500_40']; // #B58EEF
export const insight600 = palette['600']; // #945ce8
export const insight700 = palette['700']; // #7831e2
export const insight800 = palette['800']; // #631dcd
export const insight900 = palette['900']; // #5419ae
