import {
  FieldTypes,
  FormDataSchema,
} from '../../../../../../../contexts/FormContext';
import {
  hasMaxLength,
  isRequired,
  isValidVideoURL,
} from '../../../../../../../services/helpers/validators';

export const dataSchema: FormDataSchema = {
  fields: {
    videoUrl: {
      type: FieldTypes.URL,
      validation: [isValidVideoURL(true)],
    },
    videoScrape: {
      type: FieldTypes.DISPLAY,
    },
    keyImage: {
      type: FieldTypes.IMAGE,
    },
    title: {
      type: FieldTypes.TEXT,
      validation: [isRequired, hasMaxLength(128)],
    },
    description: {
      type: FieldTypes.TEXTAREA,
      validation: [hasMaxLength(600)],
    },
  },
  form: {
    validationStrategy: 'onBlur',
  },
};
