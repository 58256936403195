/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import { useInView } from 'react-intersection-observer';

import { PolymorphicLoadWhenInViewProps } from './types';

const LoadWhenInView = <C extends React.ElementType = 'div'>({
  as,
  children,
  className,
  fallbackInView = true,
  triggerOnce = true,
  notInViewComponent = null,
  ...rest
}: PolymorphicLoadWhenInViewProps<C>) => {
  const { ref, inView } = useInView({
    triggerOnce,
    fallbackInView,
  });
  const Component = as || 'div';

  return (
    <Component className={className} ref={ref} {...rest}>
      {inView ? children : notInViewComponent}
    </Component>
  );
};

export default memo(LoadWhenInView);
