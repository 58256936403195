import { z } from 'zod';

import {
  CMSContentBlockCardGridLoadedModelSchema,
  CMSContentBlockCardGridUnloadedModelSchema,
  CMSContentBlockCarouselItemLoadedModelSchema,
  CMSContentBlockCarouselItemUnloadedModelSchema,
  CMSContentBlockCarouselLoadedModelSchema,
  CMSContentBlockCarouselUnloadedModelSchema,
  CMSContentBlockImageLoadedModelSchema,
  CMSContentBlockImageUnloadedModelSchema,
  CMSContentBlockQuoteModelSchema,
  CMSContentBlockTextModelSchema,
  CMSContentBlockVideoLoadedModelSchema,
  CMSContentBlockVideoUnloadedModelSchema,
} from '../content-blocks';
import { CMSArticleBlockExecutiveBoardModelSchema } from './CMSArticleBlockExecutiveBoardModel';

export const AnyCMSArticleBlockLoadedModelSchema = z.union([
  CMSArticleBlockExecutiveBoardModelSchema,
  CMSContentBlockImageLoadedModelSchema,
  CMSContentBlockCardGridLoadedModelSchema,
  CMSContentBlockCarouselLoadedModelSchema,
  CMSContentBlockCarouselItemLoadedModelSchema,
  CMSContentBlockVideoLoadedModelSchema,
  CMSContentBlockQuoteModelSchema,
  CMSContentBlockTextModelSchema,
]);

export const AnyCMSArticleBlockUnloadedModelSchema = z.union([
  CMSArticleBlockExecutiveBoardModelSchema,
  CMSContentBlockImageUnloadedModelSchema,
  CMSContentBlockCardGridUnloadedModelSchema,
  CMSContentBlockCarouselUnloadedModelSchema,
  CMSContentBlockCarouselItemUnloadedModelSchema,
  CMSContentBlockVideoUnloadedModelSchema,
  CMSContentBlockQuoteModelSchema,
  CMSContentBlockTextModelSchema,
]);

export type AnyCMSArticleBlockLoadedModel = z.infer<
  typeof AnyCMSArticleBlockLoadedModelSchema
>;
export type AnyCMSArticleBlockUnloadedModel = z.infer<
  typeof AnyCMSArticleBlockUnloadedModelSchema
>;

export type AnyCMSArticleBlockModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? AnyCMSArticleBlockLoadedModel
    : AnyCMSArticleBlockUnloadedModel;
