import { memo } from 'react';

import useAnalyticsTriggers from './hooks/useAnalyticsTriggers';

const AnalyticsTriggers = () => {
  useAnalyticsTriggers();

  return null;
};

export default memo(AnalyticsTriggers);
