import { useCallback, useEffect, useRef } from 'react';
import { FavoriteModel } from '@kitted/account-service-models';
import { CardModelId } from '@kitted/kitted-models';
import { EMPTY_ARR } from '@kitted/shared-utils';

import useStoreKeysSubscription from '../../../contexts/StoreContext/hooks/useStoreKeysSubscription';
import useStoreSection from '../../../contexts/StoreContext/hooks/useStoreSection';
import useFavoritesRequests from '../useFavoritesRequests';
import {
  favoriteItemKeyResolver,
  mergeNonFavoritesWithFavorites,
} from './logic';

const useFavoritesPrime = () => {
  const { requestFavorites } = useFavoritesRequests();
  const { setStoreSectionItems } = useStoreSection<FavoriteModel>('Favorites');
  const { keys: allCardIds } = useStoreKeysSubscription('Cards');
  const shouldPrimeRef = useRef<boolean>(false);
  const isLoadedRef = useRef<boolean>(false);
  const favoritesRef = useRef<FavoriteModel[] | undefined>();
  const allCardIdsRef = useRef<CardModelId[] | undefined>(allCardIds);

  useEffect(() => {
    allCardIdsRef.current = allCardIds;
  }, [allCardIds]);

  const loadFavoritesIntoStore = useCallback(
    (cardIds: CardModelId[]) => {
      const favorites = favoritesRef.current;
      const mergedFavourites = mergeNonFavoritesWithFavorites(
        cardIds,
        favorites || EMPTY_ARR
      );

      setStoreSectionItems(mergedFavourites, favoriteItemKeyResolver);
    },
    [setStoreSectionItems]
  );

  const primeFavorites = useCallback(async () => {
    shouldPrimeRef.current = true;
    favoritesRef.current = await requestFavorites();
    if (isLoadedRef.current) {
      loadFavoritesIntoStore(allCardIdsRef.current || EMPTY_ARR);
    }
  }, [requestFavorites, loadFavoritesIntoStore]);

  useEffect(() => {
    if (!isLoadedRef.current && allCardIds) {
      isLoadedRef.current = true;
      if (shouldPrimeRef.current) {
        loadFavoritesIntoStore(allCardIds || EMPTY_ARR);
      }
    }
  }, [allCardIds, loadFavoritesIntoStore]);

  return {
    primeFavorites,
  };
};

export default useFavoritesPrime;
