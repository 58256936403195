import { EMPTY_ARR } from '@kitted/shared-utils';

import { isRequired } from '../../../../services/helpers/validators';
import { PasswordStrengthIndicatorProps } from './types';

export const getMappedItems = (
  items: PasswordStrengthIndicatorProps['items'],
  errors: PasswordStrengthIndicatorProps['errors'] = EMPTY_ARR
) =>
  items.map((item) => ({
    label: item.label,
    inError:
      errors.includes(item.matchError) ||
      errors.includes(isRequired.validator('') || ''),
  }));
