import { SpinnerTheme } from '@kitted/shared-components';

import { ButtonProps } from './types';

export const getLoadingTheme = (
  theme: Exclude<ButtonProps['theme'], undefined>,
  variant: ButtonProps['variant']
): SpinnerTheme => {
  if (variant === 'solid') {
    switch (theme) {
      case 'primary':
      case 'danger':
        return 'secondary';
      default:
        return 'primary';
    }
  }
  return theme;
};
