import { memo } from 'react';
import { Typography } from '@kitted/shared-components';

import { FormProvider } from '../../../contexts/FormContext';
import Form from '../../Form';
import FormFields from '../../FormFields';
import AuthenticationModalContentWrapper from '../AuthenticationModalContentWrapper';
import useChangePasswordModalContent from './hooks/useChangePasswordModalContent';
import ChangeButton from './ChangeButton';
import { dataSchema, layoutSchema } from './constants';
import useStyles from './styles';
import { AuthChangePasswordModalProps } from './types';

const AuthChangePasswordModalContent = ({
  onClose,
  meta,
  preventClose,
  title,
  isRequired,
}: AuthChangePasswordModalProps) => {
  const { onSubmit, onSubmitSuccess, initialFormData } =
    useChangePasswordModalContent(onClose, meta);
  const styles = useStyles();

  return (
    <AuthenticationModalContentWrapper
      preventClose={preventClose}
      title={title}
      isRequired={isRequired}
      onClose={onClose}
    >
      <Typography className={styles.reset}>
        Enter your email, and the code you received in your forgotten password
        email below.
      </Typography>
      <FormProvider initialValues={initialFormData} dataSchema={dataSchema}>
        <Form
          autoComplete
          onSubmit={onSubmit}
          onSubmitSuccess={onSubmitSuccess}
        >
          <FormFields layout={layoutSchema} />
          <ChangeButton />
        </Form>
      </FormProvider>
    </AuthenticationModalContentWrapper>
  );
};

export default memo(AuthChangePasswordModalContent);
