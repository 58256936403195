import { useCallback, useState } from 'react';
import { Nullable } from '@kitted/platform-models';
import { EMPTY_OBJ } from '@kitted/shared-utils';

const useDrawerSpaceContextualDrawer = () => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [contextualDrawerContent, setContextualDrawerContent] =
    useState<Nullable<React.ElementType>>(null);
  const [contextualDrawerHeader, setContextualDrawerHeader] =
    useState<Nullable<React.ElementType>>(null);
  const [contextualDrawerContentProps, setContextualDrawerContentProps] =
    useState<Record<string, unknown>>(EMPTY_OBJ);

  const mountContextualDrawer = useCallback(
    (
      newContent: React.ElementType,
      newContentProps?: Record<string, unknown>,
      newHeader?: React.ElementType
    ) => {
      setContextualDrawerHeader(newHeader ?? null);
      setContextualDrawerContent(newContent);
      setContextualDrawerContentProps(newContentProps || EMPTY_OBJ);
      setIsDisabled(false);
    },
    [setContextualDrawerContent, setContextualDrawerHeader, setIsDisabled]
  );

  const unmountContextualDrawer = useCallback(() => {
    setIsDisabled(true);
    setContextualDrawerContentProps(EMPTY_OBJ);
    setContextualDrawerContent(null);
    setContextualDrawerHeader(null);
  }, [setContextualDrawerContent, setContextualDrawerHeader, setIsDisabled]);

  const updateContextualDrawerContentProps = useCallback(
    (newContentProps: Record<string, unknown>) => {
      setContextualDrawerContentProps(newContentProps);
    },
    [setContextualDrawerContentProps]
  );

  return {
    isContextualDisabled: isDisabled,
    setIsContextualDisabled: setIsDisabled,
    contextualDrawerContent,
    contextualDrawerContentProps,
    updateContextualDrawerContentProps,
    contextualDrawerHeader,
    mountContextualDrawer,
    unmountContextualDrawer,
  };
};

export default useDrawerSpaceContextualDrawer;
