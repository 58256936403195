import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0003: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 126 82"
		height={82}
		width={126}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M56.94 42.57c0 3.11 2.71 5.63 6.05 5.63s6.06-2.52 6.06-5.63-3.23-4.47-6.07-5.91c-2.83-1.44-6.06-2.8-6.06-5.91s2.71-5.63 6.05-5.63 6.06 2.52 6.06 5.63M62.97 25.09v-4.13M62.97 52.36v-4.13" /><path vectorEffect="non-scaling-stroke" d="M47.77 17.71a24.2 24.2 0 0 1 8.57-4.43c12.88-3.68 26.3 3.77 29.98 16.65 3.67 12.88-3.79 26.3-16.66 29.98-12.88 3.68-26.3-3.78-29.98-16.65a24.17 24.17 0 0 1 1.9-18.03" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M12.94 62.78V8.81c0-4.02 3.29-7.31 7.3-7.31h85.5c4.02 0 7.31 3.29 7.31 7.31v53.97M1.28 70.83s2.13 9.67 9.2 9.67h105.04c7.07 0 9.2-9.67 9.2-9.67z" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0003);
