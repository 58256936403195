import { memo } from 'react';

import CatalogueItemGrid from '../CatalogueItemGrid';
import CatalogueTopBar from '../CatalogueTopBar';
import useSearchItems from './hooks/useSearchItems';
import useStyles from './styles';

const CatalogueSearch = () => {
  const styles = useStyles();

  const searchItems = useSearchItems();

  return (
    <div className={styles.container}>
      <CatalogueTopBar />
      <CatalogueItemGrid catalogueItems={searchItems} />
    </div>
  );
};

export default memo(CatalogueSearch);
