import { memo } from 'react';
import { styling } from '@kitted/design-system';

import { KittedBrandProps } from './types';

const KittedBrand = ({
  className,
  height = 74,
  width = 169,
  fill = styling.colors.theme.primary600,
}: KittedBrandProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16.9 7.4"
    className={className}
    height={height}
    width={width}
  >
    <g fill={fill}>
      <path d="m.8,6.43h-.29v.81h-.21v-.81H.02v-.16h.78v.16Z" />
      <path d="m1.56,7.24h-.21v-.97h.21v.41h.39v-.41h.21v.97h-.21v-.41h-.39v.41Z" />
      <path d="m2.78,6.26h.21v.97h-.21v-.97Z" />
      <path d="m3.6,6.26h.23l.31.53c.05.08.07.15.07.15h0v-.67h.19v.97h-.22l-.32-.54c-.05-.09-.07-.15-.07-.15h0v.69h-.19v-.97Z" />
      <path d="m5.24,7.24h-.21v-.97h.21v.43l.36-.43h.23l-.35.41.37.57h-.24l-.27-.41-.1.11v.3Z" />
      <path d="m6.38,6.26h.21v.97h-.21v-.97Z" />
      <path d="m7.2,6.26h.23l.31.53c.05.08.07.15.07.15h0v-.67h.19v.97h-.22l-.32-.54c-.05-.09-.07-.15-.07-.15h0v.69h-.19v-.97Z" />
      <path d="m8.81,6.75c0,.25.09.35.24.35.13,0,.21-.08.22-.22v-.02s-.23,0-.23,0v-.15h.43v.52h-.17v-.13h0c-.05.09-.14.15-.28.15-.28,0-.43-.19-.43-.49,0-.32.18-.52.46-.52.23,0,.38.11.4.31h-.21c-.01-.09-.08-.16-.19-.16-.15,0-.24.12-.24.36Z" />
      <path d="m11.5,6.43h-.29v.81h-.21v-.81h-.29v-.16h.78v.16Z" />
      <path d="m12.44,6.25c.29,0,.46.18.46.5s-.18.5-.46.5-.46-.18-.46-.5.19-.5.46-.5Zm.23.5c0-.23-.1-.35-.23-.35-.15,0-.23.12-.23.35s.08.35.23.35.23-.12.23-.35Z" />
      <path d="m13.91,6.25c.29,0,.46.18.46.5s-.18.5-.46.5-.46-.18-.46-.5.19-.5.46-.5Zm.23.5c0-.23-.1-.35-.23-.35-.15,0-.23.12-.23.35s.08.35.23.35.23-.12.23-.35Z" />
      <path d="m14.95,6.26h.21v.81h.42v.16h-.63v-.97Z" />
      <path d="m16.62,6.53c0-.06-.05-.13-.15-.13-.09,0-.15.05-.15.12,0,.06.04.1.13.12l.15.03c.17.03.26.12.26.27,0,.21-.14.32-.39.32s-.38-.12-.39-.3h.22c.01.08.07.15.18.15.12,0,.17-.06.17-.13,0-.07-.04-.1-.14-.12l-.15-.03c-.15-.03-.25-.12-.25-.28,0-.17.13-.3.37-.3.25,0,.35.13.36.28h-.21Z" />
    </g>
    <g fill={fill}>
      <path d="m4.5.93l-.24-.24s0-.01,0-.02c0,0,0-.01,0-.02l.24-.24.02-.02.03-.03.24-.24.02-.02.03-.03h.01s.03-.02.04-.01l.24.24.02.02.27.27.02.02.03.03h0s0,0,0,0c0,0,0,0,0,0,0,0,0,.01,0,.02,0,0,0,0,0,0,0,0,0,0,0,.01,0,0,0,0,0,0l-.04.04-.02.02-.27.27-.02.02-.24.24s-.01,0-.02,0-.01,0-.02,0h0s-.03-.04-.03-.04l-.02-.02-.24-.24-.03-.03-.02-.02Z" />
      <path d="m.94,5.14H.09s-.03-.01-.03-.03V.07s.01-.03.03-.03h.87s.03.01.03.03l-.02,2.37L2.99.07s.03-.02.05-.02h.95s.04.03.02.05l-1.84,2.08,1.95,2.92s0,.05-.03.05h-.99s-.05-.01-.06-.03l-1.51-2.24-.59.67v1.56s-.01.03-.03.03Z" />
      <path d="m4.46,1.65h.84s.03.01.03.03v3.43s-.01.03-.03.03h-.84s-.03-.01-.03-.03V1.68s.01-.03.03-.03Z" />
      <path d="m11.52,5.23c-1.12,0-1.7-.75-1.7-1.86s.62-1.87,1.69-1.87c1.17,0,1.64.8,1.64,1.79v.26s-.01.03-.03.03h-2.4c.04.67.3,1.03.83,1.03.45,0,.6-.22.67-.43,0-.02.03-.04.05-.04h.8s.03.02.03.03c-.13.66-.69,1.05-1.59,1.05Zm.72-2.25c-.03-.53-.24-.89-.73-.89-.46,0-.71.35-.77.93h1.51v-.04Z" />
      <path d="m16.83,5.14h-.8s-.03-.01-.03-.03v-.52h-.02c-.18.33-.53.63-1.08.63-.87,0-1.49-.55-1.49-1.83s.65-1.89,1.55-1.89c.48,0,.83.25.99.57h.01V.07s.01-.03.03-.03h.84s.03.01.03.03v5.04s-.01.03-.03.03Zm-.86-1.93c0-.67-.32-1.07-.81-1.07-.54,0-.84.43-.84,1.23,0,.75.23,1.23.81,1.23.53,0,.84-.42.84-.98v-.41Z" />
      <path d="m9.59,4.48c-.07,0-.17.02-.26.02-.36,0-.5-.15-.5-.52v-1.69h.78s.03-.01.03-.03v-.58s-.01-.03-.03-.03h-.8v-.95s-.01-.03-.03-.03h-.72s-.03.01-.03.03v.18c0,.66-.09.76-.55.76h-.72v-.94s-.01-.03-.03-.03h-.81s-.03.01-.03.03h0v3.44c0,.74.37,1.05,1.07,1.05.25,0,.47-.02.59-.04.04,0,.06-.03.06-.07v-.53s-.04-.07-.08-.07c-.07,0-.17.02-.26.02-.36,0-.5-.15-.5-.52v-1.69h1.16v1.85c0,.74.37,1.05,1.07,1.05.25,0,.47-.02.59-.04.04,0,.06-.03.06-.07v-.53s-.04-.07-.08-.07Z" />
    </g>
  </svg>
);

export default memo(KittedBrand);
