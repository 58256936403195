import { NavigationType, useBlocker } from 'react-router-dom';
import { NOOP } from '@kitted/shared-utils';

import { NavigateBlockerPredicate } from './types';

const useNavigationBlocker = (predicate: NavigateBlockerPredicate) => {
  const blocker = useBlocker(
    (args) =>
      !args.nextLocation.state?.skipBlocker &&
      !(args.historyAction === NavigationType.Replace) &&
      args.currentLocation.pathname !== args.nextLocation.pathname &&
      (typeof predicate === 'function' ? predicate(args) : predicate)
  );

  return {
    isBlocking: blocker.state === 'blocked',
    onProceed: blocker.proceed || NOOP,
    onCancel: blocker.reset || NOOP,
  };
};

export default useNavigationBlocker;
