import { CatalogueItemModel } from '@kitted/catalogue-service-models';

import { SearchFunction } from '../../../../hooks/useTextSearch/types';

export const filterCatalogueItemsBySearchTerm = (
  items: CatalogueItemModel[],
  searchText: string,
  search: SearchFunction
) => {
  const trimmedSearchText = searchText.trim();
  if (!trimmedSearchText) {
    return items;
  }

  const searchResults = search(trimmedSearchText, {
    tags: 1,
  });

  return items.filter((gi) => searchResults.includes(gi.id));
};
