/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';
import { clearTimeout, setTimeout } from 'worker-timers';

// inspired by https://codesandbox.io/s/qxnmn1n45q
const useAnimationTimer = (duration = 1000) => {
  const [elapsed, setTime] = useState(0);
  const timerRef = useRef<number>();
  const animationFrameRef = useRef<number>();
  const startTimeRef = useRef<number>(0);

  // Function to be executed on each animation frame
  const onFrame = useCallback(() => {
    setTime(Date.now() - startTimeRef.current);
    // eslint-disable-next-line no-use-before-define
    loop();
  }, []);

  // Call onFrame() on next animation frame
  const loop = useCallback(() => {
    animationFrameRef.current = requestAnimationFrame(onFrame);
  }, []);

  const onStart = useCallback(
    (timeoutDuration: number) => {
      // Set a timeout to stop things when duration time elapses
      timerRef.current = setTimeout(() => {
        if (animationFrameRef.current !== undefined) {
          cancelAnimationFrame(animationFrameRef.current);
        }
        setTime(Date.now() - startTimeRef.current);
      }, timeoutDuration);

      // Start the loop
      startTimeRef.current = Date.now();
      loop();
    },
    [loop]
  );

  const onReset = useCallback(() => {
    setTime(0);
    if (timerRef.current !== undefined) {
      clearTimeout(timerRef.current);
    }
    if (animationFrameRef.current !== undefined) {
      cancelAnimationFrame(animationFrameRef.current);
    }
    onStart(duration);
  }, [onStart, duration]);

  useEffect(
    () => {
      // Start
      onStart(duration);

      // Clean things up
      return () => {
        if (timerRef.current !== undefined) {
          clearTimeout(timerRef.current);
        }
        if (animationFrameRef.current !== undefined) {
          cancelAnimationFrame(animationFrameRef.current);
        }
      };
    },
    [duration, onStart] // Only re-run effect if duration changes
  );

  return {
    elapsed: Math.min(1, elapsed / duration),
    onReset,
  };
};

export default useAnimationTimer;
