/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';

import Image from '../Image';
import useNaturalImage from './hooks/useNaturalImage';
import { NaturalImageProps } from './types';

const NaturalImage = ({ onLoad, src, ...rest }: NaturalImageProps) => {
  const { onNaturalImageLoad, aspectRatio } = useNaturalImage(onLoad);
  return (
    <Image
      {...rest}
      onLoad={onNaturalImageLoad}
      aspectRatio={aspectRatio}
      src={src}
    />
  );
};

export default memo(NaturalImage);
