import useDrawerSpaceContainerData from '../useDrawerSpaceContainerData';

const useDrawerSpaceWidthQuery = (
  widthSize:
    | 'isXs'
    | 'isSm'
    | 'isMd'
    | 'isLg'
    | 'isXl'
    | 'isTablet'
    | 'isDesktop'
) => {
  const contextValue = useDrawerSpaceContainerData();

  return !!contextValue[widthSize];
};

export default useDrawerSpaceWidthQuery;
