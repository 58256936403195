import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    topItems: {
      width: '100%',
    },
    bottomItems: {
      width: '100%',
      marginTop: 'auto',
      '& > ul > *': {
        borderTop: [1, 'solid', styling.colors.theme.primary700],
        borderBottom: 'none',
      },
    },
  },
  { name: 'editDrawerSpaceDrawerContent' }
);
