import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const AvatarCircle: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <circle vectorEffect="non-scaling-stroke" cx="14" cy="14" r="12.62" stroke={color} /><circle vectorEffect="non-scaling-stroke" cx="14" cy="11.87" r="3.96" stroke={color} /><path vectorEffect="non-scaling-stroke" stroke={color} d="M7.27 24.39c0-3.72 3.01-6.73 6.73-6.73s6.73 3.01 6.73 6.73" /><circle vectorEffect="non-scaling-stroke" cx="14" cy="14" r="12.62" stroke={color} />
    </g>
  </WebsiteIcon>
);

export default memo(AvatarCircle);
