import {
  isArray as lodashIsArray,
  isEmpty as lodashIsEmpty,
  isEqual as lodashIsEqual,
  isNil as lodashIsNil,
  isNumber as lodashIsNumber,
  isString as lodashIsString,
} from 'lodash';

export const isArray = lodashIsArray;
export const isEmpty = lodashIsEmpty;
export const isEqual = lodashIsEqual;
export const isNil = lodashIsNil;
export const isNumber = lodashIsNumber;
export const isString = lodashIsString;
