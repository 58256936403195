import { EMPTY_OBJ } from '@kitted/shared-utils';

import {
  AdditionalPropsForInteractiveComponents,
  AdditionalPropsForNonInteractiveComponents,
  Components,
  InteractionProps,
} from './types';

const getInteractionProps = (
  interactionProps: InteractionProps,
  components: Components,
  additionalPropsForInteractiveComponents: AdditionalPropsForInteractiveComponents = EMPTY_OBJ,
  additionalPropsForNonInteractiveComponents: AdditionalPropsForNonInteractiveComponents = EMPTY_OBJ
) => {
  const { nonInteractive, to, href, onClick } = interactionProps;
  const { defaultComponent, internalLinkComponent, externalLinkComponent } =
    components;

  if (nonInteractive) {
    return {
      ...additionalPropsForNonInteractiveComponents,
      as: defaultComponent,
    };
  }
  if (to) {
    return {
      ...additionalPropsForInteractiveComponents,
      as: internalLinkComponent,
      to,
      onClick,
    };
  }
  if (href) {
    return {
      ...additionalPropsForInteractiveComponents,
      as: externalLinkComponent,
      href,
      onClick,
    };
  }
  return {
    ...additionalPropsForInteractiveComponents,
    as: defaultComponent,
    onClick,
  };
};

export default getInteractionProps;
