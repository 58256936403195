import { generatePath } from 'react-router-dom';

import { InternalRoute } from '../types';
import { extractPathParamsAndBackfillParams } from './extractPathParamsAndBackfillParams';

export const interpolateRoutePath = (
  route: InternalRoute,
  interpolationParams: Record<string, string | undefined>,
  shouldErrorOnMissingRequiredParams = true
): string => {
  const sanitizedParams = extractPathParamsAndBackfillParams(
    route.path,
    interpolationParams,
    shouldErrorOnMissingRequiredParams
  );

  return generatePath(route.path, sanitizedParams);
};
