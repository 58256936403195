import { Fragment } from 'react';
import { ResourceType } from '@kitted/resource-service-models';
import { EMPTY_OBJ } from '@kitted/shared-utils';
import { Chain, LandscapeCard, VideoPlay } from '@kitted/website-icons';

import { FormDataSchema } from '../../../../contexts/FormContext';
import { dataSchema as imageDataSchema } from '../../../../routes/resource/image/routes/edit/ImageResourceEditPage/constants/dataSchema';
import ImageResourceEditPageContentPageSection from '../../../../routes/resource/image/routes/edit/ImageResourceEditPage/ImageResourceEditPageContentPageSection';
import { dataSchema as linkDataSchema } from '../../../../routes/resource/link/routes/edit/LinkResourceEditPage/constants/dataSchema';
import LinkResourceEditPageContentPageSection from '../../../../routes/resource/link/routes/edit/LinkResourceEditPage/LinkResourceEditPageContentPageSection';
import { dataSchema as videoDataSchema } from '../../../../routes/resource/video/routes/edit/VideoResourceEditPage/constants/dataSchema';
import VideoResourceEditPageContentPageSection from '../../../../routes/resource/video/routes/edit/VideoResourceEditPage/VideoResourceEditPageContentPageSection';
import { EditDrawerSpaceDrawerItem } from '../../../EditDrawerSpace/types';
import { ResourceModelCreateProps } from '../../types';

export const getInitialData = (
  resourceType: ResourceType,
  initialData: ResourceModelCreateProps['initialData']
) => {
  switch (resourceType) {
    case ResourceType.Image:
      return {
        title: '',
        ...Object(initialData),
      };
    case ResourceType.Video:
      return {
        title: '',
        videoUrl: '',
        description: '',
        ...Object(initialData),
      };
    case ResourceType.Link:
      return {
        title: '',
        linkUrl: '',
        description: '',
        ...Object(initialData),
      };
    default:
      return EMPTY_OBJ;
  }
};

export const getDataSchema = (resourceType: ResourceType): FormDataSchema => {
  switch (resourceType) {
    case ResourceType.Image:
      return imageDataSchema;
    case ResourceType.Video:
      return videoDataSchema;
    case ResourceType.Link:
      return linkDataSchema;
    default:
      return {
        fields: {},
      };
  }
};

export const getSections = (
  resourceType: ResourceType
): [Record<string, EditDrawerSpaceDrawerItem>, string] => {
  switch (resourceType) {
    case ResourceType.Image:
      return [
        {
          page: {
            drawerGroup: 'top',
            icon: LandscapeCard,
            title: 'Resource page',
            component: ImageResourceEditPageContentPageSection,
          },
        },
        'page',
      ];
    case ResourceType.Video:
      return [
        {
          page: {
            drawerGroup: 'top',
            icon: VideoPlay,
            title: 'Resource page',
            component: VideoResourceEditPageContentPageSection,
          },
        },
        'page',
      ];
    case ResourceType.Link:
      return [
        {
          page: {
            drawerGroup: 'top',
            icon: Chain,
            title: 'Resource page',
            component: LinkResourceEditPageContentPageSection,
          },
        },
        'page',
      ];
    default:
      return [
        {
          page: {
            drawerGroup: 'top',
            title: 'Not implemented',
            component: Fragment,
          },
        },
        'page',
      ];
  }
};
