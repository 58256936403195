import { styling } from '@kitted/design-system';

import { AccountAvatarProps } from '../../types';

export const getSpinnerSizeFromSize = (size: AccountAvatarProps['size']) => {
  switch (size) {
    case 'xl':
      return styling.sizes.utils.spacingCustomFactor(50);
    case 'lg':
      return styling.sizes.utils.spacingCustomFactor(40);
    case 'md':
      return styling.sizes.utils.spacingCustomFactor(20);
    case 'sm':
    default:
      return styling.sizes.utils.spacingCustomFactor(10);
  }
};
