import { memo } from 'react';
import { Divider } from '@kitted/shared-components';

import { FormProvider } from '../../../contexts/FormContext';
import Form from '../../Form';
import FormFields from '../../FormFields';
import AuthenticationModalContentWrapper from '../AuthenticationModalContentWrapper';
import useAddButtonModalContent from './hooks/useAddButtonModalContent';
import ConfirmButton from './ConfirmCancelButtons';
import { dataSchema, layoutSchema } from './constants';
import useStyles from './styles';
import { AddButtonModalProps } from './types';

const AddButtonModalContent = ({
  onSave,
  onClose,
  preventClose,
  initialData,
  title,
}: AddButtonModalProps) => {
  const styles = useStyles();
  const { initialValues, onSubmit, onSubmitSuccess } = useAddButtonModalContent(
    initialData,
    onSave,
    onClose
  );
  return (
    <AuthenticationModalContentWrapper
      preventClose={preventClose}
      title={title}
      isRequired
      onClose={onClose}
    >
      <FormProvider dataSchema={dataSchema} initialValues={initialValues}>
        <Form onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess}>
          <FormFields layout={layoutSchema} />
          <Divider
            className={styles.divider}
            isSubtle
            spacing="lg"
            theme="primary"
          />
          <ConfirmButton onClose={onClose} />
        </Form>
      </FormProvider>
    </AuthenticationModalContentWrapper>
  );
};

export default memo(AddButtonModalContent);
