import { useCallback, useEffect, useState } from 'react';
import { EMPTY_ARR } from '@kitted/shared-utils';

import { Modal, ModalData } from '../../types';
import {
  getDeregisterModal,
  getRegisterModal,
  handleModalsChange,
} from './logic';

const useModalManagement = () => {
  const [modals, setModals] = useState<ModalData['modals']>(
    EMPTY_ARR as ModalData['modals']
  );

  const registerModal = useCallback(
    (modalId: string, modalConfig: Modal) =>
      getRegisterModal(setModals, modalId, modalConfig),
    [setModals]
  );

  const deregisterModal = useCallback(
    (modalId: string) => getDeregisterModal(setModals, modalId),
    [setModals]
  );

  useEffect(() => handleModalsChange(modals), [modals]);

  return {
    registerModal,
    deregisterModal,
    modals,
  };
};

export default useModalManagement;
