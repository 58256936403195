import { memo } from 'react';
import { Typography } from '@kitted/shared-components';

import MobileOverlay from '../../../../../MobileOverlay';
import useMobileSubjectFilterDropdown from './hooks/useMobileSubjectFilterDropdown';
import { MobileSubjectFilterDropdownProps } from './types';

const MobileSubjectFilterDropdown = ({
  children,
  isOpen,
  onClickOutside,
}: MobileSubjectFilterDropdownProps) => {
  const { listRef } = useMobileSubjectFilterDropdown(isOpen, onClickOutside);

  return (
    <MobileOverlay isOpen={isOpen}>
      <Typography ref={listRef} as="ul" variant="input">
        {children}
      </Typography>
    </MobileOverlay>
  );
};

export default memo(MobileSubjectFilterDropdown);
