/* eslint-disable @typescript-eslint/no-explicit-any */
import { RequestConfig } from '../../../../services/requests/types';
import { ErrorCapture } from '../../../ErrorCaptureContext/types';
import { ToastApi } from '../../../ToastContext/types';
import { ServiceRequestName } from '../../types';

export const getServiceBaseUrl = (
  isLocalDevelopment: boolean,
  environmentDomain: string,
  serviceName: ServiceRequestName
) => {
  let baseUrl = `https://api.${environmentDomain}/${serviceName}`;

  // TODO: joecoady - Remove this when the dev server is setup again to use subdomains
  if (isLocalDevelopment) {
    // Override it for dev server
    baseUrl = `/api/${serviceName}`;
    // personal development server
    // baseUrl = `https://api.${serviceName}.haxxxton.development.kitted.app`;
  }

  return baseUrl;
};

export const addServiceBaseUrlToConfig = (
  isLocalDevelopment: boolean,
  environmentDomain: string,
  serviceName: ServiceRequestName,
  config: RequestConfig
) => ({
  ...config,
  baseURL: getServiceBaseUrl(
    isLocalDevelopment,
    environmentDomain,
    serviceName
  ),
});

const removeStoryErrorText = (message = '') =>
  message.replace(/^story.+\[(.+)\]/, '$1');

const getPrettyErrorMessage = (errorObject: any) => {
  const body =
    removeStoryErrorText(errorObject?.response?.data?.errorText) ||
    errorObject.message;

  return {
    title: 'Error',
    body,
  };
};

export const launchErrorToast = (
  registerToast: ToastApi['registerToast'],
  error: any,
  idempotencyKey: string
) => {
  const { title, body } = getPrettyErrorMessage(error);

  registerToast(`requestError-${idempotencyKey}`, {
    theme: 'error',
    title,
    body,
  });
};

export const triggerErrorCapture = (
  errorCapture: ErrorCapture,
  originalError: any,
  config: RequestConfig
) => {
  class RequestError extends Error {
    constructor(message: string) {
      super(message);
      this.name = 'RequestError';
    }
  }

  try {
    throw new RequestError(originalError.message);
  } catch (error: any) {
    const body =
      originalError?.response?.data?.errorText || originalError.message;
    errorCapture.error(`Request Error - ${body}`, error, {
      message: originalError.message,
      body,
      url: originalError.request?.responseURL,
      config,
    });
  }
};
