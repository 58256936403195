import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0014: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 128 110"
		height={110}
		width={128}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M96.47 23.13c5.9-.79 10.04-6.2 9.24-12.1-.79-5.89-6.21-10.03-12.1-9.24-5.89.78-10.03 6.2-9.24 12.1s6.21 10.03 12.1 9.24M97.9 108.3c-.15 0-.31 0-.45-.02-1.5-.1-2.9-.81-3.9-1.97a5.7 5.7 0 0 1-1.39-4.18c.48-6.69-2.49-18.46-3.22-21.23-2.32-2.55-12.76-6.59-17.09-8.12-3.22 4.52-9.62 11.84-18.5 14.28-.24.06-.47.08-.75.08-3.1 0-13.6-3.22-27.23-10.38a5.7 5.7 0 0 1-2.82-3.38c-.46-1.47-.31-3.02.4-4.38.99-1.9 2.94-3.08 5.09-3.08.93 0 1.85.23 2.67.66 9.02 4.74 16.01 7.59 19.62 8.94 2.65-2.14 4.09-3.41 7.19-7.19 4.4-6.3 12.49-18.63 17.65-31.03-.26 0-.54-.02-.83-.02-6.74 0-18.37 2.89-23.05 4.13a4.943 4.943 0 0 1-6.03-3.52c-.7-2.63.88-5.34 3.51-6.03l1.01-.27c4.15-1.14 15.19-4.15 23.95-4.15 1.39 0 2.68.08 3.83.23.65.09 1.27.2 1.88.32 1.5.32 2.83.76 3.96 1.33.38.15.84.35 1.3.56l.76.36c.6.3 1.21.65 1.81 1.01 1.24.75 2.41 1.61 3.49 2.56q.42.375.84.78c.38.32.65.53.93.73 2.89 2.11 6.9 3.18 11.91 3.18h0c7.47 0 14.46-2.4 14.53-2.42.59-.26 1.25-.39 1.92-.39a4.944 4.944 0 0 1 1.96 9.48c-.49.21-4.98 2.08-10.98 2.89-2.07.27-4.3.42-6.47.42h0c-3.99 0-9.57-.49-14.15-2.75-2.59 7.28-6.12 11.89-8.73 15.29-.29.38-.57.74-.84 1.1 6.19 3.02 13.8 7.4 16.94 11.87l.46.66c.05.08.1.16.12.25l.23.78c.2.69 4.94 17 4.21 27.28-.22 3-2.74 5.34-5.74 5.34" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="m53.51 52.93-41.54.08M37.05 26.94H2.16M55.24 13.93H19.47M35.05 39.94H23.51" />
    </g>
  </CardIcon>
);

export default memo(In0014);
