import { AccessToken } from '../../../contexts/AuthTokensContext/types';
import { RequestConfig } from '../types';

export const getAuthConfig = (
  config: RequestConfig,
  bearerToken: AccessToken
) => {
  if (bearerToken) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${bearerToken}`,
      },
    };
  }
  return config;
};
