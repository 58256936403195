import { useCallback, useEffect, useRef, useState } from 'react';

import { useDrawerSpaceWidthQuery } from '../../../DrawerSpace/context/DrawerSpaceContainerContext';

const useMobileOverlay = (isOpen: boolean) => {
  const hasBeenOpenRef = useRef(false);
  const [isClosing, setIsClosing] = useState(false);
  const isTablet = useDrawerSpaceWidthQuery('isTablet');

  const setHasBeenOpen = useCallback(() => {
    hasBeenOpenRef.current = true;
  }, []);

  useEffect(() => {
    if (isOpen && hasBeenOpenRef.current) {
      setIsClosing(true);
    }
  }, [isOpen, setIsClosing]);

  useEffect(() => {
    if (isOpen) {
      window.document.scrollingElement?.classList.add('lock-scroll-topBar');
      setHasBeenOpen();
    } else {
      window.document.scrollingElement?.classList.remove('lock-scroll-topBar');
    }
    return () => {
      window.document.scrollingElement?.classList.remove('lock-scroll-topBar');
    };
  }, [isOpen, setHasBeenOpen]);

  const onExited = useCallback(() => {
    setIsClosing(false);
  }, [setIsClosing]);

  useEffect(() => {
    if (isTablet) {
      setIsClosing(false);
    }
  }, [isTablet]);

  return {
    onExited,
    isClosing,
  };
};

export default useMobileOverlay;
