import { z } from 'zod';

import { WebsocketServerMessageType } from './enum';
import { WebsocketServerMessageSchema } from './WebsocketServerMessage';

export const WebsocketPongServerMessageSchema =
  WebsocketServerMessageSchema.merge(
    z.object({
      messageType: z.literal(WebsocketServerMessageType.Pong),
    })
  );

// Override the any type due to the schema limitation
export type WebsocketPongServerMessage = z.infer<
  typeof WebsocketPongServerMessageSchema
>;
