import { RefinementCtx, z } from 'zod';

export function notBoth<
  A,
  K1 extends Extract<keyof A, string>,
  K2 extends Extract<keyof A, string>,
  R extends A &
    (
      | (Pick<A, K1> & { [P in K2]?: undefined })
      | (Pick<A, K2> & { [P in K1]?: undefined })
      | ({ [P in K2]?: undefined } & { [P in K1]?: undefined })
    ),
>(key1: K1, key2: K2): (arg: A, ctx: RefinementCtx) => arg is R {
  return (arg, ctx): arg is R => {
    const key1IsDefined = arg[key1] !== undefined;
    const key2IsDefined = arg[key2] !== undefined;
    if (key1IsDefined && key2IsDefined) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Both ${key1} and ${key2} cannot be filled at the same time`,
      });
      return false;
    }
    return true;
  };
}
