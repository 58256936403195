import { useMatch } from 'react-router-dom';
import { routes } from '@kitted/website-constants';

import useIsLoggedIn from '../../../../../../../contexts/UserContext/hooks/useIsLoggedIn';
import useIsLoggingIn from '../../../../../../../contexts/UserContext/hooks/useIsLoggingIn';

const useAccountSignup = () => {
  const { isLoggedIn } = useIsLoggedIn();
  const { isLoggingIn } = useIsLoggingIn();
  const isOnMMM = useMatch(routes.mentalModelMondays.path);

  return {
    isLoggedIn,
    isLoggingIn,
    isOnMMM: !!isOnMMM,
  };
};

export default useAccountSignup;
