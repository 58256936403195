import { z } from 'zod';

import {
  ImageResourceCardLoadedModelSchema,
  ImageResourceCardUnloadedModelSchema,
} from './ImageResource';
import {
  LinkResourceCardLoadedModelSchema,
  LinkResourceCardUnloadedModelSchema,
} from './LinkResource';
import {
  VideoResourceCardLoadedModelSchema,
  VideoResourceCardUnloadedModelSchema,
} from './VideoResource';

export const AnyResourceCardLoadedModelSchema = z.union([
  ImageResourceCardLoadedModelSchema,
  LinkResourceCardLoadedModelSchema,
  VideoResourceCardLoadedModelSchema,
]);

export const AnyResourceCardUnloadedModelSchema = z.union([
  ImageResourceCardUnloadedModelSchema,
  LinkResourceCardUnloadedModelSchema,
  VideoResourceCardUnloadedModelSchema,
]);

export type AnyResourceCardLoadedModel = z.infer<
  typeof AnyResourceCardLoadedModelSchema
>;
export type AnyResourceCardUnloadedModel = z.infer<
  typeof AnyResourceCardUnloadedModelSchema
>;

export type AnyResourceCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? AnyResourceCardLoadedModel
    : AnyResourceCardUnloadedModel;
