import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      flexGrow: 1,
      paddingRight: styling.sizes.theme.spacing.xs,
      '& > *': {
        textAlign: 'left',
        display: 'block',
        marginTop: -2,
        marginBottom: -2,
      },
    },
  },
  { name: 'accountMenuItemBody' }
);
