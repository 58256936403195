import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const CardsKitted: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 22 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="m20.47 8.33-12.8 12.8M19.14 26.62H9.43c-.98 0-1.77-.79-1.77-1.77V9.65c0-.98.79-1.77 1.77-1.77h9.71c.98 0 1.77.79 1.77 1.77v15.2c0 .98-.79 1.77-1.77 1.77" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M5.9 23.37c-.84 0-1.52-.68-1.52-1.52V6.16c0-.84.68-1.52 1.52-1.52h10.2c.84 0 1.52.68 1.52 1.52" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M2.61 20.11c-.84 0-1.52-.68-1.52-1.52V2.9c0-.84.68-1.52 1.52-1.52h10.2c.84 0 1.52.68 1.52 1.52M20.39 26.09l-8.84-8.84 8.92-8.92" />
    </g>
  </WebsiteIcon>
);

export default memo(CardsKitted);
