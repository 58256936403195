import { z } from 'zod';
import { CMSImageResourceUnloadedModelSchema } from '@kitted/contentful-service-models';
import {
  ImageAssetModelSchema,
  KittedModelTypeEnum,
  makeKittedModelReferenceSchema,
  oneOf,
  RimgSlugSchema,
} from '@kitted/kitted-models';

import { ResourceType } from '../Enum';
import {
  ResourceBaseLoadedModelSchema,
  ResourceBaseSaveModelSchema,
  ResourceBaseUnloadedModelSchema,
} from '../ResourceBaseModel';

export const ImageResourceBaseModelSchema = z.object({
  slug: RimgSlugSchema,

  resourceType: z.literal(ResourceType.Image),
  kittedModelType: z.literal(KittedModelTypeEnum.ResourceImageResource),

  keyImageImageAsset: makeKittedModelReferenceSchema(
    ImageAssetModelSchema,
    true
  ),
  showTitle: z.boolean().optional(),
  references: z.string().optional(),

  cmsSrcMessage: CMSImageResourceUnloadedModelSchema.optional(),
});

export const ImageResourceLoadedModelSchema =
  ResourceBaseLoadedModelSchema.merge(ImageResourceBaseModelSchema);

export const ImageResourceUnloadedModelSchema =
  ResourceBaseUnloadedModelSchema.merge(ImageResourceBaseModelSchema).merge(
    z.object({
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        false
      ),
    })
  );

export const ImageResourceSaveModelSchema =
  ImageResourceUnloadedModelSchema.merge(ResourceBaseSaveModelSchema)
    .merge(
      z.object({
        slug: ImageResourceUnloadedModelSchema.shape.slug.optional(),
        keyImageImageAsset:
          ImageResourceUnloadedModelSchema.shape.keyImageImageAsset.optional(),
        keyImageBlobId: z.string().optional().nullable(),

        // showTitle: ImageResourceUnloadedModelSchema.shape.showTitle.optional(),
        // references: ImageResourceUnloadedModelSchema.shape.references.optional(),
      })
    )
    .superRefine(oneOf('keyImageBlobId', 'keyImageImageAsset'));

export type ImageResourceLoadedModel = z.infer<
  typeof ImageResourceLoadedModelSchema
>;
export type ImageResourceUnloadedModel = z.infer<
  typeof ImageResourceUnloadedModelSchema
>;
export type ImageResourceSaveModel = z.infer<
  typeof ImageResourceSaveModelSchema
>;

export type ImageResourceModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? ImageResourceLoadedModel : ImageResourceUnloadedModel;
