import { z } from 'zod';
import { KittedModelBaseSchema } from '@kitted/kitted-models';
import { DateISOSchema } from '@kitted/platform-models';

export const CMSArticleBlockExecutiveBoardModelSchema =
  KittedModelBaseSchema.merge(
    z.object({
      createdByAuthorId: z.string(),
      createdByUserId: z.string(),

      createdAt: DateISOSchema,
      updatedAt: DateISOSchema,
    })
  );
export type CMSArticleBlockExecutiveBoardModel = z.infer<
  typeof CMSArticleBlockExecutiveBoardModelSchema
>;
