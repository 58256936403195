import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    menuContainer: {
      left: 0,
      right: 0,
      zIndex: styling.zIndex.drawerMainMenu - 1,
      minWidth: styling.sizes.context.cardFeature.width,
      display: 'flex',
      flexDirection: 'column',
    },
    menuContainerInDynamicTopBar: {},
    menuContainerOpen: {
      top: styling.sizes.context.topBar.height,
      '$menuContainerInDynamicTopBar&': {
        top: styling.sizes.context.dynamicTopBar.mobileHeight,
      },
    },
    menuContainerInner: {
      flexGrow: 1,
      overflow: 'auto',
      '&:before': {
        ...styling.utils.absoluteFull(),
        content: '""',
        background: styling.colors.context.background,
        opacity: 0.9,
        mixBlendMode: 'multiply',
      },
    },
    children: {
      position: 'relative',
      backgroundColor: styling.colors.context.background,
      boxShadow: styling.shadows.context.dropdowns,
    },
  },
  { name: 'mobileOverlay' }
);
