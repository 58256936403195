import { useCallback, useEffect, useRef, useState } from 'react';

import useToastApi from '../../../../contexts/ToastContext/hooks/useToastApi';
import {
  handleAutoClose,
  handleMouseEnterLeave,
  handleOnClose,
  handleOnExited,
  handleOnMount,
} from '../../logic';
import { ToastProps } from '../../types';

const useToast = (id: ToastProps['id'], duration: number) => {
  const { deregisterToast, publishToastEvent } = useToastApi();

  const [isHovered, setIsHovered] = useState(true);
  const [isIn, setIsIn] = useState<boolean>(true);
  const closeTimerRef = useRef<number>();

  const onClose = useCallback(() => {
    handleOnClose(setIsIn);
    publishToastEvent(id, 'close');
  }, [setIsIn, publishToastEvent, id]);

  const onMouseEnter = useCallback(
    () => handleMouseEnterLeave(setIsHovered, true),
    [setIsHovered]
  );

  const onMouseLeave = useCallback(
    () => handleMouseEnterLeave(setIsHovered, false),
    [setIsHovered]
  );

  const onExited = useCallback(
    () => handleOnExited(id, deregisterToast),
    [id, deregisterToast]
  );

  const setCloseTimerRef = useCallback((ref: number | undefined) => {
    closeTimerRef.current = ref;
  }, []);

  useEffect(
    () =>
      handleAutoClose({
        isIn,
        isHovered,
        duration,
        onClose,
        setCloseTimerRef,
        closeTimerRef: closeTimerRef.current,
      }),
    [isIn, isHovered, duration, onClose, setCloseTimerRef]
  );

  useEffect(
    () => handleOnMount(setIsHovered, closeTimerRef.current, setCloseTimerRef),
    [setCloseTimerRef]
  );

  return {
    onClose,
    onMouseEnter,
    onMouseLeave,
    onExited,
    isHovered,
    isIn,
  };
};

export default useToast;
