import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    container: {
      cursor: 'pointer',
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      position: 'relative',
      padding: [0, 32],
      textDecoration: 'none',
      '&:focus': {
        outline: 'none',
      },
    },
    containerWithIcon: {
      paddingLeft: 99,
    },
    containerIsSelected: {
      cursor: 'default',
    },
    icon: {
      position: 'absolute',
      left: 39,
      top: 0,
      bottom: 0,
      width: 32,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  { name: 'inputSelectOption' }
);
