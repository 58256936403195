import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      containerType: 'inline-size',
      '&:hover:not($errored):not($focused)': {
        boxShadow: [
          [
            styling.shadows.utils.boxShadowToString({
              inset: 'inset',
              x: 0,
              y: 0,
              blur: 0,
              spread: 1,
              color: 'var(--inputImageHoverBoxShadowColor)',
            }),
          ],
          '!important',
        ],
      },
    },
    'theme-primary': {
      '--inputImageHoverBoxShadowColor': styling.colors.theme.secondary300_20,
    },
    'theme-secondary': {
      '--inputImageHoverBoxShadowColor': styling.colors.theme.primary900_20,
    },
    errored: {},
    focused: {},
    label: {
      marginBottom: 0,
    },
    internals: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: styling.sizes.theme.spacing.xs,
      gap: styling.sizes.theme.spacing.sm,
      '& $button': {
        width: '100%',
      },
      [styling.container.utils.containerQuery('width', 420)]: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& $button': {
          width: 'auto',
        },
      },
    },
    button: {},
  },
  { name: 'inputImage' }
);
