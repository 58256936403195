/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';

import useCardFooter from './hooks/useCardFooter';
import { CardFooterProps } from './types';

const CardFooter = ({
  isMenuOpen,
  isFeatured,
  subType,
  type,
}: CardFooterProps) => {
  const { Component, componentProps } = useCardFooter(
    type,
    subType,
    isFeatured
  );

  if (!Component) return null;

  return <Component isMenuOpen={isMenuOpen} {...componentProps} />;
};

export default memo(CardFooter);
