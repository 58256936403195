import {
  FieldValue,
  FormApi,
  FormDataSchema,
} from '../../../../contexts/FormContext';
import filterFalseyValues from '../../../../services/helpers/filterFalseyValues';
import { FormLayoutSchema } from '../../../FormFields/types';
import { FormFieldSchema } from '../../types';

export const generateFieldsFromDataAndLayout = (
  dataFieldsSchema: FormDataSchema['fields'],
  layoutFieldsSchema: FormLayoutSchema['fields']
): FormFieldSchema[] => {
  const fields = layoutFieldsSchema.map((field) => {
    const mappedDataFieldSchema = dataFieldsSchema[field.key];
    if (!mappedDataFieldSchema) return null;

    return {
      ...field,
      ...mappedDataFieldSchema,
    };
  });
  return filterFalseyValues(fields);
};

export const handleFieldChange =
  (fieldKey: string, setFormData: FormApi['setFormData']) =>
  (newFieldValue: FieldValue) => {
    setFormData((currentValue) => ({
      ...currentValue,
      [fieldKey]: newFieldValue,
    }));
  };

export const getWidthsByFieldKey = (
  layoutFields: FormLayoutSchema['fields'],
  fieldKey: string
) => {
  const fieldLayout = layoutFields.find((field) => field.key === fieldKey);

  return fieldLayout?.widths;
};
