import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      ...styling.utils.absoluteFull(),
    },
    loadingPulse: {
      ...styling.utils.absoluteFull(),
      width: 'var(--createAndLoginButtonLoadingPercentage, 0)',
      zIndex: 1,
      transition: styling.transitions.utils.generate(['width']),
    },
    text: {
      ...styling.utils.absoluteFull(),
      zIndex: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  { name: 'loadingProgress' }
);
