import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0021: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 96 94"
		height={94}
		width={96}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M91.95 50.79 60.46 82.28c-13.27 13.27-34.87 13.27-48.15 0l-.68-.68c-13.27-13.27-13.27-34.87 0-48.15L43.12 1.97l15.31 15.3-31.5 31.49c-4.83 4.83-4.83 12.71 0 17.54l.68.68c4.84 4.84 12.7 4.84 17.54 0l31.49-31.49 15.3 15.3ZM34.28 20.62l10.25 10.25M67.84 54.18l10.17 10.17" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M94.33 1.76 82.75 20.61l-4.13-10.9-12.44 18.28" />
    </g>
  </CardIcon>
);

export default memo(Sn0021);
