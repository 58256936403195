import { useMemo } from 'react';

import useAuthTokensData from '../../../AuthTokensContext/hooks/useAuthTokensData';
import { LoginState } from '../../types';
import useUserData from '../useUserData';

const useIsLoggingIn = () => {
  const { loginState } = useUserData();
  const { hasCheckedTokens } = useAuthTokensData();

  const isLoggingIn = useMemo(() => {
    if (loginState === LoginState.LOADING) return true;
    if (!hasCheckedTokens) return true;
    return false;
  }, [hasCheckedTokens, loginState]);

  return {
    isLoggingIn,
  };
};

export default useIsLoggingIn;
