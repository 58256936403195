import { useCallback, useEffect } from 'react';
import { AccountModel } from '@kitted/account-service-models';
import { UserModel } from '@kitted/auth-service-models';
import { AuthorModel } from '@kitted/author-service-models';
import { KittedModelTypeEnum, KittedServiceEnum } from '@kitted/kitted-models';
import { WebsocketModelUpdateServerMessage } from '@kitted/websocket-service-models';

import logger from '../../../../../../services/logger';
import { useSubscribeToWebsocketModelUpdateServerMessage } from '../../../../../WebsocketContext';
import useStoreApi from '../../../../hooks/useStoreApi';
import useStoreSection from '../../../../hooks/useStoreSection';
import { StoreSectionInitializerProps } from '../../../types';

const useUserStoreInitializer = (
  onSectionLoaded: StoreSectionInitializerProps['onSectionLoaded']
) => {
  const { createOrUpdateStoreSection } = useStoreApi();
  const { updateStoreSectionItem } = useStoreSection('User');

  const updateUserStoreSectionFetcher = useCallback(() => {
    createOrUpdateStoreSection('User');
    onSectionLoaded('User');
  }, [createOrUpdateStoreSection, onSectionLoaded]);

  useEffect(updateUserStoreSectionFetcher, [updateUserStoreSectionFetcher]);

  const handleWebsocketItemUpdated = useCallback(
    ({ model, service }: WebsocketModelUpdateServerMessage) => {
      if (model) {
        if (service === KittedServiceEnum.Account) {
          if (model.kittedModelType === KittedModelTypeEnum.AccountAccount) {
            logger.log('[accountServiceWebsocket] account model update', model);
            const account = model as unknown as AccountModel;
            updateStoreSectionItem(account, 'account');
          }
        } else if (service === KittedServiceEnum.Author) {
          if (model.kittedModelType === KittedModelTypeEnum.AuthorAuthor) {
            logger.log('[authorServiceWebsocket] author model update', model);
            const author = model as unknown as AuthorModel;
            updateStoreSectionItem(author, 'author');
          }
        } else if (service === KittedServiceEnum.Auth) {
          if (
            model.kittedModelType === KittedModelTypeEnum.AuthUserAttributes
          ) {
            logger.log('[authServiceWebsocket] user model update', model);
            const user = model as unknown as UserModel;
            updateStoreSectionItem(user, 'user');
          }
        }
      }
    },
    [updateStoreSectionItem]
  );

  useSubscribeToWebsocketModelUpdateServerMessage(handleWebsocketItemUpdated);
};

export default useUserStoreInitializer;
