import { z } from 'zod';
import {
  ArticleSlugSchema,
  CardLoadedModelSchema,
  CardType,
  CardUnloadedModelSchema,
  ImageAssetModelSchema,
  KitSlugSchema,
  makeKittedModelReferenceSchema,
  SubjectSlugSchema,
} from '@kitted/kitted-models';

const DeckCardLoadedBaseModelSchema = z.object({
  cardType: z.literal(CardType.Deck),
  title: z.string(),
  shortTitle: z.string(),

  kitSlug: KitSlugSchema.optional(),
  articleSlug: ArticleSlugSchema.optional(),
  subjectSlug: SubjectSlugSchema,

  shopLink: z.string(),

  imageAssets: makeKittedModelReferenceSchema(ImageAssetModelSchema, true)
    .array()
    .optional(),

  videoUrl: z.string().url().optional(),
});

export const DeckCardLoadedModelSchema = CardLoadedModelSchema.merge(
  DeckCardLoadedBaseModelSchema
);
export const DeckCardUnloadedModelSchema = CardUnloadedModelSchema.merge(
  DeckCardLoadedBaseModelSchema.merge(
    z.object({
      imageAssets: makeKittedModelReferenceSchema(ImageAssetModelSchema, false)
        .array()
        .optional(),
    })
  )
);

export type DeckCardLoadedModel = z.infer<typeof DeckCardLoadedModelSchema>;
export type DeckCardUnloadedModel = z.infer<typeof DeckCardUnloadedModelSchema>;

export type DeckCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? DeckCardLoadedModel : DeckCardUnloadedModel;
