import { memo } from 'react';
import clsx from 'clsx';

import useScaleContainer from './hooks/useScaleContainer';
import useStyles from './styles';
import { ScaleContainerProps } from './types';

const ScaleContainer = ({
  className,
  children,
  aspectRatio,
  maxWidth,
}: ScaleContainerProps) => {
  const { onSetRef, style } = useScaleContainer(aspectRatio, maxWidth);
  const styles = useStyles();
  return (
    <div ref={onSetRef} className={clsx(className, styles.container)}>
      <div className={styles.scaler} style={style}>
        {children}
      </div>
    </div>
  );
};

export default memo(ScaleContainer);
