import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      cursor: 'default',
      top: 0,
      left: 0,
      right: 0,
      background: styling.colors.theme.primary900,
      display: 'flex',
      flexDirection: 'column',
    },
  },
  { name: 'fullscreenModal' }
);
