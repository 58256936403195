import { useCallback, useMemo } from 'react';

import { useFormApi, useFormData } from '../../../../contexts/FormContext';
import { FormFieldsProps } from '../../types';
import {
  generateFieldsFromDataAndLayout,
  getWidthsByFieldKey,
  handleFieldChange,
} from './logic';

const useFormFields = (layout: FormFieldsProps['layout']) => {
  const { setFormData } = useFormApi();
  const { dataSchema, formInstanceId } = useFormData();

  const fields = useMemo(
    () => generateFieldsFromDataAndLayout(dataSchema.fields, layout.fields),
    [dataSchema.fields, layout.fields]
  );

  const onFieldChange = useCallback(
    (fieldKey: string) => handleFieldChange(fieldKey, setFormData),
    [setFormData]
  );

  const getWidths = useCallback(
    (fieldKey: string) => getWidthsByFieldKey(layout.fields, fieldKey),
    [layout.fields]
  );

  return {
    fields,

    onFieldChange,
    getWidths,
    formInstanceId,
  };
};

export default useFormFields;
