import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { DismissableLayerProps } from '@radix-ui/react-dismissable-layer';

import { PopoverProps } from '../../types';

const usePopover = (
  defaultOpen: boolean,
  controlledOpen: PopoverProps['open'],
  autoFocus: PopoverProps['autoFocus'],
  onInteractOutside: PopoverProps['onInteractOutside']
) => {
  const triggerRef = useRef<HTMLSpanElement>(null);
  const [open, setOpen] = useState<boolean>(defaultOpen);

  useEffect(() => {
    setOpen(controlledOpen || defaultOpen);
  }, [controlledOpen, defaultOpen, setOpen]);

  const onOpenAutoFocus = useMemo(() => {
    if (!autoFocus) {
      return (event: Event) => {
        event.preventDefault();
      };
    }
    return undefined;
  }, [autoFocus]);

  const handleInteractOutside = useCallback(
    (e: Event) => {
      if (triggerRef.current?.contains(e.target as Node)) {
        e?.preventDefault();
        e?.stopPropagation();
        return;
      }
      onInteractOutside?.();
    },
    [onInteractOutside]
  ) as DismissableLayerProps['onInteractOutside'];

  return {
    triggerRef,
    isOpen: open,
    onOpenAutoFocus,
    handleInteractOutside,
  };
};

export default usePopover;
