import { CardTypeValues } from '@kitted/kitted-models';

import { TypographyMaxLines } from '../../Typography/types';
import { cardTypeToMaxLinesMap, cardTypeToPrefixMap } from './constants';

export const getMaxLinesByType = (
  type: CardTypeValues,
  isFeatured: boolean,
  useDefaultMaxLines: boolean
): TypographyMaxLines => {
  if (cardTypeToMaxLinesMap[type]) {
    return cardTypeToMaxLinesMap[type][
      isFeatured && !useDefaultMaxLines ? 'featured' : 'default'
    ];
  }
  return 3;
};

export const getPrefixByType = (type: CardTypeValues) => {
  if (cardTypeToPrefixMap[type]) {
    return cardTypeToPrefixMap[type];
  }
  return null;
};
