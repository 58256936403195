import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      display: 'flex',
      gap: styling.sizes.theme.spacing.xs,
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  { name: 'alertButtons' }
);
