import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

import typographyConstants from '../Typography/constants';

export const overlayStyles = () => ({
  ...styling.utils.absoluteFull(),
  content: '""',
  backgroundColor: styling.colors.theme.primary600,
  mixBlendMode: 'multiply',
  opacity: 0,
  zIndex: 1,
  borderRadius: styling.sizes.context.card.radius,
  transition: styling.transitions.utils.generate(['opacity'], ['background']),
});

export const overlayBorderStyles = () => ({
  ...styling.utils.absoluteFull(),
  content: '""',
  zIndex: 11,
  pointerEvents: 'none',
  borderRadius: styling.sizes.context.card.radius,
  opacity: 0,
  transition: styling.transitions.utils.generate(['opacity']),
  boxShadow: styling.shadows.utils.boxShadowToString({
    inset: 'inset',
    x: 0,
    y: 0,
    blur: 0,
    spread: 1,
    color: styling.colors.theme.primary400,
  }),
});

export default createUseStyles(
  {
    container: {
      '--cardHeight': styling.utils.px(styling.sizes.context.card.height),
      '--cardWidth': styling.utils.px(styling.sizes.context.card.width),
      '--cardRadius': styling.utils.px(styling.sizes.context.card.radius),
      '--cardPaddingInline': styling.sizes.context.card.paddingInline,
      '--cardBackground': styling.colors.context.card.background,
      '--cardColor': styling.colors.context.card.color,
      '--cardSlugBottom': '18px',
      '--cardBottomPadding': `calc(var(--cardSlugBottom) + ${typographyConstants.cardSlug.lineHeight})`,
      '--cardBoxShadow': styling.shadows.utils.boxShadowToString(
        styling.shadows.context.card
      ),
      '--cardFocusBoxShadow': styling.shadows.utils.boxShadowToString(
        styling.shadows.context.cardFocus
      ),
      overflow: 'hidden',
      borderRadius: 'var(--cardRadius)',
      position: 'relative',
      zIndex: 1,
      height: 'var(--cardHeight)',
      width: 'var(--cardWidth)',
      transition: styling.transitions.utils.generate(['box-shadow']),
      boxShadow: styling.shadows.theme.transparent,
      textAlign: 'left',
      flexShrink: 0,
    },
    isActive: {
      zIndex: 2,
      '--cardBackground': styling.colors.context.card.hoverBackground,
      boxShadow: 'var(--cardBoxShadow)',
      '& $card': {
        '&.inverted': {
          '--cardBackground': styling.colors.theme.secondary400,
        },
        '& $cardSlug': {
          color: 'var(--cardSlugTypeColor)',
        },
      },
    },
    isSelected: {
      boxShadow: 'var(--cardFocusBoxShadow)',
    },
    isFeatured: {
      '--cardHeight': styling.utils.px(
        styling.sizes.context.cardFeature.height
      ),
      '--cardWidth': styling.utils.px(styling.sizes.context.cardFeature.width),
      '--cardRadius': styling.utils.px(
        styling.sizes.context.cardFeature.radius
      ),
      '--cardPaddingInline': styling.utils.px(
        styling.sizes.context.cardFeature.paddingInline
      ),
      '--cardSlugBottom': '19px',
      '--cardBottomPadding': `calc(var(--cardSlugBottom) + ${typographyConstants.cardSlugFeatured.lineHeight})`,
      '--cardBoxShadow': styling.shadows.utils.boxShadowToString(
        styling.shadows.context.cardFeature
      ),
    },
    card: {
      ...styling.utils.absoluteFull(),
      cursor: 'pointer',
      textDecoration: 'none',
      outline: 'none',
      background: 'var(--cardBackground)',
      color: 'var(--cardColor)',
      overflow: 'hidden',
      borderRadius: 'var(--cardRadius)',
      borderTopRightRadius: 'calc(var(--cardRadius) + 1px)',
      paddingBlock: 'var(--cardBottomPadding)',
      transition: styling.transitions.utils.generate(['background']),
      '&.inverted': {
        '--cardBackground': styling.colors.context.card.invertedBackground,
        '--cardColor': styling.colors.context.card.invertedColor,
      },
    },
    cardWithVideo: {
      paddingBlock: 0,
    },
    cardNonInteractive: {
      cursor: 'default',
    },
    cardSlug: {
      position: 'absolute',
      left: 0,
      right: 0,
      zIndex: 5,
      bottom: 'var(--cardSlugBottom)',
      paddingInline: 'var(--cardPaddingInline)',
      transition: styling.transitions.utils.generate(['color']),
    },
  },
  { name: 'card' }
);
