/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';

import Button from '../../../../Button';
import { useFormSubmitButtonData } from '../../../../Form/contexts/FormSubmitButtonContext';
import useStyles from './styles';

const ChangeButton = () => {
  const styles = useStyles();
  const formSubmitButtonProps = useFormSubmitButtonData();

  return (
    <Button
      className={styles.button}
      variant="solid"
      theme="primary"
      fullWidth
      {...formSubmitButtonProps}
    >
      Change Password
    </Button>
  );
};

export default memo(ChangeButton);
