import { useMemo } from 'react';

import { getLoadingTheme } from '../../logic';
import { ButtonProps } from '../../types';

const useButtonLoadingTheme = (
  variant: ButtonProps['variant'],
  theme: ButtonProps['theme'] = 'primary'
) => {
  const loadingTheme = useMemo(
    () => getLoadingTheme(theme, variant),
    [theme, variant]
  );

  return {
    loadingTheme,
  };
};

export default useButtonLoadingTheme;
