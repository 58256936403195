import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    containerWrapper: {
      position: 'relative',
      zIndex: 2,
      margin: [0, 'auto'],
      width: '100%',
      paddingInline: styling.sizes.context.gutteringSmW,
      [styling.breakpoints.context.tabletUpString]: {
        paddingInline: styling.sizes.context.gutteringW,
      },
      '& .full-bleed': {
        width: `calc(100% + ${styling.sizes.context.gutteringSmW * 2}px)`,
        marginLeft: -styling.sizes.context.gutteringSmW,
        marginRight: -styling.sizes.context.gutteringSmW,
        [styling.breakpoints.context.tabletUpString]: {
          width: `calc(100% + ${styling.sizes.context.gutteringW * 2}px)`,
          marginLeft: -styling.sizes.context.gutteringW,
          marginRight: -styling.sizes.context.gutteringW,
        },
      },
    },
    container: {
      display: 'grid',
      columnGap: 0,
      gridTemplateColumns: `1fr 100% 1fr`,
      [styling.breakpoints.context.tabletUpString]: {
        //   gridTemplateColumns: `1fr min(${styling.utils.px(
        //     styling.sizes.utils.gridColumns(2, styling.sizes.context.gutteringSmW)
        //   )}, 100%) 1fr`,
        gridTemplateColumns: `1fr min(${styling.utils.px(
          styling.sizes.utils.gridColumns(3, styling.sizes.context.gutteringW)
        )}, 100%) 1fr`,
      },
      '& > *': {
        gridColumn: 2,
      },
      '& .full-bleed': {
        gridColumn: '1 / 4',
      },
    },
  },
  { name: 'contentWrapper' }
);
