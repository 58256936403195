import { useCallback, useEffect, useState } from 'react';
import { GlobalScriptValues } from '@kitted/global-config-service-models';

import { networkRequest } from '../../../../services/requests';

const useGlobalScriptVariablesManagement = () => {
  const [values, setValues] = useState<GlobalScriptValues>();

  const fetchAllGlobalScriptVariables = useCallback(async () => {
    const allGlobalScriptVariables = await networkRequest<GlobalScriptValues>({
      method: 'GET',
      url: '/global-config/constants.json',
    });

    if (allGlobalScriptVariables?.data) {
      setValues(allGlobalScriptVariables.data);
    }
  }, [setValues]);

  useEffect(() => {
    fetchAllGlobalScriptVariables();
  }, [fetchAllGlobalScriptVariables]);

  return {
    values,
  };
};

export default useGlobalScriptVariablesManagement;
