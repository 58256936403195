import { useMemo } from 'react';
import { AnyCardModel } from '@kitted/card-service-models';
import { EMPTY_OBJ } from '@kitted/shared-utils';

import useCard from '../../../../contexts/CardsContext/hooks/useCard';
import { useFormData } from '../../../../contexts/FormContext';
import useBlobStorage from '../../../../hooks/useBlobStorage';
import { getCardProps } from '../../../CardList/logic';
import { CardWithImageAssetProps } from '../../../CardWithImageAsset/types';
import { PreviewCardProps } from '../../types';

const usePreviewCard = (
  cardId: PreviewCardProps['cardId'],
  overrideCardProps: PreviewCardProps['overrideCardProps'] = EMPTY_OBJ
) => {
  const { formData, formErrors } = useFormData();
  const { card, isLoading } = useCard<AnyCardModel>(cardId);
  const { constructBlobStorageContentUrl } = useBlobStorage();

  const cardProps = useMemo(() => {
    let imageAsset;
    let imageSrc;
    if (!formErrors.keyImage) {
      if (formData.keyImage && typeof formData.keyImage === 'string') {
        if (formData.keyImage.startsWith('T::')) {
          imageSrc = constructBlobStorageContentUrl(formData.keyImage);
        } else {
          imageAsset = {
            id: formData.keyImage,
          };
        }
      } else {
        imageAsset = undefined;
      }
    } else {
      imageAsset = undefined;
    }
    let additionalProps = overrideCardProps;
    if (card) {
      additionalProps = getCardProps(card);
    }

    return {
      ...additionalProps,
      title: formData.title ?? overrideCardProps.title ?? '',
      body: formData.description,
      imageAsset,
      imageSrc,
      nonInteractive: true,
      isFeatured: true,
      preventActive: true,
    } as CardWithImageAssetProps;
  }, [
    overrideCardProps,
    card,
    formData,
    formErrors.keyImage,
    constructBlobStorageContentUrl,
  ]);

  return {
    cardProps,
    isLoading: isLoading && cardId,
  };
};

export default usePreviewCard;
