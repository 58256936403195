import { useEffect } from 'react';
import { setDefaultOptions } from 'date-fns';
import { enGB, enUS } from 'date-fns/locale';

const useSetDefaultDateFnsOptions = () => {
  useEffect(() => {
    const isUS = window?.navigator?.language === 'en-US';
    setDefaultOptions({ locale: isUS ? enUS : enGB });
  }, []);
};

export default useSetDefaultDateFnsOptions;
