import { useEffect, useRef } from 'react';
import { WebsocketServiceEvent } from 'quidproquo-web';

import useEnvironmentDomain from '../../../../hooks/useEnvironmentDomain';
import { WebsocketApi } from '../../../../services/websocket';
import { useErrorCapture } from '../../../ErrorCaptureContext';
import {
  getServiceBaseUrl,
  triggerErrorCapture,
  WebsocketErrorEventInfo,
} from './logic';

export const useWebsocketManagement = () => {
  const environmentDomain = useEnvironmentDomain();
  const errorCapture = useErrorCapture();

  const websocketApiRef = useRef<WebsocketApi>(
    new WebsocketApi(getServiceBaseUrl(environmentDomain, 'websocket', 'ws'))
  );

  useEffect(() => {
    const api = websocketApiRef.current;
    const handle = api.subscribe(
      WebsocketServiceEvent.ERROR,
      (websocketService: WebsocketApi, errorEvent?: Event) => {
        const socket = api.getSocket();

        const captureData: WebsocketErrorEventInfo = {
          url: api.url,
          isDestroyed: api.hasBeenDestroyed(),

          type: errorEvent?.type || 'unknown',
          isTrusted: errorEvent?.isTrusted,
        };

        if (socket) {
          captureData.readyState = socket.readyState;
          captureData.protocol = socket.protocol;
          captureData.extensions = socket.extensions;
          captureData.bufferedAmount = socket.bufferedAmount;
          captureData.binaryType = socket.binaryType;
        }

        if (errorEvent instanceof ErrorEvent) {
          captureData.message = errorEvent.message;
          captureData.filename = errorEvent.filename;
          captureData.lineno = errorEvent.lineno;
          captureData.colno = errorEvent.colno;
          captureData.error = errorEvent.error?.toString();
        }

        triggerErrorCapture(errorCapture, captureData);
      }
    );

    return () => {
      api.unsubscribe(handle);
    };
  }, [errorCapture]);

  useEffect(
    () => () => {
      websocketApiRef.current.destroy();
    },
    []
  );

  return websocketApiRef.current;
};
