import {
  AnyCardModel,
  AnyCardWithRelatedCardsWithoutToolsModel,
} from '@kitted/card-service-models';
import { CardType } from '@kitted/kitted-models';

const cardTypesWithRelatedCardsWithoutTools = [
  CardType.Kit,
  CardType.Author,
  CardType.Subject,
];

export function isCardWithRelatedCardsWithoutTools(
  card: AnyCardModel
): card is AnyCardWithRelatedCardsWithoutToolsModel {
  return cardTypesWithRelatedCardsWithoutTools.includes(card.cardType);
}
