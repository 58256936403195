import { CardsProvider } from '../../contexts/CardsContext';
import { CardsOrderingProvider } from '../../contexts/CardsOrderingContext';
import { RoutePreloadProvider } from '../../contexts/RoutePreloadContext';
import { UserProvider } from '../../contexts/UserContext';

export const providers = [
  RoutePreloadProvider,
  UserProvider,
  CardsOrderingProvider,
  CardsProvider,
];
