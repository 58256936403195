/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import { Image } from '@kitted/shared-components';

import useAssetImage from './hooks/useAssetImage';
import { PolymorphicAssetImageCardProps } from './types';

const AssetImage = <C extends React.ElementType = typeof Image>({
  as,
  imageAsset,
  size,
  src,
  loaderTheme,
  ...rest
}: PolymorphicAssetImageCardProps<C>) => {
  const { loadedSrc } = useAssetImage(src, imageAsset, size);
  const Component = as || Image;

  return <Component {...rest} src={loadedSrc} loaderTheme={loaderTheme} />;
};

export default memo(AssetImage);
