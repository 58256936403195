import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0001: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 96 112"
		height={112}
		width={96}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M1.19 110.59V89.68c0-16.72 12.1-22.9 27.67-22.9L48 87.85l17.78-21.07c15.57 0 29.02 6.18 29.02 22.9v20.91" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M69.66 31.54c0 12.09-9.75 21.99-21.66 21.99s-21.65-9.9-21.65-21.99v-8.15C26.35 11.3 36.1 1.41 48 1.41s21.66 9.89 21.66 21.98z" />
    </g>
  </CardIcon>
);

export default memo(Fr0001);
