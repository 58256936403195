import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0009: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 112 94"
		height={94}
		width={112}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="M66.81 39.42c-2.54-.45-7.81-.9-10.64-.9s-8.05.42-10.69.9M41.37 16.6c0 8.17 6.62 14.8 14.8 14.8s14.8-6.63 14.8-14.8-6.63-14.81-14.8-14.81-14.8 6.62-14.8 14.81" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M109.69 92.21V82.03c0-10.75-6.71-16.21-16.16-18.16l-9.14 6.4-9.14-6.4c-9.45 1.95-16.16 7.41-16.16 18.16v10.18M52.91 92.21V82.03c0-10.75-6.71-16.21-16.16-18.16l-9.14 6.4-9.14-6.4C9.02 65.82 2.31 71.28 2.31 82.03v10.18M84.39 59.32c8.17 0 14.8-6.62 14.8-14.8s-6.63-14.81-14.8-14.81-14.8 6.62-14.8 14.81 6.63 14.8 14.8 14.8M27.61 59.32c8.18 0 14.8-6.62 14.8-14.8s-6.63-14.81-14.8-14.81-14.8 6.62-14.8 14.81 6.63 14.8 14.8 14.8" />
    </g>
  </CardIcon>
);

export default memo(In0009);
