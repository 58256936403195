import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

import typographyConstants from '../../Typography/constants';

export default createUseStyles(
  {
    '@keyframes loaderBackgroundPrimary': {
      ...styling.animations.context.loaderBackgroundPrimary.keyframes,
    },
    '@keyframes loaderBackgroundSecondary': {
      ...styling.animations.context.loaderBackgroundSecondary.keyframes,
    },
    container: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      '--textSkeletonFontSize': typographyConstants.body.fontSize,
      '& > :last-child:not(:first-child)': {
        width: '45%',
      },
    },
    containerDark: {
      '& $line:before': {
        animationName: '$loaderBackgroundPrimary',
        ...styling.animations.context.loaderBackgroundPrimary.useage,
      },
    },
    line: {
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: 0,
        right: 0,
        transform: 'translateY(-50%)',
        height: 'var(--textSkeletonFontSize)',
        animationName: '$loaderBackgroundSecondary',
        ...styling.animations.context.loaderBackgroundSecondary.useage,
      },
    },
    line1: {
      width: '80%',
    },
    line2: {
      width: '100%',
    },
    line3: {
      width: '85%',
    },
    line4: {
      width: '80%',
    },
    line5: {
      width: '45%',
    },
    lineCentered: {
      right: 'auto',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  { name: 'textSkeleton' }
);
