import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    container: {
      display: 'flex',
      gap: 25,
      justifyContent: 'center',
      flexDirection: 'row',
      maxWidth: 320,
      margin: [20, 'auto', 0],
    },
  },
  { name: 'modalButtons' }
);
