import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    radioContainer: {
      flexGrow: 1,
      minWidth: 200,
      overflow: 'hidden',
      marginBottom: 0,
    },
    radio: {
      position: 'absolute',
      top: -100,
      left: -100,
    },
    label: {
      ...styling.utils.absoluteFull(),
      display: 'flex',
      alignItems: 'center',
      paddingInline: [[24]],
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      gap: styling.sizes.theme.spacing['2xs'],
    },
    labelWithIcon: {
      paddingLeft: 66,
    },
    labelSelected: {
      fontWeight: styling.fonts.theme.atlasGrotesk.weights.medium,
    },
    radioIcon: {
      position: 'relative',
      height: styling.sizes.theme.spacing.xs,
      width: styling.sizes.theme.spacing.xs,
      borderRadius: '50%',
      boxShadow: styling.shadows.utils.boxShadowToString({
        x: 0,
        y: 0,
        blur: 0,
        spread: 1,
        inset: 'inset',
        color: 'currentColor',
      }),
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        translate: '-50% -50%',
        height: '50%',
        width: '50%',
        borderRadius: '50%',
        backgroundColor: 'currentColor',
        opacity: 0,
        transition: styling.transitions.utils.generate(['opacity']),
      },
      '$labelSelected &:before': {
        opacity: 1,
      },
    },
  },
  { name: 'inputRadioGroup' }
);
