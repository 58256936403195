import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0001: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 102 124"
		height={124}
		width={102}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="m48.1 22.96 1.66-5.2c1.17-3.64 5.1-5.66 8.74-4.5l37.52 12.02c3.64 1.17 5.66 5.1 4.5 8.74L90.43 65.13M48.1 8.62 44.77 4.3c-2.34-3.02-6.73-3.58-9.75-1.24L3.85 27.16C.83 29.5.27 33.89 2.61 36.91l20.1 25.8" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M42.25 104.62h-5.23c-3.82 0-6.95-3.13-6.95-6.95v-61.5c0-3.82 3.13-6.95 6.95-6.95h39.4c3.82 0 6.95 3.13 6.95 6.95v41.62" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m100.5 116.11-2.47-21.32c-1.48-7.5-7.43-7.52-7.43-7.52l-18.12.57-3.39-17.19c-.5-2.52-3.15-4.13-5.68-3.64-2.52.5-4.36 3-3.87 5.52l4.44 22.51 2.16 10.91-10.01-6.7c-2.12-1.44-5.04-.88-6.48 1.24l-.4.59c-1.44 2.13-.88 5.04 1.24 6.48l22.06 14.82" />
    </g>
  </CardIcon>
);

export default memo(Ac0001);
