import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0021: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 122 102"
		height={102}
		width={122}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M21.54 1.6 1.68 21.93 9.36 49.3l27.54 7.03 19.86-20.34-7.68-27.36zM119.56 38.18 89.49 8.1c-1.01-1-2.66-1-3.67 0l-7.96 7.96c-1 1-1 2.65 0 3.65l6.88 6.89-20.82 20.81 18.13 14.5 19-19 6.89 6.88a2.59 2.59 0 0 0 3.66 0l7.96-7.96c1-1.01 1-2.65 0-3.66ZM49.55 62.99l16.56 12.93M56.86 55.68l16.56 12.93M42.25 70.29l16.56 12.93M43.65 100.32l8.58-8.59-18.15-14.5-6.77 6.78s-1.28 5.13 4.98 11.38c6.07 6.07 11.37 4.93 11.37 4.93Z" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M30.84 21.82c2.62-2.62 6.87-2.66 9.44-.09s2.53 6.82-.09 9.44L29.23 42.12 18.27 31.17c-2.62-2.62-2.66-6.87-.09-9.44s6.82-2.53 9.44.09l1.61 1.61 1.62-1.61Z" />
    </g>
  </CardIcon>
);

export default memo(In0021);
