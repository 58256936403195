import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0034: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 118 104"
		height={104}
		width={118}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <circle vectorEffect="non-scaling-stroke" cx="72.02" cy="76.96" r="5.31" stroke={spot} /><path vectorEffect="non-scaling-stroke" stroke={color} d="m98.44 26.68 13.91-2.66.57 12.91M10.59 2.3v89.47M116.26 93.77l-105.67-2M21.62 31.98l17.81 27.89M50.44 49.49 39.43 59.87M66.07 68.43 50.44 49.49M76.55 67.72l34.75-42.66M10.59 16.3H1.74M10.59 50.27H1.74M10.59 81.44l-8.85.15M23.41 92.01l-.32 9.06M60.65 92.71l-.15 8.99M98.44 93.43v7.96" />
    </g>
  </CardIcon>
);

export default memo(In0034);
