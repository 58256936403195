import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0029: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 136 120"
		height={120}
		width={136}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M64.35 2.09v10.92M33.32 10.41l5.46 9.46M10.6 33.13l9.46 5.46M20.06 89.73l-9.46 5.46M95.38 10.41l-5.46 9.46M38.78 108.45l-5.46 9.46M13.21 64.16H2.29" /></g><g ><g stroke={spot}><path vectorEffect="non-scaling-stroke" d="m90.78 99.77-4.12 5.74 5.25 5.25 5.74-4.12c1.58.94 3.3 1.66 5.13 2.13l1.19 6.99h7.43l1.19-6.99c1.83-.47 3.55-1.19 5.13-2.13l5.74 4.12 5.25-5.25-4.12-5.74c.95-1.6 1.67-3.33 2.14-5.18l6.97-1.15v-7.43l-6.97-1.15c-.46-1.83-1.18-3.55-2.12-5.14l4.1-5.78-5.25-5.25-5.78 4.1c-1.58-.94-3.31-1.66-5.14-2.12l-1.15-6.97h-7.43l-1.15 6.98c-1.83.47-3.55 1.18-5.14 2.12l-5.78-4.1-5.25 5.25 4.1 5.78c-.93 1.57-1.65 3.28-2.11 5.1l-6.98 1.19v7.43l6.98 1.19c.47 1.83 1.19 3.55 2.13 5.13Z" /><path vectorEffect="non-scaling-stroke" d="M104.82 82.69c.89-.36 1.85-.56 2.87-.56 4.2 0 7.6 3.41 7.6 7.61s-3.41 7.61-7.6 7.61-7.61-3.41-7.61-7.61c0-.72.1-1.42.29-2.09" /></g><g stroke={color}><path vectorEffect="non-scaling-stroke" d="M65.08 24.28v10.58M65.09 96.6v10.57M33.99 65.73H23.41M75.7 107.12c-3.4.87-6.96 1.33-10.63 1.33-23.6 0-42.73-19.13-42.73-42.72S41.47 23 65.07 23c19.16 0 35.38 12.6 40.8 29.96" /><path vectorEffect="non-scaling-stroke" d="M87.22 47.79 65.19 69.82 50.82 56.24" /></g></g>
    </g>
  </CardIcon>
);

export default memo(In0029);
