import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0060: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 102 140"
		height={140}
		width={102}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M71 20.17h5.32v16.68M69.5 37.01h13.54" /><circle vectorEffect="non-scaling-stroke" cx="75.97" cy="14.72" r=".65" /><path vectorEffect="non-scaling-stroke" d="M70.24 52.04v11.04l20.91-15.9c5.99-4.67 9.84-11.96 9.84-20.14 0-14.1-11.43-25.53-25.53-25.53S49.93 12.94 49.93 27.04c0 10.03 5.77 18.71 14.18 22.89" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M49.93 42.5c-7.43-.17-16.06 1.43-25.2 6.62 0 0-15.36 6.65-12.11 27.9 0 0 .66 5.95-1.22 8.72L1.23 97.7s-1.35 3.55 2.71 3.89l6.2 1.28s2.59 5.05 2.85 8.94-.65 8.68-.65 8.68-2.2 5.84 1.26 8.39c.68.17 3.22 3.05 21.33-.84 0 0 1.75.83 2.86 10.29M76.87 138.49c-.8-4.69-1.97-12.32-1.51-19.75 0 0 2.71-12.18 8.12-19.97 0 0 11.66-19.89 1.53-37.5M13.76 112.07l7.78.13" /><path vectorEffect="non-scaling-stroke" d="M38.65 66.92c0-5 4.62-9.04 10.32-9.04s10.32 4.05 10.32 9.04c0 0 .23 4.12-5.13 7.79-3.57 2.78-6.35 7.15-6.35 12.11" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M48.05 97.34c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9 2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9" />
    </g>
  </CardIcon>
);

export default memo(Sn0060);
