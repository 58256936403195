import { useEffect } from 'react';
import { WebsocketClientMessageType } from '@kitted/websocket-service-models';

import { useWebsocketSendMessage } from '../../../hooks';

export const useWebsocketPingPong = () => {
  const sendMessage = useWebsocketSendMessage();

  useEffect(() => {
    const intervalId = setInterval(
      () => {
        sendMessage({
          messageType: WebsocketClientMessageType.Ping,
        });
        // Every 8 minutes
      },
      8 * 60 * 1000
    );

    return () => {
      clearInterval(intervalId);
    };
  }, [sendMessage]);
};
