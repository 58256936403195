import { useCallback } from 'react';

import {
  useFormApi,
  useFormData,
} from '../../../../../../../../../../contexts/FormContext';
import useExtractRequests from '../../../../../../../../../../hooks/extract/useExtractRequests';
import { AsyncRequestState } from '../../../../../../../../../../hooks/useAsyncRequest/types';

const useLinkScrapeButton = () => {
  const { formData, formErrors } = useFormData();
  const { addFormLoadingItem, removeFormLoadingItem, setFormData } =
    useFormApi();
  const { getExtract, getExtractState } = useExtractRequests();

  const onScrape = useCallback(async () => {
    addFormLoadingItem('linkScrape');
    try {
      const extractResponse = await getExtract(formData.linkUrl, true);
      if (extractResponse) {
        const { title, description, keyImagesBlobIds } = extractResponse;
        setFormData((prevData) => ({
          ...prevData,
          keyImage: prevData.keyImage || keyImagesBlobIds[0],
          title: prevData.title || title,
          description: prevData.description || description,
        }));
      }
    } finally {
      removeFormLoadingItem('linkScrape');
    }
  }, [
    formData.linkUrl,
    getExtract,
    addFormLoadingItem,
    removeFormLoadingItem,
    setFormData,
  ]);

  return {
    onScrape,
    isDisabled: !formData.linkUrl || !!formErrors.linkUrl,
    isLoading: getExtractState === AsyncRequestState.Loading,
  };
};

export default useLinkScrapeButton;
