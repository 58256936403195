import { useCallback, useEffect, useMemo } from 'react';

import { useAssetCatalogueData } from '../../../contexts/AssetCatalogueProvider/hooks';
import {
  canSubmit,
  getCatalogueResult,
} from '../../../contexts/AssetCatalogueProvider/logic';
import { AssetCatalogueModalSpaceProps } from '../../../types';

const useCatalogueFooter = (
  onSaveChanges: AssetCatalogueModalSpaceProps['onSaveChanges'],
  onClose: AssetCatalogueModalSpaceProps['onClose'],
  isMultiSelect: AssetCatalogueModalSpaceProps['isMultiSelect']
) => {
  const state = useAssetCatalogueData();

  const onSubmit = useCallback(() => {
    onSaveChanges(getCatalogueResult(state));
    onClose();
  }, [onSaveChanges, onClose, state]);

  const canSubmitForm = useMemo(() => canSubmit(state), [state]);

  useEffect(() => {
    if (!isMultiSelect && canSubmitForm) {
      onSubmit();
    }
  }, [canSubmitForm, isMultiSelect, onSubmit]);

  return {
    onSubmit,
    isDisabled: !canSubmitForm,
  };
};

export default useCatalogueFooter;
