import { memo } from 'react';

import { InputComponentProps } from '../types';
import { InputDisplayMetaProp } from './types';

const InputDisplay = ({
  onChange,
  value,
  meta,
  theme,
}: InputComponentProps & InputDisplayMetaProp) => {
  const Component = meta?.component;

  if (!Component) return null;

  return <Component theme={theme} onChange={onChange} value={value} />;
};

export default memo(InputDisplay);
