import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      ...styling.utils.buttonReset(),
      flexShrink: 0,
      height: styling.sizes.context.topBar.height,
      borderBottom: [1, 'solid', styling.colors.theme.primary700],
      position: 'relative',
      '&:hover $button > *': {
        translate: '-3px 0',
      },
      '&:hover $buttonOpen > *': {
        translate: '3px 0',
      },
    },
    containerRight: {
      '&:hover $button > *': {
        translate: '3px 0',
      },
      '&:hover $buttonOpen > *': {
        translate: '-3px 0',
      },
      '& $buttonContainer': {
        left: 'unset',
        right: '100%',
      },
      '& $button': {
        left: 'unset',
        right: -12,
        borderRadius: ['100rem', 0, 0, '100rem'],
      },
    },
    buttonContainer: {
      position: 'absolute',
      left: '100%',
      height: 32,
      top: styling.sizes.context.topBar.height / 2,
      overflow: 'hidden',
      width: 24,
      transform: 'translate(0, -50%)',
    },
    button: {
      position: 'absolute',
      left: -12,
      top: 0,
      height: '100%',
      width: 32,
      paddingRight: 12,
      color: styling.colors.theme.secondary300,
      background: styling.colors.theme.primary900,
      borderRadius: [0, '100rem', '100rem', 0],
      zIndex: 5,
      fontSize: '0.8rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      overflow: 'hidden',
      boxShadow: styling.shadows.utils.boxShadowToString({
        x: 0,
        y: 0,
        blur: 0,
        spread: 1,
        inset: 'inset',
        color: styling.colors.theme.primary700,
      }),
      transition: styling.transitions.utils.generate(['width']),
      '& > *': {
        position: 'relative',
        zIndex: 2,
        rotate: '180deg',
        transition: styling.transitions.utils.generate(
          ['rotate'],
          ['translate']
        ),
      },
    },
    buttonOpen: {
      '& > *': {
        rotate: '0deg',
      },
    },
  },
  { name: 'drawerSpaceToggle' }
);
