import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0053: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 110 96"
		height={96}
		width={110}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M99.96 91.25H11.72M11.72 49.8v26.9M25.36 76.7V49.8H36.2L18.55 24.48.88 49.8M47.43 26.53V76.7M61.07 76.7V26.53h10.84L54.26 1.21 36.59 26.53" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M79.31 57.64c0 5.37 5.65 9.72 12.63 9.72s12.63-4.35 12.63-9.72-6.73-7.72-12.65-10.2C86 44.95 79.26 42.6 79.26 37.23s5.65-9.72 12.63-9.72 12.63 4.35 12.63 9.72M91.88 27.47v-9.05M91.88 76.92v-9.5" /></g>
    </g>
  </CardIcon>
);

export default memo(In0053);
