import { z } from 'zod';

import {
  ResourceBaseCardLoadedModelSchema,
  ResourceBaseCardUnloadedModelSchema,
} from '../ResourceBase';

export const LinkResourceCardBaseModelSchema = z.object({
  linkUrl: z.string().url(),
  description: z.string().optional(),
});

export const LinkResourceCardLoadedModelSchema =
  ResourceBaseCardLoadedModelSchema.merge(LinkResourceCardBaseModelSchema);
export const LinkResourceCardUnloadedModelSchema =
  ResourceBaseCardUnloadedModelSchema.merge(LinkResourceCardBaseModelSchema);

export type LinkResourceCardLoadedModel = z.infer<
  typeof LinkResourceCardLoadedModelSchema
>;
export type LinkResourceCardUnloadedModel = z.infer<
  typeof LinkResourceCardUnloadedModelSchema
>;

export type LinkResourceCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? LinkResourceCardLoadedModel
    : LinkResourceCardUnloadedModel;
