import { useMemo } from 'react';

import { AccountAvatarProps } from '../../types';
import { getSpinnerSizeFromSize } from './logic';

const useAccountAvatar = (size: AccountAvatarProps['size']) => {
  const spinnerSize = useMemo(() => getSpinnerSizeFromSize(size), [size]);

  return {
    spinnerSize,
  };
};

export default useAccountAvatar;
