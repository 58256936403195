import { AlertButton, AlertButtonsProps } from './types';

export const handleGetInteractionProps = (
  props: Omit<AlertButton, 'title'>,
  onClose: AlertButtonsProps['onClose']
) => {
  if (props.onClick) {
    return {
      onClick: () => {
        props.onClick?.();
        onClose();
      },
    };
  }
  if (props.href) {
    return {
      href: `${props.href}`,
    };
  }
  return {
    to: `${props.to}`,
  };
};
