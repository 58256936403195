import { ReCaptchaParameters } from './types';

export const defaultCaptchaConfig: ReCaptchaParameters = {
  onloadCallback: undefined,
  onloadCallbackName: undefined,
  verifyCallback: undefined,
  verifyCallbackName: 'verifyCallback',
  expiredCallback: undefined,
  // theme: 'secondary',
  type: 'image',
  size: 'invisible',
  tabindex: 0,
  hl: 'en',
  badge: 'bottomright',
};
