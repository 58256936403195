import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Key: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 26 26"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M8.74 24.75c4.14 0 7.5-3.36 7.5-7.51s-3.36-7.51-7.5-7.51-7.5 3.36-7.5 7.51 3.36 7.51 7.5 7.51Z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m16.18 14.68 2.62-2.62.14-2.7 2.72-.16.08-2.65 2.98-.41-.36-4.47-4.5-.4-7.25 7.25M9.07 16.94a2.149 2.149 0 1 1-3.04 3.04 2.149 2.149 0 1 1 3.04-3.04Z" />
    </g>
  </WebsiteIcon>
);

export default memo(Key);
