import { memo } from 'react';

import useSetScrollbarWidth from './hooks/useSetScrollbarWidth';
import useSetTouchOnlyDevice from './hooks/useSetTouchOnlyDevice';
import useStyles from './styles';
import useNormalizeStyles from './stylesNormalize';

const GlobalStyles = () => {
  useNormalizeStyles();
  useStyles();
  useSetScrollbarWidth();
  useSetTouchOnlyDevice();

  return null;
};

export default memo(GlobalStyles);
