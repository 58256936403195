/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef } from 'react';

import useEnvironmentDomain from '../../../../hooks/useEnvironmentDomain';
import useIsLocalDevelopment from '../../../../hooks/useIsLocalDevelopment';
import { authorizedRequestFactory } from '../../../../services/requests/authorizedRequests';
import { constructIdempotencyKey } from '../../../../services/requests/logic';
import {
  RequestConfig,
  RequestPerformer,
} from '../../../../services/requests/types';
import useAuthTokensApi from '../../../AuthTokensContext/hooks/useAuthTokensApi';
import { useErrorCapture } from '../../../ErrorCaptureContext';
import useToastApi from '../../../ToastContext/hooks/useToastApi';
import { ServiceRequestName } from '../../types';
import {
  addServiceBaseUrlToConfig,
  launchErrorToast,
  triggerErrorCapture,
} from './logic';

const usePlatformRequest = () => {
  const environmentDomain = useEnvironmentDomain();
  const isLocalDevelopment = useIsLocalDevelopment();
  const { getAccessToken } = useAuthTokensApi();
  const errorCapture = useErrorCapture();
  const performAuthorizedRequest = useRef<RequestPerformer>(
    authorizedRequestFactory(getAccessToken)
  );
  const { registerToast } = useToastApi();

  const performRequest = useCallback(
    async <ResponseType>(config: RequestConfig) => {
      const response =
        await performAuthorizedRequest.current<ResponseType>(config);
      return response.data;
    },
    []
  );

  const errorCapturingRequest = useCallback(
    async <ResponseType>(config: RequestConfig) => {
      try {
        return await performRequest<ResponseType>(config);
      } catch (e: any) {
        if (
          e?.constructor?.name === 'CanceledError' ||
          e.message === 'canceled' ||
          e.message === 'Request aborted'
        ) {
          return Promise.reject(e);
        }

        launchErrorToast(registerToast, e, constructIdempotencyKey(config));
        if (e.response?.status !== 401) {
          triggerErrorCapture(errorCapture, e, config);
        }
        throw e;
      }
    },
    [registerToast, performRequest, errorCapture]
  );

  const performPlatformRequest = useCallback(
    async <ResponseType>(
      serviceName: ServiceRequestName,
      config: RequestConfig
    ) => {
      const serviceRequestConfig = addServiceBaseUrlToConfig(
        isLocalDevelopment,
        environmentDomain,
        serviceName,
        config
      );
      return errorCapturingRequest<ResponseType>(serviceRequestConfig);
    },
    [environmentDomain, isLocalDevelopment, errorCapturingRequest]
  );

  return performPlatformRequest;
};

export default usePlatformRequest;
