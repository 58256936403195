import { MediaPlayerType } from '@kitted/shared-components';

import { VideoAttrs } from './types';

const createYoutubeEmbedUrl = (embedId: string) =>
  `https://www.youtube-nocookie.com/watch?v=${embedId}`;

const createVimeoEmbedUrl = (embedId: string) => `https://vimeo.com/${embedId}`;

export const getPlayerSource = (
  player: VideoAttrs['player'],
  id: VideoAttrs['id'],
  url: VideoAttrs['url']
): [string | null, MediaPlayerType] => {
  if (player === 'youtube') {
    return [createYoutubeEmbedUrl(id), 'youtube'];
  }
  if (player === 'vimeo') {
    return [createVimeoEmbedUrl(id), 'vimeo'];
  }
  if (player === 'video') {
    return [url, 'video'];
  }
  return [null, 'video'];
};
