import { FormDataValue } from '../../../contexts/FormContext';
import { UserVerifyFormData } from './types';

export const getInitialFormData = (initialFormData: Record<string, string>) =>
  ({
    code: initialFormData?.code,
  }) as FormDataValue;

export const transformVerifyFormDataToVerifyPayload = ({
  code,
  'g-recaptcha-response': recaptchaResponse,
}: UserVerifyFormData) => ({
  'g-recaptcha-response': recaptchaResponse,
  code,
});
