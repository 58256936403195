import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormApi, useFormData } from '../../../../../contexts/FormContext';
import { FieldProps } from '../../types';

const useFieldPropFetchers = (
  name: FieldProps['name'],
  onFieldChange: FieldProps['onFieldChange']
) => {
  const { addFormLoadingItem, removeFormLoadingItem } = useFormApi();
  const { formData, formErrors } = useFormData();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const value = useMemo(() => formData[name], [name, formData]);

  const errors = useMemo(() => formErrors[name], [name, formErrors]);

  const onChange = useMemo(() => onFieldChange(name), [name, onFieldChange]);

  const onSetIsLoading = useCallback(
    (newLoadingState: boolean) => {
      setIsLoading(newLoadingState);
      if (newLoadingState) {
        addFormLoadingItem(name);
      } else {
        removeFormLoadingItem(name);
      }
    },
    [name, addFormLoadingItem, removeFormLoadingItem]
  );

  useEffect(
    () => () => {
      removeFormLoadingItem(name);
    },
    [name, removeFormLoadingItem]
  );

  return {
    value,
    errors,
    isLoading,
    onChange,
    onSetIsLoading,
  };
};

export default useFieldPropFetchers;
