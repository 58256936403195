import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const LetterTHollow: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 24 26"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M14.97 3.07h3.79l.63 4.72h2.85L22.14 1H1.88l-.13 6.79h2.88l.63-4.72h3.77a476 476 0 0 1 .07 9.25v1.35c0 3.05-.01 6.07-.07 9.04l-2.93.31v1.96h11.82v-1.96l-2.96-.31c-.05-3.01-.07-6.04-.07-9.07v-1.32c0-3.13.01-6.21.07-9.25Z" />
    </g>
  </WebsiteIcon>
);

export default memo(LetterTHollow);
