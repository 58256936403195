//  import { NULL_OP } from '@kitted/shared-utils';
import { RoutesNonRedirectsKeys } from '@kitted/website-constants';

import { LazyRouteComponentFunction } from './types';

// redirects
const AuthRedirects = () => import('../../routes/_redirects/authRedirects');
const RootCardRedirect = () => import('../../routes/_redirects/rootCard');
// actual pages
const Account = () => import('../../routes/account');
const AccountChangePassword = () =>
  import('../../routes/account/outlets/changePassword');
const AccountInvitations = () =>
  import('../../routes/account/outlets/invitations');
const AccountMMM = () =>
  import('../../routes/account/outlets/mental-model-mondays');
const AccountEmails = () =>
  import('../../routes/account/outlets/account-emails');
const ArticleEditPage = () => import('../../routes/article/routes/edit');
const ArticlePage = () => import('../../routes/article');
const AuthorPage = () => import('../../routes/author');
const AuthorEditPage = () => import('../../routes/author/routes/edit');
const BetaInvitePage = () => import('../../routes/beta-invite');
const ContactPage = () => import('../../routes/contact');
const DiscoveryAllPage = () => import('../../routes/discovery/scenes/all');
// const DiscoveryDashboard =
//   () => import('../../routes/discovery/scenes/dashboard')
// ;
const DiscoveryMyRoutesPage = () =>
  import('../../routes/discovery/scenes/my-routes');
const DiscoverySavedPage = () => import('../../routes/discovery/scenes/saved');
const EmailTemplatePreview = () =>
  import('../../routes/email-template-preview');
const FlowPage = () => import('../../routes/flow');
const FlowEditPage = () => import('../../routes/flow/routes/edit');
const FlowPlayPage = () => import('../../routes/flow/outlets/play');
const FourOhFour = () => import('../../routes/fourohfour');
const HomePage = () => import('../../routes/home');
const KitEditPage = () => import('../../routes/kit/routes/edit');
const KitPage = () => import('../../routes/kit');
const ImageResourcePage = () => import('../../routes/resource/image');
const ImageResourceEditPage = () =>
  import('../../routes/resource/image/routes/edit');
const Legals = () => import('../../routes/legals');
const LegalsTerms = () => import('../../routes/legals/outlets/terms');
const LegalsPrivacy = () => import('../../routes/legals/outlets/privacy');
const LegalsCookies = () => import('../../routes/legals/outlets/cookies');
const LegalsAcceptableUse = () =>
  import('../../routes/legals/outlets/acceptable-use');
const LinkResourcePage = () => import('../../routes/resource/link');
const LinkResourceEditPage = () =>
  import('../../routes/resource/link/routes/edit');
const MentalModelMondays = () => import('../../routes/mental-model-mondays');
const PlayEditPage = () => import('../../routes/play/routes/edit');
const PlayPage = () => import('../../routes/play');
const RedeemPurchase = () => import('../../routes/redeem-purchase');
const SubjectPage = () => import('../../routes/discovery/scenes/subject');
const TestingSafariFooter = () => import('../../routes/testing-safari-footer');
const ToolPage = () => import('../../routes/tool');
const VideoResourcePage = () => import('../../routes/resource/video');
const VideoResourceEditPage = () =>
  import('../../routes/resource/video/routes/edit');

// const NoView = NULL_OP;

const routeSceneMap: Record<
  RoutesNonRedirectsKeys,
  LazyRouteComponentFunction
> = {
  authForgotPassword: AuthRedirects,
  authLogin: AuthRedirects,
  authResetPassword: AuthRedirects,
  rootCard: RootCardRedirect,
  // actual pages
  account: Account,
  accountChangePassword: AccountChangePassword,
  accountInvitations: AccountInvitations,
  accountMMM: AccountMMM,
  accountEmails: AccountEmails,
  actionToolTitleCode: ToolPage,
  articleAuthorTitleCode: ArticlePage,
  articleAuthorTitleCodeEdit: ArticleEditPage,
  authorTitleCode: AuthorPage,
  authorTitleCodeEdit: AuthorEditPage,
  betaInvite: BetaInvitePage,
  betaInviteWithCode: BetaInvitePage,
  contact: ContactPage,
  // dashboard: DiscoveryDashboard,
  discoveryAll: DiscoveryAllPage,
  discoveryMyArticles: DiscoveryMyRoutesPage,
  discoveryMyFlows: DiscoveryMyRoutesPage,
  discoveryMyKits: DiscoveryMyRoutesPage,
  discoveryMyPlays: DiscoveryMyRoutesPage,
  discoveryMyResources: DiscoveryMyRoutesPage,
  discoverySaved: DiscoverySavedPage,
  emailTemplatePreview: EmailTemplatePreview,
  flowAuthorTitleCode: FlowPage,
  flowAuthorTitleCodeEdit: FlowEditPage,
  flowAuthorTitleCodePlay: FlowPlayPage,
  frameToolTitleCode: ToolPage,
  home: HomePage,
  insightToolTitleCode: ToolPage,
  kitTitleCode: KitPage,
  kitTitleCodeEdit: KitEditPage,
  legals: Legals,
  legalsTerms: LegalsTerms,
  legalsPrivacy: LegalsPrivacy,
  legalsCookies: LegalsCookies,
  legalsAcceptableUse: LegalsAcceptableUse,
  mentalModelMondays: MentalModelMondays,
  playAuthorTitleCode: PlayPage,
  playAuthorTitleCodeEdit: PlayEditPage,
  redeemPurchase: RedeemPurchase,
  resourceImageTitleCode: ImageResourcePage,
  resourceImageTitleCodeEdit: ImageResourceEditPage,
  resourceLinkTitleCode: LinkResourcePage,
  resourceLinkTitleCodeEdit: LinkResourceEditPage,
  resourceVideoTitleCode: VideoResourcePage,
  resourceVideoTitleCodeEdit: VideoResourceEditPage,
  snareToolTitleCode: ToolPage,
  subjectTitleCode: SubjectPage,
  testingSafariFooter: TestingSafariFooter,
  fourOhFour: FourOhFour,
};

export default routeSceneMap;
