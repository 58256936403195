import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0041: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 96 80"
		height={80}
		width={96}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="M1.04 79.01h93.92" /><g ><path vectorEffect="non-scaling-stroke" stroke={spot} d="M72.57 69.45h14.32" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M72.57 59.67h14.32M72.57 49.89h14.32M72.57 40.11h14.32M72.57 30.33h14.32M72.57 20.55h14.32M72.57 10.77h14.32M72.57.99h14.32" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M51.42 69.45h14.31" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M51.42 59.67h14.31M51.42 49.89h14.31M51.42 40.11h14.31M51.42 30.33h14.31M51.42 20.55h14.31" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M30.27 69.45h14.31" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M30.27 59.67h14.31M30.27 49.89h14.31M30.27 40.11h14.31M30.27 30.33h14.31" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M9.11 69.45h14.32" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M9.11 59.67h14.32M9.11 49.89h14.32" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0041);
