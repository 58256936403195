import useMediaQuery from '../../../../../contexts/MediaQueryManagementContext/hooks/useMediaQuery';
import {
  useAssetCatalogueApi,
  useAssetCatalogueData,
} from '../../../contexts/AssetCatalogueProvider';

const useFilterValues = () => {
  const { setFilter, setSearchText } = useAssetCatalogueApi();
  const { filter, searchText } = useAssetCatalogueData();

  const isTablet = useMediaQuery('isTablet');

  return { setFilter, setSearchText, filter, searchText, isTablet };
};

export default useFilterValues;
