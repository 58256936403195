import ArticleStoreInitializer from './StoreInitializers/ArticleStoreInitializer';
import AssetsStoreInitializer from './StoreInitializers/AssetsStoreInitializer';
import AuthorStoreInitializer from './StoreInitializers/AuthorStoreInitializer';
import CardsStoreInitializer from './StoreInitializers/CardsStoreInitializer';
import FavoritesStoreInitializer from './StoreInitializers/FavoritesStoreInitializer';
import FlowStoreInitializer from './StoreInitializers/FlowStoreInitializer';
import ImageResourceStoreInitializer from './StoreInitializers/ImageResourceStoreInitializer';
import KitStoreInitializer from './StoreInitializers/KitStoreInitializer';
import LinkResourceStoreInitializer from './StoreInitializers/LinkResourceStoreInitializer';
import MetadataStoreInitializer from './StoreInitializers/MetadataStoreInitializer';
import PagesStoreInitializer from './StoreInitializers/PagesStoreInitializer';
import PlayStoreInitializer from './StoreInitializers/PlayStoreInitializer';
import UserStoreInitializer from './StoreInitializers/UserStoreInitializer';
import VideoResourceStoreInitializer from './StoreInitializers/VideoResourceStoreInitializer';

export const storeSections = {
  Articles: {
    component: ArticleStoreInitializer,
    // TODO: @haxxxton handle removing "your"
    shouldClearOnAuthChange: true,
  },
  Authors: {
    component: AuthorStoreInitializer,
    // TODO: @haxxxton handle removing "your"
    shouldClearOnAuthChange: true,
  },
  Assets: {
    component: AssetsStoreInitializer,
  },
  Cards: {
    component: CardsStoreInitializer,
    // TODO: @haxxxton handle removing "your cards"
  },
  Kits: {
    component: KitStoreInitializer,
    // TODO: @haxxxton handle removing "your"
    shouldClearOnAuthChange: true,
  },
  Flows: {
    component: FlowStoreInitializer,
    // TODO: @haxxxton handle removing "your"
    shouldClearOnAuthChange: true,
  },
  Pages: {
    component: PagesStoreInitializer,
  },
  Plays: {
    component: PlayStoreInitializer,
    // TODO: @haxxxton handle removing "your"
    shouldClearOnAuthChange: true,
  },
  Metadata: {
    component: MetadataStoreInitializer,
  },
  ImageResources: {
    component: ImageResourceStoreInitializer,
    // TODO: @haxxxton handle removing "your"
    shouldClearOnAuthChange: true,
  },
  LinkResources: {
    component: LinkResourceStoreInitializer,
    // TODO: @haxxxton handle removing "your"
    shouldClearOnAuthChange: true,
  },
  VideoResources: {
    component: VideoResourceStoreInitializer,
    // TODO: @haxxxton handle removing "your"
    shouldClearOnAuthChange: true,
  },
  Favorites: {
    component: FavoritesStoreInitializer,
    shouldClearOnAuthChange: true,
  },
  User: {
    component: UserStoreInitializer,
    shouldClearOnAuthChange: true,
  },
} as const;
