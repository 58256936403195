import { CardType, CardTypeValues } from '@kitted/kitted-models';

import { TypographyMaxLines } from '../../Typography/types';

export const cardTypeToMaxLinesMap: {
  [key in CardTypeValues]: {
    default: TypographyMaxLines;
    featured: TypographyMaxLines;
  };
} = {
  [CardType.Article]: { default: 7, featured: 4 },
  [CardType.Author]: { default: 4, featured: 4 },
  [CardType.Deck]: { default: 7, featured: 4 },
  [CardType.Display]: { default: 7, featured: 4 },
  [CardType.Flow]: { default: 7, featured: 4 },
  [CardType.Kit]: { default: 7, featured: 4 },
  [CardType.Play]: { default: 7, featured: 4 },
  [CardType.Resource]: { default: 7, featured: 4 },
  [CardType.Subject]: { default: 7, featured: 4 },
  [CardType.Tool]: { default: 5, featured: 4 },
};
