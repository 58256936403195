import { useMemo } from 'react';

import {
  DrawerSpaceContainerContext,
  DrawerSpaceContainerDataContext,
} from './DrawerSpaceContainerContext';
import {
  useDrawerSpaceContainerManagement,
  useDrawerSpaceContextualDrawer,
} from './hooks';
import { DrawerSpaceContainerContextProps } from './types';

export const DrawerSpaceContainerProvider: React.FC<
  DrawerSpaceContainerContextProps
> = ({ children, isMainDisabled }) => {
  const {
    onSetPortalRootRef,
    onSetRef,
    portalRoot,
    onSetContentRoot,
    contentRoot,
    onToggleOpen,
    onClose,
    isMainOpen,
    isContextualOpen,
    isXs,
    isSm,
    isMd,
    isLg,
    isXl,
    isTablet,
    isDesktop,
  } = useDrawerSpaceContainerManagement();
  const {
    isContextualDisabled,
    contextualDrawerHeader,
    contextualDrawerContent,
    contextualDrawerContentProps,
    updateContextualDrawerContentProps,
    setIsContextualDisabled,
    mountContextualDrawer,
    unmountContextualDrawer,
  } = useDrawerSpaceContextualDrawer();
  const dataValue = useMemo(
    () => ({
      isMainOpen,
      isContextualOpen,
      isMainDisabled,
      isContextualDisabled,
      contextualDrawerHeader,
      contextualDrawerContent,
      contextualDrawerContentProps,
      isXs,
      isSm,
      isMd,
      isLg,
      isXl,
      isTablet,
      isDesktop,
      portalRoot,
      contentRoot,
    }),
    [
      isXs,
      isSm,
      isMd,
      isLg,
      isXl,
      isTablet,
      isDesktop,
      isMainOpen,
      isContextualOpen,
      isMainDisabled,
      isContextualDisabled,
      contextualDrawerHeader,
      contextualDrawerContent,
      contextualDrawerContentProps,
      portalRoot,
      contentRoot,
    ]
  );
  const apiValue = useMemo(
    () => ({
      onSetRef,
      onToggleOpen,
      onClose,
      onSetContentRoot,
      setIsContextualDisabled,
      mountContextualDrawer,
      unmountContextualDrawer,
      updateContextualDrawerContentProps,
    }),
    [
      onSetRef,
      onToggleOpen,
      onClose,
      onSetContentRoot,
      setIsContextualDisabled,
      mountContextualDrawer,
      unmountContextualDrawer,
      updateContextualDrawerContentProps,
    ]
  );

  return (
    <DrawerSpaceContainerContext.Provider value={apiValue}>
      <DrawerSpaceContainerDataContext.Provider value={dataValue}>
        <div ref={onSetPortalRootRef} data-drawer-space-portal="1" />
        {children}
      </DrawerSpaceContainerDataContext.Provider>
    </DrawerSpaceContainerContext.Provider>
  );
};
