import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const AvatarPlus: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 24 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <g stroke={color} ><circle vectorEffect="non-scaling-stroke" cx="12" cy="7.28" r="6" /><path vectorEffect="non-scaling-stroke" d="M1.79 26.28c0-5.64 4.57-10.21 10.21-10.21s10.21 4.57 10.21 10.21" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M12 19.96v6.76M8.62 23.34h6.76" /></g>
    </g>
  </WebsiteIcon>
);

export default memo(AvatarPlus);
