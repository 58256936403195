import { WebInfoModel } from '@kitted/extract-service-models';
import { KittedServiceEnum } from '@kitted/kitted-models';

import useRequests from '../../../contexts/RequestsContext/hooks/useRequests';
import useAsyncRequest from '../../useAsyncRequest';

const useExtractRequests = () => {
  const { platformRequest } = useRequests();

  const [getExtract, getExtractState] = useAsyncRequest<WebInfoModel>(
    (url: string, images = false) =>
      platformRequest(KittedServiceEnum.Extract, {
        method: 'GET',
        url: `/v1/extract/web`,
        params: {
          url: encodeURIComponent(url),
          images,
        },
      })
  );

  return {
    getExtract,
    getExtractState,
  };
};

export default useExtractRequests;
