import { styling } from '@kitted/design-system';

import useMediaQueryMonitor from '../useMediaQueryMonitor';

const useMediaQueryManagement = () => {
  const isXs = useMediaQueryMonitor(styling.breakpoints.utils.up('xs'));
  const isSm = useMediaQueryMonitor(styling.breakpoints.utils.up('sm'));
  const isMd = useMediaQueryMonitor(styling.breakpoints.utils.up('md'));
  const isLg = useMediaQueryMonitor(styling.breakpoints.utils.up('lg'));
  const isXl = useMediaQueryMonitor(styling.breakpoints.utils.up('xl'));
  const isTablet = useMediaQueryMonitor(
    styling.breakpoints.context.tabletUpString
  );
  const isDesktop = useMediaQueryMonitor(
    styling.breakpoints.context.desktopUpString
  );

  return {
    isXs,
    isSm: isSm && isXs,
    isMd: isMd && isSm && isXs,
    isLg: isLg && isMd && isSm && isXs,
    isXl: isXl && isLg && isMd && isSm && isXs,
    isTablet,
    isDesktop,
  };
};

export default useMediaQueryManagement;
