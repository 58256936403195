import { z } from 'zod';

import {
  ResourceBaseCardLoadedModelSchema,
  ResourceBaseCardUnloadedModelSchema,
} from '../ResourceBase';

export const VideoResourceCardBaseModelSchema = z.object({
  videoUrl: z.string().url(),
  description: z.string().optional(),
});

export const VideoResourceCardLoadedModelSchema =
  ResourceBaseCardLoadedModelSchema.merge(VideoResourceCardBaseModelSchema);
export const VideoResourceCardUnloadedModelSchema =
  ResourceBaseCardUnloadedModelSchema.merge(VideoResourceCardBaseModelSchema);

export type VideoResourceCardLoadedModel = z.infer<
  typeof VideoResourceCardLoadedModelSchema
>;
export type VideoResourceCardUnloadedModel = z.infer<
  typeof VideoResourceCardUnloadedModelSchema
>;

export type VideoResourceCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? VideoResourceCardLoadedModel
    : VideoResourceCardUnloadedModel;
