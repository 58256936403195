import { memo } from 'react';
import { ImageSize } from '@kitted/kitted-models';
import { Typography } from '@kitted/shared-components';
import { Trash, TrayArrowUp } from '@kitted/website-icons';

import AssetImage from '../../../AssetImage';
import Button from '../../../Button';
import { useUploadedImage } from './hooks/useUploadedImage';
import useStyles from './styles';
import { UploadImageBodyProps } from './types';

const UploadImageBody = ({
  maxFileSize,
  acceptedFileFormats,
}: UploadImageBodyProps) => {
  const styles = useStyles();
  const { helperText, blobUrl, clearUploadedImage } = useUploadedImage(
    maxFileSize,
    acceptedFileFormats
  );

  return (
    <>
      {blobUrl && (
        <>
          <AssetImage
            className={styles.assetImage}
            size={ImageSize.Large}
            src={blobUrl}
          />
          <Button
            onClick={clearUploadedImage}
            iconLeft={Trash}
            variant="outline"
            theme="secondary"
          >
            Clear image
          </Button>
        </>
      )}
      {!blobUrl && (
        <>
          <div className={styles.iconContainer}>
            <TrayArrowUp size="md" />
          </div>
          <br />
          <Typography variant="uploadTitle">
            Click or drag to upload your image
          </Typography>
          {helperText && (
            <>
              <br />
              <Typography variant="uploadBody">{helperText}</Typography>
            </>
          )}
        </>
      )}
    </>
  );
};

export default memo(UploadImageBody);
