import { useCallback } from 'react';

import { toastDurations } from '../../../../../contexts/ToastContext/constants';
import useToastApi from '../../../../../contexts/ToastContext/hooks/useToastApi';
import useUserApi from '../../../../../contexts/UserContext/hooks/useUserApi';
import {
  // getInitialFormData,
  transformVerifyFormDataToVerifyPayload,
} from '../../logic';
import { AuthVerifyModalProps, UserVerifyFormData } from '../../types';

const useVerifyUserModalContent = (
  onClose: AuthVerifyModalProps['onClose']
) => {
  // const initialFormData = useRef<FormDataValue>(
  //   getInitialFormData(meta?.initialFormData)
  // );
  const { verifyEmail } = useUserApi();
  const { registerToast } = useToastApi();

  const onSubmit = useCallback(
    (formData: UserVerifyFormData) => {
      const verifyUserPayload =
        transformVerifyFormDataToVerifyPayload(formData);
      return verifyEmail(verifyUserPayload);
    },
    [verifyEmail]
  );

  const onSubmitSuccess = useCallback(() => {
    registerToast('verifyUserSuccess', {
      title: 'Account verified',
      theme: 'success',
      duration: toastDurations.success,
    });
    onClose();
  }, [registerToast, onClose]);

  return {
    onSubmit,
    onSubmitSuccess,
  };
};

export default useVerifyUserModalContent;
