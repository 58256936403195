import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0001: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 102 102"
		height={102}
		width={102}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M100.28 51c0 27.22-22.06 49.28-49.28 49.28S1.72 78.22 1.72 51c0-24.37 17.69-44.61 40.93-48.58 2.71-.46 5.5-.7 8.35-.7 27.22 0 49.28 22.06 49.28 49.28" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M90.55 37.24 48.2 51V9.1" />
    </g>
  </CardIcon>
);

export default memo(In0001);
