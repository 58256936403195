import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    accountSection: {
      flexGrow: 1,
      position: 'relative',
    },
    menuSection: {
      borderTop: [1, 'solid', styling.colors.theme.primary400],
    },
    item: {
      borderBottom: [1, 'solid', styling.colors.theme.primary400],
      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
  { name: 'mobileMenuContent' }
);
