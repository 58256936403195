import { z } from 'zod';

import { AssetBaseSchema } from './AssetBase';

export const UnknownAssetModelSchema = AssetBaseSchema.merge(
  z.object({
    srcUrl: z.string(),
  })
);

export type UnknownAssetModel = z.infer<typeof UnknownAssetModelSchema>;
