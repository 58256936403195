import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0017: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 110 70"
		height={70}
		width={110}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="m14.16 28.34 9.67 31.64v8.64h62.34v-8.64l13.07-42.77-25.37 18.96-18.85-24.21h-.04L36.14 36.17 10.76 17.21M86.17 59.98H30.62" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M61.53 40.63c0 3.61-2.93 6.53-6.53 6.53s-6.53-2.93-6.53-6.53S51.4 34.1 55 34.1s6.53 2.92 6.53 6.53" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m39.31 21.4-7.08-6.73-4.72 7.18M70.69 21.4l7.08-6.73 4.72 7.18" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M59.86 6.24c0 2.68-2.18 4.86-4.86 4.86s-4.86-2.18-4.86-4.86S52.32 1.38 55 1.38s4.86 2.18 4.86 4.86" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M11.49 12.77c0 2.68-2.17 4.86-4.86 4.86a4.861 4.861 0 1 1-.01-9.72c2.68 0 4.86 2.18 4.86 4.86ZM108.24 12.77c0 2.68-2.18 4.86-4.86 4.86s-4.86-2.18-4.86-4.86 2.18-4.86 4.86-4.86 4.86 2.18 4.86 4.86" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0017);
