import { useMemo, useRef } from 'react';
import { EMPTY_OBJ } from '@kitted/shared-utils';

import { getPlayerSource } from '../../logic';
import { VideoAttrs, VideoEmbedProps } from '../../types';

const useVideoEmbed = (
  player: VideoAttrs['player'],
  id: VideoAttrs['id'],
  url: VideoAttrs['url'],
  fullbleed: VideoAttrs['fullbleed'] = false,
  initialState: VideoEmbedProps['initialState'] = EMPTY_OBJ
) => {
  const initialPlayerState = useRef({
    playing: fullbleed || !!initialState?.playing,
    muted: fullbleed || !!initialState?.muted,
  });
  const playerConfig = useRef({
    youtube: {
      playerVars: {
        autoplay: initialPlayerState.current.playing,
        controls: 1,
        modestbranding: 1,
        loop: 0,
        rel: 0,
      },
    },
    vimeo: {
      playerOptions: {
        autoplay: initialPlayerState.current.playing,
        controls: 1,
        title: 0,
        byline: 0,
        portrait: 0,
        speed: 0,
        badge: 0,
        autopause: 0,
        muted: 0,
        responsive: 1,
      },
    },
  });
  const [playerSource, playerType] = useMemo(
    () => getPlayerSource(player, id, url),
    [player, id, url]
  );

  return {
    playerConfig: playerConfig.current,
    initialPlayerState: initialPlayerState.current,
    playerSource,
    playerType,
  };
};

export default useVideoEmbed;
