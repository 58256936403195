import { useCallback, useEffect } from 'react';
import { WebsocketClientMessageType } from '@kitted/websocket-service-models';

import { WebsocketEvent } from '../../../../../services/websocket';
import useAuthTokensData from '../../../../AuthTokensContext/hooks/useAuthTokensData';
import { isTokenWithinExpiry } from '../../../../AuthTokensContext/logic';
import {
  useSubscribeToWebsocket,
  useWebsocketApi,
  useWebsocketSendMessage,
} from '../../../hooks';

// This could probably be moved to ./src/hooks
// im not a massive fan of this pulling from the auth context AND the websocket context

export const useWebsocketAuthSync = () => {
  const { tokens } = useAuthTokensData();
  const sendMessage = useWebsocketSendMessage();
  const websocketApi = useWebsocketApi();

  const updateAuthTokens = useCallback(() => {
    if (!websocketApi?.isConnected()) {
      return;
    }
    if (
      tokens?.expiresAt &&
      isTokenWithinExpiry(tokens.expiresAt, Date.now())
    ) {
      return;
    }

    if (tokens?.accessToken) {
      sendMessage({
        messageType: WebsocketClientMessageType.Authenticate,
        accessToken: tokens.accessToken,
      });
    } else {
      sendMessage({
        messageType: WebsocketClientMessageType.Unauthenticate,
      });
    }
  }, [sendMessage, tokens?.expiresAt, tokens?.accessToken, websocketApi]);

  // Sync the tokens in on open
  useSubscribeToWebsocket(WebsocketEvent.OPEN, updateAuthTokens);

  // Sync the tokens when they change
  useEffect(updateAuthTokens, [updateAuthTokens]);
};
