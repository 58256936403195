import { FieldTypes, FormDataSchema } from '../../../contexts/FormContext';
import { isRequired } from '../../../services/helpers/validators';
import { FormLayoutSchema } from '../../FormFields/types';

export const dataSchema: FormDataSchema = {
  fields: {
    code: {
      type: FieldTypes.TEXT,
      validation: [isRequired],
    },
  },
  form: {
    hasCaptcha: true,
    validationStrategy: 'onBlur',
  },
};

export const layoutSchema: FormLayoutSchema = {
  fields: [
    {
      key: 'code',
      label: 'Code',
    },
  ],
};
