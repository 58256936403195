import { memo } from 'react';
import { styling } from '@kitted/design-system';

import ScaleContainer from '..';
import { FeaturedCardScaleContainerProps } from './types';

const FeaturedCardScaleContainer = ({
  className,
  children,
}: FeaturedCardScaleContainerProps) => (
  <ScaleContainer
    className={className}
    aspectRatio={`${styling.sizes.context.cardFeature.width} / ${styling.sizes.context.cardFeature.height}`}
    maxWidth={styling.sizes.context.cardFeature.width}
  >
    {children}
  </ScaleContainer>
);

export default memo(FeaturedCardScaleContainer);
