import { RegisterCancelFn, RequestConfig, RequestPerformer } from './types';

export const constructIdempotencyKey = ({
  method = 'GET',
  url,
  data,
  params,
}: RequestConfig): string =>
  `${method}|${url}|${JSON.stringify(data)}|${JSON.stringify(params)}`;

export const apiRequestBase =
  (performRequest: RequestPerformer) =>
  async <ResponseType>(
    requestConfig: RequestConfig,
    registerCancel: RegisterCancelFn = () => undefined
  ) => {
    const controller = new AbortController();
    registerCancel(() => controller.abort());

    try {
      const response = await performRequest<ResponseType>({
        ...requestConfig,
        signal: controller.signal,
      });

      return response;
    } catch (err) {
      if (err?.constructor?.name === 'AbortError') {
        return Promise.reject(err);
      }

      throw err;
    }
  };
