import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    list: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      margin: [0, 'auto'],
    },
    item: {
      height: '100%',
      borderRight: [1, 'solid', styling.colors.theme.primary400],
      '&:last-child': {
        borderRight: 'none',
      },
    },
    menuButton: {
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
    },
    createItem: {
      width: '100%',
      paddingInline: styling.sizes.theme.spacing['2xs'],
    },
    skeleton: {
      alignItems: 'center',
    },
  },
  { name: 'mobileMenuItems' }
);
