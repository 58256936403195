/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';
import { ResourceCardType } from '@kitted/card-service-models';

import CardContents from '../CardContents';
import CardContentsForHoverableCard from '../CardContentsForHoverableCard';
import CardImage from '../CardImage';
import CardSlug from '../CardSlug';
import useExtractInteractionProps from '../hooks/useExtractInteractionProps';
import useResourceTypeContent from './hooks/useResourceTypeContent';
import useResourceTypeImage from './hooks/useResourceTypeImage';
import useStyles from './styles';
import { PolymorphicResourceCardProps } from './types';

const ResourceCard = <C extends React.ElementType = 'div'>({
  as,
  body,
  children,
  className,
  slug,
  isActive,
  isFeatured,
  isMenuOpen,
  imageSrc,
  label,
  subType,
  showTitle,
  title,
  ...rest
}: PolymorphicResourceCardProps<C>) => {
  const interactionProps = useExtractInteractionProps(rest);
  const { displayedTitle } = useResourceTypeContent(title, showTitle, subType);
  const { halfHeight } = useResourceTypeImage(subType, isFeatured);
  const styles = useStyles();
  const Component = as || 'div';

  return (
    <Component
      className={clsx(
        className,
        styles.container,
        isActive && styles.containerActive,
        isFeatured && styles.containerFeatured,
        isMenuOpen && styles.containerMenuOpen,
        styles[`containerSubType-${subType as ResourceCardType}`]
      )}
      {...interactionProps}
    >
      <CardImage
        className={styles.image}
        halfHeight={halfHeight}
        isFeatured={isFeatured}
        imageSrc={imageSrc}
      />
      {isFeatured && subType !== 'image' ? (
        <CardContents title={title} body={body} isFeatured type="resource" />
      ) : (
        <CardContentsForHoverableCard
          className={styles.content}
          body={body}
          isActive={isActive}
          isFeatured={isFeatured}
          isMenuOpen={isMenuOpen}
          title={displayedTitle}
          type="resource"
        />
      )}
      <CardSlug className={styles.cardSlug} isFeatured={isFeatured} noPrefix>
        {subType}
        {label && (
          <>
            {' - '}
            {label}
          </>
        )}
      </CardSlug>
      {children}
    </Component>
  );
};

export default memo(ResourceCard);
