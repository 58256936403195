import { useCallback, useMemo, useState } from 'react';

import useMediaQuery from '../../../../../../../contexts/MediaQueryManagementContext/hooks/useMediaQuery';
import {
  handleOnButtonClick,
  handleOnClose,
} from '../../../../../../Input/InputSelect/logic';
import { SubjectFilterDropdownProps } from '../../types';
import { getOptions, getSanitizedItem } from './logic';

const useSubjectFilterDropdown = (
  value: SubjectFilterDropdownProps['value'],
  setValue: SubjectFilterDropdownProps['setValue']
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isTablet = useMediaQuery('isTablet');

  const selectedItem = useMemo(() => getSanitizedItem(value), [value]);

  const onButtonClick = useCallback(
    () => handleOnButtonClick(setIsOpen),
    [setIsOpen]
  );

  const onClose = useCallback(() => handleOnClose(setIsOpen), [setIsOpen]);

  const options = useMemo(
    () => getOptions(setValue, onClose),
    [setValue, onClose]
  );

  return {
    options,
    isOpen,
    onButtonClick,
    onClose,
    selectedItem,
    isTablet,
  };
};

export default useSubjectFilterDropdown;
