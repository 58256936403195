import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0047: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 106 86"
		height={86}
		width={106}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M94.89 67.31H64.46c0 9.63-7.81 17.43-17.43 17.43S29.6 76.93 29.6 67.31H.96" /><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="m34.59 31.14-17.51 8.95c-3.38 1.82-7.35 1.09-8.99-1.96-1.63-3.05-.2-7.03 3.18-8.84L47.6 9.82l1.92-.48 33.88-.92S87.76 7.2 93 1.26" /><path vectorEffect="non-scaling-stroke" d="M105.04 19.07 89.67 33.22c-7.51 5.06-16.42 2.27-16.42 2.27-4.31-1.14-12.32-1.39-12.32-1.39h-13.3c-3.96 0-7.2-2.83-7.2-6.29s3.24-6.28 7.2-6.28h18.39" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M47.39 47.99c.1-1.04.39-2.21 1.09-2.91s1.88-.99 2.91-1.09c-.1 1.04-.39 2.21-1.09 2.91-.71.71-1.89.99-2.91 1.09M57.91 58.3c-.4-.96-.69-2.14-.4-3.09s1.19-1.76 2.06-2.33c.4.96.69 2.14.41 3.09-.29.95-1.2 1.76-2.06 2.33ZM44.46 68.93c-.57-.88-1.07-1.98-.95-2.96.11-.98.85-1.94 1.6-2.67.57.87 1.07 1.98.95 2.96-.12.99-.86 1.95-1.6 2.67" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0047);
