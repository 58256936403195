import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const SplitScreen: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 30 24"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <rect vectorEffect="non-scaling-stroke" width="27.18" height="20.88" x="1.41" y="1.56" stroke={color} rx=".98" ry=".98" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M15 2.55v18.91" />
    </g>
  </WebsiteIcon>
);

export default memo(SplitScreen);
