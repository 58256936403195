import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    iconContainer: {
      borderRadius: '50%',
      background: styling.colors.theme.secondary300,
      color: styling.colors.theme.primary900,
      width: 40,
      height: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    assetImage: {
      width: styling.sizes.context.tile.width,
      height: styling.sizes.context.tile.height,
      marginBottom: 15,
    },
  },
  { name: 'uploadImageBody' }
);
