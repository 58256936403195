/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef, useState } from 'react';
import { EMPTY_PROMISE, NOOP } from '@kitted/shared-utils';

import {
  AsyncRequestReturnValues,
  AsyncRequestState,
  RequestFn,
  SuccessCallback,
} from './types';

const useAsyncRequest = <ResponseType>(
  fn: RequestFn<ResponseType>,
  successCallback: SuccessCallback<ResponseType> = NOOP
): AsyncRequestReturnValues<ResponseType | undefined> => {
  const mountState = useRef<boolean>(false);
  const runRef = useRef(EMPTY_PROMISE);

  useEffect(() => {
    mountState.current = true;

    return () => {
      mountState.current = false;
    };
  }, []);

  const runWhenMounted = (fnToRun: () => void) => {
    if (mountState.current) {
      fnToRun();
    }
  };

  const [state, setState] = useState<AsyncRequestState>(
    AsyncRequestState.Default
  );
  const [error, setError] = useState<Error>();

  const runFn = async (...args: any[]) => {
    try {
      runWhenMounted(() => setState(AsyncRequestState.Loading));

      const result = await fn(...args);

      runWhenMounted(() => {
        successCallback(result, ...args);
        setState(AsyncRequestState.Success);
        setError(undefined);
      });

      return result;
    } catch (e: unknown) {
      if (e?.constructor?.name !== 'CanceledError') {
        runWhenMounted(() => {
          setState(AsyncRequestState.Error);
          setError(e as Error);
        });
        throw e;
      }
      return undefined;
    }
  };

  runRef.current = runFn;

  const run: (...args: any[]) => Promise<ResponseType | undefined> =
    useCallback(async (...args: []) => runRef.current?.(...args), []);

  return [run, state, error];
};

export default useAsyncRequest;
