import { memo } from 'react';

import useScrollRestoration from './hooks/useScrollRestoration';

const ScrollRestoration = () => {
  useScrollRestoration();

  return null;
};

export default memo(ScrollRestoration);
