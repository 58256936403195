import AuthResetPasswordChallenge from './AuthResetPasswordChallenge';
import {
  AuthChallengeComponent,
  AuthChallengeModalMeta,
  AuthenticateUserChallengeValues,
} from './types';

export const getChallengeComponent = (meta: AuthChallengeModalMeta) => {
  const { challenge, ...restOfMeta } = meta;
  switch (challenge as AuthenticateUserChallengeValues) {
    case 'NEW_PASSWORD_REQUIRED':
      return {
        as: AuthResetPasswordChallenge,
        session: restOfMeta.session,
        email: restOfMeta.email,
      } as Omit<AuthChallengeModalMeta, 'challenge'> & AuthChallengeComponent;
    default:
      return {
        as: null,
      } as AuthChallengeComponent;
  }
};
