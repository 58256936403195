import { memo } from 'react';
import clsx from 'clsx';

import useStyles from './styles';
import { DividerProps } from './types';

const Divider = ({ className, spacing, theme, isSubtle }: DividerProps) => {
  const styles = useStyles();

  return (
    <hr
      className={clsx(
        className,
        styles.divider,
        isSubtle && styles.subtle,
        styles[`spacing-${spacing}`],
        styles[`theme-${theme}`]
      )}
    />
  );
};

export default memo(Divider);
