import { createContext } from 'react';
import { EMPTY_ARR, EMPTY_PROMISE, NOOP } from '@kitted/shared-utils';

import useStoreManagement from './hooks/useStoreManagement';
import { StoreSectionItemStates } from './services/storeOfStores/types';
import StoreInitializers from './StoreInitializers';
import { StoreApi, StoreContextProps } from './types';

export const StoreContext = createContext<StoreApi>({
  createOrUpdateStoreSection: NOOP,
  setSection: NOOP,
  getDefaultStoreItem: () => ({
    item: undefined,
    state: StoreSectionItemStates.Default,
  }),
  fetchStoreItem: EMPTY_PROMISE,
  getStoreItem: () => ({
    item: undefined,
    state: StoreSectionItemStates.Default,
  }),
  getStoreItemKeyWhere: () => undefined,
  getStoreItemKeysWhere: () => EMPTY_ARR,
  setStoreItemItem: NOOP,
  mergeStoreItemItem: NOOP,
  setStoreItemState: NOOP,
  deleteStoreItem: NOOP,
  subscribeToItemChanges: () => NOOP,
  subscribeToKeysChanges: () => NOOP,
});

export const StoreProvider: React.FC<StoreContextProps> = ({ children }) => {
  const storeManagement = useStoreManagement();

  return (
    <StoreContext.Provider value={storeManagement}>
      <StoreInitializers>{children}</StoreInitializers>
    </StoreContext.Provider>
  );
};
