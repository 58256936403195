import { useMemo } from 'react';
import { EMPTY_OBJ, NOOP } from '@kitted/shared-utils';

import { getTitleFromRouteTitle } from '../../../../../../../components/SEOHead/hooks/usePageTitle/logic';
import useIsPathSelected from '../../../../../../../hooks/useIsPathSelected';
import { MobileMenuOptionProps } from '../../types';

const useMobileMenuOption = (
  path: MobileMenuOptionProps['path'],
  target: MobileMenuOptionProps['target'],
  onClick: MobileMenuOptionProps['onClick'],
  title: MobileMenuOptionProps['title'],
  isLoading: MobileMenuOptionProps['isLoading'],
  isSelected: MobileMenuOptionProps['isSelected'],
  highlightSubroutes: MobileMenuOptionProps['highlightSubroutes']
) => {
  const isItemSelected = useIsPathSelected(path, undefined, highlightSubroutes);

  const buttonProps = useMemo(() => {
    if (isLoading) {
      return {
        onClick: NOOP,
      };
    }
    if (isItemSelected || isSelected) {
      return {
        onClick: NOOP,
      };
    }
    if (target && path) {
      return {
        href: path,
      };
    }
    if (path) {
      return {
        to: path,
      };
    }
    if (onClick) {
      return {
        onClick,
      };
    }
    return {
      isDisabled: true,
      onClick: NOOP,
    };
  }, [path, target, onClick, isItemSelected, isSelected, isLoading]);

  const titleValue = useMemo(
    () => getTitleFromRouteTitle(title, EMPTY_OBJ, false),
    [title]
  );

  return {
    buttonProps,
    isItemSelected: isItemSelected || isSelected,
    titleValue,
  };
};

export default useMobileMenuOption;
