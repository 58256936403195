import animations from './animations';
import breakpoints from './breakpoints';
import colors from './colors';
import container from './container';
import fonts from './fonts';
import shadows from './shadows';
import sizes from './sizes';
import speeds from './speeds';
import transitions from './transitions';
import typography from './typography';
import utils from './utils';
import zIndex from './zIndex';

export default {
  animations,
  breakpoints,
  colors,
  container,
  fonts,
  shadows,
  sizes,
  speeds,
  transitions,
  typography,
  utils,
  zIndex,
} as const;
