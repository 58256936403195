import { useMemo } from 'react';

import useBackgroundParallax from '../../../../hooks/useBackgroundParallax';
import { useDrawerSpaceContainerData } from '../../../DrawerSpace/context/DrawerSpaceContainerContext';
import { calculateOffset, getBackgroundImageStyle } from '../../logic';

const useBackgroundImage = (
  parallaxEnabled: boolean,
  offsetForMenu: boolean
) => {
  const { isMainOpen, isMainDisabled, isContextualOpen, isContextualDisabled } =
    useDrawerSpaceContainerData();

  const offsetDistance = useMemo(
    () =>
      calculateOffset({
        offsetForMenu,
        isMainOpen,
        isMainDisabled,
        isContextualOpen,
        isContextualDisabled,
      }),
    [
      offsetForMenu,
      isMainOpen,
      isMainDisabled,
      isContextualOpen,
      isContextualDisabled,
    ]
  );

  // const [imgDimensions, setImageDimensions] = useState<ImageAssetModelDimensions>({
  //   height: 1,
  //   width: 1,
  // });
  const { elementRef, position } = useBackgroundParallax({
    enabled: parallaxEnabled,
    factor: 2,
  });
  // imgDimensions.height * 1.7 - imgDimensions.height

  // const onLoad = useCallback(
  //   (img: HTMLImageElement, isFallback: boolean) => handleOnLoad(setImageDimensions, img),
  //   []
  // );

  const style = useMemo(
    () => getBackgroundImageStyle(position, offsetDistance),
    [position, offsetDistance]
  );

  return {
    containerRef: elementRef,
    style,
  };
};

export default useBackgroundImage;
