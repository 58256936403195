import { z } from 'zod';
import {
  ImageAssetModelSchema,
  makeKittedModelReferenceSchema,
} from '@kitted/kitted-models';

import {
  ResourceBaseCardLoadedModelSchema,
  ResourceBaseCardUnloadedModelSchema,
} from '../ResourceBase';

const ImageResourceCardLoadedBaseModelSchema = z.object({
  keyImageImageAsset: makeKittedModelReferenceSchema(
    ImageAssetModelSchema,
    true
  ),
  showTitle: z.boolean().optional(),
  references: z.string().optional(),
});

export const ImageResourceCardLoadedModelSchema =
  ResourceBaseCardLoadedModelSchema.merge(
    ImageResourceCardLoadedBaseModelSchema
  );
export const ImageResourceCardUnloadedModelSchema =
  ResourceBaseCardUnloadedModelSchema.merge(
    ImageResourceCardLoadedBaseModelSchema.merge(
      z.object({
        keyImageImageAsset: makeKittedModelReferenceSchema(
          ImageAssetModelSchema,
          false
        ),
      })
    )
  );

export type ImageResourceCardLoadedModel = z.infer<
  typeof ImageResourceCardLoadedModelSchema
>;
export type ImageResourceCardUnloadedModel = z.infer<
  typeof ImageResourceCardUnloadedModelSchema
>;

export type ImageResourceCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? ImageResourceCardLoadedModel
    : ImageResourceCardUnloadedModel;
