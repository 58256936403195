import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Inbox: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 24"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M26 14.29 22.24 3.42M2 14.29 5.76 3.42M17.09 14.31c-.24 1.49-1.53 2.72-3.09 2.72s-2.87-1.21-3.09-2.72H2v8.23h24v-8.23zM19.62 7.48V5.14H8.38v2.34M18.55 3.27V1.46h-9.1v1.81M20.98 12.42V9.39H7.02v3.03" />
    </g>
  </WebsiteIcon>
);

export default memo(Inbox);
