import { z } from 'zod';

import { WebsocketServerMessageType } from './enum';
import { WebsocketServerMessageSchema } from './WebsocketServerMessage';

export const WebsocketDownloadReadyServerMessageSchema =
  WebsocketServerMessageSchema.merge(
    z.object({
      messageType: z.literal(WebsocketServerMessageType.DownloadReady),
      blobId: z.string().optional(),
      isError: z.boolean(),
    })
  );

// Override the any type due to the schema limitation
export type WebsocketDownloadReadyServerMessage = z.infer<
  typeof WebsocketDownloadReadyServerMessageSchema
>;
