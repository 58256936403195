/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';

import CardComponent from '../../../components/Card';
import { useCardComponentProps } from './hooks/useCardComponentProps';
import { RenderCardComponentProps } from './types';

const RenderCardComponent = (props: RenderCardComponentProps) => {
  const cardProps = useCardComponentProps(props);

  return (
    <CardComponent {...cardProps} isFeatured nonInteractive preventActive />
  );
};

export default memo(RenderCardComponent);
