import { useCallback, useMemo } from 'react';

import {
  FormStates,
  useFormApi,
  useFormData,
} from '../../../../../contexts/FormContext';
import { ResourceModelCreateModalSpaceProps } from '../../../types';

const useResourceModelCreateFormFooter = (
  isCloning: ResourceModelCreateModalSpaceProps['isCloning'],
  onSaveChanges: ResourceModelCreateModalSpaceProps['onSaveChanges'],
  onClose: ResourceModelCreateModalSpaceProps['onClose']
) => {
  const { formState } = useFormData();
  const { getFormData } = useFormApi();

  const onSubmit = useCallback(async () => {
    const payload = getFormData();
    await onSaveChanges(payload);
    onClose();
  }, [onSaveChanges, getFormData, onClose]);

  const isDisabled = useMemo(() => {
    if (!isCloning) {
      return formState !== FormStates.DIRTY;
    }
    // can just immediately clone
    return formState !== FormStates.DIRTY && formState !== FormStates.CLEAN;
  }, [formState, isCloning]);

  return {
    onSubmit,
    isDisabled,
  };
};

export default useResourceModelCreateFormFooter;
