import { AccountModel } from '@kitted/account-service-models';

import useStoreItemSubscription from '../../../contexts/StoreContext/hooks/useStoreItemSubscription';
import { StoreSectionItemStates } from '../../../contexts/StoreContext/services/storeOfStores/types';

const useAccount = () => {
  const { item: account, state } = useStoreItemSubscription<AccountModel>(
    'User',
    'account'
  );

  return {
    account,
    isLoading: state !== StoreSectionItemStates.Ready,
  };
};

export default useAccount;
