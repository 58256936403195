export enum TransitionDirection {
  FromTop = 'fromTop',
  FromBottom = 'fromBottom',
  FromRight = 'fromRight',
}

export type SlideInOutProps = {
  className?: string;
  durationIn?: number;
  durationOut?: number;
  children: React.ReactNode;
  in: boolean;
  onExited?: () => void;
  onClick?: () => void;
  translateX?: string;
  transitionDirection?: TransitionDirection;
};
