import { z } from 'zod';

import {
  CMSContentBlockTextModelSchema,
  CMSContentBlockVideoLoadedModelSchema,
  CMSContentBlockVideoUnloadedModelSchema,
} from '../content-blocks';

export const AnyCMSFlowBlockLoadedModelSchema = z.union([
  CMSContentBlockVideoLoadedModelSchema,
  CMSContentBlockTextModelSchema,
]);

export const AnyCMSFlowBlockUnloadedModelSchema = z.union([
  CMSContentBlockVideoUnloadedModelSchema,
  CMSContentBlockTextModelSchema,
]);

export type AnyCMSFlowBlockLoadedModel = z.infer<
  typeof AnyCMSFlowBlockLoadedModelSchema
>;
export type AnyCMSFlowBlockUnloadedModel = z.infer<
  typeof AnyCMSFlowBlockUnloadedModelSchema
>;

export type AnyCMSFlowBlockModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? AnyCMSFlowBlockLoadedModel
    : AnyCMSFlowBlockUnloadedModel;
