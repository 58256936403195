import { PLAYER_STATE_EVENTS } from '../../constants';
import { CueChangeEvent } from '../../types';
import { PlayerCaptionsProps } from '../types';

export const handleOnCueChange = (
  e: CueChangeEvent,
  setCaption: (newCaption: string) => void,
  fireStateChangeEvent: PlayerCaptionsProps['fireStateChangeEvent']
) => {
  const cues = e.currentTarget?.activeCues;

  if (cues && cues.length > 0) {
    setCaption((cues[0] as VTTCue).text);
  } else {
    setCaption('');
  }

  fireStateChangeEvent(
    PLAYER_STATE_EVENTS.CAPTION,
    !!(cues?.[0] as VTTCue)?.text
  );
};

export const handleOnPlayerMount = (
  playerRef: PlayerCaptionsProps['playerRef'],
  onCueChange: (e: CueChangeEvent) => void,
  trackIndex: number
) => {
  if (!playerRef) return undefined;
  const videoEl = playerRef?.getInternalPlayer();
  if (!videoEl) return undefined;

  // eslint-disable-next-line dot-notation
  const track = videoEl['textTracks']?.[trackIndex];
  if (!track) return undefined;

  track.mode = 'hidden';
  track.addEventListener('cuechange', onCueChange);

  return () => {
    track.removeEventListener('cuechange', onCueChange);
  };
};
