import { CardModelId, KittedModelBase } from '@kitted/kitted-models';

import useStoreItemSubscription from '../../../StoreContext/hooks/useStoreItemSubscription';
import { StoreSectionItemStates } from '../../../StoreContext/services/storeOfStores/types';

const useCard = <TypedCardModel extends KittedModelBase>(
  id: CardModelId | undefined
) => {
  const { item: card, state } = useStoreItemSubscription<TypedCardModel>(
    'Cards',
    id
  );

  return {
    card,
    isLoading: state !== StoreSectionItemStates.Ready,
  };
};

export default useCard;
