import ReactPlayer from 'react-player';
import VimeoReactPlayer from 'react-player/vimeo';
import YouTubeReactPlayer from 'react-player/youtube';

import { PlayerStateEvents, PlayerStateEventsValues } from './types';

export const PLAYER_STATE_EVENTS: {
  [key in keyof typeof PlayerStateEvents]: PlayerStateEventsValues;
} = {
  READY: 'ready',
  PLAYING: 'playing',
  SKIPPED: 'skipped',
  MUTED: 'muted',
  COMPLETED: 'completed',
  CAPTION: 'caption',
};

export const playerTypeToComponentMap = {
  video: ReactPlayer,
  vimeo: VimeoReactPlayer,
  youtube: YouTubeReactPlayer,
};
