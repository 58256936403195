import { memo } from 'react';

import { StoreSectionInitializerProps } from '../types';
import useAssetsStoreInitializer from './hooks/useAssetsStoreInitializer';

const AssetsStoreInitializer = ({
  onSectionLoaded,
}: StoreSectionInitializerProps) => {
  useAssetsStoreInitializer(onSectionLoaded);

  return null;
};

export default memo(AssetsStoreInitializer);
