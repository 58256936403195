import { useEffect, useMemo } from 'react';

import {
  useEditDrawerSpaceApi,
  useEditDrawerSpaceData,
} from '../../../contexts/EditDrawerSpaceContext';
import { getEditDrawerSpaceDrawerItems } from './logic';

const useEditDrawerSpaceMobileMenu = () => {
  const { setSelectedDrawerItemSlug } = useEditDrawerSpaceApi();
  const { items, selectedDrawerItemSlug } = useEditDrawerSpaceData();

  useEffect(() => {
    if (!selectedDrawerItemSlug) {
      setSelectedDrawerItemSlug(Object.keys(items)?.[0]);
    }
  }, [items, selectedDrawerItemSlug, setSelectedDrawerItemSlug]);

  const itemsArray = useMemo(
    () => getEditDrawerSpaceDrawerItems(items, setSelectedDrawerItemSlug),
    [items, setSelectedDrawerItemSlug]
  );

  return {
    items: itemsArray,
    selectedDrawerItemSlug,
  };
};

export default useEditDrawerSpaceMobileMenu;
