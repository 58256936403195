import { Dispatch, SetStateAction } from 'react';
import { Nullable } from '@kitted/platform-models';

import { DrawerSpaceDrawerSide } from '../../types';

export const handleToggleOpen = (
  setIsOpen: Dispatch<SetStateAction<Nullable<DrawerSpaceDrawerSide>>>,
  drawerSide: DrawerSpaceDrawerSide
) => {
  setIsOpen((currentState) => {
    // if currently closed, open the drawer
    if (!currentState) {
      return drawerSide;
    }
    // if toggling the currently open drawer, close it
    if (currentState === drawerSide) {
      return null;
    }
    // if toggling the other drawer, switch to that drawer
    if (currentState === DrawerSpaceDrawerSide.Main) {
      return DrawerSpaceDrawerSide.Contextual;
    }
    return DrawerSpaceDrawerSide.Main;
  });
};
