import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0004: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 88 116"
		height={116}
		width={88}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><path vectorEffect="non-scaling-stroke" stroke={spot} d="M42.42 39.06c-3.42.73-6.68 2.42-9.34 5.08a18.7 18.7 0 0 0-4.86 8.42" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M25.32 41.37c-7.81 10.36-7 25.15 2.44 34.58 10.32 10.32 27.06 10.32 37.39 0 8.39-8.39 9.96-21.02 4.71-30.99l-6.55-6.55" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="m69.9 45 7.17-7.18-11.19-11.19-7.16 7.19c-9.02-4.74-20.2-3.89-28.45 2.52M71.55 32.1l3.75-3.75a3.754 3.754 0 0 0 0-5.3l-2.43-2.43c-1.46-1.46-1.45-3.85 0-5.31l2.53-2.53" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m80.31 7.89 3.19-3.2M80.25 17.79l3.19 3.2M82.34 12.76h4.52M70.47 8.01l-3.2-3.19M75.5 5.92V1.4" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="m56.31 90.92 12.67-6.47c2.59-1.39 5.64-.84 6.9 1.5 1.25 2.34.15 5.39-2.44 6.78l-27.86 14.93-1.47.37-25.98.7s-3.35.94-7.37 5.49" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M1.51 100.57 13.3 89.72c5.76-3.89 12.59-1.74 12.59-1.74 3.31.87 9.45 1.07 9.45 1.07h10.2c3.04 0 5.52 2.17 5.52 4.82s-2.49 4.82-5.52 4.82H31.43" />
    </g>
  </CardIcon>
);

export default memo(Sn0004);
