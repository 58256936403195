import { useMemo } from 'react';

import { getCardComponentByType, getThemeFromInversion } from '../../logic';
import { CardSkeletonProps } from '../../types';

const useCardSkeleton = (
  type: CardSkeletonProps['type'] = 'tool',
  isInverted: CardSkeletonProps['isInverted'] = false
) => {
  const Component = useMemo(() => getCardComponentByType(type), [type]);

  const theme = useMemo(() => getThemeFromInversion(isInverted), [isInverted]);

  return {
    Component,
    theme,
  };
};

export default useCardSkeleton;
