import { useCallback, useEffect, useRef, useState } from 'react';

import {
  calculateInView,
  getIntersectionObserver,
  handleOnMount,
} from './logic';
import { BackgroundParallaxOptions } from './types';

const useBackgroundParallax = (options: BackgroundParallaxOptions) => {
  const { max, factor, enabled = true } = options;
  const [position, setPosition] = useState(0);
  const elementRef = useRef<HTMLDivElement | null>(null);

  const observerRef = useRef<IntersectionObserver | null>(null);

  const observeElement = useCallback(() => {
    observerRef.current = getIntersectionObserver(
      calculateInView(setPosition, max, factor)
    );
    observerRef.current.observe(elementRef.current as Element);
  }, [max, factor]);

  const unobserveElement = useCallback(() => {
    if (elementRef.current !== null) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      observerRef.current!.unobserve(elementRef.current as Element);
    }
  }, []);

  useEffect(
    () => handleOnMount(enabled, observeElement, unobserveElement),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enabled, max, factor]
  );

  return {
    elementRef,
    position,
  };
};

export default useBackgroundParallax;
