import { mustMatchRegex, ValidationItemMustMatchRegex } from './mustMatchRegex';

// referencing OWASP standards for special characters
// https://owasp.org/www-community/password-special-characters
const getAtLeastXSpecialCharactersRegex = (specialCharacterCount: number) =>
  new RegExp(
    `(?=.*[ !"#$%&'()*+,-./:;<=>?@[\\]^_\`{|}~]{${specialCharacterCount}})`
  );

export const hasAtLeastXSpecialCharacters = (
  isValueRequired: boolean,
  specialCharCount: number
): ValidationItemMustMatchRegex =>
  mustMatchRegex(
    isValueRequired,
    getAtLeastXSpecialCharactersRegex(specialCharCount),
    `This requires at least ${specialCharCount} special character(s) ( !"#$%&'()*+,-./:;<=>?@[\\]^_\`{|}~)`
  );
