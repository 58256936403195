import { traverse } from '../array';
import { isNil, isNumber, isString } from '../validation';
import { SortPredicate } from './types';

export const compare = <CompareItem extends Record<string, unknown>>(
  a: CompareItem,
  b: CompareItem,
  predicate: SortPredicate<CompareItem>
) => {
  // default predicate merging
  const mergedPredicate = {
    ascending: true,
    ...predicate,
  };

  // set order direction based on ascending key
  const order = mergedPredicate.ascending ? 1 : -1;
  let direction = 0;
  const aVal = traverse<CompareItem>(a, mergedPredicate.colName);
  const bVal = traverse<CompareItem>(b, mergedPredicate.colName);

  if (aVal === bVal) {
    return 0;
  }
  // null / undefined values should be sorted to the start
  if (isNil(aVal)) {
    return order;
  }
  if (isNil(bVal)) {
    return order * -1;
  }

  if (isString(aVal) && isString(bVal)) {
    // compare object values taking into account `order`
    direction =
      aVal.localeCompare(bVal, undefined, {
        sensitivity: 'base',
        numeric: true,
        ignorePunctuation: true,
      }) * order;
  } else if (isNumber(aVal) && isNumber(bVal) && aVal !== bVal) {
    // compare numerical values
    direction = (aVal > bVal ? 1 : -1) * order;
  }
  return direction;
};
