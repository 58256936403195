import { memo } from 'react';
import clsx from 'clsx';
import { styling } from '@kitted/design-system';

import KittedBrand from '../KittedBrand';
import KittedBrandmark from '../KittedBrandmark';
import KittedBrandmarkOnLight from '../KittedBrandmarkOnLight';
import useStyles from './styles';
import { KittedBrandingFullProps } from './types';

const KittedBrandingFull = ({
  className,
  useBrandColors = false,
  onLight = false,
  fill = styling.colors.theme.primary600,
}: KittedBrandingFullProps) => {
  const styles = useStyles();

  return (
    <div
      className={clsx(
        className,
        styles.container,
        onLight && styles.containerOnLight
      )}
    >
      {onLight ? (
        <KittedBrandmarkOnLight
          className={styles.kittedBrandmarkOnLight}
          useBrandColors={useBrandColors}
          fill={fill}
        />
      ) : (
        <KittedBrandmark
          className={styles.kittedBrandmark}
          useBrandColors={useBrandColors}
          fill={fill}
        />
      )}
      <KittedBrand className={styles.kittedBrand} fill={fill} />
    </div>
  );
};

export default memo(KittedBrandingFull);
