import { useCallback, useEffect } from 'react';
import { FavoriteModel } from '@kitted/account-service-models';
import { UserModel } from '@kitted/auth-service-models';

import useFavoritesPrime from '../../../../hooks/favorites/useFavoritesPrime';
import { AsyncRequestState } from '../../../../hooks/useAsyncRequest/types';
import useUser from '../../../../hooks/users/useUser';
import logger from '../../../../services/logger';
import useAuthTokensData from '../../../AuthTokensContext/hooks/useAuthTokensData';
import useStoreSection from '../../../StoreContext/hooks/useStoreSection';
import { LoginState } from '../../types';
import useIsLoggedIn from '../useIsLoggedIn';
import useUserApi from '../useUserApi';
import useLoginLogoutStoreSubscriptions from './hooks/useLoginLogoutStoreSubscriptions';

const useOnLoginLogout = () => {
  const { setLoginState } = useUserApi();
  const { isLoggedIn } = useIsLoggedIn();
  const { user } = useUser();
  const { hasTokens, tokenRefreshState } = useAuthTokensData();
  const { resetStoreSection: resetUserStoreSection } =
    useStoreSection<UserModel>('User');
  const { resetStoreSection: resetFavoriteStoreSection } =
    useStoreSection<FavoriteModel>('Favorites');
  const { primeFavorites } = useFavoritesPrime();

  useLoginLogoutStoreSubscriptions();

  const onLogin = useCallback(() => {
    logger.log('[useOnLoginLogout] onLogin');
    resetUserStoreSection();
    resetFavoriteStoreSection();
    setLoginState(LoginState.LOADING);
    primeFavorites();
  }, [
    setLoginState,
    primeFavorites,
    resetUserStoreSection,
    resetFavoriteStoreSection,
  ]);

  const onLogout = useCallback(() => {
    logger.log('[useOnLoginLogout] onLogout');
    resetUserStoreSection();
    resetFavoriteStoreSection();
    // TODO: @haxxxton remove "my" cards
    setLoginState(LoginState.OUT);
  }, [setLoginState, resetUserStoreSection, resetFavoriteStoreSection]);

  const handleTriggerLoginLogout = useCallback(() => {
    logger.log(
      '[UserContext] tokens (hasTokens, isRefreshing, hasUser, isLoggedIn)',
      hasTokens,
      tokenRefreshState,
      !!user,
      isLoggedIn
    );
    if (isLoggedIn && !hasTokens) {
      onLogout();
    } else if (
      !isLoggedIn &&
      hasTokens &&
      tokenRefreshState === AsyncRequestState.Success &&
      !user
    ) {
      onLogin();
    }
  }, [hasTokens, tokenRefreshState, user, isLoggedIn, onLogin, onLogout]);

  useEffect(handleTriggerLoginLogout, [handleTriggerLoginLogout]);
};

export default useOnLoginLogout;
