import { CatalogueSelectorViewApi } from '../../../../../contexts/AssetCatalogueProvider';
import { defaultSubjectFilterOptions } from '../../../constants';
import { SubjectFilterDropdownProps } from '../../types';

export const getOptions = (
  setValue: CatalogueSelectorViewApi['setFilter'],
  onClose: () => void
) =>
  defaultSubjectFilterOptions.map((filterOption) => ({
    ...filterOption,
    onClick: () => {
      setValue(filterOption.value);
      onClose();
    },
  }));

export const getSanitizedItem = (value: SubjectFilterDropdownProps['value']) =>
  defaultSubjectFilterOptions.find((option) => option.value === value);
