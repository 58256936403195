import { useContext } from 'react';

import { FormDataContext } from '../../FormDataContext';

const useFormData = () => {
  const formDataService = useContext(FormDataContext);

  if (formDataService === undefined) {
    throw Error(
      'useFormData must be used inside of a FormContext, ' +
        'otherwise it will not function correctly.'
    );
  }

  return formDataService;
};

export default useFormData;
