import { AuthorModel } from '@kitted/author-service-models';
import { AuthorCardModel } from '@kitted/card-service-models';

import useStoreItemSubscription from '../../../contexts/StoreContext/hooks/useStoreItemSubscription';
import { StoreSectionItemStates } from '../../../contexts/StoreContext/services/storeOfStores/types';

const useAuthor = (id: AuthorCardModel['id'] | undefined) => {
  const { item, state } = useStoreItemSubscription<AuthorModel<false>>(
    'Authors',
    id
  );

  return {
    author: item,
    isLoading: state !== StoreSectionItemStates.Ready,
  };
};

export default useAuthor;
