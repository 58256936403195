import { memo } from 'react';
import { EMPTY_OBJ } from '@kitted/shared-utils';
import { InternalRoute } from '@kitted/website-constants';

import { getTitleFromRouteTitle } from '../../../../components/SEOHead/hooks/usePageTitle/logic';
import menus from '../../../../constants/routes/menus';
import MainMenuItem from '../MainMenuItem';

const HeaderMenu = () => (
  <nav>
    <ul>
      {menus.mainMenu.map(({ route, icon }) => {
        const title = getTitleFromRouteTitle(route.title, EMPTY_OBJ, false);
        const isAuthenticated =
          'isAuthenticated' in route ? route.isAuthenticated : false;

        return (
          <MainMenuItem
            key={route.path}
            path={route.path}
            target={route.target}
            title={title}
            isAuthenticated={isAuthenticated}
            preinterpolatedRoutePath={
              (route as InternalRoute)?.preinterpolatedRoutePath
            }
            icon={icon}
          >
            {title}
          </MainMenuItem>
        );
      })}
    </ul>
  </nav>
);

export default memo(HeaderMenu);
