import { createContext, useMemo } from 'react';
import { EMPTY_PROMISE, NOOP } from '@kitted/shared-utils';

import { AsyncRequestState } from '../../hooks/useAsyncRequest/types';
import useAuthTokensManagement from './hooks/useAuthTokensManagement';
import { AuthTokensApi, AuthTokensContextProps, AuthTokensData } from './types';

export const AuthTokensContext = createContext<AuthTokensApi>({
  getAccessToken: () => undefined,
  getRefreshToken: () => undefined,
  getTokens: () => undefined,
  requestTokenRefresh: EMPTY_PROMISE,
  setTokens: NOOP,
});

export const AuthTokensDataContext = createContext<AuthTokensData>({
  hasCheckedTokens: false,
  hasTokens: false,
  tokens: undefined,
  tokenRefreshState: AsyncRequestState.Default,
});

export const AuthTokensProvider: React.FC<AuthTokensContextProps> = ({
  children,
}) => {
  const {
    tokens,
    tokenRefreshState,
    hasCheckedTokens,
    getAccessToken,
    getRefreshToken,
    getTokens,
    requestTokenRefresh,
    setTokens,
  } = useAuthTokensManagement();
  // https://www.developerway.com/posts/react-re-renders-guide#part7.2
  // https://www.developerway.com/posts/how-to-write-performant-react-apps-with-context#part3
  const apiValue = useMemo(
    () => ({
      getAccessToken,
      getRefreshToken,
      getTokens,
      requestTokenRefresh,
      setTokens,
    }),
    [getAccessToken, getRefreshToken, getTokens, requestTokenRefresh, setTokens]
  );
  const dataValue = useMemo(
    () => ({
      tokens,
      tokenRefreshState,
      hasCheckedTokens,
      hasTokens: !!tokens,
    }),
    [tokens, tokenRefreshState, hasCheckedTokens]
  );

  return (
    <AuthTokensContext.Provider value={apiValue}>
      <AuthTokensDataContext.Provider value={dataValue}>
        {children}
      </AuthTokensDataContext.Provider>
    </AuthTokensContext.Provider>
  );
};
