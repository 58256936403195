import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0020: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 108 102"
		height={102}
		width={108}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="m106.79 44.76-7.4-7.4s-3.1-3.36-8.44-2.24c0 0 2.25-6.18-.98-9.41L73.25 8.99S60.37-5.85 34.07 5.97c0 0 17.72 4.11 22.88 10.61 3.23 4.07 3.83 9.08.71 12.2-3.95 3.95-6.92 6.88-6.92 6.88l14.95 14.95 6.46-6.46c4.31-4.31 9.41.37 9.41.37-1.54 5.66 1.79 8.89 1.79 8.89l7.4 7.4 16.04-16.04Z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M58.31 54.05 12.96 99.4c-1.61 1.6-4.23 1.6-5.83 0l-4.72-4.72a4.134 4.134 0 0 1 0-5.84l45.56-45.56" />
    </g>
  </CardIcon>
);

export default memo(Sn0020);
