import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0056: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 128 114"
		height={114}
		width={128}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M32.32 49.53V66M32.32 96.32v16.47M63.95 81.16H47.47M17.16 81.16H.69" /><circle vectorEffect="non-scaling-stroke" cx="32.32" cy="81.16" r="14.72" /><path vectorEffect="non-scaling-stroke" d="M28.8 105.98C16.62 104.27 7.25 93.81 7.25 81.16M7.5 77.65c1.55-11.03 10.28-19.76 21.31-21.31M38.71 81.16a6.4 6.4 0 1 1-12.8 0c0-3.54 2.86-6.4 6.4-6.4s6.4 2.86 6.4 6.4M57.38 81.16c0 13.84-11.22 25.07-25.07 25.07M32.32 56.09c12.66 0 23.13 9.39 24.83 21.59" /></g><g ><path vectorEffect="non-scaling-stroke" stroke={color} d="M88.51 31.34c-2.13 11.79-12.44 20.74-24.85 20.74-13.94 0-25.25-11.31-25.25-25.25S49.71 1.58 63.66 1.58c12.38 0 22.68 8.91 24.83 20.67" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M78.46 31.29c-1.91 6.29-7.76 10.87-14.67 10.87-8.47 0-15.33-6.86-15.33-15.33S55.32 11.5 63.79 11.5c6.88 0 12.69 4.53 14.64 10.76" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M68.21 31.25a6.252 6.252 0 0 1-10.67-4.42c0-3.45 2.8-6.25 6.25-6.25 1.66 0 3.17.65 4.28 1.7" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M63.9 26.76h37.6M101.5 26.76l4.67-5.8M106.17 32.54l-4.67-5.8M92.72 26.76l4.67-5.8M97.39 32.54l-4.67-5.8" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M95.68 49.53V66M95.68 96.32v16.47M127.31 81.16h-16.47M80.53 81.16H64.05" /><circle vectorEffect="non-scaling-stroke" cx="95.68" cy="81.16" r="14.72" /><path vectorEffect="non-scaling-stroke" d="M92.17 105.98c-12.18-1.71-21.55-12.17-21.55-24.82M70.86 77.65c1.55-11.03 10.28-19.76 21.31-21.31M102.08 81.16a6.4 6.4 0 1 1-12.8 0c0-3.54 2.86-6.4 6.4-6.4s6.4 2.86 6.4 6.4M120.75 81.16c0 13.84-11.22 25.07-25.07 25.07M95.68 56.09c12.66 0 23.13 9.39 24.83 21.59" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0056);
