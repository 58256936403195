import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      marginBottom: styling.sizes.theme.spacing['2xs'],
    },
  },
  { name: 'footerMenu' }
);
