import { memo } from 'react';

import { StoreSectionInitializerProps } from '../types';
import useKitStoreInitializer from './hooks/useKitStoreInitializer';

const KitStoreInitializer = ({
  onSectionLoaded,
}: StoreSectionInitializerProps) => {
  useKitStoreInitializer(onSectionLoaded);

  return null;
};

export default memo(KitStoreInitializer);
