import { useMemo } from 'react';
import { CardType } from '@kitted/kitted-models';

import { useGlobalScriptVariables } from '../../../../contexts/GlobalScriptVariablesContext';
import useAuthor from '../../../../hooks/authors/useAuthor';
import useMyAuthor from '../../../../hooks/authors/useMyAuthor';
import useAuthorMeta from '../../../../hooks/useAuthorMeta';
import { useDrawerSpaceContainerData } from '../../../DrawerSpace/context/DrawerSpaceContainerContext';
import { CardAuthorAvatarProps } from '../../types';

const useCardAuthorAvatar = (
  cardType: CardAuthorAvatarProps['cardType'],
  authorId: CardAuthorAvatarProps['authorId']
) => {
  const { KITTED_PLATFORM_AUTHOR_ID } = useGlobalScriptVariables();
  const { author } = useAuthor(authorId);
  const { initials } = useAuthorMeta(author);
  const { author: myAuthor } = useMyAuthor();
  const { contentRoot } = useDrawerSpaceContainerData();

  const shouldHide = useMemo(
    () =>
      !authorId ||
      authorId === KITTED_PLATFORM_AUTHOR_ID ||
      cardType === CardType.Author,
    [authorId, KITTED_PLATFORM_AUTHOR_ID, cardType]
  );

  return {
    shouldHide,
    author,
    initials,
    isMyAuthor: myAuthor?.id === authorId,
    contentRoot,
  };
};

export default useCardAuthorAvatar;
