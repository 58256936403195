import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    reset: {
      marginBottom: 32,
    },
  },
  { name: 'authChangePasswordModalContent' }
);
