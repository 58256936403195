import { memo } from 'react';
import clsx from 'clsx';
import { ChevronLeft, ChevronRight } from '@kitted/website-icons';

import {
  DrawerSpaceDrawerSide,
  useDrawerSpaceContainerApi,
  useDrawerSpaceContainerData,
} from '../../context/DrawerSpaceContainerContext';
import useStyles from './styles';
import { DrawerSpaceToggleProps } from './types';

const DrawerSpaceToggle = ({
  drawerHeader: DrawerHeader,
  side,
}: DrawerSpaceToggleProps) => {
  const styles = useStyles();
  const { onToggleOpen } = useDrawerSpaceContainerApi();
  const { isMainOpen, isContextualOpen } = useDrawerSpaceContainerData();
  const isContextual = side === DrawerSpaceDrawerSide.Contextual;

  return (
    <button
      className={clsx(styles.container, isContextual && styles.containerRight)}
      onClick={onToggleOpen(side)}
      type="button"
      aria-label="Toggle drawer open"
    >
      <DrawerHeader />
      <div className={styles.buttonContainer}>
        <div
          className={clsx(
            styles.button,
            ((isMainOpen && !isContextual) ||
              (isContextualOpen && isContextual)) &&
              styles.buttonOpen
          )}
        >
          {isContextual ? (
            <ChevronRight size="inherit" />
          ) : (
            <ChevronLeft size="inherit" />
          )}
        </div>
      </div>
    </button>
  );
};

export default memo(DrawerSpaceToggle);
