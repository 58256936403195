import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    '@global': {
      '[data-radix-popper-content-wrapper]': {
        zIndex: [[styling.zIndex.tooltip], '!important'],
      },
    },
    triggerWrapper: {
      display: 'inline-block',
    },
    container: {
      borderRadius: styling.sizes.context.card.radius,
      paddingInline: styling.sizes.theme.spacing['2xs'],
      paddingBlock: styling.sizes.theme.spacing['3xs'],
      userSelect: 'none',
      textAlign: 'center',
      '& > span:not([role="tooltip"]):last-of-type:before': {
        content: '""',
        position: 'absolute',
        border: [0, 'solid', styling.colors.theme.transparent],
        borderWidth: [11, 7],
        marginLeft: -7,
        height: 0,
        width: 0,
        top: 0,
        left: '50%',
        zIndex: 1,
      },
    },
    hoistedTrigger: {
      position: 'absolute',
      width: 'var(--radix-popper-anchor-width)',
      height: 'var(--radix-popper-anchor-height)',
      '[data-side="top"] &': {
        top: '100%',
        '--hoistedXOffset': '0px',
        '--hoistedYOffset': 'calc(var(--sideOffset) * 1px)',
      },
      '[data-side="left"] &': {
        // TODO: @haxxxton add styling for this
      },
      '[data-side="right"] &': {
        // TODO: @haxxxton add styling for this
      },
      '[data-side="bottom"] &': {
        bottom: '100%',
        '--hoistedXOffset': '0px',
        '--hoistedYOffset': 'calc(var(--sideOffset) * 1px)',
      },
      '[data-align="start"] &': {
        left: 0,
        translate: 'var(--hoistedXOffset) var(--hoistedYOffset)',
      },
      '[data-align="center"] &': {
        left: '50%',
        translate: 'calc(-50% + var(--hoistedXOffset)) var(--hoistedYOffset)',
      },
      '[data-align="end"] &': {
        left: '100%',
        translate: 'calc(-100% + var(--hoistedXOffset)) var(--hoistedYOffset)',
      },
    },
    arrow: {
      position: 'relative',
      zIndex: 2,
      fill: styling.colors.theme.pureWhite,
    },
    backdropContainer: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: styling.zIndex.backdrops.tooltip,
    },
    backdrop: {
      ...styling.utils.absoluteFull(),
      cursor: 'pointer',
      backgroundColor: styling.colors.theme.primary900_90,
    },
  },
  { name: 'popover' }
);
