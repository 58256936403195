import { hexToRGB } from './toRgb';

// palette generator courtesy of https://github.com/Leolainen/figma-plugin-Material-palette
// which was used to generate the swatches in the figma designs
export function hexToHSL(hex: string) {
  // Convert hex to RGB first
  const RGB = hexToRGB(hex);
  let { r, g, b } = RGB;

  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return { h, s, l, string: `hsl(${h},${s}%,${l}%)` };
}
