import { useMemo } from 'react';

import { CardSubType } from '../../../types';
import { getToolCardStyle } from '../../logic';

const useToolCard = (subType: CardSubType | undefined) => {
  const style = useMemo(() => getToolCardStyle(subType), [subType]);

  return {
    style,
  };
};

export default useToolCard;
