import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0009: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 100 106"
		height={106}
		width={100}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><circle vectorEffect="non-scaling-stroke" cx="55.29" cy="34.98" r="23.24" /><path vectorEffect="non-scaling-stroke" d="M55.29 23.26a.1.1 0 0 0-.1.1v11.53h-6.88c-.05 0-.1.04-.1.1s.04.1.1.1h6.97a.1.1 0 0 0 .1-.1V23.37a.1.1 0 0 0-.1-.1Z" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M13.34 16.94H8.83c-4.21 0-7.62 3.71-7.62 8.28v70.82c0 4.57 3.41 8.28 7.62 8.28h82.35c4.2 0 7.61-3.7 7.61-8.27V9.94c0-4.57-3.41-8.27-7.61-8.27H20.95c-4.2 0-7.61 3.7-7.61 8.27v76.14M61.48 69.29h27.14M23.51 69.29h27.14M61.48 80.15h27.14M23.51 80.15h27.14M61.48 80.15h27.14M23.51 80.15h27.14M61.48 91.01h27.14M23.51 91.01h27.14M61.48 91.01h27.14M23.51 91.01h27.14" />
    </g>
  </CardIcon>
);

export default memo(Ac0009);
