import { useCallback } from 'react';

import { handleGetInteractionProps } from '../../logic';
import { AlertButton, AlertButtonsProps } from '../../types';

const useAlertButtons = (onClose: AlertButtonsProps['onClose']) => {
  const getInteractionProps = useCallback(
    (props: Omit<AlertButton, 'title'>) =>
      handleGetInteractionProps(props, onClose),
    [onClose]
  );

  return {
    getInteractionProps,
  };
};

export default useAlertButtons;
