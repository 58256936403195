import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const LaptopArticle: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 30 20"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M8.02 4.68h4.18v4.4H8.02V6.51M14.52 4.67h7.35M14.52 6.88h7.35M14.52 9.08h7.35M8.02 11.28h13.85M8.02 13.48h10.71" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M26.01 14.47V3.38c0-.95-.77-1.72-1.72-1.72H5.6c-.95 0-1.72.77-1.72 1.72v11.09M14.94 16.73h2.04l.39-.43H28.6c-.35 1.14-1 2.05-2.05 2.05H3.45c-1.05 0-1.7-.9-2.05-2.05h11.12l.39.43h2.04Z" />
    </g>
  </WebsiteIcon>
);

export default memo(LaptopArticle);
