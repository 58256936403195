import { Entries } from 'type-fest';
import { EMPTY_OBJ, upperFirst } from '@kitted/shared-utils';

import { FormGridProps } from './types';

const constructSpanVariables = (
  widthEntries: Entries<Record<string, number>>
) =>
  widthEntries.reduce(
    (acc, [key, span]) => ({
      ...acc,
      [`--formGridItem${upperFirst(key)}Span`]: span,
    }),
    EMPTY_OBJ as React.CSSProperties
  );

export const calculateStyles = (
  item: FormGridProps['item'],
  widths: FormGridProps['widths']
) => {
  if (!widths || !item) return undefined;

  return constructSpanVariables(Object.entries(widths));
};
