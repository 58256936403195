import { memo } from 'react';

import { StoreSectionInitializerProps } from '../types';
import usePlayStoreInitializer from './hooks/usePlayStoreInitializer';

const PlayStoreInitializer = ({
  onSectionLoaded,
}: StoreSectionInitializerProps) => {
  usePlayStoreInitializer(onSectionLoaded);

  return null;
};

export default memo(PlayStoreInitializer);
