/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import { EMPTY_ARR } from '@kitted/shared-utils';

import Button from '../../Button';
import useAlertButtons from './hooks/useAlertButtons';
import useStyles from './styles';
import { AlertButtonsProps } from './types';

const AlertButtons = ({ buttons = EMPTY_ARR, onClose }: AlertButtonsProps) => {
  const styles = useStyles();
  const { getInteractionProps } = useAlertButtons(onClose);

  if (buttons.length === 0) return null;

  return (
    <div className={styles.container}>
      {buttons.map(({ title, ...rest }, i) => (
        <Button
          key={title}
          fullWidth
          theme="primary"
          variant={i === 0 ? 'solid' : 'outline'}
          {...getInteractionProps(rest)}
        >
          {title}
        </Button>
      ))}
    </div>
  );
};

export default memo(AlertButtons);
