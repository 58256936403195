import { useRef } from 'react';

import createStoreOfStores from '../../services/storeOfStores';
import { StoreOfStoresApi } from '../../services/storeOfStores/types';

const useStoreManagement = () => {
  const storeApiRef = useRef<StoreOfStoresApi>(createStoreOfStores());

  return storeApiRef.current;
};

export default useStoreManagement;
