import { AnalyticsContext } from './AnalyticsContext';
import { useAnalyticsManagement } from './hooks';
import { AnalyticsContextProps } from './types';

export const AnalyticsProvider: React.FC<AnalyticsContextProps> = ({
  children,
}) => {
  const analyticsManagement = useAnalyticsManagement();

  return (
    <AnalyticsContext.Provider value={analyticsManagement}>
      {children}
    </AnalyticsContext.Provider>
  );
};
