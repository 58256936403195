/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import {
  AnyResourceCardModel,
  AnyToolCardModel,
  ArticleCardModel,
  AuthorCardModel,
  FlowCardModel,
  ImageResourceCardModel,
  KitCardModel,
  LinkResourceCardModel,
  PlayCardModel,
  SubjectCardModel,
  VideoResourceCardModel,
} from '@kitted/card-service-models';
import { BlobGuid, CardType } from '@kitted/kitted-models';
import { getFullName } from '@kitted/shared-utils';

import { CardProps } from '../../../../../components/Card/types';
import { RenderCardComponentProps } from '../../types';

type CardComponentProps = Omit<
  CardProps,
  'href' | 'to' | 'onClick' | 'preventActive' | 'isFeatured'
>;

const getBlobStorageContentUrl = (baseUrl: string, blobId?: BlobGuid) => {
  if (!blobId) return undefined;

  if (blobId.startsWith('T::')) {
    return `https://temporary.blob-storage.${baseUrl}/${blobId}`;
  }

  return `https://persistent.blob-storage.${baseUrl}/${blobId}`;
};

const getCardComponentProps: Record<
  string,
  (baseUrl: string, card: any) => CardComponentProps
> = {
  tool: (baseUrl: string, tool: AnyToolCardModel<true>) => ({
    slug: tool.slug,
    body: tool.description,
    imageSrc: getBlobStorageContentUrl(
      baseUrl,
      tool.keyImageImageAsset?.sizes.xl.blobGuid
    ),
    nonInteractive: true,
    title: tool.title,
    subType: tool.toolType,
    type: CardType.Tool,
  }),
  subject: (baseUrl: string, subject: SubjectCardModel<true>) => ({
    slug: subject.slug,
    body: subject.description,
    imageSrc: getBlobStorageContentUrl(
      baseUrl,
      subject.keyImageImageAsset?.sizes.xl.blobGuid
    ),

    nonInteractive: true,
    title: subject.title,
    type: CardType.Subject,
  }),
  kit: (baseUrl: string, kit: KitCardModel<true>) => ({
    slug: kit.slug,
    body: kit.description,
    imageSrc: getBlobStorageContentUrl(
      baseUrl,
      kit.keyImageImageAsset?.sizes.xl.blobGuid
    ),

    nonInteractive: true,
    title: kit.title,
    type: CardType.Kit,
  }),
  article: (baseUrl: string, article: ArticleCardModel<true>) => ({
    slug: article.slug,
    body: article.description,
    imageSrc: getBlobStorageContentUrl(
      baseUrl,
      article.keyImageImageAsset?.sizes.xl.blobGuid
    ),

    nonInteractive: true,
    title: article.title,
    type: CardType.Article,
  }),
  play: (baseUrl: string, play: PlayCardModel<true>) => ({
    slug: play.slug,
    body: play.description,
    imageSrc: getBlobStorageContentUrl(
      baseUrl,
      play.keyImageImageAsset?.sizes.xl.blobGuid
    ),

    nonInteractive: true,
    title: play.title,
    type: CardType.Play,
  }),
  flow: (baseUrl: string, flow: FlowCardModel<true>) => ({
    slug: flow.slug,
    body: flow.description,
    imageSrc: getBlobStorageContentUrl(
      baseUrl,
      flow.keyImageImageAsset?.sizes.xl.blobGuid
    ),

    nonInteractive: true,
    title: flow.title,
    type: CardType.Flow,
  }),
  resource: (baseUrl: string, resource: AnyResourceCardModel<true>) => {
    const { cardType, resourceType, title, keyImageImageAsset, label, slug } =
      resource;
    const baseProps = {
      slug,
      label,
      imageSrc: getBlobStorageContentUrl(
        baseUrl,
        keyImageImageAsset?.sizes.xl.blobGuid
      ),

      nonInteractive: true,
      title,
      type: cardType,
      subType: resourceType,
    };
    switch (resourceType) {
      case 'link': {
        const { description } = resource as LinkResourceCardModel<true>;
        return {
          ...baseProps,
          body: description,
        };
      }
      case 'video': {
        const { description } = resource as VideoResourceCardModel<true>;
        return {
          ...baseProps,
          body: description,
        };
      }
      case 'image': {
        const { showTitle } = resource as ImageResourceCardModel<true>;
        return {
          ...baseProps,
          showTitle,
        };
      }
      default:
        throw new Error(`Unknown resource card type: ${resourceType}`);
    }
  },
  author: (baseUrl: string, author: AuthorCardModel<true>) => ({
    type: CardType.Author,
    slug: author.slug,
    title: getFullName(author.firstName, author.lastName),
    body: author.description,
    imageSrc: getBlobStorageContentUrl(
      baseUrl,
      author.keyImageImageAsset?.sizes.xl.blobGuid
    ),
    nonInteractive: true,
  }),
};

const getCardComponentDefaultProps = (): CardComponentProps => ({
  slug: '',
  body: '',
  title: '',
  type: CardType.Tool,
});

export const useCardComponentProps = ({
  card,
  baseUrl,
}: RenderCardComponentProps) => {
  const cardComponentProps = useMemo(() => {
    const cardProps = getCardComponentProps[card.cardType as string]
      ? getCardComponentProps[card.cardType](baseUrl, card)
      : getCardComponentDefaultProps();
    return cardProps;
  }, [card, baseUrl]);

  return cardComponentProps;
};
