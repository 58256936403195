import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    stripe: {
      '--cardFooterStripeBackground': styling.colors.theme.transparent,
      position: 'absolute',
      bottom: 0,
      height: 4,
      left: 0,
      right: 0,
      zIndex: 5,
      background: 'var(--cardFooterStripeBackground)',
      transition: styling.transitions.utils.generate(['background']),
    },
    stripeMenuOpen: {
      '--cardFooterStripeBackground': [
        [styling.colors.theme.transparent],
        'important',
      ],
    },
  },
  { name: 'cardFooterStripe' }
);
