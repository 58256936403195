import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0010: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 84 106"
		height={106}
		width={84}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M76.79 51.52v53H1.16V41.44h43.71M13.26 54.25h22.71M13.26 64.81h22.71M13.26 77.66h47.87M13.26 89.06h47.87" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="m52.93 63.98-2.29 5.3M51.18 52.8A96.86 96.86 0 0 1 74.43 2.65c1.12-1.14 3.33-1.51 4.87-.84.26.12.53.23.8.35 1.54.66 2.8 2.52 2.73 4.12C81.11 24.96 73.9 42.9 62.29 57.6l-11.12-4.8ZM51.79 53.93l-1.12 9.05 4.52 1.95 5.85-7.05M69.6 8.05l-7.41-3.72s-7.03 9.49-11.29 19.68M60.31 24.29l16.44 7.09" />
    </g>
  </CardIcon>
);

export default memo(Ac0010);
