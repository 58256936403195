import useClickOutside from '../../../../../../../../hooks/useClickOutside';
import { MobileSubjectFilterDropdownProps } from '../../types';

const useMobileSubjectFilterDropdown = (
  isOpen: boolean,
  onClickOutside: MobileSubjectFilterDropdownProps['onClickOutside']
) => {
  const { ref: listRef } = useClickOutside(isOpen, onClickOutside);

  return {
    listRef,
  };
};

export default useMobileSubjectFilterDropdown;
