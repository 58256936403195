import { createContext } from 'react';

import { GlobalScriptVariablesData } from './types';

export const GlobalScriptVariablesContext =
  createContext<GlobalScriptVariablesData>({
    KITTED_PLATFORM_AUTHOR_ID: '',
    KITTED_PLATFORM_USER_ID: '',

    KITTED_ENVIRONMENT: '',
    KITTED_FEATURE: '',
    KITTED_MODULE: '',
    KITTED_DOMAIN: '',
  });
