import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const PresentationScreenPlay: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 26 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="m13 19.25-6.99 6.99M13 19.25l6.99 6.99M2.51 1.76V15.4c0 1.15.93 2.1 2.07 2.1h16.84c1.14 0 2.07-.94 2.07-2.1V1.76M.76 1.76h24.48M13 26.24V17.5" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m11.25 7.01 5.25 2.62-5.25 2.62V7Z" />
    </g>
  </WebsiteIcon>
);

export default memo(PresentationScreenPlay);
