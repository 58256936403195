/* eslint-disable @typescript-eslint/no-explicit-any */

// empty basics
export const EMPTY_ARR: any = Object.freeze([]);
export const EMPTY_OBJ: Record<string, any> = Object.freeze({});
export const EMPTY_SET: Set<any> = new Set();

export const EMPTY_PROMISE = (..._args: any[]): Promise<any> =>
  new Promise((resolve) => {
    window.setTimeout(() => {
      resolve(undefined);
    });
  });
export const EMPTY_UNRESOLVED_PROMISE = (..._args: any[]): Promise<any> =>
  new Promise(() => {
    // empty fn
  });

export const EMPTY_RESOLVED_PROMISE = (): Promise<any> => Promise.resolve();

// empty functions
export const NOOP = (..._args: any[]): any => {
  // empty fn
};
export const FALSE_OP = (..._args: any[]) => false;
export const NULL_OP = (..._args: any[]) => null;
export const TRUE_OP = (..._args: any[]) => true;
