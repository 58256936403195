import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const ArrowToLineRight: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 24"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M19.16 12H1.38M19.16 12l-8.19-8.19M10.97 20.19 19.16 12M26.62 22.07V1.93" />
    </g>
  </WebsiteIcon>
);

export default memo(ArrowToLineRight);
