import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

import typographyConstants from '../../Typography/constants';
import { centerOffset } from '../CardContents/styles';

const cardBodyPaddingTop = 14;

export default createUseStyles(
  {
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      zIndex: 5,
      height: '100%',
      flexGrow: 1,
      paddingInline: 'var(--cardPaddingInline)',
    },
    contentActive: {
      '& $cardBodyContainer': {
        maxHeight: `calc(${
          typographyConstants.cardBodyHover.lineHeight
        } * var(--cardHoverBodyMaxLines) + ${styling.utils.px(
          cardBodyPaddingTop
        )})`,
      },
    },
    contentMenuOpen: {
      '& $cardBodyContainer': {
        maxHeight: 0,
      },
      '& $menuSpacing': {
        // 50% card height - dividerHeight - centerOffset - bottomPadding
        height: `calc(${
          styling.sizes.context.card.height / 2 - 37 - centerOffset
        }px - var(--cardBottomPadding))`,
      },
      '& $title': {
        height: `calc(var(--cardTitleMaxLines) * ${typographyConstants.cardTitle.lineHeight})`,
        paddingBlock: `calc(${typographyConstants.cardTitle.lineHeight} / 2)`,
      },
    },
    title: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      boxSizing: 'content-box',
      height: 'auto',
      padding: 0,
      transition: styling.transitions.utils.generate(
        ['height', styling.speeds.jog, styling.transitions.easing.linear],
        ['padding', styling.speeds.jog, styling.transitions.easing.linear]
      ),
    },
    menuSpacing: {
      width: '100%',
      height: 0,
      transition: styling.transitions.utils.generate([
        'height',
        '250ms',
        styling.transitions.easing.linear,
        '50ms',
      ]),
    },
    cardBodyContainer: {
      maxHeight: 0,
      overflow: 'hidden',
      transition: styling.transitions.utils.generate(['max-height']),
    },
    cardBody: {
      paddingTop: cardBodyPaddingTop,
      boxSizing: 'content-box',
    },
    divider: {
      zIndex: 6,
      width: 'calc(100% - 72px)',
    },
  },
  { name: 'cardContentsForHoverableCard' }
);
