import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0018: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 106 106"
		height={106}
		width={106}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M17.13 50.93c-.28 0-.57-.06-.84-.16a2.23 2.23 0 0 1-1.23-2.91c.34-.85 1.16-1.39 2.07-1.39.28 0 .57.06.83.16 1.14.46 1.7 1.77 1.23 2.91-.34.85-1.16 1.4-2.07 1.4ZM24.34 68.24c-.28 0-.57-.06-.84-.16a2.23 2.23 0 0 1-1.23-2.91c.34-.85 1.16-1.39 2.07-1.39.28 0 .57.06.83.16 1.14.46 1.7 1.77 1.23 2.91-.34.85-1.16 1.4-2.07 1.4Z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M52.75 89.29c-8.17 2.13-17.5 1.99-25.93-1.42C6 79.45-3.54 55.95 4.36 34.92c5.75-15.31 19.05-11.53 21.88-5.18 2.42 5.41 7.68 6.16 11.85 4.39 3.43-1.46 4.8-5.1 4.28-9.09-1.04-7.89 4.94-16.08 15.48-11.81 4.38 1.77 9.4 4.61 12.72 7.52" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M58.74 33.14c-.28 0-.57-.06-.84-.16a2.23 2.23 0 0 1-1.23-2.91c.34-.85 1.16-1.39 2.07-1.39.28 0 .57.06.83.16 1.14.46 1.7 1.77 1.23 2.91-.34.85-1.16 1.4-2.07 1.4ZM42.16 76.7c-.28 0-.57-.06-.84-.16a2.23 2.23 0 0 1-1.23-2.91c.34-.85 1.16-1.39 2.07-1.39.28 0 .57.06.83.16 1.14.46 1.7 1.77 1.23 2.91-.34.85-1.16 1.4-2.07 1.4Z" /><g ><path vectorEffect="non-scaling-stroke" stroke={color} d="M71.46 59.31c.55-6.84 1.97-17.53 5.62-28.39C83.19 12.75 89.76 1.46 89.76 1.46M85.51 66.4c3.6-6.13 9.32-16.96 12.71-28.94 5.22-18.45 6.18-31.47 6.18-31.47" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M80.03 70.23c-4.81-3.78-11.67-3.46-15.92.69 0 0-4.62 4.1-2.45 14.01 2.39 10.9-3.43 19.6-3.43 19.6s12.25-2.4 24.03-16.69c4.69-5.69 3-13.51-2.24-17.61Z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m89.24 59.13-10.47-3.29" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0018);
