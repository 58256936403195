import { v4 as uuidv4 } from 'uuid';
import { clearInterval, setInterval } from 'worker-timers';

import {
  createInitialIntervalStore,
  handleAddInternalCallback,
  handleRemoveIntervalCallback,
} from './logic';
import {
  IntervalCallback,
  IntervalCallbackStoreId,
  IntervalCallbackStoreItem,
  IntervalId,
  Intervals,
  IntervalStoreUpdater,
} from './types';

const createIntervalStore = (intervals: Intervals) => {
  let intervalStore = createInitialIntervalStore(intervals);
  let intervalTimerStore: Record<number, number> = {};

  const setIntervalStore = (setNewStore: IntervalStoreUpdater) => {
    intervalStore = setNewStore(intervalStore);
  };

  const removeIntervalCallback = (
    intervalId: IntervalId,
    intervalCallbackId: IntervalCallbackStoreId
  ) =>
    handleRemoveIntervalCallback(
      setIntervalStore,
      intervalId,
      intervalCallbackId
    );

  const addIntervalCallback = (
    intervalId: IntervalId,
    intervalCallback: IntervalCallback
  ) =>
    handleAddInternalCallback({
      setIntervalStore,
      intervalId,
      intervalCallbackId: uuidv4(),
      intervalCallback,
      removeIntervalCallback,
    });

  const removeIntervalStore = () => {
    intervals.forEach((intervalId) => {
      clearInterval(intervalTimerStore[intervalId]);
    });
  };

  const intervalInvokation = (intervalId: IntervalId) => {
    const timerId = setInterval(() => {
      const now = Date.now();
      intervalStore[intervalId].forEach(
        (intervalCallbackStore: IntervalCallbackStoreItem) => {
          intervalCallbackStore.callback(now);
        }
      );
      // intervalInvokation(intervalId);
    }, intervalId);

    intervalTimerStore = {
      ...intervalTimerStore,
      [intervalId]: timerId,
    };
  };

  const beginIntervalInvokation = () => {
    intervals.forEach((intervalId) => {
      intervalInvokation(intervalId);
    });
  };

  beginIntervalInvokation();

  return {
    addIntervalCallback,
    removeIntervalStore,
  };
};

export default createIntervalStore;
