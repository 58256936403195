import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      height: 48,
      borderBottom: 'none',
      transition: styling.transitions.utils.generate(['height']),
    },
    loggedOrLogging: {
      height: 72,
    },
  },
  { name: 'accountMenuItem' }
);
