import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const CaretUp: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 26 14"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" fill={color} d="m13.24 1.27-.07-.07s-.11-.07-.18-.07-.13.03-.18.07c0 0-9.16 9.17-11.27 11.28-.14.14-.04.38.16.38h22.58c.2 0 .3-.24.16-.38z" />
    </g>
  </WebsiteIcon>
);

export default memo(CaretUp);
