import { Params } from 'react-router-dom';
import { routesFlat } from '@kitted/website-constants';

const replacePathnamePartsFromParams = (pathname: string, params: Params) => {
  let replacedPathname = pathname;
  Object.entries(params).forEach(([paramKey, paramValue]) => {
    replacedPathname = replacedPathname.replace(
      paramValue || '',
      `:${paramKey}`
    );
  });
  return replacedPathname;
};

export const findMatchingRouteIsAuthenticated = (
  replacedPathname: string,
  routeMap: typeof routesFlat
): boolean => {
  let matchedIsAuthenticated = false;
  for (const route of Object.values(routeMap)) {
    const { path, isAuthenticated } = route;
    if (path === replacedPathname) {
      matchedIsAuthenticated = isAuthenticated;
      break;
    }
  }
  return matchedIsAuthenticated;
};

export const getIsMatchingRouteAuthenticated = (
  routeMap: typeof routesFlat,
  pathname: string,
  params: Params
): boolean => {
  const replacedPathname = replacePathnamePartsFromParams(pathname, params);
  return findMatchingRouteIsAuthenticated(replacedPathname, routeMap);
};
