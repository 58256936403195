import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const BlockInline: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 26 24"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M.66 1.04h24.68M.66 22.96h24.68" /><rect vectorEffect="non-scaling-stroke" width="21.67" height="15.14" x="2.16" y="4.38" stroke={color} rx="1.73" ry="1.73" />
    </g>
  </WebsiteIcon>
);

export default memo(BlockInline);
