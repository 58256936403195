import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Gear: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M11.76 5.22c-.84.21-1.64.55-2.37.98L6.73 4.31 4.31 6.73 6.2 9.4c-.43.73-.76 1.51-.97 2.35l-3.22.55v3.42l3.22.55c.22.84.55 1.64.98 2.37l-1.9 2.65 2.42 2.42 2.65-1.9c.73.43 1.53.77 2.37.98l.55 3.22h3.42l.55-3.22a9.3 9.3 0 0 0 2.37-.98l2.65 1.9 2.42-2.42-1.9-2.65c.44-.74.77-1.54.99-2.39l3.21-.53V12.3l-3.21-.53a9.3 9.3 0 0 0-.98-2.37l1.89-2.66-2.42-2.42-2.67 1.89c-.73-.43-1.53-.77-2.37-.98l-.53-3.22H12.3l-.53 3.22Z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M13.36 10.55A3.508 3.508 0 0 1 17.51 14c0 1.94-1.57 3.51-3.51 3.51s-3.51-1.57-3.51-3.51c0-.21.02-.41.05-.61" />
    </g>
  </WebsiteIcon>
);

export default memo(Gear);
