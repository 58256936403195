import { z } from 'zod';
import {
  CardLoadedModelSchema,
  CardUnloadedModelSchema,
  KittedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '@kitted/kitted-models';
import { DateISOSchema } from '@kitted/platform-models';

import { CMSContentBlockTheme } from './Enum';

export const CMSContentBlockCardGridLoadedModelSchema =
  KittedModelBaseSchema.merge(
    z.object({
      theme: z.nativeEnum(CMSContentBlockTheme),
      cards: makeKittedModelReferenceSchema(
        CardLoadedModelSchema,
        true
      ).array(),

      createdByAuthorId: z.string(),
      createdByUserId: z.string(),

      createdAt: DateISOSchema,
      updatedAt: DateISOSchema,
    })
  );

export const CMSContentBlockCardGridUnloadedModelSchema =
  CMSContentBlockCardGridLoadedModelSchema.merge(
    z.object({
      cards: makeKittedModelReferenceSchema(
        CardUnloadedModelSchema,
        false
      ).array(),
    })
  );

export type CMSContentBlockCardGridLoadedModel = z.infer<
  typeof CMSContentBlockCardGridLoadedModelSchema
>;
export type CMSContentBlockCardGridUnloadedModel = z.infer<
  typeof CMSContentBlockCardGridUnloadedModelSchema
>;

export type CMSContentBlockCardGridModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? CMSContentBlockCardGridLoadedModel
    : CMSContentBlockCardGridUnloadedModel;
