import { memo } from 'react';

import useNekuodah from './hooks/useNekuodah';
import Level from './Level';
import { NekuodahProps } from './types';

const Nekuodah = ({ components, children }: NekuodahProps) => {
  const { component, childComponent } = useNekuodah(components, children);
  return (
    <Level component={component} childComponent={childComponent}>
      {children}
    </Level>
  );
};

export default memo(Nekuodah);
