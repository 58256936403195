import { useCallback, useRef } from 'react';

const useQueryParamCaptureManagement = () => {
  const paramsRef = useRef<URLSearchParams>(
    new URLSearchParams(window.location.search)
  );

  const getCapturedQueryParams = useCallback(() => paramsRef.current, []);

  return {
    getCapturedQueryParams,
  };
};

export default useQueryParamCaptureManagement;
