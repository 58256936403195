import {
  extractVimeoIdFromUrl,
  extractYoutubeIdFromUrl,
} from '../../../components/Modal/LightboxModalContent/logic';
import { FieldValue } from '../../../contexts/FormContext';
import { isRequired } from './isRequired';
import { ValidationFn } from './types';

export type ValidationItemIsValidVideoURL = {
  name: 'isValidVideoURL';
  isRequired: boolean;
  validator: ValidationFn;
};

export const isValidVideoURL = (
  isValueRequired: boolean
): ValidationItemIsValidVideoURL => ({
  name: 'isValidVideoURL',
  isRequired: isValueRequired,
  validator: (value: FieldValue) => {
    if (isValueRequired) {
      const requiredErrors = isRequired.validator(value);
      if (requiredErrors) {
        return requiredErrors;
      }
    } else if (value === '' || value === null || value === undefined) {
      return undefined;
    }
    const youtubeId = extractYoutubeIdFromUrl(`${value}`);
    const vimeoId = extractVimeoIdFromUrl(`${value}`);
    if (youtubeId || vimeoId) return undefined;
    return 'Only YouTube or Vimeo links are supported';
  },
});
