import sizes from './sizes';

const breakpoints = {
  xs: 0,
  sm: sizes.utils.gridColumns(2, sizes.context.gutteringW, true), // 708
  md: sizes.utils.gridColumns(3, sizes.context.gutteringW, true), // 1044
  lg: 1280,
  xl: sizes.utils.gridColumns(5, sizes.context.gutteringW, true), // 1716
};

const getValueForKey = (key: keyof typeof breakpoints) => {
  if (breakpoints[key] !== undefined) {
    return breakpoints[key];
  }
  throw new Error(`[breakpoints] key not found: ${key}`);
};

const up = (key: keyof typeof breakpoints) =>
  `@media (min-width: ${getValueForKey(key)}px)`;

const down = (key: keyof typeof breakpoints) =>
  `@media (max-width: ${getValueForKey(key) - 1}px)`;

export default {
  theme: {
    ...breakpoints,
    tablet: breakpoints.sm,
    desktop: breakpoints.md,
  },
  context: {
    mobileOnlyString: down('sm'),
    tabletUpString: up('sm'),
    desktopUpString: up('md'),
    largeUpString: up('lg'),
    extraLargeUpString: up('xl'),
  },
  utils: {
    up,
    down,
  },
};
