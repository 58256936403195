import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';
import { typographyConstants } from '@kitted/shared-components';

export default createUseStyles(
  {
    container: {
      '--cardSkeletonHeight': styling.utils.px(
        styling.sizes.context.card.height
      ),
      '--cardSkeletonWidth': styling.utils.px(styling.sizes.context.card.width),
      '--cardSkeletonRadius': styling.utils.px(
        styling.sizes.context.card.radius
      ),
      '--cardSkeletonPaddingInline': styling.sizes.context.card.paddingInline,
      '--cardSkeletonBackground': styling.colors.context.card.background,
      '--cardSkeletonColor': styling.colors.context.card.color,
      '--cardSkeletonSlugBottom': '18px',
      '--cardSkeletonBottomPadding': `calc(var(--cardSkeletonSlugBottom) + ${typographyConstants.cardSlug.lineHeight})`,
      overflow: 'hidden',
      borderRadius: 'var(--cardSkeletonRadius)',
      position: 'relative',
      height: 'var(--cardSkeletonHeight)',
      width: 'var(--cardSkeletonWidth)',
    },
    containerFeatured: {
      '--cardSkeletonHeight': styling.utils.px(
        styling.sizes.context.cardFeature.height
      ),
      '--cardSkeletonWidth': styling.utils.px(
        styling.sizes.context.cardFeature.width
      ),
      '--cardSkeletonRadius': styling.utils.px(
        styling.sizes.context.cardFeature.radius
      ),
      '--cardSkeletonPaddingInline': styling.utils.px(
        styling.sizes.context.cardFeature.paddingInline
      ),
      '--cardSkeletonSlugBottom': '19px',
      '--cardSkeletonBottomPadding': `calc(var(--cardSkeletonSlugBottom) + ${typographyConstants.cardSlugFeatured.lineHeight})`,
    },
    containerInverted: {
      '--cardSkeletonBackground':
        styling.colors.context.card.invertedBackground,
      '--cardSkeletonColor': styling.colors.context.card.invertedColor,
    },
    card: {
      ...styling.utils.absoluteFull(),
      textDecoration: 'none',
      outline: 'none',
      background: 'var(--cardSkeletonBackground)',
      color: 'var(--cardSkeletonColor)',
      overflow: 'hidden',
      borderRadius: 'var(--cardSkeletonRadius)',
      paddingBlock: 'var(--cardSkeletonBottomPadding)',
      cursor: 'default',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    cardSlug: {
      position: 'absolute',
      left: 0,
      right: 0,
      zIndex: 5,
      bottom: 'var(--cardSkeletonSlugBottom)',
      paddingInline: 'var(--cardSkeletonPaddingInline)',
    },
  },
  { name: 'cardSkeleton' }
);
