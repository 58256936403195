import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0035: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 102 100"
		height={100}
		width={102}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M14.52 23.05h13.9V1.87H1.26v21.18H9.7L4.09 28.7M5.84 9.25h18M5.84 15.66h12.83" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M50.68 23.05h13.9V1.87H37.42v21.18h8.44l-5.61 5.65M42 9.25h18M42 15.66h12.83" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M86.84 23.05h13.9V1.87H73.58v21.18h8.44l-5.61 5.65M78.16 9.25h18M78.16 15.66h12.83" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M14.52 57.76h13.9V36.59H1.26v21.17H9.7l-5.61 5.65M5.84 43.97h18M5.84 50.38h12.83" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M50.68 57.76h13.9V36.59H37.42v21.17h8.44l-5.61 5.65M42 43.97h18M42 50.38h12.83" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M86.84 57.76h13.9V36.59H73.58v21.17h8.44l-5.61 5.65M78.16 43.97h18M78.16 50.38h12.83" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M14.52 92.47h13.9V71.3H1.26v21.17H9.7l-5.61 5.66M5.84 78.68h18M5.84 85.09h12.83" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M50.68 92.47h13.9V71.3H37.42v21.17h8.44l-5.61 5.66M42 78.68h18M42 85.09h12.83" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M86.84 92.47h13.9V71.3H73.58v21.17h8.44l-5.61 5.66M78.16 78.68h18M78.16 85.09h12.83" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0035);
