import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Reset: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M24.41 8.02C22.34 4.42 18.45 2 14 2 7.37 2 2 7.37 2 14s5.37 12 12 12 12-5.37 12-12" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m20.68 7.68 3.82.34.19-3.98" />
    </g>
  </WebsiteIcon>
);

export default memo(Reset);
