import { useContext } from 'react';

import { AlertContext } from '../..';

const useAlertApi = () => {
  const { registerAlert, deregisterAlert, closeAlert } =
    useContext(AlertContext);

  return {
    registerAlert,
    deregisterAlert,
    closeAlert,
  };
};

export default useAlertApi;
