import { memo } from 'react';

import { useDrawerSpaceContainerApi } from '../../context/DrawerSpaceContainerContext';

const DrawerSpaceSizerElement = () => {
  const { onSetRef } = useDrawerSpaceContainerApi();
  return <div ref={onSetRef} data-drawer-space-sizer="1" />;
};

export default memo(DrawerSpaceSizerElement);
