import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0020: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 104 108"
		height={108}
		width={104}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M65.04 14.77c4.73.86 9.78 2.58 15.01 5.55 0 0 14.47 6.26 11.41 26.29 0 0-.62 5.6 1.15 8.22l9.58 11.27s1.27 3.35-2.55 3.67l-5.84 1.2s-2.44 4.76-2.68 8.43c-.25 3.67.61 8.18.61 8.18s2.07 5.5-1.18 7.91c-.64.16-3.03 2.88-20.1-.79 0 0-1.95 1.79-3 10.71M30.61 105.55c.76-4.42 2.16-12.62 1.73-19.62 0 0-2.1-9.48-6.32-16.71M90.38 79.64l-7.33.12" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M43.18 5.1c-3.72-1.7-7.85-2.64-12.2-2.64-16.23 0-29.39 13.16-29.39 29.38 0 16.23 13.16 29.38 29.39 29.38s29.38-13.16 29.38-29.38c0-8.28-3.43-15.76-8.94-21.1" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M41 17.28 20.92 46.44" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M26.87 21.65a5.36 5.36 0 1 1-10.72 0 5.36 5.36 0 0 1 10.72 0M45.76 42.07a5.36 5.36 0 1 1-10.72 0 5.36 5.36 0 0 1 10.72 0" /></g>
    </g>
  </CardIcon>
);

export default memo(In0020);
