import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    container: {
      position: 'relative',
      flexDirection: 'column',
      justifyContent: 'center',
      display: 'flex',
      minHeight: 500,
      maxWidth: 500,
      margin: [40, 'auto'],
      textAlign: 'center',
      '& h4': {
        marginBottom: 48,
      },
    },
  },
  { name: 'emptyCatalogueItems' }
);
