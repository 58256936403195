/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';
import { Typography } from '@kitted/shared-components';

import useMobileMenuItem from './hooks/useMobileMenuItem';
import useStyles from './styles';
import { MobileMenuItemProps } from './types';

const MobileMenuItem = ({
  icon: Icon,
  onClick,
  path,
  target,
  title,
  highlightSubroutes,
  isSelected = false,
  hideSelectedState = false,
}: MobileMenuItemProps) => {
  const styles = useStyles();
  const { ItemComponent, itemProps, isItemSelected } = useMobileMenuItem({
    isSelected,
    onClick,
    path,
    target,
    title,
    highlightSubroutes,
  });

  return (
    <ItemComponent
      className={clsx(
        styles.container,
        !hideSelectedState && isItemSelected && styles.containerSelected
      )}
      {...itemProps}
    >
      <div className={styles.caret} />
      {Icon && (
        <span className={styles.icon}>
          <Icon size="md" />
        </span>
      )}
      <Typography variant="mobileNavigationLink">{title}</Typography>
    </ItemComponent>
  );
};

export default memo(MobileMenuItem);
