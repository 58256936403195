import { useEffect, useMemo } from 'react';

import {
  useEditDrawerSpaceApi,
  useEditDrawerSpaceData,
} from '../../../contexts/EditDrawerSpaceContext';

const useEditDrawerSpaceContentBody = () => {
  const { bodyRef, items, selectedDrawerItemSlug, spaceProps } =
    useEditDrawerSpaceData();
  const { onSetBodyRef } = useEditDrawerSpaceApi();

  const BodyComponent = useMemo(
    () => items[selectedDrawerItemSlug].component,
    [items, selectedDrawerItemSlug]
  );

  useEffect(() => {
    if (bodyRef) {
      bodyRef.scrollTo(0, 0);
    }
  }, [selectedDrawerItemSlug]);

  return {
    BodyComponent,
    spaceProps,
    onSetBodyRef,
  };
};

export default useEditDrawerSpaceContentBody;
