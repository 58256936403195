import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0028: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 122 100"
		height={100}
		width={122}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="M92.08 64.05c.67.13 1.53.11 2.05.11 12.45 0 26.16-13.58 26.16-30.18V1.16s-12.45 4.32-26.16 4.32-26.16-4.32-26.16-4.32v13.25" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M90.94 45.82c6.36-3.31 15.44 3.32 15.44 3.32 0-6.77-7.59-16.47-23.14-10.83M100.12 19.09c.86 1.59 2.54 2.67 4.48 2.67s3.57-1.05 4.44-2.6" /><g stroke={color} ><circle vectorEffect="non-scaling-stroke" cx="44.37" cy="19.67" r="7.47" /><path vectorEffect="non-scaling-stroke" d="M34.61 70c-5.45 0-7.33-4.46-7.33-9.91V44.26c0-5.45 4.46-9.92 9.91-9.92h14.36c5.45 0 9.92 4.46 9.92 9.92v15.83c0 5.45-1.88 9.91-7.33 9.91M54.22 47.26v51.95M34.53 47.26v51.95" /><circle vectorEffect="non-scaling-stroke" cx="17.56" cy="26.17" r="6.92" /><path vectorEffect="non-scaling-stroke" d="M8.51 73.56c-5.05 0-6.79-4.14-6.79-9.19V49.7c0-5.05 4.13-9.19 9.19-9.19h10.48M17.56 73.96v25.25M8.43 52.48v46.73" /><circle vectorEffect="non-scaling-stroke" cx="71.19" cy="26.17" r="6.92" /><path vectorEffect="non-scaling-stroke" d="M80.24 73.56c5.05 0 6.79-4.14 6.79-9.19V49.7c0-5.05-4.13-9.19-9.19-9.19H67.36M71.19 73.96v25.25M80.32 52.48v46.73" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0028);
