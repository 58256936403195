import { mustMatchRegex, ValidationItemMustMatchRegex } from './mustMatchRegex';

const validURLRegex = new RegExp(
  '^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$', // validate fragment locator
  'i'
);

export const isValidURL = (
  isValueRequired: boolean
): ValidationItemMustMatchRegex =>
  mustMatchRegex(isValueRequired, validURLRegex, 'This is not a valid URL');
