import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0027: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 82 108"
		height={108}
		width={82}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M27.4 28.26v30.49S6.95 90.08 2.82 96.38c-3.88 5.94-.44 10.7 7.82 10.7h50.32c8.26 0 11.7-4.76 7.82-10.7-4.12-6.31-24.58-37.63-24.58-37.63V28.59M44.2 28.55l5.02-7.3h-26.9" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M54.45 8.13C51.6 4.85 47.57 1.55 42.61 1 34.58.11 24.36 6.56 26.62 16.71M51.25 12.17c1.99-3.23 5.55-5.38 9.62-5.38 6.24 0 11.3 5.06 11.3 11.3 0 2.61-.89 5.02-2.38 6.94l-.07.18c6.42 2.16 11.04 8.22 11.04 15.37 0 8.04-6.76 15.34-14.64 16.34" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M55.55 29.93c2.3-.78 4.94-.39 6.96 1.27a7.247 7.247 0 0 1 .96 10.2M57.89 49.66c5.45 1.17 8.91 6.54 7.74 11.98-.69 3.2-2.83 5.72-5.58 7.01" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M40.94 71.16c0 .65-.52 1.17-1.17 1.17s-1.17-.52-1.17-1.17.52-1.17 1.17-1.17 1.17.52 1.17 1.17M50.34 95.49c0 .65-.52 1.17-1.17 1.17S48 96.14 48 95.49s.52-1.17 1.17-1.17 1.17.52 1.17 1.17M30.44 91.74c0 .65-.52 1.17-1.17 1.17s-1.17-.52-1.17-1.17.52-1.17 1.17-1.17 1.17.52 1.17 1.17M20.31 96.82c0 .65-.52 1.17-1.17 1.17s-1.17-.52-1.17-1.17.52-1.17 1.17-1.17 1.17.52 1.17 1.17M24.53 78.25c3.55-.43 7.82-.13 10.97 1.9 8.83 5.7 21.71 0 21.71 0" />
    </g>
  </CardIcon>
);

export default memo(Fr0027);
