import { z } from 'zod';

import { ImageAssetModelSchema } from '../asset';
import {
  KittedSaveModelBaseSchema,
  KittedVersionedModelBaseSchema,
  makeKittedModelReferenceSchema,
} from '../base';
import { ContentBlockTheme, ContentBlockType } from './Enum';

export const ContentBlockVideoLoadedModelSchema =
  KittedVersionedModelBaseSchema.merge(
    z.object({
      theme: z.nativeEnum(ContentBlockTheme),
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        true
      ).optional(),
      videoUrl: z.string().url(),
      isFullbleed: z.boolean(),
      caption: z.string().optional(),
      type: z.literal(ContentBlockType.Video),
    })
  );

export const ContentBlockVideoUnloadedModelSchema =
  ContentBlockVideoLoadedModelSchema.merge(
    z.object({
      keyImageImageAsset: makeKittedModelReferenceSchema(
        ImageAssetModelSchema,
        false
      ).optional(),
    })
  );

export const ContentBlockVideoSaveModelSchema =
  ContentBlockVideoUnloadedModelSchema.merge(KittedSaveModelBaseSchema).merge(
    z.object({
      isFullbleed: z.boolean().optional(),
      keyImageBlobId: z.string().optional().nullable(),
    })
  );

export type ContentBlockVideoLoadedModel = z.infer<
  typeof ContentBlockVideoLoadedModelSchema
>;
export type ContentBlockVideoUnloadedModel = z.infer<
  typeof ContentBlockVideoUnloadedModelSchema
>;
export type ContentBlockVideoSaveModel = z.infer<
  typeof ContentBlockVideoSaveModelSchema
>;

export type ContentBlockVideoModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? ContentBlockVideoLoadedModel
    : ContentBlockVideoUnloadedModel;
