import { memo } from 'react';
import clsx from 'clsx';
import { Stack } from '@kitted/shared-components';

import useComponentId from '../../../hooks/useComponentId';
import InputWrapper from '../InputWrapper';
import { InputComponentProps } from '../types';
import useInputRadio from './hooks/useInputRadio';
import useStyles from './styles';

const InputRadioGroup = ({
  className,
  disabled,
  errors,
  onChange,
  options,
  meta,
  name,
  value,
  theme,
  variant,
}: InputComponentProps) => {
  const styles = useStyles();
  const componentId = useComponentId();
  const { selectedItem, onSelectItem } = useInputRadio(
    options,
    value,
    onChange
  );
  return (
    <InputWrapper
      className={className}
      errors={errors}
      isFocused={false}
      hasValue
      helperText={meta?.helperText}
      variant="naked"
    >
      <Stack wrap="wrap" direction="row" spacing="2xs">
        {options?.map((option, i) => (
          <InputWrapper
            key={option.title}
            className={styles.radioContainer}
            hasValue
            iconLeft={option.icon}
            isDisabled={disabled}
            isFocused={selectedItem?.value === option.value}
            theme={theme}
            variant={variant}
          >
            <input
              type="radio"
              className={styles.radio}
              checked={selectedItem?.value === option.value}
              disabled={disabled}
              id={`${i}-${componentId}`}
              name={name}
              onChange={onSelectItem(option)}
              value={option.value}
            />
            <label
              className={clsx(
                styles.label,
                !!option.icon && styles.labelWithIcon,
                selectedItem?.value === option.value && styles.labelSelected
              )}
              htmlFor={`${i}-${componentId}`}
            >
              {!option.icon && <div className={styles.radioIcon} />}
              {option.title}
            </label>
          </InputWrapper>
        ))}
      </Stack>
    </InputWrapper>
  );
};

export default memo(InputRadioGroup);
