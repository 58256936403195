export const getHrefToFromPath = (path?: string) => {
  if (path?.indexOf('/') === 0) {
    return {
      to: path,
    };
  }
  return {
    href: path,
  };
};
