import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Linkedin: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M24.75 1.38H3.24c-1.02-.01-1.85.8-1.86 1.82v21.6c.01 1.02.85 1.83 1.86 1.82h21.51c1.02.01 1.86-.8 1.87-1.82V3.2a1.844 1.844 0 0 0-1.87-1.82M8.86 22.89H5.12V10.85h3.74zM6.99 9.2c-1.2 0-2.17-.97-2.17-2.17s.97-2.17 2.17-2.17 2.17.97 2.17 2.17S8.19 9.2 6.99 9.2m15.89 13.69h-3.74v-5.86c0-1.4-.02-3.19-1.95-3.19s-2.25 1.52-2.25 3.09v5.96H11.2V10.85h3.59v1.65h.05c.73-1.25 2.09-2 3.54-1.95 3.79 0 4.49 2.49 4.49 5.74v6.61Z" />
    </g>
  </WebsiteIcon>
);

export default memo(Linkedin);
