import { InteractionProps } from '@kitted/shared-components';

import { MobileMenuItemProps } from '../../types';

export const calculateInteractionProps = (
  isSelected: boolean,
  onClick: MobileMenuItemProps['onClick'],
  path: MobileMenuItemProps['path'],
  target: MobileMenuItemProps['target']
): InteractionProps => {
  if (isSelected) {
    return {
      nonInteractive: true,
    };
  }
  if (onClick) {
    return {
      onClick,
    };
  }
  if (target) {
    return {
      href: path!,
    };
  }
  return {
    to: path!,
  };
};
