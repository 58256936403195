import { constructPublicRoute } from '../helpers';
import { subjectTitles } from '../routeTitles';

const subjectTitleCodeBasePath = '/discovery/:titleSlug/:code';

export const subjectRedirectRoutes = {
  subjectCode: constructPublicRoute(
    '/discovery/:code',
    'Subject',
    subjectTitleCodeBasePath
  ),
} as const;

export const subjectRoutes = {
  ...subjectRedirectRoutes,
  subjectTitleCode: constructPublicRoute(subjectTitleCodeBasePath, [
    subjectTitles,
    'Subject',
  ]),
} as const;
