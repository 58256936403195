import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0043: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 136 136"
		height={136}
		width={136}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <circle vectorEffect="non-scaling-stroke" cx="68" cy="68" r="11.27" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="68" cy="68" r="37.4" stroke={color} /><circle vectorEffect="non-scaling-stroke" cx="68" cy="68" r="66.31" stroke={color} />
    </g>
  </CardIcon>
);

export default memo(In0043);
