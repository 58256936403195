import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    inputWrapper: {
      background: styling.colors.theme.primary900,
      '& > div:last-of-type': {
        fontSize: '1.8rem',
      },
    },
    selectOption: {
      height: 60,
    },
  },
  { name: 'subjectFilterDropdown' }
);
