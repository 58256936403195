import { useCallback, useEffect, useMemo, useState } from 'react';
import { styling } from '@kitted/design-system';
import { Nullable } from '@kitted/platform-models';

import useMediaQuery from '../../../../../../contexts/MediaQueryManagementContext/hooks/useMediaQuery';
import { DrawerSpaceDrawerSide } from '../../types';
import useResizeObserver from '../useResizeObserver';
import { handleToggleOpen } from './logic';

const useDrawerSpaceContainerManagement = () => {
  const [drawerSide, setDrawerSide] = useState<Nullable<DrawerSpaceDrawerSide>>(
    DrawerSpaceDrawerSide.Main
  );
  const [portalRoot, setPortalRoot] = useState<Nullable<HTMLDivElement>>(null);
  const [contentRoot, onSetContentRoot] =
    useState<Nullable<HTMLDivElement>>(null);
  const { onSetRef, width } = useResizeObserver('width');
  const isWindowDesktop = useMediaQuery('isDesktop');

  const isXs = useMemo(
    () => styling.breakpoints.theme.xs <= (width || 0),
    [width]
  );
  const isSm = useMemo(
    () => styling.breakpoints.theme.sm <= (width || 0),
    [width]
  );
  const isMd = useMemo(
    () => styling.breakpoints.theme.md <= (width || 0),
    [width]
  );
  const isLg = useMemo(
    () => styling.breakpoints.theme.lg <= (width || 0),
    [width]
  );
  const isXl = useMemo(
    () => styling.breakpoints.theme.xl <= (width || 0),
    [width]
  );
  const isTablet = useMemo(
    () => styling.breakpoints.theme.tablet <= (width || 0),
    [width]
  );
  const isDesktop = useMemo(
    () => styling.breakpoints.theme.desktop <= (width || 0),
    [width]
  );

  const onSetPortalRootRef = useCallback(
    (newEl: Nullable<HTMLDivElement>) => {
      setPortalRoot(newEl);
    },
    [setPortalRoot]
  );

  const onToggleOpen = useCallback(
    (newDrawerSide: DrawerSpaceDrawerSide) => () => {
      handleToggleOpen(setDrawerSide, newDrawerSide);
    },
    [setDrawerSide]
  );

  const onClose = useCallback(() => {
    setDrawerSide(null);
  }, [setDrawerSide]);

  const onSwitchToBelowDesktop = useCallback(() => {
    if (!isWindowDesktop) {
      setDrawerSide(null);
    }
  }, [isWindowDesktop, setDrawerSide]);

  useEffect(onSwitchToBelowDesktop, [onSwitchToBelowDesktop]);

  return {
    onSetPortalRootRef,
    onSetRef,
    portalRoot,
    onSetContentRoot,
    contentRoot,
    onToggleOpen,
    onClose,
    isMainOpen: drawerSide === DrawerSpaceDrawerSide.Main,
    isContextualOpen: drawerSide === DrawerSpaceDrawerSide.Contextual,
    isXs,
    isSm,
    isMd,
    isLg,
    isXl,
    isTablet,
    isDesktop,
  };
};

export default useDrawerSpaceContainerManagement;
