import { useCallback, useState } from 'react';

import useBindState from '../../../../hooks/useBindState';
import { ToastContextToast, ToastData, ToastEvent } from '../../types';
import {
  getDeregisterToast,
  getDeregisterToastCallback,
  getRegisterToast,
  getRegisterToastCallback,
  getUpdateToast,
} from './logic';

const useToastManagement = () => {
  const [toasts, setToasts] = useState<ToastData>({
    toasts: [],
    eventListeners: [],
  });

  const registerToastCallback = useBindState(
    setToasts,
    getRegisterToastCallback
  );

  const deregisterToastCallback = useBindState(
    setToasts,
    getDeregisterToastCallback
  );

  const registerToast = useBindState(setToasts, getRegisterToast);
  const deregisterToast = useBindState(setToasts, getDeregisterToast);

  const updateToast = useBindState(setToasts, getUpdateToast);

  const publishToastEvent = useCallback(
    (toastId: ToastContextToast['key'], event: ToastEvent) => {
      toasts.eventListeners
        .filter((el) => el.toastId === toastId && el.event === event)
        .forEach((el) => {
          el.callback();
        });
    },
    [toasts.eventListeners]
  );

  return {
    registerToast,
    deregisterToast,
    updateToast,
    registerToastCallback,
    deregisterToastCallback,
    publishToastEvent,
    toasts,
  };
};

export default useToastManagement;
