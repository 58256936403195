import { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EMPTY_OBJ } from '@kitted/shared-utils';

import useModalApi from '../../../../contexts/ModalContext/hooks/useModalApi';
import useModalData from '../../../../contexts/ModalContext/hooks/useModalData';

const useModalCloseOnLocationChange = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { modals } = useModalData();
  const { deregisterModal } = useModalApi();
  const lastLocationRef = useRef<string>(location.pathname);

  const closeAllModals = useCallback(() => {
    modals.forEach((modal) => {
      deregisterModal(modal.key);
    });
  }, [modals, deregisterModal]);

  useEffect(() => {
    if (location.pathname !== lastLocationRef.current) {
      if (!location.state?.persistModals) {
        closeAllModals();
      } else {
        const { persistModals: _deleted, ...state } =
          location.state || EMPTY_OBJ;
        navigate(location.pathname, { replace: true, state });
      }
      lastLocationRef.current = location.pathname;
    }
  }, [location, closeAllModals, navigate]);
};

export default useModalCloseOnLocationChange;
