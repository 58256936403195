import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const SquaresTwoByTwoPlus: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M9.15 1.29H2.61c-.73 0-1.32.59-1.32 1.32v6.54c0 .73.59 1.32 1.32 1.32h6.54c.73 0 1.32-.59 1.32-1.32V2.61c0-.73-.59-1.32-1.32-1.32M9.15 13.53H2.61c-.73 0-1.32.59-1.32 1.32v6.54c0 .73.59 1.32 1.32 1.32h6.54c.73 0 1.32-.59 1.32-1.32v-6.54c0-.73-.59-1.32-1.32-1.32M21.39 1.29h-6.54c-.73 0-1.32.59-1.32 1.32v6.54c0 .73.59 1.32 1.32 1.32h6.54c.73 0 1.32-.59 1.32-1.32V2.61c0-.73-.59-1.32-1.32-1.32M18.12 13.53v9.18m-4.59-4.59h9.18" />
    </g>
  </WebsiteIcon>
);

export default memo(SquaresTwoByTwoPlus);
