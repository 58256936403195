import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0018: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 72 104"
		height={104}
		width={72}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="M12.16 10.43h11.22v11.22H12.16zM30.39 10.43h11.22v11.22H30.39z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M1.67 1.74h68.67v28.62H1.67z" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M48.62 10.43h11.22v11.22H48.62z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M12.16 46.39h11.22v11.22H12.16zM30.39 46.39h11.22v11.22H30.39z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M1.67 37.69h68.67v28.62H1.67z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M48.62 46.39h11.22v11.22H48.62z" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M12.16 82.34h11.22v11.22H12.16zM30.39 82.34h11.22v11.22H30.39z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M1.67 73.65h68.67v28.62H1.67z" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M48.62 82.34h11.22v11.22H48.62z" />
    </g>
  </CardIcon>
);

export default memo(In0018);
