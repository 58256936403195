import { convertFlowModelToFlowCardModel } from '@kitted/card-service-shared';
import {
  AnyFlowBlockModel,
  AnyFlowBlockSaveModel,
  FlowMetadataModel,
  FlowModel,
  FlowSaveModel,
  FlowStepModel,
  FlowStepSaveModel,
} from '@kitted/flow-service-models';
import {
  GenericHttpCompleteResponse,
  KittedServiceEnum,
} from '@kitted/kitted-models';
import { Nullable } from '@kitted/platform-models';

import useRequests from '../../../contexts/RequestsContext/hooks/useRequests';
import useStoreSection from '../../../contexts/StoreContext/hooks/useStoreSection';
import { METADATA_KEY_SEPARATOR } from '../../metadata/useMetadata/constants';
import useAsyncRequest from '../../useAsyncRequest';
import useAuthedAsyncRequest from '../../useAuthedAsyncRequest';

const useFlowRequests = () => {
  const { updateStoreSectionItem } = useStoreSection('Cards');
  const { updateStoreSectionItem: updateMetadataStoreSectionItem } =
    useStoreSection('Metadata');
  const { platformRequest } = useRequests();

  const [getFlowLatest, flowLatestFetchState] = useAuthedAsyncRequest<
    FlowModel<false>
  >((id: FlowModel['id']) =>
    platformRequest(KittedServiceEnum.Flow, {
      method: 'GET',
      url: `/v1/flow/${id}/latest`,
    })
  );

  const [getFlowBlocksLatest, flowBlocksLatestFetchState] =
    useAuthedAsyncRequest<
      (AnyFlowBlockModel<false> | AnyFlowBlockModel<true>)[]
    >((id: FlowModel['id']) =>
      platformRequest(KittedServiceEnum.Flow, {
        method: 'GET',
        url: `/v1/flow/${id}/latest/blocks`,
      })
    );

  const [getFlowStepsLatest, flowStepsLatestFetchState] = useAuthedAsyncRequest<
    FlowStepModel[]
  >((id: FlowModel['id']) =>
    platformRequest(KittedServiceEnum.Flow, {
      method: 'GET',
      url: `/v1/flow/${id}/latest/steps`,
    })
  );

  const [getFlowLatestBySlug, flowLatestBySlugFetchState] =
    useAuthedAsyncRequest<FlowModel<false>>((slug: FlowModel['slug']) =>
      platformRequest(KittedServiceEnum.Flow, {
        method: 'GET',
        url: `/v1/flow/slug/${slug}/latest`,
      })
    );

  const [getFlowBySlug, flowBySlugFetchState] = useAsyncRequest<
    FlowModel<false>
  >((slug: FlowModel['slug']) =>
    platformRequest(KittedServiceEnum.Flow, {
      method: 'GET',
      url: `/v1/flow/slug/${slug}/published`,
    })
  );

  const [getFlow, flowFetchState] = useAsyncRequest<FlowModel<false>>(
    (id: FlowModel['id']) =>
      platformRequest(KittedServiceEnum.Flow, {
        method: 'GET',
        url: `/v1/flow/${id}/published`,
      })
  );

  const [getFlowBlocks, flowBlocksFetchState] = useAsyncRequest<
    (AnyFlowBlockModel<false> | AnyFlowBlockModel<true>)[]
  >((id: FlowModel['id']) =>
    platformRequest(KittedServiceEnum.Flow, {
      method: 'GET',
      url: `/v1/flow/${id}/published/blocks`,
    })
  );

  const [getFlowSteps, flowStepsFetchState] = useAsyncRequest<FlowStepModel[]>(
    (id: FlowModel['id']) =>
      platformRequest(KittedServiceEnum.Flow, {
        method: 'GET',
        url: `/v1/flow/${id}/published/steps`,
      })
  );

  const [cloneFlow, flowCloneState] = useAuthedAsyncRequest<FlowModel>(
    (data: FlowSaveModel) =>
      platformRequest(KittedServiceEnum.Flow, {
        method: 'POST',
        data,
        url: `/v1/flow/clone`,
      }),
    (response: FlowModel | undefined) => {
      if (response) {
        updateStoreSectionItem(
          convertFlowModelToFlowCardModel(response.id, response),
          response.id
        );
      }
    }
  );

  const [createFlow, flowCreateState] = useAuthedAsyncRequest<FlowModel>(
    (data: FlowSaveModel) =>
      platformRequest(KittedServiceEnum.Flow, {
        method: 'POST',
        data,
        url: `/v1/flow/create`,
      }),
    (response: FlowModel | undefined) => {
      if (response) {
        updateStoreSectionItem(
          convertFlowModelToFlowCardModel(response.id, response),
          response.id
        );
      }
    }
  );

  const [createFlowDraft, flowDraftCreateState] =
    useAuthedAsyncRequest<FlowModel>(
      (id: FlowModel['id'], data: FlowSaveModel) =>
        platformRequest(KittedServiceEnum.Flow, {
          method: 'POST',
          data,
          url: `/v1/flow/${id}/draft`,
        })
    );

  const [createFlowAutosave, flowAutosaveCreateState] =
    useAuthedAsyncRequest<FlowModel>((data: FlowSaveModel) =>
      platformRequest(KittedServiceEnum.Flow, {
        method: 'POST',
        data,
        url: `/v1/flow/autosave`,
      })
    );

  const [createFlowBlockAutosave, flowBlockAutosaveCreateState] =
    useAuthedAsyncRequest<AnyFlowBlockModel>(
      (id: FlowModel['id'], data: AnyFlowBlockSaveModel) =>
        platformRequest(KittedServiceEnum.Flow, {
          method: 'POST',
          data,
          url: `/v1/block/flow/${id}/autosave`,
        })
    );

  const [createFlowStepAutosave, flowStepAutosaveCreateState] =
    useAuthedAsyncRequest<FlowStepModel>(
      (id: FlowModel['id'], data: FlowStepSaveModel) =>
        platformRequest(KittedServiceEnum.Flow, {
          method: 'POST',
          data,
          url: `/v1/step/flow/${id}/autosave`,
        })
    );

  const [publishFlow, flowPublishState] = useAuthedAsyncRequest<FlowModel>(
    (id: FlowModel['id']) =>
      platformRequest(KittedServiceEnum.Flow, {
        method: 'PUT',
        url: `/v1/flow/${id}/publish`,
      })
  );

  const [createPublishFlow, flowCreatePublishState] =
    useAuthedAsyncRequest<FlowModel>(
      (data: FlowSaveModel) =>
        platformRequest(KittedServiceEnum.Flow, {
          method: 'POST',
          data,
          url: '/v1/flow/createPublish',
        }),
      (response: FlowModel | undefined) => {
        if (response) {
          updateStoreSectionItem(
            convertFlowModelToFlowCardModel(response.id, response),
            response.id
          );
        }
      }
    );

  const [getFlowMetadata, getFlowMetadataState] = useAsyncRequest<
    Nullable<FlowMetadataModel>
  >((flowId: FlowModel['id']) =>
    platformRequest(KittedServiceEnum.Flow, {
      method: 'GET',
      url: `/v1/flow/${flowId}/metadata`,
    })
  );

  const [setFlowMetadata, setFlowMetadataState] =
    useAuthedAsyncRequest<GenericHttpCompleteResponse>(
      (flowId: FlowModel['id'], data: FlowMetadataModel) =>
        platformRequest(KittedServiceEnum.Flow, {
          method: 'POST',
          data,
          url: `/v1/flow/${flowId}/metadata`,
        }),
      (
        response: GenericHttpCompleteResponse | undefined,
        flowId: FlowModel['id'],
        data: FlowMetadataModel
      ) => {
        if (response?.done) {
          updateMetadataStoreSectionItem(
            data,
            `${KittedServiceEnum.Flow}${METADATA_KEY_SEPARATOR}${flowId}`
          );
        }
      }
    );

  return {
    getFlowLatest,
    flowLatestFetchState,
    getFlowBlocksLatest,
    flowBlocksLatestFetchState,
    getFlowLatestBySlug,
    flowLatestBySlugFetchState,
    getFlowBySlug,
    flowBySlugFetchState,
    getFlow,
    flowFetchState,
    getFlowBlocks,
    flowBlocksFetchState,
    cloneFlow,
    flowCloneState,
    createFlow,
    flowCreateState,
    createFlowDraft,
    flowDraftCreateState,
    createFlowAutosave,
    flowAutosaveCreateState,
    createFlowBlockAutosave,
    flowBlockAutosaveCreateState,
    publishFlow,
    flowPublishState,
    getFlowStepsLatest,
    flowStepsLatestFetchState,
    getFlowSteps,
    flowStepsFetchState,
    createFlowStepAutosave,
    flowStepAutosaveCreateState,
    createPublishFlow,
    flowCreatePublishState,
    getFlowMetadata,
    getFlowMetadataState,
    setFlowMetadata,
    setFlowMetadataState,
  };
};

export default useFlowRequests;
