import { memo } from 'react';
import clsx from 'clsx';
import { Fade } from '@kitted/shared-components';

import DrawerSpacePortal from '../../../components/DrawerSpace/DrawerSpacePortal';
import FixedBottom from '../../../components/FixedBottom';
import useFixedMobileMenu from './hooks/useFixedMobileMenu';
import CreateMenuContent from './CreateMenuContent';
import MobileMenuContent from './MobileMenuContent';
import MobileMenuItems from './MobileMenuItems';
import useStyles from './styles';

const FixedMobileMenu = () => {
  const {
    onExited: onMenuExited,
    isClosing: isMenuClosing,
    isOpen: isMenuOpen,
    onClose: onMenuClose,
    onToggleOpen: onToggleMenuOpen,
  } = useFixedMobileMenu();
  const {
    onExited: onCreateExited,
    isClosing: isCreateClosing,
    isOpen: isCreateOpen,
    onClose: onCreateClose,
    onToggleOpen: onToggleCreateOpen,
  } = useFixedMobileMenu();
  const styles = useStyles();

  return (
    <>
      <DrawerSpacePortal>
        <FixedBottom
          as="nav"
          className={clsx(
            styles.container,
            (isCreateOpen || isMenuOpen) && styles.containerOpen
          )}
        >
          <MobileMenuItems
            isMenuOpen={isMenuOpen}
            onToggleMenuOpen={onToggleMenuOpen}
            onMenuClose={onMenuClose}
            isCreateOpen={isCreateOpen}
            onToggleCreateOpen={onToggleCreateOpen}
            onCreateClose={onCreateClose}
          />
        </FixedBottom>
      </DrawerSpacePortal>
      <DrawerSpacePortal>
        <FixedBottom
          className={clsx(
            (isMenuOpen || isMenuClosing || isCreateOpen || isCreateClosing) &&
              styles.menuContainer
          )}
        >
          <Fade
            className={styles.menuInner}
            durationIn={500}
            durationOut={500}
            in={isMenuOpen}
            onExited={onMenuExited}
          >
            <MobileMenuContent onToggleOpen={onToggleMenuOpen} />
          </Fade>
          <Fade
            className={styles.popover}
            durationIn={500}
            durationOut={500}
            in={isCreateOpen}
            onExited={onCreateExited}
          >
            <CreateMenuContent onToggleOpen={onToggleCreateOpen} />
          </Fade>
        </FixedBottom>
      </DrawerSpacePortal>
      <div className={styles.fixedMobileMenuSpacer} />
    </>
  );
};

export default memo(FixedMobileMenu);
