import { PlayCardModel, PublishType } from '@kitted/card-service-models';
import {
  CardType,
  KittedModelTypeEnum,
  KittedVersionStatusTypeEnum,
} from '@kitted/kitted-models';
import { PlayModel } from '@kitted/play-service-models';

export const convertPlayModelToPlayCardModel = (
  id: PlayModel['id'],
  play: Omit<PlayModel, 'id'>,
  oldCard: Partial<PlayCardModel> = {}
): PlayCardModel => ({
  ...oldCard,

  id,
  slug: play.slug,

  kittedModelType: KittedModelTypeEnum.CardPlay,

  cardType: CardType.Play,
  createdAt: oldCard?.createdAt || play.createdAt,
  updatedAt: play.updatedAt,
  title: play.title,
  description: play.description,
  keyImageImageAsset: play.keyImageImageAsset,
  createdByAuthorId: play.createdByAuthorId,

  excludeFromDiscovery:
    play.cmsSrcMessage?.excludeFromDiscovery ??
    oldCard?.excludeFromDiscovery ??
    true,
  live: play.cmsSrcMessage?.live ?? oldCard?.live ?? true,

  publishSettings: {
    publishType: PublishType.Private,
  },
  versionStatus: play.versionStatus,
  publishedAt:
    play.versionStatus === KittedVersionStatusTypeEnum.Published
      ? play.updatedAt
      : oldCard.publishedAt!,
});
