import createKeyValueStore from '../../keyValueStore';
import { networkRequest } from '../adhoc';
import { constructIdempotencyKey } from '../logic';
import { RequestConfig, RequestPerformer } from '../types';
import { CancelFn } from './types';

export const cancelableRequestFactory = (): RequestPerformer => {
  const inflightRequestsCache = createKeyValueStore();

  const register = (idempotencyKey: string) => (cancelFn: CancelFn) => {
    inflightRequestsCache.setValueForKey(idempotencyKey, cancelFn);
  };

  const deregister = (idempotencyKey: string) => {
    inflightRequestsCache.deleteValueForKey(idempotencyKey);
  };

  const cancel = (idempotencyKey: string) => {
    const cancelFn = inflightRequestsCache.getValueForKey(idempotencyKey) as
      | CancelFn
      | undefined;
    cancelFn?.();
  };

  const cancelableRequest = async <ResponseType>(
    requestConfig: RequestConfig
  ) => {
    const idempotencyKey = constructIdempotencyKey(requestConfig);

    // cancel previous requests for the same key
    cancel(idempotencyKey);

    try {
      const response = await networkRequest<ResponseType>(
        requestConfig,
        register(idempotencyKey)
      );

      deregister(idempotencyKey);

      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      deregister(idempotencyKey);

      throw error;
    }
  };

  return cancelableRequest;
};
