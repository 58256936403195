import { CatalogueItemModel } from '@kitted/catalogue-service-models';

import { TextSearchSchema } from '../../../../hooks/useTextSearch/types';

export const catalogueItemTextSearchSchema: TextSearchSchema<CatalogueItemModel> =
  {
    fields: ['tags', 'name', 'description'], // Assuming you want to index the array of tags
    idField: 'id', // Unique identifier for each item

    // Flattern the tags field for search
    extractField: (document, fieldName) => {
      const fieldValue = document[fieldName];

      // Handle the tags array specifically
      if (Array.isArray(fieldValue)) {
        return fieldValue.join(' ');
      }

      return String(fieldValue);
    },
  };
