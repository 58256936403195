import { createContext } from 'react';
import { EMPTY_PROMISE, NOOP } from '@kitted/shared-utils';

import { LoginState, UserApi, UserData } from './types';

export const UserContext = createContext<UserApi>({
  setLoginState: NOOP,
  logoutUser: NOOP,
  registerUser: EMPTY_PROMISE,
  loginUser: EMPTY_PROMISE,
  verifyEmail: EMPTY_PROMISE,
  resendVerifyEmail: EMPTY_PROMISE,
  forgotPassword: EMPTY_PROMISE,
  confirmForgotPassword: EMPTY_PROMISE,
  submitChallengeResponse: EMPTY_PROMISE,
});

export const UserDataContext = createContext<UserData>({
  loginState: LoginState.OUT,
});
