import { useCallback, useState } from 'react';
import { EMPTY_SET } from '@kitted/shared-utils';

import { getNonRedirectRouteKeyForPath } from './logic';

const useRoutePreloadManagement = () => {
  const [preloadRouteKeys, setPreloadRouteKeys] =
    useState<Set<string>>(EMPTY_SET);

  const preloadPath = useCallback((pathname: string) => {
    const routeKey = getNonRedirectRouteKeyForPath(pathname);
    if (routeKey) {
      setPreloadRouteKeys((previousRouteKeys) => {
        if (!previousRouteKeys.has(routeKey)) {
          return previousRouteKeys.add(routeKey);
        }
        return previousRouteKeys;
      });
    }
  }, []);

  return {
    preloadPath,
    preloadRouteKeys,
  };
};

export default useRoutePreloadManagement;
