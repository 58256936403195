import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0015: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 106 102"
		height={102}
		width={106}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="M69.85 100.63H17.54c-8.95 0-16.28-7.33-16.28-16.28v-1.59c0-8.95 7.33-16.28 16.28-16.28h14.45c5.12 0 9.3-4.19 9.3-9.31v-3.22c0-5.12-4.19-9.3-9.3-9.3h-6.42" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M104.74 46.51c0 13.43-24.32 54.39-24.32 54.39S56.1 59.94 56.1 46.51s10.89-24.32 24.32-24.32 24.32 10.89 24.32 24.32" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M90.8 46.51c0 5.73-4.65 10.39-10.39 10.39s-10.38-4.66-10.38-10.39 4.65-10.39 10.38-10.39S90.8 40.77 90.8 46.51M29.07 14.54c0 7.43-13.45 30.07-13.45 30.07S2.17 21.97 2.17 14.54 8.19 1.09 15.62 1.09s13.45 6.02 13.45 13.45" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M17.17 13.66c0 .86-.69 1.55-1.55 1.55s-1.55-.69-1.55-1.55.7-1.55 1.55-1.55 1.55.69 1.55 1.55" />
    </g>
  </CardIcon>
);

export default memo(Ac0015);
