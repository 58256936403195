import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const DocumentsPlus: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 26 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M13.43 26.62H5.36c-.48 0-.88-.39-.88-.88V5.55c0-.48.39-.88.88-.88h13.23c.48 0 .88.39.88.88v7.35" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M1.93 23.33c-.48 0-.88-.39-.88-.88V2.26c0-.48.39-.88.88-.88h13.23c.48 0 .88.39.88.88M17.27 21.13h4.39M19.47 18.94v4.39" /><circle vectorEffect="non-scaling-stroke" cx="19.47" cy="21.13" r="5.49" stroke={color} />
    </g>
  </WebsiteIcon>
);

export default memo(DocumentsPlus);
