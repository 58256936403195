import {
  UserChallengeResponseRequest,
  UserChallengeResponseResponse,
  UserConfirmForgotPasswordRequest,
  UserConfirmForgotPasswordResponse,
  UserForgotPasswordRequest,
  UserLoginRequest,
  UserLoginResponse,
  UserRegisterRequest,
  UserRegisterResponse,
  UserVerifyEmailRequest,
  UserVerifyEmailResendResponse,
} from '@kitted/auth-service-models';

export type UserContextProps = {
  children: React.ReactNode;
};

export enum LoginState {
  OUT = 'out',
  LOADING = 'loading',
  IN = 'in',
}

export type UserApi = {
  setLoginState: (newState: LoginState) => void;
  logoutUser: () => void;
  registerUser: (
    registerPayload: UserRegisterRequest
  ) => Promise<UserRegisterResponse | undefined>;
  loginUser: (
    loginPayload: UserLoginRequest
  ) => Promise<UserLoginResponse | undefined>;
  verifyEmail: (verifyEmailPayload: UserVerifyEmailRequest) => Promise<void>;
  resendVerifyEmail: () => Promise<UserVerifyEmailResendResponse | undefined>;
  forgotPassword: (
    forgotPasswordPayload: UserForgotPasswordRequest
  ) => Promise<void>;
  confirmForgotPassword: (
    confirmForgotPasswordPayload: UserConfirmForgotPasswordRequest
  ) => Promise<UserConfirmForgotPasswordResponse | undefined>;
  submitChallengeResponse: (
    challengeResponsePayload: UserChallengeResponseRequest
  ) => Promise<UserChallengeResponseResponse | undefined>;
};

export type UserData = {
  loginState: LoginState;
};
