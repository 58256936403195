import { useCallback } from 'react';

import { handleGetInteractionProps } from '../../logic';
import { ModalButton, ModalButtonsProps } from '../../types';

const useModalButtons = (onClose: ModalButtonsProps['onClose']) => {
  const getInteractionProps = useCallback(
    (props: Omit<ModalButton, 'title'>) =>
      handleGetInteractionProps(props, onClose),
    [onClose]
  );

  return {
    getInteractionProps,
  };
};

export default useModalButtons;
