import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const TrayArrowUp: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 28 24"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M26.24 16.63v3.98c0 1.06-.86 1.92-1.92 1.92H3.68a1.92 1.92 0 0 1-1.92-1.92v-3.98M14.01 1.71v15.75M7.2 8.47l6.81-6.79 6.79 6.79" />
    </g>
  </WebsiteIcon>
);

export default memo(TrayArrowUp);
