import { useCallback, useEffect } from 'react';

import usePageRequests from '../../../../../../hooks/pages/usePageRequests';
import useStoreApi from '../../../../hooks/useStoreApi';
import { StoreSectionInitializerProps } from '../../../types';

const usePagesStoreInitializer = (
  onSectionLoaded: StoreSectionInitializerProps['onSectionLoaded']
) => {
  const { getPageBySlug } = usePageRequests();
  const { createOrUpdateStoreSection } = useStoreApi();

  const updatePagesStoreSectionFetcher = useCallback(() => {
    createOrUpdateStoreSection('Pages', getPageBySlug);
    onSectionLoaded('Pages');
  }, [createOrUpdateStoreSection, getPageBySlug, onSectionLoaded]);

  useEffect(updatePagesStoreSectionFetcher, [updatePagesStoreSectionFetcher]);
};

export default usePagesStoreInitializer;
