import { createContext } from 'react';
import { NOOP } from '@kitted/shared-utils';

import { AnalyticsApi } from './types';

export const AnalyticsContext = createContext<AnalyticsApi>({
  trackEvent: NOOP,
  identifyUser: NOOP,
  getDistinctId: () => '',
});
