/* eslint-disable camelcase */
import { generateLinearPalette } from '../../services/linearPaletteGenerator';

const frameBaseColor = '#39D597'; // rgb(57, 213, 151)
const palette = generateLinearPalette(frameBaseColor);

export const frame050 = palette['050']; // #d0f5e6
export const frame100 = palette['100']; // #c0f2de
export const frame200 = palette['200']; // #abedd3
export const frame300 = palette['300']; // #90e7c4
export const frame400 = palette['400']; // #6adfb1
export const frame500 = palette['500']; // #39D597
export const frame500_30 = palette['500_30']; // #39D597
export const frame500_40 = palette['500_40']; // #39D597
export const frame600 = palette['600']; // #28bd82
export const frame700 = palette['700']; // #22a16e
export const frame800 = palette['800']; // #1d895e
export const frame900 = palette['900']; // #197450
