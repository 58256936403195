import { useMemo } from 'react';
import { AuthorModel } from '@kitted/author-service-models';
import { getFullName, getInitials } from '@kitted/shared-utils';

const useAuthorMeta = (author?: AuthorModel | undefined) => {
  const [initials, name] = useMemo(() => {
    const nameString = getFullName(author?.firstName, author?.lastName);
    return [getInitials(author?.firstName, author?.lastName), nameString];
  }, [author]);

  return {
    name,
    initials,
  };
};

export default useAuthorMeta;
