import { Dispatch, SetStateAction } from 'react';

import { Alert, AlertData } from '../../types';

export const getCloseAlert = (
  setTopAlert: Dispatch<SetStateAction<AlertData['topAlert']>>
) => {
  setTopAlert(undefined);
  window.document.scrollingElement?.classList.remove('lock-scroll-alert');
};

export const getDeregisterAlert = (
  setAlerts: Dispatch<SetStateAction<AlertData['alerts']>>,
  alertId: string
) => {
  setAlerts((alerts) => {
    const currentAlert = alerts.find((alert) => alert.key === alertId);
    currentAlert?.config?.onClose?.();
    return alerts.filter((alert) => alert.key !== alertId);
  });
};

export const getRegisterAlert = (
  setAlerts: Dispatch<SetStateAction<AlertData['alerts']>>,
  alertId: string,
  alertConfig: Alert
) => {
  setAlerts((alerts) => {
    if (alerts.find((alert) => alert.key === alertId)) {
      return alerts;
    }
    return [...alerts, { config: alertConfig, key: alertId }];
  });
};

export const handleAlertsChange = (
  alerts: AlertData['alerts'],
  setTopAlert: Dispatch<SetStateAction<AlertData['topAlert']>>
) => {
  if (alerts.length) {
    setTopAlert(alerts[alerts.length - 1].key);
    window.document.scrollingElement?.classList.add('lock-scroll-alert');
    return;
  }
  setTopAlert(undefined);
};
