import { roundToPrecision } from '@kitted/shared-utils';

import { SpinnerTheme } from './types';

export const themeToColorArrayMap: {
  [key in SpinnerTheme]: number[];
} = {
  secondary: [247, 226, 211].map((num) => roundToPrecision(num / 255, 2)),
  primary: [4, 3, 29].map((num) => roundToPrecision(num / 255, 2)),
  action: [244, 143, 98].map((num) => roundToPrecision(num / 255, 2)),
  frame: [57, 213, 151].map((num) => roundToPrecision(num / 255, 2)),
  snare: [133, 211, 234].map((num) => roundToPrecision(num / 255, 2)),
  insight: [181, 142, 239].map((num) => roundToPrecision(num / 255, 2)),
  danger: [163, 35, 67].map((num) => roundToPrecision(num / 255, 2)),
};
