import { useEffect, useRef } from 'react';

import createIntervalStore from '../../../../services/intervals';
import { IntervalService } from '../../../../services/intervals/types';
import { timerFrequenciesArray } from '../../constants';

const useTimerManagement = () => {
  const intervalStore = useRef<IntervalService>(
    createIntervalStore(timerFrequenciesArray)
  );

  useEffect(
    () => () => {
      intervalStore.current.removeIntervalStore();
    },
    []
  );

  return {
    addTimerCallback: intervalStore.current.addIntervalCallback,
  };
};

export default useTimerManagement;
