import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0055: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 132 122"
		height={122}
		width={132}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m120.87 50.47 9.41 3.79M54.36 23.68l9.4 3.78M104.37 67.29l3.97 9.33M76.29 1.31l3.96 9.33M80.81 67.52l-3.79 9.41M107.6 1.01l-3.78 9.4M63.99 51.02l-9.32 3.97M129.97 22.94l-9.33 3.97M110.67 43.65c-2.52 10.12-12.78 16.28-22.9 13.75-10.12-2.52-16.28-12.78-13.76-22.89 2.52-10.12 12.78-16.28 22.9-13.76 10.11 2.52 16.28 12.78 13.76 22.9" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M64.67 11.31c-9.87-1.61-22.41-.86-35.98 6.84 0 0-17.46 7.56-13.77 31.73 0 0 .75 6.76-1.39 9.92L1.97 73.39s-1.54 4.04 3.08 4.42l7.05 1.45s2.95 5.75 3.24 10.17c.3 4.42-.74 9.88-.74 9.88s-2.51 6.63 1.43 9.55c.77.19 3.66 3.47 24.25-.95 0 0 2.36 2.16 3.62 12.92M16.23 89.73l8.84.15M88.36 120.99c-.91-5.34-2.61-15.23-2.09-23.68 0 0 2.54-11.46 7.64-20.19" />
    </g>
  </CardIcon>
);

export default memo(Sn0055);
