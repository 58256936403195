import { Unsubscribe } from '../../../../../services/keyValueStore/types';
import logger from '../../../../../services/logger';
import {
  StoreSection,
  StoreSectionItem,
  StoreSectionItemStates,
  StoreSectionKey,
} from '../types';

const createStoreItemApi = ({
  getSection,
  getSectionItemFetcher,
}: {
  getSection: <StoreItem>(
    key: StoreSectionKey
  ) => StoreSection<StoreItem | undefined>['kvs'];
  getSectionItemFetcher: <StoreItem>(
    key: StoreSectionKey
  ) => StoreSection<StoreItem | undefined>['itemFetcher'];
}) => {
  const getDefaultStoreItem = () =>
    ({
      item: undefined,
      state: StoreSectionItemStates.Default,
    }) as StoreSectionItem<undefined>;

  const getStoreItemKeyWhere = <StoreItem>(
    sectionKey: StoreSectionKey,
    finderFn: (item: StoreSectionItem<StoreItem | undefined>) => boolean
  ) => {
    const section = getSection<StoreItem>(sectionKey);
    return section.getKeyWhere(finderFn);
  };

  const getStoreItemKeysWhere = <StoreItem>(
    sectionKey: StoreSectionKey,
    finderFn: (item: StoreSectionItem<StoreItem | undefined>) => boolean
  ) => {
    const section = getSection<StoreItem>(sectionKey);
    return section.getKeysWhere(finderFn);
  };

  const subscribeToItemChanges = <StoreItem>(
    sectionKey: StoreSectionKey,
    id: string,
    subscriptionCallback: (
      item: StoreSectionItem<StoreItem | undefined>,
      id: string
    ) => void
  ): Unsubscribe => {
    const section = getSection<StoreItem>(sectionKey);
    const alwaysPresentCallback = (
      item: StoreSectionItem<StoreItem | undefined> | undefined
    ) => {
      if (!item) {
        subscriptionCallback(getDefaultStoreItem(), id);
        return;
      }
      subscriptionCallback(item, id);
    };
    return section.subscribeToItem(id, alwaysPresentCallback);
  };

  const subscribeToKeysChanges = (
    key: StoreSectionKey,
    subscriptionCallback: (itemKeys: string[]) => void
  ): Unsubscribe => {
    const section = getSection(key);
    const alwaysPresentCallback = (itemKeys: string[]) => {
      subscriptionCallback(itemKeys);
    };
    return section.subscribeToKeys(alwaysPresentCallback);
  };

  const setStoreItemItem = <StoreItem>(
    sectionKey: StoreSectionKey,
    id: string,
    item: StoreItem | undefined
  ) => {
    const section = getSection<StoreItem>(sectionKey);
    section.setValueForKey(id, {
      state: StoreSectionItemStates.Ready,
      item,
    });
  };

  const mergeStoreItemItem = <StoreItem>(
    sectionKey: StoreSectionKey,
    id: string,
    item: Partial<StoreItem>
  ) => {
    const section = getSection<StoreItem>(sectionKey);
    const sectionItem = section.getValueForKey(id);
    if (sectionItem?.item) {
      section.setValueForKey(id, {
        state: StoreSectionItemStates.Ready,
        item: {
          ...sectionItem.item,
          ...item,
        },
      });
    } else {
      throw new Error(
        `[StoreItem] You cannot update an item that does not exist yet: ${sectionKey} -> ${id}`
      );
    }
  };

  const setStoreItemState = (
    sectionKey: StoreSectionKey,
    id: string,
    state: StoreSectionItemStates
  ) => {
    const section = getSection(sectionKey);
    const sectionItem = section.getValueForKey(id);
    section.setValueForKey(id, {
      item: sectionItem?.item,
      state,
    });
  };

  const deleteStoreItem = (sectionKey: StoreSectionKey, id: string) => {
    const section = getSection(sectionKey);
    section.deleteValueForKey(id);
  };

  const getStoreItem = <StoreItem>(
    sectionKey: StoreSectionKey,
    id: string | undefined
  ): StoreSectionItem<StoreItem | undefined> => {
    const defaultItem = getDefaultStoreItem();
    if (!id) return defaultItem;
    const section = getSection<StoreItem>(sectionKey);
    const sectionItem = section.getValueForKey(id);

    return sectionItem || defaultItem;
  };

  const fetchStoreItem = async <StoreItem>(
    sectionKey: StoreSectionKey,
    id: string
  ): Promise<StoreSectionItem<StoreItem | undefined>> => {
    const item = getStoreItem<StoreItem>(sectionKey, id);
    // escape if we've already fetched the item
    if (item.state !== StoreSectionItemStates.Default) {
      return item;
    }

    const getItemFetcher = getSectionItemFetcher<StoreItem>(sectionKey);
    // escape if we have no way of fetching the item
    if (!getItemFetcher) {
      return getDefaultStoreItem();
    }

    logger.log('[storeItemApi] fetching item', sectionKey, id);
    setStoreItemState(sectionKey, id, StoreSectionItemStates.Loading);
    let responseItem;
    try {
      responseItem = await getItemFetcher(id);
    } catch (e) {
      logger.log('[storeItemApi] failed to fetch id', id);
    }
    setStoreItemItem<StoreItem>(sectionKey, id, responseItem);
    setStoreItemState(sectionKey, id, StoreSectionItemStates.Ready);
    return {
      item: responseItem,
      state: StoreSectionItemStates.Ready,
    };
  };

  return {
    getDefaultStoreItem,
    fetchStoreItem,
    getStoreItem,
    getStoreItemKeyWhere,
    getStoreItemKeysWhere,
    setStoreItemItem,
    mergeStoreItemItem,
    setStoreItemState,
    deleteStoreItem,
    subscribeToItemChanges,
    subscribeToKeysChanges,
  };
};

export default createStoreItemApi;
