import { memo } from 'react';

import { StoreSectionInitializerProps } from '../types';
import useImageResourceStoreInitializer from './hooks/useImageResourceStoreInitializer';

const ImageResourceStoreInitializer = ({
  onSectionLoaded,
}: StoreSectionInitializerProps) => {
  useImageResourceStoreInitializer(onSectionLoaded);

  return null;
};

export default memo(ImageResourceStoreInitializer);
