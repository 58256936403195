import { z } from 'zod';

import {
  ArticleCardLoadedModel,
  ArticleCardUnloadedModelSchema,
} from '../Article';
import {
  AuthorCardLoadedModel,
  AuthorCardUnloadedModelSchema,
} from '../Author';
import { DeckCardLoadedModel, DeckCardUnloadedModelSchema } from '../Deck';
import {
  DisplayCardLoadedModel,
  DisplayCardUnloadedModelSchema,
} from '../Display';
import { FlowCardLoadedModel, FlowCardUnloadedModelSchema } from '../Flow';
import { KitCardLoadedModel, KitCardUnloadedModelSchema } from '../Kit';
import { PlayCardLoadedModel, PlayCardUnloadedModelSchema } from '../Play';
import {
  AnyResourceCardLoadedModel,
  AnyResourceCardUnloadedModelSchema,
} from '../Resource';
import {
  SubjectCardLoadedModel,
  SubjectCardUnloadedModelSchema,
} from '../Subject';
import {
  AnyToolCardLoadedModel,
  AnyToolCardUnloadedModelSchema,
} from '../Tool';

// getting an error that the inferred type will be too large for
// serialization.. so we'll add the types back in native TS
export const AnyCardLoadedModelSchema = z.any();

export const AnyCardUnloadedModelSchema = z.union([
  AnyResourceCardUnloadedModelSchema,
  AnyToolCardUnloadedModelSchema,
  ArticleCardUnloadedModelSchema,
  AuthorCardUnloadedModelSchema,
  DeckCardUnloadedModelSchema,
  DisplayCardUnloadedModelSchema,
  FlowCardUnloadedModelSchema,
  KitCardUnloadedModelSchema,
  PlayCardUnloadedModelSchema,
  SubjectCardUnloadedModelSchema,
]);

export type AnyCardLoadedModel =
  | AnyResourceCardLoadedModel
  | AnyToolCardLoadedModel
  | ArticleCardLoadedModel
  | AuthorCardLoadedModel
  | DeckCardLoadedModel
  | DisplayCardLoadedModel
  | FlowCardLoadedModel
  | KitCardLoadedModel
  | PlayCardLoadedModel
  | SubjectCardLoadedModel;
export type AnyCardUnloadedModel = z.infer<typeof AnyCardUnloadedModelSchema>;

export type AnyCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? AnyCardLoadedModel : AnyCardUnloadedModel;

export type AnyPartialCardModel = Partial<AnyCardModel<false>> & {
  id: AnyCardModel['id'];
};
