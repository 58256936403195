/* eslint-disable camelcase */
import { generateLinearPalette } from '../../services/linearPaletteGenerator';

const actionBaseColor = '#F48F62'; // rgb(244, 143, 98)
const palette = generateLinearPalette(actionBaseColor);

export const action050 = palette['050']; // #fce4da
export const action100 = palette['100']; // #fcdbcd
export const action200 = palette['200']; // #facfbd
export const action300 = palette['300']; // #f9bfa7
export const action400 = palette['400']; // #f7aa89
export const action500 = palette['500']; // #F48F62
export const action500_10 = palette['500_10']; // #F48F62
export const action500_30 = palette['500_30']; // #F48F62
export const action500_40 = palette['500_40']; // #F48F62
export const action600 = palette['600']; // #f16b32
export const action700 = palette['700']; // #e75110
export const action800 = palette['800']; // #c4450e
export const action900 = palette['900']; // #a73a0c
