import { useMemo } from 'react';
import { EMPTY_OBJ } from '@kitted/shared-utils';

import {
  EditDrawerSpaceContext,
  EditDrawerSpaceDataContext,
} from './EditDrawerSpaceContext';
import { useEditDrawerSpaceManagement } from './hooks';
import { EditDrawerSpaceContextProps } from './types';

export const EditDrawerSpaceProvider: React.FC<EditDrawerSpaceContextProps> = ({
  children,
  items,
  initialSelectedDrawerItemSlug,
  spaceProps = EMPTY_OBJ,
}) => {
  const {
    selectedDrawerItemSlug,
    setSelectedDrawerItemSlug,
    bodyRef,
    onSetBodyRef,
  } = useEditDrawerSpaceManagement(items, initialSelectedDrawerItemSlug);
  const dataValue = useMemo(
    () => ({
      selectedDrawerItemSlug,
      items,
      spaceProps,
      bodyRef,
    }),
    [selectedDrawerItemSlug, items, spaceProps, bodyRef]
  );
  const apiValue = useMemo(
    () => ({ setSelectedDrawerItemSlug, onSetBodyRef }),
    [setSelectedDrawerItemSlug, onSetBodyRef]
  );

  return (
    <EditDrawerSpaceContext.Provider value={apiValue}>
      <EditDrawerSpaceDataContext.Provider value={dataValue}>
        {children}
      </EditDrawerSpaceDataContext.Provider>
    </EditDrawerSpaceContext.Provider>
  );
};
