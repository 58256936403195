import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    '@keyframes loaderBackgroundPrimary': {
      ...styling.animations.context.loaderBackgroundPrimary.keyframes,
    },
    '@keyframes loaderBackgroundSecondary': {
      ...styling.animations.context.loaderBackgroundSecondary.keyframes,
    },
    image: {
      animationName: '$loaderBackgroundSecondary',
      ...styling.animations.context.loaderBackgroundSecondary.useage,
    },
    imageDark: {
      animationName: '$loaderBackgroundPrimary',
      ...styling.animations.context.loaderBackgroundPrimary.useage,
    },
  },
  { name: 'imageSkeleton' }
);
