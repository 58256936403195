import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0054: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 124 112"
		height={112}
		width={124}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={spot} d="M41.08 93.5c-9.8-6.45-16.44-17.31-17.11-29.75M100.03 63.76c-.67 12.45-7.33 23.32-17.15 29.77M45.15 27.49c5.08-2.51 10.8-3.92 16.84-3.92s11.72 1.4 16.78 3.89" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M105.62 47.78c-4.01-2.32-8.3-3.71-12.63-4.27 1.81-4.13 2.82-8.7 2.82-13.5 0-12.26-6.54-22.96-16.3-28.88 5.65 4.9 9.24 12.12 9.24 20.19 0 14.78-11.98 26.75-26.75 26.75S35.25 36.09 35.25 21.32c0-8.07 3.59-15.29 9.24-20.19-9.76 5.93-16.3 16.63-16.3 28.88 0 4.8 1.02 9.36 2.82 13.5-4.33.55-8.62 1.95-12.63 4.26C7.77 53.91 1.77 64.92 1.51 76.34 2.93 69 7.39 62.28 14.38 58.24c12.8-7.39 29.16-3 36.55 9.79 7.39 12.8 3 29.16-9.79 36.54-6.99 4.04-15.04 4.54-22.11 2.1 10.01 5.49 22.55 5.8 33.16-.33 3.92-2.26 7.2-5.2 9.81-8.57 2.61 3.37 5.89 6.31 9.81 8.57 10.61 6.13 23.15 5.82 33.17.33-7.07 2.44-15.12 1.94-22.11-2.1-12.79-7.39-17.18-23.75-9.79-36.54s23.75-17.18 36.55-9.79c6.99 4.04 11.44 10.75 12.87 18.1-.25-11.42-6.25-22.43-16.87-28.56Z" />
    </g>
  </CardIcon>
);

export default memo(Sn0054);
