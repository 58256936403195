import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    input: {
      color: 'inherit',
      width: '100%',
      background: 'none',
      border: 'none',
      padding: [14, 24],
      appearance: 'none',
      fontWeight: 'inherit',
      MozAppearance: 'textfield',
      '&:focus': {
        outline: 'none',
      },
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '&:-webkit-autofill, &:-webkit-autofill:focus, &[data-com-onepassword-filled]':
        {
          transition: 'background-color 600000s 0s, color 600000s 0s',
        },
    },
    inputWithLeftIcon: {
      paddingLeft: 66,
    },
    inputWithRightIcon: {
      paddingRight: 66,
    },
    inputUrl: {
      paddingLeft: 113,
    },
    urlIcon: {
      pointerEvents: 'none',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      padding: [14, 16, 14, 18],
      color: styling.colors.theme.primary100,
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 14,
        right: 0,
        bottom: 14,
        width: 1,
        backgroundColor: styling.colors.theme.secondary300_20,
      },
    },
    clearIcon: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      right: 20,
      top: 0,
      bottom: 0,
      maxWidth: 34,
      fontSize: '1.4rem',
      cursor: 'pointer',
      opacity: 0,
      pointerEvents: 'none',
      transition: styling.transitions.utils.generate(['opacity']),
    },
    clearIconVisible: {
      opacity: 1,
      pointerEvents: 'all',
    },
  },
  { name: 'inputText' }
);
