import { routes } from '@kitted/website-constants';

import useIsLoggingIn from '../../../../../../../../contexts/UserContext/hooks/useIsLoggingIn';
import useMyAuthor from '../../../../../../../../hooks/authors/useMyAuthor';
import useAuthorMeta from '../../../../../../../../hooks/useAuthorMeta';
import useIsPathSelected from '../../../../../../../../hooks/useIsPathSelected';

const AccountMenuItemIcon = () => {
  const { isLoggingIn } = useIsLoggingIn();
  const { author, isLoading } = useMyAuthor();
  const { initials } = useAuthorMeta(author);
  const linkIsSelected = useIsPathSelected(routes.account.path);

  return {
    initials,
    accountImageAsset: author?.keyImageImageAsset,
    isLoggingIn,
    isLoading,
    linkIsSelected,
  };
};

export default AccountMenuItemIcon;
