import { memo } from 'react';

import menus from '../../../../constants/routes/menus';
import AccountRegisterItem from './AccountRegisterItem';
import AccountSignup from './AccountSignup';
import LoginOutItem from './LoginOutItem';
import MobileMenuOption from './MobileMenuOption';
import useStyles from './styles';
import { MobileMenuContentProps } from './types';

const MobileMenuContent = ({ onToggleOpen }: MobileMenuContentProps) => {
  const styles = useStyles();

  return (
    <>
      {/* account prompt */}
      <div className={styles.accountSection}>
        <AccountSignup onToggleOpen={onToggleOpen} />
      </div>
      {/* menu */}
      <nav className={styles.menuSection}>
        <ul>
          {menus.mobileSecondaryMenu.map(
            ({ route: { path, title, target }, icon, highlightSubroutes }) => (
              <li key={path} className={styles.item}>
                <MobileMenuOption
                  icon={icon}
                  path={path}
                  target={target}
                  title={title}
                  highlightSubroutes={highlightSubroutes}
                />
              </li>
            )
          )}
          <li className={styles.item}>
            <AccountRegisterItem />
          </li>
          <li className={styles.item}>
            <LoginOutItem onToggleOpen={onToggleOpen} />
          </li>
        </ul>
      </nav>
    </>
  );
};

export default memo(MobileMenuContent);
