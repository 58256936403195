export const linearSettings = {
  hueMultiplier: 0,
  lightnessMultiplier: 0,
  saturationMultiplier: 0,
};

export const FULL_COLOR_KEYS = [
  '050',
  '100',
  '200',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900',
] as const;

export const ALPHA_LIST = [
  0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1, 0.05,
] as const;
