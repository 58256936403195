import { Dispatch, SetStateAction } from 'react';
import { AnyCardModel, DisplayCardModel } from '@kitted/card-service-models';
import { CardType } from '@kitted/kitted-models';
import {
  evenlyDistributeItemsIntoArray,
  insertAtIndex,
  shuffle,
  sort,
  uniq,
} from '@kitted/shared-utils';

import { randomIntFromRange } from '../../../../services/helpers/number';
import { ReducedUserCardForSorting, userCardsSortOrder } from './constants';

const extractReducedUserCardForSortingFromCard = (
  card: AnyCardModel | ReducedUserCardForSorting
): ReducedUserCardForSorting => ({
  id: card.id,
  slug: card.slug,
  updatedAt: card.updatedAt,
});

export const getPlatformCardIdsSortOrder = (
  platformCards: AnyCardModel[]
): AnyCardModel['id'][] => {
  const platformCardsWithoutDisplayCards = platformCards.filter(
    (card) => card.cardType !== CardType.Display
  );
  const displayCards = platformCards.filter(
    (card): card is DisplayCardModel => card.cardType === CardType.Display
  );

  const sortedPlatformCards = evenlyDistributeItemsIntoArray<AnyCardModel>(
    shuffle(platformCardsWithoutDisplayCards),
    shuffle(displayCards)
  );

  return sortedPlatformCards.map((card) => card.id);
};

export const getUserCardIdsSortOrder = (
  userCards: (AnyCardModel | ReducedUserCardForSorting)[]
): ReducedUserCardForSorting[] => {
  const sortedUserCards = sort(userCards, userCardsSortOrder);

  return sortedUserCards.map((card) =>
    extractReducedUserCardForSortingFromCard(card)
  );
};

export const updateOrderIdsForCardAddedUpdated = (
  card: AnyCardModel,
  setOrderedUserCardIds: Dispatch<SetStateAction<ReducedUserCardForSorting[]>>,
  setOrderedPlatformCardIds: Dispatch<SetStateAction<AnyCardModel['id'][]>>,
  platformAuthorId: string
) => {
  if (card.createdByAuthorId === platformAuthorId) {
    setOrderedPlatformCardIds((prevPlatformCardIds) => {
      // platform card updates dont trigger reorders
      if (prevPlatformCardIds.includes(card.id)) {
        return prevPlatformCardIds;
      }
      // new platform cards are inserted randomly into the existing list
      return insertAtIndex(
        prevPlatformCardIds,
        card.id,
        randomIntFromRange(0, prevPlatformCardIds.length)
      );
    });
    return;
  }
  // user card add/updates are always moved to the front of the list
  setOrderedUserCardIds((prevUserCardIds) =>
    getUserCardIdsSortOrder(
      uniq([extractReducedUserCardForSortingFromCard(card), ...prevUserCardIds])
    )
  );
};
