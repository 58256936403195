import { useMemo } from 'react';

import useUnsavedChangesNavigationBlocker from '../../hooks/useUnsavedChangesNavigationBlocker';
import { FormContext } from './FormContext';
import { FormDataContext } from './FormDataContext';
import { useFormManagement } from './hooks';
import { FormContextProps, FormStates } from './types';

export const FormProvider = ({
  children,
  initialValues,
  dataSchema,
}: FormContextProps) => {
  const {
    formData,
    formState,
    formErrors,
    formInstanceId,

    getFormData,

    addFormLoadingItem,
    removeFormLoadingItem,

    setFormData,
    setFormErrors,
    setFormIsSubmitting,
    resetForm,
  } = useFormManagement(dataSchema, initialValues);
  const apiValue = useMemo(
    () => ({
      getFormData,

      addFormLoadingItem,
      removeFormLoadingItem,

      setFormData,
      setFormErrors,
      setFormIsSubmitting,
      resetForm,
    }),
    [
      getFormData,
      addFormLoadingItem,
      removeFormLoadingItem,
      setFormData,
      setFormErrors,
      setFormIsSubmitting,
      resetForm,
    ]
  );
  const dataValue = useMemo(
    () => ({ dataSchema, formData, formState, formErrors, formInstanceId }),
    [dataSchema, formData, formState, formErrors, formInstanceId]
  );

  useUnsavedChangesNavigationBlocker(
    formState === FormStates.DIRTY || formState === FormStates.SUBMITTING
  );

  return (
    <FormContext.Provider value={apiValue}>
      <FormDataContext.Provider value={dataValue}>
        {children}
      </FormDataContext.Provider>
    </FormContext.Provider>
  );
};
