import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0037: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 98 102"
		height={102}
		width={98}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M79.84 6.25 18.17 95.74" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M23.61 13.74c0 .54.44.98.98.98s.98-.44.98-.98-.44-.98-.98-.98-.98.44-.98.98M12.32 13.74c0 .54.44.98.98.98s.98-.44.98-.98-.44-.98-.98-.98-.98.44-.98.98" /><circle vectorEffect="non-scaling-stroke" cx="18.94" cy="18.58" r="17.29" /><path vectorEffect="non-scaling-stroke" d="M11.56 27.09c1.48-2.51 4.27-4.2 7.39-4.2s5.91 1.69 7.39 4.2" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M83.72 78.57c0 .54.44.98.98.98s.98-.44.98-.98-.44-.98-.98-.98-.98.44-.98.98M72.43 78.57c0 .54.44.98.98.98s.98-.44.98-.98-.44-.98-.98-.98-.98.44-.98.98" /><circle vectorEffect="non-scaling-stroke" cx="79.06" cy="83.42" r="17.29" /><path vectorEffect="non-scaling-stroke" d="M71.67 91.92c1.48-2.51 4.27-4.2 7.39-4.2s5.91 1.69 7.39 4.2" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0037);
