import { memo } from 'react';
import {
  Divider,
  KittedBrand,
  KittedBrandmark,
  Typography,
} from '@kitted/shared-components';
import { externalRoutes, routes } from '@kitted/website-constants';
import { PlayRight } from '@kitted/website-icons';

import Button from '../../../components/Button';
import ContentWrapper from '../../../components/ContentWrapper';
import CCLincense from '../../../components/Icons/CCLicense';
import TTFHBrand from '../../../components/Icons/TTFHBrand';
import PreloadingInternalLink from '../../../components/PreloadingInternalLink';
import menus from '../../../constants/routes/menus';
import FooterMenu from './FooterMenu';
import useStyles from './styles';

const Footer = () => {
  const styles = useStyles();

  return (
    <footer className={styles.footerContainer}>
      <ContentWrapper>
        <div className={styles.brandShop}>
          <div className={styles.brand}>
            <KittedBrandmark className={styles.brandmark} />
            <KittedBrand />
          </div>
          <Button
            className={styles.shopButton}
            href={externalRoutes.shop.path}
            iconRight={PlayRight}
            theme="primary"
            alignment="pullIconRight"
          >
            Shop Card Decks
          </Button>
        </div>
        <Divider spacing="xl" theme="primary" />
        <div className={styles.ttfhMenus}>
          <div className={styles.ttfhBrandEntity}>
            <div>
              <Typography className={styles.ttfhFrom} variant="footer">
                <strong>Kitted is from</strong>
              </Typography>
              <TTFHBrand />
              <Typography className={styles.ttfh} variant="footer">
                Two Thousand Five Hundred Australia Ltd.
              </Typography>
            </div>
            <div>
              <Typography className={styles.ttfhUK} variant="footer">
                <strong>United Kingdom</strong>
                <br />
                CN 13468387
              </Typography>
              <Typography variant="footer">
                <strong>Australia</strong>
                <br />
                ABN 40 654 123 556
              </Typography>
            </div>
          </div>
          <div className={styles.menus}>
            <ul>
              <FooterMenu menu={menus.footerMenuOne} />
            </ul>
            <ul>
              <FooterMenu menu={menus.footerMenuTwo} />
            </ul>
            <ul>
              <FooterMenu menu={menus.footerMenuSocials} />
            </ul>
          </div>
        </div>
        <Divider spacing="xl" theme="primary" />
        <div className={styles.license}>
          <Typography variant="heading6">License</Typography>
          <Typography className={styles.licenseBody} variant="footer">
            Kitted is published under a Creative Commons 4.0 Attribution,
            Non-commercial, No Derivative Works (BY-NC-ND) license by Two
            Thousand Five Hundred Limited. You are free to use and share these
            resources, so long as you link to the relevant page on kitted.app,
            don&rsquo;t commercially benefit directly from reselling the
            content, and don&rsquo;t change them to create your own versions or
            pass them off as your own without attribution.
          </Typography>
          <Typography variant="footer">
            See our{' '}
            <strong>
              <PreloadingInternalLink to={routes.legals.path}>
                legals
              </PreloadingInternalLink>
            </strong>{' '}
            for more details.
          </Typography>
          <CCLincense className={styles.licenseIcons} />
        </div>
      </ContentWrapper>
    </footer>
  );
};

export default memo(Footer);
