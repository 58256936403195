import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0041: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 116 94"
		height={94}
		width={116}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M47.47 48.72c-.99-7.97-6.39-13.26-6.39-13.26s1.84 5.02-2.25 10.99c0 0-4.03 5.09-2.24 10.11M77.92 56.56c5.59-8.13-3.59-22.39-3.59-22.39.97 8.98-5.59 12.51-5.59 12.51.9-4.13-4.32-10.68-4.32-10.68-.45 3.63-6.29 6.9-6.29 6.9-2.85-2.87-7.58-8.82-6.14-19.55 0 0-2.95 3.02-4.74 8.22M24.32 61.94h67.22" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M35.09 93.15H80.9M50.04 78.55v14.2M65.96 78.55v14.2M1.68 10.7c0-5.22 4.27-9.48 9.48-9.48h93.69c5.21 0 9.47 4.26 9.47 9.47v57.85c0 5.21-4.26 9.47-9.47 9.47H11.16c-5.21 0-9.47-4.26-9.47-9.47V10.7Z" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0041);
