import { useMemo } from 'react';

import { useAnalyticsApi } from '../../../../contexts/AnalyticsContext';
import { ExternalLinkWithCrossDomainDistinctIdProps } from '../../types';
import { getHrefWithCrossDomainDistinctId } from './logic';

const useExternalLinkWithCrossDomainDistinctId = (
  href: ExternalLinkWithCrossDomainDistinctIdProps['href']
) => {
  const { getDistinctId } = useAnalyticsApi();
  const hrefWithCrossDomainDistinctId = useMemo(
    () => getHrefWithCrossDomainDistinctId(getDistinctId, href),
    [getDistinctId, href]
  );

  return {
    hrefWithCrossDomainDistinctId,
  };
};

export default useExternalLinkWithCrossDomainDistinctId;
