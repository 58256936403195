import { useMemo } from 'react';

import { ToastTheme } from '../../../../contexts/ToastContext/types';
import { getTimerThemeForTheme } from '../../logic';

const useToastIcon = (theme: ToastTheme) => {
  const timerTheme = useMemo(() => getTimerThemeForTheme(theme), [theme]);

  return {
    timerTheme,
  };
};

export default useToastIcon;
