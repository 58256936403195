import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0039: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 106 106"
		height={106}
		width={106}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M17.67 52.25h38.5M17.67 66.95h29.49M17.67 81.65h27.51M25.03 11.19v13.73H11.29" /><path vectorEffect="non-scaling-stroke" d="M79.18 44.6V1.22H21.49L1.4 21.31v83.47h54.22" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M104.6 79.5c0 13.97-11.32 25.28-25.28 25.28S54.03 93.46 54.03 79.5s11.32-25.28 25.29-25.28S104.6 65.54 104.6 79.5" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M88.75 70.07 69.89 88.93M88.75 88.93 69.89 70.07" /></g>
    </g>
  </CardIcon>
);

export default memo(Ac0039);
