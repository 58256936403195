import { CardInfo, CircleCheck, TriangleExclaim } from '@kitted/website-icons';

import { BannerTheme } from './types';

export const bannerIconComponents: Record<BannerTheme, React.ElementType> = {
  secondary: CircleCheck,
  primary: CircleCheck,
  info: CardInfo,
  success: CircleCheck,
  error: TriangleExclaim,
};
