import { memo } from 'react';

import { StoreSectionInitializerProps } from '../types';
import useFlowStoreInitializer from './hooks/useFlowStoreInitializer';

const FlowStoreInitializer = ({
  onSectionLoaded,
}: StoreSectionInitializerProps) => {
  useFlowStoreInitializer(onSectionLoaded);

  return null;
};

export default memo(FlowStoreInitializer);
