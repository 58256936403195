import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0043: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 110 52"
		height={52}
		width={110}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M24.14 31.81c.61 2.29-.75 4.64-3.03 5.26-2.29.61-4.64-.75-5.26-3.04-.61-2.29.75-4.64 3.04-5.26a4.29 4.29 0 0 1 5.25 3.03ZM20 28.57V5.36M51.57 28.78 47.38 5.5M56.82 31.81c.61 2.29-.75 4.64-3.04 5.26-2.29.61-4.64-.75-5.26-3.04-.61-2.29.74-4.64 3.04-5.26a4.29 4.29 0 0 1 5.25 3.03ZM3.77 50.97v-6.33h64.26v6.33M38.51 31.81c.61 2.29-.75 4.64-3.04 5.26-2.29.61-4.64-.75-5.26-3.04-.61-2.29.74-4.64 3.04-5.26a4.29 4.29 0 0 1 5.25 3.03ZM34.36 28.57V5.36" /><path vectorEffect="non-scaling-stroke" d="M7.96 40.14V8.19c0-3.93 3.22-7.16 7.15-7.16h41.58c3.94 0 7.15 3.22 7.15 7.16v36.39M.74 51.12h70.32" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M95.31 16.71a6.79 6.79 0 1 1 6.79 6.79H71.06M85.95 36.61a6.32 6.32 0 1 0 6.32-6.32H71.06M78.25 12.38c0-2.39 1.94-4.33 4.34-4.33a4.334 4.334 0 1 1 0 8.67H71.07" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0043);
