import useMediaQuery from '../../../../contexts/MediaQueryManagementContext/hooks/useMediaQuery';

const useEditDrawerSpace = () => {
  const isTablet = useMediaQuery('isTablet');

  return {
    isTablet,
  };
};

export default useEditDrawerSpace;
