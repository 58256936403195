/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';
import clsx from 'clsx';

import useTypography from './hooks/useTypography';
import useStyles from './styles';
import { PolymorphicTypographyProps, TypographyRef } from './types';

const Typography = React.forwardRef(
  <C extends React.ElementType = 'p'>(
    {
      as,
      children,
      className,
      maxLines,
      noMargin,
      isTight,
      variant = 'body',
      style,
      ...props
    }: PolymorphicTypographyProps<C>,
    ref?: TypographyRef<C>
  ) => {
    const { typographyStyle } = useTypography(
      maxLines,
      isTight,
      variant,
      style
    );
    const styles = useStyles();
    const Component = as || 'p';

    return (
      <Component
        ref={ref}
        className={clsx(
          className,
          styles.typography,
          styles[`variant-${variant}`],
          maxLines && styles.maxLines,
          noMargin && styles.noMargin,
          isTight && styles.isTight
        )}
        {...props}
        style={typographyStyle}
      >
        {children}
      </Component>
    );
  }
);

Typography.displayName = 'Typography';

export default memo(Typography);
