import { z } from 'zod';
import { CardDownloadTypeEnum } from '@kitted/card-service-models';

import { WebsocketDownloadFileClientMessageSchema } from './WebsocketDownloadFileClientMessage';

export const WebsocketDownloadCardZipClientMessageSchema =
  WebsocketDownloadFileClientMessageSchema.merge(
    z.object({
      downloadType: z.literal(CardDownloadTypeEnum.CardZip),

      cardSlug: z.string(),
    })
  );

export type WebsocketDownloadCardZipClientMessage = z.infer<
  typeof WebsocketDownloadCardZipClientMessageSchema
>;
