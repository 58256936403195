import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Avatar: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 24 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <circle vectorEffect="non-scaling-stroke" cx="12" cy="7.44" r="6.06" stroke={color} /><path vectorEffect="non-scaling-stroke" stroke={color} d="M1.69 26.62c0-5.69 4.62-10.31 10.31-10.31s10.31 4.62 10.31 10.31" />
    </g>
  </WebsiteIcon>
);

export default memo(Avatar);
