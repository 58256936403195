import { ToastTheme } from '../../contexts/ToastContext/types';
import { ProgressCircleProps } from '../ProgressCircle/types';

export const toastTimerTheme: Record<ToastTheme, ProgressCircleProps['theme']> =
  {
    primary: 'secondary',
    secondary: 'primary',
    info: 'secondary',
    success: 'secondary',
    error: 'secondary',
  };
