import { ExternalRoute, PageTitle } from '../types';

export const constructExternalRoute = (
  path: string,
  title: PageTitle,
  target: '_self' | '_blank' = '_self'
): ExternalRoute => ({
  path,
  title,
  target,
});
