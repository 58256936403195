import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0025: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 96 100"
		height={100}
		width={96}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M20.64 7.07c25.25-9.04 38.2-6.79 38.2 4.39M94.96 41.86C92.47 10.89 75.63 7.14 68.41 15.47" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M76.6 33.91c.18-7.63-3.67-16.59-11.22-19.78L60.36 12c-7.54-3.18-16.65.3-22 5.75" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M53.66 34.87 35.33 78.21" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m27.48 29.8-6.27-7.13-13.35 5.98M75.53 50.11l9.48-.47 5.02 13.74" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m21.8 40.93-8.63-3.65-12.13 9.15M71.51 61.94l8.63 3.65 1.89 15.07" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="m20.64 55.36-7.2-3.04L1.17 72.19M61.96 72.83l7.21 3.04-5.7 22.65" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M76.4 40.71s-10.11-1.24-22.48-6.47c-12.37-5.22-20.31-11.61-20.31-11.61-3.63 3.8-6.79 8.5-9.09 13.96-4.75 11.24-3.83 25.29-.77 32.15 2.44 5.48 6.52 8.23 11.26 10.24 4.73 2 9.56 3.01 15.19.95 7.06-2.59 17.77-11.72 22.53-22.96 2.3-5.46 3.48-10.99 3.67-16.25Z" />
    </g>
  </CardIcon>
);

export default memo(Sn0025);
