import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Fr0034: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 98 102"
		height={102}
		width={98}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M21.2 100.4V73.18h-6.35c-7.15 0-13-5.85-13-13V14.6c.01-7.15 5.86-13 13.01-13h68.29c7.15 0 13 5.85 13 13v45.58c0 7.15-5.85 13-13 13H44.67L21.21 100.4Z" /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M40.24 30.22c2.61-.78 12.36-4.41 12.36-15.71 0 0 .22-2.87 3.39-2.87 0 0 3.48-.35 3.85 3.58 0 0 .89 7.57-1.59 12.52h11.62c.48 0 2.3-.08 3.31 1.63.69 1.17.48 2.75.29 3.9s-3.69 18.88-3.69 18.88c-.38 1.63-1.8 3.47-4.45 3.47h-25.4M24.47 29.4h10.02v28.22H24.47V29.4" /></g>
    </g>
  </CardIcon>
);

export default memo(Fr0034);
