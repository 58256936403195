import { BlobGuid } from '@kitted/kitted-models';

import { getServiceBaseUrl } from '../../contexts/RequestsContext/hooks/usePlatformRequest/logic';

export const getBlobStorageMetadataUrl = (
  isLocalDevelopment: boolean,
  environmentDomain: string,
  blobId: BlobGuid
) =>
  `${getServiceBaseUrl(isLocalDevelopment, environmentDomain, 'blob-storage')}/metadata/${blobId}`;

export const getBlobStorageContentUrl = (baseUrl: string, blobId: BlobGuid) => {
  if (blobId.startsWith('T::')) {
    return `https://temporary.blob-storage.${baseUrl}/${blobId}`;
  }

  return `https://persistent.blob-storage.${baseUrl}/${blobId}`;
};
