import { z } from 'zod';

import {
  ActionToolCardLoadedModelSchema,
  ActionToolCardUnloadedModelSchema,
} from './ActionTool';
import {
  FrameToolCardLoadedModelSchema,
  FrameToolCardUnloadedModelSchema,
} from './FrameTool';
import {
  InsightToolCardLoadedModelSchema,
  InsightToolCardUnloadedModelSchema,
} from './InsightTool';
import {
  SnareToolCardLoadedModelSchema,
  SnareToolCardUnloadedModelSchema,
} from './SnareTool';

export const AnyToolCardLoadedModelSchema = z.union([
  ActionToolCardLoadedModelSchema,
  FrameToolCardLoadedModelSchema,
  InsightToolCardLoadedModelSchema,
  SnareToolCardLoadedModelSchema,
]);
export const AnyToolCardUnloadedModelSchema = z.union([
  ActionToolCardUnloadedModelSchema,
  FrameToolCardUnloadedModelSchema,
  InsightToolCardUnloadedModelSchema,
  SnareToolCardUnloadedModelSchema,
]);

export type AnyToolCardLoadedModel = z.infer<
  typeof AnyToolCardLoadedModelSchema
>;
export type AnyToolCardUnloadedModel = z.infer<
  typeof AnyToolCardUnloadedModelSchema
>;

export type AnyToolCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true ? AnyToolCardLoadedModel : AnyToolCardUnloadedModel;
