import { memo } from 'react';
import clsx from 'clsx';

import AssetImage from '../AssetImage';
import useBackgroundImage from './hooks/useBackgroundImage';
import useStyles from './styles';
import { BackgroundImageProps } from './types';

const BackgroundImage = ({
  className,
  src,
  imageAsset,
  noImageOverlay = false,
  variant = 'page',
  size,
}: BackgroundImageProps) => {
  const { containerRef, style } = useBackgroundImage(
    variant === 'header',
    variant === 'page'
  );
  const styles = useStyles();

  return (
    <div
      ref={containerRef}
      className={clsx(
        className,
        styles.container,
        styles[`variant-${variant}`],
        noImageOverlay && styles.containerNoImageOverlay
      )}
      style={style}
    >
      <AssetImage
        className={styles.image}
        imageAsset={imageAsset}
        size={size}
        src={src}
        loaderTheme="none"
      />
    </div>
  );
};

export default memo(BackgroundImage);
