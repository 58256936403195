/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  KeyValueStore,
  Unsubscribe,
} from '../../../../services/keyValueStore/types';

export enum StoreSectionItemStates {
  Loading = 'loading',
  Ready = 'ready',
  Default = 'default',
}

export type StoreItemSubscriptionCallback<T> = (item: T, id: string) => void;

export type StoreKeysSubscriptionCallback = (keys: string[]) => void;

export type StoreSectionItem<StoreItem> = {
  item: StoreItem;
  state: StoreSectionItemStates;
};

export type StoreSectionFetcherGetItemById<StoreItem> = (
  itemKey: string
) => Promise<StoreItem | undefined>;

export type StoreSection<StoreItem> = {
  kvs: KeyValueStore<StoreSectionItem<StoreItem>>;
  itemFetcher?: StoreSectionFetcherGetItemById<StoreItem>;
};

export type StoreSectionKey = string;

// TODO: explore a replacement for 'any' here, we should be able to not
// worry about typing the store, and have getters/setters know their own types
export type Store = Record<StoreSectionKey, StoreSection<any>>;

export type StoreOfStoresApi = {
  createOrUpdateStoreSection: (
    key: StoreSectionKey,
    itemFetcher?: StoreSectionFetcherGetItemById<any>
  ) => void;
  setSection: <StoreItem>(
    key: StoreSectionKey,
    value: Record<string, StoreSectionItem<StoreItem | undefined>> | undefined
  ) => void;
  getDefaultStoreItem: () => StoreSectionItem<undefined>;
  fetchStoreItem: <StoreItem>(
    sectionKey: StoreSectionKey,
    id: string
  ) => Promise<StoreSectionItem<StoreItem | undefined>>;
  getStoreItem: <StoreItem>(
    sectionKey: StoreSectionKey,
    id: string | undefined
  ) => StoreSectionItem<StoreItem | undefined>;
  getStoreItemKeyWhere: <StoreItem>(
    sectionKey: StoreSectionKey,
    finderFn: (item: StoreSectionItem<StoreItem | undefined>) => boolean
  ) => string | undefined;
  getStoreItemKeysWhere: <StoreItem>(
    sectionKey: StoreSectionKey,
    finderFn: (item: StoreSectionItem<StoreItem | undefined>) => boolean
  ) => string[];
  setStoreItemItem: <StoreItem>(
    sectionKey: StoreSectionKey,
    id: string,
    item: StoreItem | undefined
  ) => void;
  mergeStoreItemItem: <StoreItem>(
    sectionKey: StoreSectionKey,
    id: string,
    item: Partial<StoreItem>
  ) => void;
  setStoreItemState: (
    sectionKey: StoreSectionKey,
    id: string,
    state: StoreSectionItemStates
  ) => void;
  deleteStoreItem: (sectionKey: StoreSectionKey, id: string) => void;
  subscribeToItemChanges: <StoreItem>(
    sectionKey: StoreSectionKey,
    id: string,
    subscriptionCallback: StoreItemSubscriptionCallback<
      StoreSectionItem<StoreItem | undefined>
    >
  ) => Unsubscribe;
  subscribeToKeysChanges: (
    key: StoreSectionKey,
    subscriptionCallback: StoreKeysSubscriptionCallback
  ) => Unsubscribe;
};
