import { useCallback, useMemo } from 'react';
import { EMPTY_OBJ } from '@kitted/shared-utils';

import { AddButtonModalProps, ButtonFormData } from '../../types';

const useAddButtonModalContent = (
  initialData: AddButtonModalProps['initialData'],
  onSave: AddButtonModalProps['onSave'],
  onClose: AddButtonModalProps['onClose']
) => {
  const initialValues = useMemo(
    () => ({
      title: '',
      url: '',
      ...(initialData || EMPTY_OBJ),
    }),
    [initialData]
  );

  const onSubmit = useCallback(
    async (formData: ButtonFormData) => onSave(formData),
    [onSave]
  );

  const onSubmitSuccess = useCallback(() => {
    onClose();
  }, [onClose]);

  return {
    initialValues,
    onSubmit,
    onSubmitSuccess,
  };
};

export default useAddButtonModalContent;
