import { useCallback, useEffect } from 'react';
import { KittedModelTypeEnum, KittedServiceEnum } from '@kitted/kitted-models';
import { ImageResourceModel } from '@kitted/resource-service-models';
import { WebsocketModelUpdateServerMessage } from '@kitted/websocket-service-models';

import useImageResourceRequests from '../../../../../../hooks/resources/useImageResourceRequests';
import { useSubscribeToWebsocketModelUpdateServerMessage } from '../../../../../WebsocketContext';
import useStoreApi from '../../../../hooks/useStoreApi';
import useStoreSection from '../../../../hooks/useStoreSection';
import { StoreSectionInitializerProps } from '../../../types';

const useImageResourceStoreInitializer = (
  onSectionLoaded: StoreSectionInitializerProps['onSectionLoaded']
) => {
  const { getImageResource } = useImageResourceRequests();
  const { createOrUpdateStoreSection } = useStoreApi();
  const { updateStoreSectionItem } = useStoreSection('ImageResources');

  const updateImageResourcesStoreSectionFetcher = useCallback(() => {
    createOrUpdateStoreSection('ImageResources', getImageResource);
    onSectionLoaded('ImageResources');
  }, [createOrUpdateStoreSection, getImageResource, onSectionLoaded]);

  useEffect(updateImageResourcesStoreSectionFetcher, [
    updateImageResourcesStoreSectionFetcher,
  ]);

  const handleWebsocketItemUpdated = useCallback(
    ({ model, service }: WebsocketModelUpdateServerMessage) => {
      if (
        model &&
        model.kittedModelType === KittedModelTypeEnum.ResourceImageResource &&
        service === KittedServiceEnum.Resource
      ) {
        const asset = model as ImageResourceModel<false>;
        updateStoreSectionItem(asset, asset.id);
      }
    },
    [updateStoreSectionItem]
  );

  useSubscribeToWebsocketModelUpdateServerMessage(handleWebsocketItemUpdated);
};

export default useImageResourceStoreInitializer;
