import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '@kitted/website-constants';
import { ArrowCardInRight, ArrowCardOutLeft } from '@kitted/website-icons';

import useIsAuthenticatedRoute from '../../../../../../../components/PageWrapper/AuthenticatedRouteBoundary/hooks/useIsAuthenticatedRoute';
import useIsLoggedIn from '../../../../../../../contexts/UserContext/hooks/useIsLoggedIn';
import useIsLoggingIn from '../../../../../../../contexts/UserContext/hooks/useIsLoggingIn';
import useUserApi from '../../../../../../../contexts/UserContext/hooks/useUserApi';
import useAuthModal from '../../../../../../../hooks/useAuthModal';
import { LoginOutItemProps } from '../../types';

const useLoginOutItem = (onToggleOpen: LoginOutItemProps['onToggleOpen']) => {
  const { isLoggedIn } = useIsLoggedIn();
  const { logoutUser } = useUserApi();
  const { launchLoginModal } = useAuthModal();
  const { isLoggingIn } = useIsLoggingIn();
  const isAuthenticatedRoute = useIsAuthenticatedRoute();
  const navigate = useNavigate();

  const { onClick, title, IconComponent } = useMemo(() => {
    if (isLoggedIn) {
      return {
        onClick: () => {
          if (isAuthenticatedRoute) {
            navigate(routes.home.path);
          }
          logoutUser();
          onToggleOpen();
        },
        title: 'Logout',
        IconComponent: ArrowCardOutLeft,
      };
    }
    return {
      onClick: () => {
        launchLoginModal();
        onToggleOpen();
      },
      title: 'Login',
      IconComponent: ArrowCardInRight,
    };
  }, [
    isLoggedIn,
    logoutUser,
    launchLoginModal,
    onToggleOpen,
    isAuthenticatedRoute,
    navigate,
  ]);

  return { onClick, title, IconComponent, isLoggingIn };
};

export default useLoginOutItem;
