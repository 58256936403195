import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0046: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 118 102"
		height={102}
		width={118}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M115.78 88.61H13.74V2.07M2.22 24.79h11.42M2.22 50.71h11.42M2.22 76.62h11.42M25.79 99.93V88.51M50.42 99.93V88.51M75.06 99.93V88.51M99.69 99.93V88.51" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M108.99 75.44c-7.75-15.71-19.97-26.89-34.13-30.1" /><path vectorEffect="non-scaling-stroke" stroke={spot} d="M58.09 45.34c-14.17 3.2-26.38 14.39-34.13 30.1" /><circle vectorEffect="non-scaling-stroke" cx="66.48" cy="44.4" r="4.22" stroke={spot} />
    </g>
  </CardIcon>
);

export default memo(In0046);
