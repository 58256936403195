/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthedOrNonAuthedRequestFn } from './types';

export const createDeferredPromise = <
  ResponseType,
  NonAuthedResponseType = undefined,
>() => {
  let resolve: (
    fn: AuthedOrNonAuthedRequestFn<ResponseType, NonAuthedResponseType>
  ) => void;
  let reject: (reason?: any) => void;
  const promise = new Promise<
    AuthedOrNonAuthedRequestFn<ResponseType, NonAuthedResponseType>
  >((res, rej) => {
    resolve = (
      fn: AuthedOrNonAuthedRequestFn<ResponseType, NonAuthedResponseType>
    ) => res(fn);
    reject = rej;
  });

  return { promise, resolve: resolve!, reject: reject! };
};
