import { memo } from 'react';
import clsx from 'clsx';
import { Divider, TextSkeleton } from '@kitted/shared-components';

import useStyles from './styles';
import { SubjectCardSkeletonProps } from './types';

const SubjectCardSkeleton = ({
  isFeatured,
  theme,
}: SubjectCardSkeletonProps) => {
  const styles = useStyles();

  return (
    <>
      <TextSkeleton
        className={styles.title}
        lines={2}
        theme={theme}
        variant={`cardTitle${isFeatured ? 'Featured' : ''}`}
      />
      <Divider
        className={clsx(styles.divider, isFeatured && styles.dividerFeatured)}
        spacing="md"
        theme={theme}
      />
    </>
  );
};

export default memo(SubjectCardSkeleton);
