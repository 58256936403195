import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      ...styling.utils.absoluteFull(),
      display: 'flex',
      flexDirection: 'column',
      padding: styling.sizes.theme.spacing.xs,
      backgroundColor: styling.colors.theme.primary800,
    },
    image: {
      ...styling.utils.absoluteFull(),
      zIndex: 1,
    },
    button: {
      position: 'relative',
      zIndex: 2,
      marginTop: 'auto',
    },
  },
  { name: 'accountSignup' }
);
