import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '100%',
      [styling.breakpoints.context.mobileOnlyString]: {
        width: '100%',
        height: '100%',
      },
    },
    scaler: {
      transformOrigin: 'center center',
    },
  },
  { name: 'scaleContainer' }
);
