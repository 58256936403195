import { memo } from 'react';
import clsx from 'clsx';
import { TextSkeleton } from '@kitted/shared-components';

import useCardSkeleton from './hooks/useCardSkeleton';
import useStyles from './styles';
import { CardSkeletonProps } from './types';

const CardSkeleton = ({
  className,
  isFeatured = false,
  isInverted = false,
  type = 'tool',
}: CardSkeletonProps) => {
  const styles = useStyles();
  const { Component, theme } = useCardSkeleton(type, isInverted);

  return (
    <div
      className={clsx(
        className,
        styles.container,
        isFeatured && styles.containerFeatured,
        isInverted && styles.containerInverted
      )}
    >
      <div className={styles.card}>
        {Component && <Component isFeatured={isFeatured} theme={theme} />}
        <TextSkeleton
          className={styles.cardSlug}
          lines={1}
          theme={theme}
          variant={`cardSlug${isFeatured ? 'Featured' : ''}`}
        />
      </div>
    </div>
  );
};

export default memo(CardSkeleton);
