import { useCallback, useMemo } from 'react';
import { NOOP } from '@kitted/shared-utils';

import { InputComponentProps, InputSelectOption } from '../../../types';
import { getSanitizedItem, handleOnChange } from '../../logic';

const useInputRadio = (
  options: InputComponentProps['options'],
  value: InputComponentProps['value'],
  onChange: InputComponentProps['onChange'] = NOOP
) => {
  const selectedItem = useMemo(
    () => getSanitizedItem(options, value),
    [options, value]
  );

  const onSelectItem = useCallback(
    (option: InputSelectOption) => () => handleOnChange(onChange, option),
    [onChange]
  );

  return { selectedItem, onSelectItem };
};

export default useInputRadio;
