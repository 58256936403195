import { mustMatchRegex, ValidationItemMustMatchRegex } from './mustMatchRegex';

const getAtLeastXUpperAndXLowerLettersRegex = (
  upperCount: number,
  lowerCount: number
) => new RegExp(`(?=.*[a-z]{${lowerCount}})(?=.*[A-Z]{${upperCount}})`);

export const hasAtLeastXUpperAndXLowerLetters = (
  isValueRequired: boolean,
  upperCount: number,
  lowerCount: number
): ValidationItemMustMatchRegex =>
  mustMatchRegex(
    isValueRequired,
    getAtLeastXUpperAndXLowerLettersRegex(upperCount, lowerCount),
    `This requires at least ${upperCount} upper and ${lowerCount} lower case letter(s)`
  );
