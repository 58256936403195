import { useCallback } from 'react';

import { toastDurations } from '../../../../../contexts/ToastContext/constants';
import useToastApi from '../../../../../contexts/ToastContext/hooks/useToastApi';
import useUserApi from '../../../../../contexts/UserContext/hooks/useUserApi';
import { transformForgotFormDataToForgotPayload } from '../../logic';
import {
  AuthForgotPasswordModalProps,
  ForgotPasswordFormData,
} from '../../types';

const useForgotPasswordModalContent = (
  onClose: AuthForgotPasswordModalProps['onClose']
) => {
  const { forgotPassword } = useUserApi();
  const { registerToast } = useToastApi();

  const onSubmit = useCallback(
    async (formData: ForgotPasswordFormData) => {
      const forgotPasswordPayload =
        transformForgotFormDataToForgotPayload(formData);
      return forgotPassword(forgotPasswordPayload);
    },
    [forgotPassword]
  );

  const onSubmitSuccess = useCallback(() => {
    registerToast('verifyUserSuccess', {
      title: 'Forgot password email sent',
      body: 'If this email is associated to an account, the email will arrive shortly.',
      theme: 'success',
      duration: toastDurations.success,
    });
    onClose();
  }, [registerToast, onClose]);

  return {
    onSubmit,
    onSubmitSuccess,
  };
};

export default useForgotPasswordModalContent;
