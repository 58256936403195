/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';
import { Stack, Typography } from '@kitted/shared-components';
import { NOOP } from '@kitted/shared-utils';

import Button from '../../../Button';
import { useFormSubmitButtonData } from '../../../Form/contexts/FormSubmitButtonContext';
import LoadingProgress from './LoadingProgress';
import useStyles from './styles';
import { CreateAndLoginButtonProps } from './types';
import useCreateAndLoginButton from './useCreateAndLoginButton';

const CreateAndLoginButton = ({
  onClose,
  progressPercentage,
}: CreateAndLoginButtonProps) => {
  const styles = useStyles();
  const { isLoading, isSubmitting, ...rest } = useFormSubmitButtonData();
  const { onLogin, style } = useCreateAndLoginButton(
    onClose,
    progressPercentage
  );

  return (
    <Stack direction="column" spacing="xs">
      <Button
        variant="solid"
        isLoading={isLoading}
        loadingComponent={isSubmitting ? LoadingProgress : undefined}
        theme="primary"
        fullWidth
        {...rest}
        style={style}
      >
        Create Account
      </Button>
      <Typography className={styles.loginWrapper} variant="inputLabel">
        Already have an account?{' '}
        <Typography
          className={clsx(styles.login, isLoading && styles.loginDisabled)}
          as="button"
          onClick={isLoading ? NOOP : onLogin}
          variant="inlineAction"
          type="button"
        >
          Login
        </Typography>
      </Typography>
    </Stack>
  );
};

export default memo(CreateAndLoginButton);
