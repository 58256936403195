import { useMemo } from 'react';

import { getIconComponentForTheme } from '../../logic';
import { BannerProps, BannerTheme } from '../../types';

const useBannerIcon = (icon: BannerProps['icon'], theme: BannerTheme) => {
  const IconComponent = useMemo(() => getIconComponentForTheme(theme), [theme]);

  return {
    IconComponent: icon || IconComponent,
  };
};

export default useBannerIcon;
