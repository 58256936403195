import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0044: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 108 108"
		height={108}
		width={108}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <circle vectorEffect="non-scaling-stroke" cx="54" cy="54" r="19.37" stroke={spot} /><circle vectorEffect="non-scaling-stroke" cx="54" cy="54" r="53.09" stroke={color} /><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M61.18 22.2 53.99 15l-7.19 7.2M22.2 46.82 15 54.01l7.2 7.19M46.82 85.8l7.19 7.2 7.19-7.2M85.8 61.18l7.2-7.19-7.2-7.19M53.99 15v18.55M15 54.01h18.55M54.01 93V74.45M93 53.99H74.45" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0044);
