import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { clearTimeout } from 'worker-timers';

import useIsServer from '../../../../hooks/useIsServer';
import { ImageProps } from '../../types';
import { getContainerStyle, getFallbackImageSrc, onLoaded } from './logic';

const useImage = ({
  src,
  onLoad,
  aspectRatio,
}: {
  src: ImageProps['src'];
  onLoad: ImageProps['onLoad'];
  aspectRatio: ImageProps['aspectRatio'];
}) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const isServer = useIsServer();
  const startTimeRef = useRef<number>(Date.now());
  const fadeInTimerRef = useRef<number>();

  const [renderableSrc, setRenderableSrc] = useState<string>(
    src || getFallbackImageSrc()
  );
  const [isLoaded, setIsLoaded] = useState(isServer || !src || false);
  const [isErrored, setIsErrored] = useState(false);

  const setFadeInTimerRef = useCallback((ref: number) => {
    fadeInTimerRef.current = ref;
  }, []);

  useEffect(() => {
    if (typeof fadeInTimerRef.current !== 'undefined') {
      clearTimeout(fadeInTimerRef.current);
      fadeInTimerRef.current = undefined;
    }
    setIsErrored(false);
    setIsLoaded(false);
    startTimeRef.current = Date.now();
    setRenderableSrc(src || getFallbackImageSrc());
  }, [src]);

  const handleOnLoaded = useCallback(
    (image: HTMLImageElement) =>
      onLoaded({
        src: renderableSrc,
        image,
        onLoad,
        setIsLoaded,
        startTime: startTimeRef.current,
        setFadeInTimerRef,
      }),
    [renderableSrc, onLoad, setIsLoaded, setFadeInTimerRef]
  );

  const handleOnLoad = useCallback(
    (event: SyntheticEvent<HTMLImageElement, Event>) =>
      handleOnLoaded(event.target as HTMLImageElement),
    [handleOnLoaded]
  );

  const handleOnError = useCallback(() => {
    setIsErrored(true);
  }, [setIsErrored]);

  useEffect(() => {
    if (imageRef.current?.complete) {
      handleOnLoaded(imageRef.current);
    }
  }, [handleOnLoaded]);

  const containerStyle = useMemo(
    () => getContainerStyle(aspectRatio),
    [aspectRatio]
  );

  return {
    imageRef,
    isFallback: renderableSrc === getFallbackImageSrc(),
    isErrored,
    isLoaded,
    containerStyle,
    handleOnLoad,
    handleOnError,
    renderableSrc,
  };
};

export default useImage;
