import { createUseStyles } from 'react-jss';

import { getVariants } from './logic';

export default createUseStyles(
  {
    typography: {
      '& strong': {
        fontWeight: 700,
      },
      '& em': {
        fontStyle: 'italic',
      },
    },
    maxLines: {
      overflow: 'hidden',
      whiteSpace: 'normal',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 'var(--typographyMaxLines)',
      WebkitBoxOrient: 'vertical',
      maxHeight:
        'calc(var(--typographyMaxLines) * var(--typographyLineHeight) + 2px)',
    },
    ...getVariants(),
    noMargin: {
      margin: 0,
    },
    isTight: {
      lineHeight: '1.055em',
    },
  },
  { name: 'typography' }
);
