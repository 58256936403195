import useGlobalScriptVariablesManagement from './hooks/useGlobalScriptVariablesManagement';
import { GlobalScriptVariablesContext } from './GlobalScriptVariablesContext';
import {
  GlobalScriptVariablesContextProps,
  GlobalScriptVariablesData,
} from './types';

export const GlobalScriptVariablesProvider = ({
  children,
}: GlobalScriptVariablesContextProps) => {
  const { values } = useGlobalScriptVariablesManagement();

  if (!values) {
    return null;
  }

  return (
    <GlobalScriptVariablesContext.Provider
      value={values as GlobalScriptVariablesData}
    >
      {children}
    </GlobalScriptVariablesContext.Provider>
  );
};
