import { AnyCardModel } from '@kitted/card-service-models';
import { SlugPrefixEnum } from '@kitted/kitted-models';
import { extractTypeAndCodeFromSlug } from '@kitted/shared-utils';

export const getRouteForCard = (slug: AnyCardModel['slug']): string => {
  const { code, type } = extractTypeAndCodeFromSlug(slug);

  switch (type) {
    case SlugPrefixEnum.Article:
    case SlugPrefixEnum.Play:
    case SlugPrefixEnum.Author:
    case SlugPrefixEnum.Flow:
    case SlugPrefixEnum.Kit:
    case SlugPrefixEnum.Action:
    case SlugPrefixEnum.Snare:
    case SlugPrefixEnum.Frame:
    case SlugPrefixEnum.Insight: {
      return `/${type.toLowerCase()}/${code}`;
    }
    case SlugPrefixEnum.Subject: {
      return `/discovery/${code}`;
    }
    case SlugPrefixEnum.Rlnk: {
      return `/link/${code}`;
    }
    case SlugPrefixEnum.Rimg: {
      return `/image/${code}`;
    }
    case SlugPrefixEnum.Rvid: {
      return `/video/${code}`;
    }
    default:
      return '';
  }
};
