import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    container: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  { name: 'spinner' }
);
