import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EMPTY_OBJ, throttle } from '@kitted/shared-utils';

import { SAFARI_MOBILE_BOTTOM_MENU_HEIGHT } from '../../constants';
import { getFixedBottomStyles, getIsSafariMobile } from '../../logic';
import { FixedBottomProps } from '../../types';

/**
 * Component that allows sticking elements to the bottom of the screen:
 *   - iOS Safari has a long-standing issue when using `position: fixed` near the
 *   bottom edge of the screen.
 *   - `window.innerHeight` reflects the available size and it changes when the
 *   navigation bars are visible.
 *
 * This components implements the following solution:
 *   1. Check for `iosSafariMobile()`
 *   2. Check if the element is hidden by the overflow bar
 *   3. Either add a `44px` offset to place it above the bar or
 *   keep it at its original position
 *   4. On mount, check whether the overflow bar is already
 *   visible
 * based upon: https://github.com/sydboys/react-fixed-bottom
 */

const useFixedBottom = (
  offset: number,
  style: React.CSSProperties,
  cssVariableName: FixedBottomProps['cssVariableName']
) => {
  const visibilityCheckRef = useRef(null);
  const isSafariMobileRef = useRef<boolean>(getIsSafariMobile());
  const deferredComputeOffsetBottomRef = useRef<number>();
  const [bottom, setBottom] = useState(offset);

  const setDeferredComputeOffsetBottomRef = useCallback((computeId: number) => {
    deferredComputeOffsetBottomRef.current = computeId;
  }, []);

  const computeOffsetBottom = useCallback(() => {
    if (!visibilityCheckRef.current) {
      setBottom(offset);
      return;
    }

    const { bottom: visCheckBottom } = (
      visibilityCheckRef.current as HTMLDivElement
    ).getBoundingClientRect();
    if (Math.floor(Number(visCheckBottom)) > window.innerHeight) {
      setBottom(offset + SAFARI_MOBILE_BOTTOM_MENU_HEIGHT);
    }
  }, [offset, setBottom]);

  const trottledComputeOffsetBottom = useMemo(
    () => throttle(computeOffsetBottom, 200),
    [computeOffsetBottom]
  );

  useEffect(() => {
    if (isSafariMobileRef.current === true) {
      window.addEventListener('scroll', trottledComputeOffsetBottom);
      setDeferredComputeOffsetBottomRef(window.setTimeout(computeOffsetBottom));
      // bind to scroll
      // return unbind from scroll
      return () => {
        trottledComputeOffsetBottom.cancel();
        window.removeEventListener('scroll', trottledComputeOffsetBottom);
        window.clearTimeout(deferredComputeOffsetBottomRef.current);
      };
    }
    return undefined;
  }, [
    setDeferredComputeOffsetBottomRef,
    computeOffsetBottom,
    trottledComputeOffsetBottom,
  ]);

  useEffect(() => {
    computeOffsetBottom();
  }, [computeOffsetBottom, offset]);

  const childrenStyles = useMemo(
    () => getFixedBottomStyles(bottom, style, cssVariableName),
    [bottom, style, cssVariableName]
  );
  const visibilityCheckStyles = useMemo(
    () => getFixedBottomStyles(offset, EMPTY_OBJ, cssVariableName),
    [offset, cssVariableName]
  );

  return {
    isSafariMobile: isSafariMobileRef.current,
    childrenStyles,
    visibilityCheckStyles,
    visibilityCheckRef: visibilityCheckRef.current,
  };
};

export default useFixedBottom;
