import { constructAuthenticatedRoute, constructPublicRoute } from '../helpers';
import { accountOutlets, accountRoutes } from './account';
import { articleRedirectRoutes, articleRoutes } from './article';
import { authModalRedirectRoutes } from './authModalRedirects';
import { authorRedirectRoutes, authorRoutes } from './author';
import { betaInviteRoutes } from './beta-invite';
import { discoveryRedirectRoutes, discoveryRoutes } from './discovery';
import { flowOutlets, flowRedirectRoutes, flowRoutes } from './flow';
import { kitRedirectRoutes, kitRoutes } from './kit';
import { legalsOutlets, legalsRoutes } from './legals';
import { mmmRedirectRoutes, mmmRoutes } from './mmm';
import { playRedirectRoutes, playRoutes } from './play';
import { resourceRedirectRoutes, resourceRoutes } from './resource';
import { subjectRedirectRoutes, subjectRoutes } from './subject';
import { toolRedirectRoutes, toolRoutes } from './tool';

export { legalsOutlets } from './legals';

// order is important.. we use these to create the <Routes /> tree
// greedy dynamic routes should be placed LOWER in the object
export const routes = {
  home: constructPublicRoute('/', 'Home'),
  contact: constructPublicRoute('/contact', 'Contact'),
  redeemPurchase: constructPublicRoute(
    '/redeem-purchase/:orderId',
    'Redeem Purchase'
  ),
  ...legalsRoutes,
  ...betaInviteRoutes,
  ...authModalRedirectRoutes,
  ...accountRoutes,
  ...discoveryRoutes,
  ...subjectRoutes,
  ...kitRoutes,
  ...authorRoutes,
  ...articleRoutes,
  ...playRoutes,
  ...flowRoutes,
  ...resourceRoutes,
  ...toolRoutes,
  ...mmmRoutes,
  // admin routes
  emailTemplatePreview: constructAuthenticatedRoute(
    '/email-template-preview',
    'Email Template Preview'
  ),
  // testing
  testingSafariFooter: constructPublicRoute(
    '/testing-safari-footer',
    'Here be dragons'
  ),
  fourOhFour: constructPublicRoute('/not-found', 'Page not found'),
  rootCard: constructPublicRoute('/:possibleCard', 'Card'),
} as const;

export const routesFlat = {
  ...legalsOutlets,
  ...accountOutlets,
  ...flowOutlets,
  ...routes,
} as const;

export const routesRedirects = {
  ...discoveryRedirectRoutes,
  ...subjectRedirectRoutes,
  ...kitRedirectRoutes,
  ...authorRedirectRoutes,
  ...articleRedirectRoutes,
  ...playRedirectRoutes,
  ...flowRedirectRoutes,
  ...resourceRedirectRoutes,
  ...toolRedirectRoutes,
  ...mmmRedirectRoutes,
} as const;

export type RoutesNonRedirectsKeys = keyof Omit<
  typeof routesFlat,
  keyof typeof routesRedirects
>;
