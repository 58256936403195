import { ChangeEvent, useCallback, useMemo, useRef } from 'react';

import { InputComponentProps } from '../../../types';
import {
  generateHandleOnChange,
  getSanitizedValue,
  handleOnClearValue,
} from '../../logic';

const useInputText = (
  type: InputComponentProps['type'],
  onChange: InputComponentProps['onChange'],
  value: InputComponentProps['value']
) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnChange = useCallback(
    (e: ChangeEvent) =>
      generateHandleOnChange(
        type,
        onChange,
        (e.target as HTMLInputElement).value
      ),
    [type, onChange]
  );

  const onClearValue = useCallback(
    () => handleOnClearValue(onChange, inputRef?.current),
    [onChange]
  );

  const sanitizedValue = useMemo(
    () => getSanitizedValue(type, value),
    [type, value]
  );

  return {
    inputRef,
    handleOnChange,
    onClearValue,
    sanitizedValue,
  };
};

export default useInputText;
