import { FixedBottomProps } from './types';

/**
 * This function returns true if we successfully detect Safari mobile.
 * It should test against Apple devices and then exclude most non Safari browsers
 * Source in https://stackoverflow.com/questions/3007480/determine-if-user-navigated-from-mobile-safari
 */
export const getIsSafariMobile = (): boolean => {
  if (!window?.navigator) {
    return false;
  }

  const {
    navigator: { userAgent },
  } = window;
  return (
    /iP(ad|od|hone)/i.test(userAgent) &&
    /WebKit/i.test(userAgent) &&
    !/(CriOS|FxiOS|OPiOS|mercury)/i.test(userAgent)
  );
};

export const getFixedBottomStyles = (
  bottom: number,
  style: React.CSSProperties,
  cssVariableName: FixedBottomProps['cssVariableName']
) =>
  ({
    ...style,
    position: 'fixed',
    bottom: cssVariableName
      ? `calc(var(--${cssVariableName}, ${bottom}) * 1px)`
      : bottom,
  }) as React.CSSProperties;
