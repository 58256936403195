/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, memo } from 'react';
import { InternalLink, InternalLinkProps } from '@kitted/shared-components';

import usePreloadingInternalLink from './hooks/usePreloadingInternalLink';

const PreloadingInternalLink = forwardRef(
  (
    { to, ...rest }: InternalLinkProps,
    ref: React.ComponentPropsWithRef<'a'>['ref']
  ) => {
    usePreloadingInternalLink(to);

    return <InternalLink {...rest} ref={ref} to={to} />;
  }
);

PreloadingInternalLink.displayName = 'PreloadingInternalLink';

export default memo(PreloadingInternalLink);
