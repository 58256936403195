import { CatalogueItemModel } from '@kitted/catalogue-service-models';
import { CardModelId } from '@kitted/kitted-models';
import { Nullable } from '@kitted/platform-models';

import { CatalogueSelectorViewState } from './logic';

export type CardGridEditContextProps = {
  children: React.ReactNode;
};

export type CardGridEditData = {
  selectedCardIds: CardModelId[];
};

export type CardGridEditApi = {
  onToggleCardId: (cardId: CardModelId) => () => void;
};

export type CatalogueSelectorFilters = 'All' | 'My';

export enum CatalogueItemType {
  PlatformImageResource = 'PlatformImageResource',
  AuthorImageAsset = 'AuthorImageAsset',
  GalleryAsset = 'GalleryAsset',
}

export type CatalogueSelectorViewApi = {
  getInitalState: () => CatalogueSelectorViewState;
  setFilter: (filter: CatalogueSelectorFilters) => void;
  setSearchText: (searchText: string) => void;
  setGalleryItems: (galleryItems: CatalogueItemModel[]) => void;
  setPlatformImages: (platformImages: CatalogueItemModel[]) => void;
  setMyImages: (myImages: CatalogueItemModel[]) => void;
  setSelectedCatalogueItem: (
    selectedCatalogueItem: Nullable<CatalogueItemModel>
  ) => void;
  setIsLoading: (isLoading: boolean) => void;
  setUploadedBlobGuid: (uploadedBlobGuid: Nullable<string>) => void;
};
