import { useMemo } from 'react';
import { CardType } from '@kitted/kitted-models';
import { ResourceType } from '@kitted/resource-service-models';

const useLinkResourceEditPageContentPageSection = () => {
  const overrideCardProps = useMemo(
    () => ({
      type: CardType.Resource,
      subType: ResourceType.Link,
    }),
    []
  );

  return {
    overrideCardProps,
  };
};
export default useLinkResourceEditPageContentPageSection;
