const tooltip = 3000;
const toast = 2500;
const alert = 2200;
const modal = 2000;
const flow = 1200;

export default {
  tooltip,
  toast,
  alert,
  modal,
  flow,
  backdrops: {
    alert: alert - 1,
    modal: modal - 1,
    tooltip: tooltip - 1,
  },
  fixedHeaderBar: 50,
  drawerMainMenu: 100,
  fixedMobileNav: 1000,
};
