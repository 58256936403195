import { useContext } from 'react';

import { FormContext } from '../../FormContext';

const useFormApi = () => {
  const formService = useContext(FormContext);

  if (formService === undefined) {
    throw Error(
      'useFormApi must be used inside of a FormContext, ' +
        'otherwise it will not function correctly.'
    );
  }

  return formService;
};

export default useFormApi;
