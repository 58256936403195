import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0019: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 118 116"
		height={116}
		width={118}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><g stroke={color}><path vectorEffect="non-scaling-stroke" d="M23.9 40.16c0 4.25 3.7 7.68 8.26 7.68s8.27-3.44 8.27-7.68-4.41-6.11-8.29-8.07c-3.87-1.96-8.28-3.82-8.28-8.07s3.7-7.69 8.27-7.69 8.27 3.44 8.27 7.69M32.13 16.3v-5.15M32.13 53.05v-5.16" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M46.01 58.73c-3.7 1.93-7.87 3.12-12.32 3.35-16.58.86-30.72-11.87-31.58-28.45C1.24 17.05 13.98 2.91 30.56 2.05S61.28 13.92 62.14 30.5c.28 5.28-.83 10.32-3 14.76" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M94.95 101.96c0 2.33 2.03 4.21 4.53 4.21s4.53-1.88 4.53-4.21-2.42-3.35-4.54-4.42c-2.12-1.08-4.54-2.1-4.54-4.42s2.03-4.21 4.53-4.21 4.53 1.89 4.53 4.21M99.47 88.88v-2.83M99.47 109.02v-2.83" /><path vectorEffect="non-scaling-stroke" d="M92.62 82.52c2.08-.95 4.4-1.48 6.84-1.48 9.1 0 16.48 7.38 16.48 16.48S108.56 114 99.46 114s-16.48-7.38-16.48-16.48c0-4.64 1.92-8.84 5.01-11.83" /></g><path vectorEffect="non-scaling-stroke" stroke={spot} d="M41.38 52.26 60.22 71.1l-6.03 6.03c-.72.73-.5 1.63-.3 2.11.42 1.01 1.42 1.81 2.28 1.81h23.81a3.03 3.03 0 0 0 3.01-3.02l-.04-23.76c0-.92-.75-1.95-1.71-2.34-.84-.35-1.68-.16-2.37.53l-6.27 6.27-13.67-13.67" />
    </g>
  </CardIcon>
);

export default memo(In0019);
