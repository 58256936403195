import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    list: {
      margin: [
        styling.sizes.theme.spacing['2xs'],
        0,
        styling.sizes.theme.spacing.xs,
      ],
      display: 'flex',
      flexDirection: 'column',
      gap: styling.sizes.theme.spacing['2xs'],
    },
    listItem: {
      display: 'flex',
      gap: 14,
      alignItems: 'center',
    },
    indicator: {
      color: styling.colors.theme.success,
      fontSize: 16,
    },
    indicatorError: {
      color: styling.colors.theme.error,
    },
  },
  { name: 'passwordStrengthIndicator' }
);
