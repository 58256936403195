import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: styling.colors.theme.primary1000,
      color: styling.colors.context.color,
      zIndex: styling.zIndex.drawerMainMenu + 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: [150, styling.sizes.context.gutteringW, 95],
      textAlign: 'center',
    },
    stack: {
      margin: [0, 'auto', 'auto'],
      width: '100%',
      maxWidth: styling.sizes.utils.gridColumns(
        3,
        styling.sizes.context.gutteringW,
        true
      ),
    },
    heading: {
      display: 'flex',
    },
    helperText: {
      margin: [0, 'auto'],
      width: '100%',
      maxWidth: styling.sizes.utils.gridColumns(
        3,
        styling.sizes.context.gutteringW,
        true
      ),
    },
    spinner: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  { name: 'loadingOverlay' }
);
