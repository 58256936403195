import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const In0035: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 94 94"
		height={94}
		width={94}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M10.09 28.63h73.82M77.04 10.32h9.51c2.84 0 5.16 2.32 5.16 5.16V86.5c0 2.84-2.32 5.19-5.16 5.19H7.45c-2.84 0-5.16-2.35-5.16-5.19V15.48c0-2.84 2.33-5.16 5.16-5.16h14.2M54.45 10.32h12.37M31.87 10.32h12.36M22.01 2.31v17.55M67.17 2.31v17.55M44.59 2.31v17.55M16.67 51.96h60.66M16.67 68.86h60.66M47 82.22V38.61M30.1 82.22V38.61M63.9 82.22V38.61" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M22.01 44.08h.92V45h-.92zM38.36 44.08h.92V45h-.92zM54.7 44.08h.92V45h-.92zM71.05 44.08h.92V45h-.92z" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M22.01 59.49h.92v.92h-.92zM38.36 59.49h.92v.92h-.92zM54.7 59.49h.92v.92h-.92zM71.05 59.49h.92v.92h-.92z" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M22.01 75.82h.92v.92h-.92zM38.36 75.82h.92v.92h-.92zM54.7 75.82h.92v.92h-.92zM71.05 75.82h.92v.92h-.92z" /></g>
    </g>
  </CardIcon>
);

export default memo(In0035);
