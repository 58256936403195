import { memo } from 'react';
import { Typography } from '@kitted/shared-components';
import { routes } from '@kitted/website-constants';

import PreloadingInternalLink from '../../../PreloadingInternalLink';
import useStyles from './styles';
import { TermsProps } from './types';

const Terms = ({ onClick }: TermsProps) => {
  const styles = useStyles();

  return (
    <Typography className={styles.container} variant="bodySmall">
      By creating an account, you agree to our{' '}
      <PreloadingInternalLink
        className={styles.link}
        onClick={onClick}
        to={routes.legals.path}
      >
        Terms and Privacy Policy
      </PreloadingInternalLink>
    </Typography>
  );
};

export default memo(Terms);
