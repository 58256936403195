import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const Ellipsis: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 22 4"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" fill={color} d="M1.47.92c.21-.14.46-.22.72-.22.34 0 .67.14.92.38a1.3 1.3 0 0 1-.42 2.12c-.24.1-.5.12-.75.07s-.48-.17-.66-.35-.3-.41-.35-.66-.02-.51.07-.75c.1-.24.26-.44.48-.58ZM10.28.92c.21-.14.46-.22.72-.22.34 0 .67.14.92.38s.38.57.38.92c0 .26-.08.51-.22.72a1.3 1.3 0 0 1-.58.48c-.24.1-.5.12-.75.07s-.48-.17-.66-.35-.3-.41-.35-.66-.02-.51.07-.75c.1-.24.26-.44.48-.58ZM19.09.92c.21-.14.46-.22.72-.22.34 0 .67.14.92.38a1.3 1.3 0 0 1-.42 2.12c-.24.1-.5.12-.75.07s-.48-.17-.66-.35-.3-.41-.35-.66-.02-.51.07-.75c.1-.24.26-.44.48-.58Z" />
    </g>
  </WebsiteIcon>
);

export default memo(Ellipsis);
