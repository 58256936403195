import { useCallback, useEffect, useRef, useState } from 'react';
import { upperFirst } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { CardType } from '@kitted/kitted-models';
import { ResourceType } from '@kitted/resource-service-models';

import PreloadingInternalLink from '../../../../../../../components/PreloadingInternalLink';
import { toastDurations } from '../../../../../../../contexts/ToastContext/constants';
import useToastApi from '../../../../../../../contexts/ToastContext/hooks/useToastApi';
import { UserCreateableModel } from '../../types';
import { getInternalLinkAndTitleForCardType } from './logic';

const useCreateModelFlyout = (redirectOnCreate: boolean) => {
  const { registerToast } = useToastApi();
  const [redirectLocation, setRedirectLocation] = useState<string | undefined>(
    undefined
  );
  const createdModelRef = useRef<UserCreateableModel | undefined>(undefined);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, [setIsOpen]);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const registerCreatedModel = useCallback((model: UserCreateableModel) => {
    createdModelRef.current = model;
  }, []);

  const launchSuccessToast = useCallback(
    (cardType: CardType, resourceType?: ResourceType) => () => {
      if (!createdModelRef.current) return;
      const [to, title] = getInternalLinkAndTitleForCardType(
        createdModelRef.current,
        cardType,
        resourceType
      );
      if (redirectOnCreate) {
        setRedirectLocation(to);
      }
      registerToast('create-item', {
        title: `New ${upperFirst(cardType)} created`,
        theme: 'success',
        body: redirectOnCreate ? undefined : (
          <PreloadingInternalLink to={to}>{title}</PreloadingInternalLink>
        ),
        duration: toastDurations.success,
      });
      createdModelRef.current = undefined;
    },
    [registerToast, redirectOnCreate, setRedirectLocation]
  );

  useEffect(() => {
    if (redirectLocation) {
      navigate(redirectLocation);
      setRedirectLocation(undefined);
    }
  }, [navigate, redirectLocation, setRedirectLocation]);

  return {
    toggleOpen,
    isOpen,
    onClose,
    registerCreatedModel,
    onModalClose: launchSuccessToast,
  };
};

export default useCreateModelFlyout;
