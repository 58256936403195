import { memo } from 'react';
import { Typography } from '@kitted/shared-components';

import ExternalLinkWithCapturedQueryParams from '../../../../components/ExternalLinkWithCapturedQueryParams';
import PreloadingInternalLink from '../../../../components/PreloadingInternalLink';
import { MenuItem } from '../../../../constants/routes/types';
import useStyles from './styles';
import { FooterMenuProps } from './types';

const FooterMenu = ({ menu }: FooterMenuProps) => {
  const styles = useStyles();

  return (
    <>
      {menu.map(({ route: { path, title, target } }: MenuItem) => {
        if (target) {
          return (
            <li key={path} className={styles.container}>
              <Typography
                as={ExternalLinkWithCapturedQueryParams}
                href={path}
                target={target}
                variant="footerLink"
              >
                {title}
              </Typography>
            </li>
          );
        }
        return (
          <li key={path} className={styles.container}>
            <Typography
              as={PreloadingInternalLink}
              to={path}
              variant="footerLink"
            >
              {title}
            </Typography>
          </li>
        );
      })}
    </>
  );
};

export default memo(FooterMenu);
