import { convertImageResourceModelToImageResourceCardModel } from '@kitted/card-service-shared';
import { KittedServiceEnum } from '@kitted/kitted-models';
import {
  ImageResourceModel,
  ImageResourceSaveModel,
} from '@kitted/resource-service-models';

import useRequests from '../../../contexts/RequestsContext/hooks/useRequests';
import useStoreSection from '../../../contexts/StoreContext/hooks/useStoreSection';
import useAsyncRequest from '../../useAsyncRequest';
import useAuthedAsyncRequest from '../../useAuthedAsyncRequest';

const useImageResourceRequests = () => {
  const { updateStoreSectionItem } = useStoreSection('Cards');
  const { platformRequest } = useRequests();

  const [getImageResourceBySlug, imageResourceBySlugFetchState] =
    useAsyncRequest<ImageResourceModel<false>>(
      (slug: ImageResourceModel['slug']) =>
        platformRequest(KittedServiceEnum.Resource, {
          method: 'GET',
          url: `/v1/imageResource/slug/${slug}/published`,
        })
    );

  const [getImageResource, imageResourceFetchState] = useAsyncRequest<
    ImageResourceModel<false>
  >((id: ImageResourceModel['id']) =>
    platformRequest(KittedServiceEnum.Resource, {
      method: 'GET',
      url: `/v1/imageResource/${id}/published`,
    })
  );

  const [cloneImageResource, imageResourceCloneState] =
    useAuthedAsyncRequest<ImageResourceModel>(
      (data: ImageResourceSaveModel) =>
        platformRequest(KittedServiceEnum.Resource, {
          method: 'POST',
          data,
          url: '/v1/imageResource/clone',
        }),
      (response: ImageResourceModel | undefined) => {
        if (response) {
          updateStoreSectionItem(
            convertImageResourceModelToImageResourceCardModel(
              response.id,
              response
            ),
            response.id
          );
        }
      }
    );

  const [draftSavePublishImageResource, imageResourceDraftSavePublishState] =
    useAuthedAsyncRequest<ImageResourceModel>(
      (data: ImageResourceSaveModel) =>
        platformRequest(KittedServiceEnum.Resource, {
          method: 'POST',
          data,
          url: '/v1/imageResource/draftSavePublish',
        }),
      (response: ImageResourceModel | undefined) => {
        if (response) {
          updateStoreSectionItem(
            convertImageResourceModelToImageResourceCardModel(
              response.id,
              response
            ),
            response.id
          );
        }
      }
    );

  const [createPublishImageResource, imageResourceCreatePublishState] =
    useAuthedAsyncRequest<ImageResourceModel>(
      (data: ImageResourceSaveModel) =>
        platformRequest(KittedServiceEnum.Resource, {
          method: 'POST',
          data,
          url: '/v1/imageResource/createPublish',
        }),
      (response: ImageResourceModel | undefined) => {
        if (response) {
          updateStoreSectionItem(
            convertImageResourceModelToImageResourceCardModel(
              response.id,
              response
            ),
            response.id
          );
        }
      }
    );

  return {
    getImageResourceBySlug,
    imageResourceBySlugFetchState,
    getImageResource,
    imageResourceFetchState,
    cloneImageResource,
    imageResourceCloneState,
    draftSavePublishImageResource,
    imageResourceDraftSavePublishState,
    createPublishImageResource,
    imageResourceCreatePublishState,
  };
};

export default useImageResourceRequests;
