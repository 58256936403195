import { memo } from 'react';
import clsx from 'clsx';

import Stack from '../Stack';
import useStyles from './styles';
import { PanelProps } from './types';

const Panel = ({ className, children, theme = 'secondary' }: PanelProps) => {
  const styles = useStyles();

  return (
    <div
      className={clsx(className, styles.container, styles[`theme-${theme}`])}
    >
      <Stack>{children}</Stack>
    </div>
  );
};

export default memo(Panel);
