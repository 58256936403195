import { useCallback } from 'react';
import { AuthenticateUserResponse } from 'quidproquo';
import {
  UserLoginRequest,
  UserLoginResponse,
  UserRegisterRequest,
  UserRegisterResponse,
} from '@kitted/auth-service-models';
import { KittedServiceEnum } from '@kitted/kitted-models';

import useAsyncRequest from '../../../../hooks/useAsyncRequest';
import useAuthTokensApi from '../../../AuthTokensContext/hooks/useAuthTokensApi';
import useRequests from '../../../RequestsContext/hooks/useRequests';

const useSignInOutUpUser = (
  handleAuthenticateUserResponse: (
    email: string,
    response: AuthenticateUserResponse
  ) => void
) => {
  const { setTokens } = useAuthTokensApi();
  const { platformRequest } = useRequests();

  // register user function
  const [registerUser] = useAsyncRequest<UserRegisterResponse>(
    (registerPayload: UserRegisterRequest) =>
      platformRequest(KittedServiceEnum.Auth, {
        method: 'POST',
        data: registerPayload,
        url: '/v1/register',
      }),
    (registerResponse, { email }: UserRegisterRequest) => {
      handleAuthenticateUserResponse(email, registerResponse);
    }
  );

  // login function
  const [loginUser] = useAsyncRequest<UserLoginResponse>(
    (loginPayload: UserLoginRequest) =>
      platformRequest(KittedServiceEnum.Auth, {
        method: 'POST',
        data: loginPayload,
        url: '/v1/login',
      }),
    (loginResponse, { email }: UserLoginRequest) => {
      handleAuthenticateUserResponse(email, loginResponse);
    }
  );

  const logoutUser = useCallback(() => {
    setTokens(undefined);
  }, [setTokens]);

  return {
    registerUser,
    loginUser,
    logoutUser,
  };
};

export default useSignInOutUpUser;
