import { z } from 'zod';

import { ToolType } from '../Enum';
import {
  ToolBaseCardLoadedModelSchema,
  ToolBaseCardUnloadedModelSchema,
} from '../ToolBase';

export const ActionToolCardBaseModelSchema = z.object({
  toolType: z.literal(ToolType.Action),
  textTitle01: z.string().optional(),
  textEntry01: z.string().optional(),
  textTitle02: z.string().optional(),
  textEntry02: z.string().optional(),
});

export const ActionToolCardLoadedModelSchema =
  ToolBaseCardLoadedModelSchema.merge(ActionToolCardBaseModelSchema);
export const ActionToolCardUnloadedModelSchema =
  ToolBaseCardUnloadedModelSchema.merge(ActionToolCardBaseModelSchema);

export type ActionToolCardLoadedModel = z.infer<
  typeof ActionToolCardLoadedModelSchema
>;
export type ActionToolCardUnloadedModel = z.infer<
  typeof ActionToolCardUnloadedModelSchema
>;

export type ActionToolCardModel<IsLoaded extends boolean = false> =
  IsLoaded extends true
    ? ActionToolCardLoadedModel
    : ActionToolCardUnloadedModel;
