/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';
import { Fade, Typography } from '@kitted/shared-components';
import { Arrow, Content, Portal, Root, Trigger } from '@radix-ui/react-tooltip';

import useTooltip from './hooks/useTooltip';
import useStyles from './styles';
import { TooltipProps } from './types';

const Tooltip = ({
  className,
  tooltipClassName,
  children,
  content,
  open,
  defaultOpen = false,
  onOpenChange,
  isDisabled = false,
  theme = 'secondary',
  side = 'top',
  sideOffset = 32,
  align = 'center',
  collisionBoundary,
  ...props
}: TooltipProps) => {
  const { isOpen, triggerProps } = useTooltip(defaultOpen, open);
  const styles = useStyles();

  if (isDisabled) {
    return <>{children}</>;
  }

  return (
    <Root open={isOpen} onOpenChange={onOpenChange}>
      <Trigger asChild>
        <span
          {...triggerProps}
          className={clsx(className, styles.triggerWrapper)}
        >
          {children}
        </span>
      </Trigger>
      <Portal>
        {content && (
          <Content
            side={side}
            align={align}
            sideOffset={sideOffset}
            collisionBoundary={collisionBoundary}
            {...props}
          >
            <Typography
              as={Fade}
              className={clsx(
                styles.container,
                styles[`theme-${theme}`],
                tooltipClassName
              )}
              variant="tooltip"
              appear
              in
              durationIn={500}
              durationOut={500}
            >
              {content}
              <Arrow width={12} height={10} className={styles.arrow} />
            </Typography>
          </Content>
        )}
      </Portal>
    </Root>
  );
};

export default memo(Tooltip);
