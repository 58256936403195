import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0038: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 104 112"
		height={112}
		width={104}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={color} ><circle vectorEffect="non-scaling-stroke" cx="52" cy="33.68" r="8.91" /><path vectorEffect="non-scaling-stroke" d="M34.24 56.06c2.17-2.68 5.49-4.4 9.19-4.4h17.14c3.26 0 6.22 1.33 8.36 3.48" /><circle vectorEffect="non-scaling-stroke" cx="21.63" cy="33.68" r="8.91" /><path vectorEffect="non-scaling-stroke" d="M10.99 92.64c-6.51 0-9.77-5.33-9.77-11.83V63.49c0-6.51 5.32-11.83 11.83-11.83h12.72M10.89 67.07v39.1" /><circle vectorEffect="non-scaling-stroke" cx="82.38" cy="33.68" r="8.91" /><path vectorEffect="non-scaling-stroke" d="M93.01 92.64c6.5 0 9.77-5.33 9.77-11.83V63.49c0-6.51-5.32-11.83-11.83-11.83H78.24M93.11 67.07v39.1M76.1 9.85c0 4.92-3.98 8.91-8.9 8.91s-8.92-3.99-8.92-8.91S62.27.94 67.19.94s8.9 3.99 8.9 8.91Z" /><circle vectorEffect="non-scaling-stroke" cx="36.81" cy="9.85" r="8.91" /></g><g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M36.06 71.54c-.75.26-1.44.69-2.02 1.27-1.05 1.05-1.62 2.49-1.61 4.05.02 1.62.66 3.15 1.81 4.3" /><path vectorEffect="non-scaling-stroke" d="M75.85 87.25c5.7-5.7 5.79-14.94.2-20.53s-14.83-5.5-20.53.2l-3.53 3.51-3.51-3.51c-5.7-5.7-14.94-5.79-20.53-.2s-5.5 14.83.2 20.53L52 111.06l23.86-23.81Z" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0038);
