import { useMemo, useRef } from 'react';

import { transitionDirectionStyles } from '../../constants';
import { TransitionDirection } from '../../types';

const useSlideInOut = (
  transitionDirection: TransitionDirection,
  translateX = '0'
) => {
  const ref = useRef<HTMLDivElement>(null);

  const transitionStyles = useMemo(
    () => transitionDirectionStyles[transitionDirection](translateX),
    [transitionDirection, translateX]
  );

  return {
    ref,
    transitionStyles,
  };
};

export default useSlideInOut;
