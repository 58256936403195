import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      '&:not($containerSelected)': {
        '&:hover,&:active,&:focus': {
          color: styling.colors.context.inverted.color,
        },
      },
    },
    containerSelected: {
      backgroundColor: styling.colors.context.inverted.background,
      color: styling.colors.context.inverted.color,
    },
  },
  { name: 'mobileMenuOption' }
);
