import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const SquaresTwoByThree: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 20 28"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M14.93 23.47h3.15v3.15h-3.15zM1.92 23.47h3.15v3.15H1.92zM14.93 12.42h3.15v3.15h-3.15zM1.92 12.42h3.15v3.15H1.92zM14.93 1.38h3.15v3.15h-3.15zM1.92 1.38h3.15v3.15H1.92z" />
    </g>
  </WebsiteIcon>
);

export default memo(SquaresTwoByThree);
