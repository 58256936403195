import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Sn0007: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 140 120"
		height={120}
		width={140}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g stroke={spot} ><path vectorEffect="non-scaling-stroke" d="M76.76 68.39V24.58M38.56 64.37c0 4.49 5.07 8.13 11.32 8.13s11.32-3.64 11.32-8.13-11.32-23.09-11.32-23.09-11.32 18.6-11.32 23.09M60.12 62.48H39.63M92.32 43.41c0 4.49 5.07 8.13 11.32 8.13s11.32-3.64 11.32-8.13-11.32-23.09-11.32-23.09-11.32 18.6-11.32 23.09M113.88 41.52H93.4M75.67 15.53c0 .6.49 1.09 1.09 1.09s1.09-.49 1.09-1.09c0-.61-.49-1.09-1.09-1.09s-1.09.49-1.09 1.09M76.73 74.8c8.81 0 16.54 4.62 20.9 11.57l-41.76-.07c4.37-6.91 12.08-11.49 20.85-11.49Z" /><path vectorEffect="non-scaling-stroke" d="M109.78 12.64c-.03.38-.54 6.17-9.48 6.69-6.61.07-14.34 1.35-23.5 5.22l-.08.03c-9.16 3.87-15.46 8.53-20.12 13.23-6.6 6.05-11.12 2.38-11.4 2.14" /></g><g stroke={color} ><path vectorEffect="non-scaling-stroke" d="M138.27 105.53H15.24V1.19M1.35 28.59h13.77M1.35 59.83h13.77M1.35 91.08h13.77M29.77 119.18v-13.76M59.47 119.18v-13.76M89.17 119.18v-13.76M118.87 119.18v-13.76" /></g>
    </g>
  </CardIcon>
);

export default memo(Sn0007);
