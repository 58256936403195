import { useMemo } from 'react';
import { ResourceCardTypeValues } from '@kitted/card-service-models';

import { CardProps, CardSubType } from '../../../types';
import { getDisplayedTitle } from '../../logic';

const useResourceTypeContent = (
  title: CardProps['title'],
  showTitle: CardProps['showTitle'],
  subType?: CardSubType
) => {
  const displayedTitle = useMemo(
    () =>
      getDisplayedTitle(subType as ResourceCardTypeValues, title, showTitle),
    [subType, title, showTitle]
  );

  return {
    displayedTitle,
  };
};

export default useResourceTypeContent;
