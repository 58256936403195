import { z } from 'zod';

import { KittedVersionStatusTypeEnum } from './enum';
import { KittedAuditableModelSchema } from './KittedAuditableModel';
import { KittedModelBaseSchema } from './KittedModelBase';

export const KittedVersionedModelBaseSchema = KittedModelBaseSchema.merge(
  KittedAuditableModelSchema
).merge(
  z.object({
    version: z.number().int(),
    versionStatus: z.nativeEnum(KittedVersionStatusTypeEnum),
  })
);

export type KittedVersionedModelBase = z.infer<
  typeof KittedVersionedModelBaseSchema
>;
