import { memo } from 'react';
import { styling } from '@kitted/design-system';

import CardIcon from './CardIcon';
import { CardIconProps } from './types';

const Ac0029: React.FC<CardIconProps> = ({
  color = styling.colors.context.iconDefaultColor,
  spot = styling.colors.context.iconDefaultColor,
  ...props
}: CardIconProps) => (
  <CardIcon
    viewBox="0 0 108 94"
		height={94}
		width={108}
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1">
      <g ><path vectorEffect="non-scaling-stroke" stroke={spot} d="M62.4 73.34c3.22 1.54 7.11 2.96 8.31 6.14.81 2.16 1.58 5.15 1.97 7.73.03.1.04.2.04.3v.12c-.02 2.68-8.62 4.82-19.64 5.02q-1.155.045-2.37.03h1.16q-1.23 0-2.37-.06c-11.01-.37-19.58-2.65-19.55-5.33v-.12c0-.1.02-.2.05-.3.43-2.57 1.25-5.55 2.09-7.69 1.17-2.98 4.71-4.35 7.83-5.74M62.13 56.44c-.03-5.93-4.85-10.77-10.79-10.8h-.02c-5.93.04-10.76 4.88-10.79 10.8-.18.17-.35.38-.48.61-.85 1.47-.46 3.95.38 5.41.62 1.08 1.4 1.52 2.02 1.69 1.34 3.04 4.26 8.05 8.87 8.07 4.62-.02 7.54-5.02 8.88-8.06 1.98-.5 2.89-3.12 2.89-5.03 0-1.26-.39-2.14-.96-2.67Z" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M24.51 81.26c-4.02-.43-7.89-1.48-7.91-4.1v-.09q0-.21.06-.42c.27-1.67.78-3.58 1.33-5.02.62-1.63 1.86-2.74 3.35-3.57l5.43-3.08M35.53 49.89a7.35 7.35 0 0 0-3.97-1.18c-1.55 0-3.1.52-4.35 1.45-3 2.21-2.92 6.24-2.18 9.52.71 3.11 2.87 7.2 6.53 7.21 1.06 0 2.09-.42 2.91-1.08M78.04 81.26c4.02-.43 7.89-1.48 7.91-4.1v-.09q0-.21-.06-.42c-.27-1.67-.78-3.58-1.33-5.02-.62-1.63-1.85-2.74-3.35-3.57l-5.43-3.08M67.02 49.89a7.35 7.35 0 0 1 3.97-1.18c1.55 0 3.1.52 4.35 1.45 3 2.21 2.92 6.24 2.18 9.52-.71 3.11-2.87 7.2-6.53 7.21-1.06 0-2.09-.42-2.91-1.08" /></g><path vectorEffect="non-scaling-stroke" stroke={color} d="M45.89 14.9c3.74 1.99 6.91 4.92 9.17 8.49" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M90.8 70.11c9.24-1.89 16.2-10.06 16.2-19.87 0-7.99-4.67-14.86-11.37-18.17.2-1.3.33-2.62.33-3.98 0-14.79-11.99-26.78-26.78-26.78-9.99 0-18.69 5.48-23.29 13.58-3.38-1.75-7.2-2.75-11.27-2.75-13.55 0-24.55 10.99-24.55 24.55 0 1.19.11 2.35.27 3.5.04.35.09.69.16 1.03.02.14.03.28.06.41C4.96 43.79.99 49.21.99 55.58c0 6.72 4.44 12.41 10.54 14.28" /><path vectorEffect="non-scaling-stroke" stroke={color} d="M70.33 38.2c.59-.8 1.24-1.55 1.94-2.25 3.67-3.68 8.75-5.96 14.36-5.96 3.22 0 6.27.75 8.99 2.1M16.86 53.41a24.4 24.4 0 0 1-6.29-11.77" />
    </g>
  </CardIcon>
);

export default memo(Ac0029);
